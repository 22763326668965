<template>
  <!-- eslint-disable -->
  <app-markdown-preview
    v-html="$app.appMode != 'DATA_ATELIER' ? notes : notesDataAtelier">
  </app-markdown-preview>
</template>

<script>
import notes from '@root/release_notes/RELEASE_NOTES.md';
import notesDataAtelier from '@root/release_notes/RELEASE_NOTES_DATA_ATELIER.md';

export default {
  name: 'NewFeaturesTabItem',

  data: () => ({
    notes,
    notesDataAtelier,
  }),
};
</script>
