export const IMAGE_KEY_DESIGN = 'Design';
export const IMAGE_KEY_LOCATION = 'Location';
export const IMAGE_KEY_SUMMARY = 'Summary';
export const IMAGE_KEY_TOPS = 'Tops';
export const IMAGE_KEY_STRATIGRAPHIC = 'Stratigraphic';
export const IMAGE_KEY_SEISMIC = 'Seismic';
export const IMAGE_KEY_CORRELATION = 'Correlation';

export default [
  {
    text: 'Well Design',
    key: IMAGE_KEY_DESIGN,
    component: () => import(`@/modules/PostMortem/components/Images/Image${IMAGE_KEY_DESIGN}`),
  },
  {
    text: 'Location Map',
    key: IMAGE_KEY_LOCATION,
    component: () => import(`@/modules/PostMortem/components/Images/Image${IMAGE_KEY_LOCATION}`),
  },
  {
    text: 'Summary',
    key: IMAGE_KEY_SUMMARY,
    component: () => import(`@/modules/PostMortem/components/Images/Image${IMAGE_KEY_SUMMARY}`),
  },
  {
    text: 'Formation Tops',
    key: IMAGE_KEY_TOPS,
    component: () => import(`@/modules/PostMortem/components/Images/Image${IMAGE_KEY_TOPS}`),
  },
  {
    text: 'Stratigraphic Chart',
    key: IMAGE_KEY_STRATIGRAPHIC,
    component: () => import(`@/modules/PostMortem/components/Images/Image${IMAGE_KEY_STRATIGRAPHIC}`),
  },
  {
    text: 'Seismic',
    key: IMAGE_KEY_SEISMIC,
    component: () => import(`@/modules/PostMortem/components/Images/Image${IMAGE_KEY_SEISMIC}`),
  },
  {
    text: 'Cross Section',
    key: IMAGE_KEY_CORRELATION,
    component: () => import(`@/modules/PostMortem/components/Images/Image${IMAGE_KEY_CORRELATION}`),
  },
];
