<template>
  <v-tabs
    v-model="content"
    :center-active="false"
    background-color="transparent"
  >
    <v-tabs-slider color="transparent"></v-tabs-slider>
    <v-tab
      v-for="(name, i) in resource.data.pillars.names"
      :key="i"
      link
    >
      <span class="text-capitalize" v-text="name"></span>
    </v-tab>

    <v-tabs-items v-model="content" background-color="transparent">
      <v-tab-item
        v-for="(content, i) in resource.data.pillars.contents"
        :key="i"
      >
        <v-card class="mt-4" flat color="transparent">
          <v-row>
            <v-col cols="auto">
              <v-avatar size="36" color="grey lighten-3">
                <v-icon small color="grey" v-text="content.count"></v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="11">
              <h3 class="mb-6" v-text="content.title"></h3>
              <v-row justify="space-between">
                <v-col cols="12" md="4">
                  <div v-html="content.description"></div>
                </v-col>
                <v-col cols="12" md="7" offset-md="1">
                  <v-img :src="content.image"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import About from '@/modules/About/resources/About';

export default {
  data: () => ({
    resource: new About,
    content: null,
  }),
};
</script>
