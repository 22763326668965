import { KNOWLEDGE_GRAPH_URL, FETCH_EGO_GRAPH_URL } from '@/modules/Search/api/knowledgeGraph';
import { FIND_DATALAKE_DATA_URL } from '@/modules/Dashboard/api/datalake';
import { FIND_WELL_INFO_URL } from '@/modules/Dashboard/api/wells';
import DataResource from '@core/resources/DataResource';
import Datalake from '@/modules/Dashboard/resources/Datalake';
import { isNil, uniq } from 'lodash';
import * as d3 from 'd3';

export default class KnowledgeGraph extends DataResource {
  constructor (options = {}) {
    super(options);
    this.id = options.id;
    this.kgType = options.kgType;
    this.kgMode = options.kgMode;
    this.svgId = options.svgId;
    this.degreeRange = options.degreeRange;
    this.weightRange = options.weightRange;
    this.width = options.width;
    this.height = options.height;
    this.currentSelection = options.currentSelection || '';
    this.previousSelection = options.previousSelection || '';
    this.nodeLabelAttribute = options.nodeLabelAttribute;
    this.flyToScale = options.flyToScale;
    this.datalake = new Datalake();
    this.nodeSize = { center: 20, cluster: 10 };
  }

  async list (kgType) {
    this.startLoading();

    const params = {
      projects_list: this.getProjectIDs(),
      kg_type: kgType,
    };

    const { data } = await this.axios.get(KNOWLEDGE_GRAPH_URL, { params });

    this.setData(this.aggregateData(data.data));

    this.stopLoading();
  }

  getProjectIDs () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).toString();
  }

  aggregateData (data) {
    const degreeDomain = [ 0, 0 ];
    let links = [];
    let nodes = [];
    const weightDomain = [ 0, 0 ];

    data.forEach(kg => {
      degreeDomain[0] = degreeDomain[0] > kg.attributes.data.degreeDomain[0]
        ? kg.attributes.data.degreeDomain[0]
        : degreeDomain[0] === 0
          ? kg.attributes.data.degreeDomain[0]
          : degreeDomain[0];
      degreeDomain[1] = degreeDomain[1] < kg.attributes.data.degreeDomain[1]
        ? kg.attributes.data.degreeDomain[1]
        : degreeDomain[1] === 1
          ? kg.attributes.data.degreeDomain[1]
          : degreeDomain[1];

      links = links.concat(kg.attributes.data.links);
      nodes = nodes.concat(kg.attributes.data.nodes);

      weightDomain[0] = weightDomain[0] > kg.attributes.data.weightDomain[0]
        ? kg.attributes.data.weightDomain[0]
        : weightDomain[0] === 0
          ? kg.attributes.data.weightDomain[0]
          : weightDomain[0];
      weightDomain[1] = weightDomain[1] < kg.attributes.data.weightDomain[1]
        ? kg.attributes.data.weightDomain[1]
        : weightDomain[1] === 1
          ? kg.attributes.data.weightDomain[1]
          : weightDomain[1];
    });

    return {
      degreeDomain,
      links,
      nodes,
      weightDomain,
    };
  }

  setData (data) {
    this.data = data;
    this.setNodesData();
    this.setLinksData();
    this.setDegreeDomain();
    this.setWeightDomain();
  }

  async initializeEgo (docRef) {
    this.startLoading();
    this.resetKG();

    await this.fetchEgoGraph(docRef);

    if (this.linksData.length > 0) {
      this.setStrokeWidthScale();
      this.setSizeScale();
      this.setToolTip();
      this.selectSVGContainer();
      this.createSvgGroup();

      this.setSimulation();

      this.appendLinksToG();
      this.setLinkAttributes();
      this.addEventsListenerToLinks();

      this.appendNodesToG();
      this.setNodeAttributes();
      this.addEventsListenerToNodes();

      this.appendTextToG();
      this.setTextAttributes();

      this.setZoomHandler();

      this.onUpdate();
    }

    this.stopLoading();
  }

  async initialize (kgType) {
    this.resetKG();
    this.startLoading();

    await this.list(kgType);

    this.setStrokeWidthScale();
    this.setSizeScale();
    this.setToolTip();
    this.selectSVGContainer();
    this.createSvgGroup();

    this.setSimulation();

    this.appendLinksToG();
    this.setLinkAttributes();
    this.addEventsListenerToLinks();

    this.appendNodesToG();
    this.setNodeAttributes();
    this.addEventsListenerToNodes();

    this.appendTextToG();
    this.setTextAttributes();

    this.setZoomHandler();

    this.onUpdate();

    this.stopLoading();

    this.simulation.stop();
    this.simulation.restart();
    this.simulation.tick([ 0 ]);
  }

  setNodesData () {
    this.nodesData = this.data.nodes;
  }

  setLinksData () {
    this.linksData = this.data.links;
  }

  setDegreeDomain () {
    this.degreeDomain = [ ...this.data.degreeDomain ];
  }

  setWeightDomain () {
    this.weightDomain = [ ...this.data.weightDomain ];
  }

  setSimulation () {
    this.simulation = d3.forceSimulation();

    this.simulation.nodes(this.nodesData);

    this.setForces();

    // this.simulation.on('end', console.log('end of simulation'));

    // add links to simulation
    this.simulation.force('link').links(this.linksData);

    // run 200 iterations of the simulation
    this.simulation.tick([ this.meta.simulation.tick ]);

    // set simulation alpha to 1
    this.simulation.alpha([ this.meta.simulation.alpha ]);
    // this.simulation.alphaMin([ 0.5 ]);
    this.simulation.alphaDecay([ 1 ]);
  }

  setStrokeWidthScale () {
    // creates a linear scale to compute relative link/edge sizes
    this.strokeWidthScale = d3
      .scaleLinear()
      .domain(this.weightDomain) // accepts inputs between [x1,x2]
      .range([ ...this.weightRange ]); // maps outputs to [7px,20px]
  }

  setSizeScale () {
    // creates a linear scale to compute relative node sizes
    if (this.degreeDomain[1] > this.degreeDomain[0]) {
      this.sizeScale = d3
        .scaleLinear()
        .domain(this.degreeDomain) // accepts inputs between [minAdjacentNodes,maxAdjacentNodes]
        .range([ ...this.degreeRange ]); // maps outputs to [7px,25px]
    }
  }

  setToolTip () {
    this.tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);
  }

  setForces () {
    this.simulation.force('charge', d3.forceManyBody().strength(this.meta.simulation.forces.manyBody))
      .force(
        'link',
        d3
          .forceLink()
          .id(d => d.id)
          .distance([ this.meta.simulation.forces.link.distance ])
          .iterations([ this.meta.simulation.forces.link.iterations ]),
      )
      .force('center', d3.forceCenter(this.width / 2, this.height / 2))
      .force(
        'collision',
        d3.forceCollide([ this.meta.simulation.forces.collide.force ])
          .strength([ this.meta.simulation.forces.collide.strength ])
          .iterations([ this.meta.simulation.forces.collide.iterations ]),
      );
  }

  selectSVGContainer () {
    this.svg = d3.select(`#${this.svgId}`);
    this.svg.attr('width', this.width);
    this.svg.attr('height', this.height);
    // this.width = +this.svg.attr('width');
    // this.height = +this.svg.attr('height');
  }

  createSvgGroup () {
    this.g = this.svg.append('g').attr('class', 'everything');
  }

  appendLinksToG () {
    this.link = this.g
      .append('g')
      .selectAll('line')
      .data(this.linksData)
      .enter()
      .append('line');
  }

  setLinkAttributes () {
    this.link.attr('class', 'link')
      .attr('id', d => {
        if (d.source.id) {
          return `A_${d.source.id.replace(/[\W_]+/g, '_')}_A_${d.target.id.replace(/[\W_]+/g, '_')}_link`;
        }
        return `A_${d.source.replace(/[\W_]+/g, '_')}_A_${d.target.replace(/[\W_]+/g, '_')}_link`;
      })
      .attr('stroke-width', d => this.strokeWidthScale(d.value))
      .attr('stroke', this.meta.style.default_link_color);
  }

  addEventsListenerToLinks () {
    this.link.on('mouseenter', (event, d) => {
      // behaviour when mouse enters the link
      // if either of the target and source well is also the current well
      if (
        (d.target.id === this.currentSelection
        || d.source.id === this.currentSelection) && this.kgMode === 'full'
      ) {
        d3.select(`#${event.target.id}`)
          .attr('stroke', 'red')
          .attr('stroke-width', d => this.strokeWidthScale(d.value) + 5);

        d3.select(
          `#A_${ d.source.id.replace(/[\W_]+/g, '_') }_label`,
        )
          .selectAll('text')
          .style('font-size', '20px')
          .style('font-weight', '700')
          .style('fill', 'black')
          .style('stroke', 'white')
          .style('stroke-width', '0.2px');

        d3.select(
          `#A_${ d.target.id.replace(/[\W_]+/g, '_') }_label`,
        )
          .selectAll('text')
          .style('font-size', '20px')
          .style('font-weight', '700')
          .style('fill', 'black')
          .style('stroke', 'white')
          .style('stroke-width', '0.2px');

        d3.select(
          `#A_${ d.target.id.replace(/[\W_]+/g, '_') }_circle`,
        )
          .attr('fill', 'red')
          .attr('stroke-width', this.meta.style.highlight_node_stroke_width);

        d3.select(
          `#A_${ d.source.id.replace(/[\W_]+/g, '_') }_circle`,
        )
          .attr('fill', 'red')
          .attr('stroke-width', this.meta.style.highlight_node_stroke_width);
      }
    })
      .on('mouseout', (event, d) => {
        // behaviour when mouse is out the link
        // if either of the target and source well is also the current well
        if (
          (d.target.id === this.currentSelection
          || d.source.id === this.currentSelection) && this.kgMode === 'full'
        ) {
          d3.select(`#${event.target.id}`)
            .attr('stroke', this.meta.style.highlight_link_color)
            .attr('stroke-width', d => this.strokeWidthScale(d.value));

          if (d.target.id === this.currentSelection) {
            d3.select(
              `#A_${ d.target.id.replace(/[\W_]+/g, '_') }_circle`,
            )
              .attr('fill', 'red')
              .attr('stroke-width', this.meta.style.highlight_node_stroke_width);
          } else {
            d3.select(
              `#A_${ d.target.id.replace(/[\W_]+/g, '_') }_circle`,
            )
              .attr('fill', this.meta.style.highlight_node_color)
              .attr('stroke-width', this.meta.style.default_node_stroke_width);
          }

          if (d.source.id === this.currentSelection) {
            d3.select(
              `#A_${ d.source.id.replace(/[\W_]+/g, '_') }_circle`,
            )
              .attr('fill', 'red')
              .attr('stroke-width', this.meta.style.highlight_node_stroke_width);
          } else {
            d3.select(
              `#A_${ d.source.id.replace(/[\W_]+/g, '_') }_circle`,
            )
              .attr('fill', this.meta.style.highlight_node_color)
              .attr('stroke-width', this.meta.style.default_node_stroke_width);
          }
        }
      });
  }

  appendNodesToG () {
    this.node = this.g
      .append('g')
      .selectAll('node')
      .data(this.nodesData)
      .enter()
      .append('g');
    // .append('node');
    // there might be a problem here later
  }

  setDragHandler () {
    this.dragHandler = d3
      .drag()
      .on('start', (event, d) => { this.dragStarted(event, d); })
      .on('drag', (event, d) => { this.dragged(event, d); })
      .on('end', (event, d) => { this.dragEnded(event, d); });
  }

  setNodeAttributes () {
    this.node
      .attr('class', 'node')
      .attr('id', d => {
        const transformedWellName = `A_${ d.id.replace(/[\W_]+/g, '_')}`;
        return transformedWellName;
      })
      .call(
        // refactor to just call the drag handler
        d3
          .drag()
          .on('start', (event, d) => { this.dragStarted(event, d); })
          .on('drag', (event, d) => { this.dragged(event, d); })
          .on('end', (event, d) => { this.dragEnded(event, d); }),
      );

    // this.dragHandler(this.node);

    this.node
      .append('circle')
      .attr('r', d => {
        if (this.degreeDomain[1] > this.degreeDomain[0] && this.nodesData.length > 1) {
          return this.sizeScale(d.size);
        }
        return this.nodeSize.cluster;
      })
      .attr('fill', d => {
        if (d.id === this.currentSelection && this.kgMode === 'ego') {
          return 'red';
        }
        return this.meta.style.default_node_color;
      })
      // .attr('fill', this.meta.style.default_node_color)
      .attr('id', d => {
        const transformedWellName = `A_${ d.id.replace(/[\W_]+/g, '_')}`;
        return `${transformedWellName }_circle`;
      })
      .attr('title', d => d.id)
      .attr('alt', d => d.id)
      .attr('stroke', this.meta.style.default_link_color)
      .attr('stroke-width', d => {
        if (d.id === this.currentSelection && this.kgMode === 'ego') {
          return this.meta.style.highlight_node_stroke_width;
        }
        return this.meta.style.default_node_stroke_width;
      })
      .style('cursor', 'pointer');
  }

  addEventsListenerToNodes () {
    this.node
      .on('dblclick', d => this.releaseNode(d))
      .on('mouseover', (event, d) => {
        // node behaviour when mouseover

        // if node id is not equal to current well
        if (d.id !== this.currentSelection) {
          d3.select(`#${event.target.id}`)
            .attr('fill', this.meta.style.default_node_color)
            .attr('stroke-width', this.meta.style.highlight_node_stroke_width);
        }

        // if node id is equal to current well
        if (d.id === this.currentSelection) {
          d3.select(`#${event.target.id}`)
            .attr('fill', 'red')
            .attr('stroke-width', this.meta.style.highlight_node_stroke_width + 20);
        }

        // d3.select(`#A_${d.id.replace(/[\W_]+/g, '_') }_label`)
        //   .selectAll('text')
        //   .text(d => d.attributes[this.nodeLabelAttribute]);
      })
      .on('mouseout', (event, d) => {
        // node behaviour when mouse is out of the node

        // if node id is not equal to current well
        if (d.id !== this.currentSelection) {
          d3.select(`#${event.target.id}`).attr('stroke-width', this.meta.style.default_node_stroke_width);
        } else {
          d3.select(`#${event.target.id}`).attr(
            'stroke-width',
            this.meta.style.highlight_node_stroke_width,
          );
        }

        // d3.select(`#A_${d.id.replace(/[\W_]+/g, '_') }_label`)
        //   .selectAll('text')
        //   .text(d => this.setNodeText(d));
      });

    // when a node is clicked a search is triggered
    this.node.on('click', (event, d) => {
      const query = `"${d.attributes[this.nodeLabelAttribute]}"`;

      // update search with node associated well name
      this.store.dispatch('knowledgeGraph/setKgSearchQuery', query);
      // commented for now
      if (this.kgMode === 'full') {
        this.filter(d.id);
        this.onUpdate();

        d3.select(`#A_${ d.id.replace(/[\W_]+/g, '_') }_circle`)
          .attr('fill', 'red')
          .attr('stroke-width', this.meta.style.highlight_node_stroke_width);

        if (this.previousSelection !== '' && this.previousSelection !== d.id) {
          d3.select(
          `#A_${ this.previousSelection.replace(/[\W_]+/g, '_') }_circle`,
          )
            .attr('fill', this.meta.style.highlight_node_color)
            .attr('stroke-width', this.meta.style.default_node_stroke_width);

          d3.select(
          `#A_${ this.previousSelection.replace(/[\W_]+/g, '_') }_circle`,
          )
            .attr('fill', this.meta.style.highlight_node_color)
            .attr('stroke-width', this.meta.style.default_node_stroke_width);
        }

        this.previousSelection = d.id;
      } else {
        this.resetKG();
        this.initializeEgo(d.id);
      }
    });
  }

  appendTextToG () {
    this.text = this.g
      .append('g')
      .selectAll('g')
      .data(this.nodesData)
      .enter()
      .append('g');
  }

  setTextAttributes () {
    for (let j = 0; j < 5; j++) {
      this.text
        .attr('class', 'labels')
        .attr('id', d => {
          const transformedWellName = `A_${ d.id.replace(/[\W_]+/g, '_')}`;
          return `${transformedWellName }_label_${j}`;
        })
        .append('text')
        .attr('x', 20)
        .attr('y', `${1 * (j + 1)}em`)
        .style('font-family', 'sans-serif')
        .style('font-size', '15px')
        .style('font-weight', '400')
        .style('fill', 'black')
        .style('stroke', 'white')
        .style('stroke-width', '0.2px')
        .style('pointer-events', 'none')
        .text(d => this.setNodeText(d, j));
    }
  }

  setNodeText (d, titleIndex) {
    const title = d.attributes[this.nodeLabelAttribute].split(' ');
    const lineLength = 5;
    // count tokens
    const titlesSplit = [];
    // split to 3 lines
    for (let i = 0; i < lineLength; i++) {
      const slicedTitle = title.slice(i * 6, 6 * i + 6) ? title.slice(i * 6, 6 * i + 6) : [];
      titlesSplit.push(slicedTitle);
    }
    return titlesSplit[titleIndex].join(' ');
  }

  setZoomHandler () {
    this.zoom = d3
      .zoom()
      .on('zoom', event => this.zoomActions(event));

    this.zoom(this.svg);
  }

  dragStarted (event, d) {
    if (!event.active) { this.simulation.alphaTarget(0.3).restart(); }
    d.fx = d.x;
    d.fy = d.y;
  }

  dragged (event, d) {
    d.fx = event.x;
    d.fy = event.y;
  }

  dragEnded (event) {
    if (!event.active) { this.simulation.alphaTarget(0); }
  }

  releaseNode (_event, d) {
    d.fx = null;
    d.fy = null;
  }

  zoomActions (event) {
    d3.select('.everything').attr('transform', event.transform);
  }

  isConnected (a, b) {
    const linkedByIndex = {};
    this.linksData.forEach(d => {
      linkedByIndex[`${d.source.index},${d.target.index}`] = 1;
    });

    return (
      linkedByIndex[`${a.index},${b.index}`]
      || linkedByIndex[`${b.index},${a.index}`]
      || a.index === b.index
    );
  }

  isNodeInKG (key) {
    const isInKG = this.nodesData.filter(item => item.id === key);

    return isInKG.length === 1;
  }

  filter (key) {
    let d = null;
    let uniqueNodeIds = [];
    let uniqueNodeObjs = [];

    if (this.node != null) {
      const nodeGs = this.node._groups[0];
      nodeGs.forEach(node => {
        const name = node.__data__.id;
        if (name === key) {
          d = node.__data__;
        }
      });

      this.link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);
    }

    const currNodeIds = [];
    if (this.link != null) {
      this.link
        .attr('stroke', o => {
          if (o.source.id === d.id || o.target.id === d.id) {
            return this.meta.style.highlight_link_color;
          }
          return this.meta.style.default_link_color;
        })
        .attr('stroke-width', o => {
          if (o.source.id === d.id || o.target.id === d.id) {
            return this.strokeWidthScale(o.value);
          }
          return this.strokeWidthScale(o.value);
        })
        .attr('stroke-opacity', o => {
          if (o.source.id === d.id || o.target.id === d.id) {
            return 1;
          }
          return this.meta.style.highlight_trans;
        })
        .style('opacity', o => {
          if (o.source.id === d.id || o.target.id === d.id) {
            currNodeIds.push(o.source.id);
            currNodeIds.push(o.target.id);
            return 1;
          }
          return this.meta.style.highlight_trans;
        });

      // with network
      uniqueNodeIds = [ ...new Set(currNodeIds) ];
      uniqueNodeObjs = [];
      if (uniqueNodeIds.length > 0) {
        uniqueNodeObjs = [];
      } else {
        uniqueNodeIds = [ key ];
        uniqueNodeObjs = [ d ];
      }

      this.link
        .style('cursor', 'pointer')
        .on('click', (event, o) => {
          const sourceIncluded = uniqueNodeIds.includes(o.source.id);
          const targetIncluded = uniqueNodeIds.includes(o.target.id);

          if ((sourceIncluded || targetIncluded) && this.nodeLabelAttribute === 'well_name') {
            const query = `"${o.source.id}" AND "${o.target.id}"`;

            // update search with node associated well name
            this.store.dispatch('knowledgeGraph/setKgSearchQuery', query);
            // commented for now

            d3.select(`#${event.target.id}`)
              .attr('stroke', this.meta.style.highlight_link_color)
              .attr('stroke-width', d => this.strokeWidthScale(d.value));

            d3.select(
              `#A_${ o.source.id.replace(/[\W_]+/g, '_') }_label`,
            )
              .selectAll('text')
              .style('font-size', '0.7em')
              .style('font-weight', '400')
              .style('fill', 'black')
              .style('stroke', 'white')
              .style('stroke-width', '0px');

            d3.select(
              `#A_${ o.target.id.replace(/[\W_]+/g, '_') }_label`,
            )
              .selectAll('text')
              .style('font-size', '0.7em')
              .style('font-weight', '400')
              .style('fill', 'black')
              .style('stroke', 'white')
              .style('stroke-width', '0px');

            // if (o.target.id == app.currentSelection) {
            //   d3.select(
            //     "#" + "A_" + o.target.id.replace(/[\W_]+/g, "_") + "_circle"
            //   )
            //     .attr("fill", "red")
            //     .attr("stroke-width", app.highlight_node_stroke_width);
            // } else {
            //   d3.select(
            //     "#" + "A_" + o.target.id.replace(/[\W_]+/g, "_") + "_circle"
            //   )
            //     .attr("fill", app.highlight_node_color)
            //     .attr("stroke-width", app.default_node_stroke_width);
            // }

            // if (o.source.id == app.currentSelection) {
            //   d3.select(
            //     "#" + "A_" + o.source.id.replace(/[\W_]+/g, "_") + "_circle"
            //   )
            //     .attr("fill", "red")
            //     .attr("stroke-width", app.highlight_node_stroke_width);
            // } else {
            //   d3.select(
            //     "#" + "A_" + o.source.id.replace(/[\W_]+/g, "_") + "_circle"
            //   )
            //     .attr("fill", app.highlight_node_color)
            //     .attr("stroke-width", app.default_node_stroke_width);
            // }
          }
        });

      this.node
        .attr('opacity', o => {
          if (uniqueNodeIds.indexOf(o.id) !== -1) {
            uniqueNodeObjs.push(o);
            return 1;
          }
          return this.meta.style.highlight_trans;
        })
        .attr('stroke', o => {
          if (uniqueNodeIds.indexOf(o.id) !== -1) {
            return this.meta.style.highlight_link_color;
          }
          return this.meta.style.default_link_color;
        })
        .style('pointer-events', o => {
          if (uniqueNodeIds.indexOf(o.id) !== -1) {
            return 'auto';
          }
          return 'none';
        });

      this.node
        .selectAll('circle')
        .attr('fill', o => {
          if (uniqueNodeIds.indexOf(o.id) !== -1) {
            return this.meta.style.highlight_node_color;
          }
          return this.meta.style.default_node_color;
        })
        .attr('stroke', o => {
          if (uniqueNodeIds.indexOf(o.id) !== -1) {
            return this.meta.style.highlight_link_color;
          }
          return this.meta.style.default_link_color;
        })
        .style('pointer-events', o => {
          if (uniqueNodeIds.indexOf(o.id) !== -1) {
            return 'auto';
          }
          return 'auto';
          // return "none";
        });
    }

    this.text.style('font-size', o => {
      if (uniqueNodeIds.indexOf(o.id) !== -1) {
        return '20px';
      }
      return '10px';
    });

    let xS = 0;
    let yS = 0;
    let xAve = 0;
    let yAve = 0;

    uniqueNodeObjs.forEach(uniqueNode => {
      xS += uniqueNode.x;
      yS += uniqueNode.y;
    });

    xAve = xS / uniqueNodeObjs.length;
    yAve = yS / uniqueNodeObjs.length;

    this.g
      .transition()
      .duration(750)
      .attr(
        'transform',
        `translate(${
          this.width / 2
           },${
           this.height / 2
           })scale(${
           this.flyToScale
           })translate(${
           -xAve }${3000
           },${
           -yAve }${3000
           })`,
      )
      .style('stroke-width', `${2 / 4 }px`);

    const transform = d3.zoomIdentity
      .translate(this.width / 2, this.height / 2)
      .scale(this.flyToScale)
      .translate(-xAve, -yAve);

    this.svg.call(this.zoom.transform, transform);
  }

  onTick () {
    this.link
      .attr('x1', d => d.source.x)
      .attr('y1', d => d.source.y)
      .attr('x2', d => d.target.x)
      .attr('y2', d => d.target.y);

    this.node.attr('transform', d => `translate(${d.x},${d.y})`);

    this.text.attr('transform', d => `translate(${d.x},${d.y})`);
  }

  onUpdate () {
    if (this.node != null) {
      // UPDATE
      this.node = this.node.data(
        this.nodesData,
      );
      // EXIT
      this.node.exit().remove();

      this.node.selectAll('nodes');
    }

    // UPDATE
    this.link = this.link.data(
      this.linksData,
    );
    // EXIT
    this.link.exit().remove();

    // UPDATE
    this.text = this.text.data(
      this.nodesData,
    );
    // EXIT
    this.text.exit().remove();

    // update simulation nodes, links, and alpha
    this.simulation
      .nodes(this.nodesData)
      .on('tick', () => {
        this.onTick();
      });

    this.simulation
      .force('link')
      .links(this.linksData);

    // this.simulation
    //   .on('tick', () => {
    //     this.onTick();
    //   });
  }

  async fetchEgoGraph (docRef) {
    this.resetKG();
    const params = {
      doc_ref__id: docRef,
    };
    const { data } = await this.axios.get(FETCH_EGO_GRAPH_URL, { params });
    this.linksData = data.data[0] ? data.data[0].attributes.edges : [];
    if (this.linksData.length > 0) {
      await this.listEgo();
      this.store.dispatch('knowledgeGraph/hideNoNodeSnackbar');
    } else {
      const info = await this.datalake.findPublicationInfo(docRef);
      const soloNode = [{
        id: info.attributes.doc_ref,
        size: 10,
        degree: 1,
        attributes: {
          index: 1,
          doc_ref: info.attributes.doc_ref,
          article_name: info.attributes.article_title,
        },
      }];
      this.data.nodes = soloNode;
      this.nodesData = soloNode;
      this.data.links = [];
      this.linksData = [];
      this.data.degreeDomain = [ 1, 1 ];
      this.degreeDomain = [ 1, 1 ];
      this.weightDomain = [ 1, 1 ];

      this.setStrokeWidthScale();
      this.setSizeScale();
      this.setToolTip();
      this.selectSVGContainer();
      this.createSvgGroup();

      this.setSimulation();

      this.appendLinksToG();
      this.setLinkAttributes();
      this.addEventsListenerToLinks();

      this.appendNodesToG();
      this.setNodeAttributes();
      this.addEventsListenerToNodes();

      this.appendTextToG();
      this.setTextAttributes();

      this.setZoomHandler();

      this.onUpdate();

      // this.resetKG();
      // this.store.dispatch('search/showNoNodeSnackbar');
    }
  }

  async listEgo () {
    let nodes = []; let links = []; let degreeDomain = []; let
      weightDomain = [];
    if (this.linksData) {
      links = this.linksData;
      nodes = await this.buildNodesData(this.linksData);
      weightDomain = this.buildWeightDomain(this.linksData);
      degreeDomain = this.buildDegreeDomain();
    } else {
      links = [];
      nodes = [];
      weightDomain = [];
      degreeDomain = [];
    }

    const data = {
      nodes, links, weightDomain, degreeDomain,
    };

    this.setData(data);
  }

  buildWeightDomain (edges) {
    const allWeights = edges.map(edge => edge.value);
    const weightDomain = [ Math.min(...allWeights), Math.max(...allWeights) ];
    this.weightDomain = weightDomain;
    this.data.weightDomain = weightDomain;
    return weightDomain;
  }

  buildDegreeDomain () {
    const degrees = this.nodesData.map(node => node.degree);
    const minDegrees = Math.min(...degrees);
    const maxDegrees = Math.max(...degrees);
    const degreeDomain = [ minDegrees, maxDegrees ];
    this.degreeDomain = degreeDomain;
    this.data.degreeDomain = degreeDomain;
    return degreeDomain;
  }

  computeDegree (edges, id) {
    const nodeAsSource = edges.filter(edge => edge.source === id);
    const nodeAsTarget = edges.filter(edge => edge.target === id);
    return nodeAsSource.length + nodeAsTarget.length;
  }

  async buildNodesData (edges) {
    const nodes = [];
    const allTarget = uniq(edges.flatMap(edge => edge.target));
    const allSource = uniq(edges.flatMap(edge => edge.source));
    const allNodes = uniq(allTarget.concat(allSource));

    if (this.kgType === 'publications') {
      const allNodesWithAttributes = allNodes.map(async node => {
        const info = await this.datalake.findPublicationInfo(node);
        const degree = this.computeDegree(edges, info.attributes.doc_ref);
        nodes.push({
          id: info.attributes.doc_ref,
          size: degree,
          degree,
          attributes: {
            index: nodes.length,
            doc_ref: info.attributes.doc_ref,
            article_name: info.attributes.article_title,
          },
        });

        return {
          id: info.attributes.doc_ref,
          size: degree,
          degree,
          attributes: {
            index: nodes.length,
            doc_ref: info.attributes.doc_ref,
            article_name: info.attributes.article_title,
          },
        };
      });
      const allPromisedNodes = await Promise.all(allNodesWithAttributes);
      this.data.nodes = allPromisedNodes;
      this.nodesData = allPromisedNodes;
    }

    // else {
    //   // TO DO: well conditions for ego graph

    // }
    return nodes;
  }

  resetKG () {
    if (this.simulation) {
      this.simulation.stop();
    }

    if (this.svg) {
      const svg = document.getElementById(this.svgId);
      if (svg !== null) {
        svg.innerHTML = null;
      }
      this.svg = null;
      this.g = null;
      this.node = null;
      this.link = null;
      this.text = null;
      this.simulation = null;
    }
  }

  async fetchWellRelationship (docRef) {
    if (!isNil(docRef)) {
      const { data } = await this.axios.get(FIND_DATALAKE_DATA_URL(docRef));
      const wellRelationship = data.data?.relationships?.well_info?.data;
      let well;
      if (wellRelationship.length > 0) {
        const wellId = wellRelationship[0].id;
        const wellData = await this.axios.get(FIND_WELL_INFO_URL(wellId));
        well = wellData.data.data.attributes.well_name;
      }

      return well;
    }

    return {};
  }
}
