import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LocaleManifest from '@core/manifests/LocaleManifest';

Vue.use(VueI18n);

Vue.prototype.$trans_replace = function (text, fields = {}) {
  const replaceables = Object.keys(fields);

  for (let i = replaceables.length - 1; i >= 0; i--) {
    text = text.replace(new RegExp(`{${replaceables[i]}}`, 'gi'), fields[replaceables[i]]);
  }

  return text;
};

Vue.prototype.$trans = function (text, fields = {}) {
  return this.$te(text) ? this.$t(text, fields) : this.$trans_replace(text, fields);
};

Vue.prototype.$trans_choice = function (text, count = 1, fields = {}) {
  return this.$te(text) ? this.$tc(text, count, fields) : this.$trans_replace(text, fields);
};

function loadLocaleMessages () {
  return LocaleManifest.getItems();
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  messages: loadLocaleMessages(),
});
