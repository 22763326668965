var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('meta-tag',{attrs:{"title":_vm.$trans('Change Password')}}),_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","xl":"3","lg":"4","md":"4","sm":"10"}},[_c('app-brand-banner',{staticClass:"mx-auto text-center ma-4",attrs:{"width":"320"}}),_c('validation-observer',{ref:"form:signin",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"disabled":_vm.form.loading,"autocomplete":"off","method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}},model:{value:(_vm.form.valid),callback:function ($$v) {_vm.$set(_vm.form, "valid", $$v)},expression:"form.valid"}},[_c('v-card',{staticClass:"mt-10"},[_c('v-card-title',[_c('h1',[_vm._v(_vm._s(_vm.$trans('Change Password')))])]),_c('v-card-text',[_c('p',{staticClass:"heading text-left"},[_vm._v("In order to protect your account, we recommend your new password:")]),_c('ul',{staticClass:"text-left mb-10"},[_c('li',[_vm._v("Is longer than 6 characters.")]),_c('li',[_vm._v("Is not a common password pattern.")]),_c('li',[_vm._v("Does not match or significantly contain your username or email.")])]),_c('validation-provider',{attrs:{"name":"password","mode":"aggressive","rules":"required|has_uppercase|has_lowercase|has_number|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$trans('New Password'),"autocomplete":"new-password","outlined":"","type":"password","name":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password confirmation","mode":"aggressive","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$trans('Confirm New Password'),"autocomplete":"off","outlined":"","type":"password","name":"password_confirmation"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)}),_c('v-btn',{attrs:{"loading":_vm.form.loading,"disabled":_vm.form.isLoadingOr(invalid),"color":"primary","type":"submit","block":"","x-large":""}},[_vm._v(" "+_vm._s(_vm.$trans('Change Password'))+" ")]),_c('p',{staticClass:"mt-6 mb-0 text-caption text-left"},[_vm._v(" "+_vm._s(_vm.$trans('Have an account?'))+" "),_c('router-link',{staticClass:"text-caption",attrs:{"to":{ name: 'login' }}},[_vm._v(" "+_vm._s(_vm.$trans('Sign in here.'))+" ")])],1)],1)],1)],1)]}}])}),_c('auth-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }