<template>
  <v-simple-table>
    <thead>
      <tr>
        <th colspan="100%" class="text-uppercase" v-text="$trans('Depth Info')"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td v-text="$trans('Water Depth')"></td>
        <td class="text-right" v-text="`${item.water_depth}m`"></td>
      </tr>
      <tr>
        <td v-text="$trans('Derrick Floor Elevation')"></td>
        <td class="text-right" v-text="`${item.derrick_floor_elev}m`"></td>
      </tr>
      <tr>
        <td v-text="$trans('Rotary Table')"></td>
        <td class="text-right" v-text="`${item.rotary_table}m`"></td>
      </tr>
      <tr>
        <td v-text="$trans('Kelly Bushing')"></td>
        <td class="text-right" v-text="`${item.kelly_bushing}m`"></td>
      </tr>
      <tr>
        <td v-text="$trans('TVDSS (subseas)')"></td>
        <td class="text-right" v-text="`${item.tvdss}m`"></td>
      </tr>
      <tr>
        <td v-text="$trans('True Vertical Depth (TVD)')"></td>
        <td class="text-right" v-text="`${item.tvd}m`"></td>
      </tr>
      <tr>
        <td v-text="$trans('Total/Measured Depth (TD/MD)')"></td>
        <td class="text-right" v-text="`${item.total_depth}m`"></td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'TableDepthInfo',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
