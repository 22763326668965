<template>
  <v-progress-linear
    v-show="progressbar.loading"
    :indeterminate="progressbar.indeterminate"
    :value="progressbar.progress"
    color="primary"
    fixed
    top
  >
  </v-progress-linear>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      progressbar: 'progressbar/progressbar',
    }),
  },
};
</script>
