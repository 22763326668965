import ImageResource from '@/modules/Image/resources/Image';
import buckets from '@/modules/Image/config/buckets';
import aggregations from '@/modules/Search/config/aggregations';
import filters from '@/modules/Search/config/filters';
import categories from '@/modules/Search/config/categories';
import mapAreaTabs from '@/modules/Search/config/mapAreaTabs';
import { SEARCH_IMAGES_URL } from '@/modules/Image/api/images';
import { values, groupBy, flatten } from 'lodash';
import { supportedQuery, unsupportedQuery } from '@/modules/Search/config/queries';

export default class Image extends ImageResource {
  constructor (options = {}) {
    super(options);

    this.setOptions({
      itemsPerPage: 300,
      ...options.options,
    });

    this.setMeta({
      buckets,
      filters,
      aggregations,
      categories,
      mapAreaTabs,
      supportedQuery,
      dense: false,
      itemsPerRow: 6,
      singleSelect: true,
      mobileBreakpoint: 0,
      hideDefaultFooter: true,
      unsupportedBuckets: unsupportedQuery,
      ...options.meta,
    });
  }

  async listDocText () {
    this.unsetItems();
    this.startLoading();

    const params = this.makeImageSearchParameters({
      q: this.buildQuerySearchParameters(),
      project_id: this.getProjectIds(),
    }, false);

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.setItems(data.data.search_results);

    if (this.isSearching()) {
      this.setMetaBuckets(data.data?.aggregations?.img_tag_histo?.buckets);
      this.setImageChipFromAggregations(data.data?.aggregations);
    }

    this.setPagination({
      total: data.data.total_results,
      perPage: this.options.itemsPerPage,
      itemsPerPage: this.options.itemsPerPage,
    });

    this.stopLoading();
  }

  async listRelatedImages () {
    this.meta.loading = true;

    const params = this.makeImageSearchParameters({
      q: this.buildQuerySearchParameters(),
      project_id: this.getProjectIds(),
    });

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.setRelatedImages(data.data.search_results);

    this.setPagination({
      total: data.data.total_results,
      perPage: this.options.itemsPerPage,
      itemsPerPage: this.options.itemsPerPage,
    });

    this.meta.loading = false;
  }

  async listMoreRelatedImages () {
    this.meta.loading = true;

    const perPage = parseInt(this.pagination.perPage, 10) || 300;
    const pageFrom = parseInt(this.pagination.itemsPerPage, 10) + perPage;

    const params = {
      ...this.makeImageSearchParameters({
        q: this.buildQuerySearchParameters(),
        project_id: this.getProjectIds(),
      }),
      page_from: pageFrom,
    };
    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.meta.relatedImages.concat(data.data.search_results);

    this.setPagination({
      total: data.data.total_results,
      itemsPerPage: pageFrom,
    });

    this.meta.loading = false;
  }

  transformQueryString (query) {
    if (query == null) {
      return query;
    }

    let updatedQuery = '';
    // reparse the query object to do the following
    // every word in the searchbar query except AND/OR will have doc_text
    // example: Figure AND gradient => doc_text:Figure AND doc_text:gradient
    const queryArr = query.split(' ');
    if ((query.indexOf('\'') === -1 && query.indexOf('"') === -1)) {
      queryArr.forEach(item => {
        if (item === 'AND' || item === 'OR') {
          updatedQuery += `${item} `;
        } else {
          updatedQuery += `doc_text:${item} `;
        }
      });
    } else if ((query.indexOf('AND') > -1 || query.indexOf('OR') > -1)) {
      let count = 1;
      queryArr.forEach(item => {
        if (item === 'AND' || item === 'OR') {
          updatedQuery += `${item} `;
          count = 1;
        } else if (count === 1) {
          updatedQuery += `doc_text_wostem:${item} `;
          count -= 1;
        } else {
          updatedQuery += `${item} `;
          count += 1;
        }
      });
    } else {
      updatedQuery = `doc_text_wostem:${query}`;
    }

    return updatedQuery;
  }

  buildQuerySearchParameters (params = {}) {
    const routeQuery = { ...this.getRouteQuery(), ...this.getQueryString(), ...params };
    const query = routeQuery.q;
    // const filters = this.buildSearchFilters();
    const transformedQuery = this.transformQueryString(query);
    const returnVal = [ `${transformedQuery}` ].join(' AND ').replace('()', '');

    return returnVal;
  }

  buildSearchFilters () {
    const { delimiter, selected } = this.meta.filters;
    const filters = values(groupBy(flatten(selected.map(filter => filter.split(delimiter)))
      .map(i => ({ key: i.split(':')[0], value: i })), 'key'))
      .map(i => i.map(j => j.value).join(' OR ')).join(') AND (');

    return `(${filters})`;
  }

  setImageChipFromAggregations (aggregations) {
    this.meta.categories.setChipForImages(aggregations?.img_tag_histo?.buckets ?? []);
  }
}
