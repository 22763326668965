<template>
  <v-carousel
    :show-arrows="false"
    continuous cycle
    hide-delimiter-background
    height="auto"
    class="app-carousel transparent my-15 pb-15"
    v-bind="$attrs"
  >
    <v-carousel-item
      v-for="(slide, i) in slides"
      :key="i"
      class="transparent"
    >
      <v-sheet
        height="100%"
        color="transparent"
      >
        <v-row
          class="fill-height"
          align="start"
          justify="center"
          no-gutters
        >
          <component :is="slide"></component>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'AppCarousel',

  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
