<template>
  <div>Correlation</div>
</template>

<script>
export default {
  name: 'ImageCorrelation',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
