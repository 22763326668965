import StoreState from '@core/resources/StoreState';
import {
  isArray, isNil, max, min, fromPairs,
  mean, isEmpty,
} from 'lodash';

export const state = new StoreState({
  type: null,
  data: [],
  dataset: [],
  labels: [],
  plots: [],
  descriptors: [],
  references: [],
  meta: {
    plotData: [],
    total: 0,
    settings: {},
    currentDescriptor: 'temperature',
    currentSettings: {},
    isFetchingSummary: false,
    range: {
      origMinDepth: null,
      origMaxDepth: null,
      sliderMinDepth: 0,
      sliderMaxDepth: 10000,
      currentMinDepth: null,
      currentMaxDepth: null,
    },
  },
  highlighted: {
    wellId: false,
  },
  isSingle () {
    return this.type === 'single';
  },
  isList () {
    return this.type === 'list';
  },
});

export const getters = {
  ...StoreState.mapDefaultGetters(),
  scatterplot: state => state,
  descriptors: state => state.descriptors,
  references: state => state.references,
  descriptor: state => state.meta.currentDescriptor,
  data: state => state.data,
  meta: state => state.meta,
  settings: state => state.meta.currentSettings,
  highlighted: state => state.highlighted,
  activeWells: state => state.dataset.filter(i => i.active),
};

export const mutations = {
  ...StoreState.mapDefaultMutations(),

  START_FETCH_SUMMARY (state) {
    state.meta.isFetchingSummary = true;
  },

  STOP_FETCH_SUMMARY (state) {
    state.meta.isFetchingSummary = false;
  },

  SET_DATA (state, {
    updateTable,
    data,
    type,
    projects,
    updateRange = false,
    range = {},
    total = 0,
    activeWells = [],
  }) {
    const settings = projects
      .map(i => i.attributes?.project_settings?.WEBGIS_SETTINGS?.scatter_plot_settings)
      .filter(i => !isNil(i)).pop();

    state.type = type;
    state.data = data;
    state.meta.total = total;
    state.meta.settings = settings;
    state.meta.currentSettings = settings?.[state.meta.currentDescriptor] ?? {};
    state.descriptors = isEmpty(settings) ? [] : Object.keys(settings)
      .map(key => ({ value: key, text: settings[key].title, meta: settings[key] }));

    const plots = (isArray(data) ? data : [ data ])
      .map(data => (data?.values ?? [])
        .map(v => ({
          ...fromPairs([ 'x', 'y' ].map((k, i) => ([ k, v[i] ]))),
          name: data.well_name,
          wellId: data.well_id,
        }))).flat();

    state.plots = plots;

    // References
    const references = (isArray(data) ? data : [ data ]).map(i => i.references).flat();
    state.references = references;

    // For DataTable
    if (updateTable) {
      const dataset = (isArray(data) ? data : [ data ]);
      state.dataset = dataset.map(i => ({
        ...i,
        active: activeWells.map(i => i.wellId).includes(i.wellId) ?? false,
        wt: mean((i.values ?? []).map(j => j[0])),
      }));
    }

    // Range
    const yAxes = plots.map(i => i.y);

    // Set UI slider range to min/max
    if (isNil(state.meta.range.origMaxDepth) && updateTable) {
      state.meta.range.sliderMinDepth = min(yAxes);
      state.meta.range.sliderMaxDepth = max(yAxes);
    }

    // Store original/default (not user-defined) range
    if (isNil(state.meta.range.origMaxDepth) && updateTable) {
      state.meta.range.origMinDepth = state.meta.range.sliderMinDepth;
      state.meta.range.origMaxDepth = state.meta.range.sliderMaxDepth;
      state.meta.range.currentMinDepth = state.meta.range.origMinDepth;
      state.meta.range.currentMaxDepth = state.meta.range.origMaxDepth;
    }

    if (updateRange) {
      state.meta.range.sliderMinDepth = range?.min_y ?? min(yAxes);
      state.meta.range.sliderMaxDepth = range?.max_y ?? max(yAxes);
      state.meta.range.origMinDepth = state.meta.range.sliderMinDepth;
      state.meta.range.origMaxDepth = state.meta.range.sliderMaxDepth;
      state.meta.range.currentMinDepth = state.meta.range.origMinDepth;
      state.meta.range.currentMaxDepth = state.meta.range.origMaxDepth;
    }
  },

  SET_CURRENT_DESCRIPTOR (state, descriptor) {
    descriptor = descriptor ?? state.meta.currentDescriptor;
    state.meta.currentDescriptor = descriptor ?? state.meta.currentDescriptor;
    state.meta.currentSettings = state.meta.settings[descriptor];
    // Reset range filters
    state.meta.range.origMinDepth = null;
    state.meta.range.origMaxDepth = null;
    state.meta.range.currentMinDepth = 0;
    state.meta.range.currentMaxDepth = 10000;
  },

  SET_SLIDER_RANGE (state, range) {
    const [ sliderMinDepth, sliderMaxDepth ] = range;

    state.meta.range.sliderMinDepth = sliderMinDepth;
    state.meta.range.sliderMaxDepth = sliderMaxDepth;
  },

  SET_RANGE (state, range) {
    const [ currentMinDepth, currentMaxDepth ] = range;

    state.meta.range.currentMinDepth = currentMinDepth;
    state.meta.range.currentMaxDepth = currentMaxDepth;
  },

  UNSET_RANGE (state) {
    state.meta.range.currentMinDepth = undefined;
    state.meta.range.currentMaxDepth = undefined;
  },

  SET_WELL_ACTIVE (state, well) {
    const oldValue = well.active;
    state.dataset.forEach(i => {
      i.active = false;
    });
    well.active = !oldValue;
  },

  SET_HIGHLIGHTED_WELL (state, { wellId }) {
    state.highlighted.wellId = wellId;
  },
};

export const actions = {
  ...StoreState.mapDefaultActions(),

  startFetchSummary ({ commit }) {
    commit('START_FETCH_SUMMARY');
  },

  stopFetchSummary ({ commit }) {
    commit('STOP_FETCH_SUMMARY');
  },

  setData ({ getters, commit }, data) {
    commit('SET_DATA', { ...data, activeWells: getters.activeWells });
  },

  setCurrentDescriptor ({ commit }, descriptor) {
    commit('SET_CURRENT_DESCRIPTOR', descriptor);
  },

  setRange ({ commit }, range) {
    commit('SET_RANGE', range);
  },

  setSliderRange ({ commit }, range) {
    commit('SET_SLIDER_RANGE', range);
  },

  unsetRange ({ commit }) {
    commit('UNSET_RANGE');
  },

  setWellActive ({ commit }, well) {
    commit('SET_WELL_ACTIVE', well);
  },

  highlightMapWell ({ commit }, point) {
    commit('SET_HIGHLIGHTED_WELL', point);
  },
};

export default {
  namespaced: true,
  name: 'viz/scatterplot',
  state,
  getters,
  mutations,
  actions,
};
