import marked from 'marked';
import { kebabCase, toLower } from 'lodash';

const requireComponent = function (r) {
  return r.keys().map(filePath => {
    const content = marked(r(filePath));
    const fileName = filePath
      .split('/')
      .pop()
      .replace(/\.[^/.]+$/, '')
      .replace(/^\s\.\/(.*)\.\w+$/, '$1')
      .replace(/[0-9]{2}\.\s/, '');
    const slug = kebabCase(toLower(fileName));
    const title = fileName;
    const name = 'documentation';
    const to = { name, hash: `#${slug}` };

    return {
      to,
      slug,
      title,
      content,
      filePath,
      name: JSON.stringify(to),
    };
  });
};

const files = requireComponent(require.context(
  // The relative path of the components folder
  '@',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /(modules\/Datawarehouse\/docs)(\/?)([a-zA-Z0-9-_]+(.*)).*\.md/,
));

export default files;
