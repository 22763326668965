import StoreState from '@core/resources/StoreState';
import store from '@core/store';
import { uniqBy } from 'lodash';

export const state = new StoreState({
  onboarded: JSON.parse(localStorage.getItem('tour/onboarding') || '[]'),
  readScheduledMaintenanceMessage: JSON.parse(localStorage.getItem('tour/readScheduledMaintenanceMessage') || '[]'),
});

export const getters = StoreState.mapDefaultGetters({
  onboarded: state => state.onboarded,
  readScheduledMaintenanceMessage: state => state.readScheduledMaintenanceMessage,
});

export const mutations = StoreState.mapDefaultMutations({
  MARK_AS_DONE (state) {
    const user = store.getters['auth/user'];
    state.onboarded = uniqBy(state.onboarded.concat([{ userId: user.getPrimaryKey(), done: true }]), 'userId');
    localStorage.setItem('tour/onboarding', JSON.stringify(state.onboarded));
  },
  MARK_SCHEDULED_MAINTENANCE_MESSAGE_AS_READ (state) {
    const user = store.getters['auth/user'];
    state.readScheduledMaintenanceMessage = uniqBy(state.readScheduledMaintenanceMessage.concat([{ userId: user.getPrimaryKey(), read: true }]), 'userId');
    localStorage.setItem('tour/readScheduledMaintenanceMessage', JSON.stringify(state.readScheduledMaintenanceMessage));
  },
});

export const actions = StoreState.mapDefaultActions({
  markAsDone ({ commit }) {
    commit('MARK_AS_DONE');
  },
  markScheduledMaintenanceMessageAsRead ({ commit }) {
    commit('MARK_SCHEDULED_MAINTENANCE_MESSAGE_AS_READ');
  },
});

export default {
  namespaced: true,
  name: 'tour/onboarding',
  state,
  getters,
  mutations,
  actions,
};
