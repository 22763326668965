export const PUBLICATIONS_KNOWLEDGE_GRAPH_ID = 'c6c1c9b9-9d68-41ae-b63b-eb47b03e0d17';
export const WELL_KNOWLEDGE_GRAPH_ID = '67a47ff3-7c2e-44a9-93d5-9f397278b4a2';

export const KG_OPTIONS = {
  styles: {
    highlight_node_color: '#EF6C00',
    highlight_link_color: '#095256',
    highlight_trans: 1.0,
    highlight_node_stroke_width: '5px',
    default_node_color: '#EF6C00',
    default_node_stroke_width: '2px',
    default_link_color: '#FFCA28',
  },
};

export default {
  selected: 0,
  items: [
    {
      text: 'Wells',
      key: 'wells',
      active: false,
      id: WELL_KNOWLEDGE_GRAPH_ID,
      component: () => import('@/modules/Search/components/KnowledgeGraphs/WellsKnowledgeGraph'),
    },
    {
      text: 'Publications',
      key: 'publications',
      active: false,
      id: PUBLICATIONS_KNOWLEDGE_GRAPH_ID,
      component: () => import('@/modules/Search/components/KnowledgeGraphs/PublicationsKnowledgeGraph'),
    },
  ],
};
