export const items = [
  {
    key: 'file-formats',
    text: 'File formats',
    permission: 'search_filter_file_formats',
    chips: [
      { text: '.doc/docx', value: 'file_format:doc|file_format:docx' },
      { text: '.xls/xlsx', value: 'file_format:xls|file_format:xlsx' },
      { text: '.ppt/pptx', value: 'file_format:ppt|file_format:pptx' },
      { text: '.txt/csv', value: 'file_format:txt|file_format:csv' },
      { text: 'PDF', value: 'file_format:pdf' },
      { text: 'Images', value: 'file_format:jpeg|file_format:png|file_format:jpg|file_format:tif|file_format:tiff|file_format:bmp' },
      { text: 'Others', value: 'file_format:others' },
    ],
  },
  {
    key: 'scale-formats',
    text: 'Scale',
    permission: 'search_filter_scale',
    chips: [
      { text: 'Well', value: 'scale:well' },
      { text: 'Block', value: 'scale:block' },
      { text: 'Regional', value: 'scale:regional' },
      { text: 'Others', value: 'scale:others' },
    ],
  },
  {
    key: 'discipline-formats',
    text: 'Discipline',
    permission: 'search_filter_discipline',
    chips: [
      { text: 'Drilling', value: 'discipline:drilling' },
      { text: 'Geology', value: 'discipline:geology' },
      { text: 'Geomechanics', value: 'discipline:geomechanics' },
      { text: 'Geophysics', value: 'discipline:geophysics' },
      { text: 'Petrophysics', value: 'discipline:petrophysics' },
      { text: 'Production', value: 'discipline:production' },
      { text: 'Reservoir Engineering', value: 'discipline:reservoir_engineering' },
      { text: 'Mechanical Engineering', value: 'discipline:mechanical_engineering' },
      { text: 'Others', value: 'discipline:others' },
    ],
  },
  {
    key: 'document-classification',
    text: 'Document Classification',
    permission: 'search_filter_doc_class',
    chips: [
      { text: 'Final Well Report', value: 'doc_classification:fwr' },
      { text: 'Analysis', value: 'doc_classification:analysis' },
      { text: 'General Information', value: 'doc_classification:gen_info' },
      { text: 'Measurements', value: 'doc_classification:measurement' },
      { text: 'Reports', value: 'doc_classification:reports' },
      { text: 'Others', value: 'doc_classification:others' },
    ],
  },
  {
    key: 'priority',
    text: 'Priority',
    permission: 'search_filter_priority',
    chips: [
      { text: 'Very High', value: 'priority:very_high' },
      { text: 'High', value: 'priority:high' },
      { text: 'Medium', value: 'priority:medium' },
      { text: 'Low', value: 'priority:low' },
    ],
  },
];

export default {
  items,
  selected: [],
  delimiter: '|',
  isSelected (index) {
    return this.selected && this.selected.includes(index);
  },
  getHash () {
    return `${Date.now()}-${this.selected.length}`;
  },
};
