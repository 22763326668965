import Vue from 'vue';
import { route } from '@core/utils';
import { isObject } from 'lodash';

Vue.mixin({
  /**
   * Register common URL parameters as global variables.
   *
   * @return void
   */
  beforeCreate () {
    /**
     * The URL parameter `:id` assigned to Vue global variable.
     * E.g.
     *   The url
     *     /users/:id
     *   can be accessed as
     *     this.$id
     *   inside components.
     *
     * @type Number
     */
    this.$id = this.$route && Vue.observable(Number(this.$route.params.id));

    /**
     * The URL parameter `:slug` assigned to Vue global variable.
     * E.g.
     *   The url
     *     /posts/:slug
     *   can be accessed as
     *     this.$slug
     *   inside components.
     *
     * @type String
     */
    this.$slug = this.$route && Vue.observable(this.$route.params.slug);
  },

  methods: {

    /**
     * The route wrapper inside Vue instance.
     *
     * @param  {string} name
     * @param  {Object} attributes
     * @param  {Object} options
     * @return {Object}
     */
    route (name, attributes = {}, options = {}) {
      const param = isObject(attributes) ? attributes : { id: attributes };
      const query = Object.assign(name.endsWith('.create') ? {} : {
        from: this.$route.path,
      }, options);

      return route(name, param, query);
    },
  },
});
