import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      token: 'auth/token',
    }),
  },

  created () {
    this.setSession(this.token);
  },

  methods: {
    ...mapActions({
      setSession: 'auth/session',
    }),
  },
};
