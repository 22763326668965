import store from '@core/store';
import { merge } from 'lodash';

const initial = {
  // Toggle
  show: false,

  // Typography
  text: null,
  icon: false,

  // Settings
  timeout: 8000,
  mode: null, // e.g. multi-line, vertical

  // Position
  x: 'center',
  y: 'top',

  // Button
  button: {
    show: true,
    icon: false,
    text: 'Close',
    callback: () => { store.dispatch('snackbar/toggle', { show: false }); },
  },
};

export const state = () => ({
  snackbar: initial,
});

export const getters = {
  snackbar: state => state.snackbar,
  isShowing: state => state.snackbar.show,
};

export const mutations = {
  TOGGLE_SNACKBAR (state, payload) {
    state.snackbar = merge({}, state.snackbar, payload);
  },

  RESET_SNACKBAR (state) {
    state.snackbar = initial;
  },

  SHOW_SNACKBAR (state, payload) {
    state.snackbar = merge({}, state.snackbar, payload, { show: true });
  },

  HIDE_SNACKBAR (state, payload) {
    state.snackbar = merge({}, state.snackbar, payload, { show: false });
  },
};

export const actions = {
  toggle ({ commit }, payload) {
    commit('RESET_SNACKBAR');
    commit('TOGGLE_SNACKBAR', payload);
  },

  show ({ commit }, payload) {
    commit('RESET_SNACKBAR');
    commit('SHOW_SNACKBAR', payload);
  },

  hide ({ commit }, payload) {
    commit('HIDE_SNACKBAR', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
