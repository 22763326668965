<template>
  <v-card
    flat outlined
    disabled
    class="mb-5"
  >
    <v-skeleton-loader
      type="list-item-avatar-three-line"
      class="my-4"
    ></v-skeleton-loader>
  </v-card>
</template>

<script>
export default {
  name: 'FileSubmitForApprovalAlertLoadingState',
};
</script>
