<template>
  <div class="map-viz-layer__control">
    <v-divider></v-divider>
    <h4 class="px-4 pt-4" v-text="$trans('Visualization Layers')"></h4>
    <div class="px-4 mb-4">
      <v-checkbox
        v-for="(item, i) in items" :key="i"
        v-model="selected"
        :label="item.text"
        :value="item.value"
        dense
        hide-details
        single-line
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MapVizLayerControlCard',

  computed: {
    ...mapGetters({
      vizItems: 'viz/viz/items',
    }),

    items () {
      const user = this.$store.getters['auth/user'];

      return this.vizItems.filter(i => user.isPermittedTo(i.permission));
    },

    selected: {
      get () {
        return this.$store.getters['viz/viz/selected'];
      },
      set (selected) {
        this.$store.dispatch('viz/viz/setSelected', selected);
      },
    },
  },
};
</script>
