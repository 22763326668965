<template>
  <v-footer
    v-if="visible"
    app inset
    absolute
    color="workspace"
  >
    <span class="overline" v-html="`${apptitle} &copy; ${appyear}`"></span>
    <v-spacer></v-spacer>
    <span class="caption muted--text" v-text="`${shortname} ${version}`"></span>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      apptitle: 'meta/apptitle',
      appyear: 'meta/appyear',
      version: 'meta/version',
      shortname: 'meta/shortname',
      visible: 'footer/visibility',
    }),
  },
};
</script>
