export default [
  {
    name: 'workflows.petrophysics',
    title: 'Petrophysics',
    description: 'View Petrophysics page',
    parent: 'workflows',
    permission: 'pod_petropod',
    sort: 2,
  },
];
