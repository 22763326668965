var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-item-group',{attrs:{"active-class":"primary"}},[_c('v-layout',[_c('v-spacer'),_vm._l((_vm.items),function(item,i){return [_c('v-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('subscription-plan-item',{staticClass:"mx-2"},[_c('div',{staticClass:"fill-height subscription-plan-group__item",class:{
              'subscription-plan-group__item--featured': item.isFeatured,
            }},[_vm._t("default",[_c('v-card',{staticClass:"fill-height d-flex flex-column pa-5",class:{
                  'active': active,
                },attrs:{"flat":"","width":"350"}},[_c('v-card-title',[_c('v-spacer'),_c('h2',{staticClass:"h2 font-weight-bold ma-0",domProps:{"textContent":_vm._s(item.title)}}),_c('v-spacer')],1),_c('v-card-text',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"mb-5"},[(item.price)?_c('div',{staticClass:"display-2 font-weight-bold",domProps:{"textContent":_vm._s(item.price)}}):_c('div',{staticClass:"py-6 mt-1"}),(item.overline)?_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(item.overline)}}):_c('div',{staticClass:"py-4"})]),_c('div',{domProps:{"innerHTML":_vm._s(item.text)}}),_c('v-list',{staticClass:"my-6 text-left",attrs:{"dense":""}},_vm._l((item.list),function(entry,j){return _c('v-list-item',{key:j},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(entry.icon))])],1),_c('v-list-item-content',[_c('span',{domProps:{"innerHTML":_vm._s(entry.text)}})])],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":item.buttonColor,"x-large":"","depressed":"","block":""},on:{"click":function($event){return _vm.onItemClick(item)}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans(item.buttonText))}})])],1)],1)],null,item)],2)])]}}],null,true)})]}),_c('v-spacer')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }