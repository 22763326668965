/**
 * ==============================
 * UI SETTINGS
 * ==============================
 * Initial settings for UI elements
 * within the application.
 *
 * The values can be modified from theme.json
 *
 */

export const APP_BAR_HIDE_ON_SCROLL = true;
export const APP_BAR_MINI = true;

export default Object.freeze({
  APP_BAR_HIDE_ON_SCROLL,
  APP_BAR_MINI,
});
