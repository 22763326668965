var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.datalake.hasErrors())?_c('v-alert',{attrs:{"outlined":"","text":"","type":"error","dismissible":""}},[_c('span',{staticClass:"login-error-message",domProps:{"textContent":_vm._s(_vm.datalake.getErrors('message', 'Something went wrong'))}})]):_vm._e(),_c('data-source-tray-filter',{attrs:{"width":"400px"},on:{"update:selected":_vm.changedDataSource}}),_c('portal',{attrs:{"to":"header:left"}},[_c('app-search-field',{attrs:{"title":'search datalake',"loading":_vm.datalake.searching,"label":_vm.$trans('Search datalake'),"placeholder":_vm.$trans('Search datalake'),"hint":_vm.$trans('Datalake search is only limited to file names and file types. Go to the Search module for the whole corpus search.'),"autofocus":"","solo":""},on:{"click:clear":_vm.clearSearch,"enter:search":_vm.setSearch},model:{value:(_vm.datalake.search),callback:function ($$v) {_vm.$set(_vm.datalake, "search", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"datalake.search"}})],1),_c('div',{attrs:{"data-tour-step":"welcome/onboarding.5","data-tour-overlay":""}},[(!_vm.imagesMode)?_c('v-data-table',{attrs:{"footer-props":{ 'items-per-page-options': _vm.datalake.options.rowsPerPage },"dense":_vm.datalake.meta.dense,"headers":_vm.datalake.headers,"items":_vm.datalake.items,"loading":_vm.datalake.loading,"mobile-breakpoint":_vm.datalake.meta.mobileBreakpoint,"server-items-length":_vm.datalake.pagination.total,"show-select":_vm.datalake.meta.showSelect,"options":_vm.datalake.options},on:{"update:options":[function($event){return _vm.$set(_vm.datalake, "options", $event)},_vm.updateList]},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('app-stickybar',{staticClass:"bg-white"},[_c('app-data-table-toolbar',{attrs:{"size":_vm.datalake.selectedSize()},on:{"click:clear":_vm.unselectAll}},[[_c('v-switch',{attrs:{"label":_vm.currentImagesMode},model:{value:(_vm.imagesMode),callback:function ($$v) {_vm.imagesMode=$$v},expression:"imagesMode"}})]],2),_c('v-spacer'),_c('v-data-footer',{staticClass:"no-border",attrs:{"title":'search datalake pagination',"pagination":pagination,"options":options,"items-per-page-options":_vm.datalake.options.rowsPerPage},on:{"update:options":updateOptions}})],1),_c('v-divider')]}},{key:"item.doc_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate text-no-wrap",staticStyle:{"max-width":"420px"}},[_c('app-can-view',{attrs:{"code":"report_viewer"},scopedSlots:_vm._u([{key:"alt",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({domProps:{"textContent":_vm._s(item.doc_name)}},on))]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('View {name}', {
                    name: item.doc_name,
                  }))}})])]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('router-link',{staticClass:"text-no-wrap",attrs:{"to":{
                    name: 'reports.single',
                    params: {
                      slug: item.id,
                    },
                    query: {
                      data_index: item.data_index,
                    },
                  }}},[_c('span',_vm._g({domProps:{"textContent":_vm._s(item.doc_name)}},on))])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('View {name}', {
                  name: item.doc_name,
                }))}})])],1)],1)]}},{key:"item.owner",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap text-truncate",domProps:{"textContent":_vm._s(item.owner)}})]}},{key:"item.update_date",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("toDateFromNow")(item.update_date)))])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.update_date)}})])]}},{key:"item.file_type",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{attrs:{"color":_vm.getFileTypeColor(item.file_type),"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.getFileTypeIcon(item.file_type))+" ")]),_c('span',{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(item.file_type)}})],1)]}}],null,true)},[_c('span',{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(item.file_type)}})])],1)]}},{key:"item.file_size",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toMb")(item.file_size)))])]}}],null,false,1888737531)}):_c('div',[_c('app-stickybar',{staticClass:"bg-white"},[_c('app-data-table-toolbar',{attrs:{"size":_vm.datalake.selectedSize()},on:{"click:clear":_vm.unselectAll}},[[_c('v-switch',{attrs:{"label":_vm.currentImagesMode},model:{value:(_vm.imagesMode),callback:function ($$v) {_vm.imagesMode=$$v},expression:"imagesMode"}})]],2),_c('v-spacer'),_c('v-data-footer',{staticClass:"no-border",attrs:{"title":'search datalake pagination',"pagination":_vm.datalake.pagination,"options":_vm.datalake.options,"items-per-page-options":_vm.datalake.options.rowsPerPage},on:{"update:options":_vm.updateList}})],1),_c('v-divider'),_c('div',{staticClass:"item-list"},[_c('images-data-iterator',{attrs:{"items":_vm.datalake.items,"loading":_vm.datalake.loading,"hide-default-footer":true,"items-per-row":6,"mobile-breakpoint":_vm.datalake.meta.mobileBreakpoint,"options":_vm.datalake.options,"server-items-length":_vm.datalake.pagination.total,"show-select":_vm.datalake.meta.showSelect},on:{"update:options":[function($event){return _vm.$set(_vm.datalake, "options", $event)},_vm.updateList]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.datalake.hasSearch())?[_c('images-data-iterator-empty-state',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('div',{staticClass:"secondary--text text-center mx-auto"},[_c('icon-landscape-image')],1)]},proxy:true}],null,false,15008848)})]:(_vm.datalake.hasErrors())?[_c('v-alert',{attrs:{"outlined":"","text":"","type":"error","dismissible":""}},[_c('span',{staticClass:"login-error-message",domProps:{"textContent":_vm._s(_vm.image.getErrors('message', 'Something went wrong'))}})])]:[_c('images-datalake-data-iterator-empty-state')]]},proxy:true},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('images-data-iterator-thumbnail',{attrs:{"height":_vm.height,"id":("image-thumbnail-" + (item.id)),"src":_vm.getProcessedImageUrl(item.id)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-layout',{attrs:{"fill-height":"","align-center":"","align-content-center":"","justify-center":"","column":""}},[_c('v-overlay',{attrs:{"value":hover,"absolute":"","title":"images thumb overlay"}},[_vm._t("button.link",[_c('v-btn',{staticClass:"mb-4",attrs:{"to":{
                          name: 'datalake.single',
                          params: { slug: item.id },
                          query: _vm.$route.query
                        },"exact":"","dark":"","small":"","outlined":"","width":"100%"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-magnify-plus-outline")]),_vm._v(" "+_vm._s(_vm.$trans('Zoom in'))+" ")],1)],null,{ item: item }),_vm._t("overlay",[_c('v-layout',{attrs:{"fill-height":"","column":"","justify-center":""}},[_vm._t("button.go-to-report",[_c('v-btn',{staticClass:"mb-4",attrs:{"to":{
                              name: 'reports.single',
                              params: {
                                slug: item.id,
                              },
                              query: {
                                data_index: item.data_index,
                              },
                            },"dark":"","small":"","outlined":"","title":"images overlay button goto"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-file-eye-outline")]),_vm._v(" "+_vm._s(_vm.$trans("Open Report"))+" ")],1)],null,{ item: item })],2)],null,{ item: item, hover: hover })],2)],1)]}},{key:"append",fn:function(){return [_c('v-layout',{staticClass:"caption text-truncate text--disabled"},[_c('router-link',{staticClass:"caption text-truncate text--disabled",attrs:{"to":{
                      name: 'reports.single',
                      params: {
                        slug: item.id,
                      },
                      query: {
                        data_index: item.data_index,
                        back: _vm.$route.fullPath,
                      },
                    }}},[_c('span',{domProps:{"textContent":_vm._s(item.doc_name)}})])],1),_vm._t("item.append",null,null,{ item: item })]},proxy:true}],null,true)})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }