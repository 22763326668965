import { APP_MODE } from '@config/app';

export const EXPORT_SEARCH_MODES = {
  ELASTICDOCS: 'elasticdocs',
  DATA_ATELIER: 'dataatelier',
};

export const CURRENT_EXPORT_SEARCH_MODE = EXPORT_SEARCH_MODES?.[APP_MODE]
  ?? EXPORT_SEARCH_MODES.ELASTICDOCS;

export default {
  isShown: false,
  active: false,
  show () {
    this.isShown = true;
  },
  hide () {
    this.isShown = false;
  },
  setShown (isShown) {
    this.isShown = isShown;
  },
  activate () {
    this.active = true;
  },
  deactivate () {
    this.active = false;
  },
};
