<template>
  <app-content class="text-left">
    <meta-tag :title="$trans('Terms and Conditions')"></meta-tag>

    <v-row title="terms and conditions">
      <v-col cols="12" lg="8" class="mx-auto">
        <a name="top"></a>

        <v-btn :to="$user.getRedirectRouteFromEnv()" text title="back link">
          <v-icon left small>mdi-arrow-left</v-icon>
          <span v-text="$trans('Back')"></span>
        </v-btn>

        <div class="my-5"></div>

        <app-markdown-preview ref="markdown" v-html="content"></app-markdown-preview>

        <div class="mt-15"></div>
        <back-to-top-btn></back-to-top-btn>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import ELASTICDOCS from '@root/terms_and_conditions/TC_ELASTICDOCS.md';
import DATA_ATELIER from '@root/terms_and_conditions/TC_DATA_ATELIER.md';

export default {
  routename: 'legal.agreement',

  layout: 'web',

  data: () => ({
    terms: {
      DATA_ATELIER,
      ELASTICDOCS,
    },
  }),

  computed: {
    content () {
      return this.terms?.[this.$app.appMode] ?? ELASTICDOCS;
    },
  },
};
</script>
