<template>
  <div><router-view @click:goToTab="goToTab"></router-view></div>
</template>

<script>
export default {
  layout: 'admin',

  routename: 'reports.images',

  methods: {
    goToTab (item) {
      this.$emit('click:goToTab', item);
    }
  }
};

</script>
