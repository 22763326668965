export default new class RegisterHook {
  trigger (auth) {
    auth.generateAvailabilityLimit('register', 2);
    auth.store.dispatch('register/setMessage', {
      title: 'Registration Successful',
      type: 'success',
    });
    auth.router.push({
      name: 'registered',
    });
  }
};
