import Config from '@core/resources/Config';

export default new Config({
  hint: '',
  disabled: false,

  startHint () {
    this.hint = 'Please wait while verifying action';
    this.disabled = true;
  },

  successHint () {
    this.hint = 'Successfully verified action';
    this.disabled = true;
  },

  errorHint () {
    this.hint = 'Something went wrong trying to verify action. Please reload and try again.';
    this.disabled = true;
  },

  resetHint () {
    this.hint = '';
    this.disabled = false;
  },
});
