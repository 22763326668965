<template>
  <div @mousemove="onMouseMove">
    <v-menu
      :close-on-content-click="false"
      :position-x="x"
      :position-y="y"
      absolute open-on-hover
      min-width="auto"
    >
      <template v-slot:activator="{ on }">
        <slot name="activator" v-bind="{ on }"></slot>
      </template>
      <v-sheet class="py-1 px-2" dark>
        <slot></slot>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'AppPopoverTooltip',

  data: () => ({
    x: 0,
    y: 0,
  }),

  methods: {
    onMouseMove (e) {
      this.$nextTick(() => {
        this.x = e.x;
        this.y = e.y;
      });
    },
  },
};
</script>
