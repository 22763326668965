import DatalakeManifest from '@/modules/Datalake/manifests/DatalakeManifest';

const items = DatalakeManifest.getItems();

export const tabs = {
  items,
  tab: {},
  current: 0,
  findByTabKey (tabKey) {
    return this.items.find(item => item.tabKey === tabKey);
  },
  findIndexByTabKey (tabKey) {
    return this.items.findIndex(item => item.tabKey === tabKey);
  },
  find (i) {
    return this.items.find((item, j) => j === i);
  },
  setCurrent (i) {
    this.current = i;
  },
  setTab (tab, i) {
    this.tab = tab;
    this.setCurrent(i);
  },
  setResource (resource, tab) {
    tab.data = resource;
  },
  getDataResource () {
    return this.tab?.data;
  },
};

export default {
  tabs,
};
