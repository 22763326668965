import Profile from '@/modules/User/resources/Profile';

export default async function isManager (to, from, next) {
  const user = await (new Profile).getUserFromApi();

  if (user.canManageFiles()) {
    return next();
  }

  return next({ name: 'error:401', params: [ to.fullPath ], replace: true });
}
