<template>
  <v-alert
    color="grey"
    prominent
    text
    border="left"
    class="mb-6"
  >
    <template v-slot:prepend>
      <v-icon
        large
        color="grey lighten-1"
        class="v-alert__icon ma-3 mr-5"
      >mdi-file-eye</v-icon>
    </template>
    <v-row align="center">
      <v-col class="grow">
        <!-- eslint-disable-next-line max-len -->
        <p class="mb-0 body-1" v-text="$trans('To proceed to next step, click the button and submit this Set for Audit Approval. The approval process might take 2-3 days.')"></p>
      </v-col>
      <v-col class="shrink">
        <v-btn
          :to="{
            name: 'sets.submission',
            params: { id: $id },
          }"
          filled
          large
          color="primary"
          v-text="$trans('Submit for Approval')"
        ></v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
};
</script>
