<template>
  <div class="pdf-previewer__page-item">
    <panzoom-pdf-previewer-page-item-loading-state
      v-if="loading"
      class="pdf-previewer__page-item-loader"
    ></panzoom-pdf-previewer-page-item-loading-state>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PanzoomPdfPreviewerPageItem',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.pdf-previewer__page-item {
  box-shadow: $global-box-shadow;
}
</style>
