<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
    <v-form
      ref="form"
      :disabled="audit.disabled"
      :loading="audit.loading"
      @submit.prevent="handleSubmit(submit($event))"
    >
      <validation-provider
        v-slot="{ errors }"
        vid="remarks"
        name="remarks"
        mode="aggressive"
        rules="required"
      >
        <v-textarea
          v-model="audit.fields.remarks"
          :error-messages="errors"
          :label="$trans('Reason')"
          auto-grow
          outlined filled
          prepend-inner-icon="mdi-message-text-outline"
          name="remarks"
          class="is-required"
        ></v-textarea>
      </validation-provider>

      <v-layout class="mt-8">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="invalid"
          :value="REJECTED_FOR_AUDIT"
          large outlined
          name="status"
          color="error"
          class="ml-3"
          type="submit"
        >
          <span v-text="$trans('Reject')"></span>
        </v-btn>
        <v-btn
          :disabled="invalid"
          :value="APPROVED_FOR_AUDIT"
          large
          name="status"
          color="success"
          class="ml-3"
          type="submit"
        >
          <span v-text="$trans('Approve')"></span>
        </v-btn>
      </v-layout>
    </v-form>
  </validation-observer>
</template>

<script>
import Audit from '@/modules/File/resources/Audit';
import { APPROVED_FOR_AUDIT, REJECTED_FOR_AUDIT } from '@/modules/File/config/phases';
import { isArray } from 'lodash';

export default {
  name: 'FileAuditApprovalBatchForm',

  props: {
    items: {
      type: [ Array, Object ],
      default: () => [],
    },
  },

  data: () => ({
    audit: new Audit,
    APPROVED_FOR_AUDIT,
    REJECTED_FOR_AUDIT,
  }),

  computed: {
    reviews () {
      return isArray(this.items) ? this.items : [ this.items ];
    },
  },

  methods: {
    async submit ({ submitter }) {
      try {
        this.audit.setFieldStatus(submitter.value);
        await Promise.all(this.reviews.map(({ id }) => this.audit.review(id)));
        this.cleanUpAndNotify(submitter.value);
        this.emitSubmitted();
      } catch (errors) {
        this.$refs.observer.setErrors(errors ?? []);
      }
    },

    cleanUpAndNotify (status) {
      this.audit.notify(this.$trans_choice(`file.review.${status}`, this.reviews.length));
      this.$refs.form.reset();
    },

    emitSubmitted () {
      this.$emit('click:submitted', this.reviews);
    },
  },
};
</script>
