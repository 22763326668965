<template>
  <app-content></app-content>
</template>

<script>
export default {
  layout: 'admin',

  created () {
    this.$router.replace({ name: 'dashboard' });
  },
};
</script>
