export const state = {
  currentHash: null,
};

export const getters = {
  currentHash: state => state.currentHash,
};

export const mutations = {
  SET_CURRENT_HASH (state, hash) {
    state.currentHash = hash;
  },
};

export const actions = {
  setCurrentHash ({ commit }, hash) {
    commit('SET_CURRENT_HASH', hash);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
