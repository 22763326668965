// Collect all core plugins.

import '@core/plugins/asset';
import '@core/plugins/modules';
import '@core/plugins/plugins';
import '@core/plugins/systemtray';
import '@core/plugins/components';
import '@core/plugins/layouts';
import '@core/plugins/pages';
import '@core/plugins/shortkey';
import '@core/plugins/sidebar';
import '@core/plugins/url';
import '@core/plugins/iconfiles';
