<template>
  <app-content>
    Dashboard inside <code>@core/modules</code>
  </app-content>
</template>

<script>
export default {
  layout: 'admin',
};
</script>
