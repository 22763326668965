<template>
  <v-slide-y-reverse-transition :mode="mode">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </v-slide-y-reverse-transition>
</template>

<script>
import ComponentLayout from '@core/mixins/ComponentLayout';

export default {
  mixins: [ ComponentLayout ],
};
</script>
