<template>
  <v-layout align-center justify-start>
    <v-menu
      v-model="menu"
      :close-on-click="false"
      :close-on-content-click="false"
      max-width="480px"
      bottom
      right nudge-right
      eager offset-y
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          ref="search"
          v-model="search"
          v-shortkey="[ 'ctrl', '/' ]"
          :append-icon="search ? 'mdi-close' : undefined"
          :placeholder="$trans('Search file')"
          :prepend-inner-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-magnify'"
          hide-details outlined
          dense filled
          full-width
          autocomplete="off"
          class="app-search-field"
          v-on="on"
          @click:append="onClickAppend"
          @keyup.enter="onKeyUp"
          @shortkey.native="focus"
        ></v-text-field>
      </template>
      <v-card
        v-if="hasResults"
        data-search-menu
        min-width="320px" max-width="100%"
        max-height="400px"
        class="ov-y-auto"
      >
        <v-card-actions>
          <app-mode name="ELASTICDOCS">
            <v-switch
              v-model="showOriginalText"
              dense hide-details
              class="ma-0"
              @change="viewTranslatedText"
            >
              <template v-slot:label>
                <span class="small" v-text="$trans('See translated text')"></span>
              </template>
            </v-switch>
          </app-mode>
          <v-spacer></v-spacer>
          <v-btn
            :title="$trans('Close menu')"
            icon x-small
            @click="closeMenu"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-list v-model="currentItemPage" dense two-line>
          <template v-for="(result, i) in resultItems">
            <v-list-item
              :key="i+1"
              :value="i"
              :class="{ 'v-list-item--active': isActive(i+1) }"
              :data-search-item="i+1"
              @click="selectItem(result, i+1)"
            >
              <slot
                name="search.item"
                v-bind="{
                  item: result,
                  select: selectItem,
                  showOriginalText,
                  itemTextKey,
                  highlight,
                  search,
                }"
              >
                <v-list-item-content>
                  <span
                    class="caption" v-html="highlight(result.text, search)"
                  ></span>
                  <span
                    v-if="showOriginalText"
                    class="caption muted--text"
                    v-html="highlight(result[itemTextKey], search)"
                  ></span>
                </v-list-item-content>
              </slot>
            </v-list-item>
            <v-divider :key="`divider-${i}`"></v-divider>
          </template>
        </v-list>
      </v-card>
      <v-card v-else-if="hasSearchAndNoResults">
        <v-card class="py-4 text-center">
          <div class="muted--text" v-text="$trans('No results found.')"></div>
        </v-card>
      </v-card>
    </v-menu>
    <v-slide-x-transition>
      <div v-if="hasSearchAndResults" class="mx-2 text-no-wrap">
        <span
          class="mx-2"
          v-text="$trans('{page}/{total}', {
            total,
            page: currentItemPage,
          })"
        ></span>
        <v-btn small icon @click="moveUp"><v-icon small>mdi-chevron-up</v-icon></v-btn>
        <v-btn small icon @click="moveDown"><v-icon small>mdi-chevron-down</v-icon></v-btn>
        <v-btn
          v-if="hasResults"
          small text
          @click="toggleMenu"
          v-text="$trans_choice('Hide list', menu)"
        ></v-btn>
      </div>
    </v-slide-x-transition>
  </v-layout>
</template>

<script>
import HighlightText from '@core/mixins/HighlightText';
import { size, isEmpty } from 'lodash';

export default {
  name: 'PanzoomPdfPreviewerSearchField',

  mixins: [ HighlightText ],

  props: {
    value: {
      type: String,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    results: {
      type: Array,
      default: () => [],
    },

    page: {
      type: [ String, Number ],
      default: 1,
    },
  },

  data: () => ({
    menu: false,
    resultItems: [],
    currentItemPage: 1,
    itemTextKey: 'text',
    hasBegunSearched: false,
    showOriginalText: false,
  }),

  computed: {
    search: {
      get () {
        return this.value;
      },
      set (search) {
        this.$emit('input', search);
      },
    },

    total () {
      return size(this.results);
    },

    hasSearch () {
      return !isEmpty(this.search);
    },

    hasResults () {
      return !isEmpty(this.resultItems);
    },

    hasSearchAndResults () {
      return this.hasResults && this.hasSearch;
    },

    hasSearchAndNoResults () {
      return this.hasSearch
        && !this.hasResults
        && !this.loading
        && this.hasBegunSearched;
    },
  },

  watch: {
    results (results) {
      this.resultItems = results;
    },
  },

  methods: {
    focus () {
      this.$refs.search.focus();
    },

    onKeyUp () {
      this.hasBegunSearched = true;
      this.$emit('keyup:search', this.search);
      this.openMenu();
    },

    onClickAppend () {
      this.closeMenu();
      this.search = '';
      this.resultItems = [];
      this.$emit('click:clear', this.search);
    },

    toggleMenu () {
      this.menu = !this.menu;
    },

    openMenu () {
      this.menu = true;
    },

    closeMenu () {
      this.menu = false;
    },

    selectItem (item, i) {
      this.currentItemPage = i;
      this.emitItem(item, i);
    },

    emitItem (item, i) {
      this.scrollIntoView(i);
      this.$emit('click:item', { item, i });
    },

    async scrollIntoView (page) {
      const container = document.querySelector('[data-search-menu]');

      await this.$vuetify.goTo(`[data-search-item="${page}"]`, {
        container,
        offset: 1,
        easing: 'easeInOutCubic',
      });
    },

    moveUp () {
      this.currentItemPage--;

      if (this.currentItemPage <= 0) {
        this.currentItemPage = this.total;
      }

      const item = this.results.find((i, j) => j === (this.currentItemPage - 1));
      this.emitItem(item, this.currentItemPage);
    },

    moveDown () {
      this.currentItemPage++;

      if (this.currentItemPage > this.total) {
        this.currentItemPage = 1;
      }

      const item = this.results.find((i, j) => j === (this.currentItemPage - 1));
      this.emitItem(item, this.currentItemPage);
    },

    isActive (i) {
      return this.currentItemPage === i;
    },

    viewTranslatedText (isTranslatedText) {
      this.itemTextKey = isTranslatedText ? 'doc_text' : 'doc_text_original';
    },
  },
};
</script>

<style>
.app-search-field {
  min-width: 240px;
}
</style>
