var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"sidebar:top.append"}},[_c('app-mode',{attrs:{"name":"DATA_ATELIER"}},[(_vm.isSidebarMini)?_c('div',{staticClass:"px-4 text-center"},[_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"cursor":"help"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-multiple-outline")]),_c('div',{staticStyle:{"font-size":"9px"}},[_vm._v("Credits")])],1)]}}],null,false,3934275642)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('{count} of {total} pages used', {
            count: _vm.tracker.used,
            total: _vm.tracker.total,
          }))}})])],1):_c('div',{staticClass:"pa-4"},[_c('v-layout',{staticClass:"mb-2"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-file-multiple-outline")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Page Credits'))}})],1),_c('v-progress-linear',{staticClass:"mb-2",attrs:{"value":_vm.tracker.percentage}}),_c('v-layout',[_c('span',{staticClass:"caption",domProps:{"textContent":_vm._s(_vm.$trans('{count} of {total} pages used', {
            count: _vm.tracker.used,
            total: _vm.tracker.total,
          }))}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","right":""}},on),[_vm._v("mdi-information")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"mb-3 caption",domProps:{"innerHTML":_vm._s(_vm.$trans('Page credits are <em>renewed</em> monthly.'))}}),_c('v-layout',{attrs:{"align-center":""}},[_c('div',{staticClass:"mr-4 caption",domProps:{"innerHTML":_vm._s(_vm.$trans('Need more page credits?'))}}),_c('v-btn',{staticClass:"text-capitalize",attrs:{"small":"","rounded":"","color":"secondary"},on:{"click":_vm.goToPage}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Upgrade'))}})])],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }