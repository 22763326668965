<template>
  <v-tour
    :name="name"
    :steps="steps"
    :options="options"
    class="tour__main-system"
  >
    <template v-slot="tour">
      <v-scale-transition>
        <v-step
          v-if="tour.steps[tour.currentStep]"
          :key="tour.currentStep"
          :step="tour.steps[tour.currentStep]"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
          :highlight="tour.highlight"
          :class="color"
        >
          <template v-slot:header>
            <div
              class="v-step__topbar text-right caption"
              v-html="$trans('{current}/{total}', {
                current: tour.currentStep + 1,
                total: tour.steps.length,
              })"
            ></div>
          </template>
          <template v-slot:actions>
            <v-layout justify-end class="v-step__action mt-5">
              <v-btn
                v-if="!tour.isLast"
                small
                dark text
                class="ml-2"
                @click="onClick('skip', tour.currentStep, tour.skip)"
                v-text="$trans('Skip')"
              ></v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!tour.isFirst"
                small
                dark text
                class="ml-2"
                @click="onClick('previousStep', tour.currentStep, tour.previousStep)"
                v-text="$trans('Previous')"
              ></v-btn>
              <v-btn
                v-if="tour.isLast"
                small
                dark text
                class="ml-2"
                @click="onClick('stop', tour.currentStep, tour.stop)"
                v-text="$trans('Done')"
              ></v-btn>
              <v-btn
                v-else
                small
                dark text
                class="ml-2"
                @click="onClick('nextStep', tour.currentStep, tour.nextStep)"
                v-text="$trans('Next')"
              ></v-btn>
            </v-layout>
          </template>
        </v-step>
      </v-scale-transition>
    </template>
  </v-tour>
</template>

<script>
export default {
  name: 'TourMultiSystem',

  props: {
    name: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        enableScrolling: false,
        highlight: true,
      }),
    },
    color: {
      type: String,
      default: 'secondary white--text',
    },
  },

  computed: {
    isSingle () {
      return this.steps.length === 1;
    },
    isNotSingle () {
      return !this.isSingle;
    },
  },

  methods: {
    onClick (name, step, click) {
      click();
      this.$emit(`click:${name}`);
      this.$emit(`click:step-${step}:${name}`);
    },
  },
};
</script>

<style lang="scss">
.tour {
  &__main-system {
    .v-step {
      background: var(--v-secondary-base);
      border-radius: var(--global-radius);
      padding: 0;

      &__topbar {
        margin-bottom: 10px;
        padding-right: 9px;
        padding-top: 3px;
        position: absolute;
        width: 100%;
      }

      &__content {
        padding: 2rem;
        padding-bottom: 0;
        text-align: left;
      }

      &__action {
        padding: 8px;
        padding-top: 0;
      }
    }
  }
}

.v-tour__target--highlighted {
  border: 2px solid var(--v-dark-darken4);
  border-radius: var(--global-radius);
  box-shadow: 0 0 0 5px transparentize(map-deep-get($default, 'light', secondary), 0.4);
}

[data-tour-overlay] {
  &.v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
  }
}
</style>
