<template>
  <content-view></content-view>
</template>

<script>
import MetaTag from '@core/mixins/MetaTag';
import ResponseHandler from '@core/mixins/ResponseHandler';

export default {
  name: 'App',

  mixins: [ MetaTag, ResponseHandler ],
};
</script>
