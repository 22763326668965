<template>
  <v-item-group active-class="primary">
    <v-layout>
      <v-spacer></v-spacer>
      <template v-for="(item, i) in items">
        <v-item :key="i" v-slot="{ active, toggle }">
          <subscription-plan-item class="mx-2">
            <div
              :class="{
                'subscription-plan-group__item--featured': item.isFeatured,
              }"
              class="fill-height subscription-plan-group__item"
            >
              <slot v-bind="item">
                <v-card
                  :class="{
                    'active': active,
                  }"
                  flat
                  width="350"
                  class="fill-height d-flex flex-column pa-5"
                >
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <h2 class="h2 font-weight-bold ma-0" v-text="item.title"></h2>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text class="flex-grow-1">
                    <div class="mb-5">
                      <div
                        v-if="item.price"
                        class="display-2 font-weight-bold"
                        v-text="item.price"
                      ></div>
                      <div v-else class="py-6 mt-1"></div>
                      <div v-if="item.overline" class="title" v-html="item.overline"></div>
                      <div v-else class="py-4"></div>
                    </div>
                    <div v-html="item.text"></div>
                    <v-list dense class="my-6 text-left">
                      <v-list-item v-for="(entry, j) in item.list" :key="j">
                        <v-list-item-icon>
                          <v-icon color="success">{{ entry.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <span v-html="entry.text"></span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      :color="item.buttonColor"
                      x-large
                      depressed
                      block
                      @click="onItemClick(item)"
                    ><span v-text="$trans(item.buttonText)"></span></v-btn>
                  </v-card-actions>
                </v-card>
              </slot>
            </div>
          </subscription-plan-item>
        </v-item>
      </template>
      <v-spacer></v-spacer>
    </v-layout>
  </v-item-group>
</template>

<script>
export default {
  name: 'SubscriptionPlanGroup',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    onItemClick (item) {
      this.$emit('click:item', item);
    },
  },
};
</script>

<style lang="scss">
.subscription-plan-group__item {
  border: 4px solid transparent;

  &--featured {
    border: 4px solid var(--v-accent-base);
  }
}
</style>
