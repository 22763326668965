import { BASE_URL } from '@/config/api';

export const AUTH_LOGIN_URL = `${BASE_URL}/auth/api-token-auth/`;
export const REFRESH_TOKEN_URL = `${BASE_URL}/auth/api-token-refresh/`;
export const SEND_RESET_PASSWORD_URL = `${BASE_URL}/users/generate-reset-code/`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/users/change-password/`;
export const POST_REGISTER_URL = `${BASE_URL}/users/register/`;
export const POST_VERIFY_RECAPTCHA_URL = `${BASE_URL}/users/check-captcha/`;
export const POST_VERIFY_ACCOUNT_URL = `${BASE_URL}/users/verify/`;

export default Object.freeze({
  AUTH_LOGIN_URL,
  REFRESH_TOKEN_URL,
  SEND_RESET_PASSWORD_URL,
  CHANGE_PASSWORD_URL,
  POST_REGISTER_URL,
  POST_VERIFY_RECAPTCHA_URL,
  POST_VERIFY_ACCOUNT_URL,
});
