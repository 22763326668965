import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      appbar: 'appbar/appbar',
    }),

    appbarModel: {
      get () {
        return this.appbar.model;
      },
      set () {
        this.toggleAppbar();
      },
    },
  },

  methods: {
    ...mapActions({
      $_toggleAppbar: 'appbar/toggle',
    }),

    toggleAppbar () {
      this.$_toggleAppbar(!this.appbar.model);
    },
  },
};
