<template>
  <div v-bind="$attrs">
    <v-layout no-gutters justify="start" align="center">
      <div class="panzoom-pdf-previewer__page-selector mr-1">
        <input
          type="number"
          class="py-0 px-1 light darken-1 rounded"
          :value="currentPage"
          :min="min" :max="total"
          @focus="selectAll"
          @blur="updateCurrentPage"
          @keyup.enter="updateCurrentPage"
        >
      </div>
      <span>/</span>
      <span v-text="total"></span>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'PanzoomPdfPreviewerPageSelector',

  model: {
    prop: 'page',
  },

  props: {
    page: {
      type: [ String, Number ],
      default: 1,
    },
    total: {
      type: [ String, Number ],
      default: 0,
    },
    min: {
      type: [ String, Number ],
      default: 1,
    },
  },

  computed: {
    currentPage: {
      get () {
        return this.page;
      },
      set (page) {
        this.$emit('update:page', page);
        this.$emit('input', page);
      },
    },
  },

  methods: {
    selectAll (e) {
      e.target.select();
    },

    updateCurrentPage (e) {
      const page = e.target.value > this.total ? this.total : e.target.value;
      this.currentPage = page;
    },
  },
};
</script>

<style lang="scss" scoped>
.panzoom-pdf-previewer__page-selector {
  width: 40px;

  input[type=number] {
    -moz-appearance: textfield;
    text-align: right;
    width: 100%;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
}
</style>
