<template>
  <v-skeleton-loader width="100%" height="calc(100vh - 216px)" class="ov-hidden">
    <v-card flat height="calc(100vh - 216px)" class="ov-hidden">
      <div class="v-skeleton-loader__map-area rounded v-skeleton-loader__bone"></div>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
export default {
  name: 'RockphysicsPreviewCardLoadingState',
};
</script>
