<template>
  <!-- eslint-disable max-len -->
  <app-tabs-content name="profile">
    <meta-tag :title="$trans('API Access Token')"></meta-tag>

    <v-row>
      <v-col cols="12" lg="6">
        <h2 class="mb-4" v-text="$trans('API Access Token')"></h2>
        <p v-html="$trans('Keep this token secret. Anyone who has it can access all the endpoints and its corresponding data.')"></p>
        <p class="font-italic">
          {{ $trans('Read the API documentation') }} <router-link :to="{ name: 'about', query: { tab: 'api' }}">here</router-link>.
        </p>

        <api-token-field></api-token-field>

        <h2 class="mb-4 mt-6" v-text="$trans('API Base URL')"></h2>
        <p class="mb-1" v-html="$trans('Use the base URL below when building your queries:')"></p>
        <v-layout class="mb-6 align-center">
          <div class="font-weight-bold">{{ apiBaseURL }}</div>
          <v-btn
            x-small icon
            class="ml-4"
            @click="copyText(apiBaseURL)"
          >
            <v-icon x-small>mdi-content-copy</v-icon>
          </v-btn>
        </v-layout>
      </v-col>
      <v-col cols="12" lg="6">
        <h2 class="mb-4" v-text="$trans('Data Warehouse')"></h2>
        <p v-html="$trans('Access all the uploaded and processed datasets in the data warehouse. These includes original files, extracted text, extracted images, and so on.')"></p>
        <p
          class="font-italic"
          v-html="$trans('Use your {appTitle} login credentials to access the Data Warehouse.', {
            appTitle: $app.appTitle,
          })"
        ></p>

        <v-btn
          :href="linkDW"
          x-large
          target="_blank"
          class="text-transform-unset"
          color="secondary"
        >
          <span v-text="$trans('Go to the Data Warehouse')"></span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <api-requests-tracker></api-requests-tracker>
      </v-col>
    </v-row>
  </app-tabs-content>
</template>

<script>
import IsBoltAccount from '@/modules/Token/middlewares/IsBoltAccount';
import { BASE_URL } from '@/config/api';

export default {
  layout: 'admin',

  middleware: [ IsBoltAccount ],

  routename: 'profile.api',

  computed: {
    linkDW () {
      console.log(`${this.$store.getters['settings/currentInstance']?.attributes?.settings?.DW_URL}/admin/`);
      return `${this.$store.getters['settings/currentInstance']?.attributes?.settings?.DW_URL}/admin/`;
    },

    apiBaseURL () {
      return BASE_URL;
    },
  },

  methods: {
    copyText (text) {
      navigator.clipboard.writeText(text);
      this.$store.dispatch('snackbar/show', { text: 'Link copied to clipboard' });
    },
  },
};
</script>
