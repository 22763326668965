<template>
  <v-list-group v-bind="$attrs" @click="unclipSidebar">
    <template v-slot:prependIcon>
      <slot name="prependIcon"></slot>
    </template>
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>
          <slot name="activator"></slot>
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <slot></slot>
  </v-list-group>
</template>

<script>
export default {
  methods: {
    unclipSidebar () {
      this.$store.dispatch('sidebar/minify', false);
    },
  },
};
</script>
