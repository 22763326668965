export const supportedQuery = [
  'q',
  'tab',
  'page',
  'status',
  'order_by',
  'projects_list',
  { search: 'q' },
  { sortBy: 'order_by' },
  { itemsPerPage: 'page_size' },
];

export default {
  supportedQuery,
};
