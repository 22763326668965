import { BASE_URL } from '@/config/api';

export const GET_BARGRAPH_LIST_URL = `${BASE_URL}/plugins/webgis-pie-chart/list/`;
export const GET_BARGRAPH_SUMMARY_URL = `${BASE_URL}/plugins/webgis-pie-chart/summary/`;
export const EXPORT_BARGRAPH_URL = `${BASE_URL}/plugins/webgis-pie-chart/export/`;

export const GET_SCATTERPLOT_LIST_URL = `${BASE_URL}/plugins/webgis-scatter-plot/list/`;
export const GET_SCATTERPLOT_SUMMARY_URL = `${BASE_URL}/plugins/webgis-scatter-plot/summary/`;
export const FIND_SCATTERPLOT_WELL_URL = id => `${BASE_URL}/plugins/webgis-scatter-plot/${id}/`;
export const EXPORT_SCATTERPLOT_URL = `${BASE_URL}/plugins/webgis-scatter-plot/export/`;

export const POST_VIZ_SPATIAL_WELL_FITLER = `${BASE_URL}/wells/info/spatial-well-filter/`;

export default Object.freeze({
  GET_BARGRAPH_LIST_URL,
  GET_BARGRAPH_SUMMARY_URL,
  EXPORT_BARGRAPH_URL,

  GET_SCATTERPLOT_LIST_URL,
  GET_SCATTERPLOT_SUMMARY_URL,
  FIND_SCATTERPLOT_WELL_URL,
  EXPORT_SCATTERPLOT_URL,

  POST_VIZ_SPATIAL_WELL_FITLER,
});
