<template>
  <div>
    <v-card rounded flat>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn-toggle
          v-model="wordcloud.meta.selectedColorScale"
          mandatory rounded
        >
          <v-btn
            v-for="(button, name) in wordcloud.meta.colorScales"
            :key="name" :value="name"
            small
            active-class="primary white--text"
            v-text="name"
          ></v-btn>
        </v-btn-toggle>
      </v-card-actions>
      <v-card-text>
        <word-cloud-map-loading-state
          v-show="incomplete"
          :value.sync="progress"
        ></word-cloud-map-loading-state>
        <vue-word-cloud
          style="min-height: 280px; width: 100%;"
          :words="wordcloud.meta.words"
          :color="setColor"
          :progress.sync="wordcloud.meta.progress"
          class="word-cloud__container"
        >
          <template v-slot:default="{ text, weight }">
            <app-popover-tooltip>
              <template v-slot:activator="{ on }">
                <span v-on="on" v-text="text"></span>
              </template>
              <span v-text="text"></span>
              <v-chip small class="ml-3" v-text="weight"></v-chip>
            </app-popover-tooltip>
          </template>
        </vue-word-cloud>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span class="caption muted--text mr-2" v-text="$trans('Number of terms displayed')"></span>
        <v-chip-group v-model="wordcloud.meta.selectedWordCountIndex">
          <v-chip
            v-for="(chip, i) in wordcloud.meta.itemsPerPageOptions"
            :key="i" :class="{ primary: isActiveTab(i) }"
            small
            class="mx-1"
            @click="setWordCount(chip.value)"
            v-text="chip.text"
          ></v-chip>
        </v-chip-group>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import WordCloud from '@/modules/Report/resources/WordCloud';

export default {
  name: 'WordCloudMap',

  props: {
    item: {
      type: [ Object, Array ],
      default: () => {},
    },
  },

  data: () => ({
    wordcloud: new WordCloud({
      meta: {
        words: [],
        progress: {},
        selectedWordCountIndex: 1,
        selectedWordCount: 100,
        itemsPerPageOptions: [
          { text: 200, value: 200 },
          { text: 100, value: 100 },
          { text: 50, value: 50 },
          { text: 25, value: 25 },
        ],
        selectedColorScale: 'divergent',
        colorScales: {
          divergent: [ '#2ecc71', '#3498db', '#8e44ad', '#34495e', '#f1c40f' ],
          continuous: [ '#8b0000', '#c0223b', '#e75758', '#fe906a', '#ffcb91' ],
        },
      },
    }),
  }),

  computed: {
    incomplete () {
      return this.wordcloud.loading && !this.completed;
    },

    progress () {
      const progress = this.wordcloud.meta.progress || {};

      return {
        loading: this.wordcloud.loading,
        amount: progress.completedWords || 0,
        total: progress.totalWords || 0,
      };
    },

    completed () {
      const progress = this.wordcloud.meta.progress || {};

      return progress.totalWords === progress.completedWords;
    },
  },

  watch: {
    '$route.params.slug': function (slug) {
      this.wordcloud.listFrequentWordsByDocRefID(slug);
    },
  },

  async created () {
    await this.wordcloud.listFrequentWords();
  },

  methods: {
    setColor (options) {
      return this.wordcloud.setWordCloudColors(options);
    },

    isActiveTab (key) {
      return this.wordcloud.meta.selectedWordCountIndex === key;
    },

    setWordCount (count) {
      this.wordcloud.setWordCount(count).listFrequentWords();
    },
  },
};
</script>

<style lang="scss">
.word-cloud__container {
  cursor: default;
}
</style>
