<template>
  <v-sheet class="d-flex flex-column" color="transparent">
    <slot name="button.back" :page="page">
      <template v-if="page.backlink">
        <router-link exact :to="page.backlink" class="text-decoration-none body-1" title="link back">
          <v-icon small class="mb-1">mdi mdi-chevron-left</v-icon>
          <span v-text="$trans('Back')"></span>
        </router-link>
      </template>
    </slot>

    <slot name="page">
      <v-sheet class="d-flex justify-space-between align-center" color="transparent">
        <slot name="page.title" v-bind="{ page }">
          <h2 class="mb-4" v-text="$trans(page.title)"></h2>
        </slot>

        <slot name="page.action"></slot>
      </v-sheet>
    </slot>

    <slot name="page.links"></slot>
  </v-sheet>
</template>

<script>
import PageMetaInfo from '@core/mixins/PageMetaInfo';

export default {
  mixins: [ PageMetaInfo ],
};
</script>
