/* eslint-disable */

export const UPLOADED_SUCCESSFULLY = 'uploaded_successfully';
export const PENDING_FOR_AUDIT_APPROVAL = 'pending_for_audit_approval';
export const APPROVED_FOR_AUDIT = 'approved_for_audit';
export const REJECTED_FOR_AUDIT = 'rejected_for_audit';
export const AUDIT_IN_PROGRESS = 'audit_in_progress';
export const AUDIT_COMPLETED = 'audit_completed';
export const PROCESSING_IN_PROGRESS = 'processing_in_progress';
export const PROCESSING_COMPLETED = 'processing_completed';
export const DEPLOYED = 'deployed';

// meta.info
export const metaInfo = {
  [DEPLOYED]: {
    color: 'success',
    title: 'Deployment',
    text: [ 'In this stage, the processing outputs will be made available for search' ],
  },

  [APPROVED_FOR_AUDIT]: {
    color: 'success',
    title: 'Approval',
    text: [
      'In this stage, a team manager/account owner can choose which files will be passed to processing.',
      'Depending on your subscription, all files might be automatically approved for processing.',
    ],
  },

  [PROCESSING_COMPLETED]: {
    color: 'success',
    title: 'Processing',
    text: [
      "In this stage, your files will be going through {appTitle}'s full processing pipeline.",
      'This processing pipeline includes text and image extraction, table extraction, image classification and so on.',
    ],
  },

  [AUDIT_COMPLETED]: {
    color: 'success',
    title: 'Audit',
    text: [
      'In this stage, we will go through the files and information such as file formats and sizes.',
      'Information on the number of page credits that will be consumed is determined at this stage.',
    ],
  },
};

const items = [
  {
    title: 'Pre-Audit',
    statuses: [
      { key: UPLOADED_SUCCESSFULLY, color: 'success', text: 'Completed', isComplete: true, icon: 'mdi-file-upload' },
    ],
  },
  {
    title: 'Approval',
    statuses: [
      { key: undefined, color: 'grey', text: 'Pending' },
      { key: PENDING_FOR_AUDIT_APPROVAL, color: 'warning', text: 'In Progress', isInProgress: true, icon: 'mdi-eye' },
      { key: APPROVED_FOR_AUDIT, color: 'success', text: 'Completed', isComplete: true, icon: 'mdi-check' },
      { key: REJECTED_FOR_AUDIT, color: 'error', text: 'Rejected', isFail: true, icon: 'mdi-close' },
    ],
  },
  {
    title: 'Audit',
    statuses: [
      { key: undefined, color: 'grey', text: 'Pending' },
      { key: AUDIT_IN_PROGRESS, color: 'warning', text: 'In Progress', isInProgress: true },
      { key: AUDIT_COMPLETED, color: 'success', text: 'Completed', isComplete: true },
    ],
  },
  {
    title: 'Processing',
    statuses: [
      { key: undefined, color: 'grey', text: 'Pending' },
      { key: PROCESSING_IN_PROGRESS, color: 'warning', text: 'In Progress', isInProgress: true },
      { key: PROCESSING_COMPLETED, color: 'success', text: 'Completed', isComplete: true },
    ],
  },
  {
    title: 'Deployment',
    statuses: [
      { key: undefined, color: 'grey', text: 'Pending' },
      { key: DEPLOYED, color: 'success', text: 'Completed', isComplete: true },
    ],
  },
].map((i, j) => ({
  ...i,
  step: j + 1,
  isComplete: false,
}));

export const hierarchy = {
  [UPLOADED_SUCCESSFULLY]: 0,
  [PENDING_FOR_AUDIT_APPROVAL]: 1,
  [APPROVED_FOR_AUDIT]: 2,
  [REJECTED_FOR_AUDIT]: 2,
  [AUDIT_IN_PROGRESS]: 3,
  [AUDIT_COMPLETED]: 4,
  [PROCESSING_IN_PROGRESS]: 5,
  [PROCESSING_COMPLETED]: 6,
  [DEPLOYED]: 7,
};

export default {
  items,
  metaInfo,
  current: null,
  hierarchy,
  resetItems () {
    this.items = items;
  },
  setCurrentToInProgress () {
    const item = this.items.find(i => i.isInProgress);
    this.current = item?.step ?? 1;
  },
  isPendingApproval () {
    const item = this.items.find(i => i.step === this.current);
    return hierarchy[item?.key] === hierarchy[PENDING_FOR_AUDIT_APPROVAL] && item?.isInProgress;
  },
};
