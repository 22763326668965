import { uniqBy } from 'lodash';

export const state = {
  crumbs: [],
};

export const getters = {
  hasCrumbs: state => !!state.crumbs.length,
  crumbs: state => state.crumbs,
  total: state => state.crumbs.length,
};

export const mutations = {
  SET_CRUMBS (state, { crumbs, title }) {
    state.crumbs = uniqBy(crumbs.matched.map((route, i) => {
      const text = ((route.meta.title.includes('Edit') || route.meta.title.includes('Single')) ? route.meta.title : title)
        .replace(/(.* Edit)$/, 'Edit')
        .replace(/(.* Single)$/, title)
        .replace(/(.* Trashed)$/, title);

      return {
        id: i + 1,
        text: route.meta.isTitleOverrideable ? text : route.meta.title,
        to: { name: route.name, params: crumbs.params },
        disabled: false,
        meta: route.meta,
      };
    }), e => e.text);
  },
};

export const actions = {
  set ({ commit }, payload) {
    commit('SET_CRUMBS', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
