<template>
  <span class="d-block white--text caption text-right mb-1 panzoom-exclude">
    <slot>{{ $trans('Page {page}', { page }) }}</slot>
  </span>
</template>

<script>
export default {
  name: 'PanzoomPdfPreviewerPageItemNumber',

  props: {
    page: {
      type: [ String, Number ],
      default: null,
    },
  },
};
</script>
