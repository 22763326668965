import Manifest from '@core/manifests/Manifest';
import APP from '@core/enums/App';
import { sortBy } from 'lodash';

class DatalakeManifest extends Manifest {
  constructor (attributes = {}) {
    super(attributes);

    this.makeContext();
    this.generateItems();
  }

  makeContext () {
    this.requireTab = require.context(
      // The relative path of the routes folder
      '@/modules',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base names
      /(config)\/(datalake)\.js$/,
    );

    this.tabs = this.requireTab.keys().sort().map(t => this.makeTab(t));
    
    if(APP.MODE=="DATA_ATELIER"){
      this.tabs = [...this.tabs[0].filter(i=> i.name!='Publications'),this.tabs[1]];
    }
    
  }

  makeTab (tab) {
    const item = this.requireTab(tab);

    return (item?.default ?? item ?? []).map((t, i) => ({ ...t, sort: t.sort ?? i }));
  }

  generateItems () {
    this.setItems(this.tabs);
  }

  setItems (items) {
    this.items = items;
  }

  getItems () {
    return sortBy(this.items.flat(), 'sort');
  }
}

export default new DatalakeManifest;
