<template>
  <v-layout justify-start class="mx-3">
    <v-divider vertical class="mx-4 w-100"></v-divider>
    <div class="px-2">
      <div class="caption muted--text" v-text="$trans('Powered by')"></div>
      <img
        :src="qeyeLogo"
        :alt="$trans('Powered by Qeye')"
        width="auto" height="32px"
      >
    </div>
  </v-layout>
</template>

<script>
import qeyeLogo from '@/modules/RockPhysics/assets/qeye.logo.png';

export default {
  name: 'QeyeLogoBrand',

  data: () => ({
    qeyeLogo,
  }),
};
</script>
