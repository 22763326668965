<template>
  <app-cache-buster-container v-model="isShown" :non-obtrusive="isNonObtrusive">
    <template v-slot:title>
      <h2 class="mb-4 title">
        <span v-text="$trans('New Updates Detected')"></span>
      </h2>
    </template>

    <template v-slot:illustration>
      <div class="text-center" style="opacity: 0.8;">
        <icon-single-leaf class="accent--text"></icon-single-leaf>
      </div>
    </template>
    <!-- eslint-disable max-len -->
    <p
      v-text="$trans('{name} has detected you are currently running an older version of it and might not display the latest updates available.', {
        name: appTitle,
      })"
    ></p>
    <p
      v-text="$trans('Please click on update to retrieve the latest version for {name}.', {
        name: appTitle,
      })"
    ></p>
    <p v-text="$trans('This action will sign out your account.')"></p>
    <template v-slot:action>
      <v-btn
        depressed
        class="ml-3 px-4"
        @click="hideDialog"
        v-text="$trans('Cancel')"
      ></v-btn>
      <v-btn
        :disabled="okButtonDisabled"
        :loading="okButtonDisabled"
        color="primary"
        width="210"
        class="ml-3 px-4"
        @click="clearCache"
      >
        <span v-text="$trans('Sign out & Update')"></span>
        <template v-slot:loader>
          <v-slide-x-transition>
            <v-icon small left dark>mdi-loading mdi-spin</v-icon>
          </v-slide-x-transition>
          <span>{{ $trans('Sign out & Update') }}</span>
        </template>
      </v-btn>
    </template>
  </app-cache-buster-container>
</template>

<script>
import APP from '@core/enums/App';
import CacheResource from '@core/resources/CacheResource';

export default {
  name: 'AppCacheBusterDialog',

  data: () => ({
    isShown: false,
    okButtonDisabled: false,
    appTitle: APP.SHORT_NAME,
    isNonObtrusive: CacheResource.isNonObtrusive(),
  }),

  mounted () {
    CacheResource.storeEnvironmentCacheKeyIfNotExists();
    this.checkCache();
  },

  methods: {
    hideDialog () {
      this.isShown = false;
    },

    checkCache () {
      setTimeout(() => {
        if (CacheResource.isEnabled() && CacheResource.environmentCacheKeyChanged()) {
          this.isShown = true;
        }
      }, CacheResource.getCheckTimeoutDelay());
    },

    clearCache () {
      this.okButtonDisabled = true;
      CacheResource.nukeEm();
    },
  },
};
</script>
