import GeolocationAndDensity from '@/modules/About/assets/GeolocationAndDensity.png';
import MetadataExtraction from '@/modules/About/assets/MetadataExtraction.png';
import ClassificationAndClustering from '@/modules/About/assets/ClassificationAndClustering.png';
import GlobalElastic from '@/modules/About/assets/GlobalElastic.png';
import DAStep1 from '@/modules/About/assets/8-Step_Process/DA-step-1.jpg';
import DAStep2 from '@/modules/About/assets/8-Step_Process/DA-step-2.jpg';
import DAStep3 from '@/modules/About/assets/8-Step_Process/DA-step-3.jpg';
import DAStep4 from '@/modules/About/assets/8-Step_Process/DA-step-4.jpg';
import DAStep5 from '@/modules/About/assets/8-Step_Process/DA-step-5.png';
import DAStep6 from '@/modules/About/assets/8-Step_Process/DA-step-6.jpg';
import DAStep7 from '@/modules/About/assets/8-Step_Process/DA-step-7.jpg';
import DAStep8 from '@/modules/About/assets/8-Step_Process/DA-step-8.jpg';

export default class About {
  constructor () {
    this.loading = false;

    this.data = {
      title: "What's new in ElasticDocs this month",
      description: 'Recent fixes and new additions to the platform this month.',
      new: [
        {
          title: 'Workflows: Petrophysics',
          subtitle: 'Petrophysical plots are now viewable on ElasticDocs. <br/> Viewing is available in two modes: <span class="font-weight-bold font-italic">Preview</span> and <span class="font-weight-bold font-italic">Interactive</span>.',
          body: '<h4>Preview Mode</h4> <p class="mb-5">displays a compiled image of all the available plots to give the user an overview. </p> <h4>Interactive mode</h4> <p class="mb-5">allows for the user to explore the data with provisions for zooming into the graph and fetching data values.</p>',
        },
        {
          title: 'Search View: Knowledge Graph Widget',
          subtitle: 'The Knowledge Graph Widget displays well relations based on mentions in their final well reports. <br/> The following are the available interactions in the widget:',
          body: "<ul><li class=\"mb-3\">Clicking each search result card will fetch the knowledge graph network of the well associated with the document.</li><li class=\"mb-3\">Clicking the nodes (aside from the focused one) will update the current search query to include the well name 'CURRENT QUERY' 'WELL_NAME 1'.</li><li class=\"mb-3\">Clicking the links between wells will update the current search to 'WELL_NAME 1' 'WELL_NAME 2'.</li></ul>",
        },
      ],
      pillars: {
        names: [
          'Information Geolocation and Density',
          'Metadata Extraction',
          'Auto-Image Classification and Clustering',
          'Global Elastic Search in Corpus',
        ],
        contents: [
          {
            count: '1',
            title: 'Information Geolocation and Density',
            image: GeolocationAndDensity,
            description: '<p>Unstructured geoscience data are loaded and linked to wells that can be geospatially displayed in a WebGIS interface.</p> <p>Equipped with cultural and physical geographic information systems (GIS) layers, the WebGIS interface aid in characterizing insights on the larger spatial context of the well point dataset density or data gaps within the area of investigation.</p> <p>ELASTICDOCS aids in identifying the next opportunities beyond being a catch-all petroleum industry knowledge management database.</p>',
          },
          {
            count: '2',
            title: 'Metadata Extraction',
            image: MetadataExtraction,
            description: '<p>Detection of key information within the digitized texts are tagged using Natural Language Processing (NLP), creating tokens for each digitized text that are primed to have significant search value.</p> <p>Named Entity Recognition (NER) is then performed to create a model (Ratinov et al., 2009) that extracts the metadata like well name, basin, permit, operator, well classification, location coordinates, spud dates and depth information on each summary page.</p>',
          },
          {
            count: '3',
            title: 'Auto-Image Classification and Clustering',
            image: ClassificationAndClustering,
            description: '<p>Apart from extracting metadata, images are also collected and automatically classified using a very deep convolutional neural network architecture with 16 weight layers corresponding to 138 million parameters (Simonyan et al., 2014).</p> <p>The model automates the classification of seismic images, maps, stratigraphic charts and core samples with a precision range of 0.80-1.00.</p>',
          },
          {
            count: '4',
            title: 'Global Elastic Search in Corpus',
            image: GlobalElastic,
            description: '<p>A global search can be done to discover information from all of the documents digitized through ElasticDocs.</p> <p>This search engine can return results both from the text within the documents and text on extracted images which includes labels, captions and other visible annotations on it.</p> <p>Text results are all geolocated according to the identified well information from a particular results’ metadata.</p>',
          },
        ],
      },
      eightStepProcess: {
        names: [
          'Data Governance',
          'The Extraction Pipeline',
          'The Aggregator Pipeline',
          'The Data Warehouse',
          'The Helmsman',
          'The Machine Learning (ML) Application Layer',
          'The Internal Connectors',
          'External Connectors',
        ],
        contents: [
          {
            count: '1',
            title: 'Data Governance',
            image: DAStep1,
            description: `
              <p>The Data Atelier will organize the data, sort out the duplicates and flag any issues during the preliminary audit. It uses a multitude of the most advanced libraries, in terms of web development framework, for quality control and data analysis. Files that are analogue will go through an OCR process. For older documents this may be dicult thus troublesome files will be flagged for a refining process.</p>
            `,
          },
          {
            count: '2',
            title: 'The Extraction Pipeline',
            image: DAStep2,
            description: `
              <p>Data are extracted and classified into different categories through this pipeline, which includes both standard text and image elements, and industry-specific image classes.</p>
            `,
          },
          {
            count: '3',
            title: 'The Aggregator Pipeline',
            image: DAStep3,
            description: `
              <p>This pipeline combines various metadata components like file tagging, domain tagging and client databases with the extracted information from the unstructured data processing.</p>
            `,
          },
          {
            count: '4',
            title: 'The Data Warehouse',
            image: DAStep4,
            description: `
              <p>This step enables faster and more robust big data insights, making it accessible to all users across teams, maximizing the value of the large database. It will be a consistent and quality assured database. Rapid understanding is feasible as it is possible to rapidly test theories and generate evidence-based understanding. This ensures higher quality results and faster turnaround times.</p>
              <p>The Data Warehouse also tracks, catalogues, and monitors all extracted digital data. With its unique ID system, it delivers and connects the resulting structured data to the various stakeholders within the organization via API links. Data managers can track the usage of data and allocate additional resources, where necessary.</p>
              <p>Meanwhile, newly processed data can be accessed constantly by the data scientists in the organization. These data can then go through additional processes such as cognitive search, autoimage classification, and data analytics. The Data Warehouse supports effective user and data governance within the Data Atelier.</p>
            `,
            leftWidth: 6,
            rightWidth: 5,
          },
          {
            count: '5',
            title: 'The Helmsman',
            image: DAStep5,
            description: `
              <p>Iraya recognizes that different enterprise teams follow different digital processes. Our internal data orchestrator, the Helmsman, enables the Data Atelier to scale horizontally. This tool allows for the processing of different workflows and pipelines concurrently, which means that there is a huge level of flexibility in handling various tasks. This provides scalability to support enterprise digital transformation initiatives.</p>
            `,
          },
          {
            count: '6',
            title: 'The Machine Learning (ML) Application Layer',
            image: DAStep6,
            description: `
              <p>These can be either internal or external applications that can be combined to create new machine learning services. Data Version Control (DVC) is implemented to ensure that ML models are shareable and reproducible. It records & queries experiments, packages data science codes, and manages models in a central repository.</p>
            `,
          },
          {
            count: '7',
            title: 'The Internal Connectors',
            image: DAStep7,
            description: `
              <p>This connects the Data Atelier to ElasticDocs and makes the data ready for analysis for both upstream and downstream applications. Developers can build new dashboard analytics or integrate existing legacy systems. This feature allows for constant upcycling of data, thereby achieving the goal of data sustainability where you can maximize the use of existing knowledge to create new insights.</p>
            `,
          },
          {
            count: '8',
            title: 'External Connectors',
            image: DAStep8,
            description: `
              <p>This final step allows you to connect the Data Atelier to software of your choice.</p>
              <p>Here are a few external connectors that can be paired with the Data Atelier:</p>
              <ul>
                <li>Microsoft Azure for Blob Storage and Data Lake</li>
                <li>Amazon Web Services, including AWS S3, Batch Service, Redshift, DynamoDB, etc</li>
                <li>Data Bricks</li>
                <li>Google Cloud Platform, including Big Query, Cloud SQL, Bigtable</li>
              </ul>
            `,
          },
        ],
      },
    };
  }
}
