import {
  RESULTS_WELL_OPTIONS,
  DEFAULT_FIT_BOUNDS_PADDING,
  SELECTED_WELL_OPTIONS,
} from '@/modules/Dashboard/config/maps';
import { coordsToGeoJson, toGeoJson } from '@/modules/Dashboard/utils/map';
import Map from '@/modules/Search/resources/Map';
import {
  POST_VIZ_SPATIAL_WELL_FITLER,
  EXPORT_SCATTERPLOT_URL,
  GET_SCATTERPLOT_LIST_URL,
  GET_SCATTERPLOT_SUMMARY_URL,
} from '@/modules/Webgis/api/viz';
import L from 'leaflet';
import 'leaflet.minichart/dist/leaflet.minichart.min.js';
import {
  isEmpty, isArray, has, size,
} from 'lodash';
import {
  DEFAULT_SCATTERPLOT_STYLE,
  GREEN_ACTIVE_SCATTERPLOT_STYLE,
  ACTIVE_SCATTERPLOT_STYLE,
  DEACTIVE_SCATTERPLOT_STYLE,
} from '@/modules/Webgis/config/viz';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { API_PER_PAGE_ALL } from '@config/api';
import { GET_WELLS_INFO_URL } from '@/modules/Dashboard/api/wells';

export default class ScatterPlotDataViz extends Map {
  constructor (map, webgisMap) {
    super();

    this.map = map;
    this.webgisMap = webgisMap;
    this.oldScatterPlotLayer = null;
    this.backgroundLayer = L.featureGroup();
    this.scatterPlotLayer = L.featureGroup();

    this.setMeta({
      spatialWells: [],
      selectedWells: [],
      isSpatialFiltered: false,
      isFetchingSummary: false,
      isRangedFetch: false,
    });
  }

  setRange (range) {
    this.meta.isRangedFetch = true;
    this.meta.hasSpatialWells = !isEmpty(this.meta.spatialWells);
    this.store.dispatch('viz/scatterplot/setRange', range);

    return this;
  }

  unsetRange () {
    this.store.dispatch('viz/scatterplot/unsetRange');
    this.meta.hasSpatialWells = false;

    return this;
  }

  getDefaultParams () {
    const meta = this.store.getters['viz/scatterplot/meta'];

    return {
      projects_list: this.getProjectIds(),
      y_min: meta.range.currentMinDepth,
      y_max: meta.range.currentMaxDepth,
      descriptor: this.store.getters['viz/scatterplot/descriptor'],
    };
  }

  async listGeoFilteredWells (geoPolygon) {
    this.startLoading();
    this.scatterPlotLayer.eachLayer(l => l.setStyle(DEACTIVE_SCATTERPLOT_STYLE));

    const user = this.store.getters['auth/user'];
    if (user.isPermittedTo('webgis_scatter_plot') && !isEmpty(geoPolygon)) {
      const defaultParams = this.getDefaultParams();

      this.meta.isSpatialFiltered = true;

      const params = {
        geo_polygon: geoPolygon,
        descriptor: defaultParams.descriptor,
      };

      const { data } = await this.axios.post(POST_VIZ_SPATIAL_WELL_FITLER, params);

      if (isArray(data.data)) {
        const spatialWells = data.data.map(i => i.id);
        this.meta.spatialWells = spatialWells;
        const scatterParams = {
          well_id_list: spatialWells.join(','),
          descriptor: defaultParams.descriptor,
        };

        const { data: summary } = await this.axios.get(GET_SCATTERPLOT_SUMMARY_URL, {
          params: scatterParams,
        });

        const { data: wells } = await this.axios.get(GET_SCATTERPLOT_LIST_URL, {
          params: {
            ...scatterParams,
            y_min: defaultParams.y_min,
            y_max: defaultParams.y_max,
            descriptor: defaultParams.descriptor,
          },
        });

        this.store.dispatch('viz/scatterplot/setData', {
          updateTable: true,
          updateRange: true,
          type: 'list',
          range: summary.data,
          data: wells.data,
          total: size(wells.data),
          projects: this.getCurrentProjects(),
        });

        const wellHighlights = wells.data.map(i => i.well_id);
        this.scatterPlotLayer.eachLayer(l => {
          if (wellHighlights.includes(l.options.metadata.id)) {
            l.setStyle(RESULTS_WELL_OPTIONS);
          } else {
            l.setStyle(DEACTIVE_SCATTERPLOT_STYLE);
          }
        });

        // setTimeout(() => {
        //   this.store.dispatch('viz/scatterplot/show');
        // }, 300);
      }
    }

    this.stopLoading();
  }

  async listSummary () {
    try {
      this.meta.isFetchingSummary = true;
      const { data } = await this.axios.get(GET_SCATTERPLOT_SUMMARY_URL);

      this.setMeta({
        summary: data.data,
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.meta.isFetchingSummary = false;
    }
  }

  async listMapMarkers (options = { updateBounds: true, updateRange: false }) {
    this.startLoading();
    this.meta.isFetchingSummary = true;
    this.webgisMap.clearBackgroundMarkers();
    this.listBackgroundMarkers(options);

    let params = this.getDefaultParams();
    if (this.meta.hasSpatialWells) {
      params = {
        well_id_list: this.meta.spatialWells.join(','),
        descriptor: params.descriptor,
        y_min: params.y_min,
        y_max: params.y_max,
      };
    }

    const activeWells = this.store.getters['viz/scatterplot/activeWells']?.map(well => well.wellId);
    if (!isEmpty(activeWells)) {
      params.well_id_list = activeWells.join(',');
    }

    const { data } = await this.axios.get(GET_SCATTERPLOT_LIST_URL, { params });

    if (isArray(data.data)) {
      this.setItems(data.data);

      this.store.dispatch('viz/scatterplot/setData', {
        ...options,
        updateTable: true,
        type: 'list',
        data: this.items,
        total: size(this.items),
        projects: this.getCurrentProjects(),
      });

      this.setItemsAsScatterPlotMarkers();
      this.addScatterPlotMarkersToMap();
      this.setMapMarkerColors(data.data);
    }

    if (has(data.data, 'msg')) {
      this.store.dispatch('viz/scatterplot/setData', {
        type: 'list',
        data: [],
        total: 0,
        projects: this.getCurrentProjects(),
      });
      this.setErrors(data.data.msg);
    }

    this.stopLoading();
    this.meta.isFetchingSummary = false;
    this.meta.isRangedFetch = false;
  }

  setItems (items) {
    this.items = items.map(item => ({
      ...item,
      id: item.id,
      wellId: item.well_id,
      attributes: item,
    }));
  }

  setItemsAsScatterPlotMarkers () {
    // Gray out existing map markers
    // if (this.scatterPlotLayer) {
    //   this.scatterPlotLayer.eachLayer(l => l.setStyle(GREEN_ACTIVE_SCATTERPLOT_STYLE));
    // }

    // this.webgisMap.clearBackgroundMarkers();
    const wellMarkerItems = this.getItems().map(i => i.well_id);

    coordsToGeoJson(this.getItems()).forEach(feature => {
      this.setScatterPlotMarkerWithPopup({ ...feature, ...feature.properties });
    });

    if (this.scatterPlotLayer) {
      this.scatterPlotLayer.eachLayer(l => {
        if (!wellMarkerItems.includes(l.options.metadata.id)) {
          l.setStyle(ACTIVE_SCATTERPLOT_STYLE);
        } else {
          l.setStyle(ACTIVE_SCATTERPLOT_STYLE);
        }
      });
    }
  }

  addScatterPlotMarkersToMap () {
    if (this.scatterPlotLayer) {
      this.scatterPlotLayer.bringToFront();
      // this.scatterPlotLayer.eachLayer(l => l.setStyle(GREEN_ACTIVE_SCATTERPLOT_STYLE));
      this.map.addLayer(this.scatterPlotLayer);

      if (!isEmpty(this.scatterPlotLayer.getBounds())) {
        this.map.fitBounds(this.scatterPlotLayer.getBounds(), {
          padding: DEFAULT_FIT_BOUNDS_PADDING,
        });
      }

      this.map.on('click', async () => {
        const isSpatialOn = this.store.getters['webgis/spatial/isOn'];
        // if (this.scatterPlotLayer) {
        //   this.scatterPlotLayer.eachLayer(l => l.setStyle(GREEN_ACTIVE_SCATTERPLOT_STYLE));
        // }

        if (this.isEnabled()) {
          if (!isSpatialOn) {
            await this.listMapMarkers();
          } else {
            console.log('Not fetching. Spatial filter is on');
          }

          const selected = this.store.getters['viz/viz/selected'];
          if (selected.includes('scatterPlot')) {
            this.showMapMarkers();
          } else {
            this.hideMapMarkers();
          }
        }
      });
    }
  }

  setScatterPlotMarkerWithPopup (data) {
    const {
      popup, latLng,
    } = this.setMarkerWithPopup(data);

    const layer = L.circleMarker(latLng, {
      stroke: false,
      color: 'rgba(00, 00, 00, 0.8)',
      weight: 0.5,
      fill: true,
      fillColor: 'rgba(00, 00, 00, 0.8)',
      fillOpacity: 1,
      radius: 5,
      riseOnHover: true,
      metadata: data,
      opacity: 1,
      ...ACTIVE_SCATTERPLOT_STYLE,
    });

    if (this.scatterPlotLayer) {
      let exists = false;
      this.scatterPlotLayer.eachLayer(l => {
        if (l.options.metadata.id === layer.options.metadata.id) {
          exists = true;
        }
      });

      if (!exists) {
        this.scatterPlotLayer.addLayer(layer);
      }
    }

    layer.bindTooltip(`<div>
      <div><strong>Latitude</strong>: <span>${data.latitude}</span</div>
      <div><strong>Longitude</strong>: <span>${data.longitude}</span</div>
      <div><strong>First well name</strong>: <span>${data.well_name}</span</div>
    </div>`);

    layer.on('click', e => {
      L.DomEvent.stopPropagation(e);
      if (this.scatterPlotLayer) {
        this.scatterPlotLayer.eachLayer(l => l.setStyle(ACTIVE_SCATTERPLOT_STYLE));
      }
      layer.setStyle(SELECTED_WELL_OPTIONS);
      this.onScatterPlotMarkerClick({ layer: e, well: { ...data, active: true } });
    });

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  async onScatterPlotMarkerClick ({ well }, options = { updateTable: true }) {
    this.disableAxiosResponseHandlers();

    try {
      const defaultParams = this.getDefaultParams();
      const params = {
        well_id_list: well.well_id,
        y_min: defaultParams.y_min,
        y_max: defaultParams.y_max,
        descriptor: defaultParams.descriptor,
      };
      const { data } = await this.axios.get(GET_SCATTERPLOT_LIST_URL, { params });

      if (has(data.data, 'msg')) {
        this.store.dispatch('viz/scatterplot/setData', {
          type: 'list',
          data: [],
          total: 0,
          projects: this.getCurrentProjects(),
        });
        // this.setErrors(data.data.msg);
      } else {
        this.setData(data.data[0]);
        this.store.dispatch('viz/scatterplot/setData', {
          updateTable: options.updateTable,
          type: 'single',
          data: data.data[0],
          total: size(data.data[0]),
          projects: this.getCurrentProjects(),
        });
      }

      if (this.scatterPlotLayer) {
        this.setMapMarkerColors(isEmpty(this.meta.selectedWells)
          ? data.data[0] : this.meta.selectedWells);

        this.webgisMap.clearSelectedLayers();
        this.scatterPlotLayer.eachLayer(l => {
          if (l.options.metadata.id === (well.wellId ?? well.well_id) && well.active) {
            l.setStyle(SELECTED_WELL_OPTIONS);
          }
        });
      }

      this.store.dispatch('viz/scatterplot/show');
    } finally {
      this.enableAxiosResponseHandlers();
    }
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }

  getCurrentProjects () {
    const ids = this.store.getters['sourcetray/sources']?.map(i => i.id);

    return this.store.getters['sourcetray/projects'].filter(i => ids.includes(i.id));
  }

  showMapMarkers () {
    this.scatterPlotLayer = this.oldScatterPlotLayer ?? this.scatterPlotLayer;
    if (this.scatterPlotLayer) {
      this.setMapMarkerColors(this.meta.selectedWells);
      // this.scatterPlotLayer.eachLayer(l => l.setStyle(GREEN_ACTIVE_SCATTERPLOT_STYLE));
      this.map.addLayer(this.scatterPlotLayer);
    }
  }

  hideMapMarkers () {
    this.oldScatterPlotLayer = this.scatterPlotLayer;
    if (this.scatterPlotLayer) {
      this.scatterPlotLayer.eachLayer(l => l.setStyle({ opacity: 0 }));
      this.map.removeLayer(this.scatterPlotLayer);
    }
  }

  exportItemOrAll (item) {
    if (item.isSingle()) {
      this.export(item.data);
    }

    if (item.isList()) {
      this.export(item.data.map(i => i.wellId ?? i.id ?? i.well_id));
    }
  }

  async export (wells, prefix = '', key = 'well_id_list') {
    const formData = new FormData;

    if (isArray(wells)) {
      formData.append(key, wells.join(','));
    } else {
      formData.append(key, wells.well_id);
    }

    formData.append('descriptor', this.store.getters['viz/scatterplot/descriptor']);

    const { data } = await this.axios.post(EXPORT_SCATTERPLOT_URL, formData, {
      headers: {
        'Content-Type': 'arraybuffer',
      },
    });

    const date = moment().format('YYYY-MM-DD-hhmmss');
    return fileDownload(data, `${prefix}scatterplot-data-exported-${date}.csv`);
  }

  bringMapMarkersToFront () {
    if (this.scatterPlotLayer) {
      this.scatterPlotLayer.bringToFront();
    }
  }

  async onClearDrawFilters () {
    const selected = this.store.getters['viz/viz/selected'];
    this.meta.hasSpatialWells = false;

    await this.unsetRange().listMapMarkers({ updateBounds: false, updateRange: true });

    if (this.scatterPlotLayer) {
      this.scatterPlotLayer.eachLayer(l => l.setStyle(ACTIVE_SCATTERPLOT_STYLE));
    }

    if (selected.includes('scatterPlot')) {
      this.showMapMarkers();
    } else {
      this.hideMapMarkers();
    }
  }

  setMapMarkerColors (wells) {
    this.meta.selectedWells = wells;
    const wellIds = isArray(wells)
      ? (wells ?? []).map(i => i?.well_id ?? i)
      : wells?.well_id;

    if (wellIds) {
      this.scatterPlotLayer.eachLayer(l => l.setStyle(DEFAULT_SCATTERPLOT_STYLE));
      this.scatterPlotLayer.eachLayer(l => {
        if ((wellIds ?? []).includes(l.options.metadata.id)) {
          l.setStyle(GREEN_ACTIVE_SCATTERPLOT_STYLE);
        } else {
          l.setStyle(DEFAULT_SCATTERPLOT_STYLE);
        }
      });
    }
  }

  onDescriptorChange () {
    this.webgisMap.clearDrawFilters();
  }

  onMapMarkerClicked () {
    const wellIds = (this.meta?.selectedWells ?? []).map(i => i.well_id);

    if (this.scatterPlotLayer) {
      this.scatterPlotLayer.eachLayer(l => {
        if (wellIds.includes(l.options.metadata.id)) {
          l.setStyle(GREEN_ACTIVE_SCATTERPLOT_STYLE);
        } else {
          l.setStyle(DEFAULT_SCATTERPLOT_STYLE);
        }
      });
    }
  }

  async listBackgroundMarkers (options = { updateBounds: true }) {
    const params = {
      projects_list: this.getProjectIds(),
      order_by: 'well_name',
      page_size: API_PER_PAGE_ALL,
      page: 1,
    };

    const { data } = await this.axios.get(GET_WELLS_INFO_URL, { params });

    if (this.backgroundLayer) {
      this.backgroundLayer.clearLayers();
    }

    toGeoJson(data.data).forEach(feature => {
      this.setBackgroundMarkerWithPopup({ ...feature, ...feature.properties });
    });

    if (this.backgroundLayer) {
      this.backgroundLayer.bringToBack();
      this.map.addLayer(this.backgroundLayer);

      if (!isEmpty(this.backgroundLayer.getBounds()) && options.updateBounds) {
        this.map.fitBounds(this.backgroundLayer.getBounds(), {
          padding: DEFAULT_FIT_BOUNDS_PADDING,
        });
      }
    }
  }

  setBackgroundMarkerWithPopup (data) {
    const {
      popup, latLng, map,
    } = this.setMarkerWithPopup(data);

    const layer = L.circleMarker(latLng, {
      stroke: false,
      color: 'rgba(00, 00, 00, 0.8)',
      weight: 0.5,
      fill: true,
      fillColor: 'rgba(00, 00, 00, 0.8)',
      fillOpacity: 1,
      radius: 5,
      riseOnHover: true,
      metadata: data,
      opacity: 1,
      ...DEACTIVE_SCATTERPLOT_STYLE,
    });

    this.setStyles(layer, map);

    if (this.backgroundLayer) {
      this.backgroundLayer.addLayer(layer);
    }

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  highlightWellFromId (wellId) {
    this.scatterPlotLayer.eachLayer(l => {
      if (l.options.metadata.id === wellId) {
        l.setStyle(SELECTED_WELL_OPTIONS);
      } else {
        l.setStyle(RESULTS_WELL_OPTIONS);
      }
    });
  }

  isEnabled () {
    const viz = this.store.getters['viz/viz/viz'];

    return viz.isSelected('scatterPlot');
  }
}
