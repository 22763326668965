<template>
  <v-row>
    <meta-tag :title="$trans('Account Recovery')"></meta-tag>
    <v-col
      class="mx-auto"
      cols="12"
      xl="3"
      lg="4"
      md="4"
      sm="10"
    >
      <app-brand-banner
        class="mx-auto text-center ma-4"
        width="320"
      ></app-brand-banner>

      <validation-observer ref="resetform" v-slot="{ handleSubmit }">
        <v-form
          v-model="form.valid"
          :disabled="form.loading"
          method="post"
          @submit.prevent="handleSubmit(submit)"
        >
          <v-card class="mt-10">
            <v-card-title>
              <h2>{{ $trans('Account Recovery') }}</h2>
            </v-card-title>
            <v-card-text>
              <!-- eslint-disable-next-line max-len -->
              <p class="heading text-left mb-10" v-html="$trans(`Enter the email you used to register at {title}, and we'll send you a reset link.`, { title: appTitle })"></p>

              <validation-provider
                v-slot="{ errors }"
                name="email"
                mode="passive"
                rules="required|email"
              >
                <v-text-field
                  v-model="form.email"
                  :error-messages="errors"
                  :label="$trans('Email')"
                  autofocus
                  outlined
                  type="email"
                  name="email"
                ></v-text-field>
              </validation-provider>
              <v-btn
                color="primary"
                type="submit"
                block
                x-large
              >
                {{ $trans('Send Reset Link') }}
              </v-btn>

              <p class="mt-6 mb-0 text-caption text-left">
                {{ $trans('Have an account?') }}&nbsp;
                <router-link
                  :to="{ name: 'login' }"
                  class="text-caption"
                >
                  {{ $trans('Sign in here.') }}
                </router-link>
              </p>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>

      <auth-footer class="text-center"></auth-footer>
    </v-col>
  </v-row>
</template>

<script>
import Auth from '@/modules/Auth/resources/Auth';
import { mapGetters } from 'vuex';

export default {
  layout: 'auth',

  data: () => ({
    form: new Auth,
  }),

  computed: {
    ...mapGetters({
      appTitle: 'meta/apptitle',
    }),

    validator () {
      return this.$refs?.resetform;
    },
  },

  methods: {
    submit () {
      this.form.setValidator(this.validator).sendEmailResetRecoveryEmail();
    },
  },
};
</script>
