<template>
  <div class="google-recaptcha-checkbox">
    <slot v-bind="{ hint, errorMessages, token: recaptchaToken, recaptcha }">
      <v-checkbox
        :label="$trans('I\'m not a Robot')"
        :error-messages="errorMessages"
        :hint="hint"
        name="recaptcha"
        persistent-hint
        @change="recaptcha"
      >
        <template v-slot:message="{ message, key }">
          <span :key="key" v-html="message"></span>
        </template>
      </v-checkbox>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'BtnGoogleRecaptchaV3',

  props: {
    errorMessages: {
      type: Array,
      default: () => [],
    },
    action: {
      type: String,
      default: 'social',
    },
  },

  data: () => ({
    recaptchaToken: null,
    loading: false,
    hint: null,
  }),

  beforeRouteLeave (to, from, next) {
    this.$recaptchaInstance.hideBadge();
    next();
  },

  methods: {
    async recaptcha (isChecked) {
      if (isChecked) {
        this.loading = true;
        await this.$recaptchaLoaded();
        this.recaptchaToken = await this.$recaptcha(this.action);
        this.loading = false;
        this.$emit('update:token', this.recaptchaToken);
      }
    },
  },
};
</script>

<style lang="scss">
.grecaptcha-badge { visibility: hidden; }

.google-recaptcha-checkbox .v-messages__message {
  margin-left: 30px;

  a,
  span {
    font-size: 14px;
    line-height: 1.25;
  }
}
</style>
