<template>
  <div
    v-shortkey.push="{ ctrl: ['ctrl'], alt: ['alt'] }"
    @keyup="hideShortcuts"
    @shortkey="displayShortcuts"
  >
    <slot></slot>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'Shortkey',

  computed: {
    ...mapGetters({
      ctrlIsPressed: 'shortkey/ctrlIsPressed',
      altIsPressed: 'shortkey/altIsPressed',
    }),
  },

  watch: {
    ctrlIsPressed (isPressed) {
      if (!isPressed) {
        this.hideShortcuts();
      }
    },
    altIsPressed (isPressed) {
      if (!isPressed) {
        this.hideShortcuts();
      }
    },
  },

  created () {
    window.addEventListener('keyup', () => this.hideShortcuts());
  },

  methods: {
    displayShortcuts (event) {
      switch (event.srcKey) {
      case 'ctrl':
        this.$store.dispatch('shortkey/press', {
          ctrlIsPressed: !this.$store.getters['shortkey/ctrlIsPressed'],
        });
        break;
      case 'alt':
        this.$store.dispatch('shortkey/press', {
          altIsPressed: !this.$store.getters['shortkey/altIsPressed'],
        });
        break;
      default:
        break;
      }
    },

    hideShortcuts: debounce(function () {
      this.$store.dispatch('shortkey/press', {
        ctrlIsPressed: false,
        altIsPressed: false,
      });
    }, 500),
  },
};
</script>
