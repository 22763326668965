import DataResource from '@core/resources/DataResource';
import namedavatar from 'namedavatar';
import { initialCase } from '@utils/text';

export default class UserResource extends DataResource {
  constructor (options = {}) {
    super(options);
    this.username = options.data && (options.data.username || options.data.email);
    this.email = options.data && options.data.email;
    this.avatar = (options.data && options.data.avatar) || this.setDefaultAvatar(this.username);
    this.displayname = options.data
      && (options.data.displayname || options.data.username || options.data.email);
  }

  install (Vue) {
    console.warn('UserResource@all is unhandled. You should write it yourself.', Vue);
  }

  isPermittedTo (permission) {
    console.warn('UserResource@isPermittedTo is unhandled. You should write it yourself.', permission);

    return true;
  }

  setDefaultAvatar (text) {
    const svg = namedavatar?.getSVGString(initialCase(text)) ?? null;

    if (svg) {
      return namedavatar.getDataURI(svg);
    }

    return null;
  }
}
