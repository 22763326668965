export const state = {
  buckets: [],
  bucket: null,
  currentBucket: '',
};

export const getters = {
  buckets: state => state.buckets,
  bucket: state => state.bucket,
  currentBucket: state => state.currentBucket,
};

export const mutations = {
  SET_BUCKETS (state, buckets) {
    state.buckets = buckets;
  },

  SET_CURRENT_BUCKET (state, text) {
    state.currentBucket = text;
  },

  SET_BUCKET (state, bucket) {
    state.bucket = bucket;
  },
};

export const actions = {
  buckets ({ commit }, buckets) {
    commit('SET_BUCKETS', buckets);
  },

  setCurrentBucket ({ commit }, text) {
    commit('SET_CURRENT_BUCKET', text);
  },

  setBucket ({ commit }, bucket) {
    commit('SET_BUCKET', bucket);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
