<template>
  <v-app v-cloak class="s-app">
    <v-slide-y-transition mode="in-out">
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col>
            <div class="text-center">
              <v-slide-y-transition mode="out-in">
                <router-view></router-view>
              </v-slide-y-transition>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-slide-y-transition>
  </v-app>
</template>

<script>
export default {
  name: 'Centered',
};
</script>
