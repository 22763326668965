<template>
  <div>
    <img :src="logo" width="300" height="auto">

    <v-carousel
      :show-arrows="false"
      continuous cycle
      hide-delimiter-background
      height="auto"
      class="app-carousel my-15 pb-15"
    >
      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
      >
        <v-sheet
          height="100%"
          color="transparent"
        >
          <v-row
            class="fill-height"
            align="start"
            justify="center"
            no-gutters
          >
            <component :is="slide"></component>
          </v-row>
        </v-sheet>
      </v-carousel-item></v-carousel>
  </div>
</template>

<script>
import RegisterPageCarouselManifest from '@/modules/Auth/manifests/RegisterPageCarouselManifest';

export default {
  name: 'MessageRegistrationPage',

  computed: {
    slides () {
      return RegisterPageCarouselManifest.getCarouselItems();
    },
    logo () {
      return this.$asset.getAppAsset('logos/combomark', {
        modifier: 'default.',
      });
    },
  },
};
</script>
