<template>
  <v-tooltip right transition="slide-x-transition">
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="loading"
        :loading="loading"
        min-width="32" width="32"
        min-height="32"
        height="32" class="ma-1"
        v-on="on"
        @click="screenshot"
      >
        <v-icon small>mdi-camera-outline</v-icon>
      </v-btn>
    </template>
    <span v-text="$trans('Take snapshot of current map area')"></span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'MapAreaScreenshotControlBtn',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    screenshot (e) {
      this.$emit('screenshot', e);
    },
  },
};
</script>
