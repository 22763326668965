<template>
  <v-slide-y-reverse-transition mode="out-in">
    <div
      v-if="uploadTrayIsShown"
      :class="contentClass"
      data-tour-step="welcome/onboarding.3"
      data-tour-overlay
      class="file-upload__dialog"
    >
      <v-card
        :class="{
          'file-upload__tray-list--minimized': uploadTray.isMinimized,
        }"
        class="file-upload__tray-list elevation-6"
      >
        <v-layout class="grey lighten-4 pa-4 align-center justify-space-between">
          <div>
            <span
              v-if="isCancelledAll"
              class="subtitle font-weight-bold"
              v-text="$trans('Upload cancelled')"
            ></span>
            <span
              v-else-if="isDoneAll"
              class="subtitle font-weight-bold"
              v-text="$trans_choice('{count} uploads complete', completedFiles.length, {
                count: completedFiles.length,
              })"
            ></span>
            <span
              v-else
              class="subtitle font-weight-bold"
              v-text="$trans_choice('Uploading {total} files', pendingFiles.length, {
                total: pendingFiles.length,
              })"
            ></span>
            <template v-if="uploadTray.isMinimized">
              <span
                v-if="isUploadingAndNotCancelled"
                class="small muted--text ml-2"
                v-text="$trans('{time} remaining', {
                  time: progress.remaining,
                })"
              ></span>
            </template>
          </div>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="onMinimize"
              >
                <v-icon v-if="uploadTray.isMinimized">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <span v-text="$trans_choice('Minimize', uploadTray.isMinimized)"></span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="confirmClose"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span v-text="$trans('Close')"></span>
          </v-tooltip>
        </v-layout>
        <v-sheet class="file-upload__content">
          <v-simple-table dense class="va-top mt-5 rounded-0 text-left">
            <tbody>
              <tr>
                <td style="width: 120px;" class="font-weight-bold">
                  <v-icon
                    v-if="isUploadingAndNotCancelled"
                    left x-small
                  >
                    mdi-loading mdi-spin
                  </v-icon>
                  <v-icon v-else left x-small>mdi-file-document-multiple-outline</v-icon>
                  <span v-text="$trans('Set ID')"></span>
                </td>
                <td>
                  <span
                    v-if="set.isUploading"
                    v-text="$trans('Set ID will be generated once upload is completed')"
                  ></span>
                  <a
                    v-else
                    class="link--text"
                    @click="goToSinglePage(set.id)"
                    v-text="set.id"
                  ></a>
                </td>
              </tr>
              <tr>
                <td style="width: 120px;" class="font-weight-bold">
                  <v-icon left x-small>mdi-tray-full</v-icon>
                  <span v-text="$trans('Project')"></span>
                </td>
                <td v-html="$trans(set.project)"></td>
              </tr>
              <tr>
                <td style="width: 120px;" class="font-weight-bold">
                  <v-icon left x-small>mdi-file-upload-outline</v-icon>
                  <span v-text="$trans('Files')"></span>
                </td>
                <td>
                  <span
                    v-text="$trans_choice('{count} files', files.length, {
                      count: files.length,
                    })"
                  ></span>
                  <span
                    v-if="isUploadingAndNotCancelled"
                    class="small ml-2 muted--text"
                    v-text="$trans('{time} remaining', {
                      time: progress.remaining,
                    })"
                  ></span>
                </td>
              </tr>
              <tr>
                <td style="width: 120px;" class="font-weight-bold">
                  <v-icon left x-small>mdi-message-text-outline</v-icon>
                  <span v-text="$trans('Remarks')"></span>
                </td>
                <td v-html="$trans(set.remarks)"></td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-layout class="px-4 mt-2 align-center">
            <span class="font-weight-bold" v-text="$trans('In Queue')"></span>
            <v-spacer></v-spacer>
            <template v-if="filesIsNotEmpty">
              <v-btn
                v-if="isCancelledAll"
                text
                depressed
                @click="onRetryAll"
                v-text="$trans('Retry')"
              ></v-btn>
              <v-btn
                v-else
                :disabled="set.isDone"
                text
                @click="onCancelAll"
                v-text="$trans('Cancel All')"
              ></v-btn>
            </template>
          </v-layout>

          <!-- Files Queue -->
          <v-list v-if="filesIsNotEmpty" dense>
            <v-list-item v-for="(item, i) in files" :key="i">
              <slot name="item" v-bind="{ item }">
                <v-list-item-content>
                  <v-text-field
                    :value="item.value"
                    :error-messages="item.errors"
                    :loading="isProgressingOrCancelled"
                    :single-line="item.hasNoErrors()"
                    :hide-details="item.hasNoErrors()"
                    dense
                    filled
                    outlined
                    readonly
                  >
                    <template v-slot:prepend-inner>
                      <v-icon :color="getFileTypeColor(item.fileType)" class="mt-1">
                        {{ getFileTypeIconVariant(item.fileType) }}
                      </v-icon>
                    </template>
                    <template v-slot:append>
                      <!-- eslint-disable max-len -->
                      <v-icon v-if="item.isCancelled" color="error" class="mt-1">mdi-information</v-icon>
                      <v-icon v-else-if="progress.isProgressing" class="mt-1">mdi-loading mdi-spin</v-icon>
                      <v-icon v-else-if="item.hasErrors()" color="error" class="mt-1">mdi-alert</v-icon>
                      <v-icon v-else-if="item.isPending" class="mt-1">mdi-upload</v-icon>
                      <v-icon v-else-if="item.isDone" color="success" class="mt-1">mdi-check</v-icon>
                      <!-- eslint-enable max-len -->
                    </template>
                    <template v-slot:progress>
                      <v-progress-linear
                        :value="item.isCancelled ? 100 : progress.progress"
                        :color="item.isCancelled ? 'error' : 'accent'"
                        absolute
                        height="3"
                      ></v-progress-linear>
                    </template>
                  </v-text-field>
                </v-list-item-content>
              </slot>
            </v-list-item>
          </v-list>
          <!-- Files Queue -->

          <!-- Empty Files Queue -->
          <template v-else>
            <v-layout
              column
              align-center
              justify-center
              class="text-center ma-4 rounded pa-4 grey lighten-4"
            >
              <div class="accent--text" style="opacity: 0.5;">
                <icon-landscape-image width="120" height="120"></icon-landscape-image>
              </div>
              <p class="mb-0 muted--text" v-text="$trans('No files in queue')"></p>
            </v-layout>
          </template>
          <!-- Empty Files Queue -->
        </v-sheet>
      </v-card>
      <template v-if="uploadTray.isMinimized">
        <v-progress-linear
          :value="progress.progress"
          :color="isCancelledAll ? 'error' : 'accent'"
          absolute
          height="3"
        ></v-progress-linear>
      </template>
    </div>
  </v-slide-y-reverse-transition>
</template>

<script>
import GetFileTypeIcon from '@core/mixins/GetFileTypeIcon';
import { uploadTray } from '@/modules/File/config/upload';
import { values, isEmpty } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'FileUploadListSystemTray',

  mixins: [ GetFileTypeIcon ],

  props: {
    errorMessages: {
      type: [ Array, Object ],
      default: () => [],
    },
  },

  data: () => ({
    uploadTray,
  }),

  computed: {
    ...mapGetters({
      progress: 'file/uploadProgress',
      set: 'file/set',
    }),

    uploadTrayIsShown: {
      get () {
        return this.$store.getters['file/uploadTrayIsShown'];
      },
      set (isShown) {
        this.$store.dispatch('file/setUploadTrayIsShown', isShown);
      },
    },

    isUploading () {
      return this.set.isUploading;
    },

    isCancelledAll () {
      const cancelledUploadCount = this.files.filter(i => i.isCancelled).length;
      const fileCount = this.files.length;

      return cancelledUploadCount === fileCount;
    },

    isDoneAll () {
      return this.progress.isDone;
    },

    isUploadingAndNotCancelled () {
      return this.set.isUploading && !this.isCancelledAll;
    },

    isProgressingOrCancelled () {
      return this.isCancelledAll || this.progress.isProgressing;
    },

    errorBag () {
      return values(this.errorMessages);
    },

    files () {
      return (this.set?.files ?? []).map(item => {
        const errorBag = this.errorBag?.find(i => i.name === item.name) ?? item;
        const fileType = errorBag.name?.split('.')?.pop() ?? '';

        return {
          fileType,
          id: item.id,
          color: item.color,
          size: item.size,
          name: item.name,
          value: item.name,
          set: errorBag.set,
          errors: errorBag?.error?.detail,
          isDone: this.progress.isDone,
          isPending: !this.progress.isDone,
          isCancelled: this.set.isCancelled,
          hasErrors: () => !isEmpty(errorBag.error?.detail),
          hasNoErrors: () => isEmpty(errorBag.error?.detail),
        };
      });
    },

    pendingFiles () {
      return this.files.filter(i => i.isPending);
    },

    completedFiles () {
      return this.files.filter(i => i.isDone);
    },

    filesIsNotEmpty () {
      return !isEmpty(this.files);
    },

    contentClass () {
      return [
        this.uploadTray.isMinimized ? 'file-upload__dialog--minimized' : null,
      ].join(' ');
    },
  },

  mounted () {
    this.promptOnPageUnloadWhileUploading();
  },

  methods: {
    ...mapActions({
      toggleUploadTrayMinimize: 'file/toggleUploadTrayMinimize',
      hideUploadTray: 'file/hideUploadTray',
      hideUploadForm: 'file/hideUploadForm',
      showUploadForm: 'file/showUploadForm',
      upload: 'file/upload',
    }),

    promptOnPageUnloadWhileUploading () {
      // eslint-disable-next-line consistent-return
      window.onbeforeunload = e => {
        if (this.isUploadingAndNotCancelled) {
          const message = 'Changes you made may not be saved.';
          e.preventDefault();
          e.returnValue = message;

          return message;
        }
      };
    },

    resetTrayList () {
      console.log('tray:reset');
      this.$emit('tray:reset');
    },

    onRetryAll (e) {
      this.$store.dispatch('file/retryUpload');
      this.resetTrayList();
      this.$emit('click:retry', e);
    },

    onAnother (e) {
      this.resetTrayList();
      this.$emit('click:another', e);
    },

    confirmClose (e) {
      if (this.isUploadingAndNotCancelled) {
        this.onCancelAll(e);
      } else {
        this.onClose(e);
      }
    },

    onCancelAll (e) {
      this.$store.dispatch('dialog/show', {
        title: 'Cancel upload?',
        text: [
          '<p>You are about to cancel uploading the files.</p>',
          '<p>Are you sure you want to proceed?</p>',
        ],
        buttons: {
          cancel: { text: 'Continue Uploading' },
          action: {
            text: 'Cancel Upload',
            color: 'error',
            callback: () => this.cancelAll(e),
          },
        },
      });
    },

    onClose (e) {
      this.resetTrayList();
      this.hideUploadTray();
      this.hideUploadForm();
      this.$emit('click:close', e);
      this.$root.$emit('file/upload.systemtray:close');
    },

    cancelAll (e) {
      this.$store.dispatch('file/cancelUpload');
      this.$emit('click:cancel', e);
    },

    onMinimize (e) {
      this.$emit('click:minimize', e);
      this.toggleUploadTrayMinimize();
    },

    goToSinglePage (id) {
      this.hideUploadTray();
      this.$store.dispatch('file/markUploadAsDone');
      this.$root.$emit('click:file/upload.systemtray/goToSinglePage');

      setTimeout(() => {
        this.$router.push({ name: 'sets.show', params: { id } });
      }, 200);
    },
  },
};
</script>

<style lang="scss">
.file-upload {
  &__content {
    max-height: 600px;
    overflow: auto;
  }

  &__content table td a {
    line-height: 1;
  }

  &__dialog {
    bottom: 1.2rem;
    margin: 2rem;
    max-height: calc(100vh - 4rem);
    max-width: 560px;
    position: fixed;
    right: 0;
    transform-origin: center center;
    transition: all 0.05s ease-in-out;
    width: 560px;
    z-index: 10;

    &--minimized {
      bottom: 1.2rem;
      right: 0;
      transform: translateY(0) translateX(0);
    }
  }
}

.file-upload__tray-list {
  transition: all 0.25s ease-in-out;

  &--minimized {
    height: 64px;
    overflow: hidden;
  }
}
</style>
