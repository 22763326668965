<template>
  <app-can-view code="webgis_bar_graph">
    <v-tooltip left transition="slide-x-reverse-transition">
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <viz-bar-graph-map-control-btn
            offset-x nudge-left
            icon="mdi-poll"
            class="ma-1"
          >
            <viz-bar-graph-map-control-card
              @click:export="onExport"
              @click:segment="onSegmentClick"
              @click:reset="onResetClick"
            ></viz-bar-graph-map-control-card>
          </viz-bar-graph-map-control-btn>
        </div>
      </template>
      <span v-text="$trans('View Bar Chart layer data')"></span>
    </v-tooltip>
  </app-can-view>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'VizBarGraphMapControl',

  props: {
    resource: {
      type: Object,
      default: () => {},
    },
  },

  computed: mapGetters({
    isTriggered: 'webgis/spatial/isTriggered',
  }),

  watch: {
    isTriggered: {
      immediate: true,
      handler (isTriggered) {
        if (isTriggered) {
          this.resource.listGeoFilteredWells(this.$route.query.geo_polygon);
        }
      },
    },
  },

  methods: {
    onExport (item) {
      this.resource.exportItemOrAll(item.isSingle() ? item.data : item.meta.wells);
    },

    onSegmentClick (item) {
      this.resource.onSegmentClick(item);
    },

    onResetClick () {
      this.resource.resetList();
    },
  },
};
</script>
