import DataResource from '@core/resources/DataResource';
import { LIST_PUBLICATIONS_URL } from '@/modules/Datalake/api/publications';
import { isEmpty, isArray } from 'lodash';
import { SOURCETYPE_PUBLICATIONS } from '@/modules/Sourcetray/config/sources';

export default class Publication extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async list () {
    this.startLoading();

    const params = this.getQueryString();
    const sources = this.store.getters['sourcetray/sources']
      .filter(source => source.sourceType === SOURCETYPE_PUBLICATIONS)
      .map(source => source.id);
    params.projects_list = sources.join();

    if (sources.length !== 0) {
      const { data } = await this.axios.get(LIST_PUBLICATIONS_URL, { params });

      this.setItems(data.data);

      this.setPagination({
        total: data.meta.pagination.count,
        page: data.meta.pagination.page,
      });
    } else {
      this.setItems([]);
    }

    this.stopLoading();
  }

  setItems (items) {
    this.items = items.map(item => ({ ...item, ...item.attributes, doc_ref: item.relationships.doc_ref.data.id }));

    return this;
  }

  setQueryString (options) {
    const supportedQuery = this.parseOptionsAsSupportedQuery(options);

    this.query = {
      ...this.getQueryString(),
      ...supportedQuery,
    };

    this.query.order_by = this.prependSign(
      supportedQuery.order_by,
      this.isSortDesc(options.sortDesc),
    );

    this.pushRouteQuery();

    return this.mergeOptions(options, false);
  }

  prependSign (key, isTruthy = false) {
    key = isArray(key) ? key[0] : key;

    if (isEmpty(key)) { return ''; }

    return isTruthy ? `-${key}`.replace('--', '-') : key.replace('-', '');
  }

  isSortDesc (sortDesc = null) {
    sortDesc = sortDesc || this.options.sortDesc;

    return !isEmpty(sortDesc)
      ? sortDesc[0] === true
      : sortDesc === true;
  }
}
