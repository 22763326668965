<template>
  <v-layout>
    <v-icon v-if="icon" left small>{{ icon }}</v-icon>
    <a href="#top" v-bind="$attrs" @click.prevent="backToTop">
      <slot>{{ $trans('Back to top') }}</slot>
    </a>
  </v-layout>
</template>

<script>
import BackToTop from '@core/mixins/BackToTop';

export default {
  name: 'BackToTopBtn',

  mixins: [ BackToTop ],

  props: {
    icon: {
      type: String,
      default: 'mdi-arrow-up',
    },
  },

  data: () => ({
    isAutoBackToTop: false,
  }),
};
</script>
