<template>
  <portal to="sidebar:prepend">
    <app-can-view code="uploader_manage_files">
      <div :class="{ 'px-4 mb-5': isNotSidebarMini }" class="text-center">
        <v-btn
          v-shortkey="[ 'ctrl', 'alt', 'n' ]"
          :icon="isSidebarMini"
          :block="isNotSidebarMini"
          large
          rounded
          color="secondary"
          class="text-capitalize"
          @shortkey="onClick"
          @click="onClick"
        >
          <span v-if="isNotSidebarMini" v-text="$trans('Add Data')"></span>
          <v-icon :right="isNotSidebarMini">mdi-plus</v-icon>
        </v-btn>
      </div>
    </app-can-view>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GlobalFileUploadBtnSystemTray',

  computed: {
    ...mapGetters({
      isSidebarMini: 'sidebar/mini',
    }),

    isNotSidebarMini () {
      return !this.isSidebarMini;
    },
  },

  methods: {
    onClick () {
      this.$router.push({
        name: 'datalake.upload',
        query: {
          tab: 'uploaded',
        },
      });
      this.$store.dispatch('file/showUploadForm');
    },
  },
};
</script>
