<template>
  <v-app v-cloak class="s-app">
    <app-sidebar>
      <template v-slot:sidebar:append>
        <portal-target name="sidebar:append" multiple></portal-target>
        <app-send-feedback-btn></app-send-feedback-btn>
      </template>
    </app-sidebar>

    <portal-target name="app:loading" slim></portal-target>

    <app-bar></app-bar>

    <app-snackbar></app-snackbar>

    <app-dialogbox></app-dialogbox>

    <v-slide-y-transition mode="in-out">
      <v-main class="workspace">
        <app-workspace>
          <v-toolbar
            color="grey lighten-3"
            dark
            flat
          >
          </v-toolbar>
          <v-row>
            <v-col cols="9" class="mx-auto">
              <v-tabs
                v-model="profile.meta.tabs.current"
                background-color="transparent"
                style="margin-top: -48px;"
              >
                <v-tab
                  v-for="item in profile.meta.tabs.items"
                  :key="item.key"
                  :to="item.to"
                >{{ $trans(item.text) }}</v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-slide-y-transition mode="out-in">
            <router-view></router-view>
          </v-slide-y-transition>
        </app-workspace>
        <portal-target name="main:bottom" slim></portal-target>
        <portal-target name="system:tray" slim>
          <app-system-tray></app-system-tray>
        </portal-target>
      </v-main>
    </v-slide-y-transition>

    <portal-target name="sidebar:right" slim></portal-target>

    <app-footer></app-footer>

    <shortkey></shortkey>
    <app-cache-buster-dialog></app-cache-buster-dialog>
  </v-app>
</template>

<script>
import Profile from '@/modules/User/resources/Profile';
import AdminLayout from '@/layouts/admin';

export default {
  name: 'Profile',

  extends: AdminLayout,

  data: () => ({
    profile: new Profile,
  }),
};
</script>
