<template>
  <v-card
    ref="wellKGContainer"
    v-resize="onResize"
    tile
    width="100%"
    height="420px"
  >
    <svg
      id="wellsKnowledgeGraphSVG"
      :width="kgCardSize.width"
      :height="kgCardSize.height"
    />

    <v-overlay
      :absolute="overlay.absolute"
      :value="overlay.overlay && knowledgeGraph.loaded && !item"
    >
      <v-btn>
        {{ item.well_name }} is not in knowledge graph.
      </v-btn>
    </v-overlay>

    <v-overlay
      :absolute="true"
      :value="!knowledgeGraph.loaded"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import KnowledgeGraph from '@/modules/Search/resources/KnowledgeGraph';
import { mapGetters, mapActions } from 'vuex';
import {
  KG_OPTIONS,
  WELL_KNOWLEDGE_GRAPH_ID,
} from '@/modules/Search/config/knowledgeGraph';

export default {
  name: 'WellsKnowledgeGraph',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 699,
    },
    height: {
      type: Number,
      default: 420,
    },
  },

  data () {
    return {
      kgCardSize: {
        width: 0,
        height: 0,
      },
      overlay: {
        absolute: true,
        overlay: false,
      },
      knowledgeGraph: new KnowledgeGraph({
        id: WELL_KNOWLEDGE_GRAPH_ID,
        kgType: 'well',
        kgMode: 'full',
        svgId: 'wellsKnowledgeGraphSVG',
        width: '699',
        height: '420',
        degreeRange: [ 7, 25 ],
        weightRange: [ 1, 20 ],
        currentSelection: null,
        previousSelection: null,
        nodeLabelAttribute: 'well_name',
        flyToScale: 0.3,
        meta: {
          style: KG_OPTIONS.styles,
          simulation: {
            forces: {
              manyBody: -1746,
              link: {
                distance: 1000,
                iterations: 10,
              },
              collide: {
                force: 100,
                strength: 0.7,
                iterations: 5,
              },
            },
            alpha: 0.3,
            tick: 50,
          },
        },
      }),
    };
  },

  computed: {
    ...mapGetters({
      mapAreaDimensions: 'knowledgeGraph/mapAreaDimensions',
      showNoNodeSnackbar: 'knowledgeGraph/showNoNodeSnackbar',
    }),
  },

  watch: {
    async item (newItem, oldItem) {
      if (newItem.article_title === 'N/A' && newItem.doc_ref !== oldItem.doc_ref) {
        const wellName = await this.knowledgeGraph.fetchWellRelationship(newItem.doc_ref);
        this.highlightNode(wellName);
        this.knowledgeGraph.previousSelection = this.knowledgeGraph.currentSelection;
        this.knowledgeGraph.currentSelection = wellName;
      }
    },
    showNoNodeSnackbar () {
      this.overlay.overlay = this.showNoNodeSnackbar;
    },
  },

  async mounted () {
    this.knowledgeGraph.resetKG();
    const containerRef = document.getElementById('search-map');
    if (containerRef) {
      const dimensions = { width: containerRef.clientWidth, height: containerRef.clientHeight };
      if (dimensions.width > 0) {
        this.kgCardSize = dimensions;
      }
    }

    this.knowledgeGraph.width = this.kgCardSize.width ? this.kgCardSize.width : this.width;
    this.knowledgeGraph.height = this.kgCardSize.height ? this.kgCardSize.height : this.height;

    setTimeout(async () => {
      await this.knowledgeGraph.initialize(this.knowledgeGraph.kgType);
      const wellName = await this.knowledgeGraph.fetchWellRelationship(this.item.doc_ref);
      this.highlightNode(wellName);
      this.knowledgeGraph.previousSelection = this.knowledgeGraph.currentSelection;
    }, 1000);
  },

  methods: {
    ...mapActions({
      setShowNoNodeSnackbar: 'knowledgeGraph/setShowNoNodeSnackbar',
      showNoNodeSnackbarAction: 'knowledgeGraph/showNoNodeSnackbar',
      hideNoNodeSnackbarAction: 'knowledgeGraph/hideNoNodeSnackbar',
      setMapAreaDimensions: 'knowledgeGraph/setMapAreaDimensions',
    }),

    isSameNode (n1, n2) {
      return n1 === n2;
    },

    async onResize () {
      await this.$nextTick();
      this.kgCardSize = { width: this.$el.clientWidth, height: this.$el.clientHeight };
      this.setMapAreaDimensions(this.kgCardSize);
    },

    highlightNode (wellName) {
      this.knowledgeGraph.previousSelection = this.knowledgeGraph.previousSelection !== undefined ? this.knowledgeGraph.previousSelection : '';
      this.knowledgeGraph.currentSelection = wellName;

      const checkSameNode = this.isSameNode(
        this.knowledgeGraph.previousSelection,
        this.knowledgeGraph.currentSelection,
      );

      if (this.knowledgeGraph.isNodeInKG(this.knowledgeGraph.currentSelection)) {
        if (!checkSameNode) {
          this.hideNoNodeSnackbarAction();
          this.knowledgeGraph.filter(this.knowledgeGraph.currentSelection);
        }
      } else {
        this.showNoNodeSnackbarAction();
      }
    },
  },
};
</script>
