<template>
  <div>
    <v-alert v-if="datalake.hasErrors()" outlined text type="error" dismissible>
      <span
        class="login-error-message"
        v-text="datalake.getErrors('message', 'Something went wrong')"
      ></span>
    </v-alert>
    <!-- DataSource Tray -->
    <data-source-tray-filter
      width="400px"
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <portal to="header:left">
      <app-search-field
        v-model.trim.lazy="datalake.search"
        :loading="datalake.searching"
        :label="$trans('Search datalake')"
        :placeholder="$trans('Search datalake')"
        :hint="$trans('Datalake search is only limited to file names and file types. Go to the Search module for the whole corpus search.')"
        autofocus solo
        @click:clear="clearSearch"
        @enter:search="setSearch"
      ></app-search-field>
    </portal>

    <portal to="header:right">
      <has-module v-if="active" name="file">
        <file-data-storage-info></file-data-storage-info>
      </has-module>
    </portal>

    <div data-tour-step="welcome/onboarding.4" data-tour-overlay>
      <v-data-table
        :footer-props="{ 'items-per-page-options': datalake.options.rowsPerPage }"
        :dense="datalake.meta.dense"
        :headers="datalake.headers"
        :items="datalake.items"
        :loading="datalake.loading"
        :mobile-breakpoint="datalake.meta.mobileBreakpoint"
        :server-items-length="datalake.pagination.total"
        :show-select="datalake.meta.showSelect"
        :options.sync="datalake.options"
        @update:options="updateList"
      >
        <template v-slot:no-data>
          <span v-text="$trans('No data available.')"></span>
          &nbsp;
          <a
            v-if="hasNoSourcesSelected"
            class="s-link small caption"
            @click.prevent="$store.dispatch('sourcetray/show')"
            v-text="$trans('Select data sources.')"
          ></a>
        </template>

        <template v-slot:top="{ pagination, options, updateOptions }">
          <app-stickybar class="bg-white align-center">
            <app-data-table-toolbar
              :size="datalake.selectedSize()"
              @click:clear="unselectAll"
            >
              <file-data-table-filter-bar
                @click:filter="onFilter"
              ></file-data-table-filter-bar>
            </app-data-table-toolbar>
            <v-spacer></v-spacer>
            <v-data-footer
              class="no-border"
              :pagination="pagination"
              :options="options"
              :items-per-page-options="datalake.options.rowsPerPage"
              @update:options="updateOptions"
            ></v-data-footer>
          </app-stickybar>
          <v-divider></v-divider>
        </template>

        <template v-slot:item.id="{ item }">
          <router-link
            :to="{
              name: 'sets.show',
              params: { id: item.id },
            }" v-text="`Set ${item.id}`"
          ></router-link>
        </template>

        <template v-slot:item.added_by_full_name="{ item }">
          <span
            class="text-no-wrap"
            v-text="item.added_by_full_name"
          ></span>
        </template>

        <template v-slot:item.storage_used="{ item }">
          <span>{{ item.storage_used | toMb }}</span>
        </template>

        <template v-slot:item.date_added="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span class="text-no-wrap" v-on="on">{{ item.date_added | toDateFromNow }}</span>
            </template>
            <span>{{ item.date_added | toDateTime }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.status="{ item }">
          <v-alert
            :color="item.status.color"
            dense outlined
            text
            width="180px"
            class="text-center pa-1 ma-2"
            style="line-height: 1;"
          >
            <span class="caption" v-text="item.status.text"></span>
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Set from '@/modules/File/resources/Set';
import GetFileTypeIcon from '@core/mixins/GetFileTypeIcon';
import PrettyBytes from '@core/filters/PrettyBytes';
import PrettyDates from '@core/filters/PrettyDates';
import { mapGetters } from 'vuex';

export default {
  mixins: [ GetFileTypeIcon, PrettyBytes, PrettyDates ],

  props: {
    resource: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  data: vm => ({
    datalake: new Set({
      ...vm.resource,
      options: {
        ...vm.resource.options,
        sortBy: [ vm.$route.query.order_by || 'date_added' ],
      },
    }),
  }),

  computed: {
    ...mapGetters({
      itemsPerPage: 'datalake/itemsPerPage',
      isUploadDone: 'file/isUploadDone',
      sources: 'sourcetray/sources',
    }),
    hasNoSourcesSelected () {
      return this.sources.length === 0;
    },
  },

  watch: {
    itemsPerPage (value) {
      this.datalake.store.dispatch('datalake/setItemsPerPage', value);
    },

    isUploadDone (isDone) {
      if (isDone) {
        this.datalake.setQueryString(this.datalake.options).list();
      }
    },
  },

  mounted () {
    this.$emit('resource:update', this.datalake);
  },

  methods: {
    changedDataSource ({ query }) {
      this.datalake.setQueryString({
        ...this.datalake.options,
        projects_list: query.projects_list,
      }).list();
    },

    onFilter (filters) {
      this.datalake.setQueryString({ ...this.datalake.options, ...filters }).list();
    },

    updateList (options) {
      this.datalake.store.dispatch('datalake/setItemsPerPage', options.itemsPerPage);
      this.datalake.setQueryString(options).list();
    },

    setSearch (e) {
      this.datalake.setSearch(e.target.value).list();
    },

    clearSearch () {
      this.datalake.clearSearch();
    },

    unselectAll () {
      this.datalake.unselectAll();
    },
  },
};
</script>
