<template>
  <v-container v-bind="$attrs" :class="{ 'pa-0': noPadding }" class="app-content">
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: 'AppContent',

  props: {
    noPadding: {
      type: Boolean,
      default: false,
    },

    noFooter: {
      type: Boolean,
      default: false,
    },
  },

  created () {
    this.setupFooterVisibility();
  },

  methods: {
    setupFooterVisibility () {
      this.$store.dispatch('footer/toggleVisibility', !this.noFooter);
    },
  },
};
</script>
