<template>
  <app-content>
    <meta-tag :title="$trans('Registration Successful')"></meta-tag>

    <v-row class="mt-10">
      <v-col
        cols="12"
        lg="5" md="8"
        class="mx-auto text-center"
      >
        <div class="text-center mx-auto mt-5 mb-15">
          <app-brand width="180" class="mx-auto"></app-brand>
        </div>

        <div class="secondary--text">
          <icon-mail-sent></icon-mail-sent>
        </div>

        <h1 class="text-center mb-2" v-text="$trans('Verify your registered email')"></h1>
        <!-- eslint-disable-next-line max-len -->
        <h3 class="mt-5 mb-10" v-text="$trans('Your account was successfully registered.')"></h3>

        <!-- eslint-disable-next-line max-len -->
        <p v-text="$trans('Before you can use the app, please verify your account. A message was sent to your email address for the next step.')"></p>

        <!-- eslint-disable-next-line max-len -->
        <p v-html="$trans('If you do not see the email in a few minutes, check your junk mail folder or spam folder. If you do not receive a verification email, please contact us directly at <a href=mailto:{email}>{email}</a>', { email: $app.supportEmail })"></p>

        <!-- eslint-disable-next-line max-len -->
        <p class="mt-10 text--disabled" v-html="$trans('You may now close this page and continue<br>the verification step from your email client.')"></p>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import IsRequestComingFromPage from '@/middleware/IsRequestComingFromPage';

export default {
  layout: 'landing',

  middleware: [ (to, from, next) => IsRequestComingFromPage(to, from, next, 'register') ],

  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('register/unsetAvailability');
    this.$store.dispatch('register/unsetSession');

    next();
  },
};
</script>
