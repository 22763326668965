import StoreState from '@core/resources/StoreState';
import { VIZ_ITEMS } from '@/modules/Webgis/config/viz';
import store from '@core/store';

export const state = new StoreState({
  items: VIZ_ITEMS,
  selected: [],
  isSelected (viz) {
    return this.selected.includes(viz);
  },
});

export const getters = {
  ...StoreState.mapDefaultGetters(),
  viz: state => state,
  items: state => state.items,
  selected: state => state.selected,
  selectedItems: state => state.selected.map(i => state.items.find(k => k.value === i)),
  unselectedItems: state => VIZ_ITEMS.filter(i => !state.selected.includes(i.value)),
};

export const mutations = {
  ...StoreState.mapDefaultMutations(),
  SET_SELECTED (state, selected) {
    state.selected = selected;
  },
  HIDE_ALL_CONTROLS (state) {
    state.items.forEach(item => {
      store.dispatch(`viz/${item.storeName}/setShown`, false);
    });
  },
  HIDE_OTHER_CONTROLS_EXCEPT (state, viz) {
    state.items
      .filter(i => i.storeName !== viz)
      .forEach(i => store.dispatch(`viz/${i.storeName}/setShown`, false));
  },
};

export const actions = {
  ...StoreState.mapDefaultActions(),
  setSelected ({ commit }, selected) {
    commit('HIDE_ALL_CONTROLS');
    commit('SET_SELECTED', selected);
  },
  hideAllControls ({ commit }) {
    commit('HIDE_ALL_CONTROLS');
  },
  hideOtherControlsExcept ({ commit }, viz) {
    commit('HIDE_OTHER_CONTROLS_EXCEPT', viz);
  },
};

export default {
  namespaced: true,
  name: 'viz/viz',
  state,
  getters,
  mutations,
  actions,
};
