<template>
  <app-content>
    <meta-tag :title="$trans('Credit Card Details')"></meta-tag>

    <v-row class="mt-10">
      <v-col
        cols="12"
        lg="5" md="8"
        class="mx-auto text-center"
      >
        <div class="primary--text my-5">
          <icon-credit-card></icon-credit-card>
        </div>

        <!-- eslint-disable-next-line max-len -->
        <h1 class="text-center mb-6" v-text="$trans('No changes made to your Credit Card details')"></h1>

        <v-btn
          :to="redirectURL"
          x-large
          color="secondary"
        ><span v-text="$trans(`Go to {name}`, { name: redirectURL.meta.title })"></span></v-btn>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
export default {
  layout: 'web',

  computed: {
    redirectURL () {
      return this.$user.getRedirectRouteFromEnv();
    },
  },
};
</script>
