export const state = {
  itemsPerPage: 100,
};

export const getters = {
  itemsPerPage: state => state.itemsPerPage,
};

export const mutations = {
  SET_ITEMS_PER_PAGE (state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage;
  },
};

export const actions = {
  setItemsPerPage ({ commit }, itemsPerPage) {
    commit('SET_ITEMS_PER_PAGE', itemsPerPage);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
