<template>
  <div>
    <h3 class="section-content__title" v-text="$trans_choice(item.key, item.count)"></h3>
    <template v-if="item.dot">
      <app-text-loader
        :loading="loading"
        class="mb-4"
        width="200px" height="36px"
      >
        <v-layout class="mb-4 align-center">
          <v-avatar :color="item.color" left size="10"></v-avatar>
          <div class="ml-2" v-html="item.text"></div>
        </v-layout>
      </app-text-loader>
    </template>
    <template v-else-if="item.download">
      <div class="mb-4">
        <app-text-loader
          v-for="(download, i) in item.options" :key="i"
          :loading="loading"
          width="200px" height="36px"
        >
          <report-download-link-prompt
            :item="download"
            icon="mdi-email-outline"
            @click="onDownloadClick($event, item)"
          ></report-download-link-prompt>
        </app-text-loader>
      </div>
    </template>
    <template v-else>
      <app-text-loader
        :loading="loading"
        class="mb-4"
        width="200px" height="36px"
      ><p v-html="item.value"></p></app-text-loader>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SetDetailsGroupTableGrid',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: [ String, Object ],
      default: () => {},
    },
  },

  methods: {
    onDownloadClick (download, item) {
      item.downloadReport(download.type);
    },
  },
};
</script>
