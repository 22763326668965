<template>
  <v-card flat class="transparent text-center mx-auto">
    <slot name="title"></slot>
    <div class="text-center mx-auto my-10">
      <slot name="illustration" v-bind="{ illustration }">
        <component :is="illustration" height="280"></component>
      </slot>
    </div>
    <v-card-text class="text-left">
      <slot name="contacts">
        <v-card flat class="mx-auto transparent" width="320">
          <v-layout
            v-for="(item, i) in items" :key="i"
            align-center
            class="mb-6"
          >
            <div class="mr-6"><v-icon large>{{ item.icon }}</v-icon></div>
            <div v-html="item.text"></div>
          </v-layout>
        </v-card>
      </slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ContactCallingCard',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    illustration: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
