<template>
  <v-menu bottom>
    <template v-slot:activator="{ on }">
      <slot></slot>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            icon x-small
            class="ml-3"
            v-on="{ ...on, ...onTooltip }"
          ><v-icon>mdi-chevron-down</v-icon></v-btn>
        </template>
        <span v-text="$trans('Change descriptor')"></span>
      </v-tooltip>
    </template>
    <v-card>
      <v-list dense>
        <v-list-item-group v-model="selected">
          <v-list-item
            v-for="item in descriptors" :key="item.value"
            :value="item.value"
            @click="setCurrentDescriptor(item.value)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'VizScatterPlotDescriptorSelect',

  computed: {
    ...mapGetters({
      descriptors: 'viz/scatterplot/descriptors',
      meta: 'viz/scatterplot/meta',
    }),

    selected: {
      get () {
        return this.meta.currentDescriptor;
      },
      set (descriptor) {
        this.$store.dispatch('viz/scatterplot/setCurrentDescriptor', descriptor);
      },
    },
  },

  methods: {
    ...mapActions({
      changeCurrentDescriptor: 'viz/scatterplot/setCurrentDescriptor',
    }),

    setCurrentDescriptor (data) {
      this.changeCurrentDescriptor(data);
      this.$emit('change:descriptor', data);
    },

    isActive (descriptor) {
      return this.selectedWell === descriptor;
    },
  },
};
</script>
