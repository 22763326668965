<template>
  <router-link tag="a" v-bind="$attrs" class="text-decoration-none">
    <v-icon v-if="icon" left small>{{ icon }}</v-icon>
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    /**
     * The icon to be displayed on the left
     * hand side of the link.
     *
     * @type {string}
     */
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>
