import Map from '@/modules/Search/resources/Map';
import { EXPORT_PIECHART_URL, GET_PIECHART_LIST_URL, GET_PIECHART_SUMMARY_URL } from '@/modules/Webgis/api/piechart';
import { API_PER_PAGE_ALL } from '@config/api';
import { coordsToGeoJson } from '@/modules/Dashboard/utils/map';
import {
  has, isEmpty,
  isNil, isArray,
} from 'lodash';
import { DEFAULT_FIT_BOUNDS_PADDING } from '@/modules/Dashboard/config/maps';
import L from 'leaflet';
import 'leaflet.minichart/dist/leaflet.minichart.min.js';
import { mapColorToLabel } from '@/modules/Webgis/config/colors';
import fileDownload from 'js-file-download';
import { POST_VIZ_SPATIAL_WELL_FITLER } from '@/modules/Webgis/api/viz';
import { ACTIVE_PIECHART_STYLE, DEACTIVE_PIECHART_STYLE } from '@/modules/Webgis/config/viz';
import moment from 'moment';

export default class PieChartDataViz extends Map {
  constructor (map) {
    super();

    this.map = map;
    this.oldPieChartLayer = null;
    this.pieChartLayer = L.featureGroup();
    this.pieChartSpatialFilteredLayer = L.featureGroup();
    this.setMeta({
      isSpatialFiltered: false,
      isFetchingSummary: false,
    });
  }

  async listGeoFilteredWells (geoPolygon) {
    this.startLoading();
    this.pieChartLayer.eachLayer(l => l.setOptions(ACTIVE_PIECHART_STYLE));

    const user = this.store.getters['auth/user'];

    if (user.isPermittedTo('webgis_pie_chart') && !isEmpty(geoPolygon)) {
      this.meta.isSpatialFiltered = true;

      const params = {
        geo_polygon: geoPolygon,
      };

      const { data } = await this.axios.post(POST_VIZ_SPATIAL_WELL_FITLER, params);

      if (isArray(data.data)) {
        const spatialWells = data.data.map(i => i.id);

        const { data: wells } = await this.axios.get(GET_PIECHART_SUMMARY_URL, {
          params: {
            well_id_list: spatialWells.join(','),
          },
        });

        this.store.dispatch('viz/piechart/setData', {
          type: 'summary',
          wells: spatialWells,
          data: wells.data,
          total: wells.data.total_wells,
          projects: this.getCurrentProjects(),
        });

        this.pieChartLayer.eachLayer(l => {
          if (spatialWells.includes(l.options.metadata.id)) {
            l.setOptions(ACTIVE_PIECHART_STYLE);
          } else {
            l.setOptions(DEACTIVE_PIECHART_STYLE);
          }
        });

        // setTimeout(() => {
        //   this.store.dispatch('viz/piechart/show');
        // }, 300);
      }
    }

    this.stopLoading();
  }

  async listMapMarkers () {
    const user = this.store.getters['auth/user'];

    if (user.isPermittedTo('webgis_pie_chart')) {
      const params = {
        projects_list: this.getProjectIds(),
        order_by: 'well_name',
        page_size: API_PER_PAGE_ALL,
        segments: 3,
        page: 1,
      };

      const { data } = await this.axios.get(GET_PIECHART_LIST_URL, { params });

      const items = data.data.map(item => ({
        ...item,
        id: item.well_id,
        attributes: item,
      }));

      if (this.pieChartLayer) {
        this.pieChartLayer.clearLayers();
      }

      this.setItems(items);

      coordsToGeoJson(items).forEach(feature => {
        this.setPieChartMarkerWithPopup({ ...feature, ...feature.properties });
      });

      if (this.pieChartLayer) {
        this.pieChartLayer.bringToFront();
        this.map.addLayer(this.pieChartLayer);

        if (!isEmpty(this.pieChartLayer.getBounds())) {
          this.map.fitBounds(this.pieChartLayer.getBounds(), {
            padding: DEFAULT_FIT_BOUNDS_PADDING,
          });
        }
      }

      this.map.on('click', () => {
        const isSpatialOn = this.store.getters['webgis/spatial/isOn'];

        if (!this.meta.isFetchingSummary && this.isEnabled() && !isSpatialOn) {
          if (this.pieChartLayer) {
            this.pieChartLayer.eachLayer(l => l.setOptions({ opacity: 1 }));
          }
          this.listSummary();
        } else {
          console.log('Not fetching. Pie chart wells are already being fetched or spatial filter is on');
        }
      });

      this.stopLoading();
    }
  }

  setPieChartMarkerWithPopup (data) {
    if (isEmpty(data.values)) {
      return {};
    }

    const {
      popup, latLng,
    } = this.setMarkerWithPopup(data);

    const layer = L.minichart(latLng, {
      type: 'pie',
      width: 24,
      height: 24,
      labels: data.labels,
      data: data.values,
      metadata: data,
      colors: this.getPieChartColors(data.labels),
    });

    if (this.pieChartLayer) {
      this.pieChartLayer.addLayer(layer);
    }

    layer.bindTooltip(data.well_name);

    layer.on('click', e => {
      L.DomEvent.stopPropagation(e);
      this.pieChartLayer.eachLayer(l => l.setOptions({ opacity: 0.5 }));
      layer.setOptions({ opacity: 1 });
      this.onPieChartClick({ layer: e, data });
    });

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  setPieChartSpatialFilteredMarkerWithPopup (data) {
    if (isEmpty(data.values)) {
      return {};
    }

    const {
      popup, latLng,
    } = this.setMarkerWithPopup(data);

    const layer = L.minichart(latLng, {
      type: 'pie',
      width: 24,
      height: 24,
      labels: data.labels,
      data: data.values,
      opacity: 1,
      colors: this.getPieChartColors(data.labels),
    });

    if (this.pieChartSpatialFilteredLayer) {
      this.pieChartSpatialFilteredLayer.addLayer(layer);
    }

    layer.bindTooltip(data.well_name);

    layer.on('click', e => {
      L.DomEvent.stopPropagation(e);
      this.pieChartSpatialFilteredLayer.eachLayer(l => l.setOptions({ opacity: 0.5 }));
      layer.setOptions({ opacity: 1 });
      this.onPieChartClick({ layer: e, data });
    });

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  getPieChartColors (labels) {
    return mapColorToLabel(labels);
  }

  async onPieChartClick (item) {
    this.disableAxiosResponseHandlers();

    try {
      if (item?.data?.id) {
        this.store.dispatch('viz/piechart/startLoading');
        const params = { well_id_list: item.data.id };
        const { data } = await this.axios.get(GET_PIECHART_LIST_URL, { params });

        this.setData(data.data[0]);

        this.store.dispatch('viz/piechart/setData', {
          total: 0,
          type: 'single',
          data: data.data[0],
          well: data.data[0],
          projects: this.getCurrentProjects(),
        });

        setTimeout(() => {
          this.store.dispatch('viz/piechart/show');
        }, 300);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      this.enableAxiosResponseHandlers();
      this.store.dispatch('viz/piechart/stopLoading');
    }
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }

  async listSummary () {
    this.disableAxiosResponseHandlers();

    try {
      if (this.isEnabled()) {
        this.meta.isFetchingSummary = true;

        const params = { projects_list: this.getProjectIds() };
        const { data } = await this.axios.get(GET_PIECHART_SUMMARY_URL, { params });

        this.store.dispatch('viz/piechart/setData', {
          type: 'summary',
          data: data.data,
          total: data.data.total_wells,
          projects: this.getCurrentProjects(),
        });

        this.meta.isFetchingSummary = false;
        if (this.pieChartLayer) {
          this.pieChartLayer.bringToFront();
        }
      }
    } catch (e) {
      console.warn(e);
    } finally {
      this.enableAxiosResponseHandlers();
    }
  }

  async exportPieChartItemOrAll (id = null) {
    if (isNil(id)) {
      this.exportPieChart(null);
    } else {
      this.exportPieChart(id);
    }
  }

  async exportPieChart (wells, key = 'well_id_list') {
    const formData = new FormData;

    if (isNil(wells)) {
      formData.append('projects_list', this.getProjectIds());
    } else if (isArray(wells)) {
      formData.append(key, isArray(wells) ? wells.map(i => i?.well_id ?? i).join(',') : wells.well_id);
    } else {
      formData.append(key, wells.well_id);
    }

    const { data } = await this.axios.post(EXPORT_PIECHART_URL, formData, {
      headers: {
        'Content-Type': 'arraybuffer',
      },
    });

    const date = moment().format('YYYY-MM-DD-hhmmss');
    return fileDownload(data, `piechart-data-exported-${date}.csv`);
  }

  getCurrentProjects () {
    const ids = this.store.getters['sourcetray/sources']?.map(i => i.id);

    return this.store.getters['sourcetray/projects'].filter(i => ids.includes(i.id));
  }

  showMapMarkers () {
    this.pieChartLayer = this.oldPieChartLayer ?? this.pieChartLayer;
    if (this.pieChartLayer) {
      this.pieChartLayer.eachLayer(l => {
        if (has(l, 'setOptions')) {
          l.setOptions({ opacity: 1 });
        }
      });
      this.pieChartLayer.bringToFront();
      this.map.addLayer(this.pieChartLayer);
    }
  }

  hideMapMarkers () {
    this.oldPieChartLayer = this.pieChartLayer;
    if (this.pieChartLayer) {
      this.pieChartLayer.eachLayer(l => {
        if (has(l, 'setOptions')) {
          l.setOptions({ opacity: 0 });
        }
      });
      this.map.removeLayer(this.pieChartLayer);
    }
  }

  opacitateMapMarkers () {
    if (this.pieChartLayer) {
      this.pieChartLayer.eachLayer(l => l.setOptions({ opacity: 0.1 }));
    }
  }

  isEnabled () {
    const viz = this.store.getters['viz/viz/viz'];

    return viz.isSelected('pieChart');
  }

  bringMapMarkersToFront () {
    this.hideMapMarkers();
    this.showMapMarkers();
    this.pieChartLayer.bringToFront();
  }

  async onClearDrawFilters () {
    this.listSummary();

    if (this.pieChartLayer) {
      this.pieChartLayer.eachLayer(l => l.setOptions(ACTIVE_PIECHART_STYLE));
    }
  }
}
