import AppTabsMenuRegistrar from '@core/registrars/AppTabsMenuRegistrar';

AppTabsMenuRegistrar.register([
  {
    to: { name: 'profile.billing' },
    permission: 'payment',
    location: 'profile',
    text: 'Billing',
    key: 'billing',
    sort: 1,
  },
]);
