<template>
  <div>
    <v-skeleton-loader
      v-for="i in 8" :key="i"
      width="100%" height="200"
      type="image" class="mb-4"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: 'CategorySearchTabsItemsLoadingState',
};
</script>
