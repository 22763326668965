<template>
  <div>
    <!-- eslint-disable max-len -->
    <h1 class="display-1 font-weight-light">
      <span v-html="$trans('<strong>Start</strong> unlocking the power of your<br> data with')"></span>
      &nbsp;
      <span class="accent--text font-weight-bold" v-text="$app.appTitle"></span>
      &nbsp;
      <span class="secondary--text font-weight-bold">Apprentice</span>.
    </h1>

    <img class="mt-6 featured__image" :src="$asset.getModuleAsset('registration/fig1.slide2', 'Billing')">
    <v-card elevation="4" class="featured featured--shaped mb-6">
      <v-card-title class="featured__title white--text justify-center">
        <span class="title font-weight-light" v-html="$trans('Get your <strong>free trial</strong> now')"></span>
      </v-card-title>
      <v-card-text class="pa-10">
        <v-layout v-for="(item, i) in items" :key="i" class="align-center mb-6">
          <v-icon large color="secondary">mdi-chevron-right-circle-outline</v-icon>
          <span class="ml-8" v-html="item.text"></span>
        </v-layout>
      </v-card-text>
    </v-card>

    <p class="text--disabled" v-html="$trans('For more feature upgrade, visit <a target=_blank href=www.irayaenergies.com>www.irayaenergies.com</a>')"></p>
  </div>
</template>

<script>
export default {
  name: 'SecondSlideItem',

  data: () => ({
    items: [
      { text: 'Ingest up to 5,000 pages of your own data' },
      { text: 'Give a fresh new perspective to old data' },
      { text: 'Full automation and saves more time!' },
    ],
  }),
};
</script>

<style lang="scss" scoped>
$tier1-color-top: rgb(243, 104, 109);
$tier1-color-bottom: rgb(245, 135, 81);

.s-app .v-card.featured {
  &.featured--shaped {
    border-bottom-right-radius: 3rem;
    border-top-left-radius: 3rem;
  }
}

.featured {
  &__image {
    margin-bottom: -45px;
  }

  &__title {
    background-image: linear-gradient(to bottom, $tier1-color-top, $tier1-color-bottom);
  }
}
</style>
