import { isString } from 'lodash';

export default {
  computed: {
    mode () {
      return this.getComponentLayoutTransitionMode();
    },

    layout () {
      return this.getComponentLayout(this.$route.meta.layout);
    },
  },

  methods: {
    getComponentLayout (layout) {
      let component;

      if (!isString(layout)) {
        return layout;
      }

      switch (layout) {
      case 'default':
      case undefined:
        component = this.getDefaultLayout();
        break;

      case 'error':
        component = this.getErrorLayout();
        break;

      case 'inherit':
        component = this.getInheritLayout();
        break;

      default:
        component = this.findLayout(layout);
        break;
      }

      return component;
    },

    getDefaultLayout () {
      const __default = 'layouts/default';

      return this.$options.layouts.default.__file === `@/${__default}.vue`
        ? () => import(`@/${__default}.vue`)
        : () => import(`@core/${__default}.vue`);
    },

    getErrorLayout () {
      const __error = 'layouts/error';

      return this.$options.layouts.error.__file === `@/${__error}.vue`
        ? () => import(`@/${__error}.vue`)
        : () => import(`@core/${__error}.vue`);
    },

    getInheritLayout () {
      return () => import('@core/layouts/inherit.vue');
    },

    findLayout (name) {
      return () => import(`@/layouts/${name}.vue`);
    },

    getComponentLayoutTransitionMode () {
      const layout = this.$options.layouts[this.$route.meta.layout];

      return (layout && layout.transitionMode) || 'in-out';
    },
  },
};
