<template>
  <app-content class="text-left">
    <meta-tag :title="$trans('Privacy Statement')"></meta-tag>

    <v-row>
      <v-col cols="12" lg="8" class="mx-auto">
        <a name="top"></a>

        <v-btn :to="$user.getRedirectRouteFromEnv()" text>
          <v-icon left small>mdi-arrow-left</v-icon>
          <span v-text="$trans('Back')"></span>
        </v-btn>

        <div class="my-5"></div>

        <app-markdown-preview v-html="content"></app-markdown-preview>

        <div class="mt-15"></div>
        <back-to-top-btn></back-to-top-btn>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import PrivacyStatement from '@/modules/Auth/docs/privacy/PrivacyStatement.md';

export default {
  routename: 'legal.privacy',

  layout: 'web',

  data: () => ({
    content: PrivacyStatement,
  }),
};
</script>
