var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-content',[_c('data-source-tray-filter',{attrs:{"width":"400px"},on:{"update:selected":_vm.changedDataSource}}),_c('v-row',{attrs:{"fill-height":""}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('app-search-field',{staticClass:"mb-4",attrs:{"label":_vm.$trans('Search table'),"placeholder":_vm.$trans('Search table'),"loading":_vm.well.searching,"autofocus":_vm.isDesktop,"solo":""},on:{"click:clear":_vm.clearSearch,"enter:search":_vm.setSearch},model:{value:(_vm.well.search),callback:function ($$v) {_vm.$set(_vm.well, "search", $$v)},expression:"well.search"}})],1)],1),_c('v-card',[_c('app-stickybar',{staticClass:"bg-white",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var stuck = ref.stuck;
return [_c('v-layout',{staticClass:"text-no-wrap flex-no-wrap ov-x-auto",class:{ 'elevation-8': stuck }},[_c('app-data-table-toolbar',{staticClass:"flex-grow-0 flex-no-wrap",attrs:{"size":_vm.well.selectedSize(),"exportable":""},on:{"click:export":_vm.exportSelectedItems,"click:clear":_vm.unselectAll}}),_c('v-spacer'),_c('v-data-footer',{staticClass:"no-border flex-grow-1 flex-no-wrap text-no-wrap ov-x-auto",attrs:{"pagination":_vm.well.pagination,"options":_vm.well.options,"items-per-page-options":_vm.well.meta.rowsPerPage},on:{"update:options":_vm.updateList}})],1)]}}])}),_c('v-divider'),_c('v-data-table',{attrs:{"footer-props":{ 'items-per-page-options': _vm.well.meta.rowsPerPage },"dense":_vm.well.meta.dense,"single-select":_vm.well.meta.singleSelect,"headers":_vm.well.headers,"items":_vm.well.items,"loading":_vm.well.loading,"mobile-breakpoint":_vm.well.meta.mobileBreakpoint,"server-items-length":_vm.well.pagination.total,"show-select":_vm.well.meta.showSelect,"options":_vm.well.options,"item-class":_vm.well.meta.itemClass},on:{"update:options":[function($event){return _vm.$set(_vm.well, "options", $event)},_vm.updateList],"click:row":_vm.onRowClick,"item-selected":_vm.toggleRowSelect,"toggle-select-all":_vm.toggleSelectAll},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"py-4",domProps:{"textContent":_vm._s(_vm.$trans('Loading items...'))}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-4",domProps:{"textContent":_vm._s(_vm.$trans('No data available'))}})]},proxy:true},_vm._l((_vm.well.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,attrs:{"title":_vm.$trans(header.text)},domProps:{"textContent":_vm._s(_vm.$trans(header.text))}})]},proxy:true}}),{key:"item.tag_no",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            name: 'reports.single',
            params: {
              slug: item.link,
            },
            query: {
              data_index: item.data_index,
            },
          }}},[_c('div',{staticClass:"text-no-wrap",staticStyle:{"width":"auto"},attrs:{"title":item.tag_no},domProps:{"textContent":_vm._s(item.tag_no)}})])]}},{key:"item.attributes.well_name",fn:function(ref){
          var item = ref.item;
return [_c('app-can-view',{attrs:{"code":"report_viewer"},scopedSlots:_vm._u([{key:"alt",fn:function(){return [_c('div',{staticClass:"text-no-wrap text-truncate",staticStyle:{"width":"100px"},attrs:{"title":item.well_name},domProps:{"textContent":_vm._s(item.well_name)}})]},proxy:true}],null,true)},[_c('router-link',{attrs:{"to":{
              name: 'reports.well',
              query: {
                wellId: item.id,
                noPageNum: true,
              },
            }}},[_c('div',{staticClass:"text-no-wrap text-truncate",staticStyle:{"width":"auto"},attrs:{"title":item.well_name},domProps:{"textContent":_vm._s(item.well_name)}})])],1)]}},_vm._l((_vm.well.headers),function(header,i){return {key:("item." + (header.value)),fn:function(ref){
            var item = ref.item;
return [(header.isLink)?[_c('router-link',{key:i,attrs:{"to":{
              name: 'reports.single',
              params: {
                slug: item.link,
              },
              query: {
                data_index: item.data_index,
              },
            }}},[_c('div',{staticClass:"text-no-wrap",staticStyle:{"width":"auto"},attrs:{"title":item[header.value]},domProps:{"textContent":_vm._s(item[header.value])}})])]:[_c('span',{key:i,domProps:{"textContent":_vm._s(item[header.value])}})]]}}})],null,true),model:{value:(_vm.well.selected),callback:function ($$v) {_vm.$set(_vm.well, "selected", $$v)},expression:"well.selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }