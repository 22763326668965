import Manifest from '@core/manifests/Manifest';
import marked from 'marked';
import { groupBy } from 'lodash';

class AboutManifest extends Manifest {
  constructor (attributes = {}) {
    super(attributes);

    this.makeContext();
    this.generateItems();
  }

  makeContext () {
    this.requireConfig = require.context(
      // The relative path of the components folder
      '@',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base component filenames
      /(modules\/About\/docs)(\/?)([a-zA-Z0-9-_]+(.*)).*config\.json$/,
    );

    this.requireItem = require.context(
      // The relative path of the components folder
      '@',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base component filenames
      /(modules\/About\/docs)(\/?)([a-zA-Z0-9-_]+(.*)).*\.md$/,
    );

    this.files = this.requireItem.keys().sort().map(f => this.makeFile(f));
    this.configs = this.requireConfig.keys().sort().map(c => this.makeConfig(c));
  }

  generateItems () {
    const items = this.configs.map(c => ({
      ...c,
      currentIndex: [ 0 ],
      files: groupBy(
        this.files.filter(f => (f.folder === c.folder) || f.fileTree.includes(c.folder)),
        'folderName',
      ),
    }));

    this.setItems(items);
  }

  makeConfig (filePath) {
    const content = this.requireConfig(filePath);
    const { title, component: componentPath } = content;
    const component = () => import(`@/modules/About/components/${componentPath}`);
    const fileTree = filePath.split('/');
    const fileName = fileTree.splice(-1).pop();
    const folder = fileTree.pop();
    const path = filePath;
    const isConfig = true;

    return {
      ...content,
      title,
      component,
      isConfig,
      filePath,
      fileName,
      folder,
      path,
    };
  }

  makeFile (filePath) {
    const content = marked(this.requireItem(filePath));
    const fileTree = filePath.split('/');
    const fileName = fileTree.splice(-1).pop();
    const title = fileName.replace(/[0-9]{2}\.\s/, '').replace(/\.md$/, '');
    const folder = fileTree.pop();
    const folderName = folder.replace(/[0-9]{2}\.\s/, '');
    const path = filePath;
    const isFile = true;

    return {
      content,
      title,
      isFile,
      fileTree,
      filePath,
      folderName,
      fileName,
      folder,
      path,
    };
  }

  makeItem (item) {
    return {
      ...item,
      meta: { ...item.meta },
      enabled: item.enabled ?? true,
      index: item.index ?? this.items?.length ?? 0,
      sort: item.sort ?? item.index ?? this.items?.length ?? 0,
      key: (item.key ?? item.code ?? item.name ?? item.path).toLowerCase(),
      path: (item.path?.replace('/manifest.json', '') ?? '').replace('./', `${item.type ?? 'src'}/`),
    };
  }
}

export default new AboutManifest;
