<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="560px"
  >
    <v-card>
      <v-card-text>
        asdasd
      </v-card-text>
    </v-card>
    <!-- <file-upload-tray-list
      :set="set"
      :items="items"
      :error-messages="errors"
      :progress="progress"
      item-key="name"
      @click:cancel="onCancelAll"
      @click:another="onAnother"
      @click:close="onClose"
    ></file-upload-tray-list> -->
  </v-dialog>
</template>

<script>
export default {
  name: 'AppContentFileDropAreaDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isCancelledAll: false,
  }),

  computed: {
    dialog: {
      get () {
        return this.value;
      },
      set (isShown) {
        this.$emit('input', isShown);
      },
    },
  },

  methods: {
    onAnother (e) {
      this.$emit('click:another', e);
    },

    onClose (e) {
      this.$emit('click:close', e);
    },

    onCancelAll (e) {
      this.$store.dispatch('dialog/show', {
        title: 'Cancel upload?',
        text: [
          '<p>You are about to cancel uploading the files.</p>',
          '<p>Are you sure you want to proceed?</p>',
        ],
        buttons: {
          cancel: { text: 'Continue Uploading' },
          action: {
            text: 'Cancel Upload',
            color: 'error',
            callback: () => this.emitCancelledAll(e),
          },
        },
      });
    },

    emitCancelledAll (e) {
      this.isCancelledAll = true;
      this.$emit('click:cancel', e);
    },
  },
};
</script>
