export const state = {
  pdf: {
    fullview: false,
    twopageview: false,
    drawer: false,
    currentPage: 1,
  },
};

export const getters = {
  fullview: state => state.pdf.fullview,
  twopageview: state => state.pdf.twopageview,
  drawer: state => state.pdf.drawer,
  currentPage: state => state.pdf.currentPage,
};

export const mutations = {
  SET_FULLVIEW (state) {
    state.pdf.fullview = !state.pdf.fullview;
  },

  SET_TWO_PAGE_VIEW (state) {
    state.pdf.twopageview = !state.pdf.twopageview;
  },

  TOGGLE_DRAWER (state) {
    state.pdf.drawer = !state.pdf.drawer;
  },

  SET_CURRENT_PAGE (state, page) {
    state.pdf.currentPage = page;
  },
};

export const actions = {
  fullview ({ commit }) {
    commit('SET_FULLVIEW');
  },

  twopageview ({ commit }) {
    commit('SET_TWO_PAGE_VIEW');
  },

  drawer ({ commit }) {
    commit('TOGGLE_DRAWER');
  },

  currentPage ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
