<template>
  <div
    ref="stickybar"
    :class="{ 'white w-100': stuck }"
    class="app-stickybar sticky"
  >
    <v-layout v-bind="{ ...$attrs, ...$props }">
      <slot v-bind="{ stuck, unstuck }"></slot>
    </v-layout>
    <v-divider v-if="divider && stuck"></v-divider>
  </div>
</template>

<script>
export default {
  name: 'AppStickybar',

  inheritAttrs: false,

  props: {
    divider: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    stuck: false,
    unstuck: true,
  }),

  mounted () {
    this.observeStickybar();
  },

  methods: {
    observeStickybar () {
      const observer = new IntersectionObserver(
        ([ e ]) => {
          e.target.classList.toggle('app-stickybar--stuck', e.intersectionRatio < 1);
          this.stuck = e.target.classList.contains('app-stickybar--stuck');
          this.unstuck = !this.stuck;
        },
        { threshold: [ 1 ] },
      );

      observer.observe(this.$refs.stickybar);
    },
  },
};
</script>
