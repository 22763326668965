<template>
  <app-content>
    <v-alert v-if="set.hasErrors()" outlined text type="error" dismissible>
      <span
        class="login-error-message"
        v-text="set.getErrors('message', 'Something went wrong')"
      ></span>
    </v-alert>
    <meta-tag :title="$trans('File Set')"></meta-tag>
    <app-back-btn
      :to="{
        name: 'datalake',
        query: {
          tab: 'uploaded',
        },
      }"
    ></app-back-btn>

    <v-row>
      <v-col cols="12" lg="10" class="mx-auto">
        <file-audit-status-stepper
          v-model="set.meta.phases.current"
          :items="set.meta.phases.items"
          class="flat mb-4"
        ></file-audit-status-stepper>

        <app-text-loader
          :loading="set.loading"
          class="mb-4"
          width="130px" height="40px"
        >
          <h1 class="display-1 file-title mb-4" v-text="set.data.title"></h1>
        </app-text-loader>

        <section class="section-content">
          <file-submit-for-approval-alert
            :loading="set.loading"
            :status="set.getStatus()"
          ></file-submit-for-approval-alert>

          <v-row>
            <v-col
              v-for="(details, i) in set.meta.details" :key="i"
              md="6" sm="12"
              cols="12"
            >
              <template v-for="(detail, j) in details">
                <app-can-view :key="j" :code="detail.permission">
                  <set-details-group-table-grid
                    :item="detail"
                    :loading="set.loading"
                    class="section-content__group"
                  ></set-details-group-table-grid>
                </app-can-view>
              </template>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <section class="section-content__group">
                <h3 class="section-content__title" v-text="$trans('Files')"></h3>
              </section>

              <set-files-list-simple-table
                :loading="set.loading"
                :items="set.data.files"
              ></set-files-list-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="7" md="6" cols="12">
              <a id="audit-status-logs" name="audit-status-logs"></a>
              <section class="section-content__group mt-5 mb-4">
                <h3 class="section-content__title" v-text="$trans('Status Logs')"></h3>
              </section>
              <file-audit-status-logs-timeline
                :items="set.meta.logs.items"
                class="mb-4"
              ></file-audit-status-logs-timeline>
            </v-col>
          </v-row>
        </section>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import Set from '@/modules/File/resources/Set';

export default {
  layout: 'admin',

  data: () => ({
    set: new Set,
  }),

  async created () {
    await this.set.find(this.$id);
    await this.set.findStatusLogs(this.$id);
  },
};
</script>
