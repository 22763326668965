import Well from '@/modules/Dashboard/resources/Well';
import {
  GET_GENERAL_DASHINFO_VALUES_LIST,
  GET_GENERAL_DASHINFO_HEADERS_LIST,
  EXPORT_GENERAL_DASHINFO_VALUES,
} from '@/modules/Dashboard/api/general';
import { isDashboardModeDynamic, OMITTABLE_DYNAMIC_HEADERS } from '@/modules/Dashboard/config/modes';
import { isEmpty, uniqBy, orderBy } from 'lodash';
import fileDownload from 'js-file-download';
import moment from 'moment';

export default class WellGeneral extends Well {
  constructor (options = {}) {
    super(options);

    this.setHeaders([]);
  }

  hasHeaders () {
    return !isEmpty(this.headers);
  }

  async listDynamic () {
    this.startLoading();

    if (isDashboardModeDynamic()) {
      await this.getListHeaders();
    }

    const params = {
      ...this.getQueryString(),
      projects_list: !isEmpty(this.getProjectIds()) ? this.getProjectIds() : null,
    };

    try {
      this.disableAxiosResponseHandlers();

      const { data } = await this.axios.get(GET_GENERAL_DASHINFO_VALUES_LIST, { params });

      if (this.hasHeaders()) {
        this.setItems(data.data);

        this.setPagination({
          total: data.meta.pagination.count,
          page: data.meta.pagination.page,
          pageCount: data.meta.pagination.pages,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.enableAxiosResponseHandlers();
    }

    this.stopLoading();
  }

  setItems (items) {
    this.items = items.map((item, i) => ({
      active: false,
      id: `${i}-${item.id ?? item.data_index}`,
      link: item.reference.split(',')?.[0],
      ...item,
      ...item.attributes,
    }));
  }

  async getListHeaders () {
    const params = this.getDefaultHeaderParams();
    const { data } = await this.axios.get(GET_GENERAL_DASHINFO_HEADERS_LIST, { params });

    const headers = orderBy(uniqBy(data.data.map(h => ({
      text: h.attributes.name.replaceAll('_', ' '),
      value: h.attributes.name,
      sortable: h.attributes.is_sortable,
      isLink: h.attributes.data_ordering === 1,
    })).filter(i => !OMITTABLE_DYNAMIC_HEADERS.includes(i.value)), 'value'), 'data_ordering');

    this.setHeaders(headers);
  }

  getDefaultHeaderParams () {
    return {
      order_by: '',
      projects_list: this.getProjectIds(),
    };
  }

  toggleRowSelect (item, isSelected) {
    if (this.meta.singleSelect) {
      this.deactivateAllItems();
    }

    if (isSelected) {
      this.activateItem(item);
    } else {
      this.deactivateItem(item);
    }
  }

  async exportSelectedItems () {
    try {
      const { data } = await this.axios.post(EXPORT_GENERAL_DASHINFO_VALUES, {
        data_index: this.selected.map(i => i.data_index).join(','),
        projects_list: this.getProjectIds(),
      });

      if (data.data && data.data.detail) {
        return this.dialog('error', { alignment: 'center', text: data.data.detail });
      }

      const date = moment().format('YYYY-MM-DD-hhmmss');
      return fileDownload(data, `dashboard-${date}.csv`);
    } catch ({ response }) {
      const { message } = response.data;

      return this.dialog('error', {
        color: 'accent',
        persistent: true,
        illustration: () => import('@/components/Icons/IconListOneRowError'),
        illustrationHeight: 200,
        title: 'Download Limit Reached',
        text: message ?? 'Please consider upgrading your plan to increase rate limit.',
      });
    }
  }
}
