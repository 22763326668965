<template>
  <v-layout column justify-center>
    <alert-search-query-empty-results></alert-search-query-empty-results>
    <div class="accent--text text-center">
      <icon-landscape-image></icon-landscape-image>
    </div>
    <div
      class="my-6 text-center text--disabled"
      v-text="$trans('No data found')"
    ></div>
  </v-layout>
</template>

<script>
export default {
  name: 'CategorySearchTabItemEmptyState',
};
</script>
