import IconfileManifest from '@core/manifests/IconfileManifest';
import mime from 'mime';

export default {
  methods: {
    getFileTypeColor (text) {
      switch (text.toLowerCase()) {
      case 'pdf': return '#ae2e16';
      case 'doc': case 'docx': return '#2c589b';
      case 'ppt': case 'pptx': return '#d24a27';
      case 'xls': case 'xlsx': return '#31734b';
      default: return 'black';
      }
    },

    getFileTypeIcon (text) {
      switch (text.toLowerCase()) {
      case 'pdf': return 'mdi-file-pdf-box';
      case 'doc': case 'docx': return 'mdi-file-word-box';
      case 'ppt': case 'pptx': return 'mdi-file-powerpoint-box';
      case 'xls': case 'xlsx': return 'mdi-file-excel-box';
      case 'jpeg': case 'jpg': case 'png': return 'mdi-file-image-outline';
      default: return 'mdi-file-document-outline';
      }
    },

    getFileTypeIconVariant (text) {
      switch (text.toLowerCase()) {
      case 'pdf': return 'mdi-file-pdf';
      case 'doc': case 'docx': return 'mdi-file-word';
      case 'ppt': case 'pptx': return 'mdi-file-powerpoint';
      case 'xls': case 'xlsx': return 'mdi-file-excel';
      case 'jpeg': case 'jpg': case 'png': return 'mdi-file-image-outline';
      default: return 'mdi-file-document-outline';
      }
    },

    guessVariantIconFromMimeType (mimeType) {
      const icon = IconfileManifest.find(`variant_${mimeType}`);
      return icon?.value ?? this.getDefaultIcon();
    },

    guessIconFromMimeType (mimeType) {
      const icon = IconfileManifest.find(mimeType);
      return icon?.value ?? this.getDefaultIcon();
    },

    getDefaultIcon () {
      return 'mdi-file-document-outline';
    },

    getTypeFromExtension (ext) {
      return mime.getType(ext);
    },

    getExtensionFromMimeType (mimeType) {
      return mime.getExtension(mimeType);
    },
  },
};
