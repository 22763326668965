<template>
  <v-row>
    <v-col cols="12" lg="9" class="mx-auto">
      <v-expansion-panels v-model="item.currentIndex" flat multiple>
        <v-expansion-panel v-for="(files, header) in item.files" :key="header">
          <v-expansion-panel-header>
            <h2 class="font-weight-bold mb-4" v-text="$trans(header)"></h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <template v-for="(file, i) in files">
              <v-row :key="i">
                <v-col cols="1">
                  <div class="font-weight-bold" v-text="i+1"></div>
                </v-col>
                <v-col cols="11">
                  <div class="page-content" v-html="file.content"></div>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FaqTabItem',

  props: {
    item: {
      type: [ Array, Object ],
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.page-content {
  h3 {
    margin-bottom: 1.5rem;
  }

  ol > li {
    line-height: 32px;
    margin-bottom: 1rem;
  }
}
</style>
