export const state = {
  availability: 0,
};

export const getters = {
  availability: state => state.availability,
};

export const mutations = {
  SET_AVAILABILITY (state, time) {
    state.availability = time;
  },
};

export const actions = {
  setAvailability ({ commit }, time) {
    commit('SET_AVAILABILITY', time);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
