var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"min-width":"420","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",[_c('v-btn',_vm._g({staticClass:"px-6",attrs:{"color":"primary"}},on),[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('References'))}})])],null,{ on: on })]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h2',{staticClass:"h2 mb-4",domProps:{"innerHTML":_vm._s(_vm.$trans('References'))}})]),_c('v-data-iterator',{staticClass:"px-6",attrs:{"items-per-page":_vm.itemsPerPage,"items":_vm.items,"hide-default-footer":_vm.hideFooter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var references = ref.items;
return _vm._l((references),function(item,i){return _c('div',{key:i},[_c('router-link',{attrs:{"to":{
              name: 'reports.single',
              params: { slug: item.iraya_data_id },
              query: { page_num: item.page_num },
            }}},[_c('span',{domProps:{"textContent":_vm._s(item.iraya_data__doc_name)}})]),_c('span',[_vm._v(" "),_c('em',{staticClass:"muted--text",domProps:{"textContent":_vm._s(("(p." + (item.page_num) + ")"))}})])],1)})}}])}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-6",attrs:{"large":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Close'))}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }