<template>
  <div class="pb-16">
      <v-textarea
        v-model="chatInput"
        placeholder="Type your prompt here"
        outlined
        ></v-textarea>
    <v-btn outlined @click="generateResponse" :disabled="chatbot.loading">
      Generate Response
    </v-btn>
    <template>
      <div v-if="chatbot.loading">
        <v-skeleton-loader type="list-item-three-line"/>
      </div>
      <div v-if="!chatbot.loading && generated" class="py-3">
        <!-- <body :style="{ whiteSpace: 'pre-line' }">{{ chatbot.data.data.summary }}</body> -->
        <div v-html="markdownText" style="overflow-x: scroll;" class="markdown-content"></div>
        <v-row justify="end" class="mx-1 mb-1 mt-8">
          <v-col cols="8" class="pa-0">
            <div class="font-weight-light" style="font-size: 12px;">This is an AI generated response and may incur errors.<br/> Please check the outputs before using.</div>
          </v-col>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="chatbot.meta.hasTables"
                icon
                class="mx-1"
                v-on="on"
                @click="exportCsv"
              >
                <v-icon>mdi-file-delimited-outline</v-icon>
              </v-btn>
            </template>
            <span>Export tables as CSV</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-1"
                v-on="on"
                @click="copyText"
              >
                <v-icon>mdi-clipboard-outline</v-icon>
              </v-btn>
            </template>
            <span>Copy response</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-1"
                v-on="on"
              >
                <v-icon>mdi-thumb-up-outline</v-icon>
              </v-btn>
            </template>
            <span>This response is good</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-1"
                v-on="on"
              >
                <v-icon>mdi-message-draw</v-icon>
              </v-btn>
            </template>
            <span>This response needs review</span>
          </v-tooltip>
        </v-row>
      </div>
    </template>
    <v-snackbar
      v-model="showSnack"
      timeout="3000"
      color="success"
    >Copied to clipboard</v-snackbar>
  </div>
</template>
  
<script>
import Chatbot from '@/modules/Search/resources/Chatbot';
import { isEmpty } from 'lodash';
import marked from 'marked';

export default {
  name: 'GeneralSummaryInfoTable',

  props: {
    item: {
      type: [ Object, Array ],
      default: () => {},
    },
  },

  data: () => ({
    chatbot: new Chatbot,
    generated: false,
    chatInput: '',
    showSnack: false,
  }),

  computed: {
    hasData () {
      return !isEmpty(this.well);
    },

    markdownText(){
      marked.setOptions({
        gfm: true,
        // breaks: true
      });
      return marked(this.chatbot.data.data.summary)
    }
  },

  methods: {
    generateResponse(){
      this.chatbot.getSandboxResponse(this.chatInput)
      if(!this.generated) this.generated = true
    },
    copyText(){
      this.showSnack = true
      this.chatbot.copyToClipboard(this.chatbot.data.data.summary)
    },
    exportCsv(){
      this.chatbot.meta.tables.map((table, index) => {
        const csvData = this.chatbot.convertToCsv(table)
        this.chatbot.exportCsvFile(`table_${index}`, csvData)
      })
    }
  }
};
</script>


<style>
.markdown-content table {
  border-collapse: collapse;
  width: 100%;
}

.markdown-content th,
.markdown-content td {
  border: 1px solid rgb(221, 221, 221);
  padding: 8px;
}

.markdown-content th {
  background-color: rgb(242, 242, 242);
  font-weight: bold;
  text-align: left;
}

.markdown-content tr:nth-child(even) {
  background-color: rgb(249, 249, 249);
}
</style>
  