export const ACCOUNT_TYPE_BOLT = 'bolt';
export const ACCOUNT_TYPE_APPRENTICE = 'apprentice';
export const ACCOUNT_TYPE_ENTERPRISE = 'enterprise';

export const ACCOUNT_TYPES_LEVELS = {
  [ACCOUNT_TYPE_APPRENTICE]: 0,
  [ACCOUNT_TYPE_BOLT]: 1,
  [ACCOUNT_TYPE_ENTERPRISE]: 2,
};

// Should be used with "<=" or ">=" when determining account type level.
export const ACCOUNT_TYPE_FREE_SUBSCRIPTION = 0;
export const ACCOUNT_TYPE_PAID_SUBSCRIPTION = 1;

export default Object.freeze({
  ACCOUNT_TYPE_BOLT,
  ACCOUNT_TYPE_APPRENTICE,
  ACCOUNT_TYPE_ENTERPRISE,
  ACCOUNT_TYPES_LEVELS,
  ACCOUNT_TYPE_FREE_SUBSCRIPTION,
  ACCOUNT_TYPE_PAID_SUBSCRIPTION,
});
