<template>
  <div class="page-title__container">
    <img
      :alt="$trans('API Documentation')"
      src="@/modules/Datawarehouse/assets/banner.svg"
    >
    <span class="page-overline overline" v-html="$trans('API Documentation')"></span>
    <div>
      <img
        :alt="app.title"
        src="@/modules/Datawarehouse/assets/wordmark.svg"
        width="280"
        class="page-logo"
      >
    </div>
    <small
      v-if="app.version"
      class="muted--text"
      v-html="$trans('for version {version}', {
        version: app.version,
      })"
    ></small>
  </div>
</template>

<script>
import { API_VERSION } from '@config/api';
import { meta } from '@config/app';
import word from '@/modules/Datawarehouse/assets/wordmark.svg';

export default {
  name: 'DatawarehousePageHeader',

  data: () => ({
    word,
    apiVersion: API_VERSION,
  }),

  computed: {
    app () {
      return {
        version: API_VERSION,
        title: meta.VUE_APP_SHORT_NAME,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.page-logo {
  margin-left: -18px;
}
</style>
