export default [
  {
    name: 'workflows',
    title: 'Workflows',
    description: 'View workflow pods',
    icon: 'mdi-cogs',
    sort: 200,
    meta: { parentOnly: true },
  },
];
