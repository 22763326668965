<template>
  <div ref="file-upload-button" class="file-upload__btn">
    <file-upload-dialog>
      <template v-slot:activator="{ on }">
        <v-btn
          large
          outlined
          color="primary"
          v-on="on"
        >
          <v-icon left>mdi-upload</v-icon>
          <span v-text="$trans('Upload files')"></span>
        </v-btn>
      </template>
    </file-upload-dialog>
  </div>
</template>

<script>
import Uploader from '@/modules/File/resources/Uploader';
import { files } from '@/modules/File/config/files';
import { set } from '@/modules/File/config/set';
import { uploadTray, uploadForm } from '@/modules/File/config/upload';

export default {
  name: 'FileUploadBtn',

  data: () => ({
    uploader: new Uploader({
      meta: {
        set,
        files,
        uploadTray,
        uploadForm,
      },
    }),
  }),

  methods: {
    onCancelAll (e) {
      this.$emit('click:cancel', e);
    },

    onAnother (e) {
      this.$emit('click:another', e);
    },

    onClose (e) {
      this.$emit('click:close', e);
    },
  },
};
</script>
