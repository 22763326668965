import Vue from 'vue';

export const state = {
  availability: 0,
  message: {
    title: '',
    content: '',
    type: 'error',
  },
};

export const getters = {
  availability: state => state.availability,
  message: state => state.message,
};

export const mutations = {
  SET_AVAILABILITY (state, time) {
    state.availability = time;
    Vue.prototype.$session.start();
    Vue.prototype.$session.set('register.availability', time);
  },

  UNSET_AVAILABILITY (state) {
    state.availability = 0;
  },

  UNSET_SESSION (state) {
    state.availability = 0;
    Vue.prototype.$session.remove('register.availability');
  },

  SET_MESSAGE (state, message) {
    state.message = message;
  },
};

export const actions = {
  setAvailability ({ commit }, time) {
    commit('SET_AVAILABILITY', time);
  },

  unsetAvailability ({ commit }) {
    commit('UNSET_AVAILABILITY');
  },

  unsetSession ({ commit }) {
    commit('UNSET_SESSION');
  },

  setMessage ({ commit }, message) {
    commit('SET_MESSAGE', message);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
