export default [
  {
    name: 'search',
    title: 'Search',
    description: 'Search well reports and view map information',
    icon: 'mdi-magnify',
    sort: 11,
    meta: {
      attrs: {
        'data-tour-step': 'welcome/onboarding.7',
        'data-tour-overlay': true,
      },
    },
  },
];
