import Profile from '@/modules/User/resources/Profile';
import { has } from 'lodash';

export default async function (to, from, next) {
  if (!has(to.meta, 'permissions')) {
    return next();
  }

  if (has(to.meta, 'permissions')) {
    const user = await (new Profile).getUserFromApi();
    if (user.hasFeatureFrom(to.meta.permissions)) {
      return next();
    }
  }

  return next({ name: 'error:404', params: [ to.fullPath ], replace: true });
}
