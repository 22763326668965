import DataResource from '@core/resources/DataResource';
import CartHooksManifest from '@/modules/Billing/manifests/CartHooksManifest';
import Vue from 'vue';
import {
  STRIPE_PUBLISHABLE_KEY,
} from '@/modules/Billing/config/stripe';
import { HTTP_UNPROCESSABLE_ENTITY } from '@core/enums/Response';
import { isArray, isNil } from 'lodash';
import { POST_BILLING_PAYMENT_SETUP_INTENT_URL } from '@/modules/Billing/api/billing';

const STRIPE_STATUS_SUCCEEDED = 'succeeded';

export default class Cart extends DataResource {
  constructor (attributes) {
    super(attributes);

    this.setData({
      pk: STRIPE_PUBLISHABLE_KEY,
      cardNumber: null,
      token: { card: {} },
      billingDetails: this.getBillingDetailsFromStore(),
    });

    this.setItems(this.getCartItemsFromStore());
  }

  getBillingDetailsFromStore () {
    const _billingDetails = Vue.prototype.$session.get('billing.billingDetailsData');
    const currentPaymentMethod = this.store.getters['billing/currentPaymentMethod'];
    const billingDetails = currentPaymentMethod?.billing_details;

    return {
      name: _billingDetails?.name ?? billingDetails?.name ?? this.store.getters['auth/user'].data.full_name,
      email: _billingDetails?.email ?? billingDetails?.email ?? this.store.getters['auth/user'].email,
    };
  }

  async add (item) {
    await this.store.dispatch('cart/flushCart');
    await this.store.dispatch('cart/addToCart', item);

    return this;
  }

  setElementCard (elementCard) {
    this.elementCard = elementCard;

    return this;
  }

  setStripe (stripe) {
    this.stripe = stripe;

    return this;
  }

  setToken (token) {
    this.token = token;

    return this;
  }

  getStripeClientSecret () {
    return Vue.prototype.$session.get('billing.clientSecret');
  }

  async confirmCardSetupIfNotExists (card) {
    this.flushErrors();
    this.startLoading();

    try {
      const currentPaymentMethod = this.store.getters['billing/currentPaymentMethod'];
      let billingDetails = currentPaymentMethod?.billing_details;
      if (!isNil(currentPaymentMethod?.id)) {
        // No need to confirmCardSetup as it already exists.
        console.debug('Cart@confirmCardSetupIfNotExists', 'billing details already exists. Skipping...');
        return Promise.resolve(billingDetails);
      }

      billingDetails = Vue.prototype.$session.get('billing.billingDetailsData');
      console.debug('Cart@confirmCardSetupIfNotExists', 'billing details does not exists. Creating...');
      const setupIntentId = await this.axios.post(POST_BILLING_PAYMENT_SETUP_INTENT_URL);
      try {
        const result = await this.stripe.confirmCardSetup(setupIntentId.data.data, {
          payment_method: {
            card,
            billing_details: billingDetails,
          },
        });
        console.debug('[Cart@confirmCardSetup]', result);
        this.store.dispatch('billing/setClientSecret', { clientSecret: result.setupIntent.client_secret });
      } catch (e) {
        console.table(e)
        this.setErrors(e.message);
        this.stopLoading();
        return Promise.reject(e);
      }
    } catch (err) {
      this.setErrors(err);
      this.stopLoading();
      return Promise.reject(err);
    }
  }

  async confirmCardPayment (card) {
    this.startLoading();

    try {
      const currentPaymentMethod = this.store.getters['billing/currentPaymentMethod'];
      const billingDetails = currentPaymentMethod?.billing_details;

      const result = await this.stripe.confirmCardPayment(this.getStripeClientSecret(), {
        payment_method: {
          card,
          billing_details: billingDetails,
        },
        save_payment_method: false,
      });

      if (result.error) {
        this.showErrorMessage([
          result.error.message,
          'Please ensure the card have sufficient funds or is valid.',
        ]);
        this.stopLoading();
        return Promise.reject();
      }

      console.debug('[Cart@confirmCardPayment]', result);
      const status = result?.status ?? result?.paymentIntent?.status;
      if (status === STRIPE_STATUS_SUCCEEDED) {
        this.triggerCartHook();
      }
    } catch (err) {
      this.stopLoading();
      return Promise.reject();
    }

    return Promise.resolve(this);
  }

  showErrorMessage (message, status = HTTP_UNPROCESSABLE_ENTITY) {
    message = isArray(message) ? message.join('<br>') : message;

    this.$dialog({
      color: 'error',
      illustration: () => import('@/modules/Billing/components/Icons/IconCreditCard'),
      title: this.trans('Something went wrong'),
      text: [
        `<p class="small ma-0 muted--text">ERROR ${status}</p>`,
        `<p>Message: ${message}</p>`,
      ],
      buttons: {
        cancel: { show: false },
        action: { text: 'Close' },
      },
    });
  }

  triggerCartHook () {
    CartHooksManifest.getHooks().forEach(hook => hook.trigger(this));
  }

  getPK () {
    return this.data.pk;
  }

  getCartItemsFromStore () {
    return this.store.getters['cart/items'];
  }

  getLineItems () {
    return this.items.map(item => ({
      price: item.id,
      quantity: 1,
    }));
  }

  getCheckoutSessionId () {
    return Vue.prototype.$session.get('billing.checkoutSession');
  }

  async updateCardDetails () {
    this.startLoading();

    try {
      const checkoutSession = this.getCheckoutSessionId();
      await this.stripe.redirectToCheckout({
        sessionId: checkoutSession.id,
      });
    } catch (err) {
      console.debug(err, err.response);
      this.setErrors(e?.response?.data ?? e);
    }

    this.stopLoading();
  }
}
