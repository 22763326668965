export default [
  {
    name: 'dashboard',
    title: 'Dashboard',
    description: 'View Datalake information',
    icon: 'mdi-view-dashboard-outline',
    permission: 'data_dashboard',
    sort: 2,
  },
];
