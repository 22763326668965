var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-mode',{attrs:{"name":"DATA_ATELIER"}},[_c('div',[_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"920px"},model:{value:(_vm.isShown),callback:function ($$v) {_vm.isShown=$$v},expression:"isShown"}},[_c('v-card',{staticClass:"datalake-onboarding-tour ov-hidden"},[_c('v-layout',{staticClass:"dialog__systembar pa-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-8 pt-4 pb-0"},[_c('v-layout',{staticClass:"my-10"},[_c('div',[_c('h2',{staticClass:"font-weight-normal mb-3",domProps:{"textContent":_vm._s(_vm.$trans('Welcome to {name}! {icon}', {
                  name: _vm.$app.appTitle,
                  icon: '🎉',
                }))}}),_c('p',{staticClass:"lead mb-6",domProps:{"textContent":_vm._s(_vm.$trans('Start your information discovery journey now.'))}}),_c('v-btn',{staticClass:"px-10",attrs:{"x-large":"","color":"secondary"},on:{"click":_vm.startTour}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Take a tour'))}})])],1),_c('v-spacer'),_c('div',[_c('div',{staticClass:"small muted--text",domProps:{"textContent":_vm._s(_vm.$trans('You are subscribed to'))}}),_c('img',{staticClass:"mx-auto",attrs:{"src":_vm.$asset.getAppLogo(),"alt":_vm.$app.appTitle,"width":"240"}})])],1),(_vm.hasMaintenanceMessage)?_c('v-alert',{attrs:{"type":"info","outlined":""}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.scheduledMaintenanceMessage)}})]):_vm._e()],1),_c('div',{staticClass:"pa-8 pt-0"},[_c('v-divider',{staticClass:"mx-auto my-5"}),_c('p',{domProps:{"textContent":_vm._s(_vm.$trans('Use the following resources and learn how to transform energy data for sustainability'))}}),_c('v-item-group',[_c('v-layout',{staticClass:"ov-x-auto"},[_vm._l((_vm.resources),function(resource,i){return [_c('v-hover',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-item',[_c('v-card',{staticClass:"mr-4",class:{
                        'elevation-10': hover,
                      },attrs:{"href":resource.url,"outlined":"","color":"grey lighten-3","width":"150","target":"_blank"}},[_c('v-img',{attrs:{"aspect-ratio":960/720,"lazy-src":resource.thumbnail,"src":resource.thumbnail}})],1)],1)]}}],null,true)})]})],2)],1)],1)],1)],1),_c('tour-multi-system',{attrs:{"name":"welcome/onboarding","steps":_vm.steps},on:{"click:stop":_vm.onClickStop,"click:skip":_vm.onClickStop}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }