<template>
  <v-simple-table>
    <thead>
      <tr>
        <th colspan="100%" class="text-uppercase" v-text="$trans('Location')"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td v-text="$trans('Field')"></td>
        <td v-text="item.field_name"></td>
      </tr>
      <tr>
        <td v-text="$trans('Basin')"></td>
        <td v-text="item.basin"></td>
      </tr>
      <tr>
        <td v-text="$trans('Latitude')"></td>
        <td v-text="toCardinalCoordinate(item.latitude)"></td>
      </tr>
      <tr>
        <td v-text="$trans('Longitude')"></td>
        <td v-text="toCardinalCoordinate(item.longitude)"></td>
      </tr>
      <tr>
        <td v-text="$trans('CRS')"></td>
        <td v-text="item.crs"></td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { getCoordinateFromCardinalCoordinate } from '@/modules/Dashboard/utils/map';

export default {
  name: 'TableLocation',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    toCardinalCoordinate (coordinate) {
      return getCoordinateFromCardinalCoordinate(coordinate);
    },
  },
};
</script>
