<template>
  <v-row>
    <template v-if="glance.loading">
      <v-col
        v-for="i in glance.meta.skeletonItemsCount" :key="i"
        :cols="12 / glance.meta.skeletonItemsCount"
      >
        <quick-summary-item-loading-state></quick-summary-item-loading-state>
      </v-col>
    </template>
    <template v-else-if="glance.isEmpty()">
      <v-col cols="12">
        <quick-summary-item-empty-state></quick-summary-item-empty-state>
      </v-col>
    </template>
    <template v-else>
      <v-col
        v-for="(card, i) in glance.items"
        :key="i"
        cols="6"
        md="3"
      >
        <v-card height="100%">
          <v-card-text class="pa-3">
            <v-row align="center" no-gutters>
              <v-col>
                <h4 v-text="card.stat"></h4>
                <div class="text-caption">{{ $trans(card.name) }}</div>
              </v-col>
              <v-col cols="auto">
                <v-icon x-large>{{ card.icon }}</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import Glance from '@/modules/Dashboard/resources/Glance';
import { mapGetters } from 'vuex';

export default {
  name: 'QuickSummary',

  data: () => ({
    glance: new Glance({
      meta: {
        skeletonItemsCount: 4,
      },
    }),
  }),

  computed: {
    ...mapGetters({
      sourceProjects: 'sourcetray/sources',
    }),
  },

  watch: {
    sourceProjects () {
      this.updateList();
    },
  },

  created () {
    this.updateList();
  },

  methods: {
    updateList () {
      this.glance.list();
    },
  },
};
</script>
