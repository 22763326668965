import DataResource from '@core/resources/DataResource';
import { LIST_DATALAKES_URL, FIND_DATALAKE_BY_WELL_ID } from '@/modules/Datalake/api/datalake';
import { isEmpty, isArray } from 'lodash';
import { SOURCETYPE_PUBLICATIONS } from '@/modules/Sourcetray/config/sources';

export default class Datalake extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async list () {
    this.flushErrors();
    this.startLoading();

    const sources = this.store.getters['sourcetray/sources']
      .filter(source => source.sourceType !== SOURCETYPE_PUBLICATIONS)
      .map(source => source.id);

    const params = {
      ...this.getQueryString(),
      projects_list: sources.join(),
    };

    if (sources.length !== 0) {
      try {
        const { data } = await this.axios.get(LIST_DATALAKES_URL, { params });

        if (data.data.length === 0) {
          this.setItems([]);
        } else {
          this.setItems(data.data);
          this.setPagination({
            total: data.meta.pagination.count,
            page: data.meta.pagination.page,
          });
        }
      } catch (e) {
        this.setErrors(e?.response?.data ?? e);
      }
    } else {
      this.setItems([]);  
    }
    
    this.stopLoading();
  }

  async listByWellId (wellId) {
    this.startLoading();

    const { data } = await this.axios.get(FIND_DATALAKE_BY_WELL_ID(wellId));

    this.setItems(data.data);

    this.setPagination({
      total: data.meta.pagination.count,
      page: data.meta.pagination.page,
    });

    this.stopLoading();
  }

  setItems (items) {
    this.items = items.map(item => ({
      ...item,
      ...item.attributes,
      data_index: item?.relationships?.data_index_info?.data?.map(i => i.id)?.[0],
    }));

    return this;
  }

  setQueryString (options) {
    const supportedQuery = this.parseOptionsAsSupportedQuery(options);

    this.query = {
      ...this.getQueryString(),
      ...supportedQuery,
      mode: options.mode
    };

    this.query.order_by = this.prependSign(
      supportedQuery.order_by,
      this.isSortDesc(options.sortDesc),
    );

    this.pushRouteQuery();

    return this.mergeOptions(options, false);
  }

  prependSign (key, isTruthy = false) {
    key = isArray(key) ? key[0] : key;

    if (isEmpty(key)) { return ''; }

    return isTruthy ? `-${key}`.replace('--', '-') : key.replace('-', '');
  }

  isSortDesc (sortDesc = null) {
    sortDesc = sortDesc || this.options.sortDesc;

    return !isEmpty(sortDesc)
      ? sortDesc[0] === true
      : sortDesc === true;
  }
}
