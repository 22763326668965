<template>
  <div class="s-alertbox">
    <alert-error-box></alert-error-box>
    <alert-success-box></alert-success-box>
  </div>
</template>

<script>
/** 
 * Component: Alertbox
 * Displays an error or success notification
 *  
*/
import { mapActions } from 'vuex';

export default {
  name: 'Alertbox',

  computed: {
    actionWasSuccessful () {
      return this.$route.query.success;
    },
  },

  mounted () {
    this.hideErrorbox();

    if (!this.actionWasSuccessful) {
      this.hideSuccessbox();
    }
  },

  methods: {
    ...mapActions({
      hideErrorbox: 'errorbox/hide',
      hideSuccessbox: 'successbox/hide',
    }),
  },
};
</script>
