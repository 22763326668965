import Manifest from '@core/manifests/Manifest';

export default new class RegisterPageCarouselManifest extends Manifest {
  constructor (attributes = {}) {
    super(attributes);

    this.makeContext();
  }

  makeContext () {
    this.requireCarouselItems = require.context(
      // The relative path of the routes folder
      '@/modules',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base names
      /[a-zA-Z0-9-_]\/config\/carousel\.registration.js$/,
    );

    this.items = this.requireCarouselItems
      .keys().sort()
      .map(item => this.requireCarouselItems(item).default).flat();
  }

  getCarouselItems () {
    return this.items;
  }
};
