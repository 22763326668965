<template>
  <v-card flat>
    <template v-if="lithology.isTermsEmpty()">
      <v-layout
        column justify-center
        align-center
        class="pa-10"
      >
        <div class="accent--text pa-2">
          <icon-plant-with-background></icon-plant-with-background>
        </div>
        <span v-text="$trans('No terms found')"></span>
      </v-layout>
    </template>
    <v-card-text v-else>
      <h4 class="mb-4" v-text="$trans('This document talks about')"></h4>
      <template v-for="(word, i) in lithology.meta.topFiveTerms">
        <div :key="i" class="mb-4">
          <p class="ma-0" v-text="word.text"></p>
          <v-progress-linear
            :value="word.percent"
            :title="`${word.percent}%`"
            color="accent"
            rounded height="18"
          >
            <div
              class="font-weight-medium primary--text px-2 caption text-left"
              v-text="word.frequency"
            ></div>
            <v-spacer></v-spacer>
          </v-progress-linear>
        </div>
      </template>

      <template v-if="lithology.hasOtherTerms()">
        <h4 class="mt-10 mb-3" v-text="$trans('Other terms:')"></h4>
        <v-chip
          v-for="(item, i) in lithology.items" :key="`other-terms-chip-${i}`"
          label small
          class="mr-2 mb-2"
        >
          <span v-text="item.text"></span>
          <span class="ml-3" v-text="item.frequency"></span>
        </v-chip>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import Lithology from '@/modules/Report/resources/Lithology';

export default {
  name: 'ReportViewerMetaInfoSummary',

  data: () => ({
    lithology: new Lithology({
      meta: {
        words: [],
        topFiveTerms: [],
      },
    }),
  }),

  async created () {
    await this.lithology.listFrequentWords();
  },
};
</script>
