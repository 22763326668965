<template>
  <v-chip-group v-model="tab" show-arrows>
    <!-- Loading State -->
    <template v-if="noItems">
      <v-skeleton-loader v-for="i in 10" :key="`chip-${i}`" rounded width="150" height="40" type="image" class="mx-1">
      </v-skeleton-loader>
    </template>
    <!-- Loading State -->

    <template v-else>
      <v-chip v-for="(item, i) in items" 
        :id="`category-tab-${(item.text).replace(/\s/g,'-').toLowerCase()}-${i}`"
        :key="i"
        :class="{ primary: isActiveTab(item.id) }" 
        :value="item.id" 
        link 
        label 
        @click="selectTab(item)">
        <span class="mr-3" :class="'category-tab-label'" :title="item.text" v-text="item.text"></span>
        <v-badge 
          :class="'category-tab-count'"
          :color="isActiveTab(i) ? 'primary' : 'grey'" 
          :content="item.value"
          :title="`count-${(item.text).replace(/\s/g,'-').toLowerCase()}-${(item.value)}`" 
          inline>
          </v-badge>
      </v-chip>
    </template>
  </v-chip-group>
</template>

<script>
export default {
  name: 'BucketsTabChipGroup',

  props: {
    value: {
      type: [ Number, Boolean, String, Object ],
      default: null,
    },

    itemKey: {
      type: String,
      default: 'id',
    },

    items: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tab: {
      get () {
        if (typeof this.value === 'object') {
          return this.value?.[this.itemKey];
        }

        return this.value;
      },

      set (value) {
        this.$emit('input', value);
      },
    },

    noItems () {
      return this.items.length === 0 && this.loading;
    },
  },

  methods: {
    isActiveTab (key) {
      return this.tab === key;
    },

    selectTab (item) {
      this.$emit('click:tab', { item, active: this.isActiveTab(item.id) });
    },
  },
};
</script>
