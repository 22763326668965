import DataResource from '@core/resources/DataResource';
import fileDownload from 'js-file-download';
import Map from '@/modules/Dashboard/resources/Map';
import { isArray, isEmpty, sample } from 'lodash';
import { FIND_WELL_INFO_URL, GET_WELLS_INFO_URL, EXPORT_WELLS_URL } from '@/modules/Dashboard/api/wells';

export default class Well extends DataResource {
  constructor (options = {}) {
    super(options);

    this.map = new Map(options.map);
  }

  setMap (map) {
    this.map = map;
  }

  setDefaultHeaders () {
    this.setHeaders([
      { text: 'Well', value: 'attributes.well_name' },
      { text: 'basin', value: 'attributes.basin' },
      { text: 'Field', value: 'attributes.field_name' },
      { text: 'Operator', value: 'attributes.operator' },
      { text: 'Spud Date', value: 'attributes.spud' },
      { text: 'TVDSS (m)', value: 'attributes.tvdss' },
      { text: 'Water Depth (m)', value: 'attributes.water_depth' },
      { text: 'KB (m)', value: 'attributes.kelly_bushing' },
      { text: 'Post Drill', value: 'attributes.post_drill_conclusion' },
      { text: 'Well Type', value: 'attributes.well_type' },
    ]);

    return this;
  }

  async list () {
    this.flushErrors();
    this.startLoading();

    const params = {
      ...this.getQueryString(),
      projects_list: !isEmpty(this.getProjectIds()) ? this.getProjectIds() : null,
    };
    try {
      const { data } = await this.axios.get(GET_WELLS_INFO_URL, { params });

      this.setItems(data.data);

      this.setPagination({
        total: data.meta.pagination.count,
        page: data.meta.pagination.page,
        pageCount: data.meta.pagination.pages,
      });
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    } finally {
      this.stopLoading();
    }
  }

  async find (id) {
    this.startLoading();

    const { data } = await this.axios.get(FIND_WELL_INFO_URL(id));

    this.setData(data);

    this.stopLoading();
  }

  async exportSelectedItems () {
    try {
      const { data } = await this.axios.post(EXPORT_WELLS_URL, {
        well_ids: this.getSelectedItemsAsString('id'),
      });

      if (data.data && data.data.detail) {
        return this.dialog('error', { alignment: 'center', text: data.data.detail });
      }

      return fileDownload(data, 'wells.csv');
    } catch ({ response }) {
      const { message } = response.data;

      return this.dialog('error', {
        color: 'accent',
        persistent: true,
        illustration: () => import('@/components/Icons/IconListOneRowError'),
        illustrationHeight: 200,
        title: 'Download Limit Reached',
        text: message,
      });
    }
  }

  setItems (items) {
    this.items = items.map(item => ({
      id: item.id,
      type: item.type,
      active: false,
      attributes: item.attributes,
      ...item.attributes,
    }));
    this.map.setItems(items);
    this.map.updateMap(items, this.search);
    this.map.search = this.search;
  }

  setQueryString (options) {
    const supportedQuery = this.parseOptionsAsSupportedQuery(options);

    this.query = {
      ...this.getQueryString(),
      ...supportedQuery,
    };

    this.query.page_size = this.query.page_size === -1
      ? this.pagination.total
      : this.query.page_size;

    this.query.order_by = this.prependSign(
      supportedQuery.order_by,
      this.isSortDesc(options.sortDesc),
    );

    this.pushRouteQuery();

    return this.mergeOptions(options);
  }

  prependSign (key, isTruthy = false) {
    key = isArray(key) ? key[0] : key;

    if (isEmpty(key)) { return ''; }

    return isTruthy ? `-${key}`.replace('--', '-') : key.replace('-', '');
  }

  getRandomSuggestion () {
    return sample(this.meta.suggestions);
  }

  toggleSelectAll (items, isSelected) {
    items.map(item => this.toggleRowSelect(item, isSelected));
  }

  toggleRowSelect (item, isSelected) {
    if (this.meta.singleSelect) {
      this.deactivateAllItems();
    }

    if (isSelected) {
      this.activateItem(item);
      this.showOnMap(item, this.meta.singleSelect);
    } else {
      this.deactivateItem(item);
      this.clearFromMap(item, 'rgba(88, 88, 88, 0.4)');
    }
  }

  activateItem (item) {
    item.active = true;

    return this;
  }

  deactivateItem (item) {
    item.active = false;

    return this;
  }

  toggleActivateItem (item) {
    item.active = !item.active;

    return item.active;
  }

  deactivateAllItems () {
    this.items.forEach(i => { i.active = false; });

    return this;
  }

  showOnMap (item) {
    this.map.setToOpen();

    this.map.setCoordinatesFromResultLayer({
      lng: item.longitude,
      lat: item.latitude,
      properties: item,
    }, 'red');

    return this;
  }

  clearFromMap (item, color = '#09d034') {
    this.map.setCoordinatesFromResultLayer({
      lng: item.longitude,
      lat: item.latitude,
      properties: item,
    }, color, false);

    return this;
  }

  clearAllSelectedMapMarkers () {
    this.deactivateAllItems();
    this.map.clearSelectedLayer();
  }

  isSortDesc (sortDesc = null) {
    sortDesc = sortDesc || this.options.sortDesc;

    return !isEmpty(sortDesc)
      ? sortDesc[0] === true
      : sortDesc === true;
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }

  getWebGISUrl () {
    const layer = this.map.selectedLayer.getFirstLayer();

    return {
      name: 'webgis',
      query: {
        well_id: layer?.options?.metadata?.id,
        back: this.route.fullPath,
      },
    };
  }
}
