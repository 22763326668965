export const state = {
  camera2D: null,
  camera3D: null,
};

export const getters = {
  camera2D: state => state.camera2D,
  camera3D: state => state.camera3D,
};

export const mutations = {
  SET_CAMERA2D (state, camera2D) {
    state.camera2D = camera2D;
  },
  SET_CAMERA3D (state, camera3D) {
    state.camera3D = camera3D;
  },
};

export const actions = {
  setCamera2D ({ commit }, camera2D) {
    commit('SET_CAMERA2D', camera2D);
  },
  setCamera3D ({ commit }, camera3D) {
    commit('SET_CAMERA3D', camera3D);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
