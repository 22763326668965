import ActionMenuRegistrar from '@core/registrars/ActionMenuRegistrar';

ActionMenuRegistrar.register([
  {
    icon: 'mdi-palette-outline',
    text: 'Preference',
    to: { name: 'preferences' },
    sort: 100,
  },
]);
