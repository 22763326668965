// TODO: to be depricated
export const items = [
  {
    text: 'Map',
    key: 'map-area',
    storeKey: 'showMapArea',
    active: true,
    component: () => import('@/modules/Search/components/MapArea/MapAreaMapTabItem'),
  },
  // {
  //   text: 'Knowledge Graph',
  //   key: 'knowledge-graph',
  //   storeKey: 'showKnowledgeGraphArea',
  //   active: false,
  //   component: () => import('@/modules/Search/components/MapAreaTabs/MapAreaKnowledgeGraphTabItem'),
  // },
];

export default {
  currentIndex: 0,
  items: items.map((item, index) => ({ ...item, index })),
};
