var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-result-card__thumbnail mb-6"},[_c('v-card',[_c('v-card-text',{staticClass:"px-4 ov-y-auto"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.chatbot.loading)?_c('div',[_c('div',{staticClass:"mb-0 pb-1",style:({ whiteSpace: 'pre-line' }),domProps:{"textContent":_vm._s('Generating summary for favorite items. Please wait...')}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})],1):_c('div',[_c('div',{staticClass:"markdown-content",domProps:{"innerHTML":_vm._s(_vm.markdownText)}}),_c('v-row',{staticClass:"mx-1 mb-1 mt-8",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9"}},[_c('div',{staticClass:"font-weight-light",staticStyle:{"font-size":"12px"}},[_vm._v("This is an AI generated response and may incur errors."),_c('br'),_vm._v(" Please check the outputs before using.")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.chatbot.meta.hasTables)?_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.exportCsv}},on),[_c('v-icon',[_vm._v("mdi-file-delimited-outline")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Export Tables as CSV")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.copyText}},on),[_c('v-icon',[_vm._v("mdi-clipboard-outline")])],1)]}}])},[_c('span',[_vm._v("Copy response")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-thumb-up-outline")])],1)]}}])},[_c('span',[_vm._v("This response is good")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-message-draw")])],1)]}}])},[_c('span',[_vm._v("This response needs review")])])],1)],1)])],1)],1),_c('v-card-actions',{staticClass:"px-4"})],1),_c('v-snackbar',{attrs:{"timeout":"3000","color":"success"},model:{value:(_vm.showSnack),callback:function ($$v) {_vm.showSnack=$$v},expression:"showSnack"}},[_vm._v("Copied to clipboard")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }