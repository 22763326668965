<template>
  <app-content>
    <v-row>
      <v-col cols="12" lg="10" class="mx-auto">
        <page-header></page-header>
      </v-col>
    </v-row>

    <v-row class="fill-height">
      <v-col cols="12" lg="10" class="mx-auto">
        <h1 class="font-weight-bold mb-6">
          <strong v-text="$trans(page.data.title)"></strong>
        </h1>
        <div v-html="page.data.content"></div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="10" class="mx-auto">
        <v-layout>
          <v-btn :disabled="page.hasNoPrev()" text @click="prev">
            <v-icon left>mdi-arrow-left</v-icon>
            <span v-text="$trans('Previous')"></span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="page.hasNoNext()" text @click="next">
            <span v-text="$trans('Next')"></span>
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import Page from '@/modules/Datawarehouse/resources/Page';
import DocumentLayout from '@/modules/Datawarehouse/layouts/document';

export default {
  layout: DocumentLayout,

  data: () => ({
    page: new Page,
  }),

  created () {
    this.page.find(this.$slug);
  },

  methods: {
    next () {
      this.page.goToNext();
    },

    prev () {
      this.page.goToPrev();
    },
  },
};
</script>
