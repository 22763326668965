import { BASE_URL } from '@/config/api';

export const UPLOAD_UPLOADER_URL = `${BASE_URL}/uploader/upload`;
export const LIST_UPLOAD_SETS_URL = `${BASE_URL}/uploader/sets/list`;
export const FIND_UPLOAD_SET_URL = id => `${BASE_URL}/uploader/sets/${id}/`;
export const FIND_UPLOAD_SET_LOGS_URL = id => `${BASE_URL}/uploader/sets/${id}/logs`;
export const SUBMIT_FOR_APPROVAL = id => `${BASE_URL}/uploader/sets/${id}/for-approval`;
export const GET_PRE_AUDIT_REPORT_URL = id => `${BASE_URL}/uploader/report/pre-audit/${id}`;
export const GET_SET_REPORT_URL = (type, id) => `${BASE_URL}/uploader/report/${type}/${id}`;
export const GET_APPROVAL_REPORT_URL = id => `${BASE_URL}/uploader/report/approval/${id}`;
export const SET_FILESET_STATUS_URL = id => `${BASE_URL}/uploader/sets/${id}/status`;

export default Object.freeze({
  UPLOAD_UPLOADER_URL,
  FIND_UPLOAD_SET_URL,
  SUBMIT_FOR_APPROVAL,
  LIST_UPLOAD_SETS_URL,
  FIND_UPLOAD_SET_LOGS_URL,
  GET_PRE_AUDIT_REPORT_URL,
  GET_APPROVAL_REPORT_URL,
  SET_FILESET_STATUS_URL,
  GET_SET_REPORT_URL,
});
