<template>
  <v-menu min-width="280">
    <template v-slot:activator="{ on }">
      <div :id="'app-bar-user-avatar'" role="button" v-on="on">
        <div class="d-flex justify-space-between align-center">
          <v-avatar size="38"><img :src="user.avatar" width="40px"></v-avatar>
        </div>
      </div>
    </template>
    <v-card>
      <app-bar-user-menu-list :items="menus"></app-bar-user-menu-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionMenuRegistrar from '@core/registrars/ActionMenuRegistrar';

export default {
  name: 'AppBarUserMenu',

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),

    menus () {
      return ActionMenuRegistrar.all();
    },
  },
};
</script>
