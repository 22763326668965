<template>
  <router-view></router-view>
</template>

<script>
export default {
  layout: 'admin',

  created () {
    const pod = this.$options.pages.find(i => i.meta.isWorkflow);
    if (pod) {
      this.$router.push(pod).catch(() => {});
    } else {
      this.$router.push(this.$user.getRedirectRouteFromEnv()).catch(() => {});
    }
  },
};
</script>
