import { FEEDBACK_URL } from '@/config/app';
import ActionMenuRegistrar from '@core/registrars/ActionMenuRegistrar';

ActionMenuRegistrar.register([
  {
    icon: 'mdi-comment-alert',
    action: 'mdi-open-in-new',
    text: 'Send feedback',
    attrs: {
      href: FEEDBACK_URL,
      target: '_blank',
    },
    sort: 500,
  },
]);
