<template>
  <v-alert
    outlined text
    type="info"
    class="mb-8"
  >
    <div v-for="(message, i) in messages" :key="i" class="mb-8">
      <h4 v-html="$trans(message.title)"></h4>
      <div
        v-for="(item, j) in message.items" :key="j"
        class="mb-1"
        v-html="$trans(item)"
      ></div>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: 'AlertSearchQueryHint',

  data: () => ({
    messages: [
      {
        title: 'Type in the query in the search box above',
        items: [
          'Try some terms that are present in your uploaded files',
        ],
      },
      {
        title: 'To handle some complex queries',
        items: [
          '"&lt;term_1&gt;&lt;term_2&gt;"',
          '<em><strong>Quotations</strong></em> around queries force one concept search in succession (terms 1 and term 2 must occur in succession/order).',
          '<br>',
          '&lt;term_1&gt; AND &lt;term_2&gt;',
          '<em><strong>AND operator</strong></em> denotes both terms must exist in the same search result. This does not enforce term succession.',
          '<br>',
          '&lt;term_1&gt; OR &lt;term_2&gt;',
          '<em><strong>OR operator</strong></em> denotes that either term_1 only, term_2 only, or both must exist in the same search result.',
        ],
      },
    ],
  }),
};
</script>
