export const supportedQuery = [
  'q',
  'page',
  'bucket',
  'img_tag_1',
  'page_from',
  'project_id',
  'projects_list',
  { search: 'q' },
  { sortBy: 'order_by' },
  { itemsPerPage: 'page_size' },
];

export const unsupportedQuery = [];

export default {
  supportedQuery,
  unsupportedQuery,
};
