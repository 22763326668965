import { isNil } from 'lodash';

export default {
  methods: {
    can (permission) {
      if (!this.$user) {
        console.error('this.$user is not declared. You should register your own $user instance.');
      }

      if (isNil(permission)) {
        return true;
      }

      if (permission.includes('|')) {
        const permissions = permission.split('|')
          .map(i => (this.$user && this.$user.isPermittedTo(i)))
          .filter(i => i);
        const isPermitted = permissions.length > 0;

        return isPermitted;
      }

      return this.$user && this.$user.isPermittedTo(permission);
    },

    cannot (permission) {
      return !this.can(permission);
    },
  },
};
