<template>
  <component
    :is="src"
    v-bind="$attrs"
  ></component>
</template>

<script>
export default {
  name: 'AppIllustration',

  props: {
    src: {
      type: [ Object, String ],
      default: null,
    },
  },
};
</script>
