<template>
  <app-stickybar
    v-slot:default="{ stuck }"
    class="mb-0"
  >
    <v-list
      :style="`${stuck ? 'position: fixed;' : null}`"
      dense
    >
      <template v-for="(menu, i) in page.items">
        <v-list-group v-if="menu.children && menu.children.length" :key="i">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(submenu, j) in menu.children"
            :key="j"
            :title="submenu.description"
            :color="getActiveMenuColor(submenu.to)"
            :class="{ 'app-sidebar__item--active': isCurrentHash(submenu.to.hash) }"
            @click="goToPageSection(submenu.to)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ submenu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :key="i"
          :title="menu.description"
          :color="getActiveMenuColor(menu.to)"
          :class="{ 'app-sidebar__item--active': isCurrentHash(menu.to.hash) }"
          @click="goToPageSection(menu.to)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </app-stickybar>
</template>

<script>
import documents from '@/modules/Datawarehouse/config/documents';
import Page from '@/modules/Datawarehouse/resources/Page';

export default {
  name: 'DocumentSidebar',

  data: () => ({
    page: new Page({
      items: documents,
    }),
  }),

  computed: {
    currentHash: {
      get () {
        return this.$store.getters['docs/currentHash'];
      },
      set (hash) {
        this.$store.dispatch('docs/setCurrentHash', hash);
      },
    },
  },

  methods: {
    getActiveMenuColor ({ hash }) {
      return this.isCurrentHash(hash) ? 'primary' : null;
    },

    isCurrentHash (hash) {
      return this.currentHash === hash;
    },

    goToPageSection ({ hash }) {
      this.currentHash = hash;
      this.$vuetify.goTo(hash);
    },
  },
};
</script>

<style lang="scss">
.app-sidebar__item {
  &--active {
    background-color: rgb(0, 0, 0, 0.025);
    border-right: 2px solid var(--v-primary-base);

    &::before {
      background-color: currentColor;
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
  }
}
</style>
