<template>
  <portal to="app:loading">
    <v-progress-linear
      :indeterminate="loading"
      fixed top
      class="app-loader"
    ></v-progress-linear>
  </portal>
</template>

<script>
export default {
  name: 'AppLoader',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.app-loader {
  z-index: 1000;
}
</style>
