var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"bbox-card-container",staticClass:"bbox-card__container"},[(_vm.enabled)?[_vm._l((_vm.quadrilaterals),function(item,i){return [(_vm.isNotTable(item))?_c('v-hover',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{key:("bbox-card__item-" + i),staticClass:"bbox-card__item rounded ov-y-visible",style:(_vm.getBboxItemStyles(item, i)),attrs:{"id":("bbox-card__item-" + i),"color":"transparent"}},[(hover)?_c('div',{staticClass:"bbox-card__item-highlighter fill-height rounded yellow darken-1"}):_vm._e(),_c('bbox-ocr-indicator',{staticClass:"bbox-ocr-indicator",attrs:{"always-show":"","pulsate":""}}),_c('bbox-click-card',{staticClass:"bbox-card__item-container",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var clicked = ref.clicked;
return [_c('v-slide-y-reverse-transition',{attrs:{"mode":"in-out"}},[(clicked && _vm.isNotTable(item))?_c('bbox-card',{staticStyle:{"min-height":"75px"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"bbox-card__item-actions my-1"},[_vm._t("bbox.actions",[_c('v-btn',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.emitCopy(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-copy")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Copy'))}})],1)],null,{
                          item: item,
                          clicked: clicked,
                          highlightedText: _vm.getHighlightedText(),
                        })],2)]},proxy:true}],null,true)},[_c('div',{staticClass:"bbox-card__item-content w-100 rounded fill-height ov-y-auto"},[_c('div',{staticClass:"bbox-card__item-text panzoom-exclude"},[_vm._t("bbox.content",null,null,{ item: item })],2)])]):_vm._e()],1)]}}],null,true)})],1)]}}],null,true)}):_c('v-sheet',{key:("bbox-card__item-" + i),staticClass:"bbox-card__item rounded ov-y-visible",style:(_vm.getStyles(item)),attrs:{"id":("bbox-card__item-" + i),"color":"transparent"}},[_c('div',{class:_vm.parseBboxStyles([
            'bbox-card__item-highlighter',
            'bbox-card__item-highlighter__table',
            'fill-height',
            'rounded',
            'outlined' ]),on:{"click":function($event){$event.stopPropagation();return _vm.tableClicked(item)}}})])]})]:_vm._e(),_vm._t("default"),_c('router-view')],2)}
var staticRenderFns = []

export { render, staticRenderFns }