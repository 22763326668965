<template>
  <div>
    <!-- DataSource Tray -->
    <data-source-tray-filter
      width="400px"
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <portal to="header:left">
      <app-search-field
        v-model.trim.lazy="datalake.search"
        :title="'search datalake'"
        :loading="datalake.searching"
        :label="$trans('Search datalake')"
        :placeholder="$trans('Search datalake')"
        autofocus solo
        @click:clear="clearSearch"
        @enter:search="setSearch"
      ></app-search-field>
    </portal>

    <v-data-table
      :footer-props="{ 'items-per-page-options': datalake.options.rowsPerPage }"
      :dense="datalake.meta.dense"
      :headers="datalake.headers"
      :items="datalake.items"
      :loading="datalake.loading"
      :mobile-breakpoint="datalake.meta.mobileBreakpoint"
      :server-items-length="datalake.pagination.total"
      :show-select="datalake.meta.showSelect"
      :options.sync="datalake.options"
      @update:options="updateList"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <app-stickybar class="bg-white">
          <app-data-table-toolbar
            :size="datalake.selectedSize()"
            @click:clear="unselectAll"
          ></app-data-table-toolbar>
          <v-spacer></v-spacer>
          <v-data-footer
            class="no-border"
            :pagination="pagination"
            :options="options"
            :items-per-page-options="datalake.options.rowsPerPage"
            @update:options="updateOptions"
          ></v-data-footer>
        </app-stickybar>
        <v-divider></v-divider>
      </template>

      <template v-slot:item.article_title="{ item }">
        <div class="text-truncate text-no-wrap" style="max-width: 400px;">
          <app-can-view code="report_viewer">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <router-link
                  :to="{ name: 'reports.single', params: { slug: item.doc_ref } }"
                  class="text-no-wrap"
                >
                  <span v-on="on" v-text="item.article_title"></span>
                </router-link>
              </template>
              <span
                v-text="$trans('View {name}', {
                  name: item.article_title,
                })"
              ></span>
            </v-tooltip>
            <template v-slot:alt>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" v-text="item.article_title"></span>
                </template>
                <span
                  v-text="$trans('View {name}', {
                    name: item.article_title,
                  })"
                ></span>
              </v-tooltip>
            </template>
          </app-can-view>
        </div>
      </template>

      <template v-slot:item.publication_date="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ item.publication_date | toDateTime('LL', 'YYYY/MM/DD') }}</span>
          </template>
          <span v-text="item.publication_date"></span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Publication from '@/modules/Datalake/resources/Publication';
import GetFileTypeIcon from '@core/mixins/GetFileTypeIcon';
import PrettyBytes from '@core/filters/PrettyBytes';
import PrettyDates from '@core/filters/PrettyDates';
import { mapGetters } from 'vuex';

export default {
  name: 'Publications',

  mixins: [ GetFileTypeIcon, PrettyBytes, PrettyDates ],

  props: {
    resource: {
      type: Object,
      default: () => {},
    },
  },

  data: vm => ({
    datalake: new Publication(vm.resource),
  }),

  computed: {
    ...mapGetters({
      itemsPerPage: 'datalake/itemsPerPage',
    }),
  },

  watch: {
    itemsPerPage (value) {
      this.datalake.store.dispatch('datalake/setItemsPerPage', value);
    },
  },

  mounted () {
    this.$emit('resource:update', this.datalake);
  },

  methods: {
    changedDataSource ({ query }) {
      this.datalake.setQueryString(query).list();
    },

    updateList (options) {
      this.datalake.store.dispatch('datalake/setItemsPerPage', options.itemsPerPage);
      this.datalake.setQueryString(options).list();
    },

    setSearch (e) {
      this.datalake.setSearch(e.target.value).list();
    },

    clearSearch () {
      this.datalake.clearSearch();
    },

    unselectAll () {
      this.datalake.unselectAll();
    },
  },
};
</script>
