<template>
  <v-card rounded class="ov-hidden">
    <map-area
      v-model="well.map" zoom-bar
      height="325"
    >
      <template v-slot:corner.top-right>
        <v-layout class="ma-2">
          <v-btn
            small class="ma-1"
            min-height="32"
            @click="goToWebGISUrl"
            v-text="$trans('Go to WebGIS')"
          ></v-btn>
          <map-gis-layer-control-btn
            :title="$trans('Show/hide basemaps and other overlays')"
            small
            class="ma-1"
          >
            <map-gis-layer-control-card
              :basemaps="webgis.meta.basemaps"
              :layers="webgis.meta.layers"
              dense width="300"
              max-height="325"
              @change:basemaps="changedBasemaps"
              @change:layers="changedLayers"
            ></map-gis-layer-control-card>
          </map-gis-layer-control-btn>
        </v-layout>
      </template>
    </map-area>
  </v-card>
</template>

<script>
import Well from '@/modules/Dashboard/resources/Well';
import Datalake from '@/modules/Dashboard/resources/Datalake';
import WebGIS from '@/modules/Webgis/resources/WebGIS';
import { mapGetters } from 'vuex';
import { API_PER_PAGE_ALL } from '@config/api';

export default {
  name: 'WorldMapArea',

  props: {
    item: {
      type: [ Object, Array ],
      default: () => {},
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    datalake: new Datalake,
    well: new Well({
      map: { id: 'world-well-map', meta: { refresh: false } },
    }),
    webgis: new WebGIS({
      meta: {
        basemaps: {},
        layers: {},
      },
    }),
  }),

  computed: {
    ...mapGetters({
      selectedWell: 'report/selectedWell',
    }),

    wellInfo () {
      return this.selectedWell ?? {
        ...this.item?.data?.selected,
        ...this.item?.data?.selected.attributes,
      };
    },

    noItem () {
      return !this.item.hasItem;
    },
  },

  watch: {
    selectedWell (well) {
      this.generateMapMarkers(well);
    },

    'item.open': async function (isOpen) {
      if (isOpen) {
        await this.well.map.invalidateSize();
        if (this.noItem) {
          this.well.map.initialize();
        }
        this.initializeMapData();
      }
    },

    'webgis.meta.basemaps.current': function (basemap) {
      this.changedBasemaps(basemap);
    },

    'webgis.meta.layers.current': function (layers) {
      this.changedLayers(layers);
    },
  },

  async mounted () {
    this.initializeMapData();
  },

  methods: {
    async initializeMapData () {
      this.well.map.startLoading();

      await this.well.setQueryString({ per_page: API_PER_PAGE_ALL }).list();
      await this.datalake.findGeometries(this.$slug);

      const geometryIds = await this.datalake.meta.geometry;
      const geometryIdsCount = [
        ...geometryIds.wells,
        ...geometryIds.basins,
        ...geometryIds.countries,
      ];

      if (geometryIdsCount.length === 0) {
        this.item.disabled = true;
      }

      if (this.item.hasItem) {
        await this.generateMapMarkers(this.wellInfo);
      } else {
        await this.well.map.setHighlightFeatures(geometryIds);
      }

      this.webgis.attachToCurrentMap(this.well.map);
      this.well.map.stopLoading();
    },

    generateMapMarkers (well) {
      this.well.map
        .clearSelectedLayer()
        .setGreenMarkersWithPopup(this.item?.data?.items)
        .setRedMarkerWithPopup(well);
    },

    changedBasemaps (basemap) {
      if (this.webgis) {
        this.webgis
          .setBasemapLayer(basemap, this.well.map.map)
          .setBasemapZIndexToDefault();
      }
    },

    changedLayers (layers) {
      if (this.webgis) {
        this.webgis.setLayerOverlays(layers, this.well.map.map);
      }
    },

    goToWebGISUrl () {
      this.$router.push(this.well.getWebGISUrl());
    },
  },
};
</script>
