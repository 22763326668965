import Vue from 'vue';
import moment from 'moment';

const KEY_ORDER_PAGE_AVAILABILITY = 'order.availability';

export const state = {
  availability: 0,
};

export const getters = {
  availability: state => state.availability,
};

export const mutations = {
  SET_AVAILABILITY (state, time) {
    state.availability = time;
    Vue.prototype.$session.set(KEY_ORDER_PAGE_AVAILABILITY, time);
  },

  UNSET_AVAILABILITY (state) {
    state.availability = 0;
  },

  UNSET_SESSION (state) {
    state.availability = 0;
    Vue.prototype.$session.remove(KEY_ORDER_PAGE_AVAILABILITY);
  },
};

export const actions = {
  generateAvailabilityLimit ({ commit }, { limit = 2, period = 'minutes' } = {}) {
    const time = moment().add(limit, period).valueOf();
    commit('SET_AVAILABILITY', time);
  },

  setAvailability ({ commit }, time) {
    commit('SET_AVAILABILITY', time);
  },

  unsetAvailability ({ commit }) {
    commit('UNSET_AVAILABILITY');
  },

  unsetSession ({ commit }) {
    commit('UNSET_SESSION');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
