<template>
  <div
    :style="{
      background: color,
      width,
      height,
    }"
    class="rounded-circle"
    v-bind="$attrs"
  ></div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },

    height: {
      type: String,
      default: '1rem',
    },

    width: {
      type: String,
      default: '1rem',
    },
  },
};
</script>
