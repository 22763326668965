<template>
  <app-content class="pt-0">
    <v-row>
      <v-col cols="12" lg="10" class="mx-auto">
        <page-header></page-header>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="10" class="mx-auto">
        <div class="pt-10 mt-10"></div>
        <page-section
          v-for="(page, i) in page.items" :key="i"
          :name="page.slug"
          class="mb-10 pb-10"
        >
          <template v-slot:title="{ goToSection }">
            <a @click="goToSection(`#${page.slug}`)">
              <h1 class="mb-6 font-weight-bold" v-text="$trans(page.title)"></h1>
            </a>
          </template>
          <div class="page-content" v-html="page.content"></div>

          <div v-if="page.hasChild()" class="page-subcontent mt-10 ml-10">
            <page-section
              v-for="(section, j) in page.children" :key="j"
              :name="section.slug"
              class="mb-10"
            >
              <template v-slot:title="{ goToSection }">
                <a @click="goToSection(`#${section.slug}`)">
                  <h1 class="mb-6 font-weight-bold" v-text="$trans(section.title)"></h1>
                </a>
              </template>
              <div class="page-content" v-html="section.content"></div>
              <v-divider class="my-15"></v-divider>
            </page-section>
          </div>
        </page-section>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import documents from '@/modules/Datawarehouse/config/documents';
import Page from '@/modules/Datawarehouse/resources/Page';
import DocumentLayout from '@/modules/Datawarehouse/layouts/document';

export default {
  layout: DocumentLayout,

  data: () => ({
    page: new Page({
      items: documents,
    }),
  }),
};
</script>
