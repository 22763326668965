<template>
  <app-content>
    <!-- DataSource Tray -->
    <data-source-tray-filter
      width="400px"
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <!-- Quick Stats -->
    <!-- <quick-summary></quick-summary> -->
    <!-- Quick Stats -->

    <!-- Search -->
    <v-row fill-height>
      <v-col cols="12" lg="6">
        <app-search-field
          v-model="well.search"
          :label="$trans('Search table')"
          :placeholder="$trans('Search table')"
          :loading="well.searching"
          :autofocus="isDesktop"
          solo
          class="mb-4"
          @click:clear="clearSearch"
          @enter:search="setSearch"
        ></app-search-field>
      </v-col>
    </v-row>
    <!-- Search -->

    <!-- Main Datatable -->
    <v-card>
      <app-stickybar class="bg-white">
        <template v-slot:default="{ stuck }">
          <v-layout
            :class="{ 'elevation-8': stuck }"
            class="text-no-wrap flex-no-wrap ov-x-auto"
          >
            <app-data-table-toolbar
              :size="well.selectedSize()"
              exportable
              class="flex-grow-0 flex-no-wrap"
              @click:export="exportSelectedItems"
              @click:clear="unselectAll"
            ></app-data-table-toolbar>
            <v-spacer></v-spacer>
            <v-data-footer
              class="no-border flex-grow-1 flex-no-wrap text-no-wrap ov-x-auto"
              :pagination="well.pagination"
              :options="well.options"
              :items-per-page-options="well.meta.rowsPerPage"
              @update:options="updateList"
            ></v-data-footer>
          </v-layout>
        </template>
      </app-stickybar>
      <v-divider></v-divider>
      <v-data-table
        v-model="well.selected"
        :footer-props="{ 'items-per-page-options': well.meta.rowsPerPage }"
        :dense="well.meta.dense"
        :single-select="well.meta.singleSelect"
        :headers="well.headers"
        :items="well.items"
        :loading="well.loading"
        :mobile-breakpoint="well.meta.mobileBreakpoint"
        :server-items-length="well.pagination.total"
        :show-select="well.meta.showSelect"
        :options.sync="well.options"
        :item-class="well.meta.itemClass"
        @click:row="onRowClick"
        @item-selected="toggleRowSelect"
        @toggle-select-all="toggleSelectAll"
        @update:options="updateList"
      >
        <template v-slot:loading>
          <div class="py-4" v-text="$trans('Loading items...')"></div>
        </template>

        <template v-slot:no-data>
          <div class="py-4" v-text="$trans('No data available')"></div>
        </template>

        <template v-for="header in well.headers" v-slot:[`header.${header.value}`]>
          <span
            :key="header.value"
            :title="$trans(header.text)"
            v-text="$trans(header.text)"
          ></span>
        </template>

        <template v-slot:item.tag_no="{ item }">
          <router-link
            :to="{
              name: 'reports.single',
              params: {
                slug: item.link,
              },
              query: {
                data_index: item.data_index,
              },
            }"
          >
            <div
              :title="item.tag_no"
              class="text-no-wrap"
              style="width: auto;"
              v-text="item.tag_no"
            ></div>
          </router-link>
        </template>

        <template v-slot:item.attributes.well_name="{ item }">
          <app-can-view code="report_viewer">
            <router-link
              :to="{
                name: 'reports.well',
                query: {
                  wellId: item.id,
                  noPageNum: true,
                },
              }"
            >
              <div
                :title="item.well_name"
                class="text-no-wrap text-truncate"
                style="width: auto;"
                v-text="item.well_name"
              ></div>
            </router-link>
            <template v-slot:alt>
              <div
                :title="item.well_name"
                class="text-no-wrap text-truncate"
                style="width: 100px;"
                v-text="item.well_name"
              ></div>
            </template>
          </app-can-view>
        </template>

        <template v-for="(header, i) in well.headers" v-slot:[`item.${header.value}`]="{ item }">
          <template v-if="header.isLink">
            <router-link
              :key="i"
              :to="{
                name: 'reports.single',
                params: {
                  slug: item.link,
                },
                query: {
                  data_index: item.data_index,
                },
              }"
            >
              <div
                :title="item[header.value]"
                class="text-no-wrap"
                style="width: auto;"
                v-text="item[header.value]"
              ></div>
            </router-link>
          </template>
          <template v-else>
            <span :key="i" v-text="item[header.value]"></span>
          </template>
        </template>
      </v-data-table>
    </v-card>
    <!-- Main Datatable -->
  </app-content>
</template>

<script>
import Well from '@/modules/Dashboard/resources/WellGeneral';
import Breakpoints from '@core/mixins/Breakpoints';
import BackToTop from '@core/mixins/BackToTop';

export default {
  layout: 'admin',

  meta: {
    permissions: [ 'data_dashboard' ],
  },

  mixins: [ BackToTop, Breakpoints ],

  data: vm => ({
    well: new Well({
      options: {
        itemsPerPage: 50,
        sortBy: [ vm.$route.query.order_by || 'well_name' ],
      },
      meta: {
        rowsPerPage: [ 5, 10, 15, 20, 50, 100 ],
        dense: vm.isDesktop,
        showSelect: true,
        singleSelect: false,
        mobileBreakpoint: 420,
        itemClass: item => (item.active ? 'red lighten-5' : null),
        supportedQuery: [
          'q',
          'page',
          'order_by',
          'projects_list',
          { search: 'q' },
          { sortBy: 'order_by' },
          { itemsPerPage: 'page_size' },
        ],
      },
    }),
  }),

  methods: {
    changedDataSource ({ query }) {
      this.well.setQueryString({ ...query, ...this.well.options }).listDynamic();
    },

    updateList (options) {
      this.well.setQueryString(options).listDynamic();
    },

    setSearch (e) {
      this.well.setSearch(e.target.value).listDynamic();
    },

    clearSearch () {
      this.well.clearSearch().listDynamic();
    },

    unselectAll () {
      this.well.unselectAll().clearAllSelectedMapMarkers();
    },

    onRowClick (item) {
      this.well.toggle(item).toggleRowSelect(item, this.well.isSelected(item));
    },

    toggleRowSelect ({ item, value }) {
      this.well.toggleRowSelect(item, value);
    },

    toggleSelectAll ({ items, value }) {
      this.well.toggleSelectAll(items, value);
    },

    exportSelectedItems () {
      this.well.exportSelectedItems();
    },

    goToWebGISUrl () {
      this.$router.push(this.well.getWebGISUrl());
    },
  },
};
</script>
