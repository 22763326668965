<template>
  <div class="d-flex justify-center align-center">
    <div class="s-avatar-preview">
      <v-avatar size="160">
        <img :src="avatar.image" :alt="avatar.alt">
      </v-avatar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      avatar: 'avatar/avatar',
    }),
  },
};
</script>
