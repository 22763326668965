export const state = {
  model: localStorage.getItem('app:sidebar/model') === 'true' || true,
  clipped: localStorage.getItem('app:sidebar/clipped') === 'true' || false,
  mini: localStorage.getItem('app:sidebar/mini') === 'true' || false,
};

export const getters = {
  isOpen: state => state.model,
  model: state => state.model,
  clipped: state => state.clipped,
  mini: state => state.mini,
};

export const mutations = {
  SIDEBAR_TOGGLE (state, payload) {
    state.model = payload;
    localStorage.setItem('app:sidebar/model', payload);
  },

  SIDEBAR_CLIP (state, payload) {
    state.clipped = payload;
    localStorage.setItem('app:sidebar/clipped', payload);
  },

  SIDEBAR_MINIFY (state, payload) {
    state.mini = payload;
    localStorage.setItem('app:sidebar/mini', payload);
  },
};

export const actions = {
  toggle ({ commit }, payload) {
    commit('SIDEBAR_TOGGLE', payload);
  },

  show ({ commit }) {
    commit('SIDEBAR_TOGGLE', true);
  },

  hide ({ commit }) {
    commit('SIDEBAR_TOGGLE', false);
  },

  clip ({ commit }, payload) {
    commit('SIDEBAR_CLIP', payload);
  },

  minify ({ commit }, payload) {
    commit('SIDEBAR_MINIFY', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
