<template>
  <v-row justify="center" align="center">
    <meta-tag :title="$trans('Password Reset Link Sent')"></meta-tag>
    <v-col
      class="mx-auto"
      cols="12"
      xl="3"
      lg="4"
      md="4"
      sm="10"
    >
      <app-brand-banner
        class="mx-auto text-center ma-4"
        width="120"
      ></app-brand-banner>

      <div class="accent--text text-center">
        <icon-envelope-with-man></icon-envelope-with-man>
      </div>

      <h1 class="text-center mb-6" v-html="$trans('Check your email')"></h1>

      <!-- eslint-disable max-len -->
      <p class="text-center" v-html="$trans(`We have sent a password reset link to ${email}. Please check your email for further instructions.`)"></p>

      <div class="text-center mt-10 text--disabled">
        <p class="heading mb-0"><strong>{{ $trans('Still having problems?') }}</strong></p>
        <p class="mb-8" v-html="$trans('password.still:problem', { email: $app.supportEmail })"></p>
      </div>

      <p class="text-center">
        <router-link :to="{ name: 'login' }" class="text-caption">
          {{ $trans('Back to Login') }}
        </router-link>
      </p>

      <auth-footer class="text-center"></auth-footer>
    </v-col>
  </v-row>
</template>

<script>
import IsRequestComingFromResetFormPage from '@/modules/Auth/middlewares/IsRequestComingFromResetFormPage';

export default {
  layout: 'centered',

  beforeRouteEnter: IsRequestComingFromResetFormPage,

  computed: {
    email () {
      return this.$route.query.email || 'your email';
    },
  },
};
</script>
