import { isEmpty } from 'lodash';

export const SESSION_KEY_SESSION_TOKEN = 'token';
export const SESSION_KEY_SESSION_ID = 'session_id';

export function flushSession () {
  sessionStorage.removeItem(SESSION_KEY_SESSION_TOKEN);
  sessionStorage.removeItem(SESSION_KEY_SESSION_ID);
}

export function generateToken () {
  let d = new Date().getTime();

  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    // use high-precision timer if available
    d += performance.now();
  }

  const guuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise, no-mixed-operators
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });

  return guuid;
}

export function generateSessionID () {
  const guuid = generateToken();

  sessionStorage.setItem(SESSION_KEY_SESSION_ID, guuid);
}

export function getSessionID () {
  return sessionStorage.getItem(SESSION_KEY_SESSION_ID)
    || localStorage.getItem(SESSION_KEY_SESSION_ID);
}

export function getSessionToken () {
  return sessionStorage.getItem(SESSION_KEY_SESSION_TOKEN)
    || localStorage.getItem(SESSION_KEY_SESSION_TOKEN) || null;
}

export function getSessionOrGenerateToken () {
  if (!getSessionID()) {
    generateSessionID();
  }

  return getSessionID();
}

export function setSessionToken (token, persistent = false) {
  if (persistent) {
    localStorage.setItem(SESSION_KEY_SESSION_TOKEN, token);
  }

  sessionStorage.setItem(SESSION_KEY_SESSION_TOKEN, token);
  localStorage.setItem('token:persist', persistent);
}

export function hasSession () {
  return !isEmpty(sessionStorage.getItem(SESSION_KEY_SESSION_ID));
}

export default {
  flushSession,
  generateToken,
  generateSessionID,
  getSessionID,
  getSessionToken,
  hasSession,
  setSessionToken,
  getSessionOrGenerateToken,
};
