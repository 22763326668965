<template>
  <section
    :id="name"
    v-intersect="{
      handler: onIntersect,
      options: {
        threshold: [ 0, 1 ],
      },
    }"
  >
    <a data-hash :name="name"></a>
    <slot name="title" v-bind="{ goToSection }"></slot>
    <slot></slot>
  </section>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'DatawarehousePageSection',

  props: {
    name: {
      type: String,
      default: null,
    },
  },

  methods: {
    goToSection (hash) {
      this.$store.dispatch('docs/setCurrentHash', hash);
      this.$vuetify.goTo(hash);
    },

    onIntersect: debounce(function (e, observer, isIntersecting) {
      const [ item ] = e;
      const name = item.target.querySelector('[data-hash]').getAttribute('name');

      console.log('isIntersecting', isIntersecting, name);
      if (isIntersecting) {
        this.$store.dispatch('docs/setCurrentHash', `#${name}`);
      }
    }, 800),
  },
};
</script>
