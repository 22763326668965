<template>
  <div class="text-center">
    <v-row>
      <v-col cols="12" lg="8" class="mx-auto">
        <h1 class="display-4 font-weight-bold">404</h1>
        <p class="display-1" v-text="$trans('Page not found')"></p>
        <p v-text="$trans('Sorry we can\'t seem to find the page you\'re looking for.')"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  layout: 'error',
};
</script>
