import DataResource from '@core/resources/DataResource';
import { GET_TABLE_OCR, GET_TABLE_OCR_COPY } from '@/modules/Report/api/ocr';
import ImageMap from '@/modules/Report/resources/ImageMap';

export default class Ocr extends DataResource {
  constructor (options = {}) {
    super(options);
    this.imageMap = new ImageMap(options.imageMap);
  }

  async find (id) {
    this.startLoading();

    this.tableOCRItem = this.getTableOCRItem();

    const { data } = await this.axios.get(GET_TABLE_OCR(id));

    this.imageMap.addImageLayer(this.tableOCRItem);
    this.imageMap.addAllBoundingBoxes(data.data.attributes.table_data);

    this.stopLoading();
  }

  getTableOCRItem () {
    return this.store.getters['report/tableOCRItem'];
  }

  getSelectionBounds () {
    return this.store.getters['report/selectionBounds'];
  }

  async getHTMLTable (img) {
    const selectionBounds = this.getSelectionBounds();

    const params = {
      img,
      ul: selectionBounds.ul,
      lr: selectionBounds.lr,
    };

    const { data } = await this.axios.get(GET_TABLE_OCR_COPY, { params });

    // format to nested array
    const doc = new DOMParser().parseFromString(data.data, 'text/html');
    const allRows = doc.getElementsByTagName('tr');
    const rows = [];

    allRows.forEach(row => {
      const { cells } = row;
      const rowContainer = [];
      cells.forEach(cell => {
        rowContainer.push(cell.innerText);
      });
      rows.push(rowContainer);
    });

    return rows;
  }

  copyReportLinkToClipboard (tableOCR) {
    window.getSelection().removeAllRanges();
    // create a Range object
    const range = document.createRange();
    // set the Node to select the "range"
    range.selectNode(tableOCR);
    // add the Range to the set of window selections
    window.getSelection().addRange(range);
    // execute 'copy', can't 'cut' in this case
    document.execCommand('copy');

    this.notify('Table copied to clipboard');
    window.getSelection().removeAllRanges();
  }
}
