var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-16"},[_c('v-textarea',{attrs:{"placeholder":"Type your prompt here","outlined":""},model:{value:(_vm.chatInput),callback:function ($$v) {_vm.chatInput=$$v},expression:"chatInput"}}),_c('v-btn',{attrs:{"outlined":"","disabled":_vm.chatbot.loading},on:{"click":_vm.generateResponse}},[_vm._v(" Generate Response ")]),[(_vm.chatbot.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})],1):_vm._e(),(!_vm.chatbot.loading && _vm.generated)?_c('div',{staticClass:"py-3"},[_c('div',{staticClass:"markdown-content",staticStyle:{"overflow-x":"scroll"},domProps:{"innerHTML":_vm._s(_vm.markdownText)}}),_c('v-row',{staticClass:"mx-1 mb-1 mt-8",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8"}},[_c('div',{staticClass:"font-weight-light",staticStyle:{"font-size":"12px"}},[_vm._v("This is an AI generated response and may incur errors."),_c('br'),_vm._v(" Please check the outputs before using.")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.chatbot.meta.hasTables)?_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.exportCsv}},on),[_c('v-icon',[_vm._v("mdi-file-delimited-outline")])],1):_vm._e()]}}],null,false,3401627752)},[_c('span',[_vm._v("Export tables as CSV")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.copyText}},on),[_c('v-icon',[_vm._v("mdi-clipboard-outline")])],1)]}}],null,false,1369817970)},[_c('span',[_vm._v("Copy response")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-thumb-up-outline")])],1)]}}],null,false,2843251821)},[_c('span',[_vm._v("This response is good")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-message-draw")])],1)]}}],null,false,778789800)},[_c('span',[_vm._v("This response needs review")])])],1)],1):_vm._e()],_c('v-snackbar',{attrs:{"timeout":"3000","color":"success"},model:{value:(_vm.showSnack),callback:function ($$v) {_vm.showSnack=$$v},expression:"showSnack"}},[_vm._v("Copied to clipboard")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }