import { BASE_URL } from '@/config/api';

export const GET_WELLS_INFO_URL = `${BASE_URL}/wells/info/`;
export const FIND_WELL_INFO_URL = id => `${BASE_URL}/wells/info/${id}/`;
export const EXPORT_WELLS_URL = `${BASE_URL}/wells/info/export-to-csv/`;
export const FETCH_WELL_LIST_BY_WELL_IDS = `${BASE_URL}/wells/info/fetch-well-list/`;

export default Object.freeze({
  EXPORT_WELLS_URL,
  GET_WELLS_INFO_URL,
  FIND_WELL_INFO_URL,
  FETCH_WELL_LIST_BY_WELL_IDS,
});
