<template>
  <div>
    <v-btn
      :loading="cart.loading"
      :disabled="cart.loading"
      large
      color="secondary"
      @click="submit"
    ><span v-text="$trans('Update payment method')"></span></v-btn>
    <v-stripe-card
      ref="stripeCard"
      v-model="cart.data.token"
      :api-key="cart.getPK()"
      :label="$trans('Card Information')"
      :hide-icon="false"
      hide-postal-code
      outlined
      create="token"
      class="d-none"
    ></v-stripe-card>
  </div>
</template>

<script>
import Cart from '@/modules/Billing/resources/Cart';
import Billing from '@/modules/Billing/resources/Billing';
import VStripeCard from 'v-stripe-elements/lib/VStripeCard';

export default {
  name: 'SubscriptionUpdatePaymentMethodBtn',

  components: {
    VStripeCard,
  },

  data: () => ({
    billing: new Billing,
    cart: new Cart,
    model: false,
  }),

  methods: {
    async submit () {
      await this.billing.fetchPaymentCheckoutSession();
      await this.cart
        .setStripe(this.$refs.stripeCard.stripe)
        .updateCardDetails();
    },
  },
};
</script>
