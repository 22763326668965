<template>
  <v-simple-table v-if="publication" class="v-data-table--denser">
    <tbody>
      <tr>
        <td class="caption font-weight-bold text-uppercase" v-text="$trans('Title')"></td>
        <td v-text="publication.article_title"></td>
      </tr>
      <tr>
        <td class="caption font-weight-bold text-uppercase" v-text="$trans('Authors')"></td>
        <td v-text="publication.author"></td>
      </tr>
      <tr>
        <td class="caption font-weight-bold text-uppercase" v-text="$trans('Proceeding')"></td>
        <td v-text="publication.journal_proceeding_title"></td>
      </tr>
      <tr>
        <td
          class="caption font-weight-bold text-uppercase"
          v-text="$trans('Conference Name')"
        ></td>
        <td v-text="publication.other_metadata && publication.other_metadata['conf-name']"></td>
      </tr>
      <tr>
        <td
          class="caption font-weight-bold text-uppercase"
          v-text="$trans('Publication Date')"
        ></td>
        <td v-text="publication.publication_date"></td>
      </tr>
      <tr>
        <td
          class="caption font-weight-bold text-uppercase"
          v-text="$trans('Conference Location')"
        ></td>
        <td v-text="publication.location"></td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'PublicationInformationTable',

  props: {
    item: {
      type: [ Object, Array ],
      default: () => {},
    },
  },

  computed: {
    publication () {
      return this.item.data;
    },
  },
};
</script>
