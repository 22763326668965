import Vuetify from '@/plugins/vuetify';
import Theme from '@root/theme.json';

export const state = {
  themes: Theme,
  theme: {
    name: 'default',
    dark: {},
    light: {},
    isDark: false,
    ...JSON.parse(localStorage.getItem('theme:active') || '{}'),
  },
};

export const getters = {
  theme: state => state.theme,
  themes: state => state.themes,
  current: state => state.theme,
  dark: state => state.theme.dark,
  light: state => state.theme.light,
  isDark: state => state.theme.isDark,
};

export const mutations = {
  SET_THEME (state, theme) {
    state.theme = theme;
    Vuetify.framework.theme.name = theme.name;
    Vuetify.framework.theme.themes.light = theme.light;
    Vuetify.framework.theme.themes.dark = theme.dark;
    Vuetify.framework.theme.dark = theme.isDark;
  },

  SET_DARK (state, isDark) {
    state.theme.isDark = isDark;
    Vuetify.framework.theme.dark = isDark;
  },

  SAVE_THEME (state) {
    localStorage.setItem('theme:active', JSON.stringify(state.theme));
  },
};

export const actions = {
  set ({ commit }, theme) {
    commit('SET_THEME', theme);
    commit('SAVE_THEME');
  },

  dark ({ commit }, isDark) {
    commit('SET_DARK', isDark);
    commit('SAVE_THEME');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
