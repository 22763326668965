import { ACCOUNT_TYPE_APPRENTICE } from '@/modules/Billing/enums/AccountTypes';
import Profile from '@/modules/User/resources/Profile';
import AppTabsMenuRegistrar from '@core/registrars/AppTabsMenuRegistrar';

AppTabsMenuRegistrar.register([
  {
    to: { name: 'profile.api' },
    mode: 'DATA_ATELIER',
    location: 'profile',
    text: 'API',
    key: 'api',
    sort: 2,
    onClick ($store) {
      const user = (new Profile).getUserFromStore();
      if (user.isAccountType(ACCOUNT_TYPE_APPRENTICE)) {
        $store.dispatch('billing/openBoltAvailabilityWarningPrompt');
      }
    },
  },
]);
