<template>
  <keep-alive></keep-alive>
</template>

<script>
export default {
  created () {
    this.$router.replace({ name: 'dashboard' });
  },
};
</script>
