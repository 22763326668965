<template>
  <v-layout justify-center align-center class="text-no-wrap ov-x-auto px-3">
    <v-slide-x-transition>
      <div v-if="size">
        <v-btn
          v-if="exportable"
          :title="$trans('Export')"
          icon
          class="mr-2"
          @click="onExport"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn
          v-if="deletable"
          :title="$trans('Delete')"
          icon
          class="mr-2"
          @click="onDelete"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-slide-x-transition>

    <v-slide-x-transition>
      <v-divider v-if="canDisplay" vertical></v-divider>
    </v-slide-x-transition>

    <v-layout align-center class="text-no-wrap flex-no-wrap ml-1">
      <v-slide-x-transition>
        <div v-if="size" class="mx-1">
          <span
            role="button"
            class="caption s-link"
            @click="onClear"
            v-text="$trans('Unselect')"
          ></span>&nbsp;
          <span
            class="caption font-italic text--disabled"
            v-text="$trans(`${size} items selected`)"
          ></span>
        </div>
      </v-slide-x-transition>

      <slot></slot>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  name: 'AppDataTableToolbar',

  props: {
    exportable: {
      type: Boolean,
      default: false,
    },

    deletable: {
      type: Boolean,
      default: false,
    },

    size: {
      type: [ String, Number ],
      default: 0,
    },
  },

  computed: {
    canDisplay () {
      return !this.hasNoActions && this.size;
    },

    hasNoActions () {
      return [ this.exportable, this.deletable ].every(action => action === false);
    },
  },

  methods: {
    onExport () {
      this.$emit('click:export');
    },

    onDelete () {
      this.$emit('click:delete');
    },

    onClear () {
      this.$emit('click:clear');
    },
  },
};
</script>
