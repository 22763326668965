<template>
  <svg :width="width" :height="height" viewBox="0 0 400 500" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
   <g>
    <path id="svg_22" fill="#f2f2f2" d="m225.99213,44.97519c-108.80011,0 -197,88.19989 -197,197a196.11973,196.11973 0 0 0 23.61926,93.60321a82.50076,82.50076 0 1 0 110.75745,93.228a196.772,196.772 0 0 0 62.62329,10.16883c108.80011,0 197,-88.19989 197,-197s-88.19989,-197.00004 -197,-197.00004z"/>
    <ellipse id="svg_23" opacity="0.1" ry="16.40989" rx="97.20935" cy="329.86657" cx="222.05191"/>
    <circle id="svg_24" fill="#ff6584" r="69.89029" cy="95.58199" cx="157.26288"/>
    <path id="svg_25" :fill="iconColor" d="m348.55831,206.94494c0.04425,91.10982 -54.10426,122.944 -120.91269,122.97648q-2.32777,0.00112 -4.6331,-0.04973q-4.64445,-0.09795 -9.19221,-0.41876c-60.29893,-4.24 -107.1657,-37.65856 -107.20685,-122.39049c-0.04259,-87.687 111.9738,-198.40082 120.37467,-206.587c0.00734,0 0.00734,0 0.01477,-0.00744c0.31916,-0.312 0.4825,-0.468 0.4825,-0.468s121.02867,115.84251 121.07291,206.94494z"/>
    <path id="svg_26" fill="#3f3d56" d="m223.2359,315.98717l44.2144,-61.84046l-44.32249,68.6268l-0.11529,7.09818q-4.64445,-0.09795 -9.19221,-0.41876l4.72249,-91.14929l-0.03741,-0.7053l0.08151,-0.13373l0.44881,-8.613l-44.5006,-68.75443l44.63106,62.3022l0.11228,1.82647l3.56757,-68.86687l-38.10149,-71.05182l38.56338,58.94916l3.68022,-142.781l0.01455,-0.47516l0.00022,0.46772l-0.56888,112.59757l37.86706,-44.65636l-38.02577,54.35324l-0.97238,61.66352l35.36527,-59.19289l-35.5019,68.25867l-0.54021,34.288l51.32479,-82.39579l-51.51946,94.35735l-1.19552,76.24598z"/>
   </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [ Number, String ],
      default: 300,
    },
    height: {
      type: [ Number, String ],
      default: 300,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>

<style scoped>
svg {
  max-width: 100%;
}
</style>
