<template>
  <v-btn
    class="px-6" color="secondary"
    @click="exportItem"
  ><span v-text="$trans('Export Data')"></span></v-btn>
</template>

<script>
import PieChartDataViz from '@/modules/Webgis/resources/PieChartDataViz';

export default {
  name: 'MapGisLithologiesExportBtn',

  data: () => ({
    resource: new PieChartDataViz,
  }),

  methods: {
    exportItem () {
      const piechart = this.$store.getters['viz/piechart/piechart'];
      this.resource.exportPieChartItemOrAll(piechart.isSingle()
        ? piechart.data : piechart.meta.wells);
    },
  },
};
</script>
