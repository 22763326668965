import Config from '@core/resources/Config';

export default new Config({
  current: 0,
  items: [
    {
      key: 'overview',
      text: 'Overview',
      disabled: false,
      component: () => import('@/modules/Billing/components/Tabs/BillingOverviewTabItem'),
    },
    {
      key: 'invoices',
      text: 'Invoices',
      disabled: true,
      component: () => import('@/modules/Billing/components/Tabs/BillingInvoicesTabItem'),
    },
    {
      key: 'settings',
      text: 'Settings',
      disabled: true,
      component: () => import('@/modules/Billing/components/Tabs/BillingSettingsTabItem'),
    },
    {
      key: 'payment',
      text: 'Payment Method',
      disabled: false,
      component: () => import('@/modules/Billing/components/Tabs/BillingPaymentTabItem'),
    },
  ],
});
