<template>
  <keep-alive></keep-alive>
</template>

<script>
import MetaTag from '@core/mixins/MetaTag';

export default {
  mixins: [ MetaTag ],

  props: {
    /**
     * The text to appear on the title tag and,
     * if applicable, on the last breadcrumb item.
     *
     * @type {string}
     */
    title: {
      type: String,
      default: '',
    },

    /**
     * The text to appear on the description meta tag.
     *
     * @type {string}
     */
    description: {
      type: String,
      default: '',
    },
  },
};
</script>
