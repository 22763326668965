export const state = {
  show: true,
};

export const getters = {
  visibility: state => state.show,
};

export const mutations = {
  SET_VISIBILITY (state, visibility) {
    state.show = visibility;
  },
};

export const actions = {
  toggleVisibility ({ commit }, visibility) {
    commit('SET_VISIBILITY', visibility);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
