export default [
  {
    name: 'sets.index',
    title: 'Manage',
    description: 'View and manage data, files, and upload settings',
    icon: 'mdi-file-document-multiple-outline',
    permission: 'is_manager',
    sort: 210,
    meta: { parentOnly: true },
    children: [
      {
        name: 'sets.index',
        title: 'File Sets',
        description: 'View and manage uploaded files',
        permission: 'is_manager',
        sort: 210,
      },
    ],
  },
];
