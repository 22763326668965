import Config from './server.json';

if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
  Config.VUE_APP_API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
  Config.VUE_APP_API_VERSION = process.env.VUE_APP_API_VERSION;
}

export const BASE_URL = Config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL;
export const API_VERSION = Config.VUE_APP_API_VERSION || process.env.VUE_APP_API_VERSION;

/**
 * Backend value/keyword for retrieving
 * all of the resource items.
 *
 * Usually, the value is '-1' to indicate retrieving all items.
 *
 * @type {Number}
 */
export const API_PER_PAGE_ALL = 1000;

export default Object.freeze({
  BASE_URL,
  API_VERSION,
  API_PER_PAGE_ALL,
});
