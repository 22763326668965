import { getSessionID, getSessionToken } from '@utils/session';

export const ACCESS_TOKEN = getSessionToken();
export const SESSION_ID = getSessionID();
export const AUTH_TYPE = process.env.VUE_APP_API_AUTH_TYPE || 'JWT';

/**
 * Milliseconds before we request another token expires.
 *
 * Used for checking if the token will expire before
 * the given value below.
 *
 * E.g., if token expires in 60 mins, check 15 mins before
 *   60 - 15 = 45 mins is the frontend token expiration, enough time
 *   to request another refresh token.
 *
 * @type Integer
 */
export const MILLISECONDS_BEFORE_TOKEN_EXPIRED = 15 * 60;

export default {
  MILLISECONDS_BEFORE_TOKEN_EXPIRED,
  ACCESS_TOKEN,
  SESSION_ID,
  AUTH_TYPE,
};
