<template>
  <div class="pa-5">
    <h4
      class="d-block text-center display-1 muted--text"
      v-text="item.attributes.post_drill_conclusion"
    ></h4>
  </div>
</template>

<script>
export default {
  name: 'DetailPostDrill',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
