import DataResource from '@core/resources/DataResource';
import { SET_FILESET_STATUS_URL } from '@/modules/File/api/uploader';

export default class Audit extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async review (id) {
    try {
      this.disable();
      this.startLoading();
      this.disableAxiosResponseHandlers();

      await this.axios.patch(SET_FILESET_STATUS_URL(id), this.fields);
    } catch (e) {
      this.setErrors({ remarks: e.response.data.errors });
      return Promise.reject(this.getErrors());
    } finally {
      this.enable();
      this.stopLoading();
      this.enableAxiosResponseHandlers();
    }

    return Promise.resolve(this);
  }

  setFieldStatus (status) {
    this.fields.status = status;
  }
}
