import { size, get } from 'lodash';

const DEFAULT_ACTIVE_AGGREGATION = 'well_ids';
const DEFAULT_ACTIVE_AGGREGATION_INDEX = 0;

export const GEOMETRY_TYPE_POLYGON = 'polygon';
export const GEOMETRY_TYPE_MARKER = 'marker';

export const items = [
  {
    text: 'Wells', code: 'well', key: 'well_ids', type: GEOMETRY_TYPE_MARKER, items: [],
  },
  {
    text: 'Basins', code: 'basin', key: 'basin_ids', type: GEOMETRY_TYPE_POLYGON, items: [],
  },
  {
    text: 'Countries', code: 'country', key: 'country_ids', type: GEOMETRY_TYPE_POLYGON, items: [],
  },
];

export default {
  items,
  current: items[DEFAULT_ACTIVE_AGGREGATION_INDEX],
  currentIndex: DEFAULT_ACTIVE_AGGREGATION_INDEX,
  running: false,
  find (key, defaultKey = 'key') {
    return this.items.find(i => i[defaultKey] === key);
  },
  getByKey (key, dotKey, fallback = null) {
    const item = this.items.find(i => i.key === key);

    return get(item, dotKey, fallback);
  },
  isCurrentTypeMarker () {
    return this.current.type === GEOMETRY_TYPE_MARKER;
  },
  isCurrentTypePolygon () {
    return this.current.type === GEOMETRY_TYPE_POLYGON;
  },
  isCurrentType (type) {
    return this.current.type === type;
  },
  isCurrentIndex (index) {
    return this.currentIndex === index;
  },
  setCurrentToDefault () {
    return this.setCurrent(this.find(DEFAULT_ACTIVE_AGGREGATION));
  },
  setCurrentByKey (key, defaultKey = 'key') {
    this.setCurrent(this.find(key, defaultKey));
  },
  setCurrent (current) {
    this.current = current;
    this.currentIndex = this.items.findIndex(i => i.key === current?.key);
  },
  getCurrent () {
    return this.current;
  },
  setAggregations (aggregations) {
    this.items = this.items.map(item => this.makeAggregation(item, aggregations?.[item.key]));
  },
  makeAggregation (item, aggregation) {
    return {
      ...item,
      aggregation,
      items: aggregation?.buckets ?? [],
      count: size(aggregation?.buckets),
    };
  },
  setCurrentCount (count) {
    const item = this.find(this.current.key);
    item.count = count;
  },
  incrementCurrentCount () {
    const item = this.find(this.current.key);
    item.count++;
  },
  startRun () {
    console.info('aggregration/started');
    this.running = true;
  },
  stopRun () {
    console.info('aggregration/stopped');
    this.running = false;
  },
  isRunning () {
    console.warn(
      this.running
        ? 'aggregration is still running'
        : 'aggregration is not running',
    );
    return this.running;
  },
  isNotRunning () {
    return !this.isRunning();
  },
};
