<template>
  <v-alert
    v-model="model"
    :color="alert.color"
    :type="alert.type"
    dismissible
    class="text-left"
  >
    <slot v-bind="{ message }">
      <h1 v-if="message.title" class="title" v-html="message.title"></h1>
      <div v-html="message.content"></div>
    </slot>
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppAlert',

  computed: {
    ...mapGetters({
      alert: 'alert/alert',
      message: 'alert/message',
    }),
    model: {
      get () {
        return this.alert.shown;
      },
      set (isShown) {
        this.$store.dispatch('alert/setShown', isShown);
      },
    },
  },
};
</script>
