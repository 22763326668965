import Registrar from '@core/registrars/Registrar';
import { isNil } from 'lodash';

export default new class ActionMenuRegistrar extends Registrar {
  makeItem (item, index) {
    const dynamicParam = (item?.type ?? '').split(':')?.pop();
    return {
      ...item,
      sort: item.sort ?? index,
      key: this.generateKeyFrom(item),
      accelerator: item.accelerator,
      isSeparator: () => item.type === 'separator',
      isSubheader: () => item.type === 'subheader',
      isDynamic: () => (item?.type ?? '').includes('dynamic'),
      getDynamicParam: () => ((dynamicParam === 'dynamic' ? 'id' : dynamicParam).split(',')),
      hasIcon: () => !isNil(item.icon) && item.icon,
      hasAction: () => !isNil(item.action) && item.action,
      isComponent: () => !isNil(item.component) && item.component,
    };
  }
};
