<template>
  <v-list v-bind="$attrs">
    <template v-for="(menu, i) in items">
      <template v-if="menu.isComponent()">
        <component :is="menu.component" :key="i" :menu="menu"></component>
      </template>
      <template v-else-if="menu.isSeparator()">
        <v-divider :key="i" :class="{ 'mx-4': menu.inset }"></v-divider>
      </template>
      <template v-else-if="menu.isSubheader()">
        <app-can-view :key="i" :code="menu.permission">
          <v-subheader :key="i" :class="menu.class" v-text="menu.text"></v-subheader>
        </app-can-view>
      </template>
      <template v-else>
        <app-can-view :key="i" :code="menu.permission">
          <v-list-item
            :key="i"
            :to="getUrl(menu)"
            v-bind="menu.attrs"
            exact
          >
            <v-list-item-icon v-if="menu.hasIcon()">
              <v-icon small v-text="menu.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :title="menu.text" :class="menu.class" v-text="menu.text"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon v-if="menu.hasAction()">
              <v-icon small v-text="menu.action"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </app-can-view>
      </template>
    </template>
  </v-list>
</template>

<script>
import { fromPairs } from 'lodash';

export default {
  name: 'AppBarUserMenuList',

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },

  methods: {
    getUrl (menu) {
      if (menu.isDynamic()) {
        return {
          ...menu.to,
          params: this.getParamsFromRoute(menu.getDynamicParam()),
        };
      }

      return menu.to;
    },

    getParamsFromRoute (params) {
      return fromPairs(params.map(param => ([ param, this.$route.params[param] ])));
    },
  },
};
</script>
