<template>
  <div></div>
</template>

<script>
export default {
  async created () {
    await this.$store.dispatch('auth/logout');
    await this.$router.push({ name: 'login' });
    window.location.reload();
  },
};
</script>
