<template>
  <div>
    <v-stepper
      :value="value"
      flat alt-labels
    >
      <v-stepper-header>
        <template v-for="(phase, i) in items">
          <v-tooltip bottom :key="i">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="text-center">
                <v-stepper-step
                :key="i"
                :step="phase.step"
                :complete="phase.isComplete"
                :color="phase.color"
                :rules="phase.rules"
                error-icon="mdi-file-remove"
                class="text-center"
                >
                  <span v-text="$trans(phase.title)"></span>
                  <div class="text-center">
                    <small v-text="$trans(phase.text)"></small>
                  </div>
                  <div class="text-center mt-1">
                    <small v-text="$trans(phase.date)"></small>
                  </div>
                </v-stepper-step>
              </div>
            </template>
            <span
            v-text="$trans(tooltips(phase.step))"
            ></span>
          </v-tooltip>
          <v-divider v-if="isNotLastItem(i)" :key="`divider-${i}`"></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>
    <div class="mx-auto mb-10">
      <v-layout class="text-center align-center">
        <v-spacer></v-spacer>
        <v-icon left small>mdi-arrow-down</v-icon>
        <a
          href="#audit-status-logs"
          @click.prevent="goToAuditStatusLogs"
          v-text="$trans('Go to detailed view')"
        ></a>
        <v-spacer></v-spacer>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileAuditStatusStepper',

  props: {
    value: {
      type: [ Number, String ],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    isNotLastItem (index) {
      return this.items.length !== (index + 1);
    },

    goToAuditStatusLogs (e) {
      this.$vuetify.goTo(e.target.getAttribute('href'));
    },
    tooltips(index) {
      if(!Number.isInteger(index)){
        return null;
      }
      switch (index) {
        case 1:
          return "Initial step and Upload";
        case 2:
          return "Team manager/account owner approving files for processing";
        case 3:
          return "Determining file information and the number of page credits that will be consumed";
        case 4:
          return "Extracting text, image, and table, classifying images and so on.";
        case 5:
          return "Processing outputs that will be made available for search";
        default:
          return null;
      }
    }
  },
};
</script>
