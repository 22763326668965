import Config from '@core/resources/Config';

export const SKIPPABLE_BUCKETS = Object.freeze([ 'Table', 'Figure' ]);

export const UNSUPPORTED_BUCKETS = [ 'No Class', 'Others', 'Noise', 'EAGE - Title' ];

export default new Config({
  items: [],
  skippables: SKIPPABLE_BUCKETS,
  unsupported: UNSUPPORTED_BUCKETS,
  getFirstBucket () {
    const buckets = this.items
      .filter(i => !this.skippables.includes(i.text))
      .sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

    return buckets?.[0];
  },
});
