<template>
  <v-card elevation="5">
    <v-card-title class="title" v-text="$trans('Well Design')"></v-card-title>
    <template v-for="(image, i) in item.images">
      <div :key="i">
        <img
          :src="image.url"
          width="100%" height="auto"
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :href="image.url"
            download small
            tag="a"
            v-text="$trans('Download')"
          ></v-btn>
          <v-btn
            :to="{
              name: 'reports.single',
              params: { slug: image.doc_ref },
              query: { page_num: image.page_num },
            }"
            small v-text="$trans('Go to page {page}', { page: image.page_num })"
          ></v-btn>
        </v-card-actions>
      </div>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'ImageDesign',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
