<template>
  <app-content fluid>
    
    <v-alert v-if="intuition3D.hasErrors()" outlined text type="error" dismissible>
      <span
        class="login-error-message"
        v-text="intuition3D.getErrors('message', 'Something went wrong')"
      ></span>
    </v-alert>
    <!-- DataSource Tray -->
    <data-source-tray-filter
      width="400px"
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <v-row no-gutters>
      <v-col>
        <v-row align="center" justify="center" class="mb-3">
          <v-tabs v-model="activeTab" grow>
            <v-tab
              v-for="(imgClass, i) in intuition3D.tabs"
              :key="i"
              @click="changeImageClass(imgClass.text, imgClass.id)"
            >
              {{ imgClass.text }}
              <v-badge :content="imgClass.chip" inline></v-badge>
            </v-tab>
          </v-tabs>
        </v-row>
        <v-row align="center" justify="center" class="mb-3">
          <v-card
            :height="intuition3D.height"
            width="100%"
            flat
            class="ov-hidden"
          >
            <v-card
              id="intuition3DSpace"
              ref="intuition3DSpace"
              v-resize="onResize"
              height="100%"
              width="100%"
              tile
              class="intuition-area__container ov-hidden"
              @contextmenu.prevent="handleRightClick"
            >
              <v-overlay id="intuitionOverlay" :absolute="true" :value="!intuition3D.loaded">
                <!-- <v-progress-circular
                  :size="70"
                  :width="7"
                  color="white"
                  indeterminate
                ></v-progress-circular> -->
                <v-row
                  class="fill-height"
                  align-content="center"
                  justify="center"
                >
                  <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                  >
                    {{ intuition3D.deckStatus }}
                  </v-col>
                  <v-col cols="12">
                    <v-progress-linear
                      color="white"
                      indeterminate
                      rounded
                      height="6"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </v-overlay>
              <canvas id="intuition3DSpaceCanvas"></canvas>
            </v-card>
            <v-fade-transition>
              <v-layout
                v-if="showZoomBar && intuition3D.loaded"
                column
                class="intuition-area__zoom-bar ma-2"
              >
                <slot name="zoom-bar">
                  <v-btn
                    :disabled="zoomInBtnDisabled(false)"
                    :title="$trans('Zoom in')"
                    min-width="32"
                    width="32"
                    min-height="32"
                    height="32"
                    class="ma-1"
                    @click="zoomIn"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="zoomOutBtnDisabled(false)"
                    :title="$trans('Zoom out')"
                    min-width="32"
                    width="32"
                    min-height="32"
                    height="32"
                    class="ma-1"
                    @click="zoomOut"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </slot>
                <slot name="corner.top-left">
                  <v-btn
                    :title="$trans('Reset View')"
                    min-width="32"
                    width="32"
                    min-height="32"
                    height="32"
                    class="ma-1 mt-5"
                    @click="viewOrigin()"
                  >
                    <v-icon small>mdi-restore</v-icon>
                  </v-btn>
                </slot>
                <v-spacer></v-spacer>
              </v-layout>
            </v-fade-transition>

            <v-layout
              v-if="intuition3D.loaded"
              class="intuition-area__corner intuition-area__twod--top-center"
            >
              <!-- <slot v-if="intuition3D.current2DAxes==='XY'" name="twod.top-center"> -->
              <slot name="twod.top-center">
                <v-btn
                  :title="$trans('Click to draw polygon selection.')"
                  :depressed="!intuition3D.isDrawing"
                  :disabled="(!intuition3D.isDrawing && intuition3D.hasSelectionPolygon) || (intuition3D.current2DAxes==='XY' && intuition3D.currentZoom < 17) || (intuition3D.current2DAxes==='ZY' && intuition3D.currentZoom < 13) ||(intuition3D.current2DAxes==='ZX' && intuition3D.currentZoom < 12) || intuition3D.currentZoom > 20"
                  min-height="32"
                  height="32"
                  class="ma-1"
                  @click="togglePolygonSelection()"
                >
                  <span v-if="!intuition3D.isDrawing">Start 2D selection <v-icon small>mdi-vector-polygon</v-icon></span>
                  <span v-else-if="intuition3D.isDrawing&&this.intuition3D.selectionAreaPoints.length<4" disabled="true">Select at least 4 points <v-icon small>mdi-vector-polygon</v-icon></span>
                  <span v-else>Finish 2D selection <v-icon small>mdi-close-circle-outline</v-icon></span>
                </v-btn>
                <v-btn
                  :disabled="!intuition3D.hasSelectionPolygon"
                  :title="$trans('Click to delete polygon selection.')"
                  min-width="32"
                  width="32"
                  min-height="32"
                  height="32"
                  class="ma-1"
                  @click="intuition3D.deleteSelection()"
                >
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </slot>
            </v-layout>

            <v-layout
              v-if="intuition3D.loaded"
              class="intuition-area__corner intuition-area__twod--bottom-center"
            >
              <slot name="twod.bottom-center">
                <v-btn-toggle v-model="toggle_exclusive" dense>
                  <v-btn @click="intuition3D.changeAxes('Y', 'Z', 0, 0); intuition3D.setActive2DAxes('XY');">
                    <span
                      class="font-weight-black"
                      style="color: rgb(252, 161, 3);"
                    >X</span><span
                      class="font-weight-black"
                      style="color: rgb(3, 123, 252);"
                    >Y</span>
                  </v-btn>
                  <v-btn @click="intuition3D.changeAxes('Y', 'X', 0, -90);intuition3D.setActive2DAxes('ZY');">
                    <span
                      class="font-weight-black"
                      style="color: rgb(172, 0, 235);"
                    >Z</span><span
                      class="font-weight-black"
                      style="color: rgb(3, 123, 252);"
                    >Y</span>
                  </v-btn>
                  <v-btn @click="intuition3D.changeAxes('X', 'Y', -90, 90);intuition3D.setActive2DAxes('ZX');">
                    <span
                      class="font-weight-black"
                      style="color: rgb(172, 0, 235);"
                    >Z</span><span
                      class="font-weight-black"
                      style="color: rgb(252, 161, 3);"
                    >X</span>
                  </v-btn>
                </v-btn-toggle>
              </slot>
            </v-layout>

            <v-layout
              v-if="intuition3D.loaded"
              class="intuition-area__corner intuition-area__threed--top-left"
            >
              <slot name="threed.top-left"> </slot>
            </v-layout>

            <v-layout
              v-if="intuition3D.loaded"
              class="intuition-area__corner intuition-area__threed--bottom-center"
            >
              <slot name="threed.bottom-center">
                <v-card width="100%" class="pa-1">
                  <v-slider
                    v-model="intuition3D.fov"
                    track-color="grey"
                    :min="intuition3D.fovMin"
                    :max="intuition3D.fovMax"
                    step="0.1"
                    hide-details
                    prepend-icon="mdi-image"
                    append-icon="mdi-image-multiple"
                    width="45%"
                    @change="intuition3D.changeFov()"
                  >
                    <template #prepend>
                      <v-icon>
                        mdi-image
                      </v-icon>
                    </template>

                    <template #append>
                      <v-icon>
                        mdi-image-multiple
                      </v-icon>
                    </template>
                  </v-slider>
                </v-card>
              </slot>
            </v-layout>

            <v-layout
              v-if="intuition3D.loaded"
              class="intuition-area__corner intuition-area__threed--top-center"
            >
              <slot name="threed.top-center">
                <v-col class="d-flex" cols="12" sm="11">
                  <v-select
                    v-model="intuition3D.modelMode"
                    :items="intuition3D.modelModeList"
                    label="Select cluster mode"
                    background-color="white"
                    dense
                    outlined
                    filled
                    @change="changeModelMode"
                  ></v-select>
                </v-col>
              </slot>
            </v-layout>

            <v-layout
              class="intuition-area__corner intuition-area__threed--top-right"
            >
              <slot name="threed.top-right">
                <h2>3D</h2>
              </slot>
            </v-layout>

            <v-layout
              class="intuition-area__corner intuition-area__corner--bottom-left"
            >
              <slot name="zoom-bar.compass"> </slot>
            </v-layout>

            <v-layout
              class="intuition-area__corner intuition-area__corner--bottom-center"
            >
              <slot name="corner.bottom-center">
              </slot>
            </v-layout>

            <v-layout
              class="intuition-area__corner intuition-area__corner--bottom-right"
            >
              <slot name="corner.bottom-right"> </slot>
            </v-layout>

            <v-layout
              class="intuition-area__corner intuition-area__corner--top-right"
            >
              <slot name="corner.top-right">
                <h2>2D</h2>
              </slot>
            </v-layout>
          </v-card>
        </v-row>
      </v-col>
      <v-col>
        <!-- style="max-height: 37rem; overflow-y: scroll;" -->
        <v-card id="pickedObjectsContainer" max-height="calc(100vh - 140px)" class="ml-5 pa-3 ov-y-auto">
          <images-data-iterator
            :items="intuition3D.pickedObjects"
            :items-per-page="intuition3D.pagination.itemsPerPage"
            :items-per-row="intuition3D.meta.itemsPerRow"
            :hide-default-footer="!isNotEmpty"
            :col-sizes="{ lg: 4, md: 6 }"
          >
            <template v-slot:no-results>
              <intuition-data-iterator-empty-state></intuition-data-iterator-empty-state>
            </template>
            <template v-slot:no-data>
              <intuition-data-iterator-empty-state></intuition-data-iterator-empty-state>
            </template>
            <template v-if="intuition3D.pickedObjects.length>0" v-slot:item="{ item }">
              <div
                role="button"
                @click="flyToTarget(
                  item.position[0],
                  item.position[1],
                  item.position[2],
                  21
                )"
              >
                <intuition-data-iterator-thumbnail
                  :src="item.url"
                  :item="item"
                  height="160"
                  @click:download="downloadImage"
                  @click:flyToTarget="flyToTargetImage"
                  @click:goToPage="goToPageImage"
                ></intuition-data-iterator-thumbnail>
              </div>
            </template>
          </images-data-iterator>
        </v-card>
      </v-col>
    </v-row>

    <router-view></router-view>
  </app-content>
</template>

<script>
import Intuition3D from '@/modules/Intuition/resources/Intuition3D';
import { mapGetters } from 'vuex';

export default {
  layout: 'admin',

  meta: {
    permissions: [ 'intuition' ],
  },

  data () {
    return {
      toggle_exclusive: 0,
      intuition3D: new Intuition3D({
        id: 'intuition3DSpace',
        width: '1500',
        height: '600',
        fov: 30,
        fovMax: 150,
        fovMin: 20,
        nearPlaneThreshold: 1,
        farPlaneThreshold: 10000000000,
        cameraControls: [ 'Center', 'Equitorial', 'Polar' ],
        zoomFactor: 25,
        selectedCameraView: '',
        deckStatus: 'Initializing views',
        deckLayers: [],
        activeImageClassTab: 0,
        meta: {
          itemsPerRow: 4,
          // hideDefaultFooter: true,
          hideDefaultFooter: false,
        },
        pagination: {
          itemsPerPage: 15,
        },
      }),
      zoomBar: true,
    };
  },

  computed: {
    ...mapGetters({
      camera2D: 'intuition/camera2D',
    }),

    showZoomBar () {
      return this.zoomBar;
    },

    activeTab: {
      get () {
        return this.intuition3D.activeImageClassTab;
      },
      set (newValue) {
        return newValue.id;
      },
    },

    isNotEmpty () {
      return this.intuition3D.pickedObjects.length > 0;
    },
  },

  watch: {
    camera2D (val) {
      this.intuition3D.intuition3DDeck.setProps({
        viewState: {
          altitude: val.altitude,
          bearing: val.bearing,
          height: val.height,
          latitude: val.latitude,
          longitude: val.longitude,
          maxPitch: val.maxPitch,
          maxZoom: val.maxZoom,
          minPitch: val.minPitch,
          minZoom: val.minZoom,
          pitch: val.pitch,
          width: val.width,
          zoom: val.zoom,
        },
      });
    },

    activeTab (val) {
      return val;
    },
  },

  mounted () {
    this.onResize();
    this.intuition3D.initial();
  },

  methods: {
    onResize () {
      this.intuition3D.width = this.$refs.intuition3DSpace.$el.clientWidth;
      this.intuition3D.height = window.innerHeight * 0.8;
    },

    changedDataSource () {
      const projects = this.intuition3D.getProjectIds();
      if (projects.length > 0) {
        // this.intuition3D.startLoading();
        // this.intuition3D.deckStatus = 'Updating data sources';
        if (this.intuition3D.isReady()) {
          window.stop();
          this.intuition3D.setTabs();
          this.intuition3D.changeImageClass(this.intuition3D.activeImageClass);
          this.intuition3D.flyToTarget(0, 0, 0);
        }
      } else {
        window.stop();
        this.intuition3D.clearDeck();
      }
    },

    async changeImageClass (imgClass) {
      this.intuition3D.startLoading();
      this.intuition3D.deckStatus = 'Updating image class on display';
      window.stop();
      this.intuition3D.changeImageClass(imgClass);
      this.activeTab = this.intuition3D.tabs.filter(i => i.text === imgClass)[0].id;
      this.intuition3D.activeImageClassTab = this.activeTab;
      this.viewOrigin();

      // update url query
      this.intuition3D.setQueryString({ });
      this.intuition3D.query = { };
      await this.intuition3D.pushRouteQuery();
    },

    changeModelMode (mode) {
      this.intuition3D.startLoading();
      this.intuition3D.deckStatus = 'Updating clustering mode';
      window.stop();
      this.intuition3D.startLoading();
      this.intuition3D.deckStatus = 'Updating clustering mode';
      this.intuition3D.setModelMode(mode);
      this.intuition3D.changePositionModel();
    },

    zoomIn () {
      this.intuition3D.zoomIn();
    },

    zoomOut () {
      this.intuition3D.zoomOut();
    },

    zoomInBtnDisabled (val) {
      return val;
    },

    zoomOutBtnDisabled (val) {
      return val;
    },

    change2dAxes (axis1, axis2) {
      this.intuition2D.rebuildParticles(axis1, axis2);
    },

    viewOrigin () {
      // this.intuition3D.startLoading();
      // viewState should be at origin
      this.intuition3D.setDeckZoom(14);
      this.intuition3D.flyToTarget(0, 0, 0);
      this.intuition3D.fov = 30;
      this.intuition3D.changeFov();
      this.intuition3D.resetRotations();

      this.intuition3D.deckStatus = 'Resetting views';
    },

    flyToTarget (x, y, z, zoom) {
      this.intuition3D.startLoading();

      // const computedZoom = this.intuition3D.computeZoom(x,y,z);
      // console.log(computedZoom);

      this.intuition3D.setDeckZoom(this.intuition3D.currentZoom > zoom ? this.intuition3D.currentZoom : zoom);

      this.intuition3D.flyToTarget(x, y, z);

      // TO DO: compute zoom where the image is still visible

      this.intuition3D.fov = this.intuition3D.fov > 30 ? this.intuition3D.fov : 30;
      this.intuition3D.changeFov();
      this.intuition3D.resetRotations();

      this.intuition3D.stopLoading();

      // this.openImageDialog(imageId);
    },

    // openImageDialog (imageId) {
    //   console.log(imageId);
    //   console.log(this.$router);
    //   this.$router.push({
    //     name: 'intuition.single',
    //     params: { slug: imageId },
    //     query: {
    //       back: this.$route.fullPath,
    //     },
    //   });
    //   console.log(this.$router);
    // },

    togglePolygonSelection () {
      this.intuition3D.isDrawing = !this.intuition3D.isDrawing;
      this.intuition3D.togglePolygonSelection();
      if (this.intuition3D.isDrawing) {
        this.intuition3D.setDeckMinZoom(16);
      } else {
        this.intuition3D.setDeckMinZoom(-Infinity);
      }
    },

    downloadImage (item) {
      this.intuition3D.downloadImage(item);
    },

    flyToTargetImage (item) {
      this.flyToTarget(item.position[0], item.position[1], item.position[2], 21);
    },

    async goToPageImage (item) {
      const imageAttributes = await this.intuition3D.getImageAttributes(item);
      this.$router.push({
        name: 'reports.single',
        params: { slug: imageAttributes.doc_ref },
        query: {
          page_num: imageAttributes.page_num,
          project_id: imageAttributes.project_id,
        },
      });
    },

    updateList () {

    },
    handleRightClick(event) {
      event.preventDefault();
      // Allow other right-click functionalities
      return true;
    },
  },
};
</script>

<style lang="scss">
#intuition3DSpace,
#intuition2DSpace {
  overflow: hidden;
  position: relative;
}

#intuition3DSpaceCanvas,
#intuition2DSpaceCanvas {
  margin: 0;
  overflow: hidden;
  position: absolute;
}

#intuitionOverlay {
  z-index: 100;
}

.deck-tooltip {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.8em;
  width: 100px;
}

.intuition-area {
  &__container {
    z-index: 1;
  }

  &__twod {
    position: absolute;
    z-index: 1;

    &--bottom-center {
      bottom: 0.5rem;
      right: 2%;
      width: 35%;
    }

    &--top-center {
      right: 7%;
      top: 0.7rem;
      z-index: 10;
    }
  }

  &__threed {
    position: absolute;
    z-index: 1;

    &--top-right {
      color: rgba(56, 56, 56, 0.4);
      left: 44%;
      top: 0.25rem;
    }

    &--top-center {
      left: 7%;
      top: 0.15rem;
      width: 40%;
      z-index: 10;
    }

    &--bottom-center {
      bottom: 0.5rem;
      left: 2%;
      width: 46%;
    }

    &--top-left {
      left: 1%;
      top: 0.25rem;
    }
  }

  &__corner {
    position: absolute;
    z-index: 1;

    &--top-center {
      display: block;
      left: 0.25rem;
      margin: 0 0 0 4rem;
      right: 0.75rem;
      text-align: center;
      top: 0.75rem;
      width: 97%;
    }

    &--top-right {
      color: rgba(56, 56, 56, 0.4);
      right: 0.75rem;
      top: 0.25rem;
    }

    &--top-left {
      color: rgba(56, 56, 56, 0.4);
      left: 0.75rem;
      top: 1rem;
    }

    &--bottom-left {
      bottom: 3rem;
      left: 0.75rem;
    }

    &--bottom-center {
      bottom: 0.5rem;
      display: block;
      left: 0.75rem;
      margin: 0 4rem;
      right: 0.75rem;
      text-align: center;
    }

    &--bottom-right {
      bottom: 0.75rem;
      right: 0.75rem;
    }
  }

  &__zoom-bar {
    height: 83%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1;
  }
}

.selectBox {
  background-color: rgba(75, 160, 255, 0);
  border: 1px solid rgba(82, 85, 250, 0.7);
  position: fixed;
}

#pickedObjectsContainer {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}
</style>
