<template>
  <keep-alive v-if="$modules.hasNot(name)">
    <slot></slot>
  </keep-alive>
</template>

<script>
export default {
  name: 'HasNoModule',

  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
