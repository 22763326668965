import Manifest from '@core/manifests/Manifest';

export default new class CartHooksManifest extends Manifest {
  constructor (attributes = {}) {
    super(attributes);

    this.makeContext();
  }

  makeContext () {
    this.requireCartHooks = require.context(
      // The relative path of the routes folder
      '@/modules',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base names
      /[a-zA-Z0-9-_]\/hooks\/CartHook.js$/,
    );

    this.hooks = this.requireCartHooks
      .keys().sort()
      .map(item => this.requireCartHooks(item).default).flat();
  }

  getHooks () {
    return this.hooks;
  }
};
