import { HOME_REDIRECT } from '@/config/app';
import { SUPPORTS_AUTHENTICATION } from '@core/config/auth';

export default function redirectToHomeIfAuthUnsupported (to, from, next) {
  if (!SUPPORTS_AUTHENTICATION) {
    return next({ name: HOME_REDIRECT });
  }

  return next();
}
