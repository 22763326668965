import APP from '@core/enums/App';

export const state = {
  title: document.querySelector('head title') ? document.querySelector('head title').innerText : '',
  apptitle: APP.TITLE,
  appyear: APP.COPYRIGHT_YEAR,
  version: APP.VERSION,
  shortname: APP.SHORT_NAME,
  meta: [],
};

export const getters = {
  title: state => state.title,
  apptitle: state => state.apptitle,
  appyear: state => state.appyear,
  version: state => state.version,
  shortname: state => state.shortname,
  description: state => state.meta.find(meta => meta.id === 'meta-description'),
  meta: state => state.meta,
};

export const mutations = {
  SET_META: (state, { titleChunk: title, meta }) => {
    state.title = title || state.title;
    state.meta = meta || state.meta;
  },
};

export const actions = {
  set: ({ commit }, payload) => {
    commit('SET_META', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
