<template>
  <app-content>
    <!-- eslint-disable max-len -->
    <meta-tag :title="$trans('Order Completed Successfully')"></meta-tag>

    <v-row class="mt-10">
      <v-col
        cols="12"
        lg="5" md="8"
        class="mx-auto text-center"
      >
        <div class="text-center mx-auto mt-5 mb-15">
          <app-brand width="180" class="mx-auto"></app-brand>
        </div>

        <div class="secondary--text">
          <icon-success-balloon></icon-success-balloon>
        </div>

        <h1 class="text-center mb-2" v-text="$trans('Your order is complete')"></h1>

        <p class="mt-5" v-text="$trans('Thank you for your purchase!')"></p>

        <p v-text="$trans('You will be receiving a confirmation email with order details.')"></p>
        <p v-text="$trans('Your session will be signed out to reflect the recent changes made to your account.')"></p>

        <v-btn
          :to="{ name: 'auth.logout' }"
          x-large
          color="secondary"
        ><span v-text="$trans('Logout')"></span></v-btn>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import IsRequestComingFromPage from '@/middleware/IsRequestComingFromPage';

export default {
  layout: 'landing',

  middleware: [ (to, from, next) => IsRequestComingFromPage(to, from, next, 'order') ],

  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('order/unsetAvailability');
    this.$store.dispatch('order/unsetSession');

    next();
  },
};
</script>
