import { APP_BAR_HIDE_ON_SCROLL, APP_BAR_MINI } from '@config/settings';

export const state = {
  model: localStorage.getItem('app:appbar/model') === 'true' || true,
  mini: localStorage.getItem('app:appbar/mini') === 'true' || APP_BAR_MINI,
  hideOnScroll: APP_BAR_HIDE_ON_SCROLL,
};

export const getters = {
  appbar: state => state,
  isOpen: state => state.model,
  model: state => state.model,
};

export const mutations = {
  APPBAR_TOGGLE (state, payload = null) {
    state.model = payload === null ? !state.model : payload;
    localStorage.setItem('app:appbar/model', state.model);
  },

  APPBAR_SHOW (state, payload = true) {
    state.model = payload;
    localStorage.setItem('app:appbar/model', state.model);
  },

  APPBAR_HIDE (state, payload = false) {
    state.model = payload;
    localStorage.setItem('app:appbar/model', state.model);
  },

  APPBAR_HIDE_ON_SCROLL (state, payload = true) {
    state.hideOnScroll = payload;
  },
};

export const actions = {
  toggle ({ commit }, payload = null) {
    commit('APPBAR_TOGGLE', payload);
  },

  show ({ commit }, payload = true) {
    commit('APPBAR_SHOW', payload);
  },

  hide ({ commit }, payload = false) {
    commit('APPBAR_HIDE', payload);
  },

  hideOnScroll ({ commit }, payload = true) {
    commit('APPBAR_HIDE_ON_SCROLL', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
