<template>
  <v-alert
    color="error"
    prominent
    text
    border="left"
    class="mb-6"
  >
    <template v-slot:prepend>
      <v-icon
        large
        color="error lighten-1"
        class="v-alert__icon ma-3 mr-5"
      >mdi-file-remove</v-icon>
    </template>
    <v-row align="center">
      <v-col class="grow">
        <!-- eslint-disable-next-line max-len -->
        <p class="mb-0 body-1" v-text="$trans('The Set has been rejected for Audit.')"></p>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'AlertSetStatusRejectedForAudit',
};
</script>
