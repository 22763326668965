<template>
  <div>
    <v-layout justify-content-between>
      <v-skeleton-loader
        v-for="i in 4" :key="i"
        width="110" height="48"
        type="image" class="mr-2"
      ></v-skeleton-loader>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'CategorySearchTabsLoadingState',
};
</script>
