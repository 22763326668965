<template>
  <v-row>
    <v-col
      v-for="item in itemsCount"
      :key="item"
      :sm="sizes.sm"
      :md="sizes.md"
      :lg="sizes.lg"
    >
      <v-skeleton-loader
        :height="height"
        type="image, list-item"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ImagesDataIteratorLoadingState',

  props: {
    height: {
      type: [ Number, String ],
      default: 265,
    },

    itemsCount: {
      type: Number,
      default: 50,
    },

    sizes: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
