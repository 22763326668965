<template>
  <v-dialog
    :value="true"
    :width="width"
    persistent
    no-click-animation
    class="ocr"
  >
    <v-card>
      <div class="pa-1 text-right">
        <v-btn small icon @click="back"><v-icon small>mdi-close</v-icon></v-btn>
      </div>

      <v-card-text>
        <v-row>
          <v-col cols="12" lg="6" md="6">
            <h3 class="mb-3 mt-3" v-text="$trans('Table OCR')"></h3>
            <template>
              <map-area
                v-model="ocr.imageMap"
                zoom-bar
                width="100%" height="540px"
                class="w-100 mt-3"
              >
                <template v-slot:corner.top-left>
                  <v-layout column class="my-4">
                    <v-btn
                      :title="$trans('Select table area.')"
                      min-width="32" width="32"
                      min-height="32"
                      height="32" class="ma-1"
                      @click="filterSearchWithRectangle()"
                    >
                      <v-icon small>mdi-selection-drag</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="!hasDrawnItems"
                      :title="$trans('Clear selection.')"
                      min-width="32" width="32"
                      min-height="32"
                      height="32" class="ma-1 mt-4"
                      @click="clearDrawnItems()"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </v-layout>
                </template>
                <template v-slot:corner.bottom-left>
                  <v-layout column class="my-4">
                    <v-btn
                      :title="$trans('Select table area.')"
                      min-width="32" width="32"
                      min-height="32"
                      height="32" class="ma-1"
                      @click="filterSearchWithRectangle()"
                    >
                      <v-icon small>mdi-selection-drag</v-icon>
                    </v-btn>
                  </v-layout>
                </template>
                <template v-slot:corner.bottom-center>
                  <v-alert
                    border="left"
                    colored-border
                    color="primary"
                    elevation="2"
                    dismissible
                    class="text-left"
                  >
                    For optimal results:
                    <ul>
                      <li> Select one column at a time.</li>
                      <li> Use the blue cell centers as guides when selecting a table area.</li>
                    </ul>
                  </v-alert>
                </template>
                <template v-slot:zoom-bar.compass>
                  <div></div>
                </template>
              </map-area>
            </template>
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <v-row no-gutters justify="space-between">
              <v-col cols="12" lg="10">
                <p class="mb-0"><span v-text="$trans('You can edit the selected table before copying it.')"></span></p>
              </v-col>
              <v-col cols="12" lg="2">
                <v-layout row class="ma-0 float-right">
                  <v-btn
                    :title="$trans('Copy report link to clipboard')"
                    class="float-right"
                    small
                    @click="copyReportLinkToClipboard"
                  >
                    <v-icon small>mdi-content-copy</v-icon>
                    Copy table
                  </v-btn>
                </v-layout>
              </v-col>
            </v-row>
            <v-card
              flat
              class="ov-y-auto ov-x-hidden pa-5 mt-3"
              height="540"
            >
              <v-card-text>
                <!-- <table class="ocr__table"> -->
                <v-simple-table
                  id="table-ocr" ref="tableOCR"
                  dense
                  class="ocr__table"
                >
                  <tbody>
                    <tr v-for="(rows,i) in tableHTML" :key="i+'_rows'">
                      <td v-for="(cell,j) in rows" :key="j+'_cell'" contenteditable="true">
                        {{ cell }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <!-- </table> -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Ocr from '@/modules/Report/resources/Ocr';
import BackToPreviousPage from '@core/mixins/BackToPreviousPage';
import { mapGetters, mapActions } from 'vuex';

export default {
  layout: 'admin',

  routename: 'reports.ocr.single',

  mixins: [ BackToPreviousPage ],

  props: {
    loading: Boolean,

    width: {
      type: [ String, Number ],
      default: 1380,
    },
  },

  data: vm => ({
    backUrl: {
      name: 'reports.single',
      params: { slug: vm.$slug },
      query: { back: vm.$route.query.back },
    },
    tableHTML: [],
    ocr: new Ocr({
      imageMap: { id: 'image-map' },
      loading: true,
      options: {
        itemsPerPage: 1,
      },
      meta: {
        loading: false,
        relatedImages: [],
        singleSelect: true,
      },
    }),

  }),

  computed: {
    ...mapGetters({
      hasDrawnItems: 'report/hasDrawnItems',
      selectionBounds: 'report/selectionBounds',
    }),

    $ocr () {
      return this.$route.params.ocr;
    },
  },

  watch: {
    '$route.params': function ({ ocr }) {
      this.ocr.find(ocr);
    },

    async hasDrawnItems () {
      // fetch html table
      this.tableHTML = await this.ocr.getHTMLTable(this.$ocr);
    },

  },

  async mounted () {
    await this.ocr.find(this.$ocr);
  },

  beforeDestroy () {
    this.setHasDrawnItems(false);
    this.setSelectionBounds(null);
  },

  methods: {
    ...mapActions({
      setHasDrawnItems: 'report/setHasDrawnItems',
      setSelectionBounds: 'report/setSelectionBounds',
    }),

    filterSearchWithRectangle () {
      const rectangleDrawHandler = this.ocr.imageMap.getRectangleDrawHandler();
      rectangleDrawHandler.enable();
    },
    filterSearchWithPolygon () {
      const polygonDrawHandler = this.ocr.imageMap.getPolygonDrawHandler();
      polygonDrawHandler.enable();
    },
    clearDrawnItems () {
      this.ocr.imageMap.drawnItems.clearLayers();
      this.setHasDrawnItems(false);
      this.setSelectionBounds(null);
      this.tableHTML = [];
    },
    copyReportLinkToClipboard () {
      const tableOCR = this.$refs.tableOCR.$el;
      this.ocr.copyReportLinkToClipboard(tableOCR);
    },

  },
};
</script>
<style lang="scss">
.ocr {
  &__table {
    td {
      border: 1px solid rgb(73, 73, 73);
      border-radius: 2px;
      font-size: 11px;
      margin: 1px;
      padding: 3px;
    }
  }
}
</style>
