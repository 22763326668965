<template>
  <div class="text-center">
    <div class="accent--text">
      <icon-messy-folder width="180" height="180"></icon-messy-folder>
    </div>
    <p class="muted--text" v-text="$trans('No information available.')"></p>
  </div>
</template>

<script>
export default {
  name: 'GeneralSummaryInfoTableEmptyState',
};
</script>
