import StoreState from '@core/resources/StoreState';

const sources = localStorage.getItem('sourcetray:sources');
const projects = localStorage.getItem('sourcetray:projects');

export const state = new StoreState({
  sources: JSON.parse(sources === 'undefined' ? '[]' : sources),
  projects: JSON.parse(projects === 'undefined' ? '[]' : projects),
});

export const getters = StoreState.mapDefaultGetters({
  projects: state => state.projects,
  sources: state => state.sources,
});

export const mutations = StoreState.mapDefaultMutations({
  SAVE_SOURCES (state, payload) {
    state.sources = payload;
    localStorage.setItem('sourcetray:sources', JSON.stringify(payload));
  },

  RESET_SOURCES (state) {
    state.sources = [];
    localStorage.removeItem('sourcetray:sources');
  },

  SET_PROJECTS (state, projects) {
    state.projects = projects;
    localStorage.setItem('sourcetray:projects', JSON.stringify(projects));
  },

  UNSET_PROJECTS (state) {
    state.projects = [];
    localStorage.removeItem('sourcetray:projects');
  },
});

export const actions = StoreState.mapDefaultActions({
  save ({ commit }, payload) {
    commit('SAVE_SOURCES', payload);
  },

  reset ({ commit }) {
    commit('RESET_SOURCES');
  },

  setProjects ({ commit }, projects) {
    commit('SET_PROJECTS', projects);
  },

  resetProjects ({ commit }) {
    commit('UNSET_PROJECTS');
  },
});

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
