import Vue from 'vue';
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import {
  email,
  required,
  size,
  min,
  confirmed,
} from 'vee-validate/dist/rules';
import { formatFileSizeFromThreshold } from '@core/filters/PrettyBytes';
import { sumBy } from 'lodash';

extend('required', {
  ...required,
  message: 'The {_field_} is required',
});

extend('email', {
  ...email,
  message: 'The {_field_} is not a valid email',
});

extend('password', {
  params: [ 'target' ],
  validate (value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match',
});

extend('size', {
  ...size,
  message: (field, { size }) => `The ${field} field size must not exceed ${formatFileSizeFromThreshold(size)}`,
});

extend('totalSize', {
  validate (files, args) {
    const total = sumBy(files, 'size');
    const totalKb = total / 1024 ** 1;
    const [ maxKbSize ] = args;

    return parseFloat(totalKb) <= parseFloat(maxKbSize);
  },
  message: (field, args) => `The ${field} field size must not exceed ${formatFileSizeFromThreshold(args[0])}`,
});

extend('min', {
  ...min,
  message: (field, { length }) => `The ${field} field size must be at least ${length} characters`,
});

extend('has_uppercase', {
  validate: value => value.match(/[A-Z]/g) !== null,
  message: field => `The ${field} must have at least one uppercase letter`,
});

extend('has_lowercase', {
  validate: value => value.match(/[a-z]/g) !== null,
  message: field => `The ${field} must have at least one lowercase letter`,
});

extend('has_number', {
  validate: value => value.match(/[0-9]/g) !== null,
  message: field => `The ${field} must have at least one numeric character`,
});

extend('confirmed', {
  ...confirmed,
  message: (field, { target }) => `The confirmation must match with the ${target.replace('$', '')} field`,
});

extend('exact_match', {
  validate (value, args) {
    const [ match ] = args;
    return value === match;
  },
  message: (field, target) => `The ${field} must match with ${target[0]}`,
});

/**
 * Available Strategies
 *
 * Aggressive: Triggered when the user presses a key (on input).
 * Passive: Triggered when the form is submitted.
 * Lazy: Triggered when the user leaves the input (on blur or change).
 * Eager: Is a combination of aggressive and lazy, as it first validates
 * when the user leaves the input (on blur or change) then if the input is
 * invalid it will behave aggressively until the input is valid again and
 * it will go back to being lazy.
 *
 * @see https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-modes
 */
setInteractionMode('eager');

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
