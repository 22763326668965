import DataResource from '@core/resources/DataResource';
import { LIST_SETTINGS_INSTANCE_URL } from '@/modules/Settings/api/settings';

export default class Settings extends DataResource {
  constructor (options = {}) {
    super(options);
    this.loading = false;
  }

  async fetchInstanceSettings () {
    const { data } = await this.axios.get(LIST_SETTINGS_INSTANCE_URL);
    this.setItems(data.data);
    this.setSettingsStore(data.data);
  }

  setSettingsStore (items) {
    this.store.dispatch('settings/setInstances', items);
  }
}
