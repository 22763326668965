<template>
  <app-content>
    <meta-tag :title="$trans('Account Upgrade')"></meta-tag>

    <v-layout column align-center>
      <div>
        <div class="secondary--text">
          <icon-upgrade height="200" class="my-4"></icon-upgrade>
        </div>
        <h1 class="mb-10" v-html="$trans('Upgrade an account')"></h1>
        <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
          <v-form
            v-model="form.valid"
            :disabled="form.loading"
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
          >
            <input v-model="upgradeCode" name="upgrade_code" type="hidden">
            <validation-provider
              v-slot="{ errors }" name="email"
              rules="required|email"
              mode="aggressive"
            >
              <v-text-field
                v-model="form.fields.upgrade.email"
                :error-messages="errors"
                :label="$trans('Email')"
                outlined
                type="email"
                name="email"
              ></v-text-field>
            </validation-provider>
            <v-btn
              :loading="form.loading"
              :disabled="form.isLoadingOr(invalid)"
              x-large block
              width="132"
              color="secondary"
              type="submit"
            >
              {{ $trans('Upgrade') }}
              <template v-slot:loader>
                <v-slide-x-transition>
                  <v-icon small left dark>mdi-loading mdi-spin</v-icon>
                </v-slide-x-transition>
                <span>{{ $trans('Upgrade') }}</span>
              </template>
            </v-btn>
          </v-form>
        </validation-observer>
      </div>
    </v-layout>
  </app-content>
</template>

<script>
import Billing from '@/modules/Billing/resources/Billing';

export default {
  layout: 'admin',

  data: () => ({
    form: new Billing,
  }),

  computed: {
    upgradeCode () {
      return this.$route.query['upgrade-code'];
    },
  },

  mounted () {
    this.$store.dispatch('sidebar/hide');
  },

  methods: {
    async submit () {
      try {
        await this.form.upgradeSubscription();
      } catch (errors) {
        this.$refs.observer.setErrors(errors);
      }
    },
  },
};
</script>
