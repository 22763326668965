export default [
  {
    name: 'intuition',
    title: 'Intuition',
    description: 'View unsupervised clustering of extracted images.',
    icon: 'mdi-graph-outline',
    permission: 'intuition',
    sort: 14,
  },
];
