import Vue from 'vue';
import VueRouter from 'vue-router';
import { merge, keyBy, values } from 'lodash';
import core from './core';
import errors from './errors';
import modules from './modules';
import pages from './pages';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: values(merge(
    keyBy(core, 'name'),
    keyBy(modules, 'name'),
    keyBy(pages, 'name'),
    keyBy(errors, 'name'),
  )),
});

export default router;
