// import documents from '@/modules/Datawarehouse/config/documents';

export default [];
// export default [
//   {
//     name: 'documentation:subheader',
//     title: 'Content',
//     sort: 0,
//     meta: {
//       subheader: true,
//     },
//   },
// ].concat(documents);
