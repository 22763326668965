<template>
  <!-- eslint-disable max-len -->
  <div class="viz__scatter-plot_container">
    <v-card min-height="300px" class="mb-4" :loading="buildingChart">
      <div class="layer-control__popover--fixed">
        <app-popover
          :title="$trans('Click to learn more on how this data and visualization was put together')"
          width="800"
          icon="mdi-information-outline"
        >
          <div class="caption mb-0">
            <div v-html="$trans(currentSettings.curation_info)"></div>
          </div>
        </app-popover>
      </div>
      <v-card-title>
        <viz-scatter-plot-descriptor-select @change:descriptor="onDescriptorChange">
          <h3 v-text="currentSettings.title"></h3>
        </viz-scatter-plot-descriptor-select>
      </v-card-title>
      <v-card-subtitle v-if="meta.total && selectedPoint" style="padding-bottom: 0;">
        <p v-if="dataPointLoader" style="margin-bottom: 0;"><v-progress-circular indeterminate size="20"></v-progress-circular> Fetching point value...</p>
        <p v-else-if="selectedPoint.name && dataPointLoader == false" style="margin-bottom: 0;"><b>{{ selectedPoint.name }}</b>, <i>{{ currentSettings.y_label }}</i>: {{ selectedPoint.y }} ,<i>{{ currentSettings.x_label }}</i>: {{ selectedPoint.x }}</p>
        <p v-else-if="selectedPoint==null && dataPointLoader == false" style="margin-bottom: 0;"><i>Click on a data point below to show its value.</i></p>
        <p v-else style="margin-bottom: 0;"><i>Click on a data point below to show its value.</i></p>
      </v-card-subtitle>
      <v-card-subtitle v-else style="padding-bottom: 0;">
        <p style="margin-bottom: 0;"><i>Click on a data point below to show its value.</i></p>
      </v-card-subtitle>
      <v-card-text v-show="meta.total" style="padding-bottom: 0;">
        <canvas
          :key="`canviz-${canvasKey}`"
          ref="viz"
          width="400" height="300"
        ></canvas>
      </v-card-text>

      <v-card-subtitle v-if="scatterplot.plots.length>downsampleThreshold" style="padding-top: 0;">
        <p style="font-size: 11px; line-height: 20px; margin-bottom: 0; margin-left: 26px; margin-top: 0;">
          <i>*For better experience, displayed datasets are downsampled to {{ downsampleThreshold }} points.</i>
        </p>
        <p style="font-size: 11px; line-height: 20px; margin-bottom: 0; margin-left: 26px; margin-top: 0;">
          <i>Total number of data points is {{ scatterplot.plots.length }}.</i>
        </p>
      </v-card-subtitle>
      <template v-if="!meta.total">
        <v-sheet flat height="200">
          <v-layout align-center justify-center>
            <span class="text--disabled">{{ $trans('No data found.') }}</span>
          </v-layout>
        </v-sheet>
      </template>
      <v-card-text v-if="meta.total">
        <template v-if="scatterplot.isList()">
          <span v-text="$trans('Total')"></span>
          <h3 v-text="$trans_choice('{total} wells', meta.total, { total: meta.total })"></h3>
        </template>
        <template v-else>
          <span v-text="$trans('Well Name')"></span>
          <h3 v-if="scatterplot.data" v-text="scatterplot.data.well_name"></h3>
        </template>
      </v-card-text>
      <v-divider v-if="meta.total"></v-divider>
      <v-card-actions v-if="meta.total">
        <v-spacer></v-spacer>
        <viz-map-control-references-dialog
          :items="references"
        ></viz-map-control-references-dialog>
        <v-btn
          class="px-6" color="secondary"
          @click="exportItem"
        ><span v-text="$trans('Export Data')"></span></v-btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-title>
        <h3 v-text="$trans('Filter by depth (m)')"></h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-layout align-center>
              <v-text-field
                v-model="range[0]"
                single-line
                hide-details
                outlined
                dense
                @blur="onChangeRange(range)"
                @keydown.enter="onChangeRange(range)"
              ></v-text-field>
              <div class="pa-2">-</div>
              <v-text-field
                v-model="range[1]"
                single-line
                hide-details
                outlined
                dense
                @blur="onChangeRange(range)"
                @keydown.enter="onChangeRange(range)"
              ></v-text-field>
              <v-btn
                :title="$trans('Reset')"
                small icon
                class="ml-3"
                @click="resetSliders"
              ><v-icon small>mdi-replay</v-icon></v-btn>
            </v-layout>
          </v-col>
        </v-row>

        <v-range-slider
          v-model="range"
          hide-details
          :max="defaultRange.max"
          :min="defaultRange.min"
          class="mt-4"
          step="0.01"
          @end="onSliderEnd"
        ></v-range-slider>
      </v-card-text>
    </v-card>
  </div>
  <!-- eslint-enable max-len -->
</template>

<script>
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';
import '@/modules/Webgis/sass/viz.scss';

export default {
  name: 'VizScatterPlotMapControlCard',

  data: () => ({
    type: 'scatter',
    // type: 'line',
    chart: null,
    canvasKey: 1,
    selectedPoint: null,
    dataPointLoader: false,
    downsampleThreshold: 2000,
    buildingChart: false,
  }),

  computed: {
    ...mapGetters({
      scatterplot: 'viz/scatterplot/scatterplot',
      references: 'viz/scatterplot/references',
      currentSettings: 'viz/scatterplot/settings',
      isShown: 'viz/scatterplot/shown',
      meta: 'viz/scatterplot/meta',
    }),

    defaultRange () {
      return {
        min: this.meta.range.sliderMinDepth,
        max: this.meta.range.sliderMaxDepth,
      };
    },

    range: {
      get () {
        return [
          this.meta.range.currentMinDepth,
          this.meta.range.currentMaxDepth,
        ];
      },
      set (range) {
        this.$store.dispatch('viz/scatterplot/setRange', range);
      },
    },
  },

  watch: {
    isShown (isShown) {
      if (isShown) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
    'scatterplot.plots': {
      deep: true,
      handler () {
        this.canvasKey++;
        this.$nextTick(() => {
          this.initChart();
        });
      },
    },
  },

  mounted () {
    this.initChart();
  },

  methods: {
    initChart () {
      this.buildingChart = true;
      setTimeout(async () => {
        if (this.chart) {
          await this.chart.destroy();
        }
        // let data = this.getFormattedDatasets().map(i=> {return {'x':i.x,'y':i.y}});
        const data = this.getFormattedDatasets();
        const canvas = this.$refs.viz.getContext('2d');
        this.chart = new window.Chart(canvas, {
          type: this.type,
          data: {
            datasets: data,
            // indexAxis: 'x',
          },
          options: {
            onClick: this.onChartClick,
            events: [ 'click' ],
            animation: false,
            parsing: false,
            // animation: {
            //   duration: 0,
            // },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
            // tooltips: {
            //   displayColors: true,
            //   callbacks: {
            //     label: tooltipItem => {
            //       const well = this.scatterplot.plots
            //         .find((_, i) => i === tooltipItem.index);

            //       const xLabel = this.currentSettings.x_label;
            //       const yLabel = this.currentSettings.y_label;

            //       return `${well.name} - ${xLabel}: ${tooltipItem.xLabel}`
            //         + ` ${yLabel}: ${tooltipItem.yLabel}`;
            //     },
            //   },
            // },
            reverse: true,
            interaction: {
              mode: 'point',
              // axis: 'x',
              // intersect: false,
              includeInvisible: false,
            },
            downsample: {
              enabled: true,
              threshold: this.downsampleThreshold, // max number of points to display per dataset
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: false,
                  reverse: true,
                  grace: 500,
                  start: 0,
                  min: this.range[0] - 1000 < 0 ? 0 : Math.floor(this.range[0] / 1000) * 1000,
                  max: Math.ceil(this.range[1] / 1000) * 1000,
                },
                scaleLabel: {
                  display: true,
                  labelString: this.currentSettings.y_label,
                },
              }],
              xAxes: [{
                position: 'top',
                scaleLabel: {
                  display: true,
                  labelString: this.currentSettings.x_label,
                },
              }],
              x: {
                type: 'linear',
                maxRotation: 0,
              },
              y: {
                beginAtZero: false,
                type: 'linear',
                grace: 500,
              },
            },
          },
        });
      }, 300);

      setTimeout(async () => {
        this.buildingChart = false;
      }, this.scatterplot.plots.length * 2.5);
    },

    onChartClick (e) {
      this.dataPointLoader = true;
      this.selectedPoint = null;
      const points = this.chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);

      let well = { wellId: null };
      if (points.length) {
        const [ point ] = points;
        const chart = point._chart;
        well = chart.data.datasets?.[point._datasetIndex]?.data?.[point._index];
      }
      this.selectedPoint = well;
      this.$store.dispatch('viz/scatterplot/highlightMapWell', well);

      setTimeout(async () => {
        this.dataPointLoader = false;
      }, this.scatterplot.plots.length * 2);
    },

    getFormattedDatasets () {
      const sorted = sortBy(this.scatterplot.plots, [ function (o) { return o.x; } ]);

      return [{
        data: sorted,
        // data: (this.scatterplot.plots),
        // data: (this.scatterplot.plots).slice(1,200),
        // data: this.scatterplot.plots.map(i=> {return {'x':i.x,'y':i.y}}),
        backgroundColor: this.$vuetify.theme.themes.light.secondary,
        indexAxis: 'x',
      }];
    },

    resetSliders () {
      this.$store.dispatch('viz/scatterplot/setRange', [
        this.meta.range.origMinDepth,
        this.meta.range.origMaxDepth,
      ]);
      this.onChangeRange(this.range);
    },

    onSliderEnd (range) {
      this.$emit('update:range', range);
    },

    onChangeRange (range) {
      this.onSliderEnd(range);
    },

    exportItem () {
      this.$emit('click:export', this.scatterplot);
    },

    onDescriptorChange (data) {
      this.$emit('change:descriptor', data);
      this.selectedPoint = null;
    },
  },
};
</script>
