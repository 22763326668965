<template>
  <v-layout :class="{ 'layer-control--dense': dense }" column class="layer-control">
    <v-card
      :width="width"
      max-width="800"
      class="layer-control__container"
      v-bind="$attrs"
    >
      <div class="layer-control__popover--fixed">
        <app-popover
          :title="$trans('Click to learn more on how this data and visualization was put together')"
          icon="mdi-information-outline"
        >
          <p class="caption mb-0">
            <strong class="font-weight-bold">{{ $trans('Lithology Data') }}</strong>
            {{ $trans('no text found.') }}
          </p>
        </app-popover>
      </div>
      <v-card-title class="text-center">
        <v-spacer></v-spacer>
        <h2
          class="text-center text-uppercase"
          v-html="$trans('Lithologies')"
        ></h2>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <template v-if="loading">
          <v-sheet height="400">
            <v-layout fill-height class="muted--text text-center justify-center align-center">
              <v-icon size="72">mdi-loading mdi-spin</v-icon>
            </v-layout>
          </v-sheet>
        </template>
        <template v-else-if="hasData">
          <map-gis-lithologies-pie-chart
            :labels="lithology.labels"
            :data="lithology.data"
            :title="chartTitle"
          ></map-gis-lithologies-pie-chart>
        </template>
        <template v-else>
          <v-sheet height="200">
            <v-layout fill-height class="muted--text text-center justify-center align-center">
              <div v-text="$trans('No data available')"></div>
            </v-layout>
          </v-sheet>
        </template>
      </v-card-text>
      <v-card-text v-if="hasData">
        <template v-if="lithology.total">
          <span v-text="$trans('Total')"></span>
          <h3 v-text="`${lithology.total} wells`"></h3>
        </template>
        <template v-else-if="lithology.well">
          <span v-text="$trans('Well Name')"></span>
          <h3 v-text="lithology.well.well_name"></h3>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <map-gis-lithologies-references-dialog></map-gis-lithologies-references-dialog> -->
        <map-gis-lithologies-export-btn></map-gis-lithologies-export-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  name: 'MapGisLithologiesControlCard',

  props: {
    width: {
      type: [ String, Number ],
      default: '620px',
    },

    dense: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      isShown: 'lithology/shown',
      lithology: 'lithology/data',
      loading: 'lithology/loading',
    }),
    hasData () {
      return !isEmpty(this.lithology.data);
    },
    chartTitle () {
      return this.lithology.total ? `${this.lithology.total} Total Wells` : false;
    },
  },
};
</script>

<style scoped lang="scss">
.layer-control {
  &__popover--fixed {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
