import { BILLING_STATUS_ACTIVE, BILLING_STATUS_FOR_CANCELLATION } from '@/modules/Billing/enums/BillingStatus';
import StoreState from '@core/resources/StoreState';
import store from '@core/store';
import moment from 'moment';
import Vue from 'vue';

export const state = new StoreState({
  billingInfo: JSON.parse(localStorage.getItem('billing:info') || '{}') || {},
  newPlan: Vue.prototype.$session.get('billing.newPlan') || null,
  upgradeCode: Vue.prototype.$session.get('billing.upgradeCode') || null,
  client: Vue.prototype.$session.get('billing.client') || null,
  clientSecret: Vue.prototype.$session.get('billing.clientSecret') || null,
  checkoutSession: Vue.prototype.$session.get('billing.checkoutSession') || null,
  paymentMethods: Vue.prototype.$session.get('billing.paymentMethods') || null,
  upcomingInvoices: [],
  paymentIntents: [],
});

export const getters = {
  ...StoreState.mapDefaultGetters(),
  billingInfo: state => state.billingInfo,
  newPlan: state => state.newPlan,
  currentPlan: state => state.billingInfo,
  upgradeCode: state => state.upgradeCode,
  client: state => state.client,
  clientSecret: state => state.clientSecret,
  checkoutSession: state => state.checkoutSession,
  paymentMethods: state => state.paymentMethods,
  currentPaymentMethod: state => state.paymentMethods?.[0] ?? {},
  upcomingInvoices: state => state.upcomingInvoices?.[0] ?? {},
  upcomingInvoice: state => state.upcomingInvoices?.[0] ?? {},
  paymentIntents: state => state.paymentIntents,
  paymentIntent: state => state.paymentIntents?.[state.paymentIntents.length - 1] ?? {},
};

export const mutations = {
  ...StoreState.mapDefaultMutations(),
  SET_INFO (state, info) {
    state.billingInfo = info;
    localStorage.setItem('billing:info', JSON.stringify(state.billingInfo));
  },
  SET_NEW_PLAN (state, plan) {
    state.newPlan = plan;
    Vue.prototype.$session.start();
    Vue.prototype.$session.set('billing.newPlan', plan);
  },
  SET_UPGRADE_CODE (state, code) {
    state.upgradeCode = code;
    Vue.prototype.$session.set('billing.upgradeCode', code);
  },
  SET_CLIENT_SECRET (state, client) {
    state.client = client;
    state.clientSecret = client.clientSecret;
    Vue.prototype.$session.set('billing.client', client);
    Vue.prototype.$session.set('billing.clientSecret', client.clientSecret);
  },
  FLUSH_CLIENT_SECRET (state) {
    state.client = null;
    state.clientSecret = null;
    Vue.prototype.$session.remove('billing.client');
    Vue.prototype.$session.remove('billing.clientSecret');
  },
  UNSET_ALL_LOCAL_DATA () {
    localStorage.removeItem('billing:info');
  },
  UPDATE_STATUS (state, status) {
    state.billingInfo = {
      ...state.billingInfo,
      subscriptionStatus: status,
      isSubscriptionActive: status === BILLING_STATUS_ACTIVE,
      isSubscriptionForCancellation: status === BILLING_STATUS_FOR_CANCELLATION,
    };
    localStorage.setItem('billing:info', JSON.stringify(state.billingInfo));
  },
  SET_CHECKOUT_SESSION (state, checkoutSession) {
    state.checkoutSession = checkoutSession;
    Vue.prototype.$session.set('billing.checkoutSession', checkoutSession);
  },
  SET_PAYMENT_METHODS (state, paymentMethods) {
    state.paymentMethods = paymentMethods;
    Vue.prototype.$session.set('billing.paymentMethods', paymentMethods);
  },
  SET_UPCOMING_INVOICES (state, upcomingInvoices) {
    state.upcomingInvoices = (upcomingInvoices ?? []).map(upcomingInvoice => {
      const unitAmount = upcomingInvoice.amount.toString();
      const amount = `${unitAmount.substring(0, unitAmount.length - 2) }.${ unitAmount.substring(unitAmount.length - 2)}`;
      const amountText = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const unitPrice = `${upcomingInvoice.currency.toUpperCase()} ${amountText}`;

      return {
        ...upcomingInvoice,
        unitPrice,
        nextBillingDate: moment(parseInt(upcomingInvoice.period.start, 10) * 1000).format('MMM. D'),
        nextBillingDateFull: moment(parseInt(upcomingInvoice.period.start, 10) * 1000).format('MMM. D YYYY'),
      };
    });
  },
  SET_PAYMENT_INTENTS (state, paymentIntents) {
    state.paymentIntents = paymentIntents;
  },
};

export const actions = {
  ...StoreState.mapDefaultActions(),
  setInfo ({ commit }, info) {
    commit('SET_INFO', info);
  },
  setNewPlan ({ commit }, plan) {
    commit('SET_NEW_PLAN', plan);
  },
  setUpgradeCode ({ commit }, upgradeCode) {
    commit('SET_UPGRADE_CODE', upgradeCode);
  },
  setClientSecret ({ commit }, clientSecret) {
    commit('SET_CLIENT_SECRET', clientSecret);
  },
  flushClientSecret ({ commit }) {
    commit('FLUSH_CLIENT_SECRET');
  },
  unsetAllLocalData ({ commit }) {
    commit('UNSET_ALL_LOCAL_DATA');
    commit('FLUSH_CLIENT_SECRET');
  },
  closeBoltAvailabilityWarningPrompt () {
    store.dispatch('dialog/close');
  },
  openBoltAvailabilityWarningPrompt () {
    store.dispatch('dialog/show', {
      closeable: true,
      prompt: {
        show: true,
        component: () => import('@/modules/Billing/components/Partial/DialogBoltAvailabilityPartialCard'),
      },
      color: 'error',
      persistent: true,
      width: '960px',
      maxWidth: '960px',
      buttons: {
        cancel: { show: false },
        action: { show: false },
      },
    });
  },
  updateStatus ({ commit }, status) {
    commit('UPDATE_STATUS', status);
  },
  setCheckoutSession ({ commit }, checkoutSession) {
    commit('SET_CHECKOUT_SESSION', checkoutSession);
  },
  setPaymentMethods ({ commit }, paymentMethods) {
    commit('SET_PAYMENT_METHODS', paymentMethods);
  },
  setUpcomingInvoices ({ commit }, upcomingInvoices) {
    commit('SET_UPCOMING_INVOICES', upcomingInvoices);
  },
  setPaymentIntents ({ commit }, paymentIntents) {
    commit('SET_PAYMENT_INTENTS', paymentIntents);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
