export default [
  {
    name: 'webgis',
    title: 'WebGIS',
    description: 'View spatial layers',
    icon: 'mdi-map-outline',
    permission: 'webgis',
    sort: 13,
  },
];
