import { BASE_URL } from '@/config/api';

export const GET_PIECHART_LIST_URL = `${BASE_URL}/plugins/webgis-pie-chart/list/`;
export const GET_PIECHART_SUMMARY_URL = `${BASE_URL}/plugins/webgis-pie-chart/summary/`;
export const FIND_PIECHART_URL = id => `${BASE_URL}/plugins/webgis-pie-chart/${id}/`;
export const EXPORT_PIECHART_URL = `${BASE_URL}/plugins/webgis-pie-chart/export/`;

export default Object.freeze({
  GET_PIECHART_LIST_URL,
  GET_PIECHART_SUMMARY_URL,
  FIND_PIECHART_URL,
  EXPORT_PIECHART_URL,
});
