import StoreState from '@core/resources/StoreState';
import axios from 'axios';
import AUTH_STATUS from '@core/enums/AuthStatus';
import User from '@/modules/User/resources/User';
import LogoutHooksManifest from '@/modules/Auth/manifests/LogoutHooksManifest';
import { AUTH_LOGIN_URL } from '@/modules/Auth/api/authentication';
import {
  flushSession,
  generateSessionID,
  getSessionID,
  getSessionOrGenerateToken,
  getSessionToken,
  setSessionToken,
} from '@utils/session';
import { saveLogOutValue, unsetLogOutValue } from '@/modules/Auth/config/session';
import { uniqBy } from 'lodash';

export const KEY_TAB_COUNTER = '__tabs';
export const KEY_TAB_COUNTER_TRACKER = '__tabs.tracker';

export const state = new StoreState({
  remember: localStorage.getItem('user:remember') === 'true',
  user: JSON.parse(localStorage.getItem('user') || '{}'),
  status: AUTH_STATUS.UNAUTHENTICATED,
  sessionId: getSessionID(),
  token: getSessionToken(),
  errors: null,
  tabTracker: JSON.parse(localStorage.getItem(KEY_TAB_COUNTER_TRACKER) || '{}'),
  isLastTabSession: JSON.parse(localStorage.getItem(KEY_TAB_COUNTER_TRACKER) || '{}')?.isLastTab ?? false,
});

export const getters = StoreState.mapDefaultGetters({
  user: state => new User({ data: state.user }),
  isAuthenticated: state => !!state.token,
  isLoggedIn: state => !!state.token,
  remember: state => state.remember,
  status: state => state.status,
  token: state => state.token,
  error: state => state.error,
  isLastTabSession: state => !!state.isLastTabSession,
  tabTracker: state => state.tabTracker,
});

export const mutations = StoreState.mapDefaultMutations({
  SET_USER (state, payload) {
    state.status = AUTH_STATUS.AUTHENTICATED;
    state.user = { ...payload, password: undefined };
    state.remember = payload.remember || payload.rememberMe;
    localStorage.setItem('user', JSON.stringify(state.user));
    localStorage.setItem('user:remember', state.remember);
  },

  SET_TOKEN (state, token) {
    state.token = token;
    setSessionToken(state.token, state.remember);
  },

  GENERATE_SESSION (state) {
    generateSessionID();
    state.sessionId = getSessionID();
  },

  UNSET_USER (state) {
    state.user = null;
    state.sessionId = null;
    localStorage.removeItem('user');
  },

  UNSET_TOKEN (state) {
    state.token = null;
    localStorage.removeItem('token');
    localStorage.removeItem('token:persist');
    flushSession();
  },

  SET_AUTH_ERROR (state, payload) {
    state.error = payload;
  },

  EMIT_TO_LOCAL_STORAGE_USER_LOGGED_OUT () {
    saveLogOutValue();
  },

  UPDATE_TAB_COUNTER () {
    const sessionId = getSessionOrGenerateToken();
    let tabs = JSON.parse(localStorage.getItem(KEY_TAB_COUNTER) ?? '[]');
    tabs.push({ sessionId });
    tabs = uniqBy(tabs, 'sessionId');

    localStorage.setItem(KEY_TAB_COUNTER, JSON.stringify(tabs));
    localStorage.setItem(KEY_TAB_COUNTER_TRACKER, JSON.stringify({
      tabs: tabs.length,
      isLastTab: tabs.length <= 1,
    }));
  },

  UNSET_TAB_COUNTER () {
    const sessionId = getSessionID();
    let tabs = JSON.parse(localStorage.getItem(KEY_TAB_COUNTER) ?? '[]');
    tabs = tabs.filter(i => i.sessionId !== sessionId);
    tabs = uniqBy(tabs, 'sessionId');

    localStorage.setItem(KEY_TAB_COUNTER, JSON.stringify(tabs));
    localStorage.setItem(KEY_TAB_COUNTER_TRACKER, JSON.stringify({
      tabs: tabs.length,
      isLastTab: tabs.length <= 1,
    }));
  },

  UNSET_ALL_TAB_COUNTER () {
    localStorage.removeItem(KEY_TAB_COUNTER);
    localStorage.removeItem(KEY_TAB_COUNTER_TRACKER);
  },

  UNSET_EMITS_FROM_LOCAL_STORAGE () {
    unsetLogOutValue();
  },
});

export const actions = StoreState.mapDefaultActions({
  async login ({ commit }, credentials) {
    const { data } = await axios.post(AUTH_LOGIN_URL, credentials);
    await commit('SET_USER', { ...credentials, ...data.data });
    await commit('SET_TOKEN', data.token || data.data.token);
    await commit('GENERATE_SESSION');
    await commit('UNSET_EMITS_FROM_LOCAL_STORAGE');
  },

  async logout ({ commit }) {
    commit('UNSET_USER');
    commit('UNSET_TOKEN');
    commit('UNSET_ALL_TAB_COUNTER');
    commit('EMIT_TO_LOCAL_STORAGE_USER_LOGGED_OUT');
    LogoutHooksManifest.getHooks().forEach(hook => hook.trigger());
  },

  async logoutOrEndSession ({ state, dispatch }) {
    if (!state.remember) {
      dispatch('logout');
    }
  },

  async session ({ commit }, token) {
    await commit('SET_TOKEN', token);
  },

  setUser ({ commit, getters }, payload) {
    commit('SET_USER', { ...getters.user?.data, ...payload });
  },

  async setSessionToken ({ commit }, token) {
    await commit('SET_TOKEN', token);
  },

  unsetTabCounter ({ commit }) {
    commit('UNSET_TAB_COUNTER');
  },
});

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
