<template>
  <div class="s-avatar-preview selects" :style="`background-image: url(${preview})`">
    <div class="d-flex justify-end ml-5 mt-4">
      <div class="mt-11" style="margin-right: -58px;">
        <v-btn
          v-if="hasPreview"
          x-small
          fab
          @click="clearPreview"
        >
          <v-icon small color="muted">mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <v-btn
          :disabled="loading"
          small
          fab
          @click="openFileBrowser"
        >
          <v-icon small color="muted">mdi-upload</v-icon>
        </v-btn>
      </div>
    </div>
    <input
      ref="fileupload"
      accept="image/x-png,image/gif,image/jpeg"
      class="d-none"
      type="file"
      :name="name"
      @change="onFileChange"
    >
    <input v-model="preview" type="hidden" :name="parsedAvatar">
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'AvatarUpload',

  props: {
    avatar: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },

  data: vm => ({
    file: null,
    preview: JSON.parse(JSON.stringify(vm.value || '')),
    previewWasFromFileBrowser: false,
  }),

  computed: {
    hasPreview () {
      return !isEmpty(this.preview);
    },

    parsedAvatar () {
      return this.avatar ? this.avatar : 'avatar';
    },
  },

  watch: {
    value (val) {
      if (!this.previewWasFromFileBrowser) {
        this.preview = val;
      }
    },

    file (val) {
      this.$emit('input', val);
      this.$emit('change', val);
    },
  },

  methods: {
    openFileBrowser () {
      this.$refs.fileupload.click();
    },

    clearPreview () {
      this.preview = null;
      this.file = null;
    },

    onFileChange (e) {
      const [ file ] = e.target.files;
      this.file = file;
      if (this.file) {
        this.preview = URL.createObjectURL(this.file);
        this.previewWasFromFileBrowser = true;
      }
    },
  },
};
</script>
