import DataResource from '@core/resources/DataResource';
import files from '@/modules/Datawarehouse/config/files';
import { toLower, isEmpty } from 'lodash';

export default class Page extends DataResource {
  constructor (options = {}) {
    super(options);

    this.title = options?.title;
    this.key = options?.key;
    this.body = options?.body;
    this.next = options?.next;
    this.prev = options?.prev;
    this.setFiles(options?.files ?? files);
  }

  find (slug) {
    this.startLoading();

    this.setData(this.getFileFromSlug(slug));

    this.makeNextPrevNavigation();

    this.stopLoading();
  }

  makeNextPrevNavigation () {
    const files = this.getFiles();
    const { index } = this.data;
    const nextIndex = index + 1;
    const prevIndex = index - 1;

    this.next = nextIndex > files.length ? files[files.length - 1] : files?.[nextIndex];
    this.prev = prevIndex < 0 ? files[0] : files?.[prevIndex];
  }

  getFileFromSlug (slug) {
    return this.files
      .map((file, index) => ({ ...file, index }))
      .find(f => f.slug === this.parseSlug(slug));
  }

  parseSlug (slug) {
    return toLower(slug);
  }

  getFiles () {
    return files;
  }

  setFiles (files) {
    this.files = files;
  }

  hasNoNext () {
    return isEmpty(this.next);
  }

  hasNoPrev () {
    return isEmpty(this.prev);
  }

  goToNext () {
    this.router.push({
      name: 'documentation.single',
      params: { slug: this.next.slug },
    });
  }

  goToPrev () {
    this.router.push({
      name: 'documentation.single',
      params: { slug: this.prev.slug },
    });
  }
}
