import { DASHBOARD_MODE } from '@config/app';

export const DASHBOARD_DYNAMIC_MODES = [
  'DATA_ATELIER',
  'general',
  'dynamic',
];

export const DASHBOARD_DEFAULTS_MODES = [
  'ELASTICDOCS',
  'static',
  'default',
];

export const isDashboardModeDynamic = () => DASHBOARD_DYNAMIC_MODES.includes(DASHBOARD_MODE);

export const isDashboardModeDefault = () => DASHBOARD_DEFAULTS_MODES.includes(DASHBOARD_MODE);

export const OMITTABLE_DYNAMIC_HEADERS = [ 'reference' ];

export default {
  DASHBOARD_DEFAULTS_MODES,
  DASHBOARD_DYNAMIC_MODES,
  isDashboardModeDefault,
  isDashboardModeDynamic,
  OMITTABLE_DYNAMIC_HEADERS,
};
