import Registrar from '@core/registrars/Registrar';
import { has, isNil, orderBy } from 'lodash';

export default new class AppTabsMenuRegistrar extends Registrar {
  constructor (attributes) {
    super(attributes);

    this.location = 'default';
  }

  from (location) {
    this.location = location;

    return this;
  }

  all () {
    return orderBy(this.items.filter(item => item.location === this.location), 'sort');
  }

  makeItem (item, index) {
    const dynamicParam = (item?.type ?? '').split(':')?.pop();
    return {
      ...item,
      location: item.location,
      sort: item.sort ?? index,
      key: this.generateKeyFrom(item),
      accelerator: item.accelerator,
      isViewable: () => this.isViewable(item),
      isClick: () => has(item, 'onClick'),
      isSeparator: () => item.type === 'separator',
      isSubheader: () => item.type === 'subheader',
      isDynamic: () => (item?.type ?? '').includes('dynamic'),
      getDynamicParam: () => ((dynamicParam === 'dynamic' ? 'id' : dynamicParam).split(',')),
      hasIcon: () => !isNil(item.icon) && item.icon,
      hasAction: () => !isNil(item.action) && item.action,
      isComponent: () => !isNil(item.component) && item.component,
    };
  }
};
