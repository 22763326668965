/* eslint-disable global-require, import/no-dynamic-require */
import app from '@config/app';
import asset from '@config/asset';
import { isEmpty, startCase } from 'lodash';

export default class Asset {
  constructor () {
    this.$app = app;
    this.$asset = asset;
  }

  install (Vue) {
    Vue.prototype.$asset = this;
    this.$prototype = Vue.prototype;
  }

  getAppLogo (name = 'combomark') {
    const modifier = this.$prototype.$user?.data?.account_type?.name ?? 'default';
    const assetModifier = this.$asset.transformToSupported(`${modifier}.`);

    return this.getAppAsset(`logos/${name}`, {
      modifier: assetModifier,
    });
  }

  getAppAsset (name, { ext = 'png', appMode = true, modifier = '' } = {}) {
    const mode = appMode ? `${this.getAppMode()}.` : '';
    const assetModifier = isEmpty(modifier) ? modifier : this.$asset.transformToSupported(modifier);

    return require(`@assets/${name}.${assetModifier}${mode}${ext}`);
  }

  getModuleAsset (name, module, {
    ext = 'png',
    appMode = false,
    modifier = '',
  } = {}) {
    const mode = appMode ? `${this.getAppMode()}.` : '';
    const moduleName = startCase(module);
    const assetModifier = isEmpty(modifier) ? modifier : this.$asset.transformToSupported(modifier);

    return require(`@/modules/${moduleName}/assets/${name}.${assetModifier}${mode}${ext}`);
  }

  getAppMode () {
    return this.$app.APP_MODE;
  }
}
