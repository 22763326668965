import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import {
  OCR_BBOX_OPTIONS,
  OCR_BBOX_SELECTED_OPTIONS,
} from '@/modules/Report/config/ocr';
import Map from '@/modules/Dashboard/resources/Map';

export default class ImageMap extends Map {
  constructor (options = {}) {
    super(options);
    this.open = false;
    this.map = undefined;
    this.id = options.id || 'map';
    this.item = options.item || {};
    this.zoom = options.zoom || 4;
    this.maxZoom = options.maxZoom || 21;
    this.minZoom = options.minZoom || 0;
    this.coordinates = { longitude: options.longitude, latitude: options.latitude };
    this.tableImageURL = options.tableImageURL || '';
    this.tableImageBounds = options.tableImageBounds || [];
  }

  initialize () {
    this.startLoading();

    if (!document.querySelector(`#${this.id}`)) {
      return;
    }

    if (this.route.name !== 'dashboard') {
      this.remove();
    }

    this.map = L.map(this.id, {
      zoomControl: false,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
    });

    this.imageLayerGroup = L.layerGroup().addTo(this.map);
    this.bboxLayerGroup = L.layerGroup().addTo(this.map);

    this.drawnItems = L.featureGroup();
    this.map.addLayer(this.drawnItems);
    this.drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        circlemarker: false,
        circle: false,
        marker: false,
        polygon: false,
        rectangle: {
          shapeOptions: {
            clickable: true,
            weight: 2,
            dashArray: '1 4 3 1',
            color: 'yellow',
            fill: true,
            fillColor: 'yellow',
            fillOpacity: 0.3,
          },
        },
      },
      edit: {
        featureGroup: this.drawnItems,
        edit: false,
      },
    });
    this.createPolygonDrawHandler();
    this.createRectangleDrawHandler();

    this.map.on('draw:created', e => {
      const drawnLayer = e.layer;
      if (this.drawnItems !== undefined) {
        this.drawnItems.clearLayers();
        this.drawnItems.addLayer(drawnLayer);
        // Fetch html table based on bounds drawn
        this.setHasDrawnItems(true);
        const bounds = this.projectBounds(drawnLayer.getBounds());
        this.setSelectionBounds(bounds);
      }
      this.rectangleDrawHandler.disable();
    });

    this.stopLoading();
  }

  projectBounds (bounds) {
    const ll = this.map.project(bounds._southWest, this.map.getMaxZoom() - 1);
    const ur = this.map.project(bounds._northEast, this.map.getMaxZoom() - 1);

    return { ul: `${Math.floor(ll.x)},${Math.floor(ur.y)}`, lr: `${Math.floor(ur.x)},${Math.floor(ll.y)}` };
  }

  setHasDrawnItems (value) {
    this.store.dispatch('report/setHasDrawnItems', value);
  }

  setSelectionBounds (layer) {
    this.store.dispatch('report/setSelectionBounds', layer);
  }

  addImageLayer (item) {
    this.tableImageURL = item.attributes.file_url;

    const img = new Image();
    img.src = this.tableImageURL;

    img.onload = () => {
      const southWest = this.map.unproject([ 0, img.height ], this.map.getMaxZoom() - 1);

      const northEast = this.map.unproject([ img.width, 0 ], this.map.getMaxZoom() - 1);

      this.tableImageBounds = new L.LatLngBounds(southWest, northEast);
      // this.tableImageBounds = [ [ item.naturalWidth, 0 ], [ 0, item.naturalHeight ] ];

      this.imageLayer = L.imageOverlay(this.tableImageURL, this.tableImageBounds);

      this.imageLayerGroup.addLayer(this.imageLayer);

      this.map.fitBounds(this.tableImageBounds);
    };
  }

  addAllBoundingBoxes (bboxes) {
    this.bboxLayerGroup.clearLayers();

    bboxes.forEach(bbox => {
      this.addBoundingBox(bbox);
    });

    function selectBbox (e) {
      const layer = e.target;

      layer.setStyle(OCR_BBOX_SELECTED_OPTIONS);

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
    }

    function unselectBbox (e) {
      const layer = e.target;
      if (e.type === 'mouseout' && layer.options.selected === false) {
        layer.setStyle(OCR_BBOX_OPTIONS);
      }
    }

    function toggleSelectedStyle (e) {
      if (e.target.options.selected) {
        unselectBbox(e);
        e.target.options.selected = false;
      } else {
        selectBbox(e);
        e.target.options.selected = true;
      }
    }

    this.bboxLayerGroup.eachLayer(layer => {
      layer.on({
        mouseover: selectBbox,
        mouseout: unselectBbox,
        click: toggleSelectedStyle,
      });
    });
  }

  addBoundingBox (bbox) {
    const southWest = this.map.unproject([ bbox.x1, bbox.y1 ], this.map.getMaxZoom() - 1);

    const northEast = this.map.unproject([ bbox.x2, bbox.y2 ], this.map.getMaxZoom() - 1);
    // define rectangle geographical bounds
    // const bounds = [ [ bbox.x1, bbox.y1 ], [ bbox.x2, bbox.y2 ] ];
    const bounds = new L.LatLngBounds(southWest, northEast);

    const center = this.map.unproject(bbox.cell_center, this.map.getMaxZoom() - 1);

    const centerPoint = L.circleMarker(center, { radius: 3, fillOpacity: 1, stroke: false });
    this.bboxLayerGroup.addLayer(centerPoint);

    // create an orange rectangle
    const bboxRectangle = L.rectangle(bounds, { ...OCR_BBOX_OPTIONS, selected: false });
    this.bboxLayerGroup.addLayer(bboxRectangle);
  }
}
