<template>
  <v-app v-cloak class="s-app">
    <app-sidebar>
      <template v-slot:sidebar:append>
        <portal-target name="sidebar:append" multiple></portal-target>
        <app-send-feedback-btn></app-send-feedback-btn>
      </template>
    </app-sidebar>

    <portal-target name="app:loading" slim></portal-target>

    <app-bar></app-bar>

    <app-snackbar></app-snackbar>

    <app-dialogbox></app-dialogbox>
    <portal-target name="app:dialogs" multiple></portal-target>

    <v-slide-y-transition mode="in-out">
      <v-main class="workspace">
        <app-workspace>
          <v-slide-y-transition mode="out-in">
            <router-view></router-view>
          </v-slide-y-transition>
        </app-workspace>
        <portal-target name="main:bottom" slim></portal-target>
        <portal-target name="system:tray" slim>
          <app-system-tray></app-system-tray>
        </portal-target>
      </v-main>
    </v-slide-y-transition>

    <portal-target name="sidebar:right" slim></portal-target>

    <app-footer></app-footer>

    <shortkey></shortkey>
    <app-cache-buster-dialog></app-cache-buster-dialog>
  </v-app>
</template>

<script>
import CheckRefreshTokenExpiration from '@/mixins/CheckRefreshTokenExpiration';
import BeforeAdminRouteEnter from '@core/mixins/BeforeAdminRouteEnter';
import RefreshTokenGuard from '@/middleware/RefreshTokenGuard';
import CheckRoutePermission from '@/middleware/CheckRoutePermission';
import SetToken from '@core/mixins/SetToken';

export default {
  name: 'Admin',

  middleware: [ RefreshTokenGuard, CheckRoutePermission ],

  mixins: [ BeforeAdminRouteEnter, CheckRefreshTokenExpiration, SetToken ],
};
</script>
