import DataResource from '@core/resources/DataResource';
import { LIST_WELL_BY_WELL_IDS } from '@/modules/Search/api/geometry';
import { heat, feature, polygon } from '@/modules/Search/config/geometries';
import { toGeoJson, getCoordinateFromCardinalCoordinate } from '@/modules/Dashboard/utils/map';

export default class Geometry extends DataResource {
  constructor (options = {}) {
    super(options);

    this.setMeta({
      heat,
      feature,
      polygon,
    });
  }

  async listByIds (geometryIds) {
    this.startLoading();

    const params = this.buildGeometryIdsParameter(geometryIds);
    const { data } = await this.axios.post(LIST_WELL_BY_WELL_IDS, params);

    this.mergeItems(data.data, geometryIds);
    this.generateGeometryHeats();
    this.generateMapResults();

    this.stopLoading();
  }

  listPolygonByIds (geometryIds) {
    this.startLoading();

    this.setItems(geometryIds);
    this.generateGeometryPolygons();

    this.stopLoading();
  }

  mergeItems (items1, items2) {
    this.setItems(Array.from(items1 ?? []).map(item1 => {
      const item2 = items2.find(item2 => item2.key === item1.id);
      return { ...item1, ...item2 };
    }));
  }

  setItems (items) {
    this.items = items.map(item => ({
      ...item,
      count: item.doc_count,
    }));
  }

  generateGeometryPolygons () {
    this.meta.polygon.setItems(this.items);
  }

  generateGeometryHeats () {
    this.meta.heat.setItems(this.items.map(well => this.makeGeometryHeat(well)));
  }

  generateMapResults () {
    this.meta.feature.setItems(toGeoJson(this.items));
  }

  makeGeometryHeat (item) {
    return {
      lat: getCoordinateFromCardinalCoordinate(item.attributes.latitude),
      lng: getCoordinateFromCardinalCoordinate(item.attributes.longitude),
      count: item.doc_count,
    };
  }

  buildGeometryIdsParameter (geometryIds) {
    return {
      well_ids: (geometryIds ?? []).map(well => well.key).join(','),
    };
  }
}
