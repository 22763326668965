<template>
  <app-mode name="DATA_ATELIER">
    <div>
      <v-dialog
        v-model="isShown"
        persistent
        scrollable
        max-width="920px"
      >
        <v-card class="datalake-onboarding-tour ov-hidden">
          <v-layout class="dialog__systembar pa-2">
            <v-spacer></v-spacer>
            <v-btn
              small text
              icon
              @click="closeDialog"
            ><v-icon>mdi-close</v-icon></v-btn>
          </v-layout>
          <v-card-text class="pa-8 pt-4 pb-0">
            <v-layout class="my-10">
              <div>
                <h2
                  class="font-weight-normal mb-3"
                  v-text="$trans('Welcome to {name}! {icon}', {
                    name: $app.appTitle,
                    icon: '🎉',
                  })"
                ></h2>
                <p
                  class="lead mb-6"
                  v-text="$trans('Start your information discovery journey now.')"
                ></p>
                <v-btn
                  x-large
                  color="secondary"
                  class="px-10"
                  @click="startTour"
                ><span v-text="$trans('Take a tour')"></span></v-btn>
              </div>
              <v-spacer></v-spacer>
              <div>
                <div class="small muted--text" v-text="$trans('You are subscribed to')"></div>
                <img
                  :src="$asset.getAppLogo()" :alt="$app.appTitle"
                  width="240"
                  class="mx-auto"
                >
              </div>
            </v-layout>
            <v-alert v-if="hasMaintenanceMessage" type="info" outlined>
              <div v-html="scheduledMaintenanceMessage"></div>
            </v-alert>
          </v-card-text>
          <div class="pa-8 pt-0">
            <v-divider class="mx-auto my-5"></v-divider>
            <!-- eslint-disable-next-line max-len -->
            <p v-text="$trans('Use the following resources and learn how to transform energy data for sustainability')"></p>

            <v-item-group>
              <v-layout class="ov-x-auto">
                <template v-for="(resource, i) in resources">
                  <v-hover :key="i" v-slot:default="{ hover }">
                    <v-item>
                      <v-card
                        :href="resource.url"
                        :class="{
                          'elevation-10': hover,
                        }"
                        outlined
                        class="mr-4"
                        color="grey lighten-3"
                        width="150"
                        target="_blank"
                      >
                        <v-img
                          :aspect-ratio="960/720"
                          :lazy-src="resource.thumbnail"
                          :src="resource.thumbnail"
                        ></v-img>
                      </v-card>
                    </v-item>
                  </v-hover>
                </template>
              </v-layout>
            </v-item-group>
          </div>
        </v-card>
      </v-dialog>
      <tour-multi-system
        name="welcome/onboarding"
        :steps="steps"
        @click:stop="onClickStop"
        @click:skip="onClickStop"
      ></tour-multi-system>
    </div>
  </app-mode>
</template>

<script>
import { generateRandomHash } from '@utils/text';
import { has, isEmpty } from 'lodash';

export default {
  name: 'DatalakeOnboardingTourSystemTray',

  data: vm => ({
    resources: [
      {
        url: 'https://www.irayaenergies.com/data-atelier',
        thumbnail: vm.$asset.getModuleAsset('onboarding/slide1', 'datalake'),
      },
      {
        url: 'https://youtu.be/zSTQogddKBY',
        thumbnail: vm.$asset.getModuleAsset('onboarding/slide2', 'datalake'),
      },
      {
        url: 'https://irayae.webinargeek.com/watch/replay/1430783/fbaaa800eb2a3cbd9508da3c27c06db3',
        thumbnail: vm.$asset.getModuleAsset('onboarding/slide3', 'datalake'),
      },
      {
        url: 'https://irayae.webinargeek.com/watch/replay/1300369/239133aad6542a291422f08d29f4dadc',
        thumbnail: vm.$asset.getModuleAsset('onboarding/slide4', 'datalake'),
      },
      {
        url: 'https://www.irayaenergies.com/the-convertable-from-iraya',
        thumbnail: vm.$asset.getModuleAsset('onboarding/slide5', 'datalake'),
      },
    ],

    steps: [
      {
        target: '[data-tour-step="welcome/onboarding.1"]',
        content: [
          '<div class="mb-3 font-weight-bold">Start your data ingestion here!</div>',
          '<div class="mb-2">Click on <strong>Upload Files</strong> to open the <strong>Upload Dialog form</strong>.</div>',
        ].join(''),
        params: {
          placement: 'right',
        },
        before () {
          setTimeout(() => {
            vm.$vuetify.goTo(0);
          }, 800);
          return new Promise(async resolve => {
            await vm.$router.push({
              name: 'datalake',
              query: { tab: 'uploaded', o: generateRandomHash(Date.now()) },
            }).catch(() => {});
            setTimeout(resolve, 800);
          });
        },
      },
      {
        target: '[data-tour-step="welcome/onboarding.2"]',
        content: [
          '<div class="mb-3 font-weight-bold">File selection</div>',
          '<div class="mb-2">Select a project. Select your files. Add comments to describe your file set.</div>',
          '<div class="mb-2">Click SUBMIT.</div>',
          '<br>',
          '<div class="mb-2">Maximum size of file sets that can be uploaded is 200MB.</div>',
        ].join(''),
        params: {
          placement: 'right',
        },
        before () {
          return new Promise(async resolve => {
            await vm.$router.push({
              name: 'datalake.upload',
              query: { tab: 'uploaded', o: generateRandomHash(Date.now()) },
            }).catch(() => {});
            setTimeout(resolve, 350);
          });
        },
      },
      {
        target: '[data-tour-step="welcome/onboarding.3"]',
        content: [
          '<div class="mb-3 font-weight-bold">Uploading in progress</div>',
          '<div class="mb-2">The files you have selected will belong to a <strong>set</strong>.</div>',
          '<div class="mb-2">Depending on your subscription, the monthly processing page credits will vary.</div>',
          `<div class="mb-2">
            See all ${vm.$app.appTitle} <a href="https://www.irayaenergies.com/data-atelier/" target="_blank">packages.</a>
          </div>`,
        ].join(''),
        params: {
          placement: 'left',
        },
        before () {
          return new Promise((resolve => {
            vm.$router.push({ name: 'datalake', query: { o: generateRandomHash(Date.now()) } }).catch(() => {});
            vm.$store.dispatch('file/showUploadTray');
            setTimeout(resolve, 10);
          }));
        },
      },
      {
        target: '[data-tour-step="welcome/onboarding.4"]',
        content: [
          '<div class="mb-3 font-weight-bold">File processing sets</div>',
          '<div class="mb-2">Uploaded sets will be listed in this table.</div>',
          '<div class="mb-2">You can click on a <strong>set</strong> to see its progress.</div>',
        ].join(''),
        params: {
          placement: 'top',
        },
        before () {
          return new Promise((resolve => {
            const el = document.querySelector('[data-tour-step="welcome/onboarding.4"]');
            el.style.zIndex = 1;

            vm.$router.push({
              name: 'datalake',
              query: { tab: 'uploaded', o: generateRandomHash(Date.now()) },
            }).catch(() => {});
            vm.$store.dispatch('file/hideUploadTray');
            setTimeout(resolve, 10);
          }));
        },
      },
      {
        target: '[data-tour-step="welcome/onboarding.5"]',
        content: [
          '<div class="mb-3 font-weight-bold">Deployed files</div>',
          '<div class="mb-2">Files will be added to this table once it has gone through processing and deployment.</div>',
        ].join(''),
        params: {
          placement: 'top',
        },
        before () {
          return new Promise((resolve => {
            vm.$router.push({
              name: 'datalake',
              query: { tab: 'documents', o: generateRandomHash(Date.now()) },
            }).catch(() => {});
            setTimeout(resolve, 200);
          }));
        },
      },
      {
        target: '[data-tour-step="welcome/onboarding.6"]',
        content: [
          '<div class="mb-3 font-weight-bold">Select data sources to work on</div>',
          '<div class="mb-2">Search features will be based on the active data sources.</div>',
        ].join(''),
        params: {
          placement: 'top',
        },
        before () {
          return new Promise((resolve => {
            const appbar = document.querySelector('.s-appbar');
            appbar.style.zIndex = 6;
            setTimeout(resolve, 100);
          }));
        },
      },

      // Search section
      {
        target: '[data-tour-step="welcome/onboarding.7"]',
        content: [
          '<div class="mb-3 font-weight-bold">Search deployed files</div>',
          '<div class="mb-2">All extracted texts of the deployed files are readily searchable.</div>',
        ].join(''),
        params: {
          placement: 'right',
        },
        before () {
          return new Promise((resolve => {
            const el = document.querySelector('.v-navigation-drawer__content');
            const appbar = document.querySelector('.s-appbar');
            el.style.overflowX = 'inherit';
            el.style.overflowY = 'inherit';
            appbar.style.zIndex = 'unset';
            vm.$router.push({ name: 'search' }).catch(() => {});
            setTimeout(resolve, 100);
          }));
        },
      },

      // Image section
      {
        target: '[data-tour-step="welcome/onboarding.8"]',
        content: [
          '<div class="mb-3 font-weight-bold">Search deployed extracted images</div>',
          '<div class="mb-2">All extracted texts within images of the deployed files are readily searchable.</div>',
        ].join(''),
        params: {
          placement: 'right',
        },
        before () {
          return new Promise((resolve => {
            vm.$router.push({ name: 'images' }).catch(() => {});
            setTimeout(resolve, 100);
          }));
        },
      },

      // About section
      {
        target: '[data-tour-step="welcome/onboarding.9"]',
        content: [
          '<div class="mb-3 font-weight-bold">Be updated</div>',
          '<div class="mb-2">Information on new features, updates, and fixes should be available here.</div>',
          `<div class="mb-2">Other resources to help you use ${vm.$app.appTitle} should be curated here as well.</div>`,
        ].join(''),
        params: {
          placement: 'right',
        },
        before () {
          return new Promise((resolve => {
            vm.$router.push({ name: 'about', query: { tab: 'new' } }).catch(() => {});
            setTimeout(resolve, 100);
          }));
        },
      },
    ],
  }),

  computed: {
    isShown: {
      get () {
        return this.$store.getters['tour/onboarding/shown'];
      },
      set (isShown) {
        this.$store.dispatch('tour/onboarding/setShown', isShown);
      },
    },
    hasReadMaintenanceMessage () {
      const user = this.$store.getters['auth/user'];
      const hasReadMessage = (this.$store.getters['tour/onboarding/readScheduledMaintenanceMessage'] ?? [])
        .filter(hasRead => hasRead.read)
        .find(hasRead => hasRead.userId === user.getPrimaryKey());

      return hasReadMessage?.read || false;
    },
    hasMaintenanceMessage () {
      const currentInstance = this.$store.getters['settings/currentInstance'];
      return has(currentInstance, 'attributes.settings.SCHEDULED_MAINTENANCE_MESSAGE')
        && !isEmpty(currentInstance.attributes.settings.SCHEDULED_MAINTENANCE_MESSAGE);
    },
    scheduledMaintenanceMessage () {
      const currentInstance = this.$store.getters['settings/currentInstance'];
      return this.hasMaintenanceMessage ? currentInstance
        .attributes.settings.SCHEDULED_MAINTENANCE_MESSAGE
        .map(i => i.TEXT)
        .join() : '';
    },
  },

  mounted () {
    this.checkIfUserNeedsOnboarding();
    this.checkIfHasMaintenanceMessage();
    this.addTourListeners();
  },

  methods: {
    checkIfUserNeedsOnboarding () {
      if (this.$app.isAppMode('DATA_ATELIER')) {
        const user = this.$store.getters['auth/user'];
        const onboardedUser = (this.$store.getters['tour/onboarding/onboarded'] ?? [])
          .find(onboarded => onboarded.userId === user.getPrimaryKey());

        if (onboardedUser?.done) {
          this.$store.dispatch('tour/onboarding/hide');
        } else {
          setTimeout(() => {
            this.$store.dispatch('tour/onboarding/show');
          }, 1900);
        }
      }
    },

    checkIfHasMaintenanceMessage () {
      if (this.$app.isAppMode('DATA_ATELIER') && this.hasMaintenanceMessage) {
        if (!this.hasReadMaintenanceMessage) {
          setTimeout(() => {
            this.$store.dispatch('tour/onboarding/show');
          }, 1900);
        }
      }
    },

    addTourListeners () {
      this.$root.$on('file/upload.button:click', () => {
        this.$tours['welcome/onboarding'].nextStep();
      });

      this.$root.$on('file/upload.form:submit', () => {
        this.$tours['welcome/onboarding'].nextStep();
      });

      this.$root.$on('file/upload.systemtray:close', () => {
        this.$tours['welcome/onboarding'].nextStep();
      });

      this.$root.$on('click:file/upload.systemtray/goToSinglePage', () => {
        this.$tours['welcome/onboarding'].stop();
      });
    },

    closeDialog () {
      this.$store.dispatch('tour/onboarding/hide');
      this.$store.dispatch('tour/onboarding/markAsDone');
      this.$store.dispatch('tour/onboarding/markScheduledMaintenanceMessageAsRead');
    },

    startTour () {
      if (this.$app.isAppMode('DATA_ATELIER')) {
        this.closeDialog();
        this.$router.push({
          name: 'datalake.upload',
          query: {
            tab: 'uploaded',
          },
        });
        this.$tours['welcome/onboarding'].start();
      }
    },

    onClickStop () {
      this.$store.dispatch('tour/onboarding/markAsDone');
    },
  },
};
</script>

<style lang="scss">
.v-tour--active {
  .v-menu__content,
  .v-menu__content.menuable__content__active {
    pointer-events: auto;
  }
}
</style>
