<template>
  <div class="image-data-iterator__thumbnail-container">
    <div
      class="image-data-iterator__thumbnail"
      :class="{ 'image-data-iterator__thumbnail--active': active }"
    >
      <v-card :color="color" rounded>
        <v-img
          :height="height"
          :src="src"
          :lazy-src="src"
          cover
          width="auto"
        >
          <v-hover v-slot="{ hover }">
            <slot v-bind="{ hover }"></slot>
          </v-hover>
        </v-img>
      </v-card>
    </div>
    <slot name="append" v-bind="{ src }"></slot>
  </div>
</template>

<script>
export default {
  name: 'ImagesDataIteratorThumbnail',

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    src: {
      type: String,
      default: null,
    },

    color: {
      type: String,
      default: 'grey lighten-2',
    },

    height: {
      type: [ Number, String ],
      default: 200,
    },
  },
};
</script>

<style lang="scss">
.image-data-iterator__thumbnail {
  border: 3px solid transparent;
  transition: all 0.05s ease-in;

  &--active {
    border: 3px solid var(--v-primary-base);
    border-radius: 4px;
  }
}
</style>
