export const state = {
  data: {
    status: null,
    message: '',
    messages: [],
  },
};

export const getters = {
  status: state => state.data.status,
  message: state => state.data.message,
  messages: state => state.data.messages,
};

export const mutations = {
  SET_ERROR_MESSAGE (state, message) {
    state.data.message = message;
  },
};

export const actions = {
  set ({ commit }, message) {
    commit('SET_ERROR_MESSAGE', message);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
