import DataResource from '@core/resources/DataResource';
import {
  LIST_PETRO_PLOTS_META_URL, FIND_PETRO_META_URL,
  FIND_PETRO_DATA_BY_WELL_NAME_URL,
} from '@/modules/PetroPlots/api/petroplots';
import { FIND_WELL_INFO_URL } from '@/modules/Dashboard/api/wells';
import { sortBy, isNil } from 'lodash';

export default class PetroPlots extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async list () {
    this.flushErrors();
    this.startLoading();

    const params = {
      projects_list: this.getProjectIds(),
      ...this.getQueryString(),
      page_size: 10,
    };
    try {
      const { data } = await this.axios.get(LIST_PETRO_PLOTS_META_URL, { params });

      this.setItems(data.data);
      this.select(this.items?.[0]);
      this.findMetaDetails(this.items?.[0]?.id);
      this.setPagination({
        total: data.meta.pagination.count,
        page: data.meta.pagination.page,
        pageCount: data.meta.pagination.pages,
      });
      if (this.isInteractiveTab()) {
        this.fetchInteractiveData();
      }
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    }finally {
      this.stopLoading();
    }
  }

  setItems (items) {
    this.items = sortBy(items.map(item => ({
      ...item,
      ...item.attributes,
      text: item.attributes.well_name,
      subtext: item.attributes.basin,
    })), o => o.text);
  }

  async findMetaDetails (id) {
    if (isNil(id)) {
      return;
    }
    this.flushErrors();
    this.startLoading();
    try {
      const { data } = await this.axios.get(FIND_PETRO_META_URL(id));

      this.setData(data.data);
      this.setDataThumbnail(data.data);
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    } finally {
      this.stopLoading();
    }
  }

  async fetchInteractiveData () {
    this.flushErrors();
    this.startLoading();
    try {
      const { data } = await this.axios.get(FIND_PETRO_DATA_BY_WELL_NAME_URL(this.data.well_name));

      this.setBokehData(data.data);
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    } finally {
      this.stopLoading();
    }
  }

  async findWell (id) {
    this.flushErrors();
    this.startLoading();
    try {
      const { data } = await this.axios.get(FIND_WELL_INFO_URL(id));

      this.setMetaWell(data.data);
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    } finally {
      this.stopLoading();
    }
  }

  setMetaWell (well) {
    this.meta.well = { ...well, ...well.attributes };
  }

  setData (data) {
    this.data = this.observeData({ ...data, ...data.attributes });
  }

  setDataThumbnail (data) {
    const storage = this.store.getters['sourcetray/projects']?.filter(i => i.id === data.attributes.petro_meta.project_id)[0];
    const storageURL = storage.attributes.project_settings.PROJECT_STORAGE_URL;
    const storageKey = storage.attributes.project_settings.PROJECT_STORAGE_KEY;
    this.data = { ...this.data, thumbnail: `${storageURL}/${data.attributes.petro_meta.FULL}${storageKey}` };
  }

  setBokehData (data) {
    const bokehId = /id="(.*?)"/g.exec(data.bokeh_script)[1];

    const bokeh = {
      ...data,
      id: bokehId,
      script: data.bokeh_script,
    };

    this.data = { ...this.data, bokeh };
  }

  getPostMortemImageJumpToPage (item) {
    const b = item.split('/');
    const c = b[b.length - 1];
    const d = c.split('_')[0];
    const e = d.split('.');

    if (d.length >= 2) {
      return e[0];
    }

    return d;
  }

  isInteractiveTab () {
    return this.meta.currentTab === 1;
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }
}
