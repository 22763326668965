export const DATALAKE_TAB_DOCUMENTS = 'Documents';
export const DATALAKE_TAB_PUBLICATIONS = 'Publications';

export default [
  {
    name: DATALAKE_TAB_DOCUMENTS,
    tabKey: DATALAKE_TAB_DOCUMENTS.toLowerCase(),
    moduleKey: 'datalake',
    active: true,
    chip: false,
    eager: false,
    resource: {
      headers: [
        { text: 'File Name', value: 'doc_name', width: '420px' },
        { text: 'Owner', value: 'owner' },
        { text: 'Last Update', value: 'update_date', align: 'right' },
        { text: 'File Size (MB)', value: 'file_size', align: 'right' },
        { text: 'Type', value: 'file_type', align: 'center' },
      ],
      options: {
        itemsPerPage: 100,
      },
      meta: {
        dense: false,
        showSelect: false,
        mobileBreakpoint: 0,
        supportedQuery: [
          'q',
          'tab',
          'page',
          'order_by',
          'projects_list',
          { search: 'q' },
          { sortBy: 'order_by' },
          { itemsPerPage: 'page_size' },
        ],
      },
    },
    component: () => import('@/modules/Datalake/components/Tables/Documents'),
  },
  {
    name: DATALAKE_TAB_PUBLICATIONS,
    tabKey: DATALAKE_TAB_PUBLICATIONS.toLowerCase(),
    moduleKey: 'datalake',
    active: false,
    chip: false,
    eager: false,
    resource: {
      headers: [
        { text: 'Title', value: 'article_title' },
        { text: 'Journal Proceeding', value: 'journal_proceeding_title' },
        { text: 'Author/s', value: 'author', align: 'left' },
        { text: 'Publication Date', value: 'publication_date', align: 'left' },
      ],
      options: {
        itemsPerPage: 100,
      },
      meta: {
        dense: false,
        showSelect: false,
        mobileBreakpoint: 0,
        supportedQuery: [
          'q',
          'tab',
          'page',
          'order_by',
          'projects_list',
          { search: 'q' },
          { sortBy: 'order_by' },
          { itemsPerPage: 'page_size' },
        ],
      },
    },
    component: () => import('@/modules/Datalake/components/Tables/Publications'),
  },
];
