import User from '@/modules/User/resources/User';
import { accountInfo, backgroundInfo } from '@/modules/User/config/userinfo';
import { isEmpty, isArray } from 'lodash';

export default class Profile extends User {
  getBackgroundInfo () {
    return backgroundInfo.map(i => ({
      ...i,
      empty: isEmpty(this.data?.[i.key]),
      value: isArray(this.data?.[i.key]) ? this.data?.[i.key]?.join(', ') : this.data?.[i.key]?.toString(),
    }));
  }

  getAccountInfo () {
    return accountInfo.map(i => ({
      ...i,
      empty: isEmpty(this.data?.[i.key]),
      value: isArray(this.data?.[i.key]) ? this.data?.[i.key]?.join(', ') : this.data?.[i.key]?.toString(),
    }));
  }
}
