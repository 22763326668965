import DataResource from '@core/resources/DataResource';
import { isEmpty } from 'lodash';

export default class CacheResource extends DataResource {
  static isNonObtrusive () {
    return (process.env.VUE_APP_NON_OBTRUSIVE_CACHE_BUSTER ?? false) === 'true';
  }

  static setCacheKey (key) {
    return localStorage.setItem('cache:key', key);
  }

  static getCacheKey () {
    return localStorage.getItem('cache:key');
  }

  static isEnabled () {
    return process.env.VUE_APP_ENABLE_MANUAL_CACHE_BUSTER === 'true';
  }

  static environmentCacheKeyChanged () {
    return CacheResource.getCacheKey() !== CacheResource.getEnvironmentCacheKey();
  }

  static storeEnvironmentCacheKeyIfNotExists () {
    if (isEmpty(CacheResource.getCacheKey())) {
      localStorage.setItem('cache:key', process.env.VUE_APP_CACHE_KEY);
    }
  }

  static getEnvironmentCacheKey () {
    return process.env.VUE_APP_CACHE_KEY ?? process.env.CACHE_KEY;
  }

  static getCheckTimeoutDelay () {
    return process.env.VUE_APP_CACHE_CHECK_TIMEOUT_DELAY ?? 3000;
  }

  static nukeEm () {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie
      .split(';')
      .forEach(c => {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
      });
    window.location.reload(true);
  }
}
