<template>
  <v-sheet class="pa-3">
    <!-- DataSource Tray -->
    <data-source-tray-filter
      width="400px"
      class="mr-2"
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <!-- Category tabs -->
    <buckets-tab-chip-group
      v-model="image.meta.buckets.selected"
      :items="image.meta.buckets.items"
      :loading="image.searching"
      @click:tab="selectBucket"
    ></buckets-tab-chip-group>
    <!-- Category tabs -->

    <images-data-iterator
      :dense="image.meta.dense"
      :headers="image.headers"
      :hide-default-footer="image.meta.hideDefaultFooter"
      :items-per-page="image.pagination.itemsPerPage"
      :items-per-row="image.meta.itemsPerRow"
      :items="image.items"
      :loading="image.loading"
      :mobile-breakpoint="image.meta.mobileBreakpoint"
      :options.sync="image.options"
      :server-items-length="image.pagination.total"
      :show-select="image.meta.showSelect"
      @download="downloadImage"
      @update:options="updateList"
    >
      <template v-slot:button.link="{ item: imageItem }">
        <v-btn
          :to="{
            name: 'reports.image',
            params: { slug: $slug, image: imageItem.id },
            query: { project_id: imageItem.attributes.project_id },
          }" exact
          dark
          small outlined
          class="mb-4"
        >
          <v-icon left small>mdi-magnify-plus-outline</v-icon>
          {{ $trans('Zoom in') }}
        </v-btn>
      </template>
      <template
        v-if="can('report_spreadsheet_viewer') && htmlFileCheck"
        v-slot:button.go-to-page="{ item: imageItem }"
      >
        <v-btn
          :to="{
            name: 'reports.single',
            params: { slug: imageItem.attributes.doc_ref },
            query: {
              page_num: imageItem.attributes.page_num,
              project_type: imageItem.attributes.project_type,
              back: $route.path,
            },
          }"
          dark
          small
          outlined class="mb-4"
          @click="goToTab(imageItem)"
        >
          <v-icon left small>mdi-file-eye-outline</v-icon>
          {{ $trans(`Go to page {page}`, {
            page: imageItem.attributes.page_num,
          }) }}
        </v-btn>
        <!-- <p>{{imageItem}}</p> -->
      </template>

      <template v-slot:footer>
        <div v-if="image.loaded" class="text-center caption">
          <div
            class="muted--text"
            v-text="$trans_choice('{count} of {total} item', image.pagination.total, {
              count: image.size(),
              total: image.pagination.total,
            })"
          ></div>
        </div>
        <v-btn
          v-if="image.moreToLoad()"
          :loading="image.loading"
          :disabled="image.loading"
          depressed
          block large
          class="my-3"
          @click="loadMoreImages"
        >
          <span v-text="$trans('Load more')"></span>&nbsp;
        </v-btn>
      </template>
    </images-data-iterator>
  </v-sheet>
</template>

<script>
import Image from '@/modules/Image/resources/Image';
import CheckUserPermission from '@core/mixins/CheckUserPermission';

import { FIND_DATALAKE_DATA_URL } from '@/modules/Dashboard/api/datalake';
import Datalake from '@/modules/Dashboard/resources/Datalake';

export default {
  name: 'ReportViewerMetaInfoImages',

  mixins: [
    CheckUserPermission,
  ],

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => {},
    },

    tab: {
      type: Object,
      default: () => {},
    },
  },

  data: vm => ({
    datalake: new Datalake(vm.resource),
    image: new Image({
      options: {
        itemsPerPage: 10,
      },
      meta: {
        dense: false,
        itemsPerRow: 2,
        docRef: vm.$slug,
        showSelect: true,
        mobileBreakpoint: 0,
        hideDefaultFooter: true,
        selectedBucketTab: null,
        unsupportedBuckets: [ 'No Class', 'Others', 'Noise', 'EAGE - Title' ],
        supportedQuery: [
          'q',
          'page',
          'doc_ref',
          'img_tag_1',
          'page_from',
          'project_id',
          'projects_list',
          { search: 'q' },
          { sortBy: 'order_by' },
          { itemsPerPage: 'page_size' },
        ],
      },
    }),
    htmlURL: '',
    htmlFileCheck: false,
  }),

  watch: {
    'item.selectedTab': function () {
      this.$store.dispatch('image/setCurrentBucket', null);
      this.$store.dispatch('image/setBucket', null);
    },
  },

  async created () {
    await this.image.initialByDocRefWithoutImgTag();
    this.setTabChip(this.image.pagination.total);

    const { data } = await this.datalake.axios.get(FIND_DATALAKE_DATA_URL(this.$route.params.slug));
    const params = this.datalake.getQueryString();
    const currentPage = params.page_num ? params.page_num : '1';
    const storage = data.data.attributes.file_url.split('deployed')[0];
    this.htmlURL = `${storage}$web/${this.$route.params.slug}/html/page_${currentPage}.html`;
    this.htmlFileCheck = await this.datalake
      .checkHTMLSource(this.htmlURL, storage, this.$route.params.slug);
  },

  methods: {
    setTabChip (value) {
      this.image.unsetActiveBucket();
      this.tab.chip = value;
    },

    changedDataSource () {
      this.image.listByDocRefWithoutImgTag();
    },

    updateList (options) {
      this.image.setQueryString(options).listByDocRefWithoutImgTag();
    },

    selectBucket ({ item }) {
      this.image.setActiveBucket(item).listByDocRef();
    },

    setSearch (e) {
      this.image.setSearch(e.target.value).listByDocRefWithoutImgTag();
    },

    clearSearch () {
      this.image.clearSearch().listByDocRefWithoutImgTag();
    },

    downloadImage (item) {
      this.image.downloadImage(item);
    },

    loadMoreImages () {
      this.image.listMoreByDocRef();
    },

    goToTab (item) {
      this.$emit('click:goToTab', item);
      this.image.setQueryString({
        ...this.image.getQueryString(),
        page_num: item.attributes.page_num,
      });
    },
  },
};
</script>
