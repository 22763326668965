var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',{attrs:{"max-width":"960px","persistent":"","width":"960px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('dialog-browser-compatibility',{on:{"close":_vm.dialogClose}})],1),_c('meta-tag',{attrs:{"title":_vm.$trans('Sign in')}}),_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","xl":"3","lg":"4","md":"5","sm":"10"}},[_c('app-brand-banner',{staticClass:"mx-auto text-center ma-4",attrs:{"width":"320"}}),_c('login-page-greeter'),_c('v-slide-y-transition',{attrs:{"mode":"in-out"}},[(_vm.form.hasErrors())?_c('v-alert',{attrs:{"color":"error","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"login-error-message",domProps:{"textContent":_vm._s(_vm.form.getErrors('message', _vm.authErrorMessage))}})],1):_vm._e()],1),_c('validation-observer',{ref:"signin",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"disabled":_vm.form.loading,"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}},model:{value:(_vm.form.valid),callback:function ($$v) {_vm.$set(_vm.form, "valid", $$v)},expression:"form.valid"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"min-width":"320px","max-width":"420px"}},[_c('v-card-title',[_c('v-layout',{attrs:{"column":"","align-start":""}},[_c('h2',[_vm._v(_vm._s(_vm.$trans('Sign in')))]),_c('p',{staticStyle:{"font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.$trans("Don't have an account?"))+" "),_c('router-link',{attrs:{"to":{ name: 'register' }}},[_vm._v(_vm._s(_vm.$trans('Sign up')))])],1)])],1),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"username","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$trans('Email'),"autofocus":"","outlined":"","type":"email","name":"username"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-password-field',{attrs:{"error-messages":errors,"label":_vm.$trans('Password'),"outlined":"","name":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('v-layout',{staticClass:"mb-4",attrs:{"align-start":""}},[_c('p',{staticClass:"text-left mb-0"},[_c('v-checkbox',{attrs:{"label":_vm.$trans('Remember me'),"hide-details":""},model:{value:(_vm.form.rememberMe),callback:function ($$v) {_vm.$set(_vm.form, "rememberMe", $$v)},expression:"form.rememberMe"}})],1),_c('v-spacer'),_c('p',{staticClass:"text-right mt-4 mb-0"},[_c('router-link',{staticClass:"text-caption",attrs:{"to":{ name: 'password.reset' },"tabindex":"3"}},[_vm._v(" "+_vm._s(_vm.$trans('Forgot password?'))+" ")])],1)],1),_c('v-layout',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.form.loading,"disabled":_vm.form.loading,"block":_vm.isMobile,"large":"","width":"132","color":"primary","type":"submit"},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-slide-x-transition',[_c('v-icon',{attrs:{"small":"","left":"","dark":""}},[_vm._v("mdi-loading mdi-spin")])],1),_c('span',[_vm._v(_vm._s(_vm.$trans('Sign in')))])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$trans('Sign in'))+" ")])],1)],1),_c('v-divider',{staticClass:"mx-auto mt-4",attrs:{"inset":""}}),_c('div',{staticClass:"px-5 pt-4 mb-5 body-2 text-center muted--text"},[_c('div',{staticClass:"text-caption",domProps:{"innerHTML":_vm._s(_vm.$trans('login.problem', {
                email: _vm.$app.supportEmail,
              }))}})])],1)],1)]}}])}),_c('auth-footer',{staticClass:"text-center"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }