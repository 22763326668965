const DYNAMIC_ROUTE_REGEX = /^\/([:*])/;

export const sortRoutes = function (routes) {
  routes.sort((a, b) => {
    if (!a.path.length) {
      return -1;
    }

    if (!b.path.length) {
      return 1;
    }

    // Order: /static, /index, /:dynamic
    // Match exact route before index: /login before /index/_slug
    if (a.path === '/') {
      return DYNAMIC_ROUTE_REGEX.test(b.path) ? -1 : 1;
    }

    if (b.path === '/') {
      return DYNAMIC_ROUTE_REGEX.test(a.path) ? 1 : -1;
    }

    let i;
    let res = 0;
    let y = 0;
    let z = 0;
    const _a = a.path.split('/');
    const _b = b.path.split('/');
    for (i = 0; i < _a.length; i++) {
      if (res !== 0) {
        break;
      }
      y = _a[i] === '*' ? 2 : _a[i].includes(':') ? 1 : 0;
      z = _b[i] === '*' ? 2 : _b[i].includes(':') ? 1 : 0;
      res = y - z;
      // If a.length >= b.length
      if (i === _b.length - 1 && res === 0) {
        // unless * found sort by level, then alphabetically
        res = _a[i] === '*' ? -1 : (
          _a.length === _b.length ? a.path.localeCompare(b.path) : (_a.length - _b.length)
        );
      }
    }

    if (res === 0) {
      // unless * found sort by level, then alphabetically
      res = _a[i - 1] === '*' && _b[i] ? 1 : (
        _a.length === _b.length ? a.path.localeCompare(b.path) : (_a.length - _b.length)
      );
    }
    return res;
  });

  routes.forEach(route => {
    if (route.children) {
      sortRoutes(route.children);
    }
  });

  return routes;
};

export const route = function (name, params = {}, query = {}) {
  return {
    name,
    params,
    query,
  };
};

export const basename = function (str, sep) {
  return str.substr(str.lastIndexOf(sep) + 1);
};

export const stripExtension = function (str) {
  return str.substr(0, str.lastIndexOf('.'));
};
