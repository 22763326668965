<template>
  <portal to="sidebar:top.append">
    <app-mode name="DATA_ATELIER">
      <div v-if="isSidebarMini" class="px-4 text-center">
        <v-tooltip right transition="slide-x-transition">
          <template v-slot:activator="{ on }">
            <div style="cursor: help;" v-on="on">
              <v-icon small>mdi-file-multiple-outline</v-icon>
              <div style="font-size: 9px;">Credits</div>
            </div>
          </template>
          <span
            v-text="$trans('{count} of {total} pages used', {
              count: tracker.used,
              total: tracker.total,
            })"
          ></span>
        </v-tooltip>
      </div>
      <div v-else class="pa-4">
        <v-layout class="mb-2">
          <v-icon small left>mdi-file-multiple-outline</v-icon>
          <span v-text="$trans('Page Credits')"></span>
        </v-layout>
        <v-progress-linear
          :value="tracker.percentage"
          class="mb-2"
        ></v-progress-linear>
        <v-layout>
          <span
            class="caption"
            v-text="$trans('{count} of {total} pages used', {
              count: tracker.used,
              total: tracker.total,
            })"
          ></span>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="10"
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-icon small right v-on="on">mdi-information</v-icon>
            </template>
            <v-card>
              <v-card-text>
                <div
                  class="mb-3 caption"
                  v-html="$trans('Page credits are <em>renewed</em> monthly.')"
                ></div>
                <v-layout align-center>
                  <div class="mr-4 caption" v-html="$trans('Need more page credits?')"></div>
                  <v-btn
                    small rounded
                    color="secondary"
                    class="text-capitalize"
                    @click="goToPage"
                  ><span v-text="$trans('Upgrade')"></span></v-btn>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-layout>
      </div>
    </app-mode>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PageCreditCounterSystemTray',

  data: () => ({
    menu: false,
  }),

  computed: {
    ...mapGetters({
      isSidebarMini: 'sidebar/mini',
    }),
    tracker () {
      const user = this.$store.getters['auth/user']?.data;
      const used = Number(parseFloat(user.download_tracker?.['credits-used'] ?? 0)).toLocaleString('en');
      const total = Number(parseFloat(user.account_type?.restrictions?.credits ?? 0)).toLocaleString('en');
      const percentage = (user.download_tracker?.['credits-used'] / (user.account_type?.restrictions?.credits ?? 1)) * 100;

      return {
        used,
        total,
        percentage,
      };
    },
  },

  methods: {
    goToPage () {
      this.menu = false;

      if (this.$user.isPermittedTo('bolt_availability')) {
        this.$router.push({
          name: 'profile.subscriptions.change',
          query: { o: Date.now() },
        });
      } else {
        this.$store.dispatch('billing/openBoltAvailabilityWarningPrompt');
      }
    },
  },
};
</script>
