<template>
  <portal to="corner.bottom-left">
    <v-slide-y-reverse-transition mode="in-out">
      <v-card
        v-if="isScatterPlotShown"
        width="420"
        max-height="590"
        class="ml-12 ov-y-auto"
      >
        <v-data-table
          :headers="headers"
          :items="scatterplot.dataset"
          :item-class="item => (item.active ? 'red lighten-5' : null)"
          :footer-props="{ itemsPerPageOptions: [ 5, 10, 15, 20 ] }"
          fixed-header
          @click:row="onRowClick"
        >
          <template v-slot:item.avg_x="{ item }">
            <span>{{ item.avg_x | toFixed }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-slide-y-reverse-transition>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';
import ScatterPlotDataViz from '@/modules/Webgis/resources/ScatterPlotDataViz';

export default {
  name: 'VizScatterPlotMapControlTableCard',

  filters: {
    toFixed (value, num = 2) {
      return parseFloat(value).toFixed(num);
    },
  },

  props: {
    resource: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    viz: new ScatterPlotDataViz,
  }),

  computed: {
    ...mapGetters({
      isScatterPlotShown: 'viz/scatterplot/shown',
      scatterplot: 'viz/scatterplot/scatterplot',
      currentSettings: 'viz/scatterplot/settings',
    }),

    headers () {
      return [
        { text: 'Well name', value: 'well_name' },
        { text: this.currentSettings.table_title, value: 'avg_x', align: 'right' },
      ];
    },
  },

  methods: {
    async onRowClick (well) {
      await this.$store.dispatch('viz/scatterplot/setWellActive', well);

      this.resource.onScatterPlotMarkerClick({
        well,
      }, { updateTable: false });

      if (!well.active) {
        this.resource.listMapMarkers();
      }
    },
  },
};
</script>
