import Set from '@/modules/File/resources/Set';

export default async function isPendingAuditApproval (to, from, next) {
  const set = new Set;
  const { id } = to.params;

  await set.find(id);

  if (set.isPendingAuditApprovalOrAbove()) {
    return next();
  }

  return next({
    name: 'sets.show',
    params: { id },
    query: to.query,
    replace: true,
  });
}
