<template>
  <v-tour
    :name="name"
    :steps="system.steps"
    :options="system.options"
    class="tour__main-system"
  >
    <template v-slot="tour">
      <v-scale-transition>
        <slot :name="`item.${tour.currentStep}`" v-bind="{ tour }">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="tour.highlight"
            :class="color"
          >
            <template v-slot:actions>
              <slot name="actions" v-bind="{ tour }">
                <v-layout justify-end class="v-step__action">
                  <v-spacer></v-spacer>
                  <template v-if="isNotSingle">
                    <v-btn
                      v-if="tour.isFirst"
                      small
                      dark text
                      class="ml-2"
                      @click="onClick('skip', tour.skip)"
                      v-text="$trans('Skip')"
                    ></v-btn>
                    <v-btn
                      v-else
                      small
                      dark text
                      class="ml-2"
                      @click="onClick('previousStep', tour.previousStep)"
                      v-text="$trans('Previous')"
                    ></v-btn>
                  </template>
                  <v-btn
                    v-if="tour.isLast"
                    small
                    dark text
                    class="ml-2"
                    @click="onClick('stop', tour.stop)"
                    v-text="$trans('Ok')"
                  ></v-btn>
                  <v-btn
                    v-else
                    small
                    dark text
                    class="ml-2"
                    @click="onClick('nextStep', tour.nextStep)"
                    v-text="$trans('Next')"
                  ></v-btn>
                </v-layout>
              </slot>
            </template>
          </v-step>
        </slot>
      </v-scale-transition>
    </template>
  </v-tour>
</template>

<script>
export default {
  name: 'TourMainSystem',

  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        highlight: true,
      }),
    },
    autoStart: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: [ Number, String ],
      default: 800,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'secondary white--text',
    },
  },

  data: vm => ({
    system: {
      options: vm.options,
      steps: vm.steps,
    },
  }),

  computed: {
    isSingle () {
      return this.system.steps.length === 1;
    },

    isNotSingle () {
      return !this.isSingle;
    },

    attributeName () {
      return `data-tour-${this.name}`;
    },

    attributeContent () {
      return 'data-tour-content';
    },

    attributeParams () {
      return 'data-tour-options';
    },
  },

  mounted () {
    this.collectSteps();
    this.startTour();
  },

  methods: {
    startTour () {
      if (this.autoStart) {
        setTimeout(() => {
          this.$tours[this.name].start();
        }, this.delay);
      }
    },

    collectSteps () {
      this.system.steps = Array.from(document.querySelectorAll(`[${this.attributeName}]`))
        .map(step => this.makeStep(step));
    },

    makeStep (step) {
      return {
        $el: step,
        step: step.getAttribute(`${this.attributeName}`),
        content: step.getAttribute(this.attributeContent),
        params: JSON.parse(step.getAttribute(this.attributeParams)),
        target: `[${this.attributeName}="${step.getAttribute(this.attributeName)}"]`,
      };
    },

    onClick (name, click) {
      click();
      this.$emit(`click:${name}`);
    },
  },
};
</script>

<style lang="scss">
.tour {
  &__main-system {
    .v-step {
      background: var(--v-secondary-base);
      border-radius: var(--global-radius);
      padding: 0;

      &__content {
        padding: 1.25rem;
        padding-bottom: 0;
        text-align: left;
      }

      &__action {
        padding: 8px;
        padding-top: 0;
      }
    }
  }
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 5px transparentize(map-deep-get($default, 'light', secondary), 0.4);
}

[data-tour-overlay] {
  &.v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
  }
}
</style>
