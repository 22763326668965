export const state = {
  layers: {
    base: JSON.parse(localStorage.getItem('webgis:layers.base') || '[]'),
    others: JSON.parse(localStorage.getItem('webgis:layers.others') || '[]'),
  },
};

export const getters = {
  layers: state => state.layers,
};

export const mutations = {
  SET_LAYERS (state, { type, items }) {
    state.layers[type] = items;
    localStorage.setItem(`webgis:layers.${type}`, JSON.stringify(items));
  },
};

export const actions = {
  layers ({ commit }, payload) {
    commit('SET_LAYERS', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
