<template>
  <v-sheet height="100%">
    <v-card tile class="fill-height" height="100%">
      <slot></slot>
    </v-card>
    <slot name="actions"></slot>
  </v-sheet>
</template>

<script>
export default {
  name: 'BboxCard',
};
</script>
