<template>
  <file-submit-for-approval-alert-loading-state
    v-if="loading"
  ></file-submit-for-approval-alert-loading-state>
  <component
    :is="alertComponent"
    v-else
  ></component>
</template>

<script>
import { startCase } from 'lodash';

export default {
  name: 'FileSubmitForApprovalAlert',

  props: {
    status: {
      type: String,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    success: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    alertComponent () {
      const status = startCase(this.status.replaceAll('_', ' ')).replaceAll(' ', '')
        ?? 'Default';
      return `AlertSetStatus${status}`;
    },

    isDone () {
      return this.success;
    },

    isPending () {
      return !this.success;
    },
  },
};
</script>
