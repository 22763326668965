<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn large icon v-on="on">
        <v-icon size="30">mdi-palette</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list-item
        v-for="(theme, name) in themes"
        :key="name"
        :disabled="$vuetify.theme.themes.name === name"
        two-line
        @click="setTheme({ theme, name })"
      >
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            {{ name | titleCase }}
          </v-list-item-title>
          <div class="d-grid grid-template-columns" style="grid-gap: 0.4rem;">
            <app-theme-color-badge
              :color="theme.light.primary"
              title="primary"
            ></app-theme-color-badge>
            <app-theme-color-badge
              :color="theme.light.secondary"
              title="secondary"
            ></app-theme-color-badge>
            <app-theme-color-badge
              :color="theme.light.accent"
              title="accent"
            ></app-theme-color-badge>
          </div>
        </v-list-item-content>
        <v-list-item-action v-if="$vuetify.theme.themes.name === name">
          <v-icon>mdi-check</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            Dark Mode
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch v-model="dark"></v-switch>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </v-menu>
</template>

<script>
import Theme from '@root/theme.json';
import { startCase } from 'lodash';

export default {
  name: 'AppThemeSwitcher',

  filters: {
    titleCase (value) {
      return startCase(value);
    },
  },

  data: () => ({
    menu: false,
    themes: Theme,
    dark: false,
  }),

  watch: {
    dark (isDark) {
      this.$store.dispatch('theme/dark', isDark);
    },
  },

  methods: {
    setTheme ({ theme, name }) {
      this.$store.dispatch('theme/set', { name, ...theme });
    },
  },
};
</script>
