<template>
  <v-fab-transition>
    <v-btn
      fab
      large
      bottom
      fixed
      right
      class="fab-image-search"
      color="secondary"
      @click="$vuetify.goTo(0)"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'FabImageSearch',
};
</script>

<style>
.fab-image-search {
  bottom: 50px;
  display: block;
  font-size: 40px;
  font-weight: bold;
  height: 60px;
  padding-top: 2px;
  right: 90px;
  text-align: center;
  width: 60px;
  z-index: 999;
}
</style>
