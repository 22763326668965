import { isEmpty } from 'lodash';

export function generateRandomHash (seed = undefined) {
  let salt = seed || new Date().getTime();

  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    // use high-precision timer if available
    salt += performance.now();
  }

  const guuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (salt + Math.random() * 16) % 16 | 0;
    salt = Math.floor(salt / 16);
    // eslint-disable-next-line no-bitwise, no-mixed-operators
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });

  return guuid;
}

export function initialCase (text, len = 2) {
  if (isEmpty(text)) {
    return '';
  }

  const words = text.split(' ');
  let finalWord = '';

  words.forEach(word => { finalWord += word?.[0]?.toUpperCase(); });

  return finalWord.substring(0, len);
}

export default {
  initialCase,
  generateRandomHash,
};
