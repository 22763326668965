import Map from '@/modules/Search/resources/Map';
import { EXPORT_PIECHART_URL, GET_PIECHART_LIST_URL, GET_PIECHART_SUMMARY_URL } from '@/modules/Webgis/api/piechart';
import { API_PER_PAGE_ALL } from '@config/api';
import { coordsToGeoJson } from '@/modules/Dashboard/utils/map';
import { isEmpty, isNil, isArray } from 'lodash';
import { DEFAULT_FIT_BOUNDS_PADDING } from '@/modules/Dashboard/config/maps';
import L from 'leaflet';
import 'leaflet.minichart/dist/leaflet.minichart.min.js';
import { PIE_CHART_COLORS } from '@/modules/Webgis/config/colors';
import fileDownload from 'js-file-download';

export default class Lithology extends Map {
  constructor (map) {
    super();

    this.map = map;
    this.pieChartLayer = L.featureGroup();
    this.meta.isFetchingSummary = false;
  }

  async listPieChartBackgroundMarkers () {
    const user = this.store.getters['auth/user'];

    if (user.isPermittedTo('webgis_pie_chart')) {
      const params = {
        // projects_list: this.getProjectIds(),
        order_by: 'well_name',
        page_size: API_PER_PAGE_ALL,
        page: 1,
      };

      const { data } = await this.axios.get(GET_PIECHART_LIST_URL, { params });

      const items = data.data.map(item => ({
        ...item,
        id: item.well_id,
        attributes: item,
      }));

      this.store.dispatch('lithology/setPieChartItems', items);

      coordsToGeoJson(items).forEach(feature => {
        this.setPieChartMarkerWithPopup({ ...feature, ...feature.properties });
      });

      if (this.pieChartLayer) {
        this.pieChartLayer.bringToFront();
        this.map.addLayer(this.pieChartLayer);

        if (!isEmpty(this.pieChartLayer.getBounds())) {
          this.map.fitBounds(this.pieChartLayer.getBounds(), {
            padding: DEFAULT_FIT_BOUNDS_PADDING,
          });
        }
      }

      this.map.on('click', () => {
        const isSpatialOn = this.store.getters['webgis/spatial/isOn'];
        this.pieChartLayer.eachLayer(l => l.setOptions({ opacity: 1 }));
        if (!this.meta.isFetchingSummary && !isSpatialOn) {
          this.listPieChartSummary();
        } else {
          console.log('pie chart wells are already being fetched');
        }
      });

      this.stopLoading();
    }
  }

  setPieChartMarkerWithPopup (data) {
    if (isEmpty(data.values)) {
      return {};
    }

    const {
      popup, latLng,
    } = this.setMarkerWithPopup(data);

    const layer = L.minichart(latLng, {
      type: 'pie',
      width: 24,
      height: 24,
      labels: data.labels,
      data: data.values,
      colors: this.getPieChartColors(),
    });

    if (this.pieChartLayer) {
      this.pieChartLayer.addLayer(layer);
    }

    layer.bindTooltip(data.well_name);

    layer.on('click', e => {
      L.DomEvent.stopPropagation(e);
      this.pieChartLayer.eachLayer(l => l.setOptions({ opacity: 0.3 }));
      layer.setOptions({ opacity: 1 });
      this.onPieChartClick({ layer: e, data });
    });

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  getPieChartColors () {
    return PIE_CHART_COLORS;
  }

  async onPieChartClick (item) {
    this.disableAxiosResponseHandlers();

    try {
      if (item?.data?.id) {
        this.store.dispatch('lithology/startLoading');
        const params = { well_id_list: item.data.id };
        const { data } = await this.axios.get(GET_PIECHART_LIST_URL, { params });

        this.store.dispatch('lithology/setData', {
          id: item.data.id,
          well: data.data[0],
          labels: data.data[0].labels,
          data: data.data[0].values,
          total: false,
        });

        this.setQueryString({ pcWell: item.data.id });

        setTimeout(() => {
          this.store.dispatch('lithology/show');
        }, 300);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      this.enableAxiosResponseHandlers();
      this.store.dispatch('lithology/stopLoading');
    }
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }

  async listPieChartSummary () {
    this.meta.isFetchingSummary = true;
    const { data } = await this.axios.get(GET_PIECHART_SUMMARY_URL);
    this.store.dispatch('lithology/setData', {
      labels: data.data.labels,
      data: data.data.values,
      total: data.data.total_wells,
    });
    this.meta.isFetchingSummary = false;
  }

  async exportPieChartItemOrAll (id = null) {
    if (isNil(id)) {
      this.exportPieChart(this.getProjectIds(), 'projects_list');
    } else {
      this.exportPieChart(id);
    }
  }

  async exportPieChart (id, key = 'well_id_list') {
    const formData = new FormData;

    if (isArray(id)) {
      id.forEach(i => formData.append(key, i));
    } else {
      formData.append(key, id);
    }

    const { data } = await this.axios.post(EXPORT_PIECHART_URL, formData, {
      headers: {
        'Content-Type': 'arraybuffer',
      },
    });

    return fileDownload(data, 'well-export.csv');
  }
}
