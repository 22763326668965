export const USER_JUST_LOGGEDOUT_KEY = 'user.loggedout';
export const USER_JUST_LOGGEDOUT_VALUE = 'true';
export const SESSION_EXPIRE_AT_BROWSER_CLOSE_KEY = 'SESSION_EXPIRE_AT_BROWSER_CLOSE';

export const isUserJustLoggedOut = e => e.newValue === USER_JUST_LOGGEDOUT_VALUE;
export const saveLogOutValue = () => localStorage.setItem(
  USER_JUST_LOGGEDOUT_KEY,
  USER_JUST_LOGGEDOUT_VALUE,
);
export const unsetLogOutValue = () => localStorage.removeItem(USER_JUST_LOGGEDOUT_KEY);

export default {
  USER_JUST_LOGGEDOUT_KEY,
  isUserJustLoggedOut,
  saveLogOutValue,
  unsetLogOutValue,
};
