<template>
  <app-content>
    <meta-tag :title="$trans('Account Verified')"></meta-tag>

    <v-row class="mt-10">
      <v-col
        cols="12"
        lg="4" md="8"
        class="mx-auto text-center"
      >
        <div class="text-center mx-auto mt-5 mb-15">
          <app-brand width="180" class="mx-auto"></app-brand>
        </div>

        <div class="secondary--text">
          <icon-success-circle></icon-success-circle>
        </div>

        <h1 class="text-center mb-2" v-text="$trans(message.title)"></h1>
        <p v-html="$trans(message.content)"></p>

        <v-btn
          x-large
          color="primary"
          @click="goToLogin"
        ><span v-text="$trans('Sign in')"></span></v-btn>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import { mapGetters } from 'vuex';
import IsRequestComingFromVerifyPage from '@/modules/Auth/middlewares/IsRequestComingFromVerifyPage';

export default {
  layout: 'landing',

  middleware: [ IsRequestComingFromVerifyPage ],

  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('verify/unsetAvailability');

    next();
  },

  computed: mapGetters({
    message: 'verify/message',
  }),

  methods: {
    goToLogin () {
      this.$router
        .push({ name: 'logout' })
        .catch(() => {});
    },
  },
};
</script>
