<template>
  <div class="muted--text">
    <div class="title" v-html="$trans('API requests logs are unavailable.')"></div>
  </div>
</template>

<script>
export default {
  name: 'ApiRequestsTrackerEmptyState',
};
</script>
