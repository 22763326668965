var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{ref:"panzoom-pdf-previewer",staticClass:"pdf-previewer",class:{
    'pdf-previewer--fullscreen': _vm.fullview,
    'pdf-previewer--twopage': _vm.twopageview,
  },attrs:{"outlined":"","height":"100%","min-height":"calc(100vh - 330px)"}},[_c('app-stickybar',[_c('v-toolbar',{attrs:{"elevation":"4"}},[_c('v-app-bar-nav-icon',{staticClass:"mr-2",on:{"click":_vm.toggleDrawer}}),_vm._t("toolbar.title",[_c('v-layout',{staticClass:"ov-x-auto",attrs:{"align-center":"","justify-start":""}},[_c('panzoom-pdf-previewer-page-selector',{staticClass:"mr-2",attrs:{"total":_vm.total},on:{"update:page":_vm.jumpToPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('v-spacer'),_c('div',{staticClass:"panzoom-pdf-previewer__search-field-container"},[(_vm.searchBar)?_c('panzoom-pdf-previewer-search-field',{staticClass:"mx-2",attrs:{"loading":_vm.searching,"page":_vm.currentPage,"results":_vm.searchResults},on:{"click:item":_vm.selectItem,"click:clear":_vm.clearSearch,"keyup:search":_vm.setSearch},scopedSlots:_vm._u([{key:"search.item",fn:function(ref){
                var item = ref.item;
                var select = ref.select;
                var highlight = ref.highlight;
                var search = ref.search;
                var showOriginalText = ref.showOriginalText;
                var itemTextKey = ref.itemTextKey;
return [_vm._t("search.item",null,null,{
                    item: item,
                    select: select,
                    highlight: highlight,
                    showOriginalText: showOriginalText,
                    itemTextKey: itemTextKey,
                    search: search,
                    updatePage: _vm.updatePage,
                  })]}}],null,true),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-spacer'),_c('v-layout',{attrs:{"align-center":"","justify-lg-end":"","justify-sm-start":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":_vm.downloadItem}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-download")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Download'))}})]),_c('panzoom-pdf-previewer-toolbar-menu')],1)],1)])],2)],1),_c('v-card',{staticClass:"ov-hidden",attrs:{"flat":"","tile":""}},[_c('panzoom-image-previewer',{ref:"image-previewer",staticClass:"panzoom-pdf-previewer__image-previewer ov-hidden",attrs:{"id":"pdf-previewer","color":_vm.color,"loading":_vm.loading,"options":_vm.computedOptions,"zoom-bar":_vm.zoomBar,"height":_vm.height},on:{"scroll":_vm.onScroll,"scrolled":_vm.onScrolled,"wheel:up":_vm.onWheelUp,"wheel:down":_vm.onWheelDown},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"top-intersector__bar",staticStyle:{"height":"10px","position":"absolute","width":"100%"}})]},proxy:true},{key:"loading",fn:function(){return [_vm._t("loading")]},proxy:true},{key:"zoom-bar.append",fn:function(){return [_vm._t("zoom-bar.append")]},proxy:true}],null,true)},[_c('v-row',[_vm._l((_vm.items),function(item,i){return [_c('v-col',{key:i,attrs:{"cols":_vm.twoPageViewCol,"lg":_vm.twoPageViewCol}},[_c('div',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
                handler: function (e) { return _vm.onTopIntersect(e, item); },
                options: {
                  root: _vm.rootElement,
                },
              }),expression:"{\n                handler: e => onTopIntersect(e, item),\n                options: {\n                  root: rootElement,\n                },\n              }",modifiers:{"quiet":true}}],staticClass:"top-intersector__sensor",staticStyle:{"height":"10px","margin-top":"10px","width":"100%"},attrs:{"data-page":i+1}}),_c('v-sheet',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
                handler: function (e) { return _vm.onIntersect(e, item); },
                options: {
                  root: _vm.rootElement,
                  threshold: [ 0.3, 0.45, 0.5 ],
                },
              }),expression:"{\n                handler: e => onIntersect(e, item),\n                options: {\n                  root: rootElement,\n                  threshold: [ 0.3, 0.45, 0.5 ],\n                },\n              }",modifiers:{"quiet":true}}],staticClass:"pdf-previewer__item mb-3",attrs:{"id":("page-" + (i+1)),"data-page":i+1,"color":"transparent"}},[(_vm.showPage)?_c('div',{staticClass:"muted--text text-right caption pa-2 white",domProps:{"textContent":_vm._s(_vm.$trans('Page {page}', {
                  page: i+1,
                }))}}):_vm._e(),_c(_vm.itemWrapper,{key:i,tag:"component",staticClass:"pdf-previewer__item-wrapper"},[_vm._t("item",null,null,{
                    item: item,
                    twopageview: _vm.twopageview,
                    page: i+1,
                    isLoaded: item.loaded,
                    isLoading: item.loading,
                    onImageLoad: function (e) { return _vm.onImageLoad(item, e); },
                  })],2)],1)],1)]})],2)],1),_c('panzoom-pdf-previewer-thumbnail-navigator',{attrs:{"color":_vm.color,"value":_vm.drawer,"items":_vm.computedItems},on:{"click:thumbnail":_vm.selectThumbnail},scopedSlots:_vm._u([{key:"thumbnail",fn:function(ref){
                  var item = ref.item;
                  var clickThumbnail = ref.selectThumbnail;
                  var p = ref.page;
return [_vm._t("thumbnail",[_c('img',{staticClass:"d-block pa-2 mb-2 bordered",attrs:{"data-src":item.url,"src":item.url,"width":"100%","height":"auto"}})],null,{ item: item, selectThumbnail: clickThumbnail, page: p })]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }