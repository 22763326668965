import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      title: 'meta/title',
      description: 'meta/description',
    }),

    /**
     * The page object lifted from global meta store.
     *
     * @return {Object}
     */
    page () {
      return {
        title: this.title,
        description: this.description,
        backlink: this.$route.query.from,
      };
    },
  },
};
