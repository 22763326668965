var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":"","max-width":"480"}},[_c('v-card-text',{staticClass:"pt-0 px-0"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$trans('We would love to hear from you.'))}}),_c('validation-provider',{attrs:{"vid":"message","name":_vm.$trans('message'),"rules":"required|min:100","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"is-required",attrs:{"error-messages":errors,"label":_vm.$trans('Write your message'),"name":"message","counter":"","auto-grow":"","outlined":"","filled":"","prepend-inner-icon":"mdi-message-text-outline","height":"250"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})]}}],null,true)}),_c('v-btn',{attrs:{"disabled":invalid,"href":_vm.href,"x-large":"","block":"","color":"primary"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$trans('Send'))}})]),_c('div',{staticClass:"mt-4",domProps:{"innerHTML":_vm._s(_vm.$trans('Or directly email us<br> at <a href=\'mailto:{email}\'>{email}</a>', {
            email: _vm.$app.supportEmail,
          }))}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }