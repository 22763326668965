<template>
  <keep-alive v-if="can(code)">
    <slot></slot>
  </keep-alive>
  <keep-alive v-else>
    <slot name="alt"></slot>
  </keep-alive>
</template>

<script>
import CheckUserPermission from '@core/mixins/CheckUserPermission';

export default {
  mixins: [ CheckUserPermission ],

  props: {
    /**
     * The permission code to check against user permissions.
     *
     * @type {string}
     */
    code: {
      type: [ String, Boolean ],
      default: null,
    },
  },
};
</script>
