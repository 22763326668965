<template>
  <v-card fill-height height="calc(100vh - 216px)" class="ov-auto">
    <template v-if="loading">
      <v-layout fill-height align-center justify-center>
        <v-icon>mdi-loading mdi-spin</v-icon>
      </v-layout>
    </template>
    <div id="bokeh-card"></div>
  </v-card>
</template>

<script>
export default {
  name: 'RockphysicsInteractiveCard',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    loading: true,
  }),

  computed: {
    bokeh () {
      return {
        id: null,
        script: '',
        ...this.item,
      };
    },
  },

  mounted () {
    this.initializeBokehScript();
  },

  methods: {
    initializeBokehScript () {
      const scriptSrc = /xhr.open\('GET', "(.*?)"/g.exec(this.bokeh.script)[1];
      const pluginScript = document.createElement('script');
      pluginScript.setAttribute('type', 'text/javascript');
      pluginScript.setAttribute('src', scriptSrc);
      pluginScript.id = this.bokeh.id;
      pluginScript.async = true;
      const bokehTarget = document.querySelector('#bokeh-card');
      bokehTarget.appendChild(pluginScript);
      this.loading = false;
    },
  },
};
</script>
