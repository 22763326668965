export const supportedQuery = [
  'q',
  '_id',
  'tab',
  'page',
  'filters',
  'geo_tab',
  'page_from',
  'geo_polygon',
  'geo_filter_type',
  'has_geo_polygon',
  'has_geo_filter_type',
  { itemsPerPage: 'page_size' },
  { search: 'q' },
];

export const unsupportedQuery = [
  'No Class',
  'Others',
  'Noise',
  'EAGE - Title',
];

export default {
  supportedQuery,
  unsupportedQuery,
};
