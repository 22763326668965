import store from '@core/store';

export const uploadForm = {
  isShown: false,
  isHidden: true,
  supportedFileExtensions: null,
  setSupportedFileExtensions (extensions) {
    this.supportedFileExtensions = extensions;
  },
  getSupportedFileExtensions () {
    return this.supportedFileExtensions;
  },
  show () {
    this.isShown = true;
    this.isHidden = false;
  },
  hide () {
    this.isShown = false;
    this.isHidden = true;
  },
  toggle () {
    this.isShown = !this.isShown;
    this.isHidden = !this.isHidden;
  },
};

export const uploadTray = {
  isShown: false,
  isHidden: true,
  isMinimized: false,
  items: [],
  setIsShown (shown) {
    this.isShown = shown;
    this.isHidden = !shown;
  },
  show () {
    this.isShown = true;
    this.isHidden = false;
  },
  hide () {
    this.isShown = false;
    this.isHidden = true;
  },
  toggle () {
    this.isShown = !this.isShown;
    this.isHidden = !this.isHidden;
  },
  setItems (items) {
    this.items = items;
  },
  getitems () {
    return this.items;
  },
  toggleMinimize () {
    this.isMinimized = !this.isMinimized;
  },
  minimize () {
    this.isMinimized = true;
  },
  restore () {
    this.isMinimized = false;
  },
};

export const drag = {
  files: [],
  isOver: false,
  flagAsEnteredArea () {
    this.isOver = true;
  },
  flagAsLeftArea () {
    this.isOver = false;
  },
  setFiles (files) {
    this.files = files;
    store.dispatch('file/setFiles', files);
  },
  getFiles () {
    return this.files;
  },
};

export const uploadProgress = {
  isProgressing: false,
  isDone: false,
  remaining: 0,
  progress: 0,
  loaded: 0,
  total: 0,
};

export default {
  uploadProgress,
  uploadForm,
  uploadTray,
  drag,
};
