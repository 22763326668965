<template>
  <v-card>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: 'SubscriptionPlanItem',
};
</script>
