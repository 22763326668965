<template>
  <div class="file-upload__container fill-height">
    <v-snackbar
      v-model="snackbar"
      bottom
      absolute
      dark
      timeout="-1"
      transition="slide-y-reverse-transition"
    >
      <div class="text-center" v-text="$trans('Drop files to upload')"></div>
    </v-snackbar>
    <app-content-file-drop-area-dialog v-model="dialog"></app-content-file-drop-area-dialog>
    <div
      :class="{ 'file-upload__drop-area--is-hovering' : drag.isOver }"
      class="file-upload__drop-area fill-height"
      @dragend="onDragEnd"
      @dragover.prevent="onDragOver"
      @dragleave.prevent="onDragLeave"
      @drop.prevent="onDragEnd"
    >
      <app-content v-bind="$attrs">
        <slot></slot>
      </app-content>
    </div>
  </div>
</template>

<script>
import { drag } from '@/modules/File/config/upload';

export default {
  name: 'AppContentFileDropArea',

  data: () => ({
    drag,
    dialog: false,
    snackbar: false,
  }),

  methods: {
    onDragOver () {
      this.drag.flagAsEnteredArea();
      this.snackbar = true;
    },

    onDragLeave () {
      this.drag.flagAsLeftArea();
      this.snackbar = false;
    },

    onDragEnd (e) {
      this.drag.setFiles(e.dataTransfer.files);
      this.drag.flagAsLeftArea();
      this.snackbar = false;
      this.dialog = true;
      this.emitDropped(e);
    },

    emitDropped (event) {
      const files = this.drag.getFiles();
      // this.$emit('file:drop', { files, event });
    },
  },
};
</script>

<style lang="scss">
.file-upload__drop-area {
  border: 2px dashed transparent;
  border-radius: 0;
  display: flex;
  height: 100%;
  position: relative;
  transition: $global-transition;

  &--is-hovering {
    background: rgba(0, 0, 0, 0.25);
    border: 3px solid var(--v-primary-base);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>
