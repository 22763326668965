<template>
  <v-img
    v-bind="$attrs"
    :height="height"
    :lazy-src="$app.appMode!='DATA_ATELIER' ? logo : logoDataAtelier"
    :src="$app.appMode!='DATA_ATELIER' ? logo : logoDataAtelier"
    :width="width"
  ></v-img>
</template>

<script>
import logo from '@/assets/logo.png';
import logoDataAtelier from '@/assets/logo.data.atelier.svg';

export default {
  name: 'AppBrand',

  props: {
    width: {
      type: [ String, Number ],
      default: '100%',
    },
    height: {
      type: [ String, Number ],
      default: 'auto',
    },
  },

  data: () => ({
    logo,
    logoDataAtelier
  }),
};
</script>
