import StoreState from '@core/resources/StoreState';
import { isNil, sumBy, isEmpty } from 'lodash';

export const state = new StoreState({
  type: null,
  well: {},
  data: {
    labels: [],
    values: [],
  },
  others: [],
  descriptors: [],
  meta: {
    wells: [],
    total: 0,
    settings: {},
    currentSettings: {
      curation_info: 'None',
    },
    current: 'lithologies',
    currentDescriptor: 'lithologies',
    isFetchingSummary: false,
  },
  isSingle () {
    return this.type === 'single';
  },
  isSummary () {
    return this.type === 'summary';
  },
  hasOthers () {
    return !isEmpty(this.others);
  },
  getOthersPercent () {
    return `${Math.round(sumBy(this.others, 'percent'))}%`;
  },
  hasData () {
    return !isEmpty(this.meta.settings);
  },
});

export const getters = {
  ...StoreState.mapDefaultGetters(),
  piechart: state => state,
  descriptors: state => state.descriptors,
  well: state => state.well,
  meta: state => state.meta,
  settings: state => state.meta.settings,
  currentSettings: state => state.meta.currentSettings,
  isSingle: state => state.isSingle(),
  isSummary: state => state.isSummary(),
};

export const mutations = {
  ...StoreState.mapDefaultMutations(),
  SHOW (state) {
    state.shown = true;
  },
  HIDE (state) {
    state.shown = false;
  },
  SET_SHOWN (state, isShown) {
    state.shown = isShown;
  },
  UNSET_DATA (state) {
    state.data = {};
  },
  SET_DATA (state, data) {
    const settings = data.projects
      .map(i => i.attributes?.project_settings?.WEBGIS_SETTINGS?.pie_chart_settings)
      .filter(i => !isNil(i)).pop();

    state.data = {
      ...data.data,
      labels: data.data.labels || [],
      values: data.data.values || [],
    };
    state.type = data.type;
    state.well = data.data;
    state.meta.total = data.total;
    state.meta.settings = settings;
    state.meta.currentSettings = settings?.[state.meta.current] ?? state.meta.currentSettings;
    state.descriptors = isEmpty(settings) ? [] : Object.keys(settings)
      .map(key => ({ value: key, text: settings[key].title, meta: settings[key] }));

    state.others = (data.data?.other_labels ?? [])
      .map((l, i) => ({ text: l, percent: data.data?.other_values[i] }));

    state.meta.wells = data.wells;
  },
  SET_CURRENT_DESCRIPTOR (state, descriptor) {
    state.meta.currentDescriptor = descriptor;
    state.meta.currentSettings = state.meta.settings[descriptor];
  },
  SET_ITEMS (state, items) {
    state.charts.pie = items;
  },
  START_LOADING (state) {
    state.loading = true;
  },
  STOP_LOADING (state) {
    state.loading = false;
  },
};

export const actions = {
  ...StoreState.mapDefaultActions(),
  show ({ commit }) {
    commit('SHOW');
  },
  hide ({ commit }) {
    commit('HIDE');
  },
  setShown ({ commit }, isShown) {
    commit('SET_SHOWN', isShown);
  },
  setData ({ commit }, data) {
    commit('UNSET_DATA');
    commit('SET_DATA', data);
  },
  setItems ({ commit }, data) {
    commit('SET_ITEMS', data);
  },
  startLoading ({ commit }) {
    commit('START_LOADING');
  },
  stopLoading ({ commit }) {
    commit('STOP_LOADING');
  },
  setCurrentDescriptor ({ commit }, descriptor) {
    commit('SET_CURRENT_DESCRIPTOR', descriptor);
  },
};

export default {
  namespaced: true,
  name: 'viz/piechart',
  state,
  getters,
  mutations,
  actions,
};
