import marked from 'marked';
import { kebabCase, toLower } from 'lodash';
import { sortRoutes } from '@core/utils';

const files = [];
const router = {};

let maxDepth = 0;

const requireItems = require.context(
  // The relative path of the components folder
  '@',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /(modules\/Datawarehouse\/docs)(\/?)([a-zA-Z0-9-_]+(.*)).*\.md$/,
);

requireItems.keys().sort().forEach(filePath => {
  const content = marked(requireItems(filePath));

  const filename = filePath.replace(new RegExp(/\.md/), '');
  const urlPath = `${filename === 'index' ? '' : filename}`
    .replace(/(modules\/Datawarehouse\/docs)\//, '')
    .replace('./', '/')
    .replace(/\/_/g, '/:');
  const path = urlPath.replace(/\/index$/, '');
  const depth = path.match(/\//g).length;
  const key = `depth${depth}`;

  const fileName = filePath
    .split('/')
    .pop()
    .replace(/\.[^/.]+$/, '')
    .replace(/^\s\.\/(.*)\.\w+$/, '$1')
    .replace(/[0-9]{2}\.\s/, '');
  const slug = kebabCase(toLower(fileName));
  const title = fileName;
  const name = 'documentation';
  const to = { name, hash: `#${slug}` };

  const route = {
    to,
    slug,
    path,
    title,
    content,
    filePath,
    children: [],
    hasChild: () => false,
    name: JSON.stringify(to),
  };

  maxDepth = depth > maxDepth ? depth : maxDepth;

  if (Array.isArray(router[key])) {
    router[key].push(route);
  } else {
    router[key] = [ route ];
  }
});

files.push(...router.depth1);

for (let idx = maxDepth; idx > 1; idx--) {
  const preIdx = idx - 1;
  router[`depth${idx}`].forEach(route => {
    let parent = router[`depth${preIdx}`].find(pre => route.path.startsWith(`${pre.path}/`));
    if (parent) {
      parent.children.push(route);
    } else {
      parent = {
        path: route.path.substring(0, route.path.lastIndexOf('/')),
        children: [ route ],
      };
      router[`depth${preIdx}`].push(parent);
    }

    parent.children = sortRoutes(parent.children);
    parent.hasChild = () => parent.children.length > 0;
  });
}

export default sortRoutes(files);
