import APP from '@core/enums/App';
import { camelCase, startCase } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  head () {
    return {
      title: this.$_title,
      titleTemplate: `%s | ${APP.TITLE}`,

      meta: [
        this.$_description ? {
          vmid: 'description',
          name: 'description',
          content: this.$_description,
        } : {},
      ],

      changed: info => {
        this.setMeta(info);
      },
    };
  },

  computed: {
    ...mapGetters({
      metaTitle: 'meta/title',
      metaAppTitle: 'meta/apptitle',
      metaDescription: 'meta/description',
    }),

    /**
     * The meta info object.
     *
     * @return {Object}
     */
    meta () {
      return {
        title: this.metaTitle,
        apptitle: this.metaAppTitle,
        description: this.metaDescription,
      };
    },

    /**
     * The mutated title value.
     *
     * @return {string}
     */
    $_title () {
      return this.title ?? startCase(camelCase(
        this.$route.meta.title ?? this.$route.name,
      ));
    },

    /**
     * The mutated description value.
     *
     * @return {string}
     */
    $_description () {
      return this.description || this.$route.meta.description;
    },
  },

  methods: {
    ...mapActions({
      setMeta: 'meta/set',
    }),
  },
};
