import { BASE_URL } from '@/config/api';

export const INTUITION_URL = `${BASE_URL}/data-aggregations/tsne/`;
export const INTUITION_MODELS_BY_PROJECT_ID = id => `${BASE_URL}/data-aggregations/tsne/${id}/`;
export const INTUITION_MODELS_URL = `${BASE_URL}/data-aggregations/intuition-summary/`;
export const INTUITION_SAMPLES_URL = `${BASE_URL}/data-aggregations/intuition-vectors/sample/`;
export const INTUITION_FETCH_VECTOR = `${BASE_URL}/data-aggregations/intuition-vectors/`;

export default Object.freeze({
  INTUITION_URL,
  INTUITION_MODELS_URL,
  INTUITION_SAMPLES_URL,
  INTUITION_MODELS_BY_PROJECT_ID,
  INTUITION_FETCH_VECTOR,
});
