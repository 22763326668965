var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-3"},[_c('data-source-tray-filter',{staticClass:"mr-2",attrs:{"width":"400px"},on:{"update:selected":_vm.changedDataSource}}),_c('buckets-tab-chip-group',{attrs:{"items":_vm.image.meta.buckets.items,"loading":_vm.image.searching},on:{"click:tab":_vm.selectBucket},model:{value:(_vm.image.meta.buckets.selected),callback:function ($$v) {_vm.$set(_vm.image.meta.buckets, "selected", $$v)},expression:"image.meta.buckets.selected"}}),_c('images-data-iterator',{attrs:{"dense":_vm.image.meta.dense,"headers":_vm.image.headers,"hide-default-footer":_vm.image.meta.hideDefaultFooter,"items-per-page":_vm.image.pagination.itemsPerPage,"items-per-row":_vm.image.meta.itemsPerRow,"items":_vm.image.items,"loading":_vm.image.loading,"mobile-breakpoint":_vm.image.meta.mobileBreakpoint,"options":_vm.image.options,"server-items-length":_vm.image.pagination.total,"show-select":_vm.image.meta.showSelect},on:{"update:options":[function($event){return _vm.$set(_vm.image, "options", $event)},_vm.updateList],"download":_vm.downloadImage},scopedSlots:_vm._u([{key:"button.link",fn:function(ref){
var imageItem = ref.item;
return [_c('v-btn',{staticClass:"mb-4",attrs:{"to":{
          name: 'reports.image',
          params: { slug: _vm.$slug, image: imageItem.id },
          query: { project_id: imageItem.attributes.project_id },
        },"exact":"","dark":"","small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-magnify-plus-outline")]),_vm._v(" "+_vm._s(_vm.$trans('Zoom in'))+" ")],1)]}},(_vm.can('report_spreadsheet_viewer') && _vm.htmlFileCheck)?{key:"button.go-to-page",fn:function(ref){
        var imageItem = ref.item;
return [_c('v-btn',{staticClass:"mb-4",attrs:{"to":{
          name: 'reports.single',
          params: { slug: imageItem.attributes.doc_ref },
          query: {
            page_num: imageItem.attributes.page_num,
            project_type: imageItem.attributes.project_type,
            back: _vm.$route.path,
          },
        },"dark":"","small":"","outlined":""},on:{"click":function($event){return _vm.goToTab(imageItem)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-file-eye-outline")]),_vm._v(" "+_vm._s(_vm.$trans("Go to page {page}", { page: imageItem.attributes.page_num, }))+" ")],1)]}}:null,{key:"footer",fn:function(){return [(_vm.image.loaded)?_c('div',{staticClass:"text-center caption"},[_c('div',{staticClass:"muted--text",domProps:{"textContent":_vm._s(_vm.$trans_choice('{count} of {total} item', _vm.image.pagination.total, {
            count: _vm.image.size(),
            total: _vm.image.pagination.total,
          }))}})]):_vm._e(),(_vm.image.moreToLoad())?_c('v-btn',{staticClass:"my-3",attrs:{"loading":_vm.image.loading,"disabled":_vm.image.loading,"depressed":"","block":"","large":""},on:{"click":_vm.loadMoreImages}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Load more'))}})]):_vm._e()]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }