<template>
  <v-text-field
    v-bind="computedProps"
    v-on="$listeners"
    @click:append="toggleRevealText"
  ></v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: 'AppPasswordField',

  extends: VTextField,

  props: {
    revealed: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: 'mdi-eye-outline',
    },
    appendIconRevealed: {
      type: String,
      default: 'mdi-eye-off-outline',
    },
    type: {
      type: String,
      default: 'password',
    },
  },

  data: vm => ({
    reveal: vm.revealed,
  }),

  computed: {
    computedProps () {
      return {
        ...this.$props,
        type: this.reveal ? 'text' : 'password',
        appendIcon: this.reveal ? this.appendIconRevealed : this.appendIcon,
      };
    },
  },

  methods: {
    toggleRevealText () {
      this.reveal = !this.reveal;
    },
  },
};
</script>
