import { BASE_URL } from '@/config/api';

export const GET_GENERAL_DASHINFO_VALUES_LIST = `${BASE_URL}/datalake/dashinfo/values/`;
export const GET_GENERAL_DASHINFO_HEADERS_LIST = `${BASE_URL}/datalake/dashinfo/headers/`;
export const EXPORT_GENERAL_DASHINFO_VALUES = `${BASE_URL}/datalake/dashinfo/values/export-csv/`;

export default Object.freeze({
  GET_GENERAL_DASHINFO_VALUES_LIST,
  GET_GENERAL_DASHINFO_HEADERS_LIST,
  EXPORT_GENERAL_DASHINFO_VALUES,
});
