import Manifest from '@core/manifests/Manifest';
import { groupBy } from 'lodash';

export const LOCALE_TYPE_CORE = 'core';
export const LOCALE_TYPE_SRC = 'src';

const requireLocale = require.context(
  // The relative path of the routes folder
  '@/locales',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base names
  /[A-Za-z0-9-_,\s]+\.json$/,
);

const requireModuleLocale = require.context(
  // The relative path of the routes folder
  '@/modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base names
  /[a-zA-Z0-9-_]\/locales\/[A-Za-z0-9-_,\s]+\.json$/,
);

export const locales = [
  ...requireLocale
    .keys().sort()
    .map(file => ({
      type: LOCALE_TYPE_SRC,
      path: file,
      trans: requireLocale(file),
      module: file.replace('./', '').split('/').shift().toLowerCase(),
    })),
  ...requireModuleLocale
    .keys().sort()
    .map(file => ({
      type: LOCALE_TYPE_SRC,
      path: file,
      trans: requireModuleLocale(file),
      module: file.replace('./', '').split('/').shift().toLowerCase(),
    })),
];

class LocaleManifest extends Manifest {
  constructor (attributes = {}) {
    super(attributes);
  }

  setItems (items) {
    this.items = {};

    const grouped = groupBy(items.map(item => this.makeItem(item)), 'lang');
    Object.keys(grouped).forEach(key => {
      this.items[key] = grouped[key].map(i => i.trans)
        .reduceRight((result, current) => ({ ...result, ...current }), {});
    });

    return this;
  }

  getItems () {
    return this.items;
  }

  makeItem (item) {
    return {
      trans: item.trans,
      lang: item.path.match(/([A-Za-z0-9-_]+)\./i).pop(),
    };
  }
}

export default new LocaleManifest({ items: locales });
