<template>
  <app-content>
    <meta-tag :title="$trans('Report Viewer')"></meta-tag>
    <v-row justify-center class="fill-height">
      <v-col cols="12" lg="7">
        <v-layout fill-height column>
          <div class="flex-shrink-0">
            <app-header-loading-state></app-header-loading-state>
          </div>
          <div class="flex-grow-1">
            <panzoom-pdf-previewer-loading-state></panzoom-pdf-previewer-loading-state>
          </div>
        </v-layout>
      </v-col>
      <v-col cols="12" lg="5">
        <report-viewer-meta-info-loading-state></report-viewer-meta-info-loading-state>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import Datalake from '@/modules/Datalake/resources/Datalake';

export default {
  layout: 'admin',

  data: () => ({
    datalake: new Datalake,
  }),

  async created () {
    await this.datalake.listByWellId(this.$route.query.wellId);
    const first = this.datalake.items[0] || null;
    const withoutPageNum = this.$route.query.noPageNum;
    const query = {
      wellId: this.$route.query.wellId,
      page_num: withoutPageNum ? null : first.page_num,
    };

    this.$router.replace({
      name: 'reports.single',
      params: { slug: first.id },
      query,
    });
  },
};
</script>
