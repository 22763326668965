<template>
  <app-content>
    <meta-tag :title="$trans('Subscriptions')"></meta-tag>

    <v-row class="mt-10">
      <v-col
        cols="12"
        lg="10"
        class="mx-auto text-center"
      >
        <div class="text-center mx-auto mt-5 mb-15">
          <h1 v-text="$trans('Choose a plan')"></h1>
        </div>

        <subscription-plan-group
          :items="plans"
          @click:item="onItemClick"
        ></subscription-plan-group>

        <div class="caption my-4">
          <p
            v-html="$trans('See the features matrix below for full comparison.')"
          ></p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="10" class="mx-auto text-center">
        table matrix
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
export default {
  layout: 'web',

  data: () => ({
    plans: [
      {
        title: 'Apprentice',
        price: 'Free',
        overline: 'for 14 days',
        text: 'Up to 5,000 pages per month',
        isFeatured: true,
        buttonColor: 'secondary',
        buttonText: 'Start 14-day trial',
        list: [
          {
            icon: 'mdi-check-circle',
            text: '<strong>160</strong> pages guaranteed processing rate per day',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Upload pdf file formats',
          },
          {
            icon: 'mdi-check-circle',
            text: '1 project, 1 user limit',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Deploy to Iraya Cloud',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Silver Support',
          },
        ],
      },
      {
        title: 'Bolt',
        price: '$1,000',
        overline: 'per month',
        text: 'Up to 30,000 pages per month',
        isFeatured: false,
        buttonColor: 'primary',
        buttonText: 'Choose plan',
        list: [
          {
            icon: 'mdi-check-circle',
            text: '<strong>1,000</strong> pages guaranteed processing rate per day',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Upload pdf, office, and image file formats',
          },
          {
            icon: 'mdi-check-circle',
            text: '3 projects limit, 3 users limit',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Deploy to Iraya Cloud',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Silver Support',
          },
        ],
      },
      {
        title: 'Forge',
        price: '$10,000',
        overline: 'per month per license',
        text: 'Up to 100,000 pages per month',
        isFeatured: false,
        buttonColor: 'primary',
        buttonText: 'Choose plan',
        list: [
          {
            icon: 'mdi-check-circle',
            text: '<strong>33,000</strong> pages guaranteed processing rate per day',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Upload pdf, office, and image file formats',
          },
          {
            icon: 'mdi-check-circle',
            text: 'up to 10 projects, 5 users limit',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Deploy to Iraya managed cloud or self-hosted',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Gold Support',
          },
        ],
      },
      {
        title: 'Enterprise',
        text: '100,000 pages and up per month',
        isFeatured: false,
        buttonColor: 'primary',
        buttonText: 'Talk to us',
        list: [
          {
            icon: 'mdi-check-circle',
            text: 'Feature and functional demo/recorded webinar with sales team',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Upload pdf, office, and image file formats',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Up to X projects, up to N users',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Deploy to Iraya Cloud or self-hosted cloud',
          },
          {
            icon: 'mdi-check-circle',
            text: 'Platinum Support',
          },
        ],
      },
    ],
  }),

  methods: {
    onItemClick (item) {
      console.log('clicked:', item);
    },
  },
};
</script>
