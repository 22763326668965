import { render, staticRenderFns } from "./BboxOcrIndicator.vue?vue&type=template&id=4b07479c&scoped=true&"
import script from "./BboxOcrIndicator.vue?vue&type=script&lang=js&"
export * from "./BboxOcrIndicator.vue?vue&type=script&lang=js&"
import style0 from "./BboxOcrIndicator.vue?vue&type=style&index=0&id=4b07479c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b07479c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
