import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Theme from '@root/theme.json';

Vue.use(Vuetify);

const theme = JSON.parse(localStorage.getItem('theme:active')) || Theme.default;

export default new Vuetify({
  theme: {
    name: theme.name,
    dark: theme.isDark,
    themes: {
      light: theme.light,
      dark: theme.dark,
    },
    options: {
      customProperties: true,
    },
  },
});
