<template>
  <v-skeleton-loader class="v-skeleton-loader--relative" v-bind="$props">
    <div class="v-skeleton-loader__thumbnail v-skeleton-loader__bone">
      <slot></slot>
    </div>
  </v-skeleton-loader>
</template>

<script>
import { VSkeletonLoader } from 'vuetify/lib';

export default {
  name: 'PanzoomImageThumbnailLoadingState',

  extends: VSkeletonLoader,
};
</script>
