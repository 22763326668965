import multiguard from 'vue-router-multiguard';
import RedirectToDashboardIfLoggedIn from '@core/router/middleware/RedirectToDashboardIfLoggedIn';
import RedirectToHomeIfAuthUnsupported from '@core/router/middleware/RedirectToHomeIfAuthUnsupported';
import IsAuthenticated from '@core/router/middleware/IsAuthenticated';
import { camelCase, startCase, has } from 'lodash';
import { sortRoutes } from '@core/utils';

const modules = [];
const router = {};

let maxDepth = 0;

const requireItems = require.context(
  // The relative path of the routes folder
  '@core/modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base route filenames
  /[a-zA-Z0-9-_]+\/pages\/[a-zA-Z0-9-_]+(\/?)([a-zA-Z0-9-_]+(.*))[a-zA-Z0-9-_]+.vue$/,
);

requireItems.keys().sort().forEach(fileName => {
  const componentFile = requireItems(fileName);
  const filename = fileName.replace(new RegExp(/\.vue$/), '');
  const urlPath = `${filename === 'index' ? '' : filename}`
    .replace(/\w+\/pages\//, '')
    .replace('./', '/')
    .replace(/\/_/g, '/:');
  const path = urlPath.replace(/\/index$/, '');
  const depth = path.match(/\//g).length;
  const key = `depth${depth}`;
  const name = (has(componentFile.default, 'routename') && componentFile.default.routename)
    || `${urlPath.split('/').join('.')}`
      .replace('.', '')
      .replace(':id', 'show')
      .replace('show.index', 'show')
      .replace('show.edit', 'edit')
      .replace(':slug', 'single')
      .replace('single.index', 'single')
      .replace('single.edit', 'edit');

  const filePath = `modules/${fileName.replace('./', '')}`;

  const layout = (componentFile.default ? componentFile.default.layout : componentFile.layout) || 'default';

  const isIndex = (filename.match(/index$/) && filename.match(/index$/)[0]) === 'index';
  const isAdminLayout = layout === 'admin';
  const isAuthLayout = layout === 'auth';
  const isAuthenticatable = isAdminLayout || !!componentFile.default.authenticable;

  const component = () => import(`@core/${filePath}`);
  const defaultComponent = () => import('@/layouts/default.vue');

  const title = startCase(camelCase(name.replace('.index', '')));
  const description = has(componentFile.default, 'head.meta')
    ? componentFile.default.head.meta.find(meta => meta.vmid === 'description').content
    : null;

  const isTitleOverrideable = name.endsWith('.single') || name.endsWith('.single.edit')
    || name.endsWith('.single.index') || name.endsWith('.show') || name.endsWith('.create');

  const componentFileMeta = has(componentFile.default, 'meta')
    ? componentFile.default.meta : null;

  const meta = {
    ...componentFileMeta,
    description,
    isAuthenticatable,
    isTitleOverrideable,
    title: isIndex ? `All ${title}` : title,
    layout: isIndex ? 'default' : layout,
    isSlug: name.endsWith('.show'),
    children: [ name ],
  };

  const props = route => ({
    id: route.params.id,
    slug: route.params.slug,
  });

  const authGuard = isAuthLayout ? [
    RedirectToDashboardIfLoggedIn,
    RedirectToHomeIfAuthUnsupported,
  ] : [];
  const adminGuard = isAuthenticatable ? [
    IsAuthenticated,
  ] : [];
  const beforeEnter = multiguard([ ...authGuard, ...adminGuard ]);

  const route = {
    path,
    props,
    beforeEnter,
    name: isIndex ? name.replace('.index', '') : name,
    redirect: isIndex ? path : null,
    component: isIndex ? defaultComponent : component,
    children: isIndex ? [{
      path,
      name,
      props,
      component,
      children: [],
      meta: { ...meta, ...{ layout }, isTitleOverrideable: true },
    }] : [],
    meta: { ...meta, ...{ title } },
  };

  maxDepth = depth > maxDepth ? depth : maxDepth;

  if (Array.isArray(router[key])) {
    router[key].push(route);
  } else {
    router[key] = [ route ];
  }
});

modules.push(...router.depth1);

for (let idx = maxDepth; idx > 1; idx--) {
  const preIdx = idx - 1;
  router[`depth${idx}`].forEach(route => {
    let parent = router[`depth${preIdx}`].find(pre => route.path.startsWith(`${pre.path}/`));
    if (parent) {
      parent.children.push(route);
    } else {
      parent = {
        path: route.path.substring(0, route.path.lastIndexOf('/')),
        children: [ route ],
      };
      router[`depth${preIdx}`].push(parent);
    }

    parent.children = sortRoutes(parent.children);
  });
}

export default sortRoutes(modules);
