<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        flat tile
        class="image-preview ov-hidden"
        color="transparent"
      >
        <slot name="top"></slot>
        <v-card
          :height="height"
          :color="color"
          flat tile
          class="image-preview__window ov-hidden"
          width="100%"
          @scroll="onScroll"
        >
          <panzoom-image-thumbnail-loading-state
            v-if="loading"
            :height="height"
          >
            <v-layout fill-height align-center justify-center>
              <slot name="loading">
                <div>
                  <v-icon left color="white">mdi-loading mdi-spin</v-icon>
                  <span class="white--text" v-text="$trans('Loading preview')"></span>
                </div>
              </slot>
            </v-layout>
          </panzoom-image-thumbnail-loading-state>
          <div
            ref="image-preview"
            class="image-preview__container pa-1"
            title="image preview container"
            @wheel="onMouseWheel"
            @dblclick.prevent="onDblclick"
          >
            <div class="image-preview__inner-container">
              <slot></slot>
            </div>
          </div>
        </v-card>

        <template v-if="showZoomBar">
          <v-fade-transition>
            <v-layout v-show="hover" column class="image-preview__zoombar ma-2">
              <slot name="zoom-bar">
                <v-tooltip right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="zoomInBtnDisabled"
                      min-width="32" width="32"
                      min-height="32"
                      height="32" class="ma-1"
                      v-on="on"
                      @click="zoomIn"
                      title="button zoom in"
                    >
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$trans('Zoom in')"></span>
                </v-tooltip>
                <v-tooltip right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="zoomOutBtnDisabled"
                      min-width="32" width="32"
                      min-height="32"
                      height="32" class="ma-1"
                      v-on="on"
                      @click="zoomOut"
                      title="button zoom out"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$trans('Zoom out')"></span>
                </v-tooltip>
                <v-tooltip right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="zoomResetBtnDisabled"
                      min-width="32" width="32"
                      min-height="32"
                      height="32" class="ma-1"
                      v-on="on"
                      @click="zoomReset"
                      title="button zoom reset"
                    >
                      <v-icon small>mdi-magnify-scan</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$trans('Reset zoom')"></span>
                </v-tooltip>
              </slot>
              <slot name="zoom-bar.append"></slot>
            </v-layout>
          </v-fade-transition>
        </template>

        <template v-if="showSlider">
          <v-fade-transition>
            <v-layout
              v-show="hover" class="image-preview__slider ma-4 px-4 text-center"
              align-center
              justify-center
            >
              <v-card
                elevation="1" width="80%"
                class="image-preview__slider-card mx-auto rounded"
                @wheel.prevent="onMouseWheel"
              >
                <v-card-text class="py-1 px-2">
                  <v-slider
                    title="zoom slider"
                    v-model="zoom"
                    max="200" min="1"
                    hide-details
                  ></v-slider>
                </v-card-text>
              </v-card>
            </v-layout>
          </v-fade-transition>
        </template>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import Panzoom from '@panzoom/panzoom';

export default {
  name: 'PanzoomImagePreviewer',

  props: {
    alwaysZoomFromCenter: {
      type: Boolean,
      default: false,
    },

    zoomOnWheel: {
      type: Boolean,
      default: false,
    },

    zoomOnDblclick: {
      type: Boolean,
      default: false,
    },

    resetZoomLevel: {
      type: Number,
      default: 100,
    },

    color: {
      type: String,
      default: 'grey lighten-2',
    },

    options: {
      type: Object,
      default: () => {},
    },

    height: {
      type: [ String, Number ],
      default: '600',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    slider: {
      type: Boolean,
      default: false,
    },

    zoomBar: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    scrollTimer: null,
    panzoom: null,
    zoom: 100,
  }),

  computed: {
    computedOptions () {
      return {
        startScale: 1,
        cursor: 'default',
        smoothScroll: true,
        excludeClass: 'panzoom-exclude',
        setTransform: (elem, { x, y, scale }) => this.setTransform(elem, { x, y, scale }),
        ...this.options,
      };
    },

    panzoomOptions () {
      return this.panzoom?.getOptions() ?? {};
    },

    showSlider () {
      return this.slider;
    },

    showZoomBar () {
      return this.zoomBar;
    },

    zoomInBtnDisabled () {
      return this.zoom >= (this.panzoomOptions.maxScale * 100);
    },

    zoomOutBtnDisabled () {
      return this.zoom <= (this.panzoomOptions.minScale * 100);
    },

    zoomResetBtnDisabled () {
      return this.zoom === this.resetZoomLevel;
    },
  },

  watch: {
    zoom (zoomLevel) {
      this.panzoom.zoom(zoomLevel / 100, { animate: true });
    },
  },

  mounted () {
    this.initPanzoom();
    this.setInitialZoomLevel();
  },

  methods: {
    initPanzoom () {
      this.panzoom = this.$refs['image-preview'] && Panzoom(
        this.$refs['image-preview'],
        this.computedOptions,
      );
    },

    setInitialZoomLevel () {
      if (this.panzoom) {
        this.panzoom.zoom(this.computedOptions.initialZoom || 1, { silent: true });
      }
    },

    zoomIn () {
      const zoom = this.panzoom.zoomIn();
      this.setZoomToPercent(zoom.scale);
      this.emitEvent('zoom:in');
    },

    zoomOut () {
      const zoom = this.panzoom.zoomOut();
      this.setZoomToPercent(zoom.scale);
      this.emitEvent('zoom:out', { zoom });
    },

    zoomReset () {
      if (this.zoomResetBtnDisabled) {
        this.onDblclick();
      } else {
        const zoom = this.panzoom.reset();
        this.setZoomToPercent(zoom.scale);
      }
      this.emitEvent('zoom:reset');
    },

    setZoomToInitialLevel () {
      const zoom = this.panzoom.reset();
      this.setZoomToPercent(zoom.scale);
    },

    onMouseWheel (event) {
      if (this.zoomOnWheel) {
        this.zoomWithWheel(event);
      }

      if (event.deltaY < 0) {
        this.emitEvent('wheel:up', { event });
      } else if (event.deltaY > 0) {
        this.emitEvent('wheel:down', { event });
      }
    },

    zoomWithWheel (e) {
      const zoom = this.panzoom.zoomWithWheel(e);
      this.setZoomToPercent(zoom.scale);
    },

    onDblclick () {
      if (this.zoomOnDblclick) {
        const zoom = (this.zoom / 100) <= 1 ? this.panzoom.zoom(2) : this.panzoom.zoom(1);
        this.setZoomToPercent(zoom.scale);
      }
    },

    setZoomToPercent (zoomLevel) {
      this.zoom = zoomLevel * 100;
    },

    getZoom () {
      return this.zoom;
    },

    setTransform (elem, { x, y, scale }) {
      if (!this.alwaysZoomFromCenter) {
        if (scale > 1) {
          this.panzoom.setStyle('transform-origin', '0 0');
        } else {
          this.panzoom.setStyle('transform-origin', '50% 50%');
        }
      } else {
        this.panzoom.setStyle('transform-origin', '50% 50%');
      }

      this.panzoom.setStyle('transform', `scale(${scale}) translate(${x}px, ${y}px)`);
    },

    onScroll (event) {
      this.emitEvent('scroll', { event });

      if (this.scrollTimer) {
        clearTimeout(this.scrollTimer);
      }

      this.scrollTimer = setTimeout(() => {
        this.emitEvent('scrolled', { event });
      }, 150);
    },

    emitEvent (name, payload = null) {
      this.$emit(name, {
        ...payload,
        scale: this.getZoom(),
        panzoom: this,
      });
    },
  },
};
</script>

<style lang="scss">
.image-preview {
  & .image-preview__window {
    padding: 8px;
  }

  &__container {
    height: 100%;
  }

  &__slider {
    bottom: 0;
    position: absolute;
    width: 100%;
  }

  &__zoombar {
    left: 0;
    position: absolute;
    top: 0;
  }
}
</style>
