<template>
  <div v-bind="$attrs">
    <slot v-bind="{ selected }"></slot>
  </div>
</template>

<script>
export default {
  name: 'AppClickGroup',

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    selected: {
      get () {
        return this.value;
      },
      set (selected) {
        this.$emit('input', selected);
      },
    },

    children () {
      return this.$children.map(({ clicked }, key) => ({ key, clicked }));
    },
  },

  watch: {
    selected (selected) {
      this.$emit('input', selected);
    },
  },

  mounted () {
    this.setDefaultSelections();
    this.watchChildNodes();
  },

  methods: {
    setDefaultSelections () {
      this.$children
        .filter((child, key) => this.value.includes(key))
        .forEach(child => child.select());
    },

    watchChildNodes () {
      this.$children.forEach(child => {
        child.$on('click:item', () => {
          if (!this.multiple) {
            this.unselectAllChild();
            child.select();
          }

          this.selected = this.children
            .filter(child => child.clicked)
            .map(({ key }) => key);
        });
      });
    },

    unselectAllChild () {
      if (!this.multiple) {
        this.selected = [];
        this.$children.forEach(child => {
          child.unselect();
        });
      }
    },
  },
};
</script>
