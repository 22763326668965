import { size, isString } from 'lodash';

export default class Config {
  constructor (options = {}) {
    this.selected = options.selected ?? null;
    this.items = options.items ?? [];

    this.setLoadingStates(options.loading);
    this.setOptions(options);
  }

  setOptions (options) {
    this.options = this.mergeToDefaultOptions(options);

    Object.keys(this.options).forEach(option => {
      this[option] = options[option];
    });
  }

  mergeToDefaultOptions (options) {
    return {
      selected: null,
      items: [],
      ...options,
    };
  }

  find (value, key = 'id') {
    return (this.items ?? []).find(i => i?.[key] === value);
  }

  getHash () {
    return `${Date.now}-${size(this.selected ?? this.items)}`;
  }

  setItems (items) {
    this.items = items;
  }

  getFirstItem () {
    return this.items?.[0];
  }

  getItems () {
    return this.items;
  }

  setActive (selected) {
    this.selected = selected;
  }

  getActive () {
    return this.selected;
  }

  unsetActive () {
    this.selected = null;
  }

  getActiveProperty (property) {
    const selected = isString(this.selected) ? this.find(this.selected) : this.selected;

    return selected?.[property];
  }

  setLoadingStates (loading = false) {
    this.loading = loading;
    this.loaded = !this.loading;
  }

  startLoading () {
    this.loaded = false;
    this.loading = true;

    return this;
  }

  stopLoading () {
    this.loading = false;
    this.loaded = true;

    return this;
  }

  isLoading () {
    return this.loading;
  }
}
