import Auth from '@/modules/Auth/resources/Auth';

export default async function (to, from, next) {
  const auth = new Auth;

  if (auth.isTokenAboutToExpire()) {
    await auth.refreshToken();
  }

  return next();
}
