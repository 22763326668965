<template>
  <div>
    <template v-if="nonObtrusive">
      <v-snackbar
        :value="isShown"
        light outlined
        bottom right
        timeout="-1"
        vertical="vertical"
      >
        <slot name="title"></slot>
        <slot></slot>
        <template v-slot:action>
          <slot name="action"></slot>
        </template>
      </v-snackbar>
    </template>
    <template v-else>
      <v-dialog
        :value="isShown"
        width="420"
      >
        <v-card>
          <v-card-title>
            <slot name="title"></slot>
          </v-card-title>
          <v-card-text class="pt-6">
            <slot name="illustration"></slot>
            <slot></slot>
          </v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <slot name="action"></slot>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppCacheBusterContainer',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    nonObtrusive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isShown: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
