import moment from 'moment';

export const toDateFromNow = function (date, fromFormat = null) {
  return moment(date, fromFormat).fromNow();
};

export const toDateTime = function (date, format = 'LL LT', fromFormat = null) {
  return moment(date, fromFormat).format(format);
};

export default {
  filters: {
    toDateFromNow,
    toDateTime,
  },

  methods: {
    toDateFromNow,
    toDateTime,
  },
};
