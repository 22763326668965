export default [
  {
    name: 'images',
    title: 'Images',
    description: 'View images information',
    icon: 'mdi-image',
    sort: 12,
    meta: {
      attrs: {
        'data-tour-step': 'welcome/onboarding.8',
        'data-tour-overlay': true,
      },
    },
  },
];
