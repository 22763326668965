<template>
  <v-alert
    v-if="hasMessage"
    :color="message.color" :type="message.type"
    dismissible
    class="text-left"
  >
    <slot>
      <div v-html="message.content"></div>
    </slot>
  </v-alert>
</template>

<script>
import { LOGIN_MESSAGE, LOGIN_MESSAGE_COLOR, LOGIN_MESSAGE_TYPE } from '@config/app';
import { isEmpty } from 'lodash';

export default {
  name: 'LoginPageGreeter',

  data: () => ({
    LOGIN_MESSAGE,
    LOGIN_MESSAGE_TYPE,
    LOGIN_MESSAGE_COLOR,
  }),

  computed: {
    hasMessage () {
      return !isEmpty(this.LOGIN_MESSAGE);
    },

    message () {
      return {
        content: this.LOGIN_MESSAGE,
        type: this.LOGIN_MESSAGE_TYPE || 'info',
        color: this.LOGIN_MESSAGE_COLOR || 'blue',
      };
    },
  },
};
</script>
