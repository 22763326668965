import { supportedQuery } from '@/modules/File/config/queries';

export const DATALAKE_TAB_UPLOADED = 'Uploaded';
export const headers = [
  { text: 'Set', value: 'id', width: 120 },
  { text: 'Owner', value: 'added_by_full_name' },
  { text: 'Total Files', value: 'total_files', align: 'right' },
  { text: 'Total Storage', value: 'storage_used', align: 'right' },
  { text: 'Project', value: 'project_name' },
  { text: 'Created', value: 'date_added' },
  { text: 'Status', value: 'status', align: 'center' },
];

export default [
  {
    name: DATALAKE_TAB_UPLOADED,
    tabKey: DATALAKE_TAB_UPLOADED.toLowerCase(),
    permission: 'uploader_manage_files',
    moduleKey: 'file',
    sort: 3,
    active: false,
    chip: false,
    eager: false,
    resource: {
      headers,
      options: {
        itemsPerPage: 100,
        sortDesc: [ true ],
        sortBy: [ 'date_added' ],
      },
      meta: {
        dense: false,
        showSelect: false,
        mobileBreakpoint: 0,
        supportedQuery,
      },
    },
    component: () => import('@/modules/File/components/Tables/UploadedTable'),
  },
];
