<template>
  <page-content>
    <v-card>
      <v-card-title>The Title of the Card</v-card-title>
      <v-card-text>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, quae.</p>
        <p>Lorem ipsum dolor sit.</p>
      </v-card-text>
    </v-card>
  </page-content>
</template>

<script>
export default {
  layout: 'admin',
};
</script>
