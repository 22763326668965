/* eslint-disable object-curly-newline */
import Config from '@core/resources/Config';

export const accountInfo = [
  { key: 'role', text: 'Role', icon: 'mdi-badge-account-horizontal-outline', class: 'text-capitalize' },
];

export const backgroundInfo = [
  { active: false, key: 'job', text: 'Job Title', icon: 'mdi-briefcase-outline' },
  { active: false, key: 'team', text: 'Team', icon: 'mdi-file-tree-outline' },
  { active: true, key: 'organization', text: 'Organization', icon: 'mdi-office-building-outline' },
].filter(i => i.active);

export default new Config({
  accountInfo,
  backgroundInfo,
});
