<template>
  <set-file-list-simple-table-empty-state
    v-if="isItemsEmpty"
  ></set-file-list-simple-table-empty-state>
  <v-simple-table v-else>
    <thead>
      <tr>
        <th><span v-text="$trans('File name')"></span></th>
        <th class="text-right"><span v-text="$trans('File size')"></span></th>
        <th><span v-text="$trans('Type')"></span></th>
        <th><span v-text="$trans('Status')"></span></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(file, i) in items" :key="i">
        <td>
          <v-tooltip right transition="slide-x-transition">
            <template v-slot:activator="{ on }">
              <a
                :href="file.raw_file_link"
                target="_blank"
                class="td-underline"
                v-on="on"
                v-text="file.name"
              ></a>
            </template>
            <!-- eslint-disable-next-line max-len -->
            <span v-text="$trans('Click to open file in your browser or download the original file.')"></span>
          </v-tooltip>
        </td>
        <td class="text-right text-no-wrap" v-text="file.size"></td>
        <td>
          <v-icon small left v-text="guessIconFromMimeType(file.mimeType)"></v-icon>
          <span class="text-uppercase" v-text="getExtensionFromMimeType(file.mimeType)"></span>
        </td>
        <td>
          <span
            :style="`color: ${file.status.color}`"
            v-text="file.status.text"
          ></span>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { isEmpty } from 'lodash';
import GetFileTypeIcon from '@core/mixins/GetFileTypeIcon';

export default {
  name: 'SetFilesListSimpleTable',

  mixins: [ GetFileTypeIcon ],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    isItemsEmpty () {
      return isEmpty(this.items);
    },
  },
};
</script>
