<template>
  <div class="system-tray__container">
    <template v-for="(item, i) in tray">
      <component
        :is="item.component"
        :id="`system-tray-${item.key}-${i}`"
        :key="`system-tray-${item.key}-${i}`"
      ></component>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppSystemTray',

  computed: {
    tray () {
      return this.$systemTray.items ?? [];
    },
  },
};
</script>
