<template>
  <v-layout
    column align-center
    class="fill-height"
  >
    <icon-messy-folder class="accent--text"></icon-messy-folder>
    <p class="my-3 body-1 muted--text" v-text="$trans('No files found')"></p>
    <slot></slot>
  </v-layout>
</template>

<script>
export default {
  name: 'SetFileListSimpleTableEmptyState',
};
</script>
