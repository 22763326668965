const requirePlugin = require.context(
  // The relative path of the components folder
  '@/modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /plugins\/index\.js$/,
);

requirePlugin.keys().sort().forEach(i => import(`@/modules/${i.replace('./', '')}`));
