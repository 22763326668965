<template>
  <v-layout column class="ma-10 align-center justify-center text-center">
    <div class="secondary--text">
      <icon-plant-with-background></icon-plant-with-background>
    </div>
    <p class="mb-3" v-text="$trans('Your invoices will show up here.')"></p>
  </v-layout>
</template>

<script>
export default {
  name: 'BillingInvoicesTabItemEmptyState',
};
</script>
