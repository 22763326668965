export const details = [
  {
    project_name: 'Project',
    totalFiles: 'Total files',
    remarks: 'Remarks',
  },
  {
    statusText: { text: 'Status', dot: true },
    createdBy: 'Created by',
    downloads: { text: 'Available Reports', permission: 'audit_report', download: true },
  },
];

export const set = {
  id: null,
  set: null,
  files: [],
  isDone: false,
  isUploading: true,
  isCancelled: false,
  isRetrying: false,
  retries: 0,
  remarks: null,
  color: null,
  project: null,
  project_id: null,
  cancelUpload () {
    this.isCancelled = true;
    this.isDone = true;
    this.isUploading = false;
  },
  retryUpload () {
    this.isRetrying = true;
    this.retries++;
  },
};

export default {
  set,
  details,
};
