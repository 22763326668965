import { BASE_URL } from '@/config/api';

export const TABLE_OCR_URL = `${BASE_URL}/table-ocr/`;
export const GET_TABLE_OCR_COPY = `${BASE_URL}/table-ocr/copy`;
export const GET_TABLE_OCR = id => `${BASE_URL}/table-ocr/${id}/`;

export default Object.freeze({
  TABLE_OCR_URL,
  GET_TABLE_OCR,
  GET_TABLE_OCR_COPY,
});
