import Billing from '@/modules/Billing/resources/Billing';

export default new class LoginHook {
  trigger () {
    this.saveBillingInfoToStore();
    this.listPaymentMethods();
  }

  saveBillingInfoToStore () {
    const billing = new Billing;

    billing.listBillingInfoAndSaveToStore();
  }

  listPaymentMethods () {
    const billing = new Billing;

    billing.listPaymentMethods();
  }
};
