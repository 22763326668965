<template>
  <v-card>
    <v-text-field
      :placeholder="$trans('API Token Key')"
      :type="type"
      :value="value"
      filled
      hide-details
      readonly
      solo
      autocomplete="off"
      v-bind="$attrs"
    >
      <template v-slot:append class="ma-0 m-0">
        <v-divider vertical class="mx-3"></v-divider>
        <v-btn
          large icon
          color="secondary"
          @click="onClickReveal"
        ><v-icon>{{ showText ? 'mdi mdi-eye-off' : 'mdi mdi-eye' }}</v-icon></v-btn>
        <v-btn
          large icon
          color="secondary"
          @click="onClickCopy"
        ><v-icon>mdi mdi-content-copy</v-icon></v-btn>
      </template>
    </v-text-field>
  </v-card>
</template>

<script>
export default {
  name: 'ApiTokenField',

  data: () => ({
    showText: false,
  }),

  computed: {
    type () {
      return this.showText ? 'text' : 'password';
    },
    value () {
      const user = this.$user.getUserFromLocalStorage();
      return user.key;
    },
  },

  methods: {
    onClickReveal () {
      this.showText = !this.showText;
    },

    onClickCopy () {
      navigator.clipboard.writeText(this.value);
      this.$store.dispatch('snackbar/show', { text: 'Token copied to clipboard' });
    },
  },
};
</script>
