<template>
  <v-card>
    <!-- TODO: Move to separate component, fix error on console -->
    <v-toolbar
      flat
      height="auto"
      color="transparent"
    >
      <v-row align="center" justify="space-between">
        <v-col cols="12" sm="4">
          <slot name="search">
            <v-badge
              v-model="ctrlIsPressed"
              bordered
              bottom
              class="s-badge d-block"
              color="dark"
              content="/"
              offset-x="28"
              offset-y="28"
              tile
              transition="fade-transition"
            >
              <v-text-field
                ref="tablesearch"
                v-model="query"
                v-shortkey="['ctrl', '/']"
                autocomplete="off"
                background-color="heading"
                class="s-text-field__search"
                clear-icon="mdi-close-circle-outline"
                clearable
                filled
                flat
                full-width
                hide-details
                single-line
                solo
                :placeholder="$trans('Search...')"
                :prepend-inner-icon="items.isSearching ? 'mdi-spin mdi-loading' : 'mdi-magnify'"
                @click:clear="search"
                @keyup="search"
                @shortkey.native="focus"
              >
              </v-text-field>
            </v-badge>
          </slot>
        </v-col>
      </v-row>
    </v-toolbar>
    <!-- TODO: Move to separate component, fix error on console -->

    <v-divider></v-divider>

    <v-data-table
      v-bind="$attrs"
      :headers="headers"
      :items="items"
      :search="search"
      :options="options"
    >
      <template v-for="(header, i) in headers" v-slot:[`item.${header.value}`]="props">
        <slot :name="`item.${header.value}`" :item="props.item">
          <td :key="i">{{ props.item[header.value] }}</td>
        </slot>
      </template>

      <template v-slot:item.action="props">
        <slot name="item.action" :item="props.item">
          <app-data-table-action-menu
            :item="props.item"
            :actions="actions"
          ></app-data-table-action-menu>
        </slot>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters({
      ctrlIsPressed: 'shortkey/ctrlIsPressed',
    }),

    query: {
      get () {
        return this.search;
      },

      set (query) {
        this.$emit('search', query);
      },
    },
  },

  methods: {
    focus () {
      this.$refs.tablesearch.focus();
    },
  },
};
</script>
