import Vue from 'vue';
import VStripeCard from 'v-stripe-elements/lib/VStripeCard';
import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
};

Vue.loadScript('https://js.stripe.com/v3/').then(() => {
  Vue.use(StripePlugin, options);
  Vue.component('v-stripe-card', VStripeCard);
});
