<template>
  <v-dialog
    :value="true"
    :width="width"
    persistent
    no-click-animation
    title="dialog zoom preview"
  >
    <v-card>
      <div class="pa-1 text-right">
        <v-btn title="button zoom close" small icon @click="back"><v-icon small>mdi-close</v-icon></v-btn>
      </div>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-layout justify-space-between align-center class="mb-4">
              <!-- Image title -->
              <h3 v-text="$trans('Image Preview')"></h3>
              <!-- Image title -->

              <!-- Image actions -->
              <v-sheet>
                <v-btn
                  v-if="datalake.loaded"
                  :to="{
                    name: 'reports.single',
                    params: { slug: datalake.data.id },
                    query: {
                      back: $route.fullPath,
                    },
                  }"
                  small text
                  class="ml-2"
                  title="button zoom goto"
                >
                  <v-icon small left>mdi-file-document-outline</v-icon>
                  <span
                    v-text="$trans('Go to page 1')"
                  ></span>
                </v-btn>
              </v-sheet>
              <!-- Image actions -->
            </v-layout>

            <!-- Image Previewer -->
            <panzoom-image-previewer
              ref="panzoom-image-previewer"
              :loading="datalake.loading"
              :options="{
                overflow: 'auto',
                touchAction: 'pan',
                panOnlyWhenZoomed: true,
              }"
              always-zoom-from-center
              slider zoom-bar
              zoom-on-dblclick
              height="800"
              @wheel:up="onWheelUp"
              @wheel:down="onWheelDown"
            >
              <img
                v-if="datalake.loaded" :src="parsePDFImagesUrl(datalake.data)"
                width="100%"
                height="auto"
              >
            </panzoom-image-previewer>
            <!-- Image Previewer -->

            <!-- Image info -->
            <v-layout justify-space-between class="mt-3">
              <span
                class="muted--text caption"
                v-text="$trans('From {name}', {
                  name: datalake.data.doc_name,
                })"
              ></span>
              <span
                class="muted--text caption"
                v-text="$trans('page {page}', {
                  page: 1,
                })"
              ></span>
            </v-layout>
            <!-- Image info -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Datalake from '@/modules/Dashboard/resources/Datalake';
import BackToPreviousPage from '@core/mixins/BackToPreviousPage';

export default {
  mixins: [ BackToPreviousPage ],

  layout: 'admin',

  props: {
    loading: Boolean,

    width: {
      type: [ String, Number ],
      default: 800,
    },
  },

  data: vm => ({
    datalake: new Datalake(vm.resource),
    backUrl: { name: 'datalake', query: vm.$route.query },
  }),

  async created () {
    await this.datalake.find(this.$slug);
  },

  methods: {
    onWheelUp ({ event, panzoom }) {
      event.preventDefault();
      panzoom.zoomIn();
    },

    onWheelDown ({ event, panzoom }) {
      event.preventDefault();
      panzoom.zoomOut();
    },
    parsePDFImagesUrl (item) {
      if(item.file_type === "tif"){
        return require('@assets/broken-image.png')
      }
      const split = item.pdf_images_url.split('?');
      const pageNumber = 1;
      const pageSize = parseInt(pageNumber, 10);
      const urlPages = [];
      
      for (let i = 1; i <= pageSize; i++) {
        urlPages.push({
          page: i,
          pageNum: pageNumber,
          url: `${split[0]}/${i}.png?${split[1]}`,
          ocrData: [],
          totalOCRData: 0,
        });
      }
      return urlPages[0].url;
    }
  },
};
</script>
