<template>
  <v-app v-cloak class="s-app">
    <v-app-bar app height="64">
      <v-container>
        <v-row justify="space-between" align="center">
          <v-col :lg="layoutOptions.columnWidth" cols="12" class="mx-auto">
            <v-layout align-center>
              <app-brand-banner height="36px" width="auto"></app-brand-banner>
              <v-spacer></v-spacer>
              <portal-target name="webbar:append" multiple></portal-target>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <app-snackbar></app-snackbar>
    <app-dialogbox></app-dialogbox>

    <v-slide-y-transition mode="in-out">
      <v-main class="grey lighten-4">
        <v-slide-y-transition mode="out-in">
          <router-view></router-view>
        </v-slide-y-transition>
      </v-main>
    </v-slide-y-transition>
    <!-- TODO: footer -->
  </v-app>
</template>

<script>
export default {
  name: 'Web',

  transitionMode: 'out-in',

  computed: {
    layoutOptions () {
      return {
        columnWidth: 8,
        ...this.$page?.meta?.layoutOptions,
      };
    },
  },
};
</script>
