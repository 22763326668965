import { STATS_SUMMARY_URL } from '@/modules/Datalake/api/summary';
import DataResource from '@core/resources/DataResource';

const ONE_GIGABYTE_IN_MB = 1000.0;
const ONE_TERABYTE_IN_GB = 1000.0;
const ONE_TERABYTE_IN_TB = 1.0;

export default class SummaryInfo extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async list () {
    this.startLoading();
    const { data } = await this.axios.get(STATS_SUMMARY_URL);

    this.setData(data.data);

    this.stopLoading();
  }

  setData (data) {
    const item = data.find(i => i.name === 'Total File Size(mb)');
    const total = parseFloat(item.stat / ONE_GIGABYTE_IN_MB).toFixed(2);
    const percent = Math.floor(Math.floor((total / ONE_TERABYTE_IN_GB) * 100));
    const percentText = `${percent}%`;
    const text = `GB of ${ONE_TERABYTE_IN_TB} TB Used`;
    const reachedCapacity = total > ONE_TERABYTE_IN_GB;

    this.data = {
      ...item,
      text,
      total,
      percent,
      percentText,
      reachedCapacity,
    };

    return this;
  }
}
