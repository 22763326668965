export const BOUNDS = {
  // ll: [ -47.5172006978394, 103.35937499999999 ],
  // ur: [ -0.5273363048115043, 178.2421875 ],
  ll: [
    -76.51681887717322,
    -143.4375,
  ],
  ur: [
    77.76758238272801,
    163.125,
  ],
};
export const MAP_CENTER = [ -11.802834233547674, 126.826171875 ];
export const MAP_LAYER_URL = 'https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}';
export const MAX_ZOOM = 12;
export const MIN_ZOOM = 1;
export const Z_INDEX = 1000;
export const ZOOM = 6;
export const BACKGROUND_WELL_OPTIONS = {
  color: 'rgba(88, 88, 88, 0.4)',
  fill: true,
  fillColor: 'rgba(88, 88, 88, 0.4)',
  fillOpacity: 1,
  radius: 5,
  riseOnHover: true,
  weight: 0.5,
};
export const RESULTS_WELL_OPTIONS = {
  radius: 5,
  weight: 1,
  color: '#09d034',
  fill: true,
  fillColor: '#09d034',
  fillOpacity: 1,
};

export const SELECTED_WELL_OPTIONS = {
  radius: 5,
  weight: 0.5,
  color: 'red',
  fill: true,
  fillColor: 'red',
  fillOpacity: 1,
};

export const POLYGON_HIGHLIGHT_OPTIONS = {
  color: 'rgb(244,41,65)',
  stroke: true,
  weight: 1,
  fillOpacity: 0.7,
};

export const POLYGON_DASHED_HIGHLIGHT_OPTIONS = {
  stroke: true,
  color: 'steelblue',
  dashArray: '1 4 3 1',
  fill: true,
  fillColor: '#fcfdff',
  fillOpacity: 0.3,
  opacity: 0.5,
  weight: 2,
};

export const GEO_FILTER_OPTIONS = {
  weight: 2,
  dashArray: '1 4 3 1',
  color: 'steelblue',
  fill: true,
  fillColor: '#fcfdff',
  fillOpacity: 0.3,
};

export const HEATMAP_CONFIG = {
  // radius should be small ONLY if scaleRadius is true (or small radius is intended)
  radius: 0.25,
  maxOpacity: 0.7,
  // scales the radius based on map zoom
  scaleRadius: true,
  // if set to false the heatmap uses the global maximum for colorization
  // if activated: uses the data maximum within the current map boundaries
  //   (there will always be a red spot with useLocalExtremas true)
  useLocalExtrema: false,
  // "useLocalExtrema": true,
  // which field name in your data represents the latitude - default "lat"
  latField: 'lat',
  // which field name in your data represents the longitude - default "lng"
  lngField: 'lng',
  // which field name in your data represents the data value - default "value"
  valueField: 'count',

  gradient: {
    0.0: 'blue',
    0.2: 'cyan',
    0.4: 'lime',
    0.6: 'yellow',
    0.8: 'orange',
    1.0: 'red',
  },
};

export const DEFAULT_FIT_BOUNDS_PADDING = [ 50, 50 ];

export default Object.freeze({
  BOUNDS,
  MAP_CENTER,
  MAP_LAYER_URL,
  MAX_ZOOM,
  MIN_ZOOM,
  Z_INDEX,
  ZOOM,
  BACKGROUND_WELL_OPTIONS,
  RESULTS_WELL_OPTIONS,
  SELECTED_WELL_OPTIONS,
  POLYGON_HIGHLIGHT_OPTIONS,
  GEO_FILTER_OPTIONS,
  HEATMAP_CONFIG,
  DEFAULT_FIT_BOUNDS_PADDING,
});
