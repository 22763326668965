import DataResource from '@core/resources/DataResource';
import {
  isEmpty, values,
  groupBy, flatten,
} from 'lodash';
import fileDownload from 'js-file-download';
import Map from '@/modules/Search/resources/Map';
import Geometry from '@/modules/Search/resources/Geometry';
import WebGIS from '@/modules/Webgis/resources/WebGIS';
import aggregations, { GEOMETRY_TYPE_POLYGON, GEOMETRY_TYPE_MARKER } from '@/modules/Search/config/aggregations';
import filters from '@/modules/Search/config/filters';
import categories, { BUCKET_TYPE_NA } from '@/modules/Search/config/categories';
import state from '@/modules/Search/config/state';
import spatial from '@/modules/Search/config/spatial';
import { map, mapAreaTabs } from '@/modules/Search/config/map';
import { SEARCH_URL } from '@/modules/Search/api/search';
import { supportedQuery } from '@/modules/Search/config/queries';
import { CURRENT_EXPORT_SEARCH_MODE } from '@/modules/Search/config/exports';
import { FIND_DATALAKE_DATA_URL } from '@/modules/Dashboard/api/datalake';
import {
  GET_WELLS_INFO_URL,
  FIND_WELL_INFO_URL,
} from '@/modules/Dashboard/api/wells';
import config from '@/config/app';

export default class Search extends DataResource {
  constructor (options = {}) {
    super(options);

    this.setMap(options.map);
    this.setWebGIS(options.webgis);
    this.setGeometry(options.geometry);

    this.setOptions(this.mapInitialOptions(options.options));
    this.setMeta({
      filters,
      spatial,
      aggregations,
      categories,
      mapAreaTabs,
      supportedQuery,
      singleSelect: true,
      projectResultsCount: 0,
      ...options.meta,
    });

    this.setState(state);

    this.initializeAggregation();
  }

  mapInitialOptions (options) {
    return {
      itemsPerPage: 20,
      ...options,
      ...this.route.query,
      page: parseInt(this.route.query.page, 10),
    };
  }

  runAggregations () {
    return true;
  }

  initializeAggregation () {
    if (this.route.query.geo_tab) {
      this.meta.aggregations.setCurrentByKey(this.route.query.geo_tab, 'code');
    } else {
      this.meta.aggregations.setCurrentToDefault();
    }

    if (!isEmpty(this.route.query.geo_polygon)) {
      this.store.dispatch('map/setAsDrawn');
    }
  }

  setMap (options) {
    this.map = options instanceof Map ? options : new Map({
      events: {
        onMapLoad: map => this.onMapLoad(map),
        onDrawCreated: (e, map) => this.onDrawCreated(e, map),
      },
      ...map,
      ...options,
    });
  }

  setWebGIS (options) {
    this.webgis = new WebGIS({
      ...options,
    });
  }

  setGeometry (options) {
    this.geometry = new Geometry({
      ...options,
    });
  }

  setState (state) {
    this.state = state;
  }

  async listCategoriesCount () {
    this.meta.categories.startLoading();

    const params = this.getDefaultQueryString();
    const { data } = await this.axios.get(SEARCH_URL, { params });

    this.setCategoriesChipFromAggregations(data.data?.aggregations);

    this.meta.categories.stopLoading();
  }

  async list () {
    this.startLoading();
    this.state.makeReady();
    this.disableAxiosResponseHandlers();

    try {
      const params = this.getDefaultQueryString();
      const { data } = await this.axios.get(SEARCH_URL, { params });

      this.setItems(data.data.search_results);
      this.setAggregations(data.data?.aggregations);

      this.setPagination({
        total: data.data.total_results,
        perPage: this.options.itemsPerPage,
        itemsPerPage: this.options.itemsPerPage,
      });

      this.stopLoading();
      this.setupWebGIS();
      this.generateMapGeometries();
      this.enableAxiosResponseHandlers();
    } catch (e) {
      this.unsetItems();
    } finally {
      this.stopLoading();
    }
  }

  setQueryString (options) {
    const supportedQuery = this.parseOptionsAsSupportedQuery(options);

    this.query = {
      ...this.getQueryString(),
      ...supportedQuery,
    };

    this.pushRouteQuery();
    this.cleanQuery();

    return this;
  }

  async listCategoryTabsResultsCount () {
    const params = this.getDefaultQueryString();
    const { data } = await this.axios.get(SEARCH_URL, { params });
    this.setAggregations(data.data?.aggregations);
    this.stopLoading();
  }

  async toggleRowSelect (item) {
    if (item) {
      this.setSelectedItemToRouteQuery(item);
      const { hasWellRelationship } = await this.setWellItemToData(item);

      this.map.clearSelectedLayer();

      if (hasWellRelationship) {
        this.setWhiteCircleCoordinates(this.data.relatedWells.selected, item);
      } else {
        this.map.setPolygonCoordinates({
          meta: {
            item,
            basinIDs: this.meta.aggregations.getByKey('basin_ids', 'items').map(i => i.key).toString(),
            countryIDs: this.meta.aggregations.getByKey('country_ids', 'items').map(i => i.key).toString(),
          },
        });
      }
    }
  }

  async setWellItemToData (item) {
    this.disableAxiosResponseHandlers();

    try {
      const { data } = await this.axios.get(FIND_DATALAKE_DATA_URL(item.doc_ref));
      const wellRelationship = data.data.relationships.well_info.data;

      if (wellRelationship.length > 0) {
        const wellId = wellRelationship[0].id;
        const { data: well } = await this.axios.get(FIND_WELL_INFO_URL(wellId));

        const wellIDs = wellRelationship;
        const params = { well_id_list: wellIDs?.map(i => i.id).join(',') };
        const { data: wells } = await this.axios.get(GET_WELLS_INFO_URL, { params });
        const total = wells.data.length;
        const relatedWells = {
          total,
          selected: { ...well.data, ...well.data.attributes },
          items: wells.data.map(i => ({ ...i, ...i.attributes })),
        };

        this.setData({
          ...item,
          ...well.data,
          ...well.data.attributes,
          hasWellInfo: true,
          relatedWells,
        });
      } else {
        this.setData({
          ...item,
          ...data.data,
          ...data.data.attributes,
          hasWellInfo: false,
          relatedWells: { items: [], total: 0, selected: {} },
        });
      }

      return {
        item: data,
        hasWellRelationship: wellRelationship.length > 0,
      };
    } catch (e) {
      this.setData({});
    } finally {
      this.enableAxiosResponseHandlers();
    }

    return { item: {}, hasWellRelationship: false };
  }

  getDefaultQueryString () {
    const query = this.clone(this.getQueryString());
    const page = parseInt(this.options.page, 10);

    Object.keys(query).forEach(k => {
      if (isEmpty(query[k])) {
        delete query[k];
      }

      if (query[`has_${k}`] === 'false') {
        delete query[k];
      }
    });

    return {
      ...query,
      page_size: this.options.itemsPerPage || 50,
      page_from: (page - 1) * this.options.itemsPerPage || 0,
      q: this.buildQuerySearchParameters(query),
    };
  }

  transformQueryString (query) {
    if (query == null) {
      return query;
    }

    let updatedQuery = '';
    // reparse the query object to do the following
    // every word in the searchbar query except AND/OR will have doc_text
    // example: Figure AND gradient => doc_text:Figure AND doc_text:gradient
    const queryArr = query.split(' ');
    if ((query.indexOf('\'') === -1 && query.indexOf('"') === -1)) {
      queryArr.forEach(item => {
        if (item === 'AND' || item === 'OR') {
          updatedQuery += `${item} `;
        } else {
          updatedQuery += `doc_text:${item} `;
        }
      });
    } else if ((query.indexOf(' AND ') > -1 || query.indexOf(' OR ') > -1)) {
      let count = 1;
      queryArr.forEach(item => {
        if (item === 'AND' || item === 'OR') {
          updatedQuery += `${item} `;
          count = 1;
        // eslint-disable-next-line eqeqeq
        } else if (count == 1) {
          updatedQuery += `doc_text_wostem:${item} `;
          count -= 1;
        } else {
          updatedQuery += `${item} `;
          count += 1;
        }
      });
    } else {
      updatedQuery = `doc_text_wostem:${query}`;
    }
    updatedQuery = "(" + updatedQuery.trim() + ")";
    console.log('updatedQuery', updatedQuery);
    return updatedQuery;
  }

  buildQuerySearchParameters (params = {}) {
    const routeQuery = { ...this.getRouteQuery(), ...params };
    const query = routeQuery.q;
    const filters = this.buildSearchFilters();
    const projectIDs = this.getProjectIDs();
    let updatedQuery = '';

    updatedQuery = this.transformQueryString(query)?.trim();
    const returnVal = [
      query ? `${updatedQuery}` : null,
      `(${filters})`,
      `(${projectIDs})`,
    ].filter(filter => filter && filter.length > 0).join(' AND ').replace(' () AND', '');
    return returnVal;
  }

  buildSearchFilters () {
    const { delimiter, selected } = this.meta.filters;
    const filters = values(groupBy(flatten(selected.map(filter => filter.split(delimiter)))
      .map(i => ({ key: i.split(':')[0], value: i })), 'key'))
      .map(i => i.map(j => j.value).join(' OR ')).join(') AND (');
    return `${filters}`;
  }

  getProjectIDs () {
    return `project_id:${this.store.getters['sourcetray/sources']?.map(i => i.id).join(',')}`;
  }

  setSearch (search = undefined) {
    this.cancelMapRequests();
    this.unsetItems();
    this.search = search;
    this.searching = true;
    this.setQueryString({
      page: 1,
      q: search,
    });

    this.state.makeReady();
    return this.pushRouteQuery();
  }

  setFilters (filters) {
    this.meta.filters.selected = filters;
    this.setSearch(this.search);
    this.setQueryString({ filters: this.meta.filters.getHash() });

    return this;
  }

  setItems (items) {
    this.items = items.map(item => this.makeItem(item));
  }

  unsetItems () {
    this.items = [];
  }

  makeItem (item) {
    return {
      ...item,
      ...item.attributes,
      title: item?.attributes?.article_title === BUCKET_TYPE_NA
        ? item
          .attributes
          .doc_title
          .trim()
        : item.attributes.article_title,
      data_index: item?.attributes?.data_index_info?.[0],
    };
  }

  listOrReset () {
    this.setActiveTabFromRoute();

    if (this.hasSearch()) {
      this.setSearch(this.route.query.q).listCategoriesCount();
      this.store.dispatch('search/showMapArea');
    } else {
      this.resetToInitialState();
    }
  }

  async checkIfProjectIsEmpty () {
    if (!this.hasSearch()) {
      this.startLoading();
      this.disableAxiosResponseHandlers();

      try {
        const projectIDs = this.getProjectIDs();
        const params = { q: `(${projectIDs})`, page: 1, page_size: 1 };
        const { data } = await this.axios.get(SEARCH_URL, { params });
        this.meta.projectResultsCount = data.data.total_results;
      } catch (e) {
        console.log(e)
        this.setErrors(e);
        this.unsetItems();
      } finally {
        this.enableAxiosResponseHandlers();
        this.stopLoading();
      }
    }
  }

  isProjectEmpty () {
    return this.state.isInitial && !this.hasSearch() && !this.meta.projectResultsCount;
  }

  isNotProjectEmpty () {
    return !this.isProjectEmpty();
  }

  resetToInitialState () {
    this.setData({});
    this.unsetItems();
    this.stopLoading();
    this.setupWebGIS();
    this.makePristine();
    this.state.makeUnready();
    this.state.makeInitial();
    this.store.dispatch('search/hideMapArea');
    this.store.dispatch('search/hideExportButton');
    this.store.dispatch('search/hideSpatialButton');
  }

  clearQueryString () {
    this.query = {};

    return this.pushRouteQuery();
  }

  clearDrawFilters () {
    this.setQueryString({
      geo_polygon: '',
      geo_filter_type: '',
      has_geo_filter_type: 'false',
      has_geo_polygon: 'false',
    });
    this.map.clearAllDrawHandlers();
    this.store.dispatch('search/hideSpatialButton');
    this.store.dispatch('search/setSpatialButtonOn', false);
  }

  setCategoriesChipFromAggregations (aggregations) {
    this.meta.categories.setChipFromAggregations(aggregations);
  }

  setAggregations (aggregations) {
    if (this.runAggregations()) {
      this.meta.categories.setChipFromAggregations(aggregations);
      this.meta.aggregations.setAggregations(aggregations);
    }
  }

  setupWebGIS () {
    this.webgis.attachToCurrentMap(this.map);
  }

  setActiveTabFromRoute () {
    const { tab } = this.route.query;

    if (tab) {
      this.meta.categories.setCurrentIndex(tab);
    }
  }

  generateMapGeometries () {
    if (this.runMapGeometries()) {
      this.listCurrentAggregationGeometries();
    }
  }

  runMapGeometries () {
    return this.store.getters['search/isMapGeometriesActive'];
  }

  getMapInstance () {
    return this.map.map;
  }

  onMapLoad (map) {
    map.on('load', () => {
      this.drawGeoPolygonFromRoute(map);
      this.drawGreenMapResults(map);
    });
  }

  listCurrentAggregationGeometries () {
    const currentAggregation = this.meta.aggregations.getCurrent();
    this.listGeometriesOfType(currentAggregation);
    this.setQueryString({ geo_tab: currentAggregation.code });
    this.store.dispatch('search/setMapGeometriesState', false);
  }

  hasGeoPolygon () {
    return this.query.has_geo_polygon === 'true';
  }

  async listGeometriesOfType ({ key, type, code }) {
    if (this.hasGeoPolygon() || this.meta.aggregations.isNotRunning()) {
      this.meta.aggregations.startRun();
      this.meta.aggregations.setCurrentByKey(key);
      this.setQueryString({ geo_tab: code });

      switch (type) {
      case GEOMETRY_TYPE_POLYGON:
        await this.listPolygonGeometries();
        break;
      case GEOMETRY_TYPE_MARKER:
      default:
        await this.listGeometries();
        break;
      }

      this.meta.aggregations.stopRun();
    } else {
      console.warn('Cancelled Search@listGeometriesOfType due to method already running.');
    }
  }

  async listGeometries () {
    this.map.startLoading();
    this.map.clearHeatLayer();
    this.map.clearResultMarkers();
    this.map.listBackgroundMarkers();
    this.meta.aggregations.startRun();

    await this.geometry.listByIds(
      this.meta.aggregations.getByKey(
        this.meta.aggregations.current.key,
        'aggregation.buckets',
      ),
    );

    const features = this.geometry.meta.feature.getItems();
    await Promise.all(features.map((feature, index) => {
      this.map.setResultMarkerWithPopup(feature);
      return this.meta.aggregations.setCurrentCount(index + 1);
    }));

    if (this.meta.categories.canCurrentShowHeatmap()) {
      const heatData = this.geometry.meta.heat.getItems();
      this.map.setHeatLayer(heatData);
    }

    if (this.data.hasWellInfo) {
      this.setWhiteCircleCoordinates(this.data.relatedWells.selected, this.data);
    }

    this.stopLoading();
    this.map.stopLoading();
    this.meta.aggregations.stopRun();
  }

  async listPolygonGeometries () {
    this.map.startLoading();
    this.map.clearHeatLayer();
    this.map.clearSelectedLayer();
    this.map.clearResultMarkers();
    this.map.clearBackgroundMarkers();
    this.meta.aggregations.startRun();

    if (this.map.resultsLayer) {
      this.map.resultsLayer.bringToFront();
    }

    await this.geometry.listPolygonByIds(
      this.meta.aggregations.getByKey(
        this.meta.aggregations.current.key,
        'aggregation.buckets',
      ),
    );

    const docCounts = this.geometry.meta.polygon.mapBy('doc_count');
    this.map.setAxiosTimeoutToDynamic(this.geometry.meta.polygon.getItems());
    this.map.setHeatColorScale(docCounts);
    this.map.setFillOpacityScale(docCounts);

    await Promise.all(this.geometry.meta.polygon.items.map(async (poly, index) => {
      const style = this.map.getPolygonGeometryStyle(poly.doc_count);
      const geoJSONLayer = this.map.createGeoJSONLayer(style);

      if (this.map.resultsLayer) {
        this.map.addLayerToResultMarkers(geoJSONLayer);
      }

      await this.map.createFeature(
        poly.key,
        this.meta.aggregations.current.code,
        geoJSONLayer,
        false,
      );

      this.meta.aggregations.setCurrentCount(index + 1);
    }));

    this.meta.aggregations.setCurrentCount(this.geometry.meta.polygon.items.length);
    this.map.fitBoundsToResultsOrWorld();

    this.stopLoading();
    this.map.stopLoading();
    this.meta.aggregations.stopRun();
  }

  getShowSpatialFilter () {
    return this.store.getters['search/showSpatialFilter'];
  }

  setSpatialFilter (isOn) {
    this.setQueryString({
      has_geo_filter_type: isOn.toString(),
      has_geo_polygon: isOn.toString(),
    });

    if (isOn) {
      this.drawGeoPolygonFromRoute();
    } else {
      this.map.clearDrawLayer();
    }
  }

  drawGreenMapResults () {
    console.log('run: Search@drawGreenMapResults');
  }

  drawSelectedLayerResults () {
    console.log('run: Search@drawSelectedLayerResults');
  }

  drawGeoPolygonFromRoute () {
    const { geo_polygon: flatPolygon } = this.query;

    if (!isEmpty(flatPolygon)) {
      if (this.hasGeoPolygon()) {
        const geoPolygon = this.map.generateGeoFilterFromFlatPolygon(flatPolygon, true);
        const drawLayer = this.map.L.polygon(
          geoPolygon,
          this.map.getPolygonDashedHighlightOptions(),
        );
        this.map.addLayerToDrawLayer(drawLayer);
      }

      this.store.dispatch('search/setSpatialButtonOn', this.hasGeoPolygon());
      this.store.dispatch('search/showSpatialButton');
    }
  }

  async onDrawCreated (e, geoPolygon) {
    await this.store.dispatch('search/setSpatialButtonOn');
    this.meta.categories.setCurrentToDefault();
    this.setQueryString({
      geo_polygon: geoPolygon,
      geo_filter_type: this.meta.aggregations.current.code,
      has_geo_filter_type: this.store.getters['search/isSpatialButtonOn'].toString(),
      has_geo_polygon: this.store.getters['search/isSpatialButtonOn'].toString(),
    });
    await this.store.dispatch('search/showSpatialButton');
    await this.store.dispatch('search/triggerSpatialFilter');
  }

  // TODO: move to Map.js
  setWhiteCircleCoordinates (well, item) {
    if (this.meta.aggregations.isCurrentTypeMarker()) {
      this.map.setSelectedLayerWithWhiteMarker({
        lng: well.longitude,
        lat: well.latitude,
        properties: well,
        meta: {
          item,
          basinIDs: this.meta.aggregations.getByKey('basin_ids', 'items').map(i => i.key).toString(),
          countryIDs: this.meta.aggregations.getByKey('country_ids', 'items').map(i => i.key).toString(),
        },
      });
    }
  }

  async exportItems () {
    const params = {
      ...this.getDefaultQueryString(),
      export_search: true,
      export_search_mode: this.getExportSearchMode(),
      page_size: 500,
    };

    try {
      const { data } = await this.axios.get(SEARCH_URL, { params }, {
        headers: {
          'Content-Type': 'arraybuffer',
        },
      });
      if(config.APP_MODE === "PETROS"){
        return fileDownload(data, 'search-result.csv')
      }
      // turn csv into array to change headers value
      var lines = data.split('\n');
      const headers = "id,result_id,name,basin,field_name,operator,longitude_decimal_degree,longitude_degree,longitude_minutes,longitude_seconds,longitude_direction,latitude_decimal_degree,latitude_degree,latitude_minutes,latitude_seconds,latitude_direction,spud,end_of_drill,northing,easting,kelly_bushing,well_type,crs,derric_floor_elevation,rotary_table,total_depth,tvd,tvdss,water_depth,play_segment,post_drill_conclusion,tagged_wells_count,country,article_title,source_name,source_type,document_id,document_title,page_number,doc_text_translated,doc_text_original"
      lines[0] = headers
      const result = lines.join('\n')

      return fileDownload(result, 'search-results.csv');
    } catch ({ response }) {
      const { message } = response.data;
      return this.dialog('error', {
        color: 'accent',
        persistent: true,
        illustration: () => import('@/components/Icons/IconListOneRowError'),
        illustrationHeight: 200,
        title: 'Download Limit Reached',
        text: message,
      });
    }
  }

  getExportSearchMode () {
    return CURRENT_EXPORT_SEARCH_MODE;
  }

  async onTabChanged (index) {
    await this.meta.categories.setCurrentIndex(index);

    this.store.dispatch(
      'search/setKnowledgeGraphAreaShown',
      this.meta.categories?.current?.showKnowledgeGraph ?? false,
    );

    this.store.dispatch(
      'search/setMapAreaShown',
      this.meta.categories?.current?.showMap ?? false,
    );

    return this.setQueryString({ tab: index });
  }

  cancelMapRequests () {
    this.meta.aggregations.stopRun();
    this.map.cancelAllRequests();
    this.map.makeCancelToken();
  }

  setSelectedItemToRouteQuery (item) {
    this.setQueryString({ _id: item?.id });
  }

  selectAndSlideToPreviousOrFirstItem () {
    this.selectPreviousOrFirstItem();
    const [ item ] = this.selected;

    if (this.isFirstItem(item)) {
      this.setSelectedItemToRouteQuery(item);
    } else {
      this.goToAndSlide(`[name=item-${item.id}]`);
    }
  }
}
