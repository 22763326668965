export const BOUNDS = {
  ll: [ -47.5172006978394, 103.35937499999999 ],
  ur: [ -0.5273363048115043, 178.2421875 ],
};
export const MAP_CENTER = [ -11.802834233547674, 126.826171875 ];
export const MAP_LAYER_URL = 'https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}';
export const MAX_ZOOM = 21;
export const MIN_ZOOM = 4;
export const Z_INDEX = 1000;
export const DEFAULT_BASEMAP_Z_INDEX = 1000;
export const ZOOM = 4;
export const DEFAULT_BASEMAP_NAME = 'ESRI Ocean';

export default Object.freeze({
  BOUNDS,
  MAP_CENTER,
  MAP_LAYER_URL,
  MAX_ZOOM,
  MIN_ZOOM,
  Z_INDEX,
  ZOOM,
  DEFAULT_BASEMAP_NAME,
});
