<template>
  <v-row class="datawarehouse-section">
    <v-col cols="3" lg="2" style="position: relative;">
      <document-sidebar></document-sidebar>
    </v-col>
    <v-col cols="12" lg="10">
      <datawarehouse-page-header></datawarehouse-page-header>
      <div class="pt-10 mt-10"></div>
      <datawarehouse-page-section
        v-for="(page, i) in page.items" :key="i"
        :name="page.slug"
        class="mb-10 pb-10"
      >
        <template v-slot:title="{ goToSection }">
          <a @click="goToSection(`#${page.slug}`)">
            <h1 class="mb-6 font-weight-bold" v-text="$trans(page.title)"></h1>
          </a>
        </template>
        <div class="page-content" v-html="page.content"></div>

        <div v-if="page.hasChild()" class="page-subcontent mt-10 ml-10">
          <datawarehouse-page-section
            v-for="(section, j) in page.children" :key="j"
            :name="section.slug"
            class="mb-10"
          >
            <template v-slot:title="{ goToSection }">
              <a @click="goToSection(`#${section.slug}`)">
                <h1 class="mb-6 font-weight-bold" v-text="$trans(section.title)"></h1>
              </a>
            </template>
            <div class="page-content" v-html="section.content"></div>
            <v-divider class="my-15"></v-divider>
          </datawarehouse-page-section>
        </div>
      </datawarehouse-page-section>
    </v-col>
  </v-row>
</template>

<script>
import documents from '@/modules/Datawarehouse/config/documents';
import Page from '@/modules/Datawarehouse/resources/Page';
import '@/modules/Datawarehouse/sass/custom.scss';

export default {
  name: 'AboutApiTabItem',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    page: new Page({
      items: documents,
    }),
  }),
};
</script>

<style lang="scss">
.app-sidebar__item {
  &--active {
    background-color: rgb(0, 0, 0, 0.025);
    border-right: 2px solid var(--v-primary-base);

    &::before {
      background-color: currentColor;
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
  }
}
</style>
