import { BASE_URL } from '@/config/api';

export const LIST_PETRO_PLOTS_URL = `${BASE_URL}/plugins/petrophysics-plotter/petro-data/`;
export const LIST_PETRO_PLOTS_META_URL = `${BASE_URL}/plugins/petrophysics-plotter/petro-meta/`;
export const FIND_PETRO_PLOTS_URL = id => `${BASE_URL}/plugins/petrophysics-plotter/petro-data/${id}/`;
export const FIND_PETRO_META_URL = id => `${BASE_URL}/plugins/petrophysics-plotter/petro-meta/${id}/`;
export const FIND_PETRO_DATA_BY_WELL_NAME_URL = wellName => `${BASE_URL}/plugins/petrophysics-plotter/petro-plot/?well_name=${wellName}`;

export default Object.freeze({
  LIST_PETRO_PLOTS_URL,
  LIST_PETRO_PLOTS_META_URL,
  FIND_PETRO_PLOTS_URL,
  FIND_PETRO_META_URL,
  FIND_PETRO_DATA_BY_WELL_NAME_URL,
});
