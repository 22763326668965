import Vue from 'vue';
import Vuex from 'vuex';
import core from './core';
import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: Object.assign(core, modules),
  // Making sure that we're doing
  // everything correctly by enabling
  // strict mode in the dev environment.
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
