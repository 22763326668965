<template>
  <div v-if="verified" class="caption success--text">
    <v-icon left x-small color="success">mdi-check</v-icon>
    <span v-text="$trans('Verified')"></span>
  </div>
  <div v-else class="caption disabled--text">
    <v-icon left x-small>mdi-circle</v-icon>
    <span v-text="$trans('Unverified')"></span>
  </div>
</template>

<script>
export default {
  name: 'VerifiedBadge',

  props: {
    verified: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
