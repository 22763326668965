export default Object.freeze({
  PATH_IMAGES: '/images',
  PATH_REPORTS: '/reports',
  PATH_DATALAKE: '/datalake',
  PATH_SEARCH: '/search',
  PATH_WEBGIS: '/webgis',
  PATH_INTUITION: '/intuition',
  PATH_WORKFLOW_POSTMORTEM: '/workflow/post-mortem',
  PATH_WORKFLOW_PETROPHYSICS: '/workflow/petrophysics',
  PATH_WORKFLOW_ROCKPHYSICS: '/workflow/rock-physics',
});
