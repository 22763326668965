<template>
  <v-card width="890px" min-height="400px">
    <div class="layer-control__popover--fixed">
      <app-popover
        :title="$trans('Click to learn more on how this data and visualization was put together')"
        width="800"
        icon="mdi-information-outline"
      >
        <div class="caption mb-0">
          <div v-html="$trans(currentSettings.curation_info)"></div>
        </div>
      </app-popover>
    </div>
    <v-card-title>
      <h3 v-text="currentSettings.title"></h3>
    </v-card-title>
    <v-card-text>
      <v-sheet flat height="400px" class="muted--text">
        <canvas
          ref="viz"
          width="400"
          height="420"
        ></canvas>
      </v-sheet>
    </v-card-text>
    <v-card-text class="py-0" style="height: 14px;">
      <span v-if="bargraph.isFiltered()" class="caption text--disabled">
        {{ $trans('Showing a filtered result') }}.
        <a href="#to" class="caption" @click.prevent="resetFilters">{{ $trans('Reset') }}</a>
      </span>
    </v-card-text>
    <v-card-text>
      <template v-if="bargraph.isSummary()">
        <span v-text="$trans('Total')"></span>
        <h3
          v-text="$trans_choice(
            '{total} wells', bargraph.meta.total, {
              total: bargraph.meta.total
            }
          )"
        ></h3>
      </template>
      <template v-else>
        <span v-text="$trans('Well Name')"></span>
        <h3 v-text="bargraph.well.well_name"></h3>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <viz-map-control-references-dialog
        :items="references"
      ></viz-map-control-references-dialog>
      <v-btn
        class="px-6" color="secondary"
        @click="exportItem"
      ><span v-text="$trans('Export Data')"></span></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapColorToLabel } from '@/modules/Webgis/config/colors';
import { mapGetters } from 'vuex';

export default {
  name: 'VizBarGraphMapControlCard',

  data: () => ({
    type: 'bar',
    chart: null,
    bgColors: null,
  }),

  computed: mapGetters({
    bargraph: 'viz/bargraph/bargraph',
    references: 'viz/bargraph/references',
    currentSettings: 'viz/bargraph/currentSettings',
  }),

  watch: {
    'bargraph.data': {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.initChart();
        });
      },
    },
  },

  mounted () {
    this.initChart();
  },

  methods: {
    initChart () {
      this.setBackgroundColors(mapColorToLabel(this.bargraph.data.labels));

      setTimeout(async () => {
        if (this.chart) {
          await this.chart.destroy();
        }

        const canvas = this.$refs.viz.getContext('2d');
        this.chart = new window.Chart(canvas, {
          type: this.type,
          data: {
            labels: this.getFormattedLabels(),
            datasets: this.getFormattedDatasets(),
          },
          options: {
            onClick: this.onChartClick,
            animation: {
              duration: 0,
            },
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: 80,
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
              text: this.title,
              position: 'bottom',
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                title (tooltipItem, data) {
                  return data.labels[tooltipItem[0].index];
                },
                label (tooltipItem, data) {
                  const dataset = data.datasets[0];
                  const value = dataset.data[tooltipItem.index];
                  return value;
                },
              },
            },
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: this.currentSettings.y_label,
                },
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: this.currentSettings.x_label,
                },
              }],
            },
          },
        });
      }, 200);
    },

    onChartClick (e) {
      const points = this.chart.getElementAtEvent(e);
      if (points.length) {
        const [ point ] = points;
        const chart = point._chart;
        const value = chart.data.datasets?.[point._datasetIndex]?.data?.[point._index];
        const label = chart.data.labels[point._index];
        const colors = this.getBackgroundColors();
        const origColors = mapColorToLabel(this.bargraph.data.labels);
        origColors.forEach((c, i) => {
          colors[i] = c;
        });
        colors[point._index] = 'red';
        this.setBackgroundColors(colors);
        this.chart.update();

        this.$emit('click:segment', { data: this.bargraph, value, label });
      }
    },

    getFormattedLabels () {
      return this.bargraph.data.labels.map(label => label.replace('_Percentage', ''));
    },

    getFormattedDatasets () {
      return [{
        label: this.currentSettings.title,
        data: this.bargraph.data.values,
        backgroundColor: this.getBackgroundColors(),
        borderWidth: 2,
        hoverOffset: 4,
        // meta: this.bargraph
      }];
    },

    setBackgroundColors (colors) {
      this.bgColors = colors;
    },

    getBackgroundColors () {
      return this.bgColors;
    },

    exportItem () {
      this.$emit('click:export', this.bargraph);
    },

    resetFilters () {
      this.$emit('click:reset');
    },
  },
};
</script>
