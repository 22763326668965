<template>
  <app-content>
    <meta-tag :title="$trans('File Sets')"></meta-tag>

    <!-- DataSource Tray -->
    <data-source-tray-filter
      width="400px"
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <!-- Search -->
    <v-row justify="space-between" align="start">
      <v-col
        cols="12" lg="6"
        order="2"
        order-lg="1"
      >
        <app-search-field
          v-model.trim.lazy="set.search"
          :loading="set.searching"
          :label="$trans('Search file sets')"
          :placeholder="$trans('Search file sets')"
          autofocus solo
          @click:clear="clearSearch"
          @enter:search="setSearch"
        ></app-search-field>
      </v-col>

      <v-col
        cols="12" md="4"
        order="1"
        order-lg="2"
      >
        <portal-target name="header:right" slim>
          <file-data-storage-info></file-data-storage-info>
        </portal-target>
      </v-col>
    </v-row>
    <!-- Search -->

    <v-row>
      <v-col cols="12">
        <v-card class="mt-8 ov-hidden">
          <v-data-table
            v-model="set.selected"
            :footer-props="{ 'items-per-page-options': set.options.rowsPerPage }"
            :dense="set.meta.dense"
            :headers="set.headers"
            :items="set.items"
            :loading="set.loading"
            :mobile-breakpoint="set.meta.mobileBreakpoint"
            :server-items-length="set.pagination.total"
            :show-select="set.meta.showSelect"
            :options.sync="set.options"
            class="hover-checkbox--disabled"
            @update:options="updateList"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <app-stickybar class="bg-white align-center">
                <app-data-table-toolbar
                  :size="set.selectedSize()"
                  @click:clear="unselectAll"
                >
                  <file-data-table-manager-filter-bar
                    class="ml-2"
                    @click:filter="onFilter"
                  ></file-data-table-manager-filter-bar>
                </app-data-table-toolbar>
                <v-spacer></v-spacer>
                <v-data-footer
                  class="no-border"
                  :pagination="pagination"
                  :options="options"
                  :items-per-page-options="set.options.rowsPerPage"
                  @update:options="updateOptions"
                ></v-data-footer>
              </app-stickybar>
              <v-divider></v-divider>
            </template>

            <template v-slot:item.id="{ item }">
              <router-link
                :to="{
                  name: 'sets.approval',
                  params: { id: item.id },
                  query: { back: $route.fullPath },
                }"
                :class="{ 'muted--text': item.isNotSelectable }"
                v-text="item.title"
              ></router-link>
            </template>

            <template v-slot:item.storage_used="{ item }">
              <span
                :class="{ 'muted--text': item.isNotSelectable }"
              >{{ item.storage_used | toMb }}</span>
            </template>

            <template v-slot:item.date_added="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span
                    :class="{ 'muted--text': item.isNotSelectable }"
                    class="text-no-wrap"
                    v-on="on"
                  >{{ item.date_added | toDateFromNow }}</span>
                </template>
                <span>{{ item.date_added | toDateTime }}</span>
              </v-tooltip>
            </template>

            <template v-slot:item.status="{ item }">
              <v-alert
                :color="item.status.color"
                dense outlined
                text
                width="180px"
                class="text-center pa-1 ma-2"
                style="line-height: 1;"
              >
                <span class="caption" v-text="item.status.text"></span>
              </v-alert>
            </template>

            <template v-slot:item.total_files="{ item }">
              <span
                :class="{ 'muted--text': item.isNotSelectable }"
                v-text="item.total_files"
              ></span>
            </template>
            <template v-slot:item.added_by_full_name="{ item }">
              <span
                :class="{ 'muted--text': item.isNotSelectable }"
                class="text-no-wrap"
                v-text="item.added_by_full_name"
              ></span>
            </template>
            <template v-slot:item.project_name="{ item }">
              <span
                :class="{ 'muted--text': item.isNotSelectable }"
                v-text="item.project_name"
              ></span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <app-right-sidebar-area
      :value="isRightSidebarAreaShown"
      width="420px"
      color="grey lighten-4"
    >
      <div class="d-block w-100 mt-5 px-6 right-sidebar__content">
        <v-layout class="align-center mb-4">
          <h1 v-text="$trans('Batch Approval')"></h1>
          <v-spacer></v-spacer>
          <v-btn small icon @click="closeSidebar"><v-icon small>mdi-close</v-icon></v-btn>
        </v-layout>
        <div class="mb-5">
          <!-- eslint-disable-next-line max-len -->
          <p class="body-2 muted--text" v-text="$trans('The following sets will be approved or rejected. Add or remove by selecting or unselecting the checkbox from the table.')"></p>
          <!-- eslint-disable-next-line max-len -->
          <div class="body-2 muted--text" v-text="$trans('You may also approve / reject individual sets by clicking the set below. You will be redirected to a new page.')"></div>
        </div>

        <div v-for="(set, i) in set.selected" :key="i">
          <v-card dense class="mb-2">
            <v-card-text>
              <v-layout class="align-center">
                <div>
                  <router-link
                    :to="{
                      name: 'sets.approval',
                      params: { id: set.id },
                    }"
                    target="_blank"
                    class="link--text font-weight-bold"
                    v-text="set.title"
                  ></router-link>
                </div>
                <v-spacer></v-spacer>
                <v-tooltip left transition="slide-x-reverse-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small icon
                      v-on="on"
                      @click="set.unselect(set)"
                    >
                      <v-icon small color="error">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$trans('Remove from batch')"></span>
                </v-tooltip>
              </v-layout>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card flat>
          <v-card-text>
            <p
              class="body-1 mb-10 muted--text"
              v-text="$trans_choice('Selected {count} Sets', set.selectedSize(), {
                count: set.selectedSize(),
              })"
            ></p>
            <file-audit-approval-batch-form
              :items="set.selected"
              @click:submitted="onSubmitted"
            ></file-audit-approval-batch-form>
          </v-card-text>
        </v-card>
      </template>
    </app-right-sidebar-area>
  </app-content>
</template>

<script>
import Set from '@/modules/File/resources/Set';
import IsManager from '@/modules/File/middlewares/IsManager';
import GetFileTypeIcon from '@core/mixins/GetFileTypeIcon';
import PrettyBytes from '@core/filters/PrettyBytes';
import PrettyDates from '@core/filters/PrettyDates';

export default {
  layout: 'admin',

  mixins: [ GetFileTypeIcon, PrettyBytes, PrettyDates ],

  meta: {
    permissions: [ 'is_manager' ],
  },

  beforeRouteEnter: IsManager,

  data: vm => ({
    set: new Set({
      options: {
        itemsPerPage: 100,
        sortDesc: [ true ],
        sortBy: [ vm.$route.query.order_by || 'date_added' ],
      },
      meta: {
        dense: true,
        showSelect: true,
        mobileBreakpoint: 0,
      },
    }),
  }),

  computed: {
    isRightSidebarAreaShown () {
      return this.set.hasSelected();
    },
  },

  methods: {
    changedDataSource ({ query }) {
      this.set.setQueryString({ ...query, ...this.set.options }).listManagedOnly();
    },

    updateList (options) {
      this.set.setQueryString(options).listManagedOnly();
    },

    onFilter (filters) {
      this.set.setQueryString({ ...this.set.options, ...filters }).listManagedOnly();
    },

    onSubmitted () {
      this.closeSidebar();
      this.set.listManagedOnly();
    },

    setSearch (e) {
      this.set.setSearch(e.target.value).listManagedOnly();
    },

    clearSearch () {
      this.set.clearSearch();
    },

    unselectAll () {
      this.set.unselectAll();
    },

    closeSidebar () {
      this.unselectAll();
    },
  },
};
</script>
