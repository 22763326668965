import DataResource from '@core/resources/DataResource';
import { STATS_SUMMARY_URL } from '@/modules/Dashboard/api/summary';
import { isArray } from 'lodash';

export default class Glance extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async list () {
    this.startLoading();

    const params = this.getDefaultParams();
    const { data } = await this.axios.get(STATS_SUMMARY_URL, { params });

    if (isArray(data.data)) {
      this.setItems(data.data);
    } else {
      this.setItems([]);
    }

    this.stopLoading();
  }

  getDefaultParams () {
    return {
      projects_list: this.getProjectIDs(),
    };
  }

  getProjectIDs () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).toString();
  }
}
