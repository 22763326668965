<template>
  <portal to="appbar:navicon">
    <v-app-bar-nav-icon
      v-if="visible"
      :title="$trans(title)" icon
      data-test="appbar-nav-icon"
      @click="goBack"
    >
      <v-icon color="dark">{{ icon }}</v-icon>
    </v-app-bar-nav-icon>
  </portal>
</template>

<script>
export default {
  name: 'AppBackBtn',

  props: {
    to: {
      type: [ String, Object ],
      default: null,
    },

    icon: {
      type: String,
      default: 'mdi-arrow-left',
    },

    title: {
      type: String,
      default: 'Back to previous page',
    },

    visible: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    href () {
      return this.$route.query.back || this.to || this.backUrl || -1;
    },
  },

  methods: {
    goBack () {
      return this.href < 0 ? this.$router.go(this.href) : this.$router.push(this.href);
    },
  },
};
</script>
