export const BILLING_STATUS_ACTIVE = 'active';
export const BILLING_STATUS_FOR_CANCELLATION = 'for_cancellation';
export const BILLING_STATUS_SUCCEEDED = 'succeeded';
export const BILLING_STATUS_REQUIRES_PAYMENT_METHOD = 'requires_payment_method';

export default Object.freeze({
  BILLING_STATUS_ACTIVE,
  BILLING_STATUS_FOR_CANCELLATION,
  BILLING_STATUS_SUCCEEDED,
  BILLING_STATUS_REQUIRES_PAYMENT_METHOD,
});
