import '@core/sass/_highlights.scss';

export default {
  methods: {
    highlightAll (text, needle = '', trimQuotes = true) {
      needle = trimQuotes ? this.trimQuotes(needle) : needle;
      const needles = needle.map(phrase => phrase.replace(new RegExp("[\\s-']", 'igm'), "[\\s\\S\\w-']")).toString().replace(new RegExp(',', 'igm'), '|');

      return (text || '').replace(new RegExp(needles, 'igm'), this.highlightElement());
    },

    highlight (text, needle, trimQuotes = true) {
      needle = trimQuotes ? this.trimQuotes(needle) : needle;
      return (text || '').replace(new RegExp(needle, 'igm'), this.highlightElement());
    },

    trimQuotes (needle) {
      return (needle || '').split(' AND ').map(phrase => phrase.split(' OR ').map(phrase => phrase.replace(/['"]+/g, '').trim())).flat(Infinity);
    },

    highlightElement () {
      return '<span class="highlight">$&</span>';
    },
  },
};
