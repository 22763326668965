<template>
  <v-menu left>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
    </template>
    <v-sheet>
      <v-list dense>
        <v-list-item
          v-for="(action, i) in actions"
          :key="i"
          :to="route(action.name, item[itemKey])"
        >
          <v-list-item-icon>
            <v-icon>{{ action.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ action.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  props: {
    itemKey: {
      type: String,
      default: 'id',
    },

    item: {
      type: Object,
      required: true,
    },

    actions: {
      type: Array,
      required: true,
    },
  },

  methods: {
    emitSearch () {
      this.$emit('search', this.search);
    },
  },
};
</script>
