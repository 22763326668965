<template>
  <v-card flat>
    <v-card-text class="text-center">
      <v-avatar size="60" class="my-2">
        <img
          :src="user.avatar" width="60"
          height="60"
          class="text-center mx-auto"
        >
      </v-avatar>
      <p class="lead mb-0 font-weight-bold">{{ user.displayname }}</p>
      <p style="font-size: 14px;">{{ user.email }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ActionMenuAvatar',

  computed: mapGetters({
    user: 'auth/user',
  }),
};
</script>
