export default [
  {
    name: 'workflows.rock-physics',
    title: 'Rock Physics',
    description: 'View Rock Physics page',
    parent: 'workflows',
    permission: 'pod_rock_physics',
    sort: 3,
  },
];
