<template>
  <div
    tabindex="0"
    v-bind="$attrs"
    @focus="onClick"
    @click.stop="onClick"
  >
    <slot v-bind="{ clicked, notYetClicked, click: onClick }"></slot>
  </div>
</template>

<script>
export default {
  name: 'AppClick',

  data: () => ({
    notYetClicked: true,
    clicked: false,
  }),

  computed: {
    boundOptions () {
      return {
        clicked: this.clicked,
        notYetClicked: this.notYetClicked,
      };
    },
  },

  methods: {
    onClick () {
      this.clicked = !this.clicked;
      this.notYetClicked = false;
      this.emitClick();
    },

    select () {
      this.clicked = true;
      this.notYetClicked = false;
    },

    unselect () {
      this.clicked = false;
      this.notYetClicked = false;
    },

    emitClick () {
      this.$emit('click', this.boundOptions);
      this.$emit('click:item', this.boundOptions);
    },
  },
};
</script>
