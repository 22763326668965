export const items = [
  {
    name: 'uploader',
    isShown: (localStorage.getItem('tour/uploader:show') ?? 'true') === 'true',
  },
];

export default {
  find (name) {
    return this.items.find(i => i.name === name);
  },
  show (name) {
    const item = this.find(name);
    item.isShown = true;

    return item.isShown;
  },
  hide (name) {
    const item = this.find(name);
    item.isShown = false;

    return item.isShown;
  },
  toggleShown (name) {
    const item = this.find(name);
    item.isShown = !item.isShown;

    return item.isShown;
  },
  setLocal (name, key, value) {
    localStorage.setItem(`tour/${name}:${key}`, value);
  },
  getLocal (name, key) {
    return localStorage.getItem(`tour/${name}:${key}`);
  },
  items,
};
