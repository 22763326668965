/* eslint-disable max-len */
import Vue from 'vue';
import { omit, zipObject, camelCase } from 'lodash';
import Config from './server.json';

if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
  Config.VUE_APP_TITLE = process.env.VUE_APP_TITLE;
  Config.VUE_APP_SHORT_NAME = process.env.VUE_APP_SHORT_NAME;
  Config.VUE_APP_VERSION = process.env.VUE_APP_VERSION;
  Config.VUE_APP_MODE = process.env.VUE_APP_MODE;
  Config.VUE_APP_DASHBOARD_MODE = process.env.VUE_APP_DASHBOARD_MODE || process.env.VUE_APP_MODE || 'default';
  Config.VUE_APP_LOGIN_PAGE_GREETING_MESSAGE = process.env.VUE_APP_LOGIN_PAGE_GREETING_MESSAGE;
  Config.VUE_APP_LOGIN_PAGE_GREETING_COLOR = process.env.VUE_APP_LOGIN_PAGE_GREETING_COLOR;
  Config.VUE_APP_LOGIN_PAGE_GREETING_TYPE = process.env.VUE_APP_LOGIN_PAGE_GREETING_TYPE;
  Config.VUE_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY = process.env.VUE_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY;
  Config.VUE_APP_GOOGLE_RECAPTCHA_V3_SECRET_KEY = process.env.VUE_APP_GOOGLE_RECAPTCHA_V3_SECRET_KEY;
  Config.VUE_APP_STRIPE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
  Config.VUE_APP_DEFAULT_PRODUCT_ID = process.env.VUE_APP_DEFAULT_PRODUCT_ID;
  Config.VUE_APP_SEND_FEEDBACK_URL = process.env.VUE_APP_SEND_FEEDBACK_URL;
  Config.VUE_APP_SUPPORT_EMAIL = process.env.VUE_APP_SUPPORT_EMAIL;
}

export const APP_TITLE = Config.VUE_APP_TITLE || process.env.VUE_APP_TITLE;
export const APP_SHORT_NAME = Config.VUE_APP_SHORT_NAME || process.env.VUE_APP_SHORT_NAME;
export const APP_VERSION = Config.VUE_APP_VERSION || process.env.VUE_APP_VERSION;
export const APP_MODE = Config.VUE_APP_MODE || process.env.VUE_APP_MODE;
export const DASHBOARD_MODE = Config.VUE_APP_DASHBOARD_MODE || process.env.VUE_APP_DASHBOARD_MODE || APP_MODE || 'default';
export const LOGIN_MESSAGE = Config.VUE_APP_LOGIN_PAGE_GREETING_MESSAGE || process.env.VUE_APP_LOGIN_PAGE_GREETING_MESSAGE;
export const LOGIN_MESSAGE_COLOR = Config.VUE_APP_LOGIN_PAGE_GREETING_COLOR || process.env.VUE_APP_LOGIN_PAGE_GREETING_COLOR;
export const LOGIN_MESSAGE_TYPE = Config.VUE_APP_LOGIN_PAGE_GREETING_TYPE || process.env.VUE_APP_LOGIN_PAGE_GREETING_TYPE;
export const APP_YEAR = process.env.VUE_APP_YEAR;
export const DASHBOARD_REDIRECT = process.env.DASHBOARD_REDIRECT || 'dashboard';
export const DEFAULT_PRODUCT_ID = Config.VUE_APP_DEFAULT_PRODUCT_ID || process.env.VUE_APP_DEFAULT_PRODUCT_ID;
export const FEEDBACK_URL = Config.VUE_APP_SEND_FEEDBACK_URL || process.env.VUE_APP_SEND_FEEDBACK_URL;
export const SUPPORT_EMAIL = Config.VUE_APP_SUPPORT_EMAIL || process.env.VUE_APP_SUPPORT_EMAIL;

// TODO: to be DEPRECATED
export const HOME_REDIRECT = process.env.HOME_REDIRECT || 'home';
export const HOME_ROUTE = process.env.VUE_APP_HOME_ROUTE;
export const meta = omit(process.env, [
  'BASE_URL',
  'HOME_ROUTE',
  'VUE_APP_YEAR',
  'VUE_APP_TITLE',
  'APP_SHORT_NAME',
  'VUE_APP_VERSION',
  'VUE_APP_CACHE_KEY',
  'DASHBOARD_REDIRECT',
  'VUE_APP_API_BASE_URL',
  'VUE_APP_API_AUTH_TYPE',
  'VUE_APP_SEND_FEEDBACK_URL',
  'VUE_APP_MODE',
  'VUE_APP_SUPPORT_EMAIL',
]);

const isAppMode = mode => APP_MODE === mode;

const app = Object.freeze({
  APP_TITLE,
  APP_SHORT_NAME,
  APP_VERSION,
  APP_YEAR,
  DASHBOARD_REDIRECT,
  FEEDBACK_URL,
  SUPPORT_EMAIL,
  APP_MODE,
  HOME_REDIRECT,
  HOME_ROUTE,
  meta,
  DASHBOARD_MODE,
  LOGIN_MESSAGE,
  LOGIN_MESSAGE_COLOR,
  LOGIN_MESSAGE_TYPE,
  isAppMode,
});

export const $app = zipObject(
  Object.keys(app).map(i => camelCase(i)),
  Object.values(app),
);

Vue.prototype.$app = $app;

export default app;
