<template>
  <dashboard-view></dashboard-view>
</template>

<script>
export default {
  layout: 'admin',

  meta: {
    permissions: [ 'data_dashboard' ],
  },
};
</script>
