import Auth from '@/modules/Auth/resources/Auth';

export default {
  created () {
    const auth = new Auth;
    window.axios.interceptors.request.use(async config => {
      if (auth.isTokenAboutToExpire()) {
        await auth.refreshToken();
        config.headers.Authorization = auth.getAuthorizationHeaderToken();
      }

      return config;
    }, err => Promise.reject(err));
  },
};
