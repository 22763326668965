<template>
  <div class="map-area__tab-item">
    <v-card>
      <map-area
        v-model="map"
        zoom-bar
        width="100%" height="420px"
        class="w-100"
      >
        <template v-slot:corner.top-left>
          <v-layout column class="my-4">
            <v-tooltip right transition="slide-x-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  min-width="32" width="32"
                  min-height="32"
                  height="32" class="ma-1"
                  title="map search filter polygon"
                  v-on="on"
                  @click="filterSearchWithPolygon"
                >
                  <v-icon small>mdi-pentagon</v-icon>
                </v-btn>
              </template>
              <span
                v-if="map.isPolygonDrawHandlerEnabled()"
                v-text="$trans('Clear polygon boundary')"
              ></span>
              <span v-else v-text="$trans('Search with a polygon boundary')"></span>
            </v-tooltip>

            <v-tooltip right transition="slide-x-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  min-width="32" width="32"
                  min-height="32"
                  height="32" class="ma-1"
                  title="map search filter rectangle"
                  v-on="on"
                  @click="filterSearchWithRectangle"
                >
                  <v-icon small>mdi-square</v-icon>
                </v-btn>
              </template>
              <span
                v-if="map.isRectangleDrawHandlerEnabled()"
                v-text="$trans('Clear rectangular boundary')"
              ></span>
              <span v-else v-text="$trans('Search within a rectangular boundary')"></span>
            </v-tooltip>

            <v-tooltip right transition="slide-x-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="isDeleteBtnDisabled"
                  title="map search filter delete"
                  min-width="32" width="32"
                  min-height="32"
                  height="32" class="ma-1 mt-4"
                  v-on="on"
                  @click="clearDrawFilters"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span v-text="$trans('Delete spatial filter')"></span>
            </v-tooltip>

            <map-area-screenshot-control-btn
              :loading="map.loading"
              @screenshot="onScreenshot"
            ></map-area-screenshot-control-btn>
          </v-layout>
        </template>

        <template v-slot:corner.top-center>
          <v-card width="100%" class="ma-1">
            <v-card-text class="pa-2">
              <h5 class="caption font-weight-medium" v-text="$trans('Results Density')"></h5>
              <v-layout class="ma-1 align-center justify-center">
                <span
                  class="caption text-no-wrap font-weight-medium"
                  v-text="$trans('Low')"
                ></span>
                <map-area-heatmap-legend
                  :type="aggregations.current.type"
                  class="mx-2"
                ></map-area-heatmap-legend>
                <span
                  class="caption text-no-wrap font-weight-medium"
                  v-text="$trans('High')"
                ></span>
              </v-layout>
            </v-card-text>
          </v-card>
        </template>

        <template v-slot:corner.top-right>
          <v-layout column class="ma-2">
            <map-gis-layer-control-btn
              :title="$trans('Show/hide basemaps and other overlays')"
              class="ma-1"
            >
              <map-gis-layer-control-card
                :basemaps="search.webgis.meta.basemaps"
                :layers="search.webgis.meta.layers"
                dense width="300"
                @change:basemaps="changeBasemaps"
                @change:layers="changeLayers"
              ></map-gis-layer-control-card>
            </map-gis-layer-control-btn>
          </v-layout>
        </template>

        <template v-slot:zoom-bar.compass>
          <map-area-map-tab-item-loading-state
            v-if="map.loading && !mapExceptionEncountered"
          ></map-area-map-tab-item-loading-state>
          <v-icon v-else size="38">mdi-compass-rose</v-icon>
        </template>

        <template v-slot:corner.bottom-center>
          <v-btn-toggle v-model="aggregations.currentIndex">
            <v-btn
              v-for="(aggregation, i) in aggregations.items"
              :key="i" :disabled="isDisabled(aggregations, i)"
              small
              @click="listGeometriesOfType(aggregation)"
              :title="`map-btn-${$trans((aggregation.text).toLowerCase())}`"
            >
              <template v-if="search.loading">
                <span v-text="$trans(aggregation.text)"></span>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="10"
                  width="1"
                  class="mx-2"
                ></v-progress-circular>
              </template>
              <template v-else>
                <v-badge
                  v-if="aggregation.count"
                  :content="aggregation.count"
                  :disabled="aggregations.isCurrentIndex(i)"
                  inline overlap
                  :title="`map-tab-${$trans((aggregation.text).toLowerCase())}`"
                >
                  <span v-text="$trans(aggregation.text)"></span>
                </v-badge>
                <span v-else v-text="$trans(aggregation.text)"></span>
              </template>
            </v-btn>
          </v-btn-toggle>
        </template>
        <template v-slot:corner.bottom-right>
          <v-btn
            :title="$trans('Toggle expand')"
            min-width="32" width="32"
            min-height="32" height="32"
            class="mr-3"
            @click="expandMapArea"
          >
            <v-icon
              v-if="$store.getters['search/isMapAreaExpanded']"
              small
            >mdi-arrow-expand-right</v-icon>
            <v-icon v-else small>mdi-arrow-expand-left</v-icon>
          </v-btn>
        </template>
      </map-area>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'MapAreaMapTabItem',

  model: {
    prop: 'search',
  },

  props: {
    search: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      mapExceptionEncountered: false,
      loadingAnimationActive: false,
    };
  },

  computed: {
    aggregations () {
      return this.search.meta.aggregations;
    },

    map () {
      return this.search.map;
    },

    isDeleteBtnDisabled () {
      return !this.$store.getters['map/hasMapDrawn'];
    },
  },

  watch: {
    'map.loading': {
      // Allow component properties/data to be accessed from within watcher
      deep: true,
      handler () {
        // Reset exception flag for every successful state change
        this.mapExceptionEncountered = false;
      },
    },
  },

  mounted () {
    this.search.setupWebGIS();
    window.addEventListener('unhandledrejection', this.handleMapException);
  },

  beforeDestroy () {
    window.removeEventListener('unhandledrejection', this.handleMapException);
  },

  methods: {
    async expandMapArea () {
      await this.$store.dispatch('search/toggleExpandMapArea');
      await this.$store.dispatch('search/setMapAreaShown', false);
      setTimeout(() => {
        this.$nextTick(() => {
          this.$store.dispatch('search/setMapAreaShown', true);
        });
      }, 200);
    },

    handleMapException () {
      this.mapExceptionEncountered = true;
    },

    filterSearchWithPolygon () {
      this.search.map.togglePolygonDrawHandler();
    },

    filterSearchWithRectangle () {
      this.search.map.toggleRectangleDrawHandler();
    },

    clearDrawFilters () {
      this.search.clearDrawFilters();
    },

    listGeometriesOfType (aggregation) {
      this.search.cancelMapRequests();
      this.search.listGeometriesOfType(aggregation);
    },

    isZero (value) {
      return parseInt(value, 10) === 0;
    },

    isDisabled (aggregations, i) {
      return aggregations.isCurrentIndex(i) || aggregations.items[i].count <= 0;
    },

    changeBasemaps (basemap) {
      if (this.search.webgis) {
        this.search.webgis
          .setBasemapLayer(basemap, this.search.getMapInstance())
          .setBasemapZIndexToDefault();
      }
    },

    changeLayers (layers) {
      if (this.search.webgis) {
        this.search.webgis.setLayerOverlays(layers, this.search.getMapInstance());
      }
    },

    onScreenshot () {
      this.map.screenshot();
    },
  },
};
</script>
