<template>
  <div class="text-center">
    <v-layout v-if="isDisplayed">
      <v-spacer></v-spacer>
      <v-btn :disabled="isFirst" icon @click="onPrev"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            {{ current }}
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item-group v-model="currentMenu">
              <v-list-item
                v-for="(num, i) in length" :key="i"
                class="justify-end"
                @click="setCurrent(num)"
              >
                <div v-text="num"></div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
      <v-btn :disabled="isLast" icon @click="onNext"><v-icon>mdi-chevron-right</v-icon></v-btn>
      <v-spacer></v-spacer>
    </v-layout>
    <span
      class="caption muted--text"
      v-text="$trans('{start}-{stop} of {total}', {
        start: pagination.pageStart + 1,
        stop: pagination.pageStop,
        total,
      })"
    ></span>
    <div v-if="hasFilters">
      <a
        href="#"
        class="app-link caption muted--text"
        @click.prevent="onRemoveFilters"
        v-text="$trans('Remove filters')"
      ></a>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  name: 'MenuPagination',

  props: {
    page: {
      type: Number,
      default: 1,
    },
    length: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    filtered: {
      type: [ Boolean, String ],
      default: false,
    },
  },

  data: () => ({
    current: 1,
    currentMenu: 0,
  }),

  computed: {
    isDisplayed () {
      return this.length > 1;
    },
    isFirst () {
      return this.current === 1;
    },
    isLast () {
      return this.current === this.length;
    },
    hasFilters () {
      return !isEmpty(this.filtered);
    },
  },

  watch: {
    page (page) {
      this.current = page;
    },
    current (current) {
      this.$emit('update:current', current);
    },
  },

  methods: {
    setCurrent (current) {
      this.current = current;
      this.$emit('click:current', this.current);
    },
    onPrev () {
      this.current--;
      this.$emit('click:prev', this.current);
    },
    onNext () {
      this.current++;
      this.$emit('click:next', this.current);
    },
    onRemoveFilters () {
      this.$emit('click:unfilter');
    },
  },
};
</script>
