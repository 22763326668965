<template>
  <v-layout column class="fill-height my-4">
    <div class="flex-shrink-0">
      <v-skeleton-loader
        type="image" width="100%"
        height="64"
        class="mb-2"
      ></v-skeleton-loader>
    </div>
    <div class="flex-grow-1">
      <v-skeleton-loader
        type="image"
        width="100%"
        class="mb-2"
      >
        <div class="v-skeleton-loader__workspace rounded v-skeleton-loader__bone"></div>
      </v-skeleton-loader>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'PanzoomPdfPreviewerLoadingState',
};
</script>

<style lang="scss">
.v-skeleton-loader__workspace {
  background: rgba(0, 0, 0, 0.12);
  height: 100vh;
}
</style>
