<template>
  <div class="mt-10">
    <h1 class="mb-5" v-html="$trans('Billing Information')"></h1>
    <p v-html="$trans('billing.blurb')"></p>

    form goes here.
  </div>
</template>

<script>
export default {
  name: 'BillingPaymentTabItem',
};
</script>
