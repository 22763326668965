<template>
  <canvas
    id="canvas" ref="canvas"
    :width="width"
    :height="height"
    class="white"
  ></canvas>
</template>

<script>
import { PIE_CHART_COLORS } from '@/modules/Webgis/config/colors';
import { mapGetters } from 'vuex';

export default {
  name: 'MapGisLithologiesPieChart',

  props: {
    type: {
      type: String,
      default: 'pie',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    width: {
      type: [ String, Number ],
      default: 400,
    },
    height: {
      type: [ String, Number ],
      default: 400,
    },
    title: {
      type: [ String, Boolean ],
      default: false,
    },
  },

  data: () => ({
    chart: null,
  }),

  computed: {
    ...mapGetters({
      isShown: 'lithology/shown',
    }),
  },

  watch: {
    data () {
      this.initChart();
    },
    isShown () {
      this.$nextTick(() => {
        this.initChart();
      });
    },
  },

  mounted () {
    this.initChart();
  },

  methods: {
    initChart () {
      setTimeout(async () => {
        if (this.chart) {
          await this.chart.destroy();
        }

        const canvas = document.getElementById('canvas').getContext('2d');
        this.chart = new window.Chart(canvas, {
          type: this.type,
          data: {
            labels: this.getFormattedLabels(),
            datasets: this.getFormattedDatasets(),
          },
          options: {
            animation: {
              duration: 0,
            },
            responsive: true,
            maintainAspectRatio: false,
            zoomOutPercentage: 60,
            layout: {
              padding: 60,
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
              text: this.title,
              position: 'bottom',
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                title (tooltipItem, data) {
                  return data.labels[tooltipItem[0].index];
                },
                label (tooltipItem, data) {
                  const dataset = data.datasets[0];
                  const value = dataset.data[tooltipItem.index];
                  const meta = Object.values(dataset._meta).pop();
                  const percent = Math.round((value / meta?.total) * 100);
                  return `${percent}%`;
                },
              },
            },
            plugins: {
              outlabels: {
                text: '%l: %p',
                color: 'white',
                stretch: 14,
                font: {
                  resizable: true,
                  minSize: 10,
                  maxSize: 12,
                },
              },
            },
          },
        });
      }, 800);
    },

    getFormattedLabels () {
      return this.labels.map(label => label.replace('_Percentage', ''));
    },

    getFormattedDatasets () {
      return [{
        label: this.title,
        data: this.data,
        backgroundColor: PIE_CHART_COLORS,
        hoverOffset: 4,
      }];
    },
  },

  destroy () {
    this.chart.destroy();
  },
};
</script>
