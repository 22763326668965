<template>
  <v-layout class="py-5 align-center">
    <v-autocomplete
      v-model="project.currentFilter"
      :label="$trans('Projects')"
      :items="projects"
      dense filled
      outlined
      hide-details
      @change="onProjectChange"
    >
      <template v-slot:selection="{ item }">
        <v-avatar :color="item.color" left size="10"></v-avatar>
        <span class="ml-4 caption" v-text="item.text"></span>
      </template>
      <template v-slot:item="{ item }">
        <v-avatar :color="item.color" size="10"></v-avatar>
        <span class="ml-4 caption" v-text="item.text"></span>
      </template>
    </v-autocomplete>

    <div class="mx-2"></div>

    <v-autocomplete
      v-model="status.currentFilter"
      :label="$trans('Status')"
      :items="statusItems"
      dense filled
      outlined
      hide-details
      @change="onStatusChange"
    >
      <template v-slot:selection="{ item }">
        <span class="text-no-wrap">
          <v-avatar :color="item.color" left size="10"></v-avatar>
          <span class="mx-4 caption" v-text="item.text"></span>
        </span>
      </template>
      <template v-slot:item="{ item }">
        <v-avatar :color="item.color" size="10"></v-avatar>
        <span class="ml-4 caption" v-text="item.text"></span>
      </template>
    </v-autocomplete>

    <div class="mx-2"></div>

    <v-btn
      v-if="updateOnClick"
      depressed
      @click="filter"
      v-text="$trans('Filter')"
    ></v-btn>
    <v-btn
      v-if="hasFilters"
      depressed
      class="ml-2"
      @click="clearFilter"
      v-text="$trans('Clear Filter')"
    ></v-btn>
  </v-layout>
</template>

<script>
import {
  PROJECT_TYPE_ALL, STATUS_TYPE_ALL, files, statuses,
} from '@/modules/File/config/files';
import { values, isEmpty } from 'lodash';

export default {
  name: 'FileDataTableManagerFilterBar',

  props: {
    projectKey: {
      type: String,
      default: 'project',
    },
    statusKey: {
      type: String,
      default: 'status',
    },
    updateOnClick: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    project: files,
    status: statuses,
  }),

  computed: {
    projects () {
      const projects = this.$user.getManagedProjects().map(i => i.project);
      return this.project.projects.filter(i => projects.includes(i.value));
    },

    filters () {
      return {
        [this.projectKey]: this.projectValue,
        [this.statusKey]: this.statusValue,
      };
    },

    cleanFilters () {
      return {
        [this.projectKey]: null,
        [this.statusKey]: null,
      };
    },

    hasFilters () {
      return values(this.filters).filter(i => !isEmpty(i)).length > 0;
    },

    projectValue () {
      return this.project.currentFilter === PROJECT_TYPE_ALL
        ? this.project.projects.map(i => i.value).join(',')
        : this.project.currentFilter;
    },

    statusValue () {
      return this.status.currentFilter === STATUS_TYPE_ALL
        ? this.status.items.map(i => i.value).join(',')
        : this.status.currentFilter;
    },

    statusItems () {
      return this.status.items.map(i => ({ ...i, text: `${i.phaseOrder}. ${i.text}` }));
    },

    updateImmediately () {
      return !this.updateOnClick;
    },
  },

  mounted () {
    this.setCurrentFiltersFromRouteQuery();
  },

  methods: {
    setCurrentFiltersFromRouteQuery () {
      this.project.setCurrentFilter(this.$route.query.project);
      this.status.setCurrentFilter(this.$route.query.status);
    },

    onProjectChange (id) {
      this.$emit('project:change', id);

      if (this.updateImmediately) {
        this.filter();
      }
    },

    onStatusChange (id) {
      this.$emit('status:change', id);

      if (this.updateImmediately) {
        this.filter();
      }
    },

    filter () {
      this.$emit('click:filter', this.filters);
    },

    clearFilter () {
      this.project.unsetCurrentFilter();
      this.status.unsetCurrentFilter();
      this.$emit('click:filter', this.filters);
    },
  },
};
</script>
