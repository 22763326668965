import { BASE_URL } from '@config/api';

export const GET_BILLING_SUBSCRIPTION_URL = `${BASE_URL}/payments/subscription/`;
export const GET_BILLING_INVOICE_URL = `${BASE_URL}/payments/invoice/`;
export const GET_BILLING_GENERATE_UPGRADE_CODE_URL = `${BASE_URL}/payments/generate-upgrade-code/`;
export const GET_BILLING_UPGRADE_SUBSCRIPTION_URL = `${BASE_URL}/payments/upgrade-subscription/`;
export const POST_BILLING_CANCEL_SUBSCRIPTION_URL = `${BASE_URL}/payments/subscription/cancel/`;
export const POST_BILLING_RESUME_SUBSCRIPTION_URL = `${BASE_URL}/payments/subscription/resume/`;
export const GET_BILLING_PAYMENT_METHODS_URL = `${BASE_URL}/payments/methods/`;
export const PUT_BILLING_PAYMENT_METHODS_URL = `${BASE_URL}/payments/methods/`;
export const GET_BILLING_PAYMENT_CHECKOUT_SESSION_URL = `${BASE_URL}/payments/checkout-session/`;
export const GET_BILLING_PAYMENT_INTENT_URL = `${BASE_URL}/payments/intent/`;
export const POST_BILLING_PAYMENT_INTENT_URL = `${BASE_URL}/payments/intent/`;
export const POST_BILLING_PAYMENT_SETUP_INTENT_URL = `${BASE_URL}/payments/setup-intent/`;

export default Object.freeze({
  GET_BILLING_SUBSCRIPTION_URL,
  GET_BILLING_INVOICE_URL,
  GET_BILLING_GENERATE_UPGRADE_CODE_URL,
  GET_BILLING_UPGRADE_SUBSCRIPTION_URL,
  POST_BILLING_CANCEL_SUBSCRIPTION_URL,
  POST_BILLING_RESUME_SUBSCRIPTION_URL,
  GET_BILLING_PAYMENT_METHODS_URL,
  GET_BILLING_PAYMENT_CHECKOUT_SESSION_URL,
  GET_BILLING_PAYMENT_INTENT_URL,
  POST_BILLING_PAYMENT_INTENT_URL,
  POST_BILLING_PAYMENT_SETUP_INTENT_URL,
});
