<template>
  <v-hover v-slot="{ hover }">
    <v-navigation-drawer
      v-model="open"
      :width="mapWidth"
      eager
      app clipped
      bottom right
      v-bind="$attrs"
      class="sidebar-controls"
    >
      <template v-if="hover">
        <v-fab-transition>
          <v-btn
            v-if="resizable"
            absolute
            fab
            left
            top
            x-small
            class="mt-8 ml-n8"
            @click="toggleMapAreaContainerExpansion"
          >
            <v-icon v-if="expanded">mdi-chevron-double-right</v-icon>
            <v-icon v-else>mdi-chevron-double-left</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <slot></slot>
    </v-navigation-drawer>
  </v-hover>
</template>

<script>
import Breakpoints from '@core/mixins/Breakpoints';
import { isNil } from 'lodash';

export default {
  name: 'MapAreaContainer',

  mixins: [ Breakpoints ],

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    height: {
      type: [ String, Number ],
      default: '75vh',
    },

    width: {
      type: [ String, Number ],
      default: '320px',
    },

    resizable: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    expanded: false,
    expandedMapWidth: null,
  }),

  computed: {
    open: {
      get () {
        return this.value;
      },

      set (open) {
        this.$emit('input', open);
      },
    },

    mapWidth () {
      if (this.resizable && !isNil(this.expandedMapWidth)) {
        return this.expandedMapWidth;
      }

      if (!!this.width && this.width !== '320px') {
        return this.width;
      }

      const width = 620;
      switch (this.$vuetify.breakpoint.name) {
      case 'xs': return width - 50;
      case 'sm': return width - 50;
      case 'md': return width - 50;
      case 'lg': return width - 50;
      case 'xl': return width;
      default: return width;
      }
    },
  },

  mounted () {
    this.setDefaultNavDrawerState();
  },

  methods: {
    toggleMapAreaContainerExpansion () {
      this.expanded = !this.expanded;

      if (this.expanded) {
        this.expandedMapWidth = '70%';
      } else {
        this.expandedMapWidth = null;
      }
    },

    setDefaultNavDrawerState () {
      if (this.isMobile) {
        this.showNavDrawer();
      } else {
        this.hideNavDrawer();
      }
    },

    showNavDrawer () {
      this.$emit('input', false);
    },

    hideNavDrawer () {
      this.$emit('input', true);
    },
  },
};
</script>
