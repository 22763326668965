<template>
  <v-simple-table>
    <thead>
      <tr>
        <th colspan="100%" class="text-uppercase" v-text="$trans('Operator')"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="100%" v-text="item.operator"></td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'TableOperator',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
