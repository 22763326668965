<template>
  <!-- eslint-disable max-len -->
  <v-layout column class="text-center">
    <v-layout>
      <v-spacer></v-spacer>
      <img
        :src="$asset.getAppAsset('logos/combomark', { modifier: 'bolt.' })"
        width="300"
        class="text-center mt-8 mb-10"
      >
      <v-spacer></v-spacer>
    </v-layout>
    <h1 class="font-weight-normal my-5" v-text="$trans('Have access to this feature soon!')"></h1>
    <p
      class="lead muted--text"
      v-text="$trans(`{name} Bolt will help your transform up to {number} pages of unstructured data per month.`, {
        name: $app.appTitle,
        number: '30K',
      })"
    ></p>
    <v-layout justify-center class="mt-8">
      <section class="secondary--text pa-8">
        <h3 v-text="$trans('Convert unstructured data')"></h3>
        <div v-text="$trans('to usable data')"></div>
      </section>
      <v-divider vertical inset></v-divider>
      <section class="secondary--text pa-6">
        <h3 v-text="$trans('Search all extracted text')"></h3>
        <div v-text="$trans('and texts on images')"></div>
      </section>
      <v-divider vertical inset></v-divider>
      <section class="secondary--text pa-6">
        <h3 v-text="$trans('Extend your data discovery')"></h3>
        <div v-text="$trans('with API access')"></div>
      </section>
    </v-layout>
    <v-layout justify-center class="mt-10">
      <v-spacer></v-spacer>
      <v-btn
        href="https://www.irayaenergies.com/data-atelier-bolt/"
        target="_blank"
        x-large
        rounded
        color="secondary"
        class="text-capitalize px-8"
      ><span v-text="$trans('See all features')"></span></v-btn>
      <div class="mx-4"></div>
      <v-btn
        x-large
        rounded outlined
        class="text-capitalize px-10"
        @click="goToContactPage"
      ><span v-text="$trans('Talk to us!')"></span></v-btn>
      <v-spacer></v-spacer>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  name: 'DialogBoltAvailabilityPartialCard',

  methods: {
    async goToContactPage () {
      await this.$store.dispatch('billing/closeBoltAvailabilityWarningPrompt');
      this.$nextTick(() => {
        this.$router.push({ name: 'contact' });
      });
    },
  },
};
</script>
