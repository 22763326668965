<template>
  <app-content>
    <meta-tag :title="$trans('Talk to us')"></meta-tag>

    <v-row>
      <v-col cols="12" lg="10" class="mx-auto">
        <v-row>
          <v-col cols="12">
            <v-btn :to="$user.getRedirectRouteFromEnv()" text>
              <v-icon left small>mdi-arrow-left</v-icon>
              <span v-text="$trans('Back')"></span>
            </v-btn>
            <app-header>
              <template v-slot:page.title="{ page }">
                <h1 class="display-2">{{ page.title }}</h1>
              </template>
            </app-header>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="5">
            <form-contact class="mt-10"></form-contact>
          </v-col>
          <v-col cols="12" lg="7" class="text-center">
            <app-carousel
              :slides="slides"
              hide-delimiters
            ></app-carousel>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
export default {
  layout: 'web',

  meta: {
    layoutOptions: {
      columnWidth: 10,
    },
  },

  data: () => ({
    slides: [
      () => import('@/modules/About/components/Carousel/CallingCardCarouselItem'),
    ],
  }),
};
</script>
