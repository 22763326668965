<template>
  <div>
    <h3 class="section-content__title mb-2">
      <slot name="title"></slot>
    </h3>
    <app-text-loader
      :loading="loading"
      class="mb-4"
      width="200px" height="36px"
    >
      <slot></slot>
    </app-text-loader>
  </div>
</template>

<script>
export default {
  name: 'SetSimpleTableGrid',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
