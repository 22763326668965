<template>
  <v-list dense>
    <v-list-item :href="url" :title="$trans('Send feedback')" target="_blank">
      <v-list-item-action><v-icon>mdi-comment-alert</v-icon></v-list-item-action>
      <v-list-item-content><span v-text="$trans('Send feedback')"></span></v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { FEEDBACK_URL } from '@config/app';

export default {
  name: 'AppSendFeedbackBtn',

  computed: {
    url () {
      return FEEDBACK_URL;
    },
  },
};
</script>
