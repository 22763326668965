<template>
  <div ref="markdown" class="markdown__content">
    <slot></slot>
  </div>
</template>

<script>
import marked from 'marked';

export default {
  name: 'AppMarkdownPreview',

  mounted () {
    this.setupMarkdownContent();
    this.setupMarkdownStyles();
  },

  methods: {
    setupMarkdownContent () {
      const markdown = marked(this.$refs.markdown.innerHTML);
      this.$refs.markdown.innerHTML = markdown;
    },

    setupMarkdownStyles () {
      const tables = this.$refs.markdown.querySelectorAll('.table');
      tables.forEach(container => {
        const classNames = container.className;
        container.className = '';
        container.innerHTML = `<div class="v-data-table ${classNames} theme--light">
        <div class="v-data-table__wrapper">
        ${container.innerHTML}
        </div></div>`;
      });

      const innerHTML = this.$refs.markdown.innerHTML
        .replaceAll(':/:', '✔')
        .replaceAll(':x:', '✘');
      this.$refs.markdown.innerHTML = innerHTML;
    },
  },
};
</script>
