export default {
  fallbackDotModifier: 'default.',

  supportedLogoDotModifiers: [
    'apprentice.',
    'bolt.',
    'default.',
    'enterprise.',
    'forge.',
    'company.',
    'mini.'
  ],

  transformToSupported (modifier) {
    if (this.supportedLogoDotModifiers.includes(modifier)) {
      return modifier;
    }

    return this.fallbackDotModifier;
  },
};
