<template>
  <v-list v-if="isPetros" v-bind="$attrs" class="pt-0 mb-n4">
    <v-list-item>
      <v-list-item-content class="mx-auto">
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="8" class="py-0">
            <img
              :key="`key_${logo}`"
              :src="logo"
              :class="{
                'app-logo__mini': mini,
              }"
              width="100%"
              height="auto"
            >
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import MetaTag from '@core/mixins/MetaTag';
import logo from '@/assets/logo.png';
import logoDataAtelier from '@/assets/logo.data.atelier.svg';
import banner from '@/assets/logo.sidebar.png';
import bannerDataAtelier from '@/assets/banner.data.atelier.svg';
import { mapGetters } from 'vuex';

export default {
  mixins: [ MetaTag ],

  data: () => ({
    app: {
      logo,
      logoDataAtelier,
      banner,
      bannerDataAtelier,
    },
  }),

  computed: {
    ...mapGetters({
      mini: 'sidebar/mini',
    }),

    logo () {
      return this.$app.isAppMode('PETROS') ? require('@assets/logo.slogan.png') : ''
    },

    isPetros(){
      return this.$app.isAppMode('PETROS')
    }
  },
};
</script>

<style lang="scss">
.app-logo {
  &__mini {
    max-width: 60px;
  }
}
</style>
