<template>
  <v-row class="text-left mt-5">
    <v-col
      class="text-center"
      cols="12"
      xl="7"
      lg="7"
      md="12"
      sm="10"
    >
      <message-registration-page></message-registration-page>
    </v-col>
    <v-col
      cols="12"
      xl="5"
      lg="5"
      md="12"
      sm="10"
      class="mx-sm-auto"
    >
      <v-slide-y-transition mode="in-out">
        <v-alert v-if="form.hasErrors()" color="error" dark>
          <v-icon left>mdi-alert</v-icon>
          <span v-text="form.getErrors('message', 'An error occured')"></span>
        </v-alert>
      </v-slide-y-transition>

      <app-alert></app-alert>

      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <v-form
          v-model="form.valid"
          :disabled="form.loading"
          method="post"
          autocomplete="off"
          @submit.prevent="handleSubmit(submit)"
        >
          <v-card min-width="320px" max-width="620px" class="mx-auto">
            <v-card-title class="align-start">
              <v-layout column align-start>
                <h2>{{ $trans('Create an account') }}</h2>
                <p style="font-weight: normal;">
                  {{ $trans('Already a user?') }}
                  <router-link :to="{ name: 'login' }">{{ $trans('Sign in') }}</router-link>
                </p>
              </v-layout>

              <img
                :src="$asset.getAppAsset('logos/combomark', {
                  modifier: 'company.',
                  appMode: false,
                })"
                width="200"
                height="auto"
              >
            </v-card-title>
            <v-card-text>
              <validation-provider
                v-slot="{ errors }" name="email"
                rules="required|email"
                mode="aggressive"
              >
                <v-text-field
                  v-model="form.fields.email"
                  :error-messages="errors"
                  :label="$trans('Email')"
                  outlined
                  type="email"
                  name="email"
                ></v-text-field>
              </validation-provider>
              <!-- eslint-disable max-len -->
              <validation-provider
                v-slot="{ errors }" name="password"
                vid="password"
                rules="required|has_uppercase|has_lowercase|has_number|min:8"
                mode="aggressive"
              >
                <app-password-field
                  v-model="form.fields.password"
                  :error-messages="errors"
                  :label="$trans('Password')"
                  outlined
                  name="password"
                ></app-password-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }" name="confirmation_password"
                vid="confirmation_password"
                rules="required|confirmed:password"
                mode="aggressive"
              >
                <app-password-field
                  v-model="form.fields.confirmation_password"
                  :error-messages="errors"
                  :label="$trans('Confirm password')"
                  outlined
                  name="confirmation_password"
                ></app-password-field>
              </validation-provider>

              <v-row>
                <v-col cols="12" lg="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="first name"
                    rules="required"
                    mode="aggressive"
                  >
                    <v-text-field
                      v-model="form.fields.first_name"
                      :error-messages="errors"
                      :label="$trans('First name')"
                      outlined
                      name="first_name"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="last name"
                    rules="required"
                    mode="aggressive"
                  >
                    <v-text-field
                      v-model="form.fields.last_name"
                      :error-messages="errors"
                      :label="$trans('Last name')"
                      outlined
                      name="last_name"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>

              <validation-provider
                v-slot="{ errors }" name="organization"
                rules="required"
                mode="aggressive"
              >
                <v-text-field
                  v-model="form.fields.organization"
                  :error-messages="errors"
                  :label="$trans('Organization')"
                  outlined
                  name="organization"
                ></v-text-field>
              </validation-provider>

              <v-layout align-start class="mb-8">
                <btn-google-recaptcha-v3
                  v-slot:default="{ token, recaptcha }"
                  @update:token="token => form.verifyRecaptcha(token)"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{ required: { allowFalse: false } }"
                    name="recaptcha_check"
                    mode="aggressive"
                  >
                    <v-checkbox
                      v-model="form.fields.recaptcha_check"
                      :label="$trans('I\'m not a Robot')"
                      :error-messages="errors"
                      :disabled="form.meta.recaptcha.disabled"
                      :hint="form.meta.recaptcha.hint"
                      name="recaptcha_check"
                      persistent-hint
                      @change="recaptcha"
                    >
                      <template v-slot:message="{ message, key }">
                        <span :key="key" v-html="message"></span>
                      </template>
                    </v-checkbox>
                  </validation-provider>
                </btn-google-recaptcha-v3>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-5">
                <v-spacer></v-spacer>
                <v-btn
                  :loading="form.loading"
                  :disabled="form.isLoadingOr(invalid)"
                  x-large block
                  width="132"
                  color="primary"
                  type="submit"
                >
                  {{ $trans('Sign up') }}
                  <template v-slot:loader>
                    <v-slide-x-transition>
                      <v-icon small left dark>mdi-loading mdi-spin</v-icon>
                    </v-slide-x-transition>
                    <span>{{ $trans('Sign up') }}</span>
                  </template>
                </v-btn>
              </v-layout>
              <div class="text--disabled caption mt-6">
                <div>
                  {{ $trans('By signing up, you agree that you have read and accepted our ') }}
                  <router-link
                    :to="{ name: 'legal.agreement' }"
                    target="_blank"
                    class="caption"
                  >{{ $trans('Terms of Use') }}</router-link>
                  {{ $trans('and') }}
                  <router-link
                    :to="{ name: 'legal.privacy' }"
                    target="_blank"
                    class="caption"
                  >{{ $trans('Privacy Policy') }}</router-link>.
                </div>
                <div class="mt-3">
                  {{ $trans('This site is protected by reCAPTCHA and the Google') }}
                  <a
                    target="_blank"
                    class="caption"
                    href="https://policies.google.com/privacy"
                  >{{ $trans('Privacy Policy') }}</a> {{ $trans('and') }}
                  <a
                    target="_blank"
                    class="caption"
                    href="https://policies.google.com/terms"
                  >{{ $trans('Terms of Service') }}</a> {{ $trans('apply') }}.
                </div>
              </div>
            </v-card-text>
            <v-divider inset class="mx-auto mt-4"></v-divider>
            <div class="px-5 pt-4 mb-5 body-2 text-center muted--text">
              <div
                class="text-caption"
                v-html="$trans('register.problem', {
                  email: $app.supportEmail,
                })"
              ></div>
            </div>
          </v-card>
        </v-form>
      </validation-observer>
      <v-card
        flat min-width="320px"
        max-width="620px"
        class="mx-auto mb-10" color="transparent"
      >
        <auth-footer class="text-center"></auth-footer>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Auth from '@/modules/Auth/resources/Auth';
import BeforeAuthRouteEnter from '@core/mixins/BeforeAuthRouteEnter';
import Breakpoints from '@core/mixins/Breakpoints';

export default {
  layout: 'auth',

  mixins: [ BeforeAuthRouteEnter, Breakpoints ],

  data: () => ({
    form: new Auth,
    style: [
      'filter: drop-shadow(6px 11px 54px white);',
      'transform: rotateY(180deg);',
    ].join(' '),
  }),

  computed: {
    validator () {
      return this.$refs?.signup;
    },
  },

  methods: {
    submit () {
      this.form.setValidator(this.validator).register();
    },
  },
};
</script>
