<template>
  <div class="bbox-click-card">
    <v-slide-y-reverse-transition mode="in-out">
      <div v-if="clicked" class="bbox-click-card__actions text-right">
        <v-btn
          icon
          x-small
          class="error white--text"
          @click="unselect"
        >
          <v-icon x-small>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-slide-y-reverse-transition>
    <div
      role="button"
      class="bbox-click-card__activator"
      @click.stop="onClick"
    ></div>
    <slot v-bind="{ clicked, notYetClicked, click: onClick }"></slot>
  </div>
</template>

<script>
export default {
  name: 'BboxClickCard',

  data: () => ({
    notYetClicked: true,
    clicked: false,
  }),

  computed: {
    boundOptions () {
      return {
        clicked: this.clicked,
        notYetClicked: this.notYetClicked,
      };
    },
  },

  methods: {
    onClick () {
      this.clicked = !this.clicked;
      this.notYetClicked = false;
      this.emitClick();
    },

    select () {
      this.clicked = true;
      this.notYetClicked = false;
    },

    unselect () {
      this.clicked = false;
      this.notYetClicked = false;
    },

    emitClick () {
      this.$emit('click', this.boundOptions);
      this.$emit('click:item', this.boundOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
.bbox-click-card {
  position: relative;

  &__activator {
    background: transparent;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__actions {
    height: auto;
    position: absolute;
    right: -20px;
    top: -12px;
  }
}
</style>
