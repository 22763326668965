<template>
  <v-simple-table dense>
    <tbody>
      <tr>
        <td>
          <span class="caption font-weight-bold text-uppercase">
            {{ $trans('Water Depth') }}
          </span> : {{ item.attributes.water_depth + 'm' }}
        </td>
        <td>
          <span class="caption font-weight-bold text-uppercase">
            {{ $trans('Derrick Floor Elevation') }}
          </span> : {{ item.attributes.derrick_floor_elev + 'm' }}
        </td>
      </tr>
      <tr>
        <td>
          <span class="caption font-weight-bold text-uppercase">
            {{ $trans('Rotary Table') }}
          </span> : {{ item.attributes.rotary_table + 'm' }}
        </td>
        <td>
          <span class="caption font-weight-bold text-uppercase">
            {{ $trans('Kelly Bushing') }}
          </span> : {{ item.attributes.kelly_bushing + 'm' }}
        </td>
      </tr>
      <tr>
        <td>
          <span class="caption font-weight-bold text-uppercase">
            {{ $trans('TVDSS (Subsea)') }}
          </span> : {{ item.attributes.tvdss + 'm' }}
        </td>
        <td>
          <span class="caption font-weight-bold text-uppercase">
            {{ $trans('TVD') }}</span> : {{ item.attributes.tvd + 'm' }}
        </td>
      </tr>
      <tr>
        <td>
          <span class="caption font-weight-bold text-uppercase">
            {{ $trans('Total/Measured Depth (TD/MD)') }}
          </span> : {{ item.attributes.total_depth + 'm' }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'DetailDepthInfo',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
