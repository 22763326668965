export const state = {
  openMenu: true,
};

export const getters = {
  openMenu: state => state.openMenu,
};

export const mutations = {
  TOGGLE_MENU (state) {
    state.openMenu = !state.openMenu;
  },
};

export const actions = {
  toggleMenu ({ commit }) {
    commit('TOGGLE_MENU');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
