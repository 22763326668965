<template>
  <keep-alive>
    <template v-if="loading">
      <v-skeleton-loader
        class="ov-hidden"
      >
        <v-card
          v-for="i in count" :key="i"
          :height="$props.height"
          :width="$props.width"
          flat
          class="ov-hidden"
          v-bind="$attrs"
        >
          <div class="v-skeleton-loader__app-text rounded v-skeleton-loader__bone"></div>
        </v-card>
      </v-skeleton-loader>
    </template>
    <div v-else><slot></slot></div>
  </keep-alive>
</template>

<script>
import { VSkeletonLoader } from 'vuetify/lib';

export default {
  name: 'AppTextLoader',

  extends: VSkeletonLoader,

  props: {
    count: {
      type: [ String, Number ],
      default: 1,
    },

    width: {
      type: [ String, Number ],
      default: '100%',
    },

    height: {
      type: [ String, Number ],
      default: 18,
    },

    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.v-skeleton-loader__app-text {
  background: rgba(0, 0, 0, 0.12);
  height: 100vh;
}
</style>
