import AppTabsMenuRegistrar from '@core/registrars/AppTabsMenuRegistrar';
import AboutManifest from '@/modules/About/manifests/AboutManifest';
import { $app } from '@config/app';
import { kebabCase } from 'lodash';

const menus = AboutManifest.items
  .filter(({ mode }) => mode.includes($app.appMode))
  .map((item, i) => ({
    ...item,
    text: item.title,
    key: `${kebabCase(item.title)}-${Date.now()}`,
    sort: i,
  }));

AppTabsMenuRegistrar.register(menus);
