<template>
    <div
      class="search-result-card__thumbnail mb-6"
    >
      <v-card>
        <v-card-text class="px-4 ov-y-auto">
          <v-row>
            <v-col cols="12">
              <div  v-if="chatbot.loading" >
                <div :style="{ whiteSpace: 'pre-line' }" class="mb-0 pb-1" v-text="'Generating summary for favorite items. Please wait...'"/>
                <v-skeleton-loader type="list-item-three-line"/>
              </div>
              <div v-else>
                <div v-html="markdownText" class="markdown-content"></div>
                <v-row justify="end" class="mx-1 mb-1 mt-8">
                  <v-col cols="9" class="pa-0">
                    <div class="font-weight-light" style="font-size: 12px;">This is an AI generated response and may incur errors.<br/> Please check the outputs before using.</div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="chatbot.meta.hasTables"
                        icon
                        class="mx-1"
                        v-on="on"
                        @click="exportCsv"
                      >
                        <v-icon>mdi-file-delimited-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Export Tables as CSV</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        class="mx-1"
                        v-on="on"
                        @click="copyText"
                      >
                        <v-icon>mdi-clipboard-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Copy response</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        class="mx-1"
                        v-on="on"
                      >
                        <v-icon>mdi-thumb-up-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>This response is good</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        class="mx-1"
                        v-on="on"
                      >
                        <v-icon>mdi-message-draw</v-icon>
                      </v-btn>
                    </template>
                    <span>This response needs review</span>
                  </v-tooltip>
                </v-row>
              </div>

            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4">
        </v-card-actions>
      </v-card>
      <v-snackbar
        v-model="showSnack"
        timeout="3000"
        color="success"
      >Copied to clipboard</v-snackbar>
    </div>
</template>
  
<script>
  import Chatbot from '@/modules/Search/resources/Chatbot';
  import marked from 'marked';

  export default {
    name: 'CategorySearchSummary',
    props: {
      favorites:{
        type: Array,
        default: []
      },
      generated: {
        type: Boolean,
        default: false
      }
    },
  
    data: () => ({
      chatbot: new Chatbot,
      showSnack: false,
    }),

    async mounted(){
      if(!this.generated){
        this.chatbot.getFavoritesSummary(this.favorites)
      }
    },
  
    computed: {
      markdownText(){
        marked.setOptions({
          gfm: true,
          breaks: true
        });
        return marked(this.chatbot.data.data.summary)
      }
    },
  
    methods: {
      copyText(){
        this.showSnack = true
        this.chatbot.copyToClipboard(this.chatbot.data.data.summary)
      },
      exportCsv(){
        this.chatbot.meta.tables.map((table, index) => {
          const csvData = this.chatbot.convertToCsv(table)
          this.chatbot.exportCsvFile(`table_${index}`, csvData)
        })
      }
    },
  };
</script>

<style>
.search-result-card__thumbnail {
  border: 3px solid transparent;
  transition: all 0.05s ease-in;
}

.markdown-content table {
  border-collapse: collapse;
  width: 100%;
}

.markdown-content th,
.markdown-content td {
  border: 1px solid rgb(221, 221, 221);
  padding: 8px;
}

.markdown-content th {
  background-color: rgb(242, 242, 242);
  font-weight: bold;
  text-align: left;
}

.markdown-content tr:nth-child(even) {
  background-color: rgb(249, 249, 249);
}
</style>