import DataResource from '@core/resources/DataResource';
import { GET_WORD_FREQUENCY } from '@/modules/Report/api/data-aggregations';
import { isEmpty } from 'lodash';

export default class WordCloud extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async listFrequentWords () {
    this.startLoading();

    const params = {
      doc_ref__id: this.route.params.slug,
      wf_type: 'lithology',
      page_size: this.meta.selectedWordCount,
    };
    const { data } = await this.axios.get(GET_WORD_FREQUENCY, { params });

    if (!isEmpty(data.data)) {
      this.meta.words = data.data[0].attributes.frequency_distribution;
      this.meta.total = data.data[0].attributes.total_terms;

      this.setItems(this.meta.words.map(word => ({
        text: word.term,
        frequency: word.frequency,
        percent: parseFloat((word.frequency * 100) / this.meta.total).toFixed(2),
      })));

      this.meta.topFiveTerms = this.items.splice(0, 5);
    }

    this.stopLoading();
  }

  hasOtherTerms () {
    return this.size() > 0;
  }

  isTermsEmpty () {
    return isEmpty(this.meta.words);
  }
}
