import Config from '@core/resources/Config';
import APP from '@core/enums/App';

import {
  sumBy,
  includes,
  kebabCase,
  groupBy,
  flatten,
  sortBy,
} from 'lodash';

export const BUCKET_TYPE_ALL = 'All';
export const BUCKET_TYPE_PUBLIC_DATABASE = 'Public Databases';
export const BUCKET_TYPE_PRIVATE = 'Private';
export const BUCKET_TYPE_PUBLICATIONS = 'Publications';
export const BUCKET_TYPE_IMAGES = 'Images';
export const BUCKET_TYPE_NA = 'N/A';
export const DEFAULT_ACTIVE_BUCKET_TAB = 'all';
export const DEFAULT_ACTIVE_BUCKET_TAB_INDEX = 0;

export let items = [
  {
    sort: 1,
    text: 'All',
    key: BUCKET_TYPE_ALL,
    code: kebabCase('All'),
    color: 'blue',
    chip: false,
    eager: true,
    showMap: true,
    showHeatMap: true,
    showKnowledgeGraph: false,
    sourceTypes: [ BUCKET_TYPE_ALL ],
    component: () => import('@/modules/Search/components/Category/CategorySearchAllTabItem'),
  },
  {
    sort: 2,
    text: 'Documents',
    key: BUCKET_TYPE_PUBLIC_DATABASE,
    code: kebabCase('Documents'),
    color: 'blue',
    chip: false,
    eager: false,
    showMap: true,
    showHeatMap: true,
    showKnowledgeGraph: true,
    sourceTypes: [ BUCKET_TYPE_PUBLIC_DATABASE, BUCKET_TYPE_PRIVATE ],
    component: () => import('@/modules/Search/components/Category/CategorySearchDocumentsTabItem'),
  },
  {
    sort: 3,
    text: 'Publications',
    key: BUCKET_TYPE_PUBLICATIONS,
    code: kebabCase('Publications'),
    color: 'accent',
    chip: false,
    eager: false,
    showMap: true,
    showHeatMap: false,
    showKnowledgeGraph: true,
    sourceTypes: [ BUCKET_TYPE_PUBLICATIONS ],
    component: () => import('@/modules/Search/components/Category/CategorySearchPublicationsTabItem'),
  },
  {
    sort: 4,
    text: 'Images',
    key: BUCKET_TYPE_IMAGES,
    code: kebabCase('Images'),
    color: 'blue',
    chip: false,
    eager: false,
    showMap: false,
    showHeatMap: false,
    showKnowledgeGraph: false,
    sourceTypes: [ BUCKET_TYPE_IMAGES ],
    component: () => import('@/modules/Search/components/Category/CategorySearchImagesTabItem'),
  },
];

if (APP.MODE=='DATA_ATELIER'){
  items = items.filter(i => i.text!='Publications');
}

export default new Config({
  items: sortBy(items.map((item, index) => ({ ...item, index })), 'sort'),
  currentIndex: DEFAULT_ACTIVE_BUCKET_TAB,
  current: items[DEFAULT_ACTIVE_BUCKET_TAB_INDEX],
  tabKey: DEFAULT_ACTIVE_BUCKET_TAB_INDEX,
  find (key) {
    return this.items.find(i => i.key === key);
  },
  findByIndex (index) {
    return this.items.find((i, j) => j === parseInt(index, 10));
  },
  findBy (value, key = 'code') {
    return this.items.find(i => i[key] === value);
  },
  setItems (items) {
    this.items = items.map((item, index) => ({ ...item, index }));
    return this;
  },
  setCurrentToDefault () {
    this.setCurrentIndex(DEFAULT_ACTIVE_BUCKET_TAB_INDEX);
  },
  setCurrent (current) {
    this.current = current;
    return this;
  },
  setCurrentIndex (index) {
    this.currentIndex = index;
    this.current = this.findBy(index);
    return this;
  },
  getCurrent () {
    return this.current;
  },
  isCurrentIndex (index) {
    return this.currentIndex === index;
  },
  canCurrentShowHeatmap () {
    return this.current?.showHeatMap ?? false;
  },
  setChipFromAggregations (aggregations) {
    const { buckets = [] } = aggregations?.project_type ?? {};

    this.setChipForBuckets(buckets ?? []);
    this.setChipForAll(buckets ?? []);
    this.setChipForImages(aggregations?.img_tag_histo?.buckets ?? []);

    this.incrementTabKey();
  },
  setChipForAll (buckets) {
    const all = this.find(BUCKET_TYPE_ALL);
    if (all) {
      all.chip = sumBy(buckets, 'doc_count');
    }
  },
  setChipForImages (buckets) {
    const image = this.find(BUCKET_TYPE_IMAGES);
    if (image) {
      image.chip = sumBy(buckets.filter(item => item.key !== BUCKET_TYPE_NA), 'doc_count');
    }
  },
  setChipForBuckets (buckets) {
    this.items = this.items.map(i => ({
      ...i,
      chip: sumBy(buckets.filter(b => includes(i.sourceTypes, b.key)), 'doc_count'),
    }));
  },
  setResultsFromAggregations (aggregations, items) {
    const groupedItems = groupBy(items, 'attributes.project_type');
    this.items = this.items.map(item => {
      const grouped = Object.keys(groupedItems).map(i => ({ key: i, results: groupedItems[i] }));
      const aggregation = grouped.filter(i => includes(item.sourceTypes, i.key));
      return { ...item, chip: flatten(aggregation.map(i => i.results)) ?? [] };
    });
  },
  getByIndex (index, key = null) {
    const item = this.findBy(index);

    return key ? item?.[key] : item;
  },
  incrementTabKey () {
    this.tabKey++;
    return this;
  },
});
