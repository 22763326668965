import Set from '@/modules/File/resources/Set';

export default async function isNotSubmittedForApproval (to, from, next) {
  const set = new Set;
  const { id } = to.params;

  await set.find(id);

  if (set.isSubmittedForApproval()) {
    return next({
      name: 'sets.show',
      params: { id },
      replace: true,
    });
  }

  return next();
}
