<template>
  <portal to="header:action">
    <has-module name="file">
      <app-can-view code="uploader_manage_files">
        <div class="my-5">
          <v-btn
            :to="{
              name: 'datalake.upload',
              query: {
                tab: 'uploaded',
              },
            }"
            data-tour-step="welcome/onboarding.1"
            data-tour-overlay
            large
            outlined
            color="primary"
            @click="onClick"
          >
            <v-icon left>mdi-upload</v-icon>
            <span v-text="$trans('Upload files')"></span>
          </v-btn>
          <tour-main-system
            v-if="isUploaderTourShown"
            auto-start
            name="uploader" @click:stop="onStopClick"
          ></tour-main-system>
        </div>
      </app-can-view>
    </has-module>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FileUploadBtnSystemTray',

  computed: {
    ...mapGetters({
      isUploaderTourShown: 'file/isUploaderTourShown',
    }),
  },

  methods: {
    onStopClick () {
      this.$store.dispatch('file/hideUploaderTour');
    },

    onClick () {
      this.$store.dispatch('file/showUploadForm');
      this.$root.$emit('file/upload.button:click');
    },
  },
};
</script>
