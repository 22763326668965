<template>
  <v-dialog
    v-model="show"
    :max-width="dialog.maxWidth"
    :persistent="persistent"
    :width="dialog.width"
    scrollable
  >
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-card :dark="dialog.dark" :class="{ 'text-center': dialog.alignment == 'center' }">
        <v-layout v-if="dialog.closeable" class="dialog__systembar pa-2">
          <v-spacer></v-spacer>
          <v-btn
            small text
            icon
            @click="onCancelClick(dialog.buttons.cancel)"
          ><v-icon>mdi-close</v-icon></v-btn>
        </v-layout>
        <v-card-text>
          <slot name="illustration">
            <div
              v-if="dialog.illustration"
              :class="`${dialog.color}--text`"
              class="text-center pa-3"
            >
              <component
                :is="dialog.illustration"
                :height="dialog.illustrationHeight"
                :width="dialog.illustrationWidth"
              ></component>
            </div>
          </slot>
          <v-card-title v-if="dialog.title" class="px-0">
            <slot name="title"><h1 class="title">{{ $trans(dialog.title) }}</h1></slot>
          </v-card-title>
          <slot name="text"><div v-if="text" v-html="text"></div></slot>
          <slot name="prompt">
            <template v-if="dialog.prompt.show">
              <component
                :is="dialog.prompt.component"
                v-if="dialog.prompt.component"
                v-bind="dialog.prompt.attrs"
              ></component>
              <template v-else>
                <validation-provider
                  v-slot="{ errors }"
                  :name="dialog.prompt.name"
                  :rules="dialog.prompt.rules"
                  :mode="dialog.prompt.mode"
                >
                  <v-text-field
                    v-model="promptModel"
                    :error-messages="errors"
                    v-bind="dialog.prompt.attrs"
                  ></v-text-field>
                </validation-provider>
              </template>
            </template>
          </slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            v-if="dialog.buttons.cancel.show"
            :color="dialog.buttons.cancel.color"
            text
            @click.native="onCancelClick(dialog.buttons.cancel)"
            v-text="$trans(dialog.buttons.cancel.text)"
          >
          </v-btn>

          <v-btn
            v-if="dialog.buttons.action.show"
            :color="dialog.buttons.action.color"
            :disabled="invalid"
            :loading="dialog.loading"
            text
            @click="onActionClick(dialog.buttons.action)"
            v-text="$trans(dialog.buttons.action.text)"
          >
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { clone } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'AppDialogbox',

  props: {
    width: {
      type: Number,
      default: 420,
    },
  },

  computed: {
    ...mapGetters({
      dialog: 'dialog/dialog',
    }),

    show: {
      get () {
        return this.dialog.show;
      },
      set (val) {
        this.$store.dispatch('dialog/prompt', { show: val });
      },
    },

    persistent () {
      return clone(this.dialog.persistent);
    },

    text () {
      if (this.dialog.text instanceof Array) {
        return this.dialog.text.map(text => this.$trans(text)).join('');
      }

      return this.dialog.text;
    },

    promptModel: {
      get () {
        return this.dialog.prompt.model;
      },
      set (val) {
        this.$store.dispatch('dialog/updatePromptModel', val);
      },
    },
  },

  methods: {
    onActionClick (action) {
      action.callback(this.$store);
      if (action.closeOnClick) {
        this.$store.dispatch('dialog/close');
      }
    },

    onCancelClick (cancel) {
      cancel.callback(this.$store);
      if (cancel.closeOnClick) {
        this.$store.dispatch('dialog/close');
      }
    },
  },
};
</script>
