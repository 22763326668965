<template>
  <panzoom-pdf-previewer zoom-bar>
    <template v-slot:toolbar.title>
      <span>TODO: moved from reprots/_slug.vue</span>
      <v-btn small class="ml-3"><v-icon left>mdi-download</v-icon>Download</v-btn>
      <v-spacer></v-spacer>
      <app-search-field dense rounded></app-search-field>
      <!-- <v-toolbar-title v-text="datalake.data.attributes.doc_name"></v-toolbar-title> -->
    </template>
  </panzoom-pdf-previewer>
</template>

<script>
export default {
  name: 'DatalakeReportViewer',
};
</script>
