<template>
  <v-card flat class="transparent text-center mx-auto">
    <h1
      class="font-weight-light"
      v-html="$trans('Own the power of your <span class=accent--text>data</span> with')"
    ></h1>
    <h1 class="display-1 font-weight-light">
      <span class="secondary--text font-weight-bold" v-text="$app.appTitle"></span>
    </h1>
    <div class="text-center mx-auto secondary--text my-10">
      <app-illustration
        src="IconPlantWithBackground"
        class="text-center mx-auto"
      ></app-illustration>
    </div>
    <v-card-text class="text-left">
      <v-card flat class="mx-auto transparent" width="320">
        <v-layout
          v-for="(item, i) in items" :key="i"
          align-center
          class="mb-6"
        >
          <div class="mr-6"><v-icon>{{ item.icon }}</v-icon></div>
          <div v-html="item.text"></div>
        </v-layout>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CallingCardCarouselItem',

  data: () => ({
    illustration: () => import('@core/components/Icons/IconSingleLeaf.vue'),

    items: [
      {
        icon: 'mdi-map-marker-outline',
        text: `<addresss>
        Suite 8.01 Level 8, Menara Binjai
        No. 2 Jalan Binjai
        50450 Kuala Lumpur, Malaysia
        </address>`,
      },
      {
        icon: 'mdi-phone-outline',
        text: '+603 2386 7741',
      },
      {
        icon: 'mdi-linkedin',
        text: 'Get in touch at <a target="_blank" href="https://www.linkedin.com/company/irayaenergies">Linked<strong>in</strong></a>',
      },
    ],
  }),
};
</script>
