<template>
  <v-data-iterator
    v-bind="{ ...computedProps, ...$attrs }"
    v-on="$listeners"
  >
    <template v-slot:loading>
      <images-data-iterator-loading-state
        :height="height"
        :items-count="24"
        :sizes="sizes"
      ></images-data-iterator-loading-state>
    </template>
    <template v-slot:no-results>
      <slot name="no-results">
        <images-data-iterator-empty-state></images-data-iterator-empty-state>
      </slot>
    </template>
    <template v-slot:no-data>
      <slot name="no-data">
        <images-data-iterator-empty-state></images-data-iterator-empty-state>
      </slot>
    </template>
    <template v-slot:default="props">
      <v-row>
        <v-col
          v-for="(item, image_id) in props.items"
          :key="item.name"
          :sm="sizes.sm"
          :md="sizes.md"
          :lg="sizes.lg"
          @click="selectItem({
            item,
            select: props.select,
            isSelected: props.isSelected,
          })"
        >
          <v-lazy transition="slide-y-reverse-transition">
            <slot
              name="item"
              v-bind="{
                ...props,
                item,
                height,
                selected: props.isSelected(item),
              }"
            >
              <images-data-iterator-thumbnail
                :id="`image-thumbnail-${image_id}`"
                :height="height"
                :src="item.attributes.thumbnail_url"
              >
                <template v-slot:default="{ hover }">
                  <v-layout
                    fill-height align-center
                    align-content-center
                    justify-center
                    column
                  >
                    <v-overlay :value="hover" absolute title="images thumb overlay">
                      <template v-if="hasOverlay">
                        <slot name="overlay" v-bind="{ item, hover, isSelected: isSelected(item) }">
                          <v-layout fill-height column justify-center>
                            <slot name="button.link" v-bind="{ item }">
                              <v-btn
                                :to="{
                                  name: 'images.single',
                                  params: { slug: item.id },
                                }" 
                                exact
                                dark
                                small outlined
                                class="mb-4"
                              >
                                <v-icon left small>mdi-magnify-plus-outline</v-icon>
                                {{ $trans('Zoom in') }}
                              </v-btn>
                            </slot>
                            <slot name="button.download" v-bind="{ item, download }">
                              <v-btn
                                dark
                                small outlined
                                class="mb-4"
                                @click="download(item)"
                                title="images overlay button download"
                              >
                                <v-icon left small>mdi-download</v-icon>
                                {{ $trans('Download') }}
                              </v-btn>
                            </slot>
                            <slot name="button.go-to-page" v-bind="{ item }">
                              <!-- project_type: item.attributes.project_type, -->
                              <v-btn
                                :to="{
                                  name: 'reports.single',
                                  params: { slug: item.attributes.doc_ref },
                                  query: {
                                    page_num: item.attributes.page_num,
                                    data_index: item.data_index,
                                    back: $route.fullPath,
                                  },
                                }"
                                dark
                                small outlined
                                class="mb-4"
                                title="images overlay button goto"
                              >
                                <v-icon left small>mdi-file-eye-outline</v-icon>
                                {{ $trans(`Go to page {page}`, {
                                  page: item.attributes.page_num,
                                }) }}
                              </v-btn>
                            </slot>
                          </v-layout>
                        </slot>
                      </template>
                    </v-overlay>
                  </v-layout>
                </template>

                <template v-slot:append>
                  <v-layout class="caption text-truncate text--disabled">
                    <router-link
                      :title="item.attributes.doc_title"
                      :to="{
                        name: 'reports.single',
                        params: { slug: item.attributes.doc_ref },
                        query: {
                          page_num: item.attributes.page_num,
                          project_type: item.attributes.project_type,
                          data_index: item.data_index,
                          back: $route.path,
                        },
                      }"
                      class="caption text-truncate text--disabled"
                      v-text="item.attributes.doc_title"
                    ></router-link>
                    <v-spacer></v-spacer>
                    <span
                      v-text="`${$trans('Page')}: ${item.attributes.page_num}`"
                    ></span>
                  </v-layout>
                  <slot name="item.append" v-bind="{ item }"></slot>
                </template>
              </images-data-iterator-thumbnail>
            </slot>
          </v-lazy>
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer="props">
      <slot name="footer" v-bind="props"></slot>
    </template>
  </v-data-iterator>
</template>

<script>
import { VDataIterator } from 'vuetify/lib';

export default {
  name: 'ImagesDataIterator',

  extends: VDataIterator,

  props: {
    selectable: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    noOverlay: {
      type: Boolean,
      default: false,
    },

    itemsPerRow: {
      type: Number,
      default: 6,
    },

    height: {
      type: [ Number, String ],
      default: 265,
    },

    colSizes: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    computedProps () {
      return {
        ...this.$props,
        height: this.height,
      };
    },

    sizes () {
      const size = 12 / this.itemsPerRow;

      return {
        ...{
          sm: size * 3,
          md: size * 2,
          lg: size,
        },
        ...this.colSizes,
      };
    },

    hasOverlay () {
      return !this.noOverlay;
    },
  },

  methods: {
    download (item) {
      this.$emit('download', item);
    },

    selectItem ({ item, select, isSelected }) {
      select(item, !isSelected(item));
      this.$emit('selected:item', { item, isSelected: isSelected(item) });
    },

    randomImageId (imageId) {
      return (Math.random() * Date.now() * imageId).toString().substring(3, 8);
    },
  },
};
</script>
