import { APP_TITLE } from '@config/app';

export default [
  {
    name: 'about',
    title: 'About',
    description: `View information about ${APP_TITLE} and most recent updates`,
    icon: 'mdi-information-outline',
    sort: 50,
    query: {
      tab: 'new',
    },
    meta: {
      attrs: {
        'data-tour-step': 'welcome/onboarding.9',
        'data-tour-overlay': true,
      },
    },
  },
];
