<template>
  <v-app v-cloak class="s-app workspace">
    <app-snackbar></app-snackbar>

    <v-slide-y-transition mode="in-out">
      <v-main>
        <v-img src="@/assets/patterns/triwhite.png">
          <v-container class="text-center">
            <v-slide-y-transition mode="out-in">
              <router-view></router-view>
            </v-slide-y-transition>
          </v-container>
        </v-img>
      </v-main>
    </v-slide-y-transition>
  </v-app>
</template>

<script>
import BeforeAuthRouteEnter from '@core/mixins/BeforeAuthRouteEnter';

export default {
  name: 'Auth',

  transitionMode: 'out-in',

  mixins: [ BeforeAuthRouteEnter ],
};
</script>
