<template>
  <div>
    <h1
      class="display-1 secondary--text font-weight-bold"
      v-text="$trans('Read your data better')"
    ></h1>
    <h1
      style="font-weight: 300;"
      v-text="$trans('with the right plan')"
    ></h1>

    <v-simple-table class="mt-15 featured-tier-table striped transparent">
      <thead>
        <tr>
          <td></td>
          <td class="pa-4 featured-tier featured-tier--tier1 white--text">
            <div class="title overline text-uppercase" v-text="$trans('Apprentice')"></div>
            <h1 v-text="$trans('Free')"></h1>
          </td>
          <td class="pa-4 featured-tier featured-tier--tier2 white--text">
            <div class="title overline text-uppercase" v-text="$trans('Enterprise')"></div>
            <h1 v-text="$trans('Get in touch')"></h1>
          </td>
        </tr>
      </thead>
      <tbody class="ov-hidden white elevation-3">
        <tr v-for="(feature, i) in features" :key="i">
          <td class="font-weight-bold text-left" v-html="feature.text"></td>
          <td v-html="feature.tier1"></td>
          <td v-html="feature.tier2"></td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'FirstSlideItem',

  data: () => ({
    features: [
      {
        text: 'Upload capacity/month',
        tier1: 'Up to 5,000 pages',
        tier2: 'as many as needed',
      },
      {
        text: 'Supported formats',
        tier1: 'pdf',
        tier2: 'pdf, office, images',
      },
      {
        text: 'Deployment',
        tier1: 'Iraya Cloud',
        tier2: 'Iraya Cloud<br>Self-managed Cloud<br>Iraya-managed Client Cloud',
      },
      {
        text: 'Team members',
        tier1: '―',
        tier2: 'as many as needed',
      },
      {
        text: 'Project/Data Source',
        tier1: '1',
        tier2: 'as many as needed',
      },
      {
        text: 'Data Warehouse',
        tier1: 'read-only access',
        tier2: 'Read and write access',
      },
      {
        text: 'Data Warehouse API',
        tier1: '―',
        tier2: 'Read and write access',
      },
      {
        text: 'HELMSMAN: Processing pipeline monitoring',
        tier1: '―',
        tier2: 'Full access',
      },
    ],
  }),
};
</script>

<style lang="scss">
$tier1-color-top: rgb(243, 104, 109);
$tier1-color-bottom: rgb(245, 135, 81);

$tier2-color-top: rgba(54, 146, 141);
$tier2-color-bottom: rgb(69, 181, 174);

.featured-tier {
  color: var(--purewhite);

  &:nth-of-type(2) {
    border-top-left-radius: 12px;
  }

  &:last-of-type {
    border-top-right-radius: 12px;
  }

  &--tier1 {
    background-image: linear-gradient(to bottom, $tier1-color-top, $tier1-color-bottom);
  }

  &--tier2 {
    background-image: linear-gradient(to bottom, $tier2-color-top, $tier2-color-bottom);
  }
}

.featured-tier-table {
  table {
    border-radius: 12px;
    border-top-right-radius: 0;
    overflow: hidden;

    tbody {
      tr:first-child > td:first-child {
        border-top-left-radius: 12px;
      }
    }
  }
}
</style>
