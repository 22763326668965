<template>
  <v-card :height="height" flat class="ov-hidden">
    <v-card
      :id="map.id"
      height="100%"
      tile width="100%"
      class="map-area__container ov-hidden"
    >
      <map-area-loading-state v-if="map.loading" skeleton></map-area-loading-state>
    </v-card>
    <v-fade-transition>
      <v-layout v-if="showZoomBar" column class="map-area__zoom-bar ma-2">
        <slot name="zoom-bar">
          <v-btn
            :disabled="zoomInBtnDisabled"
            :title="$trans('Zoom in')"
            min-width="32" width="32"
            min-height="32"
            height="32" class="ma-1"
            @click="zoomIn"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            :disabled="zoomOutBtnDisabled"
            :title="$trans('Zoom out')"
            min-width="32" width="32"
            min-height="32"
            height="32" class="ma-1"
            @click="zoomOut"
          >
            <v-icon small>mdi-minus</v-icon>
          </v-btn>
        </slot>
        <slot name="corner.top-left"></slot>
        <v-spacer></v-spacer>
      </v-layout>
    </v-fade-transition>

    <v-layout column align-start class="map-area__corner map-area__corner--bottom-left">
      <div class="map-area__corner-topshelf">
        <portal-target name="corner.bottom-left"></portal-target>
      </div>
      <slot name="zoom-bar.compass">
        <v-icon size="38">mdi-compass-rose</v-icon>
      </slot>
    </v-layout>

    <v-layout class="map-area__corner map-area__corner--top-center">
      <slot name="corner.top-center"></slot>
    </v-layout>

    <v-layout class="map-area__corner map-area__corner--bottom-center">
      <slot name="corner.bottom-center"></slot>
    </v-layout>

    <v-layout class="map-area__corner map-area__corner--bottom-right">
      <slot name="corner.bottom-right"></slot>
    </v-layout>

    <v-layout class="map-area__corner map-area__corner--top-right">
      <slot name="corner.top-right"></slot>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  model: {
    prop: 'map',
  },

  props: {
    map: {
      model: true,
      type: [ String, Object ],
      default: () => {},
    },

    height: {
      type: [ String, Number ],
      default: '100%',
    },

    zoomBar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showZoomBar () {
      return this.zoomBar;
    },

    zoomInBtnDisabled () {
      return this.map.isZoomedInMax();
    },

    zoomOutBtnDisabled () {
      return this.map.isZoomedOutMax();
    },
  },

  watch: {
    'map.items': function (items) {
      this.map.setItems(items);

      if (this.map.checkContainer(this.map.id)) {
        this.map.initialize();
      }
    },
  },

  mounted () {
    this.map.initialize();
    this.emitMapInitialized();
  },

  methods: {
    emitMapInitialized () {
      this.$emit('initialized', this.map);
    },

    zoomIn () {
      this.map.zoomIn();
    },

    zoomOut () {
      this.map.zoomOut();
    },
  },
};
</script>

<style lang="scss">
.map-area {
  &__container {
    width: 100%;
    z-index: 2;
  }

  &__corner {
    position: absolute;
    z-index: 3;

    &--top-center {
      display: block;
      left: 0.05rem;
      margin: 0 auto;
      right: 0.05rem;
      text-align: center;
      top: 0.5rem;
      width: 50%;
    }

    &--top-right {
      right: 0;
      top: 0;
    }

    &--bottom-left {
      bottom: 3rem;
      left: 0.75rem;
    }

    &--bottom-center {
      bottom: 0.5rem;
      display: block;
      left: 0.75rem;
      margin: 0 4rem;
      right: 0.75rem;
      text-align: center;
    }

    &--bottom-right {
      bottom: 3rem;
      right: 0.75rem;
    }
  }

  &__zoom-bar {
    height: 83%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  &__corner-topshelf {
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 9;
  }
}
</style>
