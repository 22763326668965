<template>
  <div class="pa-5">
    <h4 class="d-block text-center display-1 muted--text" v-text="item.attributes.spud"></h4>
  </div>
</template>

<script>
export default {
  name: 'DetailSpudDate',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
