<template>
  <v-alert
    :color="color"
    prominent text
    border="left"
    class="mb-6"
  >
    <template v-slot:prepend>
      <v-icon
        :color="color"
        large
        class="v-alert__icon ma-3 mr-5"
      >mdi-file-check</v-icon>
    </template>
    <v-row align="center">
      <v-col class="grow">
        <slot></slot>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'grey',
    },
  },
};
</script>
