<template>
  <keep-alive>
    <slot v-if="isAppMode(name)" v-bind="{ isAppMode }"></slot>
  </keep-alive>
</template>

<script>
import { isNil } from 'lodash';

export default {
  name: 'AppMode',

  props: {
    name: {
      type: [ String, Array ],
      default: null,
    },
    delimiter: {
      type: String,
      default: '|',
    },
  },

  methods: {
    isAppMode (name = null) {
      if (isNil(name)) {
        return true;
      }

      let modes = [].concat([ name ]).flat();

      if (name.includes(this.delimiter)) {
        modes = name.split(this.delimiter);
      }

      return modes
        .map(mode => this.$app.isAppMode(mode))
        .filter(i => i).length >= 1;
    },
  },
};
</script>
