import DataResource from '@core/resources/DataResource';
import { GET_WORD_FREQUENCY } from '@/modules/Report/api/data-aggregations';
import { size, isEmpty } from 'lodash';

export default class WordCloud extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async listFrequentWords () {
    this.startLoading();

    const params = {
      doc_ref__id: this.route.params.slug,
      wf_type: 'wordcloud',
      page_size: this.meta.selectedWordCount,
    };

    const { data } = await this.axios.get(GET_WORD_FREQUENCY, { params });

    if (!isEmpty(data.data)) {
      this.meta.words = data.data?.[0].attributes.frequency_distribution;
      this.meta.words = this.meta.words.slice(0, this.meta.selectedWordCount);
      this.meta.words = this.meta.words.map(word => ([ word.term, word.frequency ]));
    }
  }

  async listFrequentWordsByDocRefID (docRefID) {
    this.startLoading();

    const params = {
      doc_ref__id: docRefID,
      wf_type: 'wordcloud',
      page_size: this.meta.selectedWordCount,
    };

    const { data } = await this.axios.get(GET_WORD_FREQUENCY, { params });

    if (!isEmpty(data.data)) {
      this.meta.words = data.data?.[0].attributes.frequency_distribution;
      this.meta.words = this.meta.words.slice(0, this.meta.selectedWordCount);
      this.meta.words = this.meta.words.map(word => ([ word.term, word.frequency ]));
    }
  }

  setWordCount (count) {
    this.meta.selectedWordCount = count;

    return this;
  }

  getWordCloudSize () {
    return size(this.meta.words);
  }

  setWordCloudColors ([ , weight ]) {
    const [
      colorScale0,
      colorScale1,
      colorScale2,
      colorScale3,
      colorScale4,
    ] = this.meta.colorScales[this.meta.selectedColorScale];

    const wordCount75 = parseInt(this.getWordCloudSize() * 0.75, 10);
    const wordCount5 = parseInt(this.getWordCloudSize() * 0.5, 10);
    const wordCount25 = parseInt(this.getWordCloudSize() * 0.25, 10);
    const wordCount05 = parseInt(this.getWordCloudSize() * 0.05, 10);
    const word75 = this.meta.words[wordCount75][1];
    const word5 = this.meta.words[wordCount5][1];
    const word25 = this.meta.words[wordCount25][1];
    const word05 = this.meta.words[wordCount05][1];

    if (weight > word05) {
      return colorScale0;
    }

    if (weight > word25) {
      return colorScale1;
    }

    if (weight > word5) {
      return colorScale2;
    }

    if (weight > word75) {
      return colorScale3;
    }

    return colorScale4;
  }
}
