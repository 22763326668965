import Manifest from '@core/manifests/Manifest';

export const TRAY_TYPE_CORE = 'core';
export const TRAY_TYPE_SRC = 'src';

const requireSystemTray = require.context(
  // The relative path of the routes folder
  '@/modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base names
  /(\/.*\/components\/System).*SystemTray\.vue$/,
);

export const trayItems = [
  ...requireSystemTray
    .keys().sort()
    .map(item => ({
      type: TRAY_TYPE_SRC,
      path: item,
      module: item.replace('./', '').split('/').shift().toLowerCase(),
      ...requireSystemTray(item),
    })),
];

class SystemTrayManifest extends Manifest {
  constructor (attributes = {}) {
    super(attributes);
  }

  setItems (items) {
    this.items = items.map(item => this.makeItem(item));

    return this;
  }

  makeItem (item) {
    return {
      key: (item?.default?.name ?? item.name ?? Date.now()).toLowerCase(),
      component: item.default ?? item,
    };
  }

  install (Vue) {
    const self = this;
    Object.defineProperties(Vue.prototype, {
      $systemTray: {
        get () {
          return self;
        },
      },
    });
  }
}

export default new SystemTrayManifest({ items: trayItems });
