export default {
  computed: {
    href () {
      return this.backUrl || this.$route.query.back || -1;
    },
  },

  methods: {
    back () {
      return this.href < 0 ? this.$router.go(-1) : this.$router.push(this.href);
    },
  },
};
