<template>
  <v-menu left eager>
    <template v-slot:activator="{ on }">
      <v-btn
        small icon
        class="mr-1"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list dense color="transparent">
        <v-list-item
          @click="toggleTwoPageView"
        >
          <v-list-item-action>
            <v-icon v-if="twopageview">mdi-check</v-icon>
          </v-list-item-action>
          <v-list-item-content
            class="caption"
            v-text="$trans('Two page view')"
          ></v-list-item-content>
        </v-list-item>

        <v-list-item
          v-shortkey="[ 'ctrl', 'f11' ]"
          @shortkey="toggleFullview"
          @click="toggleFullview"
        >
          <v-list-item-action>
            <v-icon v-if="fullview">mdi-fullscreen-exit</v-icon>
            <v-icon v-else>mdi-fullscreen</v-icon>
          </v-list-item-action>
          <v-list-item-content
            class="caption"
            v-text="$trans_choice('Enter Fullview mode', fullview)"
          ></v-list-item-content>
          <v-list-item-action>
            <span v-if="fullview" v-shortkey="['esc']" @shortkey="toggleFullview"></span>
            <span class="muted--text">Ctrl+F11</span>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PanzoomPdfPreviewerToolbarMenu',

  computed: {
    ...mapGetters({
      fullview: 'panzoom/fullview',
      twopageview: 'panzoom/twopageview',
    }),
  },

  methods: {
    ...mapActions({
      toggleFullview: 'panzoom/fullview',
      toggleTwoPageView: 'panzoom/twopageview',
    }),
  },
};
</script>
