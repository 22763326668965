<template>
  <v-row>
    <v-col cols="12" lg="4">
      <v-skeleton-loader type="image" height="36"></v-skeleton-loader>
      <v-row>
        <v-col cols="11" lg="8">
          <v-skeleton-loader class="mt-2" type="image" height="14"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AppHeaderLoadingState',
};
</script>
