<template>
  <div>
    <!-- eslint-disable max-len -->
    <img class="featured__image" :src="$asset.getModuleAsset('registration/fig2.slide3', 'Billing')">
    <v-card elevation="4" class="featured featured--shaped mb-6">
      <v-card-title class="featured__title white--text justify-center">
        <span class="title font-weight-light" v-html="$trans('<strong>Key</strong> Features')"></span>
      </v-card-title>
      <v-card-text class="pa-10">
        <v-layout v-for="(item, i) in items" :key="i" class="align-center mb-6">
          <v-icon large color="secondary">mdi-chevron-right-circle-outline</v-icon>
          <span class="ml-8" v-html="item.text"></span>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ThirdSlideItem',

  data: () => ({
    items: [
      { text: 'Save your time by using the OCR feature for full-text customizability' },
      { text: 'Level up your analysis with the export functionality' },
      { text: 'Improve your analysis with our image classification functions' },
    ],
  }),
};
</script>

<style lang="scss" scoped>
$tier1-color-top: rgb(243, 104, 109);
$tier1-color-bottom: rgb(245, 135, 81);

.s-app .v-card.featured {
  &.featured--shaped {
    border-bottom-right-radius: 3rem;
    border-top-left-radius: 3rem;
  }
}

.featured {
  &__image {
    margin-bottom: -45px;
  }

  &__title {
    background-image: linear-gradient(to bottom, $tier1-color-top, $tier1-color-bottom);
  }
}
</style>
