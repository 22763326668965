<template>
  <div>
    <h6 class="overline text-right">
      <v-icon left x-small>mdi-server</v-icon>
      <span
        :class="{ 'error--text': storage.data.reachedCapacity }"
        v-text="storage.data.total"
      ></span>&nbsp;
      <span v-text="storage.data.text"></span>
    </h6>
    <v-progress-linear
      :color="color"
      background-color="workspace darken-1"
      height="10"
      query rounded
      :indeterminate="storage.loading"
      :value="storage.data.percent"
    ></v-progress-linear>
  </div>
</template>

<script>
import SummaryInfo from '@/modules/Datalake/resources/SummaryInfo';

export default {
  name: 'SummaryStorageInfo',

  props: {
    color: {
      type: String,
      default: 'accent',
    },
  },

  data: () => ({
    storage: new SummaryInfo({
      loading: true,
    }),
  }),

  created () {
    this.storage.list();
  },
};
</script>
