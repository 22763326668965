<template>
  <div>
    <v-btn outlined @click="generateSummary" :disabled="chatbot.loading">
      Generate Summary
    </v-btn>
    <template v-if="hasData">
      <v-simple-table class="v-data-table--denser">
        <tbody>
          <tr v-for="(row, i) in rows" :key="i">
            <td
              class="caption text-no-wrap font-weight-bold text-uppercase"
              v-text="$trans(row.text)"
            ></td>
            <td class="caption" v-text="row.value"></td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
    <template v-else>
      <general-summary-info-table-empty-state v-if="!generated"></general-summary-info-table-empty-state>
      <div v-if="chatbot.loading">
        <v-skeleton-loader type="list-item-three-line"/>
      </div>
      <div v-if="chatbot.loaded && generated" class="py-3">
        <!-- <body :style="{ whiteSpace: 'pre-line' }">{{ chatbot.data.data.summary }}</body> -->
        <div v-html="markdownText"></div>
        <v-row justify="end" class="mx-1 mb-1 mt-8">
          <v-col cols="9" class="pa-0">
            <div class="font-weight-light" style="font-size: 12px;">This is an AI generated response and may incur errors.<br/> Please check the outputs before using.</div>
          </v-col>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-1"
                v-on="on"
                @click="copyText"
              >
                <v-icon>mdi-clipboard-outline</v-icon>
              </v-btn>
            </template>
            <span>Copy response</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-1"
                v-on="on"
              >
                <v-icon>mdi-thumb-up-outline</v-icon>
              </v-btn>
            </template>
            <span>This response is good</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-1"
                v-on="on"
              >
                <v-icon>mdi-message-draw</v-icon>
              </v-btn>
            </template>
            <span>This response needs review</span>
          </v-tooltip>
        </v-row>
      </div>
    </template>
    <v-snackbar
      v-model="showSnack"
      timeout="3000"
      color="success"
    >Copied to clipboard</v-snackbar>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import Chatbot from '@/modules/Search/resources/Chatbot';
import marked from 'marked';

export default {
  name: 'GeneralSummaryInfoTable',

  props: {
    item: {
      type: [ Object, Array ],
      default: () => {},
    },
  },

  data: vm => ({
    well: vm.item.data,
    chatbot: new Chatbot,
    generated: false,
    showSnack: false,
  }),

  computed: {
    hasData () {
      return !isEmpty(this.well);
    },

    rows () {
      return [
        {
          text: 'Project name',
          value: this.well.project_name,
        },
        {
          text: 'Company',
          value: this.well.company,
        },
        {
          text: 'Field',
          value: this.well.field,
        },
        {
          text: 'Start',
          value: this.well['start date'],
        },
        {
          text: 'End',
          value: this.well['end date'],
        },
        {
          text: 'Platform',
          value: this.well.platform,
        },
      ];
    },
    markdownText(){
      marked.setOptions({
        gfm: true,
        breaks: true
      });
      return marked(this.chatbot.data.data.summary)
    }
  },

  methods: {
    generateSummary(){
      if (!this.generated){this.generated = true;}
      this.chatbot.getDocumentsSummary(this.$route.params.slug)
    },
    copyText(){
      this.showSnack = true
      this.chatbot.copyToClipboard(this.chatbot.data.data.summary)
    }
  }
};
</script>
