import Vue from 'vue';
import axios from 'axios';
import { getSessionID, getSessionToken } from '@utils/session';
import { AUTH_TYPE } from '@/config/token';
import Config from '../config/server.json';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
  Config.VUE_APP_API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
}
export const config = {
  baseURL: Config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL || process.env.baseURL || process.env.apiUrl || '',
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

export const _axios = axios.create(config);

_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the access token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

export const requestInterceptors = {
  // Do something before request is sent
  success: config => {
    const ACCESS_TOKEN = getSessionToken();

    if (ACCESS_TOKEN) {
      const sessionId = getSessionID();
      config.headers.Authorization = `${AUTH_TYPE} ${ACCESS_TOKEN}`;
      config.headers.EDSESSIONID = sessionId;
    }

    return config;
  },
  // Do something with request error
  error: err => Promise.reject(err),
};

_axios.interceptors.request.use(requestInterceptors.success, requestInterceptors.error);

// Add a response interceptor
_axios.interceptors.response.use(
  // Do something with response data
  response => response,

  // Do something with response error
  err => Promise.reject(err),
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios;
      },
    },
    $axios: {
      get () {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
