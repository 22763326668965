<template>
  <v-layout column class="fill-height">
    <div>
      <v-row>
        <v-col cols="12" lg="8">
          <div class="d-flex column">
            <v-skeleton-loader
              v-for="i in 3" :key="i"
              type="image"
              width="120" height="48"
              class="mr-2"
            ></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-skeleton-loader
      v-for="i in 3" :key="i"
      type="table-heading, divider"
      width="100%"
      class="mt-4"
    ></v-skeleton-loader>
  </v-layout>
</template>

<script>
export default {
  name: 'ReportViewerMetaInfoLoadingState',
};
</script>
