<template>
  <app-content>
    <!-- eslint-disable max-len -->
    <meta-tag :title="$trans('Sign out to update')"></meta-tag>
    <v-row>
      <v-col cols="12" lg="8" class="mx-auto">
        <h1 class="mb-4" v-text="$trans('Sign out to update')"></h1>
        <p v-html="$trans('You will be signed out for the changes to take effect. After you are automatically redirected, you may proceed to sign in again.')"></p>
        <p
          v-html="$trans('Click here if the page does not automatically redirect to the Sign in page.', {
            url: url,
          })"
        ></p>
        <v-layout>
          <v-icon v-if="loading" small left>mdi-spin mdi-loading</v-icon>
          <p
            class="muted--text font-italic mb-0"
            v-html="$trans('Please do not close tab if page is loading.')"
          ></p>
        </v-layout>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
export default {
  layout: 'web',

  data: () => ({
    loading: true,
  }),

  computed: {
    url () {
      return this.$router.resolve({ name: 'logout' }).href;
    },
  },

  async created () {
    this.turnOffLoading();
    this.goToLogout();
  },

  methods: {
    turnOffLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 10000);
    },

    goToLogout () {
      setTimeout(async () => {
        await this.$store.dispatch('auth/logout');
        await this.$router.push({ name: 'login' });
        window.location.reload();
      }, 3000);
    },
  },
};
</script>
