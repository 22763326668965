<template>
  <div ref="bbox-card-container" class="bbox-card__container">
    <template v-if="enabled">
      <template v-for="(item, i) in quadrilaterals">
        <v-hover v-if="isNotTable(item)" v-slot:default="{ hover }" :key="i">
          <v-sheet
            :id="`bbox-card__item-${i}`"
            :key="`bbox-card__item-${i}`"
            :style="getBboxItemStyles(item, i)"
            color="transparent"
            class="bbox-card__item rounded ov-y-visible"
          >
            <div
              v-if="hover"
              class="bbox-card__item-highlighter fill-height rounded yellow darken-1"
            ></div>
            <bbox-ocr-indicator
              class="bbox-ocr-indicator"
              always-show pulsate
            >
            </bbox-ocr-indicator>
            <bbox-click-card class="bbox-card__item-container">
              <template v-slot:default="{ clicked }">
                <v-slide-y-reverse-transition mode="in-out">
                  <bbox-card v-if="clicked && isNotTable(item)" style="min-height: 75px;">
                    <div class="bbox-card__item-content w-100 rounded fill-height ov-y-auto">
                      <div class="bbox-card__item-text panzoom-exclude">
                        <slot name="bbox.content" v-bind="{ item }"></slot>
                      </div>
                    </div>
                    <template v-slot:actions>
                      <div class="bbox-card__item-actions my-1">
                        <slot
                          name="bbox.actions"
                          v-bind="{
                            item,
                            clicked,
                            highlightedText: getHighlightedText(),
                          }"
                        >
                          <v-btn small class="mr-2" @click.stop="emitCopy(item)">
                            <v-icon left small>mdi-content-copy</v-icon>
                            <span v-text="$trans('Copy')"></span>
                          </v-btn>
                        </slot>
                      </div>
                    </template>
                  </bbox-card>
                </v-slide-y-reverse-transition>
              </template>
            </bbox-click-card>
          </v-sheet>
        </v-hover>
        <v-sheet
          v-else
          :id="`bbox-card__item-${i}`"
          :key="`bbox-card__item-${i}`"
          :style="getStyles(item)"
          color="transparent"
          class="bbox-card__item rounded ov-y-visible"
        >
          <div
            :class="parseBboxStyles([
              'bbox-card__item-highlighter',
              'bbox-card__item-highlighter__table',
              'fill-height',
              'rounded',
              'outlined',
            ])"
            @click.stop="tableClicked(item)"
          ></div>
        </v-sheet>
      </template>
    </template>
    <slot></slot>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { sortBy } from 'lodash';

export default {
  name: 'BboxContainer',

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      tableOCRItem: 'report/tableOCRItem',
    }),

    enabled () {
      return !this.disabled;
    },

    quadrilaterals () {
      return sortBy(this.items, 'top');
    },

    parent () {
      return this.$refs?.['bbox-card-container'];
    },
  },

  data: () => ({
    clicked: false,
  }),

  methods: {
    ...mapActions({
      setTableOCRItem: 'report/setTableOCRItem',
    }),

    getBboxItemStyles (item) {
      return {
        top: `${item.top}%`,
        left: `${item.left}%`,
        width: `${item.width}%`,
        height: `${item.height}%`,
      };
    },

    getStyles (item, i) {
      return {
        top: `${item.top}%`,
        left: `${item.left}%`,
        width: `${item.width}%`,
        height: `${item.height}%`,
        zIndex: i + 10,
      };
    },

    getHighlightedText () {
      return window.getSelection().toString();
    },

    emitCopy (item) {
      this.$emit('copy:item', item);
    },

    tableClicked (item) {
      if (item.attributes.img_tag_1 === 'Table') {
        this.$router.push({
          name: 'reports.ocr.single',
          params: {
            ocr: item.id,
            slug: this.$route.params.slug,
          },
          query: {
            back: this.$route.query.back,
          },
          replace: true,
        });
        this.setTableOCRItem(item);
      }
    },

    isNotTable ({ isTable }) {
      return !isTable;
    },

    parseBboxStyles (classes) {
      return classes.join(' ');
    },

    toggleBboxContent () {
      this.clicked = !this.clicked;

      return this.clicked;
    },
  },
};
</script>

<style lang="scss">
.bbox-card {
  &__container {
    position: relative;
  }

  &__item {
    background-color: transparent;
    line-height: 1;
    overflow: visible;
    position: absolute;

    .v-menu__content {
      height: 100%;
    }
  }

  &__item-container {
    height: 100%;
    min-height: 100%;
  }

  &__item-highlighter {
    opacity: 0.7;
    position: absolute;
    width: 100%;

    &__table {
      border: 10px solid;
      color: rgb(173, 255, 47);
      cursor: pointer;
    }
  }

  &__item-content {
    background-color: var(--purewhite);
    border: 1px solid rgba(0, 0, 0, 0.4);
    min-height: 67px;
    min-width: 120px;
    overflow: visible;
    padding: 2px;
  }

  &__item-group {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 72;
  }

  &__item-text {
    cursor: text;
    font-size: 1.05rem;
    line-height: 1.2;
    user-select: text;
  }

  &__item-actions {
    display: flex;
    position: relative;
    z-index: 999;
  }

  &__item-close-btn {
    position: absolute;
    right: -30px;
    top: -30px;
  }
}

.bbox-card__item:hover {
  .bbox-ocr-indicator {
    opacity: 0.2;
  }
}
</style>
