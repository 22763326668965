<template>
  <div>
    <p class="my-2 text-caption text--disabled">
      <small>
        <span v-html="`${appTitle} &copy; ${appYear}. All rights reserved.`"></span>
      </small>
    </p>
    <p class="my-0 text-caption text--disabled">
      {{ $trans('By signing in, you agree to our') }}
      <br>
      <router-link
        :to="{ name: 'legal.agreement' }"
        class="text-caption text--disabled"
      >
        {{ $trans('Terms and Conditions.') }}
      </router-link>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AuthFooter',

  computed: {
    ...mapGetters({
      appTitle: 'meta/apptitle',
      appYear: 'meta/appyear',
    }),
  },
};
</script>
