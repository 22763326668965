<template>
  <div class="ov-hidden text-left">
    <v-layout align-start>
      <span class="text-uppercase" v-text="$trans('Storage')"></span>
      <v-spacer></v-spacer>
      <v-menu open-on-hover offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn
            :title="files.selected.text"
            text small
            class="mb-2"
            v-on="on"
          >
            <span v-text="files.selected.text"></span>
            <v-icon right small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list v-model="files.current" dense>
            <template v-for="(file, i) in files.itemsAll">
              <v-subheader
                v-if="file.isSubheader"
                :key="i"
              >
                <v-avatar :color="file.color" size="10" class="ml-2"></v-avatar>
                <div class="ml-3" v-text="$trans(file.header)"></div>
              </v-subheader>
              <v-divider v-else-if="file.isDivider" :key="i"></v-divider>
              <v-list-item
                v-else
                :key="i"
                :class="{ 'v-list-item--active': files.isSelected(file) }"
                @click="setSelectedFile(file, i)"
              >
                <v-list-item-content>
                  <div class="ml-6" v-text="file.text"></div>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-menu>
    </v-layout>
    <v-layout align-start>
      <v-icon v-if="space.loading" left small>mdi-loading mdi-spin</v-icon>
      <v-icon v-else left small>mdi-cloud-outline</v-icon>
      <div class="flex-grow-1 text-left">
        <v-card flat class="ov-hidden text-right border-none">
          <v-layout class="w-100 grey lighten-3 progress-linear__container">
            <template v-if="space.loading">
              <v-progress-linear
                indeterminate
                background-color="workspace darken-1"
                color="primary"
                height="10"
              ></v-progress-linear>
            </template>
            <template v-else>
              <template v-for="(progress, i) in space.progressStatus.storages">
                <template v-for="(bar, j) in progress.bars">
                  <v-tooltip :key="`bar-${i}-${j}`" left>
                    <template v-slot:activator="{ on }">
                      <v-progress-linear
                        :style="`width: ${bar.percent}%; opacity: ${bar.opacity};`"
                        :color="bar.color"
                        :value="100"
                        background-color="workspace darken-1"
                        height="10"
                        v-on="on"
                      ></v-progress-linear>
                    </template>
                    <span v-text="bar.text"></span>
                  </v-tooltip>
                </template>
              </template>
            </template>
          </v-layout>
        </v-card>
      </div>
      <!-- <div class="caption muted--text ml-2" v-text="space.progressStatus.totalLimit"></div> -->
    </v-layout>
    <div class="file-data-storage__details caption text-no-wrap ov-x-auto w-100 ml-6">
      <table>
        <tbody>
          <tr
            v-for="(item, i) in space.progressStatus.storages" :key="i"
            class="caption"
          >
            <td class="px-1 py-0">
              <v-tooltip :key="`bar-${i}`" left>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-avatar :color="item.color" size="10"></v-avatar>
                    <span class="caption ml-2" v-text="`${item.used} used`"></span>
                  </div>
                </template>
                <span v-text="item.name"></span>
              </v-tooltip>
            </td>
            <td class="px-1 py-0">
              <v-avatar :color="item.color" size="10" style="opacity: 0.25;"></v-avatar>
              <span class="caption ml-2" v-text="`${item.ingested} ingested`"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { files } from '@/modules/File/config/files';
import Space from '@/modules/File/resources/Space';

export default {
  name: 'FileDataStorageInfo',

  data: () => ({
    files,
    space: new Space({
      items: files,
    }),
  }),

  watch: {
    'files.selected': function ({ value }) {
      this.space.find(value);
    },
  },

  mounted () {
    this.findSelectedFile();
  },

  methods: {
    findSelectedFile () {
      this.space.find(this.files.getSelectedValue());
    },

    setSelectedFile (file, i) {
      this.files.setSelected(file, i);
    },
  },
};
</script>

<style lang="scss">
.file-data-storage {
  &__details {
    max-height: 140px;
    overflow-y: auto;
  }
}
</style>
