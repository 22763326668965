<template>
  <v-row>
    <v-dialog
      v-model="show"
      max-width="960px"
      persistent
      width="960px"
    >
      <dialog-browser-compatibility @close="dialogClose">
      </dialog-browser-compatibility>
    </v-dialog>
    <meta-tag :title="$trans('Sign in')"></meta-tag>
    <v-col
      class="mx-auto"
      cols="12"
      xl="3"
      lg="4"
      md="5"
      sm="10"
    >
      <app-brand-banner
        class="mx-auto text-center ma-4"
        width="320"
      ></app-brand-banner>

      <!-- Greeting message -->
      <login-page-greeter></login-page-greeter>
      <!-- Greeting message -->

      <v-slide-y-transition mode="in-out">
        <v-alert v-if="form.hasErrors()" color="error" dark>
          <v-icon left>mdi-alert</v-icon>
          <span
            class="login-error-message"
            v-text="form.getErrors('message', authErrorMessage)"
          ></span>
        </v-alert>
      </v-slide-y-transition>

      <validation-observer ref="signin" v-slot="{ handleSubmit }">
        <v-form
          v-model="form.valid"
          :disabled="form.loading"
          method="post"
          @submit.prevent="handleSubmit(submit)"
        >
          <v-card min-width="320px" max-width="420px" class="mx-auto">
            <v-card-title>
              <v-layout column align-start>
                <h2>{{ $trans('Sign in') }}</h2>
                <p style="font-weight: normal;">
                  {{ $trans("Don't have an account?") }}
                  <router-link :to="{ name: 'register' }">{{ $trans('Sign up') }}</router-link>
                </p>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <validation-provider v-slot="{ errors }" name="username" rules="required|email">
                <v-text-field
                  v-model="form.username"
                  :error-messages="errors"
                  :label="$trans('Email')"
                  autofocus
                  outlined
                  type="email"
                  name="username"
                ></v-text-field>
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="password" rules="required">
                <app-password-field
                  v-model="form.password"
                  :error-messages="errors"
                  :label="$trans('Password')"
                  outlined
                  name="password"
                ></app-password-field>
              </validation-provider>

              <v-layout align-start class="mb-4">
                <p class="text-left mb-0">
                  <v-checkbox
                    v-model="form.rememberMe"
                    :label="$trans('Remember me')"
                    hide-details
                  ></v-checkbox>
                </p>
                <v-spacer></v-spacer>
                <p class="text-right mt-4 mb-0">
                  <router-link
                    :to="{ name: 'password.reset' }"
                    class="text-caption"
                    tabindex="3"
                  >
                    {{ $trans('Forgot password?') }}
                  </router-link>
                </p>
              </v-layout>

              <v-layout class="mt-5">
                <v-spacer></v-spacer>
                <v-btn
                  :loading="form.loading"
                  :disabled="form.loading"
                  :block="isMobile"
                  large width="132"
                  color="primary"
                  type="submit"
                >
                  {{ $trans('Sign in') }}
                  <template v-slot:loader>
                    <v-slide-x-transition>
                      <v-icon small left dark>mdi-loading mdi-spin</v-icon>
                    </v-slide-x-transition>
                    <span>{{ $trans('Sign in') }}</span>
                  </template>
                </v-btn>
              </v-layout>
            </v-card-text>
            <v-divider inset class="mx-auto mt-4"></v-divider>
            <div class="px-5 pt-4 mb-5 body-2 text-center muted--text">
              <div
                class="text-caption"
                v-html="$trans('login.problem', {
                  email: $app.supportEmail,
                })"
              ></div>
            </div>
          </v-card>
        </v-form>
      </validation-observer>

      <auth-footer class="text-center"></auth-footer>
    </v-col>
  </v-row>
</template>

<script>
import Auth from '@/modules/Auth/resources/Auth';
import BeforeAuthRouteEnter from '@core/mixins/BeforeAuthRouteEnter';
import Breakpoints from '@core/mixins/Breakpoints';

export default {
  layout: 'auth',

  mixins: [ BeforeAuthRouteEnter, Breakpoints ],

  data: () => ({
    form: new Auth,
    authErrorMessage: "Wrong credentials given.",
    show: false,
  }),

  computed: {
    validator () {
      return this.$refs?.signin;
    },
  },

  created() {
    this.show = (function (agent) {
      if (!!navigator.userAgentData && navigator.userAgentData.brands.some(data => data.brand == 'Chromium')) {
        return false
      }
      return true
    })(window.navigator.userAgent.toLowerCase());
  },

  methods: {
    submit () {
      this.form.setValidator(this.validator).login();
    },
    dialogClose () {
      this.show = false;
    },
  },
};
</script>
