<template>
  <v-navigation-drawer
    :color="color"
    :value="value"
    absolute clipped
    eager floating
    temporary width="180"
  >
    <v-card color="transparent" class="fill-height">
      <v-card-text>
        <template v-for="(item, i) in items">
          <v-lazy :key="i">
            <div role="button" @click="selectThumbnail(i+1)">
              <slot name="thumbnail" v-bind="{ item, selectThumbnail, page: i+1 }">
                <img
                  :data-src="item.url"
                  :src="item.url"
                  width="100%" height="auto"
                  class="d-block pa-2 mb-2 bordered"
                >
              </slot>
            </div>
          </v-lazy>
        </template>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'PanzoomPdfPreviewerThumbnailNavigator',

  props: {
    value: {
      type: Boolean,
      default: true,
    },

    color: {
      type: String,
      default: null,
    },

    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    selectThumbnail (index) {
      this.$emit('click:thumbnail', index);
    },
  },
};
</script>
