import {
  has, kebabCase, orderBy, uniqBy,
} from 'lodash';

export default class Registrar {
  constructor (attributes) {
    this.setAttributes(attributes);
    this.initializeItems();
  }

  setAttributes (attributes) {
    this.attributes = attributes;
  }

  getAttributes () {
    return this.attributes;
  }

  initializeItems () {
    this.items = [];
  }

  register (items) {
    this.items = uniqBy(this.items.concat(items))
      .map((item, i) => this.makeItem(item, i));
  }

  isViewable (item) {
    if (!has(item, 'isViewable')) {
      return true;
    }

    return item.isViewable();
  }

  all () {
    return orderBy(this.items, 'sort');
  }

  generateKeyFrom (item = {}) {
    return kebabCase(`${item.type}-${item?.to?.name ?? JSON.stringify(item.to ?? Date.now())}-s${item.sort}`
      .replace('undefined-', '')
      .replace('-undefined', ''));
  }
}
