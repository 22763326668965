<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="false"
    bottom left
    origin="top right"
    nudge-left="8"
    transition="scale-transition"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        min-width="32" width="32"
        min-height="32"
        height="32" class="ma-1"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
        @click="changePieChartDataToAll"
      >
        <v-icon small>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script>
import Lithology from '@/modules/Webgis/resources/Lithology';
import { mapGetters } from 'vuex';

export default {
  name: 'MapGisLithologiesBtn',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: 'mdi-chart-pie',
    },
  },

  data: () => ({
    resource: new Lithology,
  }),

  computed: {
    ...mapGetters({
      isShown: 'lithology/shown',
    }),
    menu: {
      get () {
        return this.isShown;
      },
      set (isShown) {
        this.$store.dispatch('lithology/setShown', isShown);
      },
    },
  },

  mounted () {
    this.resource.listPieChartSummary();
  },

  methods: {
    changePieChartDataToAll () {
      this.resource.listPieChartSummary();
    },
  },
};
</script>
