import { BASE_URL } from '@/config/api';

export const DOCUMENT_SUMMARY = `${BASE_URL}/chatbot/document-summary/`;
export const FAVORITES_SUMMARY = `${BASE_URL}/chatbot/favorites-summary/`;
export const SANDBOX_URL = `${BASE_URL}/chatbot/sandbox/`;

export default Object.freeze({
  DOCUMENT_SUMMARY,
  FAVORITES_SUMMARY,
  SANDBOX_URL,
});
