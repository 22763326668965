import { sortBy, isEmpty, size } from 'lodash';

export default class Manifest {
  constructor (attributes = {}) {
    this.setItems(attributes.items ?? []);
    this.setOptions(attributes.options ?? {});
  }

  getAllItems () {
    return sortBy(this.items, 'sort');
  }

  getItems () {
    return this.getAllItems().filter(i => i.enabled);
  }

  setItems (items) {
    this.items = items.map((item, index) => this.makeItem({ ...item, index }));

    return this;
  }

  makeItem (item) {
    return {
      ...item,
      meta: { ...item.meta },
      enabled: item.enabled ?? true,
      index: item.index ?? this.items?.length ?? 0,
      sort: item.sort ?? item.index ?? this.items?.length ?? 0,
      key: (item.key ?? item.code ?? item.name ?? item.path).toLowerCase(),
      manifestPath: (item.path ?? '').replace('./', `${item.type}/modules/`),
      path: (item.path?.replace('/manifest.json', '') ?? '').replace('./', `${item.type}/modules/`),
    };
  }

  addItem (item) {
    this.items.push(this.makeItem(item));

    return this;
  }

  find (name, key = 'key') {
    return this.getItems().find(item => item[key] === name);
  }

  enable (name, key = 'key') {
    const item = this.find(name, key);
    item.enabled = true;

    return this;
  }

  disable (name, key = 'key') {
    const item = this.find(name, key);
    item.enabled = false;

    return this;
  }

  has (name, key = 'key') {
    return !isEmpty(this.find(name, key));
  }

  hasNot (name, key = 'key') {
    return !this.has(name, key);
  }

  size () {
    return size(this.items);
  }

  setOptions (options) {
    this.options = options;
  }

  getOptions () {
    return this.options;
  }
}
