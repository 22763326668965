<template>
  <v-fab-transition>
    <v-btn
      absolute
      top
      class="mt-8 mr-n8"
      fab
      right
      x-small
      @click="minifySidebar"
    >
      <v-icon v-if="mini">mdi-chevron-right</v-icon>
      <v-icon v-else>mdi-chevron-left</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
import SidebarToggle from '@core/mixins/SidebarToggle';

export default {
  mixins: [ SidebarToggle ],
};
</script>
