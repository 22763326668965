var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":true,"width":_vm.width,"persistent":"","no-click-animation":"","title":"dialog zoom preview"}},[_c('v-card',[_c('div',{staticClass:"pa-1 text-right"},[_c('v-btn',{attrs:{"title":"button zoom close","small":"","icon":""},on:{"click":_vm.back}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-layout',{staticClass:"mb-4",attrs:{"justify-space-between":"","align-center":""}},[_c('h3',{domProps:{"textContent":_vm._s(_vm.$trans('Image Preview'))}}),_c('v-sheet',[(_vm.datalake.loaded)?_c('v-btn',{staticClass:"ml-2",attrs:{"to":{
                  name: 'reports.single',
                  params: { slug: _vm.datalake.data.id },
                  query: {
                    back: _vm.$route.fullPath,
                  },
                },"small":"","text":"","title":"button zoom goto"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-file-document-outline")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Go to page 1'))}})],1):_vm._e()],1)],1),_c('panzoom-image-previewer',{ref:"panzoom-image-previewer",attrs:{"loading":_vm.datalake.loading,"options":{
              overflow: 'auto',
              touchAction: 'pan',
              panOnlyWhenZoomed: true,
            },"always-zoom-from-center":"","slider":"","zoom-bar":"","zoom-on-dblclick":"","height":"800"},on:{"wheel:up":_vm.onWheelUp,"wheel:down":_vm.onWheelDown}},[(_vm.datalake.loaded)?_c('img',{attrs:{"src":_vm.parsePDFImagesUrl(_vm.datalake.data),"width":"100%","height":"auto"}}):_vm._e()]),_c('v-layout',{staticClass:"mt-3",attrs:{"justify-space-between":""}},[_c('span',{staticClass:"muted--text caption",domProps:{"textContent":_vm._s(_vm.$trans('From {name}', {
                name: _vm.datalake.data.doc_name,
              }))}}),_c('span',{staticClass:"muted--text caption",domProps:{"textContent":_vm._s(_vm.$trans('page {page}', {
                page: 1,
              }))}})])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }