import { map } from '@/modules/Search/config/map';

export const state = {
  map,
};

export const getters = {
  hasMapDrawn: state => state.map.hasDrawn,
};

export const mutations = {
  SET_AS_DRAWN (state) {
    state.map.setAsDrawn();
  },
  SET_AS_UNDRAWN (state) {
    state.map.setAsUndrawn();
  },
};

export const actions = {
  setAsDrawn ({ commit }) {
    commit('SET_AS_DRAWN');
  },
  setAsUndrawn ({ commit }) {
    commit('SET_AS_UNDRAWN');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
