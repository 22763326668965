<template>
  <portal to="main:bottom">
    <div
      :class="{
        'app-debugger--left': !right,
        'app-debugger--right': right
      }" class="app-debugger ma-6"
    >
      <slot name="activator" v-bind="{ on, shortcut, shortkey }">
        <v-slide-x-transition>
          <v-btn
            v-show="!hideButton"
            v-shortkey="shortkey"
            :class="{ 'rotate-180': shown }"
            :left="!right" :right="right"
            :color="color"
            fixed bottom
            fab rounded
            x-small
            v-on="on"
            @shortkey.native="shortcut"
          >
            <v-icon v-if="shown">mdi-close</v-icon>
            <v-icon v-else>mdi-bug</v-icon>
          </v-btn>
        </v-slide-x-transition>
      </slot>
      <v-expand-transition mode="in-out">
        <v-card
          v-show="model" v-bind="$attrs"
          :class="{ 'app-debugger-card--left': left, 'app-debugger-card--right': right }"
          :height="height" :width="width"
          max-height="calc(100vh - 3rem)"
          max-width="calc(100vw - 4rem)"
          class="app-debugger-card ov-y-auto"
        >
          <v-btn
            v-if="hideButton"
            fab
            right top
            small icon
            class="app-debugger-close-btn ma-2"
            @click="click"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-text class="pa-8">
            <slot></slot>
          </v-card-text>
        </v-card>
      </v-expand-transition>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'AppDebugger',

  props: {
    height: {
      type: String,
      default: '800px',
    },

    width: {
      type: String,
      default: '500px',
    },

    hideButton: {
      type: Boolean,
      default: false,
    },

    shortkey: {
      type: Array,
      default: () => [ 'alt', 'q' ],
    },

    left: {
      type: Boolean,
      default: true,
    },

    right: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: 'error',
    },

    open: {
      type: Boolean,
      default: false,
    },
  },

  data: vm => ({
    fab: false,

    model: false,

    on: {
      click: e => vm.click(e),
    },
  }),

  computed: {
    shown () {
      return this.model;
    },
  },

  mounted () {
    this.model = this.open;
  },

  methods: {
    click (e) {
      this.model = !this.model;
      this.$emit('click:toggle', e);
    },

    shortcut (e) {
      e.target.click();
    },
  },
};
</script>

<style scoped lang="scss">
.app-debugger {
  bottom: 0;
  position: fixed;
  z-index: 200;

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }

  &-card--left {
    float: left;
  }

  &-card--right {
    float: right
  }
}

.app-debugger-close-btn {
  position: absolute;
  right: 0;
}

.v-btn {
  transition: all 0.3s ease-in-out;

  &.rotate-180 {
    transform: rotate(180deg);
  }
}
</style>
