import DataResource from '@core/resources/DataResource';
import {
  INTUITION_MODELS_URL, INTUITION_SAMPLES_URL, INTUITION_FETCH_VECTOR,
} from '@/modules/Intuition/api/intuition';
import {
  DEFAULT_IMAGE_CLASSES, PARTICLE_POSITION_MULTIPLIER, PARTICLE_SIZE, TWO_D_AXES,
} from '@/modules/Intuition/config/intuition';
import {
  uniq, cloneDeep, sum, uniqBy, orderBy, includes,
} from 'lodash';
import {
  Deck, IconLayer, PointCloudLayer, COORDINATE_SYSTEM, LineLayer, OrbitView, PathLayer,
} from 'deck.gl';
import { PathStyleExtension } from '@deck.gl/extensions';
import CustomOrbitView from '@/modules/Intuition/utils/CustomOrbitView';
import ThumbnailsHighlightLayer from '@/modules/Intuition/assets/thumbnailHighlight.png';
import { polygon, point } from '@turf/helpers';
import bbox from '@turf/bbox';
import centroid from '@turf/centroid';
import bboxPolygon from '@turf/bbox-polygon';
import { GET_PROJECTS_URL } from '@/modules/Sourcetray/api/projects';
import { FIND_IMAGE_URL } from '@/modules/Image/api/images';
import booleanIntersects from '@turf/boolean-intersects';
import BigNumber from 'bignumber.js';

export default class Intuition3D extends DataResource {
  constructor (options = {}) {
    super(options);
    this.defaultImageClasses = DEFAULT_IMAGE_CLASSES;
    this.id = options.id;
    this.width = options.width;
    this.height = options.height;
    this.fov = options.fov;
    this.fovMin = options.fovMin;
    this.fovMax = options.fovMax;
    this.nearPlaneThreshold = options.nearPlaneThreshold;
    this.farPlaneThreshold = options.farPlaneThreshold;
    this.deckStatus = options.deckStatus;
    this.deckLayers = [];
    this.activeImageClass = 'Core';
    this.activeImageClassTab = options.activeImageClassTab;
    this.tabs = [];
    this.imgCount = {};
    this.cameraControls = options.cameraControls;
    this.zoomFactor = options.zoomFactor;
    this.selectedCameraView = options.selectedCameraView;
    this.projects = [];
    this.projectsSelection = [];
    this.activeProject = '';
    this.pickedObjects = [];
    this.modelMode = 'default';
    this.modelModeList = [ 'texture', 'default' ];
    this.current2DAxes = 'XY';
    this.failedThumbnails = [];
    this.isDrawing = false;
    this.selectionAreaPoints = [];
    this.hasSelectionPolygon = false;
    this.currentZoom = 14;
  }

  async initial () {
    this.pointCloudData = await this.axios.get(INTUITION_MODELS_URL, { params: { projects_list: this.getProjectIds(), mode: this.modelMode } });
    const imageClasses = await this.pointCloudData.data.data.map(model => ({ text: model.attributes.image_class, chip: model.attributes.image_count }));
    this.setActiveImageClass(imageClasses);
    this.startLoading();

    const projects = this.getProjectIds();

    if (projects.length > 0) {
      this.setClusteringModes();
      this.initialize3DSpace();
    } else {
      console.log('No projects selected.');
    }
  }

  setActiveImageClass (imageClasses) {
    const tabs = orderBy((uniq(imageClasses.map(i => i.text))).map(k => ({ text: k, chip: sum(imageClasses.filter(j => j.text === k).map(l => l.chip)) })), [ 'text' ], [ 'asc' ]).map((m, n) => ({ id: n, text: m.text, chip: m.chip }))
    this.activeImageClass = this.getRouteQuery().img_tag_1 ? this.getRouteQuery().img_tag_1 : tabs[0]?.text;
  }

  async setFlyToVectors (imageId, mode) {
    this.flushErrors();
    const params = {
      image_id: imageId,
      mode,
    };
    try {
      const flyVectors = await this.axios.get(INTUITION_FETCH_VECTOR, { params });
      const target = flyVectors.data.data[0].attributes.image_vector;
      this.flyToTarget(...target);
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    }
  }

  async setClusteringModes () {
    this.flushErrors();
    try {
      const modes = await this.axios.get(INTUITION_MODELS_URL, { params: { projects_list: this.getProjectIds() } });
      const modelModeList = uniq(modes.data.data.map(i => ({ mode: i.attributes.mode })));
      const sortedModelModeList = orderBy(modelModeList, [ 'mode' ], [ 'asc' ]);
      this.modelModeList = sortedModelModeList.map(i => i.mode);
  
      this.setTabs();
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    }
  }

  async setTabs () {
    this.flushErrors();
    try {
      this.pointCloudData = await this.axios.get(INTUITION_MODELS_URL, { params: { projects_list: this.getProjectIds(), mode: this.modelMode } });

      const imageClasses = await this.pointCloudData.data.data.map(model => ({ text: model.attributes.image_class, chip: model.attributes.image_count }));

      this.tabs = [];
      this.tabs = orderBy((uniq(imageClasses.map(i => i.text))).map(k => ({ text: k, chip: sum(imageClasses.filter(j => j.text === k).map(l => l.chip)) })), [ 'text' ], [ 'asc' ]).map((m, n) => ({ id: n, text: m.text, chip: m.chip }));

      // check if there is an image class in query
      this.activeImageClass = this.getRouteQuery().img_tag_1 ? this.getRouteQuery().img_tag_1 : this.tabs[0].text;
      this.activeImageClassTab = this.tabs.filter(i => i.text === this.activeImageClass)[0].id;
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    }
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }

  createPointCloudLayer (url, project, imageClass) {
    this.deckStatus = 'Creating cluster point cloud layer';

    const pointCloudLayer = new PointCloudLayer({
      id: `pointCloudLayer_${project}_${imageClass}`,
      data: url,
      getColor: [ 255, 165, 0, 70 ],
      getNormal: d => [ (d[0] * PARTICLE_POSITION_MULTIPLIER) / 1000, (d[1] * PARTICLE_POSITION_MULTIPLIER) / 1000, -1 ],
      getPosition: d => [ d[0] * PARTICLE_POSITION_MULTIPLIER, d[1] * PARTICLE_POSITION_MULTIPLIER, d[2] * PARTICLE_POSITION_MULTIPLIER ],
      pointSize: 2.5,
      autoHighlight: true,
      coordinateOrigin: [ 0, 0 ],
      coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      highlightColor: [ 255, 255, 0, 128 ],
      pickable: true,
      visible: true,
    });

    return pointCloudLayer;
  }

  createThumbnailLayer (data, startSplice) {
    this.deckStatus = 'Creating representative image layer';
    const PARTICLE_SIZE = 5;

    const thumbnailLayer = new IconLayer({
      id: `IconLayer_${startSplice}`,
      data,
      autoHighlight: true,
      highlightColor: [ 255, 255, 0, 128 ],
      getIcon: d => ({ url: `${d.attributes.thumbnail_url}`, height: 50, width: 50 }),
      onIconError: event => {
        console.log(`Failed to fetch image ${event.source.attributes.image_id}`);
      },
      getPosition: d => [ d.attributes.image_vector[0] * PARTICLE_POSITION_MULTIPLIER, d.attributes.image_vector[1] * PARTICLE_POSITION_MULTIPLIER, d.attributes.image_vector[2] * PARTICLE_POSITION_MULTIPLIER ],
      getSize: (PARTICLE_SIZE * this.currentZoom) / 10,
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 128,
          height: 128,
          anchorY: 128,
          mask: true,
        },
      },
      sizeScale: 8,
      coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      opacity: 1,
      pickable: true,
      visible: true,
      updateTriggers: {
        getSize: this.currentZoom,
      },
    });

    return thumbnailLayer;
  }

  async initialize3DSpace () {
    this.flushErrors();
    this.startLoading();
    const axesData = [
      {
        from: {
          name: 'xStart',
          coordinates: [ -1000, 0, 0 ],
        },
        to: {
          name: 'xEnd',
          coordinates: [ 1000, 0, 0 ],
        },
        color: [ 252, 161, 3 ],
      },
      {
        from: {
          name: 'yStart',
          coordinates: [ 0, -1000, 0 ],
        },
        to: {
          name: 'yEnd',
          coordinates: [ 0, 1000, 0 ],
        },
        color: [ 3, 123, 252 ],
      },
      {
        from: {
          name: 'zStart',
          coordinates: [ 0, 0, -1000 ],
        },
        to: {
          name: 'zEnd',
          coordinates: [ 0, 0, 1000 ],
        },
        color: [ 172, 0, 235 ],
      },
    ];

    try {
      this.deckStatus = 'Drawing all space axes';
      this.axesHelper3D = new LineLayer({
        id: 'line-layer',
        data: axesData,
        getWidth: 5,
        getSourcePosition: d => d.from.coordinates,
        getTargetPosition: d => d.to.coordinates,
        getColor: d => d.color,
      });

      this.deckLayers = [ this.axesHelper3D ];

      this.views = [
        new OrbitView({
          id: '3d-map',
          x: '0%',
          y: '0%',
          orbitAxis: 'Y',
          height: '100%',
          width: '49.5%',
          clear: true,
          controller: { dragMode: 'pan', inertia: true },
          fovy: this.fov,
        }),

        new CustomOrbitView({
          id: '2d-map',
          x: '50%',
          y: '0%',
          height: '100%',
          width: '50%',
          orbitAxis: 'Y',
          flipY: false,
          near: -10000,
          far: 10000,
          clear: true,
          controller: { dragMode: 'pan', inertia: true, dragRotate: false },
          // fovy: this.fov,
          fovy: Infinity,
        }),
      ];

      this.currentZoom = 14;
      this.intuition3DDeck = new Deck({
        canvas: document.getElementById('intuition3DSpaceCanvas'),
        initialViewState: {
          zoom: 14,
          maxZoom: 10000,
          // maxZoom: 18,
          target: [ 0, 0, 0 ],
          rotationX: 0,
          rotationOrbit: 0,
          minRotationX: -360,
          maxRotationX: 360,
          minZoom: -10000,
        },
        onViewStateChange: ({ viewId, viewState }) => {
          this.onViewStateChange(viewId, viewState, 'onViewStateChange');
        },
        onClick: (info, event) => this.onDeckClick(info, event),
        onLoad: () => this.onDeckLoad(),
        getTooltip: ({ object }) => {
          if (typeof (object) === 'object') {
            if (object.type) {
              return {
                html: `<img src='${object.attributes.thumbnail_url}' style='width:100px; height:100px;'></img>`,
                style: {
                  backgroundColor: 'white',
                  fontSize: '0.8em',
                  width: '120px',
                  height: '120px',
                },
              };
            }

            if (object.length === 6) {
              const storage = this.store.getters['sourcetray/projects']?.filter(i => i.id === object[5])[0]?.attributes.project_settings;
              // get storage url from projects api
              return {
                html: `<img src='${storage?.PROJECT_STORAGE_URL}/${object[4]}${storage?.PROJECT_STORAGE_KEY}' style='width:100px; height:100px;'></img>`,
                style: {
                  backgroundColor: 'white',
                  fontSize: '0.8em',
                  width: '120px',
                  height: '120px',
                },
              };
            }
          }

          return '';
        },
        getCursor: ({ isDragging }) => {
          if (isDragging) {
            return 'grab';
          } if (this.isDrawing) {
            return 'crosshair';
          }
          return 'pointer';
        },
        pickingRadius: 10,
        layers: this.deckLayers,
        views: this.views,
      });

      this.deckStatus = 'Fetching cluster point cloud data';
      this.pointCloudData = await this.axios.get(INTUITION_MODELS_URL, { params: { projects_list: this.getProjectIds(), image_class: this.activeImageClass, mode: this.modelMode } });
      const pointCloudLayerArray = [];
      await this.pointCloudData.data.data.forEach(i => {
        pointCloudLayerArray.push(this.createPointCloudLayer(i.attributes.model_url, i.attributes.project, i.attributes.image_class.replace(' ', '')));
      });

      this.deckLayers = [ ...pointCloudLayerArray, ...this.deckLayers ];

      this.deckStatus = 'Updating layers';
      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });

      this.deckStatus = 'Fetching representative image data';
      this.thumbnailData = await this.axios.post(INTUITION_SAMPLES_URL, { projects: this.getProjectIds(), image_class: this.activeImageClass, mode: this.modelMode });

      const iconPerLayer = 100;

      const dataLen = this.thumbnailData.data.data.length

      for (let i = 0; i < (dataLen < 100 ? 1 : Math.floor(this.thumbnailData.data.data.length / iconPerLayer)); i++) {
        const slicedData = this.thumbnailData.data.data.slice((i * iconPerLayer) + 1, i * iconPerLayer + iconPerLayer);

        const thumbnailLayer = this.createThumbnailLayer(slicedData, (i * iconPerLayer) + 1);

        this.deckLayers = [ thumbnailLayer, ...this.deckLayers ];

        this.intuition3DDeck.setProps({
          layers: this.deckLayers,
        });
      }

      this.deckStatus = 'Defining initial view state';
      const startingViewstate = {
        '2d-map': {
          height: 576,
          maxRotationX: 360,
          maxZoom: Infinity,
          // maxZoom: 18,
          minRotationX: -360,
          minZoom: -Infinity,
          orbitAxis: 'Z',
          rotationOrbit: 0,
          rotationX: 0,
          target: [ 1.0408340855860846e-17, 0, 5.551115123125784e-17 ],
          width: 568,
          zoom: 14,
        },
        '3d-map': {
          height: 576,
          maxRotationX: 360,
          maxZoom: Infinity,
          // maxZoom: 18,
          minRotationX: -360,
          minZoom: -Infinity,
          orbitAxis: 'Y',
          rotationOrbit: 0,
          rotationX: 0,
          target: [ 1.1160379498207982e-17, -7.440252998805321e-18, 2.9761011995221285e-17 ],
          width: 568,
          zoom: 14,
        },

        maxRotationX: 360,
        maxZoom: 10000,
        // maxZoom: 18,
        minRotationX: -360,
        minZoom: -10000,
        rotationOrbit: 0,
        rotationX: 0,
        target: [ 0, 0, 0 ],
        zoom: 14,
      };

      // initialize viewState for 3d and 2d
      this.intuition3DDeck.setProps({
        viewState: startingViewstate,
      });


      this.setFromImagesFlyToLayers();
      this.setFromImagesFlyToLayers();
      this.setFromImagesFlyToLayers();
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    } finally {
      this.makeReady();
      this.stopLoading();
    }
  }

  setFromImagesFlyToLayers () {
    const routeQuery = this.getRouteQuery();
    if (routeQuery.img_id) {
      setTimeout(async () => {
        await this.setFlyToVectors(routeQuery.img_id, this.modelMode);
        this.setDeckZoom(this.currentZoom > 25 ? this.currentZoom : 25);
        this.fov = 30;
        this.changeFov();
        this.resetRotations();

        // check if in thumbnailData
        const selectedImageData = this.thumbnailData.data.data.filter(i => i.attributes.image_id === routeQuery.img_id);
        // console.log('thumbnail data check');
        // console.log(selectedImageData);

        // const params = {
        //   image_id: routeQuery.img_id,
        //   mode: this.modelMode,
        // };
        // const selectedImage = await this.axios.get(INTUITION_FETCH_VECTOR, { params });
        // const selectedImageData = selectedImage.data.data[0];

        if (selectedImageData[0]) {
          const orbitViewport = this.intuition3DDeck.getViewports()[0];
          let x; let y; let z;

          // XY
          if (this.current2DAxes === 'XY') {
            [ x, y, z ] = orbitViewport.project([
              selectedImageData[0].attributes.image_vector[0] * PARTICLE_POSITION_MULTIPLIER,
              selectedImageData[0].attributes.image_vector[1] * PARTICLE_POSITION_MULTIPLIER,
              this.currentTarget[2] ]);
          } else if (this.current2DAxes === 'ZY') {
            [ x, y, z ] = orbitViewport.project([
              this.currentTarget[0],
              selectedImageData[0].attributes.image_vector[1] * PARTICLE_POSITION_MULTIPLIER,
              selectedImageData[0].attributes.image_vector[0] * PARTICLE_POSITION_MULTIPLIER ]);
          } else {
            [ x, y, z ] = orbitViewport.project([
              selectedImageData[0].attributes.image_vector[1] * PARTICLE_POSITION_MULTIPLIER,
              this.currentTarget[1],
              selectedImageData[0].attributes.image_vector[0] * PARTICLE_POSITION_MULTIPLIER ]);
          }

          this.onDeckClick({ x, y, z });
          this.pickedObjects = this.formatPickedObjects(selectedImageData);

          const woHighlightLayers = this.deckLayers.filter(i => i.id.split('_')[0] != 'HighlightLayer_Thumbnail').filter(i => i.id.split('_')[0] != 'HighlightLayer_points');
          this.deckLayers = [ ...woHighlightLayers ];

          this.intuition3DDeck.setProps({
            layers: this.deckLayers,
          });

          // thumbnail highlight
          this.thumbnailHighlightLayer = new IconLayer({
            id: 'HighlightLayer_Thumbnail',
            data: selectedImageData,
            getIcon: () => ({ url: ThumbnailsHighlightLayer, height: 128, width: 128 }),
            onIconError: event => {
              console.log(event, 'Failed to fetch highlight image.');
            },
            getPosition: d => [ d.attributes.image_vector[0] * PARTICLE_POSITION_MULTIPLIER, d.attributes.image_vector[1] * PARTICLE_POSITION_MULTIPLIER, d.attributes.image_vector[2] * PARTICLE_POSITION_MULTIPLIER ],
            getSize: ((PARTICLE_SIZE * this.currentZoom) / 10) + (10 / this.currentZoom),
            sizeScale: 7,
            // sizeUnits:'pixels',
            // sizeScale: 2,
            coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
            opacity: 1,
            visible: true,
            updateTriggers: {
              getSize: this.currentZoom,
            },
          });
          this.deckLayers = [ this.thumbnailHighlightLayer, ...this.deckLayers ];

          this.intuition3DDeck.setProps({
            layers: this.deckLayers,
          });
        } else {
          const params = {
            image_id: routeQuery.img_id,
            mode: this.modelMode,
          };
          const selectedImage = await this.axios.get(INTUITION_FETCH_VECTOR, { params });
          const selectedImageData = selectedImage.data.data[0];

          const orbitViewport = this.intuition3DDeck.getViewports()[0];
          let x; let y; let z;
          // XY
          if (this.current2DAxes === 'XY') {
            [ x, y, z ] = orbitViewport.project([
              selectedImageData.attributes.image_vector[0] * PARTICLE_POSITION_MULTIPLIER,
              selectedImageData.attributes.image_vector[1] * PARTICLE_POSITION_MULTIPLIER,
              this.currentTarget[2] ]);
          } else if (this.current2DAxes === 'ZY') {
            [ x, y, z ] = orbitViewport.project([
              this.currentTarget[0],
              selectedImageData.attributes.image_vector[1] * PARTICLE_POSITION_MULTIPLIER,
              selectedImageData.attributes.image_vector[0] * PARTICLE_POSITION_MULTIPLIER ]);
          } else {
            [ x, y, z ] = orbitViewport.project([
              selectedImageData.attributes.image_vector[1] * PARTICLE_POSITION_MULTIPLIER,
              this.currentTarget[1],
              selectedImageData.attributes.image_vector[0] * PARTICLE_POSITION_MULTIPLIER ]);
          }

          this.onDeckClick({ x, y, z });

          // remove highlight layers here
          const woHighlightLayers = this.deckLayers.filter(i => i.id.split('_')[0] != 'HighlightLayer_Thumbnail').filter(i => i.id.split('_')[0] != 'HighlightLayer_points');
          this.deckLayers = [ ...woHighlightLayers ];

          this.intuition3DDeck.setProps({
            layers: this.deckLayers,
          });

          this.pointHighlightLayer = new PointCloudLayer({
            id: 'HighlightLayer_points',
            data: [ [ ...selectedImageData.attributes.image_vector, selectedImageData.attributes.image_id, selectedImageData.attributes.thumbnail_url, selectedImageData.attributes.project ] ],
            getColor: [ 255, 0, 0, 200 ],
            getNormal: d => [ ((d[0]) * PARTICLE_POSITION_MULTIPLIER) / 1000, ((d[1]) * PARTICLE_POSITION_MULTIPLIER) / 1000, -1 ],
            getPosition: d => [ (d[0]) * PARTICLE_POSITION_MULTIPLIER, (d[1]) * PARTICLE_POSITION_MULTIPLIER, parseFloat(d[2]) * PARTICLE_POSITION_MULTIPLIER ],
            pointSize: 5,
            coordinateOrigin: [ 0, 0 ],
            coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
            highlightColor: [ 255, 255, 0, 128 ],
            opacity: 1,
            visible: true,
          });

          this.deckLayers = [ this.pointHighlightLayer, ...this.deckLayers ];

          this.intuition3DDeck.setProps({
            layers: this.deckLayers,
          });
        }
      }, 2000);
    }
  }

  onDeckLoad () {
    this.deckStatus = '2D and 3D views intialized';
  }

  async onDeckClick (info) {
    const { x, y } = info;

    if (!this.isDrawing) {
      const width = 20;
      const depth = 10;

      const layersWOAxes = this.deckLayers.filter(i => i.id !== this.axesHelper3D.id).map(k => k.id);

      const pickedObjects = this.intuition3DDeck.pickMultipleObjects({
        x, y, radius: width, layersWOAxes, depth, unproject3D: true,
      });

      this.pickedObjects = this.formatPickedObjects(pickedObjects.map(i => i.object));

      this.deckLayers = this.deckLayers.filter(layer => layer.id.split('_')[0] !== 'HighlightLayer');

      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });

      if (this.pickedObjects.length > 0) {
        const thumbsData = this.pickedObjects.filter(i => i.type !== 'pointcloud');
        const pointsData = this.pickedObjects.filter(i => i.type === 'pointcloud');

        // remove highlight layers here
        const woHighlightLayers = this.deckLayers.filter(i => i.id.split('_')[0] != 'HighlightLayer_Thumbnail').filter(i => i.id.split('_')[0] != 'HighlightLayer_points');
        this.deckLayers = [ ...woHighlightLayers ];

        this.intuition3DDeck.setProps({
          layers: this.deckLayers,
        });

        //

        this.thumbnailHighlightLayer = new IconLayer({
          id: 'HighlightLayer_Thumbnail',
          data: thumbsData,
          getIcon: () => ({ url: ThumbnailsHighlightLayer, height: 128, width: 128 }),
          onIconError: event => {
            console.log(event, 'Failed to fetch highlight image.');
          },
          getPosition: d => [ d.position[0] * PARTICLE_POSITION_MULTIPLIER, d.position[1] * PARTICLE_POSITION_MULTIPLIER, d.position[2] * PARTICLE_POSITION_MULTIPLIER ],
          // getSize: ((PARTICLE_SIZE * this.currentZoom) / 10) + (10 / this.currentZoom),
          getSize: (PARTICLE_SIZE * this.currentZoom) / 9,
          sizeScale: 8,
          coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
          opacity: 1,
          visible: true,
          updateTriggers: {
            getSize: this.currentZoom,
          },
        });

        this.pointHighlightLayer = new PointCloudLayer({
          id: 'HighlightLayer_points',
          data: pointsData,
          getColor: [ 255, 0, 0, 200 ],
          getNormal: d => [ (d.position[0] * PARTICLE_POSITION_MULTIPLIER) / 1000, (d.position[1] * PARTICLE_POSITION_MULTIPLIER) / 1000, -1 ],
          getPosition: d => [ d.position[0] * PARTICLE_POSITION_MULTIPLIER, d.position[1] * PARTICLE_POSITION_MULTIPLIER, d.position[2] * PARTICLE_POSITION_MULTIPLIER ],
          pointSize: 5,
          coordinateOrigin: [ 0, 0 ],
          coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
          highlightColor: [ 255, 255, 0, 128 ],
          opacity: 1,
          visible: true,
        });

        this.deckLayers = [ this.thumbnailHighlightLayer, this.pointHighlightLayer, ...this.deckLayers ];

        this.intuition3DDeck.setProps({
          layers: this.deckLayers,
        });
      }

      // update url query to blank
      const route = this.getRouteQuery();

      if (this.pickedObjects[0].type === 'thumbnail') {
        const allImgIds = this.pickedObjects.map(i => i.id);
        if (!allImgIds.includes(route.img_id)) {
          this.setQueryString({});
          this.query = {};
          this.pushRouteQuery();
        }
      } else {
        const allImgIds = this.pickedObjects.map(i => i.id);
        if (!allImgIds.includes(route.img_id)) {
          this.setQueryString({});
          this.query = {};
          this.pushRouteQuery();
        }
      }

      if (this.hasSelectionPolygon) {
        this.deleteSelection();
        this.isDrawing = false;
        this.hasSelectionPolygon = false;
      }
    } else {
      // for XY
      if (this.current2DAxes === 'XY') {
        // this.addSelectionAreaPoints([ info.coordinate[0], info.coordinate[1], 1 / 50 ]);
        this.addSelectionAreaPoints([ info.coordinate[0], info.coordinate[1], this.currentTarget[2] ]);
      } else if (this.current2DAxes === 'ZY') {
        // this.addSelectionAreaPoints([ 1 / 50, info.coordinate[1], info.coordinate[2] ]);
        this.addSelectionAreaPoints([ this.currentTarget[0], info.coordinate[1], info.coordinate[2] ]);
      } else {
        // this.addSelectionAreaPoints([ info.coordinate[0], 1 / 50, info.coordinate[2] ]);
        this.addSelectionAreaPoints([ info.coordinate[0], this.currentTarget[1], info.coordinate[2] ]);
      }
    }
  }

  formatPickedObjects (pickedObjects) {
    const formattedPickedObjects = [];
    pickedObjects.map(async pickedObject => {
      if (typeof (pickedObject) === 'object') {
        if (pickedObject.type) {
          formattedPickedObjects.push({
            attributes: { ...pickedObject?.attributes },
            url: pickedObject.attributes.thumbnail_url,
            id: pickedObject.attributes.image_id,
            position: pickedObject.attributes.image_vector,
            type: 'thumbnail',
          });
        }

        if (pickedObject.length === 6) {
          let image = {};
          if (!pickedObject?.attributes) {
            image = await this.getImageById(pickedObject[3], pickedObject[5]);
          }
          const storage = this.store.getters['sourcetray/projects']?.filter(i => i.id === pickedObject[5])[0].attributes.project_settings;
          formattedPickedObjects.push({
            // attributes: { ...pickedObject?.attributes, ...image.attributes },
            attributes: { ...pickedObject?.attributes, ...image.attributes },
            url: `${storage.PROJECT_STORAGE_URL}/${pickedObject[4]}${storage.PROJECT_STORAGE_KEY}`,
            id: pickedObject[3],
            position: [ pickedObject[0], pickedObject[1], pickedObject[2] ],
            type: 'pointcloud',
          });
        }
      }
    });

    return uniqBy(formattedPickedObjects, 'id');
  }

  onViewStateChange (viewId, viewState, event) {
    const viewState2D = this.intuition3DDeck._getViewState()['2d-map'];
    const viewState3D = this.intuition3DDeck._getViewState()['3d-map'];

    this.currentTarget = viewState.target;
    this.currentZoom = viewState.zoom;

    const viewports = this.intuition3DDeck.getViewports();
    [ this.activeViewport ] = viewports.filter(i => i.id === viewId);

    this.newPathLayerData = [];

    if (this.hasSelectionPolygon) {
      if (this.current2DAxes === 'XY') {
        const pathLayerData = this.deckLayers.filter(i => i.id === 'path_selected_layer');
        this.newPathLayerData = pathLayerData[0].props.data[0].path.map(i => [ i[0], i[1], this.currentTarget[2] < 0 ? this.currentTarget[2] - 1e-10 : this.currentTarget[2] + 1e-10 ]);
      } else if (this.current2DAxes === 'ZY') {
        const pathLayerData = this.deckLayers.filter(i => i.id === 'path_selected_layer');
        this.newPathLayerData = pathLayerData[0].props.data[0].path.map(i => [ this.currentTarget[0] < 0 ? this.currentTarget[0] - 1e-10 : this.currentTarget[0] + 1e-10, i[1], i[2] ]);
      } else {
        const pathLayerData = this.deckLayers.filter(i => i.id === 'path_selected_layer');
        this.newPathLayerData = pathLayerData[0].props.data[0].path.map(i => [ i[0], this.currentTarget[1] < 0 ? this.currentTarget[1] - 1e-10 : this.currentTarget[1] + 1e-10, i[2] ]);
      }

      this.deckLayers = this.deckLayers.filter(i => i.id !== 'path_selected_layer');
      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });

      this.pathSelectedLayer = new PathLayer({
        id: 'path_selected_layer',
        data: [{ path: [ ...this.newPathLayerData ] }],
        pickable: false,
        lineWidthMinPixels: 2,
        lineWidthUnits: 'pixels',
        widthScale: 1,
        widthMinPixels: 2,
        billboard: true,
        getPath: d => d.path,
        getColor: [ 70, 130, 180 ],
        getWidth: 1 / 40000,
        getLineWidth: 2,
        getDashArray: [ 3, 2 ],
        dashJustified: true,
        dashGapPickable: true,
        extensions: [ new PathStyleExtension({ dash: true }) ],
      });

      this.deckLayers = [ this.pathSelectedLayer, ...this.deckLayers ];

      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });
    }

    if (event === 'onViewStateChange') {
      if (viewId === '2d-map') {
        this.intuition3DDeck.setProps({
          viewState: {
            '2d-map': { ...viewState },
            '3d-map': {
              ...viewState, ...viewState3D, rotationOrbit: viewState.rotationOrbit, rotationX: viewState.rotationX, target: viewState.target, zoom: viewState.zoom,
            },
          },
        });
      } else {
        this.intuition3DDeck.setProps({
          viewState: {
            '2d-map': { ...viewState2D, target: viewState.target, zoom: viewState.zoom },
            '3d-map': { ...viewState },
          },
        });
      }
    }
  }

  async getStorageUrl (projectId) {
    // update when source tray is fixed
    const params = {};
    this.flushErrors();
    try {
      const { data } = await this.axios.get(GET_PROJECTS_URL, params);

      const [ storage ] = data.data.filter(i => i.id === projectId);

      return {
        storageUrl: storage.attributes.project_settings.PROJECT_STORAGE_URL,
        storageKey: storage.attributes.project_settings.PROJECT_STORAGE_KEY,
      };
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    }
  }

  zoomIn () {
    const currentViewState = cloneDeep(this.intuition3DDeck._getViewState());

    if (currentViewState['2d-map']) {
      this.intuition3DDeck.setProps({
        viewState: {
          '2d-map': {
            ...currentViewState['2d-map'].viewState,
            target: currentViewState['2d-map'].target,
            rotationX: 0,
            rotationOrbit: 0,
            zoom: currentViewState['2d-map'].zoom + 0.5,
          },
          '3d-map': {
            ...currentViewState['3d-map'].viewState,
            target: currentViewState['3d-map'].target,
            rotationX: currentViewState['3d-map'].rotationX,
            rotationOrbit: currentViewState['3d-map'].rotationOrbit,
            zoom: currentViewState['3d-map'].zoom + 0.5,
          },

        },
      });
    } else {
      this.intuition3DDeck.setProps({
        viewState: {
          target: currentViewState.target,
          rotationX: currentViewState.rotationX,
          rotationOrbit: currentViewState.rotationOrbit,
          zoom: currentViewState.zoom + 0.5,
        },
      });
    }
  }

  zoomOut () {
    const currentViewState = cloneDeep(this.intuition3DDeck._getViewState());

    if (currentViewState['2d-map']) {
      this.intuition3DDeck.setProps({
        viewState: {
          '2d-map': {
            ...currentViewState['2d-map'].viewState,
            target: currentViewState['2d-map'].target,
            rotationX: 0,
            rotationOrbit: 0,
            zoom: currentViewState['2d-map'].zoom - 0.5,
          },
          '3d-map': {
            ...currentViewState['3d-map'].viewState,
            target: currentViewState['3d-map'].target,
            rotationX: currentViewState['3d-map'].rotationX,
            rotationOrbit: currentViewState['3d-map'].rotationOrbit,
            zoom: currentViewState['3d-map'].zoom - 0.5,
          },
        },
      });
    } else {
      this.intuition3DDeck.setProps({
        viewState: {
          target: currentViewState.target,
          rotationX: currentViewState.rotationX,
          rotationOrbit: currentViewState.rotationOrbit,
          zoom: currentViewState.zoom - 0.5,
        },
      });
    }
  }

  isZoomedOutMax () {
    if (this.intuition3DDeck) {
      const { zoom, minZoom } = cloneDeep(this.intuition3DDeck._getViewState());
      return zoom === minZoom;
    }
    return false;
  }

  isZoomedInMax () {
    if (this.intuition3DDeck) {
      const { zoom, maxZoom } = cloneDeep(this.intuition3DDeck._getViewState());
      return zoom === maxZoom;
    }
    return false;
  }

  changeFov () {
    this.intuition3DDeck._getViews()[0].props.fovy = this.fov;
    const viewState3D = cloneDeep(this.intuition3DDeck._getViewState()['3d-map']);
    const viewState2D = cloneDeep(this.intuition3DDeck._getViewState()['2d-map']);
    if (viewState2D) {
      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
        viewState: {
          '3d-map': { ...viewState3D, target: viewState3D.target, zoom: viewState3D.zoom },
          '2d-map': { ...viewState2D, target: viewState2D.target, zoom: viewState2D.zoom },
        },
      });
    } else {
      this.intuition3DDeck.setProps({
        viewState: { ...this.intuition3DDeck.viewState, ...this.intuition3DDeck.viewManager.viewState },
      });

      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
      });
    }
  }

  setDeckZoom (zoom) {
    const viewState3D = cloneDeep(this.intuition3DDeck._getViewState()['3d-map']);
    const viewState2D = cloneDeep(this.intuition3DDeck._getViewState()['2d-map']);

    if (viewState2D) {
      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
        viewState: {
          '3d-map': {
            ...viewState3D,
            zoom,
          },
          '2d-map': {
            ...viewState2D,
            zoom,
          },
        },
      });
    } else {
      this.intuition3DDeck.setProps({
        viewState: { ...this.intuition3DDeck.viewState, ...this.intuition3DDeck.viewManager.viewState },
      });

      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
      });
    }
  }

  setDeckMinZoom (zoom) {
    const viewState3D = cloneDeep(this.intuition3DDeck._getViewState()['3d-map']);
    const viewState2D = cloneDeep(this.intuition3DDeck._getViewState()['2d-map']);

    if (viewState2D) {
      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
        viewState: {
          '3d-map': {
            ...viewState3D,
            minZoom: zoom,
          },
          '2d-map': {
            ...viewState2D,
            minZoom: zoom,
          },
        },
      });
    } else {
      this.intuition3DDeck.setProps({
        viewState: { ...this.intuition3DDeck.viewState, ...this.intuition3DDeck.viewManager.viewState },
      });

      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
      });
    }
  }

  setDeckMinZoom (zoom) {
    const viewState3D = cloneDeep(this.intuition3DDeck._getViewState()['3d-map']);
    const viewState2D = cloneDeep(this.intuition3DDeck._getViewState()['2d-map']);

    if (viewState2D) {
      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
        viewState: {
          '3d-map': {
            ...viewState3D,
            minZoom: zoom,
          },
          '2d-map': {
            ...viewState2D,
            minZoom: zoom,
          },
        },
      });
    } else {
      this.intuition3DDeck.setProps({
        viewState: { ...this.intuition3DDeck.viewState, ...this.intuition3DDeck.viewManager.viewState },
      });

      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
      });
    }
  }

  resetRotations () {
    const viewState3D = cloneDeep(this.intuition3DDeck._getViewState()['3d-map']);
    const viewState2D = cloneDeep(this.intuition3DDeck._getViewState()['2d-map']);
    const { rotationX } = TWO_D_AXES[this.current2DAxes];
    const { rotationOrbit } = TWO_D_AXES[this.current2DAxes];

    const routeQuery = this.getRouteQuery();

    if (viewState2D) {
      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
        viewState: {
          '3d-map': {
            ...viewState3D,
            rotationX,
            rotationOrbit,
            zoom: routeQuery.img_id ? this.currentZoom : 14,
            transitionEasing: t => 1 - (1 - t) * (1 - t),
            transitionDuration: 800,
          },
          '2d-map': {
            ...viewState2D,
            rotationX,
            rotationOrbit,
            zoom: routeQuery.img_id ? this.currentZoom : 14,
            transitionEasing: t => 1 - (1 - t) * (1 - t),
            transitionDuration: 800,
          },
        },
      });
    } else {
      this.intuition3DDeck.setProps({
        viewState: { ...this.intuition3DDeck.viewState, ...this.intuition3DDeck.viewManager.viewState },
      });

      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
      });
    }
  }

  flyToTarget (x, y, z) {
    const viewState3D = cloneDeep(this.intuition3DDeck._getViewState()['3d-map']);
    const viewState2D = cloneDeep(this.intuition3DDeck._getViewState()['2d-map']);

    if (viewState2D) {
      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
        viewState: {
          '3d-map': {
            ...viewState3D,
            target: [ x * PARTICLE_POSITION_MULTIPLIER, y * PARTICLE_POSITION_MULTIPLIER, z * PARTICLE_POSITION_MULTIPLIER ],
          },
          '2d-map': {
            ...viewState2D,
            target: [ x * PARTICLE_POSITION_MULTIPLIER, y * PARTICLE_POSITION_MULTIPLIER, z * PARTICLE_POSITION_MULTIPLIER ],
          },
        },
      });
    } else {
      this.intuition3DDeck.setProps({
        viewState: { ...this.intuition3DDeck.viewState, ...this.intuition3DDeck.viewManager.viewState },
      });

      this.intuition3DDeck.setProps({
        views: this.intuition3DDeck._getViews(),
      });
    }
  }

  setActive2DAxes (axes) {
    this.current2DAxes = axes;
  }

  changeAxes (axis3d, axis2d, rotationX, rotationOrbit) {
    const viewState = cloneDeep(this.intuition3DDeck._getViewState());

    this.intuition3DDeck._getViews()[0].props.orbitAxis = axis3d;
    this.intuition3DDeck._getViews()[1].props.orbitAxis = axis3d;

    this.views = [
      this.intuition3DDeck._getViews()[0],
      this.intuition3DDeck._getViews()[1],
    ];

    this.intuition3DDeck.setProps({
      views: this.views,
    });

    this.intuition3DDeck.setProps({
      viewState: {
        '3d-map': {
          ...viewState['3d-map'], target: viewState['3d-map'].target, zoom: viewState['3d-map'].zoom, rotationX, rotationOrbit, orbitAxis: axis3d,
        },
        '2d-map': {
          ...viewState['2d-map'], target: viewState['2d-map'].target, zoom: viewState['2d-map'].zoom, rotationX, rotationOrbit, orbitAxis: axis3d,
        },
      },
    });

    // const routeQuery = this.getRouteQuery();
    // if (routeQuery.img_id) {
    //   setTimeout(async () => {
    //     await this.setFlyToVectors(routeQuery.img_id, this.modelMode);
    //     this.setDeckZoom(this.currentZoom > 25 ? this.currentZoom : 25);
    //     this.fov = this.fov > 30 ? this.fov : 30;
    //     this.changeFov();
    //     this.resetRotations();
    //   }, 1500);
    // }

    if (this.hasSelectionPolygon) {
      this.deleteSelection();
      this.isDrawing = false;
      this.hasSelectionPolygon = false;
    }
  }

  setModelMode (mode) {
    this.modelMode = mode;
  }

  async changePositionModel () {
    this.flushErrors();
    this.startLoading();
    this.deckStatus = 'Updating clustering mode';

    // this.pickedObjects = [];

    // get picked objects id
    // get from the new data
    this.deckLayers = [ this.axesHelper3D ];
    this.intuition3DDeck.setProps({
      layers: this.deckLayers,
    });
    try {
      this.pointCloudData = await this.axios.get(INTUITION_MODELS_URL, { params: { projects_list: this.getProjectIds(), image_class: this.activeImageClass, mode: this.modelMode } });
      const pointCloudLayerArray = [];
      await this.pointCloudData.data.data.forEach(i => {
        pointCloudLayerArray.push(this.createPointCloudLayer(i.attributes.model_url, i.attributes.project, i.attributes.image_class.replace(' ', '')));
      });
  
      this.deckLayers = [ ...pointCloudLayerArray, ...this.deckLayers ];
      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });
  
      this.thumbnailData = await this.axios.post(INTUITION_SAMPLES_URL, { projects: this.getProjectIds(), image_class: this.activeImageClass, mode: this.modelMode });
      const thumbnailLayer = this.createThumbnailLayer(this.thumbnailData.data.data);
  
      this.deckLayers = [ thumbnailLayer, ...this.deckLayers ];
      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });
  
      this.setFromImagesFlyToLayers();
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    }

    setTimeout(() => {
      this.stopLoading();
    }, 2000);
  }

  clearDeck () {
    this.pickedObjects = [];
    this.deckLayers = [ this.axesHelper3D ];
    this.intuition3DDeck.setProps({
      layers: this.deckLayers,
    });
  }

  moveHighlightLayersBelow () {
    const highlightLayers = this.deckLayers.filter(layer => layer.id.split('_')[0] === 'HighlightLayer');
    const otherLayers = this.deckLayers.filter(layer => layer.id.split('_')[0] !== 'HighlightLayer' || layer.id !== this.axesHelper3D.id);

    this.deckLayers = [];
    this.deckLayers = [ ...otherLayers, ...highlightLayers, this.axesHelper3D ];
    this.intuition3DDeck.setProps({
      layers: this.deckLayers,
    });
  }

  async changeImageClass (imgClass) {
    this.flushErrors();
    this.startLoading();
    this.pickedObjects = [];
    this.activeImageClass = imgClass;
    this.deckLayers = [ this.axesHelper3D ];
    this.intuition3DDeck.setProps({
      layers: this.deckLayers,
    });

    const params = {
      projects_list: this.getProjectIds(),
      image_class: this.activeImageClass,
      mode: this.modelMode,
    };
    try {
      this.pointCloudData = await this.axios.get(INTUITION_MODELS_URL, { params });
      const pointCloudLayerArray = [];
      await this.pointCloudData.data.data.forEach(i => {
        pointCloudLayerArray.push(this.createPointCloudLayer(i.attributes.model_url, i.attributes.project, i.attributes.image_class.replace(' ', '')));
      });

      this.deckLayers = [ ...pointCloudLayerArray, ...this.deckLayers ];
      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });

      this.thumbnailData = await this.axios.post(INTUITION_SAMPLES_URL, { projects: this.getProjectIds(), image_class: this.activeImageClass, mode: this.modelMode });
      const thumbnailLayer = this.createThumbnailLayer(this.thumbnailData.data.data);

      this.deckLayers = [ thumbnailLayer, ...this.deckLayers ];
      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    }
    this.deleteSelection();

    this.stopLoading();
  }

  toPixelCoords (ptArray) {
    let x; let y; let z;

    if (this.current2DAxes === 'XY') {
      [ x, y, z ] = this.activeViewport.project([ ...ptArray, this.currentTarget[2] ]);
    } else if (this.current2DAxes === 'ZY') {
      [ x, y, z ] = this.activeViewport.project([ this.currentTarget[0], ptArray[1], ptArray[0] ]);
    } else {
      [ x, y, z ] = this.activeViewport.project([ ptArray[1], this.currentTarget[1], ptArray[0] ]);
    }

    return [ x, y, z ];
  }

  getDistance (x1, y1, x2, y2) {
    const y = x2 - x1;
    const x = y2 - y1;

    return Math.sqrt(Math.pow(Math.abs(x), 2) + Math.pow(Math.abs(y), 2));
  }

  togglePolygonSelection () {
    const boostFactor = 100000000000000000000000000000000000000000000000000.0;
    if (!this.isDrawing && this.hasSelectionPolygon) {
      // remove points
      this.deckLayers = this.deckLayers.filter(i => i.id !== 'pointCloud_selected_layer');

      this.selectionPointCloudLayer = null;

      // create turf polygon
      let selectionPolygon = null;
      if (this.current2DAxes === 'XY') {
        selectionPolygon = polygon([ this.pathSelectedLayer.props.data[0].path.map(i => [ i[0], i[1] ]) ], { name: 'selection-polygon' });
      } else if (this.current2DAxes === 'ZY') {
        selectionPolygon = polygon([ this.pathSelectedLayer.props.data[0].path.map(i => [ i[2], i[1] ]) ], { name: 'selection-polygon' });
      } else {
        selectionPolygon = polygon([ this.pathSelectedLayer.props.data[0].path.map(i => [ i[2], i[0] ]) ], { name: 'selection-polygon' });
      }

      // minX,minY,maxX,maxY
      const viewportBounds = bbox(selectionPolygon);

      // get centroid of polygon selection
      const selectionCentroid = centroid(selectionPolygon);

      const bboxPolygonShape = bboxPolygon(viewportBounds);
      const bboxPolygonCentroid = centroid(bboxPolygonShape);

      // compute radius: inputs centroid pt, maxX,maxY from Bounds
      const pxCentroid = this.toPixelCoords(bboxPolygonCentroid.geometry.coordinates);

      let pxBoundsMax; let
        pxBoundsMin;

      if (this.current2DAxes === 'XY') {
        pxBoundsMax = this.toPixelCoords([ viewportBounds[2], viewportBounds[3], this.currentTarget[2] ]); // XY
        pxBoundsMin = this.toPixelCoords([ viewportBounds[0], viewportBounds[1], this.currentTarget[2] ]); // XY
      } else if (this.current2DAxes === 'ZY') {
        pxBoundsMax = this.toPixelCoords([ viewportBounds[2], viewportBounds[3], this.currentTarget[0] ]); // ZY
        pxBoundsMin = this.toPixelCoords([ viewportBounds[0], viewportBounds[1], this.currentTarget[0] ]); // ZY
      } else {
        pxBoundsMax = this.toPixelCoords([ viewportBounds[2], viewportBounds[3], this.currentTarget[1] ]); // ZX
        pxBoundsMin = this.toPixelCoords([ viewportBounds[0], viewportBounds[1], this.currentTarget[1] ]); // ZX
      }

      let pxRadius = 0;
      if (this.current2DAxes === 'XY') {
        pxRadius = this.getDistance(pxCentroid[0], pxCentroid[1], pxBoundsMax[0], pxBoundsMax[1]);
      } else if (this.current2DAxes === 'ZY') {
        pxRadius = this.getDistance(pxCentroid[2], pxCentroid[1], pxBoundsMax[2], pxBoundsMax[1]);
      } else {
        pxRadius = this.getDistance(pxCentroid[2], pxCentroid[0], pxBoundsMax[2], pxBoundsMax[0]);
      }

      const pickDepth = 1000; // number of objects to be picked within the radius
      const layersWOAxes = this.deckLayers.filter(i => i.id.split('_')[0] === 'pointCloudLayer').map(k => k.id);

      // use grid points to pickmultipleobjects in point cloud
      this.pickedObjectsArray = [];
      this.startLoading();
      this.deckStatus = 'Checking all intersected images.';

      const pickedObjects = this.intuition3DDeck.pickMultipleObjects({
        x: pxCentroid[0], y: pxCentroid[1], radius: pxRadius, layerIds: layersWOAxes, depth: pickDepth, unproject3D: true,
      });
      // const pickedObjects = this.intuition3DDeck.pickObjects({x: pxBoundsMin[0], y: pxBoundsMax[1], width: pxBoundsMax[0]-pxBoundsMin[0], height: pxBoundsMax[1]-pxBoundsMin[1], layerIds: layersWOAxes});
      if (pickedObjects.length > 0) {
        pickedObjects.forEach(pickedObject => {
          const selectedPolygonCoordinates = selectionPolygon.geometry.coordinates[0];

          const boostedCoords = selectedPolygonCoordinates.map(coords => [ coords[0] * boostFactor, coords[1] * boostFactor ]);

          const boostedSelectionPolygon = polygon([ boostedCoords ], { name: 'boosted-selection-polygon' });

          let originalCoords = [];
          if (pickedObject.object.attributes) {
            originalCoords = pickedObject.object.attributes.image_vector;
          } else {
            originalCoords = [ pickedObject.object[0], pickedObject.object[1], pickedObject.object[2] ];
          }

          // const minXBounds = new BigNumber(viewportBounds[0]*boostFactor);
          // const maxXBounds = new BigNumber(viewportBounds[2]*boostFactor);
          // const minYBounds = new BigNumber(viewportBounds[1]*boostFactor);
          // const maxYBounds = new BigNumber(viewportBounds[3]*boostFactor);

          const minXBounds = (viewportBounds[0] * boostFactor);
          const maxXBounds = (viewportBounds[2] * boostFactor);
          const minYBounds = (viewportBounds[1] * boostFactor);
          const maxYBounds = (viewportBounds[3] * boostFactor);
          // XY
          if (this.current2DAxes === 'XY') {
            // const xPicked = new BigNumber(originalCoords[0]*PARTICLE_POSITION_MULTIPLIER*boostFactor);
            // const yPicked = new BigNumber(originalCoords[1]*PARTICLE_POSITION_MULTIPLIER*boostFactor);

            const xPicked = originalCoords[0] * PARTICLE_POSITION_MULTIPLIER * boostFactor;
            const yPicked = originalCoords[1] * PARTICLE_POSITION_MULTIPLIER * boostFactor;

            // if(xPicked.isGreaterThan(minXBounds) && xPicked.isLessThan(maxXBounds)){
            //   if(yPicked.isGreaterThan(minYBounds) && yPicked.isLessThan(maxYBounds)){
            if ((xPicked > minXBounds) && (xPicked < maxXBounds)) {
              if ((yPicked > minYBounds) && (yPicked < maxYBounds)) {
                const pickedIntersected = booleanIntersects(boostedSelectionPolygon, point([ originalCoords[0] * PARTICLE_POSITION_MULTIPLIER * boostFactor, originalCoords[1] * PARTICLE_POSITION_MULTIPLIER * boostFactor ]));
                if (pickedIntersected) {
                  this.pickedObjectsArray.push(pickedObject);
                }
                // this.pickedObjectsArray.push(pickedObject);
              }
            }
          } else if (this.current2DAxes === 'ZY') {
            // const xPicked = new BigNumber(originalCoords[2]*PARTICLE_POSITION_MULTIPLIER*boostFactor);
            // const yPicked = new BigNumber(originalCoords[1]*PARTICLE_POSITION_MULTIPLIER*boostFactor);

            const xPicked = originalCoords[2] * PARTICLE_POSITION_MULTIPLIER * boostFactor;
            const yPicked = originalCoords[1] * PARTICLE_POSITION_MULTIPLIER * boostFactor;

            // if(xPicked.isGreaterThan(minXBounds) && xPicked.isLessThan(maxXBounds)){
            //   if(yPicked.isGreaterThan(minYBounds) && yPicked.isLessThan(maxYBounds)){
            if ((xPicked > minXBounds) && (xPicked < maxXBounds)) {
              if ((yPicked > minYBounds) && (yPicked < maxYBounds)) {
                const pickedIntersected = booleanIntersects(boostedSelectionPolygon, point([ originalCoords[2] * PARTICLE_POSITION_MULTIPLIER * boostFactor, originalCoords[1] * PARTICLE_POSITION_MULTIPLIER * boostFactor ]));
                if (pickedIntersected) {
                  this.pickedObjectsArray.push(pickedObject);
                }
              }
            }
          } else {
            // const xPicked = new BigNumber(originalCoords[2]*PARTICLE_POSITION_MULTIPLIER*boostFactor);
            // const yPicked = new BigNumber(originalCoords[0]*PARTICLE_POSITION_MULTIPLIER*boostFactor);

            const xPicked = originalCoords[2] * PARTICLE_POSITION_MULTIPLIER * boostFactor;
            const yPicked = originalCoords[0] * PARTICLE_POSITION_MULTIPLIER * boostFactor;

            // if(xPicked.isGreaterThan(minXBounds) && xPicked.isLessThan(maxXBounds)){
            //   if(yPicked.isGreaterThan(minYBounds) && yPicked.isLessThan(maxYBounds)){
            if ((xPicked > minXBounds) && (xPicked < maxXBounds)) {
              if ((yPicked > minYBounds) && (yPicked < maxYBounds)) {
                const pickedIntersected = booleanIntersects(boostedSelectionPolygon, point([ originalCoords[2] * PARTICLE_POSITION_MULTIPLIER * boostFactor, originalCoords[0] * PARTICLE_POSITION_MULTIPLIER * boostFactor ]));
                if (pickedIntersected) {
                  this.pickedObjectsArray.push(pickedObject);
                }
              }
            }
          }
        });
      }
      // selectionGridPoints.features.forEach(pt => {
      //   let x; let y; let z;

      //   if (this.current2DAxes === 'XY') {
      //     [ x, y, z ] = this.activeViewport.project([ ...pt.geometry.coordinates, this.currentTarget[2] ]);
      //   } else if (this.current2DAxes === 'ZY') {
      //     [ x, y, z ] = this.activeViewport.project([ this.currentTarget[0], pt.geometry.coordinates[1], pt.geometry.coordinates[0] ]);
      //   } else {
      //     [ x, y, z ] = this.activeViewport.project([ pt.geometry.coordinates[1], this.currentTarget[1], pt.geometry.coordinates[0] ]);
      //   }

      //   const pickedObjects = this.intuition3DDeck.pickMultipleObjects({
      //     x, y, radius: pickRadius, layerIds: layersWOAxes, depth: pickDepth, unproject3D: true,
      //   });
      //   console.log(pickedObjects);
      //   if (pickedObjects.length > 0) {
      //     pickedObjects.forEach(pickedObject => {
      //       const boostFactor = 100000000.00;
      //       const selectedPolygonCoordinates = selectionPolygon.geometry.coordinates[0];
      //       // console.log(selectedPolygonCoordinates);
      //       const boostedCoords = selectedPolygonCoordinates.map(coords => [ coords[0] * boostFactor, coords[1] * boostFactor ]);
      //       // console.log(boostedCoords);
      //       const boostedSelectionPolygon = polygon([ boostedCoords ], { name: 'boosted-selection-polygon' });
      //       // XY
      //       if (this.current2DAxes === 'XY') {
      //         // const pickedIntersected = booleanWithin(point([ pickedObject.coordinate[0], pickedObject.coordinate[1] ]), selectionPolygon);
      //         // const pickedIntersected = booleanWithin(point([ pickedObject.coordinate[0] * boostFactor, pickedObject.coordinate[1] * boostFactor ]), boostedSelectionPolygon);
      //         if ((pickedObject.coordinate[0] > viewportBounds[0]) && (pickedObject.coordinate[0] < viewportBounds[2])) {
      //           if ((pickedObject.coordinate[1] > viewportBounds[1]) && (pickedObject.coordinate[1] < viewportBounds[3])) {
      //             console.log(`${pickedObject.coordinate[0]},${viewportBounds[0]},${pickedObject.coordinate[0]},${viewportBounds[2]}`);
      //             console.log(`${pickedObject.coordinate[1]},${viewportBounds[1]} && ${pickedObject.coordinate[1]},${viewportBounds[3]}`);
      //             const pickedIntersected = booleanWithin(point([ pickedObject.coordinate[0] * boostFactor, pickedObject.coordinate[1] * boostFactor ]), boostedSelectionPolygon);
      //             if (pickedIntersected) {
      //               this.pickedObjectsArray.push(pickedObject);
      //             }
      //           }
      //         }
      //         // if (pickedIntersected) {
      //         //   this.pickedObjectsArray.push(pickedObject);
      //         // }
      //       } else if (this.current2DAxes === 'ZY') {
      //         // const pickedIntersected = booleanWithin(point([ pickedObject.coordinate[2], pickedObject.coordinate[1] ]), selectionPolygon);
      //         const pickedIntersected = booleanWithin(point([ pickedObject.coordinate[2] * boostFactor, pickedObject.coordinate[1] * boostFactor ]), boostedSelectionPolygon);
      //         if (pickedIntersected) {
      //           this.pickedObjectsArray.push(pickedObject);
      //         }
      //       } else {
      //         // const pickedIntersected = booleanWithin(point([ pickedObject.coordinate[2], pickedObject.coordinate[0] ]), selectionPolygon);
      //         const pickedIntersected = booleanWithin(point([ pickedObject.coordinate[2] * boostFactor, pickedObject.coordinate[0] * boostFactor ]), boostedSelectionPolygon);
      //         if (pickedIntersected) {
      //           this.pickedObjectsArray.push(pickedObject);
      //         }
      //       }
      //     });
      //   }
      // });

      // check if selectionGridPoints is inside polygon
      this.pickedObjects = this.formatPickedObjects(this.pickedObjectsArray.map(i => i.object));
      this.pickedThumbData = [];

      // check if ID is in thumbData
      this.pickedThumbObjects = [];
      this.pickedPointObjects = [];

      this.pickedObjects.forEach(thumb => {
        if (includes(this.thumbnailData.data.data.map(j => j.attributes.image_id), thumb.id)) {
          const newThumb = { ...thumb, type: 'thumbnail' };
          this.pickedThumbObjects.push(newThumb);
        } else {
          this.pickedPointObjects.push(thumb);
        }
      });

      this.deckLayers = this.deckLayers.filter(layer => layer.id.split('_')[0] !== 'HighlightLayer');

      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });

      const filteredPickedObjectsArray = this.thumbnailData.data.data.filter(i => this.pickedThumbObjects.map(j => j.id).includes(i.attributes.image_id));

      this.deckLayers = this.deckLayers.filter(layer => layer.id.split('_')[1] !== 'polyhighlight');

      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });

      const extraThumbnailLayer = this.createThumbnailLayer(filteredPickedObjectsArray, `polyhighlight_${this.pickedThumbObjects.length}`);

      if (this.pickedObjects.length > 0) {
        this.thumbnailHighlightLayer = new IconLayer({
          id: 'HighlightLayer_Thumbnail',
          data: this.pickedThumbObjects,
          getIcon: () => ({ url: ThumbnailsHighlightLayer, height: 128, width: 128 }),
          onIconError: event => {
            console.log(event, 'Failed to fetch highlight image.');
          },
          getPosition: d => [ d.position[0] * PARTICLE_POSITION_MULTIPLIER, d.position[1] * PARTICLE_POSITION_MULTIPLIER, d.position[2] * PARTICLE_POSITION_MULTIPLIER ],
          getSize: ((PARTICLE_SIZE * this.currentZoom) / 10) + (10 / this.currentZoom),
          sizeScale: 8,
          coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
          opacity: 1,
          visible: true,
          updateTriggers: {
            getSize: this.currentZoom,
          },
        });

        this.pointHighlightLayer = new PointCloudLayer({
          id: 'HighlightLayer_points',
          data: this.pickedPointObjects,
          getColor: [ 255, 0, 0, 200 ],
          getNormal: d => [ (d.position[0] * PARTICLE_POSITION_MULTIPLIER) / 1000, (d.position[1] * PARTICLE_POSITION_MULTIPLIER) / 1000, -1 ],
          getPosition: d => [ d.position[0] * PARTICLE_POSITION_MULTIPLIER, d.position[1] * PARTICLE_POSITION_MULTIPLIER, d.position[2] * PARTICLE_POSITION_MULTIPLIER ],
          pointSize: 5,
          coordinateOrigin: [ 0, 0 ],
          coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
          highlightColor: [ 255, 255, 0, 128 ],
          opacity: 1,
          visible: true,
        });

        this.deckLayers = [ this.thumbnailHighlightLayer, this.pointHighlightLayer, extraThumbnailLayer, ...this.deckLayers ];

        this.intuition3DDeck.setProps({
          layers: this.deckLayers,
        });
      }
    }

    this.intuition3DDeck.setProps({
      layers: this.deckLayers,
    });

    this.stopLoading();
  }

  addSelectionAreaPoints (coordinates) {
    this.hasSelectionPolygon = true;
    this.selectionAreaPoints.push({ coordinates });

    this.deleteSelection();

    const pathSelectedPoints = this.selectionAreaPoints.map(i => i.coordinates);

    this.selectionAreaPointsData = [];

    this.selectionAreaPointsData = [ ...this.selectionAreaPoints ];

    this.pathSelectedLayer = new PathLayer({
      id: 'path_selected_layer',
      data: [{ path: [ ...pathSelectedPoints, pathSelectedPoints[0] ] }],
      pickable: false,
      lineWidthMinPixels: 1,
      lineWidthUnits: 'pixels',
      widthScale: 1,
      widthMinPixels: 1,
      billboard: true,
      getPath: d => d.path,
      getColor: [ 70, 130, 180 ],
      getWidth: () => 1 / 50000,
      // getWidth: () =>{ return 1 / this.currentZoom*4*10000},
      getLineWidth: 2,
      getDashArray: [ 3, 2 ],
      dashJustified: true,
      dashGapPickable: true,
      extensions: [ new PathStyleExtension({ dash: true }) ],
    });

    this.selectionPointCloudLayer = new PointCloudLayer({
      id: 'pointCloud_selected_layer',
      data: this.selectionAreaPointsData.map(i => i.coordinates),
      getColor: [ 70, 130, 180, 255 ],
      getNormal: d => [ (d[0]) / 1000, (d[1]) / 1000, -1 ],
      getPosition: d => [ d[0], d[1], d[2] ],
      pointSize: 5,

      autoHighlight: false,
      coordinateOrigin: [ 0, 0 ],
      coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      opacity: 1,
      pickable: false,
      visible: true,
    });

    this.deckLayers = [ this.selectionPointCloudLayer, this.pathSelectedLayer, ...this.deckLayers ];

    this.intuition3DDeck.setProps({
      layers: this.deckLayers,
    });
  }

  deleteSelection () {
    if (this.pathSelectedLayer) {
      if (this.selectionPointCloudLayer) {
        this.selectionPointCloudLayer.finalizeState();
      }
      this.pathSelectedLayer.finalizeState();

      this.deckLayers = this.deckLayers.filter(i => i.id.split('_')[1] !== 'selected');

      this.intuition3DDeck.setProps({
        layers: this.deckLayers,
      });

      if (!this.isDrawing) {
        this.hasSelectionPolygon = false;
        this.polygonData = [];
        this.solidPolygonData = [];
        this.selectionAreaPoints = [];

        this.deckLayers = this.deckLayers.filter(layer => layer.id.split('_')[0] !== 'HighlightLayer');

        this.intuition3DDeck.setProps({
          layers: this.deckLayers,
        });

        this.pickedObjects = [];
      }
    }
  }

  async getImageById (id, projectId) {
    let image = {};

    try {
      const { data } = await this.axios.get(FIND_IMAGE_URL(id, projectId));
      image = data.data.search_results?.[0];
    } catch (error) {
      console.log('Image does not exists.');
    }

    return image;
  }

  async downloadImage (image) {
    const urlSource = await this.axios.get(FIND_IMAGE_URL(image.id, image.attributes.project_id));
    const downloadUrl = urlSource.data.data.search_results[0].attributes.download_url;

    try {
      const { data } = await this.axios.get(downloadUrl);
      window.open(data.data.raw_file_url);
    } catch ({ response }) {
      const { message } = response.data.errors;
      this.dialog('error', {
        color: 'accent',
        persistent: true,
        illustration: () => import('@/components/Icons/IconListOneRowError'),
        illustrationHeight: 200,
        title: 'Download Limit Reached',
        text: message,
      });
    }
  }

  async getImageAttributes (image) {
    const urlSource = await this.axios.get(FIND_IMAGE_URL(image.id, image?.attributes?.project_id));
    const attributes = urlSource.data.data.search_results?.[0]?.attributes;
    return attributes;
  }

  computeZoom (xImage, yImage, zImage) {
    // console.log(this.intuition3DDeck);
    // closest point to camera
    const viewport = this.intuition3DDeck.getViewports()[1];

    const [ , , z ] = viewport.unproject([ 0, 0, -1 ]);
    // z is cut in half for each +1 zoom level
    const zoom = Math.log2(z / zImage);

    return zoom;
  }
}
