import Vue from 'vue';
import core from '@core/router/core';
import errors from '@core/router/errors';
import modules from '@core/router/modules';
import pages from '@core/router/pages';
import {
  flatten, merge, keyBy, values,
} from 'lodash';

const components = {
  install (Vue) {
    let _pages = values(merge(
      keyBy(core, 'name'),
      keyBy(modules, 'name'),
      keyBy(pages, 'name'),
      keyBy(errors, 'name'),
    ));

    _pages = flatten(_pages.map(i => merge([ i ], flatten(i.children))));

    Vue.options = Vue.util.mergeOptions(Vue.options, {
      created () {
        this.$page = _pages.find(page => page?.name === this?.$route?.name);
        this.$options.pages = Vue.observable(_pages);
      },
    });
  },
};

Vue.use(components);
