import Config from '@core/resources/Config';
import store from '@core/store';
import sources from '@/modules/Sourcetray/config/sources.js';
import { groupBy } from 'lodash';

export const PROJECT_TYPE_ALL = 'all';
export const STATUS_TYPE_ALL = 'all';

const _projects = store?.getters['sourcetray/projects'] ?? [];

const group = groupBy(_projects.map(i => ({ ...i, ...i.attributes })).map(project => ({
  value: project.id,
  text: project.name,
  projectType: project.project_type,
  color: sources.find(source => source.name === project.project_type)?.color,
})), 'projectType');

const projects = Object.keys(group)
  .flatMap(key => [].concat(group[key]));

const items = Object.keys(group)
  .flatMap(key => [{ divider: true, isDivider: true }, {
    header: key,
    isSubheader: true,
    color: sources.find(source => source.name === key)?.color,
  }].concat(group[key]));

const itemsAll = [
  { text: 'All Projects', value: PROJECT_TYPE_ALL },
].concat(items);

export const files = new Config({
  items,
  itemsAll,
  projects,
  selected: itemsAll[0],
  current: itemsAll[0]?.value,
  currentFilter: null,
  disabled: false,
  find (key) {
    return this.items.find(i => i.value === key);
  },
  unsetCurrent () {
    this.current = '';

    return this;
  },
  setCurrentFilter (filter) {
    this.currentFilter = filter;
  },
  unsetCurrentFilter () {
    this.currentFilter = '';

    return this;
  },
  setCurrent (index) {
    this.current = index;
  },
  getCurrent () {
    return this.current;
  },
  setSelected (file, i) {
    this.selected = file;
    this.current = i;
  },
  getSelected () {
    return this.selected;
  },
  getSelectedValue () {
    return this.selected?.value;
  },
  isSelected (file) {
    return this.selected?.value === file?.value;
  },
  isSetToAll () {
    return this.selected?.value === PROJECT_TYPE_ALL;
  },
  setItemsFromProjects (projects) {
    this.group = groupBy(projects.map(i => ({ ...i, ...i.attributes })).map(project => ({
      value: project.id,
      text: project.name,
      projectType: project.project_type,
      color: sources.find(source => source.name === project.project_type)?.color,
    })), 'projectType');

    this.projects = Object.keys(this.group)
      .flatMap(key => [].concat(this.group[key]));

    this.setItems(Object.keys(this.group)
      .flatMap(key => [{ divider: true, isDivider: true }, {
        header: key,
        isSubheader: true,
        color: sources.find(source => source.name === key)?.color,
      }].concat(this.group[key])));

    return this;
  },
});

const statusItems = [
  {
    color: 'success',
    key: 'Uploading',
    text: 'Uploaded',
    code: 'uploaded_successfully',
    value: 'uploaded_successfully',
    phaseOrder: 0,
  },
  {
    color: '#ffc107',
    key: 'Pre-Audit',
    text: 'Pre-Audit in Progress',
    code: 'pre-audit_in_progress',
    value: 'pre-audit_in_progress',
    phaseOrder: 1,
  },
  {
    color: 'warning',
    key: 'Pre-Audit',
    text: 'Pre-Audit Completed',
    code: 'pre-audit_completed',
    value: 'pre-audit_completed',
    phaseOrder: 1,
  },
  {
    color: 'warning',
    key: 'Approval',
    text: 'Pending for Audit Approval',
    code: 'pending_for_audit_approval',
    value: 'pending_for_audit_approval',
    phaseOrder: 2,
  },
  {
    color: '#28a745',
    key: 'Approval',
    text: 'Approved for Audit',
    code: 'approved_for_audit',
    value: 'approved_for_audit',
    phaseOrder: 2,
  },
  {
    color: '#dc3545',
    key: 'Approval',
    text: 'Rejected for Audit',
    code: 'rejected_for_audit',
    value: 'rejected_for_audit',
    phaseOrder: 2,
  },
  {
    color: '#ffc107',
    key: 'Auditing',
    text: 'Audit in Progress',
    code: 'audit_in_progress',
    value: 'audit_in_progress',
    phaseOrder: 3,
  },
  {
    color: '#28a745',
    key: 'Auditing',
    text: 'Audit Completed',
    code: 'audit_completed',
    value: 'audit_completed',
    phaseOrder: 3,
  },
  {
    color: '#ffc107',
    key: 'Processing',
    text: 'Processing in Progress',
    code: 'processing_in_progress',
    value: 'processing_in_progress',
    phaseOrder: 4,
  },
  {
    color: '#28a745',
    key: 'Processing',
    text: 'Processing Completed',
    code: 'processing_completed',
    value: 'processing_completed',
    phaseOrder: 4,
  },
  {
    color: 'rgb(71, 229, 179)',
    key: 'Deployment',
    text: 'Deployed',
    code: 'deployed',
    value: 'deployed',
    phaseOrder: 5,
  },
];

const statusItemsAll = [
  { text: 'All Status', value: STATUS_TYPE_ALL },
].concat(statusItems);

export const statuses = {
  items: statusItems,
  itemsAll: statusItemsAll,
  current: statusItems[0]?.value,
  currentFilter: null,
  find (code) {
    return this.items.find(i => i.code === code) || {};
  },
  getKey (code, key) {
    return this.find(code)?.[key];
  },
  unsetCurrent () {
    this.current = '';

    return this;
  },
  setCurrentFilter (filter) {
    this.currentFilter = filter;
  },
  unsetCurrentFilter () {
    this.currentFilter = '';

    return this;
  },
};

export default {
  files,
  statuses,
};
