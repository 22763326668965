import Billing from '@/modules/Billing/resources/Billing';
import { GET_BILLING_INVOICE_URL } from '@/modules/Billing/api/billing';

export default class Invoice extends Billing {
  async list () {
    this.startLoading();

    const params = {};
    const { data } = await this.axios.get(GET_BILLING_INVOICE_URL, { params });

    console.log(params, data, 'x');
    // this.setItems(data.data);

    this.stopLoading();
  }

  setItems (items) {
    this.items = items.map(item => this.makeItem(item));
  }

  makeItem (item) {
    return {
      ...item,
      ...item.attributes,
    };
  }
}
