<template>
  <app-content>
    <meta-tag :title="$trans('Billing Details')"></meta-tag>
    <app-dialogbox></app-dialogbox>

    <v-row>
      <v-col
        cols="12"
        lg="8" md="10"
        class="mx-auto"
      >
        <v-btn :to="{ name: 'profile.subscriptions.change' }" text>
          <v-icon left small>mdi-arrow-left</v-icon>
          <span v-text="$trans('Back')"></span>
        </v-btn>
        <h1 class="display-1 font-weight-bold mt-5 mb-10" v-text="$trans('Billing Details')"></h1>
        <subscription-update-payment-form
          :items="customer.fields"
          :customer="customer"
          fetch-user-billing-details
          submit-text="Next"
          @success="onSuccess"
          @cancel="onCancel"
        ></subscription-update-payment-form>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import Customer from '@/modules/Billing/resources/Customer';
import RefreshTokenGuard from '@/middleware/RefreshTokenGuard';
import CheckRoutePermission from '@/middleware/CheckRoutePermission';

export default {
  layout: 'web',

  middleware: [ RefreshTokenGuard, CheckRoutePermission ],

  meta: {
    permissions: [ 'payment' ],
  },

  data: () => ({
    customer: new Customer,
  }),

  methods: {
    onSuccess () {
      this.$router.replace({ name: 'checkout' });
    },

    onCancel () {
      this.$router.replace({ name: 'profile.subscriptions.change' });
    },
  },
};
</script>
