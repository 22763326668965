import ActionMenuRegistrar from '@core/registrars/ActionMenuRegistrar';

ActionMenuRegistrar.register([
  {
    type: 'separator',
    inset: true,
    sort: 199,
  },
  {
    icon: 'mdi-power',
    text: 'Sign out',
    to: { name: 'logout' },
    sort: 200,
  },
]);
