import prettyBytes from 'pretty-bytes';

export const formatFileSizeFromThreshold = (size, threshold = 1000, units = [ 'Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ], delimiter = ' ') => {
  size *= threshold;
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold));
  // eslint-disable-next-line no-restricted-properties
  return (size / Math.pow(threshold, i)).toFixed(2) * 1 + delimiter + units[i];
};

// eslint-disable-next-line max-len
export const formatFileSize = (byte, options = { maximumFractionDigits: 2 }) => prettyBytes(byte ?? 0, options);

export default {
  filters: {
    formatFileSize,
    toMb (byte, options = { maximumFractionDigits: 2 }) {
      return prettyBytes(byte ?? 0, options);
    },
    toPrettyBytes (byte, options = { maximumFractionDigits: 2 }) {
      return prettyBytes(byte ?? 0, options);
    },
  },

  methods: {
    toMb (byte, options = { maximumFractionDigits: 2 }) {
      return prettyBytes(byte ?? 0, options);
    },
  },
};
