<template>
  <app-can-view code="webgis_pie_chart">
    <v-tooltip left transition="slide-x-reverse-transition">
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <viz-pie-chart-map-control-btn
            offset-x nudge-left
            class="ma-1"
            @click:toggle="onClickToggle"
          >
            <viz-pie-chart-map-control-card
              v-if="piechart.hasData()"
            ></viz-pie-chart-map-control-card>
            <template v-else>
              <v-card class="pa-5">
                <v-card-text class="text-center muted--text">
                  <div v-html="$trans('No records found.')"></div>
                </v-card-text>
              </v-card>
            </template>
          </viz-pie-chart-map-control-btn>
        </div>
      </template>
      <span v-text="$trans('Toggle lithology layer')"></span>
    </v-tooltip>
  </app-can-view>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'VizPieChartMapControl',

  props: {
    resource: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters({
      piechart: 'viz/piechart/piechart',
      isVizCardShown: 'viz/piechart/shown',
      meta: 'viz/piechart/meta',
      isTriggered: 'webgis/spatial/isTriggered',
    }),
  },

  watch: {
    isVizCardShown (isShown) {
      if (isShown) {
        this.resource.bringMapMarkersToFront();
      }
    },
    isTriggered: {
      immediate: true,
      handler (isTriggered) {
        if (isTriggered) {
          this.resource.listGeoFilteredWells(this.$route.query.geo_polygon);
        }
      },
    },
  },

  async mounted () {
    await this.resource.listMapMarkers();
    await this.resource.listSummary();
  },

  methods: {
    onClickToggle () {
      this.$nextTick(() => {
        if (this.isVizCardShown) {
          this.resource.bringMapMarkersToFront();
        }
      });
    },
  },
};
</script>
