<template>
  <v-skeleton-loader width="100%" height="66px" class="ov-hidden">
    <v-card flat height="66px" class="ov-hidden">
      <div class="v-skeleton-loader__quick-summary rounded v-skeleton-loader__bone"></div>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
export default {
  name: 'QuickSummaryItemLoadingState',
};
</script>

<style>
.v-skeleton-loader__quick-summary {
  background: rgba(0, 0, 0, 0.12);
  height: 100px;
}
</style>
