<template>
  <v-badge
    v-model="keyPressed"
    :content="content"
    bordered
    bottom
    class="s-badge d-block"
    color="dark"
    offset-x="28"
    offset-y="28"
    transition="fade-transition"
  >
    <slot></slot>
  </v-badge>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ShortkeyTooltip',

  props: {
    type: {
      type: String,
      default: 'ctrl',
    },

    content: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      ctrlIsPressed: 'shortkey/ctrlIsPressed',
      altIsPressed: 'shortkey/altIsPressed',
    }),

    keyPressed () {
      return this.type === 'alt' ? this.altIsPressed : this.ctrlIsPressed;
    },
  },
};
</script>
