<template>
  <tree-view :data="json" :options="computedOptions"></tree-view>
</template>

<script>
import TreeView from 'vue-json-tree-view';
import Vue from 'vue';

Vue.use(TreeView);

export default {
  name: 'JsonTreeview',

  props: {
    json: {
      type: [ String, Object, Array ],
      default: null,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    computedOptions () {
      return {
        rootObjectKey: 'root',
        ...this.options,
      };
    },
  },
};
</script>
