/**
 * It contains the storage URL for all the raw files to be displayed in ElasticDocs.
 *
 * @type {String}
 */
export const STORAGE_URL = 'https://ausstorageacc.blob.core.windows.net/elasticdocs-australia-blob';

export default Object.freeze({
  STORAGE_URL,
});
