import moment from 'moment';

class Timer {
  constructor (options = {}) {
    this.time = options.time ?? new Date;
    this.interval = null;
    this.remaining = 0;
  }

  start () {
    this.time = new Date;
  }

  run (uploadedBytes, totalBytes, callback = null) {
    const timeElapsed = (new Date()) - this.time;
    const uploadSpeed = uploadedBytes / (timeElapsed / 1000);

    this.setRemainingTime((totalBytes - uploadedBytes) / uploadSpeed);

    if (callback) {
      callback(this.getRemainingTime());
    }

    return this;
  }

  setRemainingTime (time) {
    this.remainingTime = moment.duration(time, 'seconds');
    this.remaining = moment.duration(time, 'seconds').humanize();
  }

  getRemaining () {
    return this.remaining;
  }

  getRemainingTime () {
    return this.remainingTime;
  }

  stop () {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}

export default new Timer;
