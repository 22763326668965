class ScrollEventHandler {
  constructor () {
    this.pageURL = null;
  }

  pageURLPath (url) {
    this.pageURL = url;
  }

  setScrollPosition (scrollPosition) {
    sessionStorage.setItem(`${this.pageURL.replace('/', '')}_scroll_position`, scrollPosition);
  }

  getScrollPosition () {
    return parseInt(sessionStorage.getItem(`${this.pageURL.replace('/', '')}_scroll_position`), 10);
  }
}

// Enforce singleton
export default new ScrollEventHandler();
