import Vue from 'vue';
import {
  merge, keyBy, values,
  flatten, isEmpty, reject,
} from 'lodash';

const sidebars = {
  install (Vue) {
    const core = this.requireSidebar(require.context(
      // The relative path of the modules folder
      '@core',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base route filenames
      /(modules)\/.*\/config\/sidebar\.js$/,
    ));

    const modules = this.requireSidebar(require.context(
      // The relative path of the modules folder
      '@',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base route filenames
      /(modules)\/.*\/config\/sidebar\.js$/,
    ));

    const sidebar = values(merge(
      keyBy(flatten(core), 'name'),
      keyBy(flatten(modules), 'name'),
    ));

    const self = this;
    Vue.options = Vue.util.mergeOptions(Vue.options, {
      created () {
        const items = self.cleanUpSidebar(sidebar)
          .filter(item => item.meta.enabled).sort((a, b) => a.sort - b.sort);
        this.$options.sidebar = Vue.observable(items);
      },
    });
  },

  cleanUpSidebar (items) {
    const sidebarItems = items;

    sidebarItems.map(sidebar => {
      if (sidebar.parent) {
        const parent = sidebarItems.find(i => i.name === sidebar.parent);
        const { children } = parent;

        if (!children.find(i => i.name === sidebar.name)) {
          parent.children.push(sidebar);
          parent.children = parent.children.sort((a, b) => a.sort - b.sort);
          parent.meta.hasChildren = true;
          parent.meta.children = [ ...parent.meta.children, sidebar.name ];
        }
      }

      return sidebar;
    });

    return reject(sidebarItems, item => item.meta?.parentOnly && isEmpty(item.children))
      .filter(i => !i.parent).map(item => this.buildItem(item));
  },

  requireSidebar (r) {
    return r.keys().map(item => {
      const sidebarComponent = r(item);

      return sidebarComponent.default.map(item => this.buildItem(item));
    });
  },

  buildItem (item) {
    item.children = (item.children || []).map(child => ({
      ...{
        meta: {
          enabled: true,
          hasChildren: (child.children && child.children.length > 0) || false,
          children: Object.assign(
            [],
            [ child.name ],
            (child.meta && child.meta.children) || [],
          ),
        },
      },
      ...child,
    }));

    return {
      ...item,
      ...{
        meta: {
          ...{
            enabled: true,
            hasChildren: (item.children && item.children.length > 0) || false,
            children: Object.assign([], [ item.name ], (item.meta && item.meta.children) || []),
          },
          ...item.meta,
        },
      },
    };
  },
};

Vue.use(sidebars);
