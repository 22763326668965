import store from '@core/store';
import { SUPPORTS_AUTHENTICATION } from '@core/config/auth';

export default async function (to, from, next) {
  const isAuthenticated = SUPPORTS_AUTHENTICATION && store.getters['auth/isAuthenticated'];
  const user = store.getters['auth/user'];
  const path = user.getRedirectRouteFromEnv();
  const intended = to.query.redirect
    ? to.query.redirect
    : path;

  if (!SUPPORTS_AUTHENTICATION) {
    return next();
  }

  if (isAuthenticated) {
    return next(intended);
  }

  return next();
}
