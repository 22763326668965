<template>
  <v-app v-cloak class="s-app">
    <document-sidebar>
      <template v-slot:sidebar:append>
        <!-- <app-send-feedback-btn></app-send-feedback-btn> -->
      </template>
    </document-sidebar>

    <portal-target name="app:loading" slim></portal-target>
    <!-- <app-bar></app-bar> -->

    <app-snackbar></app-snackbar>

    <app-dialogbox></app-dialogbox>

    <v-slide-y-transition mode="in-out">
      <v-main class="workspace">
        <app-workspace>
          <v-slide-y-transition mode="out-in">
            <router-view></router-view>
          </v-slide-y-transition>
        </app-workspace>
        <portal-target name="main:bottom" slim></portal-target>
      </v-main>
    </v-slide-y-transition>

    <shortkey></shortkey>

    <portal-target name="sidebar:right"></portal-target>

    <app-footer></app-footer>
  </v-app>
</template>

<script>

export default {
  name: 'Document',
};
</script>
