<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit, invalid }"
    slim
  >
    <v-form
      ref="form"
      enctype="multipart/form-data"
      @submit.prevent="handleSubmit(submit)"
    >
      <v-card flat max-width="480" class="transparent">
        <v-card-text class="pt-0 px-0">
          <p v-html="$trans('We would love to hear from you.')"></p>
          <validation-provider
            v-slot="{ errors }" vid="message"
            :name="$trans('message')"
            rules="required|min:100"
            mode="aggressive"
          >
            <v-textarea
              v-model="text"
              :error-messages="errors"
              :label="$trans('Write your message')"
              name="message"
              counter
              auto-grow
              outlined filled
              prepend-inner-icon="mdi-message-text-outline"
              class="is-required"
              height="250"
            ></v-textarea>
          </validation-provider>
          <v-btn
            :disabled="invalid"
            :href="href"
            x-large block
            color="primary"
          ><span v-html="$trans('Send')"></span></v-btn>
          <div
            class="mt-4"
            v-html="$trans('Or directly email us<br> at <a href=\'mailto:{email}\'>{email}</a>', {
              email: $app.supportEmail,
            })"
          ></div>
        </v-card-text>
      </v-card>
    </v-form>
  </validation-observer>
</template>

<script>
export default {
  name: 'FormContact',

  data: () => ({
    text: null,
  }),

  computed: {
    href () {
      return `mailto:${this.$app.supportEmail}?body=${encodeURIComponent(this.text)}`;
    },
  },

  methods: {
    submit (e) {
      console.log(e);
    },
  },
};
</script>
