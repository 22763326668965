<template>
  <v-container class="app-workflow">
    <v-row>
      <v-slide-x-transition>
        <v-col v-show="openMenu" cols="auto" lg="2">
          <app-stickybar v-slot="{ stuck }">
            <div :class="{ 'mt-5': stuck }">
              <portal-target name="workflow:sidebar"></portal-target>
            </div>
          </app-stickybar>
        </v-col>
      </v-slide-x-transition>
      <v-col cols="auto" lg="10">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WorkflowContent',

  computed: mapGetters({
    openMenu: 'menu/openMenu',
  }),
};
</script>
