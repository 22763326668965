var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('images-data-iterator-thumbnail',_vm._g(_vm._b({staticClass:"intuition-data-iterator__thumbnail",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-layout',{attrs:{"fill-height":"","align-center":"","align-content-center":"","justify-center":"","column":""}},[_c('v-overlay',{attrs:{"value":hover,"absolute":""}},[_c('v-layout',{attrs:{"fill-height":"","column":"","justify-center":""}},[_c('v-btn',{staticClass:"mb-2",attrs:{"dark":"","small":"","outlined":""},on:{"click":function($event){return _vm.flyToTarget(_vm.image)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-image-search-outline")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Fly to image'))}})],1),_c('v-btn',{staticClass:"mb-2",attrs:{"to":{
              name: 'intuition.single',
              params: { slug: _vm.image.id },
              query: { project_id: _vm.image.attributes.project_id },
            },"exact":"","dark":"","small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-magnify-plus-outline")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('View image'))}})],1),_c('v-btn',{staticClass:"mb-2",attrs:{"dark":"","small":"","outlined":""},on:{"click":function($event){return _vm.download(_vm.image)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-download")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Download'))}})],1),_c('v-btn',{staticClass:"mb-2",attrs:{"dark":"","small":"","outlined":""},on:{"click":function($event){return _vm.goToPage(_vm.image)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-file-eye-outline")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$trans("Go to page {page}", {
                page: _vm.image.attributes.page_num,
              }))}})],1)],1)],1)],1)]}},{key:"append",fn:function(){return [_c('v-layout',{staticClass:"caption text-truncate text--disabled"},[_c('router-link',{staticClass:"caption text-truncate text--disabled",attrs:{"title":_vm.image.attributes.doc_title,"to":{
          name: 'reports.single',
          params: { slug: _vm.image.attributes.doc_ref },
          query: {
            page_num: _vm.image.attributes.page_num,
            project_id: _vm.item.attributes.project_id,
            project_type: _vm.image.attributes.project_type,
            back: _vm.$route.path,
          },
        }},domProps:{"textContent":_vm._s(_vm.image.attributes.doc_title)}}),_c('v-spacer'),_c('span',{domProps:{"textContent":_vm._s(((_vm.$trans('Page')) + ": " + (_vm.image.attributes.page_num)))}})],1),_vm._t("image.append",null,null,{ image: _vm.image })]},proxy:true}],null,true)},'images-data-iterator-thumbnail',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }