<template>
  <v-menu
    v-model="isShown"
    :close-on-content-click="false"
    :close-on-click="false"
    bottom left
    origin="top right"
    nudge-left="8"
    transition="scale-transition"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        min-width="32" width="32"
        min-height="32"
        height="32" class="ma-1"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
      >
        <v-icon small>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script>
export default {
  name: 'VizMapControlBtn',

  props: {
    icon: {
      type: String,
      default: 'mdi-chart-scatter-plot',
    },
  },

  computed: {
    isShown: {
      get () {
        return this.$store.getters['viz/viz/shown'];
      },
      set (isShown) {
        this.$store.dispatch('viz/viz/setShown', isShown);
      },
    },
  },
};
</script>
