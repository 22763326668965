<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="false"
  >
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip left transition="slide-x-reverse-transition">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            min-width="32" width="32"
            min-height="32"
            height="32" class="ma-1"
            v-bind="$attrs"
            v-on="{ ...onMenu, ...onTooltip }"
            @click="onStart"
          >
            <v-icon small>mdi-ruler-square</v-icon>
          </v-btn>
        </template>
        <span v-text="$trans('Measure distances and areas')"></span>
      </v-tooltip>
    </template>
    <v-card max-width="320" class="map-measure__results">
      <v-card-title><span v-text="$trans('Measure distances and areas')"></span></v-card-title>
      <v-card-text>
        <div v-if="text" v-html="text"></div>
        <p v-else v-text="$trans('Start creating a measurement by adding points to the map.')"></p>
      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="onCancel"><span v-text="$trans('Cancel')"></span></v-btn>
        <v-spacer></v-spacer>
        <v-btn small text @click="onFinish"><span v-text="$trans('Finish')"></span></v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'MapMeasureControlBtn',

  props: {
    map: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    menu: false,
    text: false,
  }),

  methods: {
    onStart () {
      this.$emit('click:start');
      this.map.startMapMeasureControl();

      this.map.map.on('measurefinish', () => {
        this.menu = false;
        this.text = false;
      });

      this.map.map.on('mouseup', () => {
        this.text = this.map.getMeasurementResults();
      });
    },

    onCancel () {
      this.$emit('click:cancel');
      this.menu = false;
      this.map.cancelMapMeasureControl();
    },

    onFinish () {
      this.$emit('click:finish');
      this.menu = false;
      this.map.finishMapMeasureControl();
    },
  },
};
</script>

<style lang="scss">
.leaflet-control-measure.leaflet-bar.leaflet-control {
  display: none;
}

.map-measure {
  &__results {
    .heading {
      font-weight: bold;
    }
  }
}
</style>
