<template>
  <app-tabs-content name="about">
    <!-- spacer -->
    <template v-slot:footer>
      <v-card height="50" flat color="transparent"></v-card>
    </template>
    <!-- spacer -->
  </app-tabs-content>
</template>

<script>
export default {
  layout: 'admin',
};
</script>
