export const state = {
  open: false,
  text: null,
  type: null,
  color: null,
};

export const getters = {
  text: state => state.text,
  type: state => state.type,
  color: state => state.color,
  isOpen: state => state.open,
};

export const mutations = {
  SET_TEXT (state, { text, type }) {
    state.text = text;
    state.type = type;
    state.color = type;
    state.open = true;
  },
};

export const actions = {
  error ({ commit }, text) { commit('SET_TEXT', { text, type: 'error' }); },

  warning ({ commit }, text) { commit('SET_TEXT', { text, type: 'warning' }); },

  success ({ commit }, text) { commit('SET_TEXT', { text, type: 'success' }); },

  info ({ commit }, text) { commit('SET_TEXT', { text, type: 'info' }); },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
