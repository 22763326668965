const instances = localStorage.getItem('settings:instances');

export const state = {
  instances: JSON.parse(((instances === 'undefined' || instances === 'null') ? false : instances) ?? '[]'),
};

export const getters = {
  instances: state => state.instances,
  currentInstance: state => state.instances?.[0],
  scheduledMaintenanceMessage: state => state.instances?.[0]
    ?.attributes?.settings?.SCHEDULED_MAINTENANCE_MESSAGE,
};

export const mutations = {
  SET_INSTANCES (state, instances) {
    state.instances = instances;
    localStorage.setItem('settings:instances', JSON.stringify(instances));
  },

  UNSET_INSTANCES (state) {
    state.instances = [];
    localStorage.removeItem('settings:instances');
  },
};

export const actions = {
  setInstances ({ commit }, instances) {
    commit('SET_INSTANCES', instances);
  },

  unsetInstances ({ commit }) {
    commit('UNSET_INSTANCES');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
