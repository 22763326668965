var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":true,"width":_vm.width,"persistent":"","no-click-animation":""}},[_c('v-card',[_c('div',{staticClass:"pa-1 text-right"},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":_vm.back}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-layout',{staticClass:"mb-4",attrs:{"justify-space-between":"","align-center":""}},[_c('h3',{domProps:{"textContent":_vm._s(_vm.$trans('Image Preview'))}}),_c('v-sheet',[(_vm.isImageinIntuition)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.goToIntuition()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-graph-outline")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('View in Intuition'))}})],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","text":""},on:{"click":_vm.reloadImage}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-reload")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Reload'))}})],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.downloadImage(_vm.image.data)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-download")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Download'))}})],1),(_vm.image.loaded)?_c('v-btn',{staticClass:"ml-2",attrs:{"to":{
                  name: 'reports.single',
                  params: { slug: _vm.image.data.doc_ref },
                  query: {
                    page_num: _vm.image.data.page_num,
                    project_type: _vm.image.project_type,
                    back: _vm.$route.fullPath,
                  },
                },"small":"","text":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-file-document-outline")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Go to page {page}', {
                    page: _vm.image.data.page_num,
                  }))}})],1):_vm._e()],1)],1),(_vm.image.hasErrors())?_c('v-alert',{attrs:{"outlined":"","text":"","type":"error","dismissible":""}},[_c('span',{staticClass:"login-error-message",domProps:{"textContent":_vm._s(_vm.image.getErrors('message', 'Something went wrong'))}})]):_vm._e(),_c('panzoom-image-previewer',{ref:"panzoom-image-previewer",attrs:{"loading":_vm.image.loading,"slider":"","zoom-bar":"","zoom-on-wheel":"","zoom-on-dblclick":"","height":"600"}},[(_vm.image.loaded)?_c('img',{attrs:{"src":_vm.image.data.file_url,"width":"100%","height":"auto"}}):_vm._e()]),_c('v-layout',{staticClass:"mt-3",attrs:{"justify-space-between":""}},[_c('span',{staticClass:"muted--text caption",domProps:{"textContent":_vm._s(_vm.$trans('From {name}', {
                name: _vm.image.data.doc_title,
              }))}}),_c('span',{staticClass:"muted--text caption",domProps:{"textContent":_vm._s(_vm.$trans('page {page}', {
                page: _vm.image.data.page_num,
              }))}})])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }