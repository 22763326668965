<template>
  <app-content>
    <dashboard-view></dashboard-view>

    <!-- DataSource Tray -->
    <data-source-tray-filter
      width="400px"
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <!-- Quick Stats -->
    <quick-summary></quick-summary>
    <!-- Quick Stats -->

    <!-- Search -->
    <v-row fill-height>
      <v-col cols="12" lg="6">
        <app-search-field
          v-model="well.search"
          :label="$trans('Search a well')"
          :placeholder="$trans('Search a well')"
          :loading="well.searching"
          :autofocus="isDesktop"
          solo
          class="mb-4"
          @click:clear="clearSearch"
          @enter:search="setSearch"
        ></app-search-field>
      </v-col>
    </v-row>
    <!-- Search -->

    <!-- Main Datatable -->
    <v-card>
      <app-stickybar class="bg-white">
        <template v-slot:default="{ stuck }">
          <v-layout
            :class="{ 'elevation-8': stuck }"
            class="text-no-wrap flex-no-wrap ov-x-auto"
          >
            <app-data-table-toolbar
              :size="well.selectedSize()"
              exportable
              class="flex-grow-0 flex-no-wrap"
              @click:export="exportSelectedItems"
              @click:clear="unselectAll"
            ></app-data-table-toolbar>
            <v-spacer></v-spacer>
            <v-data-footer
              class="no-border flex-grow-1 flex-no-wrap text-no-wrap ov-x-auto"
              :pagination="well.pagination"
              :options="well.options"
              :items-per-page-options="well.options.rowsPerPage"
              @update:options="updateList"
            ></v-data-footer>
          </v-layout>
        </template>
      </app-stickybar>
      <v-divider></v-divider>
      <v-data-table
        v-model="well.selected"
        :footer-props="{ 'items-per-page-options': well.options.rowsPerPage }"
        :dense="well.meta.dense"
        :single-select="well.meta.singleSelect"
        :headers="well.headers"
        :items="well.items"
        :loading="well.loading"
        :mobile-breakpoint="well.meta.mobileBreakpoint"
        :server-items-length="well.pagination.total"
        :show-select="well.meta.showSelect"
        :options.sync="well.options"
        :item-class="well.meta.itemClass"
        :class="{ 'v-data-table--denser': isDesktop }"
        @click:row="onRowClick"
        @item-selected="toggleRowSelect"
        @toggle-select-all="toggleSelectAll"
        @update:options="updateList"
      >
        <template v-slot:loading>
          <div class="py-4" v-text="$trans('Loading items...')"></div>
        </template>

        <template v-slot:no-data>
          <div class="py-4" v-text="$trans('No data available')"></div>
        </template>

        <template v-for="header in well.headers" v-slot:[`header.${header.value}`]>
          <span
            :key="header.value"
            :title="$trans(header.text)"
            v-text="$trans(header.text)"
          ></span>
        </template>

        <template v-slot:item.well_name="{ item }">
          <app-can-view code="report_viewer">
            <router-link
              :to="{
                name: 'reports.well',
                query: {
                  wellId: item.id,
                  noPageNum: true,
                },
              }"
            >
              <div
                :title="item.well_name"
                class="text-no-wrap text-truncate"
                style="width: 100px;"
                v-text="item.well_name"
              ></div>
            </router-link>
            <template v-slot:alt>
              <div
                :title="item.well_name"
                class="text-no-wrap text-truncate"
                style="width: 100px;"
                v-text="item.well_name"
              ></div>
            </template>
          </app-can-view>
        </template>

        <template v-slot:item.basin="{ item }">
          <div
            :title="item.basin"
            class="text-no-wrap text-truncate"
            style="width: 80px;"
            v-text="item.basin"
          ></div>
        </template>

        <template v-slot:item.field_name="{ item }">
          <div
            :title="item.field_name"
            class="text-no-wrap text-truncate"
            style="width: 80px;"
            v-text="item.field_name"
          ></div>
        </template>

        <template v-slot:item.operator="{ item }">
          <div
            :title="item.operator"
            class="text-wrap"
            style="width: 100px;"
            v-text="item.operator"
          ></div>
        </template>

        <template v-slot:item.spud="{ item }">
          <span class="text-no-wrap" v-text="item.spud"></span>
        </template>

        <template v-slot:item.tvdss="{ item }">
          <div
            :title="item.tvdss"
            class="text-no-wrap text-right text-truncate"
            style="width: 50px;"
            v-text="item.tvdss"
          ></div>
        </template>

        <template v-slot:item.water_depth="{ item }">
          <div
            :title="item.water_depth"
            class="text-no-wrap text-right text-truncate"
            style="width: 40px;"
            v-text="item.water_depth"
          ></div>
        </template>

        <template v-slot:item.kelly_bushing="{ item }">
          <div
            :title="item.kelly_bushing"
            class="text-no-wrap text-right text-truncate"
            style="width: 40px;"
            v-text="item.kelly_bushing"
          ></div>
        </template>

        <template v-slot:item.post_drill_conclusion="{ item }">
          <div
            :title="item.post_drill_conclusion"
            class="text-wrap text-right"
            style="width: 80px;"
            v-text="item.post_drill_conclusion"
          ></div>
        </template>

        <template v-slot:item.well_type="{ item }">
          <div
            :title="item.well_type"
            class="text-no-wrap text-right text-truncate"
            style="width: 80px;"
            v-text="item.well_type"
          ></div>
        </template>
      </v-data-table>
    </v-card>
    <!-- Main Datatable -->

    <portal to="main:bottom">
      <map-area-container v-model="well.map.open">
        <map-area v-model="well.map" zoom-bar height="100%">
          <template v-slot:corner.top-right>
            <v-btn
              small class="ma-3"
              @click="goToWebGISUrl"
              v-text="$trans('Go to WebGIS')"
            ></v-btn>
          </template>
        </map-area>
      </map-area-container>
    </portal>
  </app-content>
</template>

<script>
import Well from '@/modules/Dashboard/resources/Well';
import Breakpoints from '@core/mixins/Breakpoints';
import BackToTop from '@core/mixins/BackToTop';

export default {
  layout: 'admin',

  meta: {
    permissions: [ 'data_dashboard' ],
  },

  mixins: [ BackToTop, Breakpoints ],

  data: vm => ({
    well: new Well({
      map: { id: 'well-map' },
      headers: [
        { text: 'Well', value: 'well_name' },
        { text: 'basin', value: 'basin' },
        { text: 'Field', value: 'field_name' },
        { text: 'Operator', value: 'operator' },
        { text: 'Spud Date', value: 'spud' },
        { text: 'TVDSS (m)', value: 'tvdss' },
        { text: 'Water Depth (m)', value: 'water_depth' },
        { text: 'KB (m)', value: 'kelly_bushing' },
        { text: 'Post Drill', value: 'post_drill_conclusion' },
        { text: 'Well Type', value: 'well_type' },
      ],
      options: {
        itemsPerPage: 50,
        sortBy: [ vm.$route.query.order_by || 'well_name' ],
        rowsPerPage: [ 5, 10, 15, 20, 50, 100 ],
      },
      meta: {
        dense: vm.isDesktop,
        showSelect: true,
        singleSelect: false,
        mobileBreakpoint: 420,
        itemClass: item => (item.active ? 'red lighten-5' : null),
        supportedQuery: [
          'q',
          'page',
          'order_by',
          'projects_list',
          { search: 'q' },
          { sortBy: 'order_by' },
          { itemsPerPage: 'page_size' },
        ],
      },
    }),
  }),

  methods: {
    changedDataSource ({ query }) {
      this.well.setQueryString({ ...query, ...this.well.options }).list();
      this.well.map.reInitialize();
    },

    updateList (options) {
      this.well.setQueryString(options).list();
      this.well.map.clearOverlayLayers();
    },

    setSearch (e) {
      this.well.setSearch(e.target.value).list();
      this.well.map.clearOverlayLayers();
    },

    clearSearch () {
      this.well.clearSearch().list();
      this.well.map.clearOverlayLayers();
      this.well.map.fitToBackgroundLayer();
    },

    unselectAll () {
      this.well.unselectAll().clearAllSelectedMapMarkers();
    },

    onRowClick (item) {
      this.well.toggle(item).toggleRowSelect(item, this.well.isSelected(item));
    },

    toggleRowSelect ({ item, value }) {
      this.well.toggleRowSelect(item, value);
    },

    toggleSelectAll ({ items, value }) {
      this.well.toggleSelectAll(items, value);
    },

    exportSelectedItems () {
      this.well.exportSelectedItems();
    },

    goToWebGISUrl () {
      this.$router.push(this.well.getWebGISUrl());
    },
  },
};
</script>
