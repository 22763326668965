<template>
  <v-card v-if="loading">
    <v-skeleton-loader
      width="100%" height="200"
      type="image" class="mb-4"
    ></v-skeleton-loader>
  </v-card>
  <v-card v-else-if="hasWellInfo">
    <v-card-actions>
      <!-- Related Wells Selection -->
      <v-autocomplete
        v-if="hasRelatedWells"
        v-model="relatedWells.selected"
        :items="relatedWells.items"
        :label="$trans('Related wells')"
        hide-details return-object
        outlined dense
        item-value="id"
        item-text="attributes.well_name"
        @change="changeWell"
      >
        <template v-slot:append>
          <v-chip small v-text="relatedWells.total"></v-chip>
        </template>
        <template v-slot:prepend-inner>
          <v-icon x-small color="green" class="mt-2">mdi-circle</v-icon>
        </template>
      </v-autocomplete>
      <!-- Related Wells Selection -->
    </v-card-actions>
    <v-card-title class="flex-no-wrap">
      <h2
        :title="wellInfo.well_name"
        class="text-truncate"
        v-text="wellInfo.well_name"
      ></h2>
      <v-chip
        small
        outlined
        color="accent"
        class="ml-3"
        v-text="wellInfo.well_type"
      ></v-chip>
      <v-spacer></v-spacer>
      <v-btn
        :to="{
          name: 'reports.well',
          query: { wellId: item.id },
        }"
        small
        v-text="$trans('View report')"
      ></v-btn>
    </v-card-title>
    <v-tabs v-model="well.meta.currentTab" center-active>
      <v-tab v-for="(tab, i) in well.meta.tabs" :key="i" v-text="tab.text"></v-tab>
    </v-tabs>
    <v-tabs-items v-model="well.meta.currentTab">
      <v-tab-item v-for="(tab, i) in well.meta.tabs" :key="i">
        <component
          :is="tab.component"
          :item.sync="wellInfo"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Well from '@/modules/Dashboard/resources/Well';
import { isEmpty } from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'WellInformationDetailCard',

  props: {
    item: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: vm => ({
    well: new Well({
      data: vm.item,
      meta: {
        currentTab: 0,
        tabs: [
          {
            text: 'Location',
            component: () => import('@/modules/Search/components/WellInformation/DetailLocation.vue'),
          },
          {
            text: 'Depth Info',
            component: () => import('@/modules/Search/components/WellInformation/DetailDepthInfo.vue'),
          },
          {
            text: 'Operator',
            component: () => import('@/modules/Search/components/WellInformation/DetailOperator.vue'),
          },
          {
            text: 'Spud Date',
            component: () => import('@/modules/Search/components/WellInformation/DetailSpudDate.vue'),
          },
          {
            text: 'Post Drill Conclusion',
            component: () => import('@/modules/Search/components/WellInformation/DetailPostDrill.vue'),
          },
        ],
      },
    }),
  }),

  computed: {
    hasWellInfo () {
      return this.item.id !== undefined;
    },

    wellInfo () {
      return this.relatedWells.selected;
    },

    relatedWells () {
      return {
        total: this.item?.relatedWells?.total,
        selected: this.item?.relatedWells?.selected,
        items: this.item?.relatedWells?.items,
      };
    },

    hasRelatedWells () {
      const wells = this.relatedWells.items.filter(well => well.id !== this.wellInfo.id);
      return !isEmpty(wells);
    },
  },

  methods: {
    ...mapActions({
      setSelectedWell: 'search/setSelectedWell',
    }),

    changeWell (well) {
      this.item.relatedWells.selected = well;
      this.setSelectedWell(well);
    },
  },
};
</script>
