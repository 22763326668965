<template>
  <div><router-view></router-view></div>
</template>

<script>
export default {
  layout: 'admin',

  routename: 'reports.ocr',
};
</script>
