<template>
  <v-tooltip
    v-model="tooltip"
    :close-delay="closeDelay"
    right transition="slide-x-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="disableOCR ? undefined : 'primary'"
        :disabled="disabled"
        :loading="loading"
        min-width="32" min-height="32"
        width="32" height="32"
        class="ma-1"
        v-bind="attrs"
        v-on="on"
        @click="onClick"
      >
        <template v-slot:loader>
          <v-icon small>mdi-loading mdi-spin</v-icon>
        </template>
        <v-icon small>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{
      disableOCR ? $trans('Fetch OCR Results') : $trans_choice(
        'There is {count} OCR results in page {page}',
        total, {
          count: total,
          page: page,
        }
      )
    }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'BboxOcrBtn',

  props: {
    value: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: 'mdi-translate',
    },

    total: {
      type: [ String, Number ],
      default: 0,
    },

    page: {
      type: [ String, Number ],
      default: 0,
    },
  },

  data: () => ({
    closeDelay: 0,
    tooltip: false,
    timeout: null,
  }),

  computed: {
    disableOCR () {
      return this.value;
    },
  },

  watch: {
    total () {
      this.showTooltip();
    },
  },

  methods: {
    onClick () {
      this.$emit('click');
    },

    showTooltip (delay = 5000) {
      this.tooltip = true;
      this.closeDelay = delay;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.hideTooltip(), this.closeDelay);
    },

    hideTooltip () {
      this.tooltip = false;
      this.closeDelay = 0;
    },
  },
};
</script>
