<template>
  <app-content>
    <meta-tag :title="set.data.title"></meta-tag>
    <app-back-btn
      :to="{
        name: 'sets.show',
        params: { id: $id },
      }"
    ></app-back-btn>

    <v-row>
      <v-col lg="9" cols="12" class="mx-auto">
        <v-row>
          <v-col
            cols="12"
            lg="7"
            order="2"
            order-lg="1"
          >
            <app-header>
              <template v-slot:page.title>
                <h2 class="mb-4" v-text="$trans('Confirmation')"></h2>
              </template>
            </app-header>

            <p
              class="body-1"
              v-html="$trans('confirmation.check', {
                name: $app.appShortName,
              })"
            ></p>

            <v-simple-table class="va-top">
              <tbody>
                <tr>
                  <td class="font-weight-bold">
                    <app-text-loader
                      :loading="set.loading"
                      class="mb-4"
                      width="120px" height="20px"
                    >
                      <span v-text="$trans('Set')"></span>
                    </app-text-loader>
                  </td>
                  <td>
                    <app-text-loader
                      :loading="set.loading"
                      class="mb-4"
                      width="120px" height="20px"
                    >
                      <span v-text="set.data.id"></span>
                    </app-text-loader>
                  </td>
                </tr>
                <template v-for="(details, k) in set.meta.details">
                  <tr v-for="(detail, i) in details" :key="`${k}-${i}`">
                    <td class="font-weight-bold">
                      <app-text-loader
                        :loading="set.loading"
                        class="mb-4"
                        width="120px" height="20px"
                      >
                        <span v-text="$trans_choice(detail.key, detail.count)"></span>
                      </app-text-loader>
                    </td>
                    <td v-if="detail.dot">
                      <app-text-loader
                        :loading="set.loading"
                        class="mb-4"
                        width="200px" height="20px"
                      >
                        <v-layout class="align-center">
                          <v-avatar :color="detail.color" left size="10"></v-avatar>
                          <div class="ml-2" v-html="detail.text"></div>
                        </v-layout>
                      </app-text-loader>
                    </td>
                    <td v-else-if="detail.download">
                      <div class="mb-4">
                        <app-text-loader
                          v-for="(download, j) in detail.options" :key="j"
                          :loading="set.loading"
                          width="200px" height="36px"
                        >
                          <report-download-link-prompt
                            :item="download"
                            icon="mdi-email-outline"
                            @click="onDownloadClick($event, detail)"
                          ></report-download-link-prompt>
                        </app-text-loader>
                      </div>
                    </td>
                    <td v-else>
                      <app-text-loader
                        :loading="set.loading"
                        class="mb-4"
                        width="200px" height="20px"
                      >
                        <span v-html="detail.value"></span>
                      </app-text-loader>
                    </td>
                  </tr>
                </template>
                <tr class="align-start justify-start">
                  <td class="font-weight-bold align-start justify-start">
                    <app-text-loader
                      :loading="set.loading"
                      class="mb-4"
                      width="120px" height="20px"
                    >
                      <span v-text="$trans('Files')"></span>
                    </app-text-loader>
                  </td>
                  <td>
                    <app-text-loader
                      :loading="set.loading"
                      :count="3"
                      class="mb-4"
                      width="100%" height="20px"
                    >
                      <template v-for="(file, i) in set.data.files">
                        <div
                          :key="i"
                          :title="`${file.name} (${file.size})`"
                          class="mb-2 break-word"
                        >
                          <a
                            :href="file.raw_file_link"
                            target="_blank"
                            class="td-underline"
                            v-text="`${file.name}`"
                          ></a>
                        </div>
                      </template>
                    </app-text-loader>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col
            lg="5"
            sm="12"
            cols="auto"
            order="1"
            order-lg="2" class="ml-auto"
          >
            <app-header>
              <template v-slot:page.title>
                <h2 class="mb-4" v-text="$trans('Approval')"></h2>
              </template>
            </app-header>

            <template v-if="set.isReviewed()">
              <alert-set-status-reviewed
                :color="set.data.statusText.color"
              >
                <span v-text="set.data.statusText.text"></span>
              </alert-set-status-reviewed>
            </template>

            <section class="section__group mb-4">
              <set-simple-table-grid :loading="set.loading">
                <template v-slot:title><span v-text="$trans('Priority')"></span></template>
                <div v-if="set.data.priority" v-html="set.data.priority.text"></div>
              </set-simple-table-grid>

              <set-simple-table-grid :loading="set.loading">
                <template v-slot:title><span v-text="$trans('Justification')"></span></template>
                <div v-html="set.data.justification"></div>
                <div
                  class="muted--text mt-5 mb-8"
                  v-html="$trans('Submitted for approval by {name} on {date}', {
                    name: set.data.added_by_full_name,
                    date: set.data.date,
                  })"
                ></div>
              </set-simple-table-grid>
            </section>

            <section v-if="set.isReviewed()" class="section__group mt-10 mb-4">
              <set-simple-table-grid :loading="set.loading">
                <template v-slot:title>
                  <v-icon left small>mdi-message-text-outline</v-icon>
                  <span v-text="$trans('Reviewer Remarks')"></span>
                </template>
                <div v-html="set.data.currentStatus.remarks"></div>
                <div
                  :title="set.data.currentStatus.date_added"
                  class="muted--text mt-3"
                  v-html="$trans('Reviewed by {name} on {date}', {
                    name: set.data.currentStatus.added_by_full_name,
                    date: set.data.currentStatus.date,
                  })"
                ></div>
              </set-simple-table-grid>
            </section>

            <template v-if="set.isPendingAuditApproval()">
              <v-card flat min-width="420">
                <div
                  class="mt-4 mb-3"
                  v-text="$trans('Approve or reject this Set.')"
                ></div>
                <file-audit-approval-batch-form
                  :items="set.fields"
                  @click:submitted="onSubmitted"
                ></file-audit-approval-batch-form>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import Set from '@/modules/File/resources/Set';
import IsPendingAuditApproval from '@/modules/File/middlewares/IsPendingAuditApproval';
import IsManager from '@/modules/File/middlewares/IsManager';
import multiguard from 'vue-router-multiguard';

export default {
  layout: 'admin',

  routename: 'sets.approval',

  beforeRouteEnter: multiguard([ IsManager, IsPendingAuditApproval ]),

  data: () => ({
    set: new Set,
  }),

  created () {
    this.set.findWithStatus(this.$id);
  },

  methods: {
    onSubmitted () {
      this.$router.push({
        name: 'sets.show',
        params: { id: this.$id },
      });
    },

    onDownloadClick (download, item) {
      item.downloadReport(download.type);
    },
  },
};
</script>
