<template>
  <v-timeline
    align-top
    dense
  >
    <v-timeline-item
      v-for="(item, i) in items" :key="i"
      :color="item.color"
      :icon="item.icon"
      small fill-dot
      class="mb-10"
    >
      <div class="body-1" v-text="`${item.title} ${item.text}`"></div>
      <div
        class="body-2 muted--text"
        v-text="$trans('by {name}', {
          name: item.added_by_full_name,
        })"
      ></div>
      <div class="body-2 muted--text" v-text="item.date"></div>
      <v-layout v-if="item.hasRemarks" class="mt-5">
        <template v-if="item.isSubmittedForApproval">
          <div>
            <div class="body-2 font-weight-bold" v-text="$trans('Justification')"></div>
            <div class="body-2" v-html="item.justification"></div>
          </div>
        </template>
        <template v-if="item.remarks">
          <div>
            <div class="body-2 font-weight-bold" v-text="$trans('Remarks')"></div>
            <div class="body-2" v-html="item.remarks"></div>
          </div>
        </template>
      </v-layout>
      <v-layout v-if="item.hasMetaInfo" class="mt-5">
        <v-alert
          :color="item.metaInfo.color"
          :icon="item.metaInfo.icon"
          dark
          prominent text
          border="left"
          class="pa-10"
        >
          <div class="text-h6 mb-3" v-text="$trans(item.metaInfo.title)"></div>
          <div
            v-for="(text, j) in item.metaInfo.text"
            :key="j"
            class="mb-2"
            v-html="text"
          ></div>
        </v-alert>
      </v-layout>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  name: 'FileAuditStatusLogsTimeline',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
