<template>
  <v-overlay color="grey darken-2" absolute dark>
    <v-card flat class="rounded white rounded-circle">
      <v-progress-circular
        :rotate="progress"
        :size="100"
        :width="10"
        :indeterminate="progress === 0"
        :value="progress"
        color="accent"
      >
        <span v-text="progressText"></span>
      </v-progress-circular>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: 'WordCloudMapLoadingState',

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    progress () {
      return ((this.value.amount / this.value.total) * 100 * 1.5) || 0;
    },

    progressText () {
      return `${this.value.amount}/${this.value.total}`;
    },
  },
};
</script>
