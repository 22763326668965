<template>
  <div class="mx-1">
    <v-icon>mdi-loading mdi-spin</v-icon>
  </div>
</template>

<script>
export default {
  name: 'MapAreaMapTabItemLoadingState',
};
</script>
