export const VIZ_ITEMS = [
  {
    text: 'Scatter Plot',
    value: 'scatterPlot',
    storeName: 'scatterplot',
    component: 'VizScatterPlotMapControl',
    permission: 'webgis_scatter_plot',
  },
  {
    text: 'Pie Chart',
    value: 'pieChart',
    storeName: 'piechart',
    component: 'VizPieChartMapControl',
    permission: 'webgis_pie_chart',
  },
  {
    text: 'Bar Graph',
    value: 'barGraph',
    storeName: 'bargraph',
    component: 'VizBarGraphMapControl',
    permission: 'webgis_bar_graph',
  },
];

export const DEFAULT_BARGRAPH_STYLE = { fillColor: 'rgba(88, 88, 88, 0.8)', opacity: 0.8, weight: 0 };
export const DEACTIVE_BARGRAPH_STYLE = { fillColor: 'rgba(88, 88, 88, 0.5)', opacity: 0.5, weight: 0 };
export const ACTIVE_BARGRAPH_STYLE = { fillColor: 'rgba(88, 88, 88, 0.8)', opacity: 0.8, weight: 0 };
export const GREEN_ACTIVE_BARGRAPH_STYLE = { fillColor: 'rgba(103, 209, 54, 0.8)', opacity: 0.5, weight: 0 };

export const DEFAULT_SCATTERPLOT_STYLE = { fillColor: 'rgba(88, 88, 88, 0.4)', opacity: 1, weight: 0 };
export const DEACTIVE_SCATTERPLOT_STYLE = { fillColor: 'rgba(88, 88, 88, 0.4)', opacity: 0.5, weight: 0 };
export const ACTIVE_SCATTERPLOT_STYLE = { fillColor: 'rgba(88, 88, 88, 1)', opacity: 1, weight: 0 };
export const GREEN_ACTIVE_SCATTERPLOT_STYLE = { fillColor: '#67d136', opacity: 1, weight: 0 };

export const DEACTIVE_PIECHART_STYLE = { opacity: 0.5 };
export const ACTIVE_PIECHART_STYLE = { opacity: 1 };

export const GLOBAL_BARGRAPH_SCALE = {
  min: 10,
  max: 30,
};

export default Object.freeze({
  VIZ_ITEMS,

  DEFAULT_BARGRAPH_STYLE,
  DEACTIVE_BARGRAPH_STYLE,
  ACTIVE_BARGRAPH_STYLE,
  GREEN_ACTIVE_BARGRAPH_STYLE,

  DEFAULT_SCATTERPLOT_STYLE,
  DEACTIVE_SCATTERPLOT_STYLE,
  ACTIVE_SCATTERPLOT_STYLE,
  GREEN_ACTIVE_SCATTERPLOT_STYLE,

  GLOBAL_BARGRAPH_SCALE,
});
