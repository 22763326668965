<template>
  <app-content>
    <v-alert v-if="datalake.hasErrors()" outlined text type="error" dismissible>
      <span
        class="login-error-message"
        v-text="datalake.getErrors('message', 'Something went wrong')"
      ></span>
    </v-alert>
    <meta-tag :title="$trans('Report Viewer')"></meta-tag>
    <app-back-btn></app-back-btn>

    <!-- Loading State -->
    <v-row v-if="datalake.loading" justify-center class="fill-height">
      <v-col cols="12" lg="7">
        <v-layout fill-height column>
          <div class="flex-shrink-0">
            <app-header-loading-state></app-header-loading-state>
          </div>
          <div class="flex-grow-1">
            <panzoom-pdf-previewer-loading-state></panzoom-pdf-previewer-loading-state>
          </div>
        </v-layout>
      </v-col>
      <v-col cols="12" lg="5">
        <report-viewer-meta-info-loading-state></report-viewer-meta-info-loading-state>
      </v-col>
    </v-row>
    <!-- Loading State -->

    <!-- Ready State -->
    <v-row v-if="datalake.isReady()">
      <v-col cols="12" lg="12">
        <v-layout fill-height column>
          <div class="flex-shrink-0">
            <!-- App header -->
            <app-header class="ov-x-auto">
              <template v-slot:page>
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" lg="10">
                    <div class="mb-3">
                      <p class="overline font-weight-bold mb-0" v-text="$trans('File name')"></p>
                      <v-layout row class="ma-0 flex-no-wrap">
                        <v-btn
                          :title="$trans('Copy report link to clipboard')"
                          small icon
                          @click="copyReportLinkToClipboard"
                        >
                          <v-icon small>mdi-content-copy</v-icon>
                        </v-btn>
                        <p
                          :title="datalake.data.doc_name"
                          class="text-no-wrap text-truncate mb-0"
                          v-text="datalake.data.doc_name"
                        ></p>
                      </v-layout>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <div class="mb-3">
                      <p
                        class="overline text-no-wrap font-weight-bold mb-0"
                        v-text="$trans('File size')"
                      ></p>
                      <p class="ma-0 text-no-wrap">{{ datalake.data.file_size | toMb }}</p>
                    </div>
                  </v-col>
                </v-row>
              </template>
            </app-header>
            <!-- App header -->
          </div>

          <div class="flex-grow-1">
            <spreadsheet-previewer
              v-if="can('report_spreadsheet_viewer') && htmlFileCheck" :src-h-t-m-l="htmlURL"
              :tab-strip-array="tabStripArray"
              :current-page-table="currentPageTable" @click:download="downloadItem"
            ></spreadsheet-previewer>

            <!-- PDF Previewer -->
            <panzoom-pdf-previewer
              v-else
              :items="datalake.meta.pages"
              :page.sync="datalake.meta.currentPage"
              :total="datalake.data.total"
              :loading="datalake.loading"
              :searching="datalake.searching"
              :search-results="datalake.meta.searchResults"
              zoom-bar search-bar
              height="calc(100vh - 240px)"
              @enter:search="setSearch"
              @click:download="downloadItem"
              @intersected:item="onItemIntersect"
              @jump:item="onJumpToPage"
            >
              <template v-slot:loading>
                <div class="white--text">
                  <v-icon small left color="white">mdi-loading mdi-spin</v-icon>
                  <span v-text="$trans('Loading images')"></span>
                </div>
              </template>

              <template v-slot:item="{ item, page, isLoading, onImageLoad }">
                <panzoom-pdf-previewer-page-item :loading="isLoading">
                  <panzoom-pdf-previewer-page-item-number
                    :page="page"
                  ></panzoom-pdf-previewer-page-item-number>
                  <bbox-container
                    :items="item.ocrData"
                    :disabled="datalake.meta.disableOCR"
                  >
                    <img
                      :data-src="item.url"
                      :src="item.url"
                      :class="{ 'active': page == $route.query.page_num }"
                      width="100%" height="auto"
                      class="d-block"
                      @load="onImageLoad"
                    >
                    <template v-slot:bbox.content="{ item: image }">
                      <div v-html="nl2br(image.text)"></div>
                    </template>
                    <template v-slot:bbox.actions="{ item: image, highlightedText }">
                      <v-spacer></v-spacer>
                      <app-mode name="ELASTICDOCS">
                        <v-btn
                          small
                          color="accent"
                          class="ml-2"
                          @click.stop="showOriginalItem(image)"
                        >
                          <v-icon left small>mdi-translate</v-icon>
                          <span v-text="$trans_choice('Show original', image.original)"></span>
                        </v-btn>
                      </app-mode>
                      <v-btn
                        small
                        color="accent"
                        class="ml-2"
                        @click.stop="copyItemTextToClipboard(image, highlightedText)"
                      >
                        <v-icon left small>mdi-content-copy</v-icon>
                        <span v-text="$trans('Copy')"></span>
                      </v-btn>
                    </template>
                  </bbox-container>
                </panzoom-pdf-previewer-page-item>
              </template>

              <template
                v-slot:search.item="{
                  item,
                  search,
                  highlight,
                  itemTextKey,
                }"
              >
                <v-list-item-content>
                  <div>
                    <v-chip
                      small label
                      class="mr-3"
                      v-text="$trans('Page {page}', {
                        page: item.page_num,
                      })"
                    ></v-chip>
                    <span class="caption" v-html="highlight(item[itemTextKey], search)"></span>
                  </div>
                </v-list-item-content>
              </template>

              <template v-slot:thumbnail="{ item, page }">
                <bbox-ocr-indicator
                  v-model="datalake.meta.disableOCR"
                  :total="item.totalOCRData"
                  pulsate
                  left="0.5rem" top="0.5rem"
                >
                  <img
                    :data-src="item.url"
                    :src="item.url"
                    width="100%" height="auto"
                    class="d-block pa-2 pb-0 bordered"
                  >
                  <panzoom-pdf-previewer-page-item-number
                    :page="page"
                    class="text-center"
                  ></panzoom-pdf-previewer-page-item-number>
                </bbox-ocr-indicator>
              </template>

              <template v-slot:zoom-bar.append>
                <bbox-ocr-btn
                  v-model="datalake.meta.disableOCR"
                  :disabled="datalake.meta.ocr.disabled"
                  :loading="datalake.meta.ocr.loading"
                  :total="datalake.meta.ocr.totalInCurrentPage"
                  :page="datalake.meta.currentPage"
                  @click="toggleOCRDataFetching"
                ></bbox-ocr-btn>
              </template>
            </panzoom-pdf-previewer>
            <!-- PDF Previewer -->

          </div>

          <!-- <div v-else class="flex-grow-1">

          </div> -->
        </v-layout>
      </v-col>

      <v-col v-if="isMobile" cols="12">
        <portal-target name="sidebar:right.activator"></portal-target>
      </v-col>
    </v-row>
    <!-- Ready State -->

    <!-- Empty State -->
    <report-viewer-empty-state v-if="!datalake.loading && !datalake.isReady()">
      <v-btn @click="back" v-text="$trans('Back to Previous Page')"></v-btn>
    </report-viewer-empty-state>
    <!-- Empty State -->

    <app-right-sidebar-area v-if="datalake.isReady()" v-model="datalake.loaded" width="600px">
      <app-stickybar
        v-if="datalake.loaded"
        divider column
        class="mb-4 px-1"
      >
        <v-tabs v-model="datalake.meta.selectedTab">
          <template v-for="(tab, i) in tabs">
            <app-can-view :key="i" :code="tab.permission">
              <v-tab>
                {{ $trans(tab.name) }}
                <v-chip
                  v-if="tab.chip" small
                  class="ml-3"
                  v-text="tab.chip"
                ></v-chip>
              </v-tab>
            </app-can-view>
          </template>
        </v-tabs>
      </app-stickybar>
      <v-tabs-items v-model="datalake.meta.selectedTab" class="px-1">
        <template v-for="(tab, t) in tabs">
          <app-can-view :key="t" :code="tab.permission">
            <v-tab-item :key="t" :eager="tab.eager">
              <component
                :is="tab.component"
                :key="`${t}-${datalake.meta.selectedTab}`"
                :item="datalake.meta"
                :tab-index="t"
                :tab.sync="tab"
                @click:goToTab="goToTab"
              ></component>
            </v-tab-item>
          </app-can-view>
        </template>
      </v-tabs-items>
    </app-right-sidebar-area>
    <router-view></router-view>
  </app-content>
</template>

<script>
import Datalake from '@/modules/Dashboard/resources/Datalake';
import Breakpoints from '@core/mixins/Breakpoints';
import BackToPreviousPage from '@core/mixins/BackToPreviousPage';
import PrettyBytes from '@core/filters/PrettyBytes';
import NewLineToBreakHTML from '@core/filters/NewLineToBreakHTML';
import CheckUserPermission from '@core/mixins/CheckUserPermission';

export default {
  layout: 'admin',

  meta: {
    permissions: [ 'report_viewer' ],
  },

  beforeRouteLeave (to, from, next) {
    this.datalake.cancelAllRequests();

    next();
  },

  mixins: [
    BackToPreviousPage,
    Breakpoints,
    NewLineToBreakHTML,
    PrettyBytes,
    CheckUserPermission,
  ],

  data: vm => ({
    backUrl: { name: 'datalake' },
    datalake: new Datalake({
      loading: true,
      meta: {
        pages: [],
        publication: {},
        ocr: {
          items: [],
          disabled: false,
          loading: false,
          totalInCurrentPage: 0,
        },
        disableOCR: true,
        searchResults: [],
        fetchedOCRPages: [],
        relatedWells: [],
        relatedDocuments: [],
        totalSearchResults: 50,
        isScrolling: false,
        currentPage: vm.$route.query.page_num || 1,
        selectedTab: 0,
        tabs: [
          {
            name: 'Summary',
            active: true,
            chip: false,
            eager: false,
            permission: 'report_summary',
            component: () => import('@/modules/Report/components/MetaInfo/ReportViewerMetaInfoSummary'),
          },
          {
            name: 'Lithology',
            active: true,
            chip: false,
            eager: false,
            permission: 'report_lithology',
            component: () => import('@/modules/Report/components/MetaInfo/ReportViewerMetaInfoLithology'),
          },
          {
            name: 'Images',
            active: true,
            chip: false,
            eager: true,
            permission: 'report_images',
            component: () => import('@/modules/Report/components/MetaInfo/ReportViewerMetaInfoImages'),
          },
        ],
      },
    }),
    htmlURL: '',
    htmlFileCheck: false,
    tabStripArray: [],
    currentPageTable: '<table></table>',
  }),

  computed: {
    tabs () {
      return this.datalake.meta.tabs.filter(i => i.active);
    },
  },

  watch: {
    '$route.params.slug': async function ($slug) {
      await this.datalake.find($slug);

      this.$nextTick(async () => {
        await this.generateHTMLURL($slug);
        await this.checkHTMLSource($slug);
      });
    },
  },

  async created () {
    await this.datalake.find(this.$slug);
    await this.checkHTMLSource();
  },

  methods: {
    setSearch (e) {
      this.datalake.searchForKeyword(e);
    },

    downloadItem () {
      this.datalake.downloadItem();
    },

    toggleOCRDataFetching () {
      this.datalake.toggleOCRState().fetchCurrentPageOCRData();
    },

    onItemIntersect (item) {
      this.datalake.setCurrentPage(item);
      this.datalake.fetchItemOCRData(item);
    },

    onJumpToPage (page) {
      this.datalake.setCurrentPage({ page });
    },

    showOriginalItem (item) {
      this.datalake.setBboxCardText(item);
    },

    copyItemTextToClipboard (item, highlightedText) {
      this.datalake
        .copyToClipboard(highlightedText || item.text)
        .notify(this.$trans('Copied to clipboard'));
    },

    copyReportLinkToClipboard () {
      this.datalake.copyReportLinkToClipboard();
    },

    goToTab (event) {
      if (this.can('report_spreadsheet_viewer') && this.htmlFileCheck) {
        const storage = this.datalake.meta.pages[0].url.split('deployed')[0];
        this.htmlURL = `${storage}$web/${this.$slug}/html/page_${event.attributes.page_num}.html`;
        this.datalake.setQueryString({
          ...this.datalake.getQueryString(),
          page_num: event.attributes.page_num,
        });
      }
    },

    async checkHTMLSource (slug = null) {
      const params = this.datalake.getQueryString();
      const currentPage = params.page_num ? params.page_num : '1';
      const storage = this.datalake.meta.pages?.[0]?.url?.split('deployed')[0];
      this.htmlURL = storage ? `${storage}$web/${slug ?? this.$slug}/html/page_${currentPage}.html` : null;

      this.htmlFileCheck = false;
      try {
        this.htmlFileCheck = await this.datalake
          .checkHTMLSource(this.htmlURL, storage, slug ?? this.$slug);
      } catch (e) {
        this.htmlFileCheck = false;
      }
    },

    async generateHTMLURL (slug = null) {
      if (this.can('report_spreadsheet_viewer') && this.htmlFileCheck) {
        const params = this.datalake.getQueryString();
        const currentPage = params.page_num ? params.page_num : '1';
        const storage = this.datalake.meta.pages[0].url.split('deployed')[0];
        this.htmlURL = `${storage}$web/${slug ?? this.$slug}/html/page_${currentPage}.html`;
      }
    },
  },
};
</script>
