<template>
  <app-content>
    <meta-tag :title="set.data.title"></meta-tag>
    <app-back-btn :to="{ name: 'sets.index' }"></app-back-btn>

    <v-row>
      <v-col lg="9" cols="12" class="mx-auto">
        <v-row>
          <v-col
            cols="12"
            lg="7"
            order="2"
            order-lg="1"
          >
            <app-header>
              <template v-slot:page.title>
                <h2 class="mb-4" v-text="$trans('Confirmation')"></h2>
              </template>
            </app-header>

            <p
              class="body-1"
              v-html="$trans('confirmation.check', {
                name: $app.appShortName,
              })"
            ></p>

            <v-simple-table class="va-top">
              <tbody>
                <tr>
                  <td class="font-weight-bold">
                    <app-text-loader
                      :loading="set.loading"
                      class="mb-4"
                      width="120px" height="20px"
                    >
                      <span v-text="$trans('Set')"></span>
                    </app-text-loader>
                  </td>
                  <td>
                    <app-text-loader
                      :loading="set.loading"
                      class="mb-4"
                      width="120px" height="20px"
                    >
                      <span v-text="set.data.id"></span>
                    </app-text-loader>
                  </td>
                </tr>
                <template v-for="(details, k) in set.meta.details">
                  <tr v-for="(detail, i) in details" :key="`${k}-${i}`">
                    <td v-if="detail.download" class="d-none"></td>
                    <td v-else class="font-weight-bold">
                      <app-text-loader
                        :loading="set.loading"
                        class="mb-4"
                        width="120px" height="20px"
                      >
                        <span v-text="$trans(detail.key)"></span>
                      </app-text-loader>
                    </td>
                    <td v-if="detail.dot">
                      <app-text-loader
                        :loading="set.loading"
                        class="mb-4"
                        width="200px" height="20px"
                      >
                        <v-layout class="align-center">
                          <v-avatar :color="detail.color" left size="10"></v-avatar>
                          <div class="ml-2" v-html="detail.text"></div>
                        </v-layout>
                      </app-text-loader>
                    </td>
                    <td v-else-if="detail.download" class="d-none"></td>
                    <td v-else>
                      <app-text-loader
                        :loading="set.loading"
                        class="mb-4"
                        width="200px" height="20px"
                      >
                        <span v-html="detail.value"></span>
                      </app-text-loader>
                    </td>
                  </tr>
                </template>
                <tr class="align-start justify-start">
                  <td class="font-weight-bold align-start justify-start">
                    <app-text-loader
                      :loading="set.loading"
                      class="mb-4"
                      width="120px" height="20px"
                    >
                      <span v-text="$trans('Files')"></span>
                    </app-text-loader>
                  </td>
                  <td>
                    <app-text-loader
                      :loading="set.loading"
                      :count="3"
                      class="mb-4"
                      width="100%" height="20px"
                    >
                      <template v-for="(file, i) in set.data.files">
                        <div
                          :key="i"
                          :title="`${file.name} (${file.size})`"
                          class="mb-2 break-word"
                        >
                          <a
                            :href="file.raw_file_link"
                            target="_blank"
                            class="td-underline d-block text-no-wrap text-truncate"
                            style="max-width: 100%;"
                            v-text="`${file.name}`"
                          ></a>&nbsp;
                        </div>
                      </template>
                    </app-text-loader>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col
            lg="5"
            sm="12"
            cols="auto"
            order="1"
            order-lg="2" class="ml-auto"
          >
            <app-header>
              <template v-slot:page.title>
                <h2 class="mb-4" v-text="$trans('Submission')"></h2>
              </template>
            </app-header>

            <v-card flat min-width="420">
              <validation-observer ref="observer" v-slot="{ handleSubmit }" slim>
                <v-form ref="form" @submit.prevent="handleSubmit(submit)">
                  <validation-provider
                    v-slot="{ errors }" vid="priority"
                    :name="$trans('Priority')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="set.fields.priority"
                      :error-messages="errors"
                      :items="set.meta.priorities.items"
                      :label="$trans('Priority')"
                      outlined filled
                      prepend-inner-icon="mdi-priority-high"
                      name="priority"
                      class="is-required"
                    >
                    </v-autocomplete>
                  </validation-provider>

                  <v-layout>
                    <v-spacer></v-spacer>
                    <app-popover icon="mdi-help-circle">
                      <!-- eslint-disable-next-line max-len -->
                      <p class="caption mb-0" v-text="$trans('Your justification must state clearly the reason(s) why the uploaded set of files should be approved.')"></p>
                    </app-popover>
                  </v-layout>
                  <validation-provider
                    v-slot="{ errors }" vid="justification"
                    :name="$trans('justification')"
                    rules="required|min:200"
                  >
                    <v-textarea
                      v-model="set.fields.justification"
                      :error-messages="errors"
                      :label="$trans('Justification')"
                      counter
                      auto-grow
                      outlined filled
                      prepend-inner-icon="mdi-message-text-outline"
                      name="justification"
                      class="is-required"
                    ></v-textarea>
                  </validation-provider>

                  <v-layout class="mt-8">
                    <v-spacer></v-spacer>
                    <v-btn
                      large block
                      type="submit"
                      color="primary"
                      v-text="$trans('Submit for Approval')"
                    ></v-btn>
                  </v-layout>
                </v-form>
              </validation-observer>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import Set from '@/modules/File/resources/Set';
import IsNotSubmittedForApproval from '@/modules/File/middlewares/IsNotSubmittedForApproval';

export default {
  layout: 'admin',

  routename: 'sets.submission',

  beforeRouteEnter: IsNotSubmittedForApproval,

  data: () => ({
    set: new Set,
  }),

  created () {
    this.set.find(this.$id);
  },

  methods: {
    submit () {
      this.set.submitForApproval(this.$id);
    },
  },
};
</script>
