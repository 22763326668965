<template>
  <div class="mt-10">
    <h1 v-html="$trans('Invoices')"></h1>
    <template v-if="invoice.isEmpty()">
      <billing-invoices-tab-item-empty-state></billing-invoices-tab-item-empty-state>
    </template>
    <v-data-table
      v-else
      :headers="invoice.headers"
      :items="invoice.items"
    ></v-data-table>
  </div>
</template>

<script>
import Invoice from '@/modules/Billing/resources/Invoice';

export default {
  name: 'BillingInvoicesTabItem',

  data: () => ({
    invoice: new Invoice,
  }),

  created () {
    // this.invoice.list();
  },
};
</script>
