import { GET_PRE_AUDIT_REPORT_URL, GET_APPROVAL_REPORT_URL } from '@/modules/File/api/uploader';
import { UPLOADED_SUCCESSFULLY, PENDING_FOR_AUDIT_APPROVAL } from '@/modules/File/config/phases';

export const TYPE_PRE_AUDIT_REPORT = 'pre-audit';
export const TYPE_FOR_APPROVAL_REPORT = 'approval';

export default {
  items: [
    {
      key: UPLOADED_SUCCESSFULLY,
      icon: 'mdi-download',
      text: 'Pre-Audit Report',
      type: TYPE_PRE_AUDIT_REPORT,
      href: id => GET_PRE_AUDIT_REPORT_URL(id),
    },
    {
      key: PENDING_FOR_AUDIT_APPROVAL,
      icon: 'mdi-download',
      text: 'For Approval Report',
      type: TYPE_FOR_APPROVAL_REPORT,
      href: id => GET_APPROVAL_REPORT_URL(id),
    },
  ],
};
