import Manifest from '@core/manifests/Manifest';
import { groupBy } from 'lodash';

class IconfileManifest extends Manifest {
  constructor (attributes = {}) {
    super(attributes);

    this.makeContext();
    this.generateItems();
  }

  makeContext () {
    this.requireCoreFile = require.context(
      // The relative path of the routes folder
      '@core',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base names
      /(config)\/(iconfile)\.js$/,
    );

    this.requireFile = require.context(
      // The relative path of the routes folder
      '@/modules',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base names
      /(config)\/(iconfile)\.js$/,
    );

    this.iconfiles = this.unionBy(
      this.requireCoreFile
        .keys().sort()
        .map(item => this.makeIconsfile(this.requireCoreFile(item))).flat(),
      this.requireFile
        .keys().sort()
        .map(item => this.makeIconsfile(this.requireFile(item))).flat(),
      'icon',
    );
  }

  unionBy (arr1, arr2, key = 'icon') {
    const items = groupBy([ ...arr1, ...arr2 ], key);
    return Object.keys(items).map(item => ({
      icon: item,
      files: items[item].map(i => i.files).flat(),
    }));
  }

  makeIconsfile (item) {
    return Object.keys(item?.default ?? item).map(i => ({
      icon: i,
      files: (item?.default ?? item)[i],
    }));
  }

  generateItems () {
    this.items = this.iconfiles.map(
      i => i.files.flat().map(f => ({ key: f, value: i.icon })),
    ).flat();
  }

  getItems () {
    return this.items;
  }
}

export default new IconfileManifest;
