<template>
  <app-content>
    <meta-tag :title="$trans('Verifying Account')"></meta-tag>

    <v-row class="mt-10">
      <v-col
        cols="12"
        lg="4" md="8"
        class="mx-auto text-center"
      >
        <div class="text-center mx-auto mt-5 mb-15">
          <app-brand width="180" class="mx-auto"></app-brand>
        </div>
        <h1 class="text-center mb-2" v-text="$trans('Verify Account')"></h1>

        <app-alert></app-alert>

        <p v-html="$trans('auth.verify')"></p>
        <input v-model="auth.verification.verify_code" type="hidden">
        <v-btn
          :disabled="auth.loading"
          x-large
          color="primary"
          class="my-5"
          @click="verify"
        ><span v-text="$trans('Verify Account')"></span></v-btn>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import Auth from '@/modules/Auth/resources/Auth';

export default {
  layout: 'landing',

  data: () => ({
    auth: new Auth,
  }),

  mounted () {
    this.verify();
  },

  methods: {
    verify () {
      this.auth.verifyAccount();
    },
  },
};
</script>
