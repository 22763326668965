<template>
  <app-mode name="DATA_ATELIER">
    <div>
      <v-divider class="mx-4"></v-divider>
      <v-list-item
        exact
        @click="onClick"
      >
        <v-list-item-icon>
          <v-icon small color="secondary">mdi mdi-license</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            class="secondary--text"
            v-text="$trans('Take onboarding tour')"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </app-mode>
</template>

<script>
export default {
  name: 'TakeTourUserMenuItem',

  methods: {
    onClick () {
      this.$store.dispatch('tour/onboarding/show');
    },
  },
};
</script>
