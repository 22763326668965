export const state = {
  tableOCRItem: null,
  hasDrawnItems: false,
  selectionBounds: null,
  selectedWell: null,
};

export const getters = {
  tableOCRItem: state => state.tableOCRItem,
  hasDrawnItems: state => state.hasDrawnItems,
  selectionBounds: state => state.selectionBounds,
  selectedWell: state => state.selectedWell,
};

export const mutations = {
  SET_TABLE_OCR_ITEM (state, tableOCRItem) {
    state.tableOCRItem = tableOCRItem;
  },
  SET_HAS_DRAWN_ITEMS (state, hasDrawnItems) {
    state.hasDrawnItems = hasDrawnItems;
  },
  SET_SELECTION_BOUNDS (state, selectionBounds) {
    state.selectionBounds = selectionBounds;
  },
  SET_SELECTED_WELL (state, well) {
    state.selectedWell = well;
  },
};

export const actions = {
  setTableOCRItem ({ commit }, tableOCRItem) {
    commit('SET_TABLE_OCR_ITEM', tableOCRItem);
  },
  setHasDrawnItems ({ commit }, hasDrawnItems) {
    commit('SET_HAS_DRAWN_ITEMS', hasDrawnItems);
  },
  setSelectionBounds ({ commit }, selectionBounds) {
    commit('SET_SELECTION_BOUNDS', selectionBounds);
  },
  setWell ({ commit }, well) {
    commit('SET_SELECTED_WELL', well);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
