<template>
  <v-app-bar
    :clipped-left="sidebar.clipped"
    :hide-on-scroll="appbar.hideOnScroll"
    app
    clipped-right
    scroll-off-screen
    color="appbar"
    elevation="4"
    class="s-appbar"
    data-test="appbar"
  >
    <portal-target name="appbar:navicon" multiple>
      <shortkey-tooltip content="k">
        <v-app-bar-nav-icon
          v-shortkey.once="['ctrl', 'k']"
          color="dark"
          data-test="appbar-nav-icon"
          @shortkey="toggleSidebar"
          @click="toggleSidebar"
        ></v-app-bar-nav-icon>
      </shortkey-tooltip>
    </portal-target>

    <portal-target name="appbar:title" slim>
      <v-toolbar-title class="ml-3 font-weight-bold">{{ meta.title }}</v-toolbar-title>
    </portal-target>

    <portal-target name="appbar:subtitle" slim></portal-target>
    <portal-target name="appbar:left" multiple class="layout justify-start"></portal-target>

    <v-spacer></v-spacer>
    <portal-target name="appbar:center" multiple></portal-target>
    <v-spacer></v-spacer>

    <portal-target name="appbar:right" multiple></portal-target>

    <portal-target name="appbar:action" slim>
      <app-bar-user-menu></app-bar-user-menu>
    </portal-target>

    <portal-target name="appbar:append" multiple></portal-target>
  </v-app-bar>
</template>

<script>
import AppBarToggle from '@core/mixins/AppBarToggle';
import MetaTag from '@core/mixins/MetaTag';
import SidebarToggle from '@core/mixins/SidebarToggle';

export default {
  mixins: [ AppBarToggle, MetaTag, SidebarToggle ],
};
</script>
