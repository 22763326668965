<template>
  <v-breadcrumbs
    :divider="divider"
    :items="crumbs"
    class="pa-0 ma-0"
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :exact="isExact(item)"
      >
        <small v-text="item.text"></small>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { includes } from 'lodash';

export default {
  name: 'AppBreadcrumbs',

  props: {
    /**
     * The breadcrumb item divider.
     *
     * @type {string}
     */
    divider: {
      type: String,
      default: '/',
    },
  },

  computed: {
    ...mapGetters({
      /**
       * The array of crumbs from store.
       *
       * @type {array}
       */
      crumbs: 'breadcrumbs/crumbs',

      /**
       * The title of the page set by metatag.
       *
       * @return {string}
       */
      title: 'meta/title',
    }),
  },

  watch: {
    $route (route) {
      this.setBreadcrumbs({ crumbs: route, title: this.title });
    },

    title (title) {
      this.setBreadcrumbs({ crumbs: this.$route, title });
    },
  },

  methods: {
    ...mapActions({
      /**
       * Set the breadcrumb items value.
       *
       * @type  {function}
       * @param {object} route
       */
      setBreadcrumbs: 'breadcrumbs/set',
    }),

    isExact (item) {
      return !includes(item.meta.children, this.$route.name);
    },
  },
};
</script>
