import Vue from 'vue';

const layouts = {
  install (Vue) {
    const files = {};

    this.requireLayout(require.context(
      // The relative path of the components folder
      '@core',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base component filenames
      /(layouts).*\.vue$/,
    )).forEach(item => {
      files[item.name.toLowerCase()] = item.component.default || item.component;
    });

    this.requireLayout(require.context(
      // The relative path of the components folder
      '@',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base component filenames
      /(layouts).*\.vue$/,
    )).forEach(item => {
      files[item.name.toLowerCase()] = item.component.default || item.component;
    });

    Vue.options = Vue.util.mergeOptions(Vue.options, {
      created () {
        this.$options.layouts = Vue.observable(files);
      },
    });
  },

  requireLayout (r) {
    return r.keys().map(fileName => {
      const layoutConfig = r(fileName);

      const layoutName = layoutConfig.default.name || fileName
        .split('/')
        .pop()
        .replace(/\.[^/.]+$/, '')
        .replace(/^\.\/(.*)\.\w+$/, '$1');

      return {
        name: layoutName,
        component: layoutConfig.default || layoutConfig,
      };
    });
  },
};

Vue.use(layouts);
