<template>
  <component :is="storageInfoComponent" :id="'app-data-storage-info'"></component>
</template>

<script>
export default {
  name: 'DatalakeStorageInfo',

  computed: {
    storageInfoComponent () {
      return this.$modules.has('file')
        ? 'file-data-storage-info'
        : 'summary-storage-info';
    },
  },
};
</script>
