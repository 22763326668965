<template>
  <keep-alive></keep-alive>
</template>

<script>
export default {
  layout: 'web',

  created () {
    this.$router.push({ name: 'legal.privacy' });
  },
};
</script>
