<template>
  <div :class="`map-area__heatmap-legend map-area__heatmap-legend--${type}`"></div>
</template>

<script>
export default {
  name: 'MapAreaHeatmapLegend',

  props: {
    type: {
      type: String,
      default: 'marker',
    },
  },
};
</script>

<style lang="scss">
$blue: rgb(0, 0, 255);
$cyan: rgb(0, 255, 255);
$lime : rgb(0, 255, 0);
$yellow: rgb(255, 255, 0);
$orange: rgb(255, 165, 0);
$red: rgb(255, 0, 0);

$initialRed: rgb(255, 194, 194);
$endRed: rgb(255, 0, 0);

.map-area__heatmap-legend {
  height: 8px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &--marker {
    background: linear-gradient(to right, $blue, $cyan, $lime, $yellow, $orange, $red);
  }

  &--polygon {
    background: linear-gradient(to right, $initialRed, $endRed);
  }
}
</style>
