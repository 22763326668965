<template>
  <div class="mt-10">
    <!-- eslint-disable max-len -->
    <h1 :title="'Current Card'" class="mb-15" v-html="$trans('Current Card')"></h1>
    <template v-if="billing.loading">
      <v-layout class="my-8">
        <v-icon left small>mdi-loading mdi-spin</v-icon>
        <p class="muted--text mb-0" v-text="$trans('Please wait, fetching card details')"></p>
      </v-layout>
    </template>
    <template v-else>
      <template v-if="currentPaymentMethod.card">
        <p
          class="text-uppercase"
          v-text="$trans('{brand} ****{number}, {expM}/{expY}', {
            brand: currentPaymentMethod.card.brand,
            number: currentPaymentMethod.card.last4,
            expM: currentPaymentMethod.card.exp_month,
            expY: currentPaymentMethod.card.exp_year,
          })"
        >
        </p>
        <p v-text="$trans('Next payment will be automatically collected from this card.')"></p>
        <div
          class="mb-15"
          v-html="$trans('Next billing is due on {date} for {price}', {
            date: upcomingInvoice.nextBillingDate,
            title: upcomingInvoice.nextBillingDateFull,
            price: upcomingInvoice.unitPrice,
          })"
        ></div>
      </template>
      <template v-else>
        <p v-text="$trans('No credit card detail saved.')"></p>
        <p class="font-italic muted--text" v-text="$trans('Add one by clicking the button below.')"></p>
      </template>
    </template>

    <subscription-update-payment-method-dialog
      :disabled="billing.loading"
    ></subscription-update-payment-method-dialog>
  </div>
</template>

<script>
import Billing from '@/modules/Billing/resources/Billing';
import { mapGetters } from 'vuex';

export default {
  name: 'BillingPaymentTabItem',

  data: () => ({
    billing: new Billing,
  }),

  computed: mapGetters({
    currentPaymentMethod: 'billing/currentPaymentMethod',
    upcomingInvoice: 'billing/upcomingInvoice',
  }),

  async created () {
    await this.billing.listPaymentMethods();
  },
};
</script>
