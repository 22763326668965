<template>
  <workflow-content>
    <meta-tag :title="$trans('Post Mortem')"></meta-tag>

    <portal to="workflow:sidebar">
      <app-search-field
        v-model="postmortem.search"
        :loading="postmortem.searching"
        :label="$trans('Search a well')"
        :placeholder="$trans('Search a well')"
        solo class="mb-6"
        @click:clear="clearSearch"
        @enter:search="setSearch"
      ></app-search-field>

      <v-list
        dense nav
        two-line
        class="pa-0"
      >
        <v-list-item
          v-for="(item, i) in wells" :key="i"
          :class="{ 'grey': isActive(item) }"
          @click="setPage(item)"
        >
          <v-list-item-content>
            <strong v-text="item.text"></strong>
            <span v-text="item.subtext"></span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <menu-pagination
        :filtered="postmortem.search"
        :page="postmortem.pagination.page"
        :length="postmortem.pagination.pageCount"
        :total="postmortem.pagination.total"
        :pagination="postmortem.pagination"
        @update:current="onUpdatePage"
        @click:unfilter="onUnfilter"
      ></menu-pagination>
    </portal>
    <v-alert v-if="postmortem.hasErrors()" outlined text type="error" dismissible>
      <span
        class="login-error-message"
        v-text="postmortem.getErrors('message', 'Something went wrong')"
      ></span>
    </v-alert>

    <v-row v-if="postmortem.meta.well.well_name">
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-layout align-center justify-center class="mb-2">
              <v-btn icon class="mr-3" @click="toggleMenu">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
              <v-layout align-center>
                <h2 v-text="postmortem.meta.well.well_name"></h2>
                <v-chip
                  label outlined
                  color="accent"
                  class="ml-3"
                  v-text="$trans(postmortem.meta.well.well_type)"
                ></v-chip>
              </v-layout>
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-card v-for="(item, i) in postmortem.meta.currentTables" :key="i" class="mb-6">
              <component :is="item.component" :item="postmortem.meta.well"></component>
            </v-card>
          </v-col>
          <v-col cols="9">
            <v-row>
              <template v-if="!postmortem.loading">
                <template v-for="(items, i) in postmortem.meta.currentImages">
                  <v-col :key="i" cols="6">
                    <template v-for="(item, j) in items">
                      <image-detail-card
                        v-if="item.hasImages"
                        :key="j"
                        :title="item.text"
                        :item.sync="item"
                        class="mb-6"
                      ></image-detail-card>
                    </template>
                  </v-col>
                </template>
              </template>
              <template v-else>
                <template v-for="item in itemsCount">
                  <v-col :key="item" cols="6">
                    <template v-for="item in itemsCount">
                      <v-skeleton-loader
                        :key="item"
                        height="256px"
                        type="list-item, image"
                      ></v-skeleton-loader>
                    </template>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </workflow-content>
</template>

<script>
import PostMortem from '@/modules/PostMortem/resources/PostMortem';
import currentImages from '@/modules/PostMortem/config/currentImages';
import { mapActions } from 'vuex';

export default {
  layout: 'admin',

  meta: {
    permissions: [ 'pod_post_mortem' ],
    isWorkflow: true,
  },

  data: () => ({
    openMenu: true,
    itemsCount: 4,
    postmortem: new PostMortem({
      options: {
        order_by: [ 'well_name' ],
      },
      meta: {
        well: {},
        currentImages,
        currentTables: [
          { component: () => import('@/modules/PostMortem/components/Tables/TableLocation') },
          { component: () => import('@/modules/PostMortem/components/Tables/TableDepthInfo') },
          { component: () => import('@/modules/PostMortem/components/Tables/TableOperator') },
          { component: () => import('@/modules/PostMortem/components/Tables/TableSpudDate') },
          { component: () => import('@/modules/PostMortem/components/Tables/TableEndOfDrilling') },
          { component: () => import('@/modules/PostMortem/components/Tables/TablePostDrillConclusion') },
        ],
        supportedQuery: [
          'q',
          'page',
          'order_by',
          'projects_list',
          { search: 'q' },
          { sortBy: 'order_by' },
          { itemsPerPage: 'page_size' },
        ],
      },
    }),
  }),

  computed: {
    searchQuery () {
      return this.postmortem.search || '';
    },

    wells () {
      return (this.postmortem.items || []);
    },
  },

  mounted () {
    this.postmortem.list();
  },

  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggleMenu',
    }),

    setSearch (e) {
      this.postmortem.setSearch(e.target.value).setQueryString({ page: 1 }).list();
    },

    clearSearch () {
      this.postmortem.clearSearch().list();
    },

    isActive (item) {
      return this.postmortem.selected.includes(item);
    },

    setPage (item) {
      this.postmortem.unselectAll();
      this.postmortem.select(item);
      this.postmortem.findWell(item.relationships.well_id.data.id);
      this.postmortem.findPostMortemDetails(item.id);
    },

    onUpdatePage (page) {
      this.postmortem.setQueryString({ page }).list();
    },

    onUnfilter () {
      this.postmortem.clearSearch().list();
    },
  },
};
</script>
