<template>
  <v-layout column justify-center>
    <div class="accent--text text-center">
      <icon-relation-map-tree></icon-relation-map-tree>
    </div>
    <div class="my-6 text-center text--disabled">
      <div class="font-weight-bold" v-text="$trans('No selected images')"></div>
      <span
        v-html="$trans('To select images, click on an image <br>or draw a 2D selection.')"
      ></span>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'IntuitionDataIteratorEmptyState',
};
</script>
