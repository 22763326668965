import Config from '@config/server.json';

export const STRIPE_PUBLISHABLE_KEY = Config.VUE_APP_STRIPE_PUBLISHABLE_KEY
  || process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

export const DEFAULT_PRODUCT_ID = Config.VUE_APP_DEFAULT_PRODUCT_ID
  || process.env.VUE_APP_DEFAULT_PRODUCT_ID;

export default {
  STRIPE_PUBLISHABLE_KEY,
  DEFAULT_PRODUCT_ID,
};
