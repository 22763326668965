import StoreState from '@core/resources/StoreState';

export const state = new StoreState({
  // Type
  type: 'info',
  color: 'info',

  // Message
  title: null,
  content: null,
  setMessage ({
    title,
    content,
    type,
    color,
  }) {
    this.title = title;
    this.content = content;
    this.type = type ?? this.type;
    this.color = color ?? type ?? this.color;
  },
  getMessage () {
    return {
      title: this.title,
      content: this.content,
    };
  },

  // Reset
  reset () {
    this.title = null;
    this.content = null;
  },
});

export const getters = {
  ...StoreState.mapDefaultGetters(),
  alert: state => state,
  message: state => state.getMessage(),
};

export const mutations = {
  ...StoreState.mapDefaultMutations(),
  RESET_ALERT (state) {
    state.reset();
  },
  SET_MESSAGE (state, message) {
    state.setMessage(message);
  },
};

export const actions = {
  ...StoreState.mapDefaultActions(),
  showAndSetMessage ({ commit }, message) {
    commit('RESET_ALERT');
    commit('SET_AS_SHOWN');
    commit('SET_MESSAGE', message);
  },
  setMessage ({ commit }, message) {
    commit('SET_MESSAGE', message);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
