export default class StoreState {
  constructor (attributes = {}) {
    this.setDefaultStates();
    this.mapToSelf(attributes);
  }

  setDefaultStates () {
    this.active = true;
    this.shown = false;
    this.loading = false;
  }

  setActive (isActive) {
    this.active = isActive;
  }

  isActive () {
    return this.active;
  }

  isInactive () {
    return !this.active;
  }

  isShown () {
    return this.shown;
  }

  isHidden () {
    return !this.shown;
  }

  setShown (isShown) {
    this.shown = isShown;
  }

  startLoading () {
    this.loading = true;
  }

  stopLoading () {
    this.loading = false;
  }

  isLoading () {
    return this.loading;
  }

  mapToSelf (attributes) {
    this.setAttributes(attributes);

    Object.keys(attributes)
      .forEach(attribute => {
        this[attribute] = attributes[attribute];
      });
  }

  setAttributes (attributes) {
    this.attributes = attributes;
  }

  static mapDefaultGetters (getters = null) {
    return {
      shown: state => state.isShown(),
      active: state => state.isActive(),
      loading: state => state.isLoading(),
      ...getters,
    };
  }

  static mapDefaultMutations (mutations = null) {
    return {
      SET_SHOWN (state, isShown) {
        state.setShown(isShown);
      },
      SET_AS_SHOWN (state) {
        state.setShown(true);
      },
      SET_AS_HIDDEN (state) {
        state.setShown(false);
      },
      START_LOADING (state) {
        state.startLoading();
      },
      STOP_LOADING (state) {
        state.stopLoading();
      },
      ...mutations,
    };
  }

  static mapDefaultActions (actions = null) {
    return {
      setShown ({ commit }, isShown) {
        commit('SET_SHOWN', isShown);
      },
      show ({ commit }) {
        commit('SET_AS_SHOWN');
      },
      hide ({ commit }) {
        commit('SET_AS_HIDDEN');
      },
      startLoading ({ commit }) {
        commit('START_LOADING');
      },
      stopLoading ({ commit }) {
        commit('STOP_LOADING');
      },
      ...actions,
    };
  }
}
