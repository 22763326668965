<template>
  <!-- eslint-disable max-len -->
  <v-layout column justify-center>
    <div class="secondary--text text-center">
      <icon-landscape-image></icon-landscape-image>
    </div>
    <div class="my-6 text-center">
      <v-sheet max-width="500" color="transparent" class="mx-auto">
        <h1 class="mb-5" v-text="$trans('Your Datalake is empty')"></h1>
        <div
          class="body-1"
          v-text="$trans('Head over to Datalake and upload the files for your project.')"
        ></div>
        <div
          class="body-1"
          v-text="$trans('If you have already uploaded some files, wait for them to be processed and deployed.')"
        ></div>

        <v-btn
          :to="{
            name: 'datalake.upload',
            query: {
              tab: 'uploaded',
            },
          }"
          x-large
          rounded
          color="secondary"
          class="text-capitalize my-8 px-10"
        >
          <span v-text="$trans('Add Data')"></span>
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-sheet>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'ImagesDatalakeDataIteratorEmptyState',
};
</script>
