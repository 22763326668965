<template>
  <div>
    <template v-for="(item, i) in items">
      <component
        :is="item.component"
        :key="`${i}-${item.value}`"
        :resource="vizGroup[item.value]"
      ></component>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { has } from 'lodash';

export default {
  name: 'VizMapControlGroup',

  props: {
    vizGroup: {
      type: [ Object, Array ],
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      items: 'viz/viz/selectedItems',
      unselectedItems: 'viz/viz/unselectedItems',
    }),
  },

  watch: {
    items (selected) {
      this.showMapMarkers(selected);
    },

    unselectedItems (unselectedItems) {
      this.hideMapMarkers(unselectedItems);
    },
  },

  methods: {
    showMapMarkers (items) {
      items.forEach(item => {
        if (has(this.vizGroup, item.value)) {
          this.vizGroup[item.value].showMapMarkers();
        }
      });
    },

    hideMapMarkers (items) {
      items.forEach(item => {
        if (has(this.vizGroup, item.value)) {
          this.vizGroup[item.value].hideMapMarkers();
        }
      });
    },
  },
};
</script>
