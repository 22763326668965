<template>
  <v-layout column class="ma-10 align-center justify-center text-center">
    <div class="secondary--text" style="filter: grayscale(30%);">
      <icon-single-leaf></icon-single-leaf>
    </div>
    <div class="muted--text mb-3" v-text="$trans('You do not have any subcriptions.')"></div>
    <div
      class="muted--text mb-6"
      v-text="$trans('Contact our sales team to subcribe to any of our plans.')"
    ></div>
    <v-btn
      :to="{ name: 'contact', query: { email: user.data.email } }"
      x-large
      color="secondary"
      class="my-3"
    >
      <span v-text="$trans('Contact Sales')"></span>
    </v-btn>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BillingOverviewTabItemEmptyState',

  computed: mapGetters({
    user: 'auth/user',
  }),
};
</script>
