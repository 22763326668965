<template>
  <v-dialog
    v-model="dialog"
    v-bind="{ ...$attrs, ...$props }"    
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="{ on }">
        <v-btn depressed v-on="on" :title="'search open filters'">
          <v-icon left small>mdi-tune</v-icon>
          <span v-text="$trans('Advanced filters')"></span>
        </v-btn>
      </slot>
    </template>
    <v-card :title="'search dialog filters'">
      <v-card-title><span v-text="$trans('Advanced filters')"></span></v-card-title>
      <v-card-text>
        <slot name="items">
          <template v-if="isFiltersEmpty">
            <v-sheet height="200" class="d-flex align-center">
              <v-spacer></v-spacer>
              <div class="mx-auto muted--text" v-text="$trans('No filters available')"></div>
              <v-spacer></v-spacer>
            </v-sheet>
          </template>
          <v-chip-group
            v-else
            v-model="selected"
            column multiple
          >
            <template v-for="filter in visibleFilters">
              <slot name="item" v-bind="{ item: filter, selected, isSelected }">
                <div :key="filter.key" class="d-block mb-8" :title="`filter-${filter.key}`">
                  <p class="overline mb-0" v-text="$trans(filter.text)"></p>
                  <v-chip
                    v-for="chip in filter.chips" :key="chip.value"
                    :active-class="color"
                    :value="chip.value" :input-value="chip.value"
                    v-text="chip.text"
                  ></v-chip>
                </div>
              </slot>
            </template>
          </v-chip-group>
        </slot>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions class="caption">
        <button
          :disabled="isSelectedNone"
          :class="{ 'muted--text s-link--disabled': isSelectedNone }"
          :title="'search deselect filters'"
          type="button"
          class="s-link px-1"
          @click="unselectAll"
          v-text="$trans('Clear all')"
        ></button>
        <button
          :disabled="isSelectedAll"
          :class="{ 'muted--text s-link--disabled': isSelectedAll }"
          :title="'search select filters'"
          type="button"
          class="s-link px-1"
          @click="selectAll"
          v-text="$trans('Select all')"
        ></button>
        <v-spacer></v-spacer>
        <v-btn
          depressed min-width="120px"
          @click="hideDialog"
          v-text="$trans('Cancel')"
          :title="'search close filters'"
        ></v-btn>
        <v-btn
          :disabled="isFiltersEmpty"
          color="primary"
          min-width="120px"
          @click="onClick"
          :title="'search apply filters'"
          v-text="$trans('Filter')"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty, size, flatten } from 'lodash';

export default {
  name: 'AppSearchAdvancedFilterDialog',

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [],
    },

    color: {
      type: String,
      default: 'accent',
    },

    itemKey: {
      type: String,
      default: 'key',
    },

    itemValue: {
      type: String,
      default: 'value',
    },

    itemText: {
      type: String,
      default: 'text',
    },
  },

  data: () => ({
    dialog: false,
  }),

  computed: {
    filters () {
      return this.getFiltersByFeatureFlag(this.items.map(item => ({
        ...item,
        key: item[this.itemKey],
        value: item[this.itemValue],
        text: item[this.itemText],
      })));
    },

    visibleFilters () {
      return this.filters.filter(i => i.isVisible);
    },

    invisibleFilters () {
      return this.filters.filter(i => !i.isVisible);
    },

    flattenedFilters () {
      return flatten(this.filters.map(filter => filter.chips.map(chip => chip.value)));
    },

    flattenedInvisibleFilters () {
      return flatten(this.invisibleFilters.map(filter => filter.chips.map(chip => chip.value)));
    },

    selected: {
      get () {
        return this.value;
      },
      set (selected) {
        this.$emit('input', this.getSelectedFilters(selected));
      },
    },

    isSelectedAll () {
      return size(this.selected) === size(this.flattenedFilters);
    },

    isSelectedNone () {
      return isEmpty(this.selected);
    },

    isFiltersEmpty () {
      return isEmpty(this.filters);
    },
  },

  mounted () {
    this.selectAll();
  },

  methods: {
    getSelectedFilters (filters) {
      return [
        ...this.flattenedInvisibleFilters,
        ...filters,
      ];
    },

    getFiltersByFeatureFlag (filters) {
      const user = this.$store.getters['auth/user'];

      return filters.map(filter => ({
        ...filter,
        isVisible: user.isPermittedTo(filter.permission),
      }));
    },

    showDialog () {
      this.dialog = true;
    },

    hideDialog () {
      this.dialog = false;
    },

    isSelected (index) {
      return this.selected.includes(index);
    },

    onClick () {
      this.$emit('input', this.getSelectedFilters(this.selected));
      this.$emit('click:filter', this.getSelectedFilters(this.selected));
      this.hideDialog();
    },

    unselectAll () {
      this.selected = [];
    },

    selectAll () {
      this.selected = this.flattenedFilters;
    },
  },
};
</script>
