var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('images-data-iterator',{attrs:{"dense":_vm.image.meta.dense,"headers":_vm.image.headers,"hide-default-footer":_vm.image.meta.hideDefaultFooter,"items-per-page":_vm.image.pagination.itemsPerPage,"items-per-row":_vm.image.meta.itemsPerRow,"items":_vm.image.items,"loading":_vm.image.loading,"mobile-breakpoint":_vm.image.meta.mobileBreakpoint,"options":_vm.image.options,"server-items-length":_vm.image.pagination.total,"show-select":_vm.image.meta.showSelect},on:{"update:options":[function($event){return _vm.$set(_vm.image, "options", $event)},_vm.updateList],"download":_vm.downloadImage},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('category-search-tab-item-empty-state')]},proxy:true},{key:"no-data",fn:function(){return [_c('category-search-tab-item-empty-state')]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"text-center text--disabled",domProps:{"textContent":_vm._s(_vm.$trans('Loading items...'))}})]},proxy:true},{key:"button.link",fn:function(ref){
var imageItem = ref.item;
return [_c('v-btn',{staticClass:"mb-4",attrs:{"to":{
        name: 'search.image',
        params: { image: imageItem.id },
        query: {
          q: _vm.$route.query.q,
          project_id: imageItem.attributes.project_id,
          img_tag_1: imageItem.attributes.img_tag_1,
        },
      },"exact":"","dark":"","small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-magnify-plus-outline")]),_vm._v(" "+_vm._s(_vm.$trans('Zoom in'))+" ")],1)]}},{key:"button.go-to-page",fn:function(ref){
      var imageItem = ref.item;
return [_c('v-btn',{staticClass:"mb-4",attrs:{"to":{
        name: 'reports.single',
        params: { slug: imageItem.attributes.doc_ref },
        query: {
          page_num: imageItem.attributes.page_num,
          data_index: imageItem.data_index,
          back: _vm.$route.fullPath,
        },
      },"dark":"","small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-file-eye-outline")]),_vm._v(" "+_vm._s(_vm.$trans("Go to page {page}", { page: imageItem.attributes.page_num, }))+" ")],1)]}},{key:"item.append",fn:function(ref){
      var imageItem = ref.item;
return [_c('v-chip',{staticClass:"my-3",attrs:{"label":"","outlined":"","small":""}},[_c('span',{domProps:{"textContent":_vm._s(imageItem.attributes.img_tag)}})])]}},{key:"footer",fn:function(){return [(_vm.image.loaded)?_c('div',{staticClass:"text-center caption mt-5"},[_c('span',{staticClass:"muted--text",attrs:{"title":"category images footer"},domProps:{"textContent":_vm._s(_vm.$trans_choice('{count} of {total} item', _vm.image.pagination.total, {
          count: _vm.image.size(),
          total: _vm.image.pagination.total,
        }))}})]):_vm._e(),_c('v-layout',[_c('v-spacer'),(_vm.image.moreToLoad())?_c('v-btn',{staticClass:"my-5",attrs:{"loading":_vm.image.loading,"disabled":_vm.image.loading,"text":"","large":"","title":"load more"},on:{"click":_vm.loadMoreImages}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Load more'))}})]):_vm._e(),_c('v-spacer')],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }