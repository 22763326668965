import DataResource from '@core/resources/DataResource';
import DataSource from '@/modules/Sourcetray/resources/DataSource';
import sources, { SOURCETYPE_PUBLIC, SOURCETYPE_PUBLICATIONS } from '@/modules/Sourcetray/config/sources';
import { GET_PROJECTS_URL } from '@/modules/Sourcetray/api/projects';
import {
  keyBy,
  keys,
  isEmpty,
  flattenDeep,
  uniqBy,
} from 'lodash';

export default class Project extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async list () {
    const params = this.getQueryString();
    const { data } = await this.axios.get(GET_PROJECTS_URL, params);
    this.projects = this.parseProjects(data.data);
    this.setSources(this.projects);
    this.setProjects(this.projects);
    this.setItems(this.meta.sources);
    this.makeSelectedItemsFromStore();
    this.updateQueryStringFromStore();
  }

  parseProjects (items) {
    const { permissions } = this.store.getters['auth/user'];
    const types = [ SOURCETYPE_PUBLIC, SOURCETYPE_PUBLICATIONS ];
    return items.filter(i => types.includes(i.attributes.project_type)
        || permissions.includes(i.id));
  }

  setSources (items) {
    const trays = items
      .map(i => ({ ...i, ...i.attributes.project_settings.SOURCE_TRAY_CONFIGURATION_TREE[0] }))
      .map(i => ({ ...i, viewable: i.attributes.project_settings.VIEW_SOURCE_TRAY }))
      .map(i => ({ ...i, url: i.attributes.project_settings.PROJECT_STORAGE_URL }))
      .map(i => ({ ...i, ...i.attributes.project_settings }))
      .map(i => ({ ...i, key: `${i.name}:${i.id}` }))
      .map(i => this.setSourceUrl(i, i.url))
      .filter(i => (i.viewable ?? '').toLowerCase() === 'true');

    this.meta.sources = this.mergeDeepByKey(trays, 'key')
      .map(tray => ({ ...tray, ...sources.find(source => source.name === tray.name) }))
      .map(source => ({ ...new DataSource(source) }));
  }

  setSourceUrl (source, url) {
    if (source.children) {
      source.children = source.children.map(i => this.setSourceUrl(i, url));
    }

    return { ...source, url };
  }

  mergeDeepByKey (items, key = 'name') {
    return items.reduce((res, elem) => {
      const checker = res.filter(el => {
        const elKey = `${el[key]}:${el.id}`;
        const elemKey = `${elem[key]}:${elem.id}`;
        return elKey === elemKey;
      });

      if (checker.length) {
        if (checker[0]?.children) {
          checker[0].children.push(elem.children[0]);
          checker[0].children = this.mergeDeepByKey(checker[0].children, key);
        }

        return res;
      }

      return res.concat(elem);
    }, []);
  }

  setProjects (projects) {
    this.store.dispatch('sourcetray/setProjects', projects);
  }

  saveSourceTrayList (items) {
    this.store.dispatch('sourcetray/save', items);
    this.updateQueryStringFromStore();
    this.setMetaAllSelected(items);
  }

  setMetaAllSelected (selected) {
    this.meta.isAllSelected = selected.length === this.meta.sources.length;
  }

  updateQueryStringFromStore () {
    this.setQueryString({
      projects_list: this.getSelectedItemsAsString(),
    });
  }

  getSelectedItemsAsString () {
    return keys(keyBy(this.getSelectedItemsFromStore(), 'id')).join(',');
  }

  getSelectedItemsFromStore () {
    return this.store.getters['sourcetray/sources'] || null;
  }

  makeSelectedItemsFromStore () {
    this.selected = this.getSelectedItemsFromLocalStorageOrDefault();
  }

  getSelectedItemsFromLocalStorageOrDefault () {
    const items = this.getSelectedItemsFromStore();
    const projects = this.store.getters['sourcetray/projects'];
    const selected = items?.filter(item => projects.find(project => item.id === project.id));

    this.unselectAll();

    if (isEmpty(selected)) {
      const defaults = this.getDefaultSelections();
      this.store.dispatch('sourcetray/save', defaults);
      return defaults;
    }

    return selected;
  }

  getDefaultSelections () {
    const list = [];
    let item = null;

    this.meta.sources.forEach(source => {
      if (!isEmpty(source.children)) {
        item = flattenDeep(this.getDefaultSelection(source.children));
      } else {
        item = source;
      }

      list.push(item);
    });

    return uniqBy(flattenDeep(list));
  }

  getDefaultSelection (items) {
    let item = null;
    const _items = [];

    items.forEach(i => {
      if (!isEmpty(i.children)) {
        item = this.getDefaultSelection(i.children);
      } else {
        item = i;
      }

      _items.push(item);
    });

    return _items;
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }
}
