export const map = {
  id: 'map-area',
  hasDrawn: false,
  setDrawn (hasDrawn) {
    this.hasDrawn = hasDrawn;
  },
  setAsDrawn () {
    this.hasDrawn = true;
  },
  setAsUndrawn () {
    this.hasDrawn = false;
  },
};

export const KEY_KNOWLEDGE_GRAPH = 'knowledge-graph';
export const KEY_MAP_AREA = 'map-area';

export const mapAreaTabs = {
  currentIndex: 0,
  items: [
    {
      text: 'Map',
      key: KEY_MAP_AREA,
      storeKey: 'showMapArea',
      eager: true,
      active: true,
      permission: 'search_map',
      component: () => import('@/modules/Search/components/MapArea/MapAreaMapTabItem'),
    },
    {
      text: 'Knowledge Graph',
      key: KEY_KNOWLEDGE_GRAPH,
      storeKey: 'showKnowledgeGraphArea',
      eager: false,
      active: false,
      permission: 'search_knowledge_graph',
      component: () => import('@/modules/Search/components/MapArea/MapAreaKnowledgeGraphTabItem'),
    },
  ],
  find (key) {
    return this.items.find(i => i.key === key);
  },
  setActive (key, value) {
    const item = this.find(key);
    item.active = value;
  },
  setCurrentIndex (index) {
    this.currentIndex = index;
  },
  setCurrentIndexToDefault () {
    this.currentIndex = 0;
  },
};

export const area = {
  isShown: false,
  active: false,
  expand: false,
  show () {
    this.isShown = true;
  },
  hide () {
    this.isShown = false;
  },
  setShown (isShown) {
    this.isShown = isShown;
  },
  activate () {
    this.active = true;
  },
  deactivate () {
    this.active = false;
  },
};

export const draw = {
  options (items) {
    return {
      draw: {
        polyline: false,
        circlemarker: false,
        circle: false,
        marker: false,
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          clickable: true,
          drawError: {
            color: '#e1e100', // Color the shape will turn when intersects
            message: '<strong>Oh snap!<strong> you can\'t draw that!', // Message that will show when intersect
          },
          shapeOptions: {
            weight: 2,
            dashArray: '1 4 3 1',
            color: 'steelblue',
            fill: true,
            fillColor: '#fcfdff',
            fillOpacity: 0.3,
          },
        },
        rectangle: {
          shapeOptions: {
            clickable: true,
            weight: 2,
            dashArray: '1 4 3 1',
            color: 'steelblue',
            fill: true,
            fillColor: '#fcfdff',
            fillOpacity: 0.3,
          },
        },
      },
      edit: {
        featureGroup: items,
        edit: false,
      },
    };
  },
};

export default {
  map,
  draw,
  area,
  mapAreaTabs,
};
