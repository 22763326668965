import { BASE_URL } from '@/config/api';

export const LIST_DATALAKES_URL = `${BASE_URL}/data-dashboard/table`;
export const FIND_DATALAKE_DATA_URL = id => `${BASE_URL}/datalake/data/${id}/`;
export const FIND_DATALAKE_DOCUMENTS = `${BASE_URL}/datalake/data/`;
export const FIND_DATALAKE_BY_WELL_ID = id => `${BASE_URL}/datalake/data/?well_info__id=${id}`;
export const FIND_RELATED_DOCUMENTS_URL = id => `${BASE_URL}/datalake/data/?data_id_list=${id}`;

export default Object.freeze({
  LIST_DATALAKES_URL,
  FIND_DATALAKE_DATA_URL,
  FIND_DATALAKE_DOCUMENTS,
  FIND_DATALAKE_BY_WELL_ID,
  FIND_RELATED_DOCUMENTS_URL,
});
