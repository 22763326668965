<template>
  <v-card
    ref="spreadsheet-previewer"
    outlined
    height="100%"
    min-height="calc(100vh - 260px)"
    class="spreadsheet-previewer"
  >
    <app-stickybar>
      <v-toolbar elevation="4">
        <slot name="toolbar.title">
          <v-layout align-center justify-start class="ov-x-auto" style="overflow: hidden;">

            <v-layout align-center justify-lg-end justify-sm-start>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small icon
                    class="mr-2"
                    v-on="on"
                    @click="downloadItem"
                  >
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span v-text="$trans('Open in new tab')"></span>
              </v-tooltip> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small icon
                    class="mr-2"
                    v-on="on"
                    @click="downloadItem"
                  >
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span v-text="$trans('Download')"></span>
              </v-tooltip>
            </v-layout>
            
          </v-layout>
        </slot>
      </v-toolbar>
    </app-stickybar>

    <v-card flat tile class="ov-hidden spreadsheet-card">
        <!-- <iframe title="htmlSpreadsheet" id="htmlSpreadsheet" type="text/html" width="100%" height="100%" :onload="iframeLoaded()" allow="cross-origin-isolated">
        </iframe> -->
        <iframe title="htmlSpreadsheet" id="htmlSpreadsheet" type="text/html" :src="srcHTML" width="100%" height="100%">
        </iframe>
        <!-- <html v-html="currentPageTable"></html>
          <a v-for="(tab) in tabStripArray" :key="tab.tabName" :href="tab.tabSrc">
            {{tab.tabName}}
          </a> -->
    </v-card>
  </v-card>
</template>

<script>

export default {
  name: 'SpreadsheetPreviewer',

  props: {

    srcHTML: {
      type: String,
      default: () => '',
    },

    tabStripArray:{
      type: Array,
      default: () => [],
    },

    currentPageTable:{
      type: String,
      default: () => '',
    },

  },

  data: vm => ({
  }),

  mounted () {
  },

  methods: {

    downloadItem () {
      this.$emit('click:download');
    },

  },
};
</script>
<style lang="scss">
.spreadsheet-card {
  height: 100%;
}
</style>


