import ActionMenuRegistrar from '@core/registrars/ActionMenuRegistrar';

ActionMenuRegistrar.register([
  {
    icon: 'mdi-credit-card-outline',
    permission: 'payment',
    text: 'Billing',
    to: { name: 'profile.billing' },
    sort: 3,
  },
]);
