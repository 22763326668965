<template>
  <images-data-iterator
    :dense="image.meta.dense"
    :headers="image.headers"
    :hide-default-footer="image.meta.hideDefaultFooter"
    :items-per-page="image.pagination.itemsPerPage"
    :items-per-row="image.meta.itemsPerRow"
    :items="image.items"
    :loading="image.loading"
    :mobile-breakpoint="image.meta.mobileBreakpoint"
    :options.sync="image.options"
    :server-items-length="image.pagination.total"
    :show-select="image.meta.showSelect"
    @download="downloadImage"
    @update:options="updateList"
  >
    <template v-slot:no-results>
      <category-search-tab-item-empty-state></category-search-tab-item-empty-state>
    </template>
    <template v-slot:no-data>
      <category-search-tab-item-empty-state></category-search-tab-item-empty-state>
    </template>
    <template v-slot:loading>
      <div class="text-center text--disabled" v-text="$trans('Loading items...')"></div>
    </template>

    <template v-slot:button.link="{ item: imageItem }">
      <v-btn
        :to="{
          name: 'search.image',
          params: { image: imageItem.id },
          query: {
            q: $route.query.q,
            project_id: imageItem.attributes.project_id,
            img_tag_1: imageItem.attributes.img_tag_1,
          },
        }" exact
        dark
        small outlined
        class="mb-4"
      >
        <v-icon left small>mdi-magnify-plus-outline</v-icon>
        {{ $trans('Zoom in') }}
      </v-btn>
    </template>

    <template v-slot:button.go-to-page="{ item: imageItem }">
      <v-btn
        :to="{
          name: 'reports.single',
          params: { slug: imageItem.attributes.doc_ref },
          query: {
            page_num: imageItem.attributes.page_num,
            data_index: imageItem.data_index,
            back: $route.fullPath,
          },
        }"
        dark
        small outlined
        class="mb-4"
      >
        <v-icon left small>mdi-file-eye-outline</v-icon>
        {{ $trans(`Go to page {page}`, {
          page: imageItem.attributes.page_num,
        }) }}
      </v-btn>
    </template>

    <template v-slot:item.append="{ item: imageItem }">
      <v-chip
        label outlined
        small class="my-3"
      >
        <span v-text="imageItem.attributes.img_tag"></span>
      </v-chip>
    </template>
    <template v-slot:footer>
      <div v-if="image.loaded" class="text-center caption mt-5">
        <span
          class="muted--text"
          title="category images footer"
          v-text="$trans_choice('{count} of {total} item', image.pagination.total, {
            count: image.size(),
            total: image.pagination.total,
          })"
        ></span>
      </div>
      <v-layout>
        <v-spacer></v-spacer>
        <v-btn
          v-if="image.moreToLoad()"
          :loading="image.loading"
          :disabled="image.loading"
          text
          large
          class="my-5"
          title="load more"
          @click="loadMoreImages"
        >
          <span v-text="$trans('Load more')"></span>&nbsp;
        </v-btn>
        <v-spacer></v-spacer>
      </v-layout>
    </template>
  </images-data-iterator>
</template>

<script>
import Image from '@/modules/Search/resources/Image';
import { mapActions } from 'vuex';

export default {
  name: 'CategorySearchImagesTabItem',

  props: {
    tab: {
      type: [ Object ],
      default: () => {},
    },

    item: {
      type: [ Object ],
      required: true,
    },
  },

  data: () => ({
    image: new Image,
  }),

  watch: {
    '$route.query.q': function (query) {
      this.fetchList(query);
    },
  },

  mounted () {
    this.hideMapArea();
    this.hideExportButton();
    this.hideSpatialButton();
  },

  methods: {
    ...mapActions({
      hideExportButton: 'search/hideExportButton',
      hideMapArea: 'search/hideMapArea',
      hideSpatialButton: 'search/hideSpatialButton',
    }),

    async fetchList (query) {
      if (this.image.hasSearch()) {
        await this.image.setSearch(query).listDocText();
        this.item.stopLoading();
      }
    },

    async updateList (options) {
      if (this.image.hasSearch()) {
        await this.image.setQueryString({ ...options, ...this.$route.query }).listDocText();
        this.item.stopLoading();
      }
    },

    onResultClick (item) {
      this.emitSelectedItem(item);
      this.$router.push({
        name: 'reports.single',
        params: { slug: item.docRef },
        query: {
          page_num: item.page,
          project_type: item.type,
          data_index: item.data_index,
          back: this.$route.fullPath,
        },
      });
    },

    selectItem ({ item, select }) {
      select(item, true);
      this.emitSelectedItem(item);
    },

    emitSelectedItem (item) {
      this.$emit('click:item', item);
    },

    downloadImage (item) {
      this.image.downloadImage(item);
    },

    loadMoreImages () {
      this.image.listMore();
    },
  },
};
</script>
