<template>
  <v-skeleton-loader width="100%" :height="height" class="ov-hidden">
    <v-card flat :height="height" class="ov-hidden">
      <div
        class="v-skeleton-loader__pdf-previewer__page-item rounded v-skeleton-loader__bone"
      ></div>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
export default {
  name: 'PanzoomPdfPreviewerPageItemLoadingState',

  props: {
    height: {
      type: [ String, Number ],
      default: '100%',
    },
  },
};
</script>

<style lang="scss">
.v-skeleton-loader__pdf-previewer__page-item {
  background: rgba(0, 0, 0, 0.12);
  height: 100vh;
}
</style>
