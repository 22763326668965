import { area, mapAreaTabs, KEY_KNOWLEDGE_GRAPH } from '@/modules/Search/config/map';
import { mapGeometries } from '@/modules/Search/config/geometries';
import exports from '@/modules/Search/config/exports';
import spatial from '@/modules/Search/config/spatial';

export const state = {
  mapArea: area,
  mapAreaTabs,
  exports,
  spatial,
  mapGeometries,
};

export const getters = {
  mapArea: state => state.mapArea,
  spatial: state => state.spatial,
  mapAreaTabs: state => state.mapAreaTabs,
  isMapAreaShown: state => state.mapArea.isShown,
  isMapAreaExpanded: state => state.mapArea.expand,
  isExportButtonShown: state => state.exports.isShown,
  isSpatialButtonShown: state => state.spatial.isShown,
  isSpatialButtonOn: state => state.spatial.isOn,
  isSpatialFilterTriggered: state => state.spatial.isSpatialFilterTriggered,
  currentMapAreaTabIndex: state => state.mapAreaTabs.currentIndex,
  isMapGeometriesActive: state => state.mapGeometries.isActive,
};

export const mutations = {
  SHOW_MAP_AREA (state) {
    state.mapArea.show();
    state.mapArea.activate();
  },
  HIDE_MAP_AREA (state) {
    state.mapArea.hide();
    state.mapArea.deactivate();
  },
  SET_MAP_AREA_SHOWN (state, isShown) {
    state.mapArea.setShown(isShown);
  },
  SHOW_EXPORT_BUTTON (state) {
    state.exports.show();
  },
  HIDE_EXPORT_BUTTON (state) {
    state.exports.hide();
  },
  SHOW_SPATIAL_BUTTON (state) {
    state.spatial.show();
  },
  HIDE_SPATIAL_BUTTON (state) {
    state.spatial.hide();
  },
  TOGGLE_SPATIAL_BUTTON_ON (state) {
    state.spatial.toggle();
  },
  SET_SPATIAL_BUTTON_ON (state, isOn = true) {
    state.spatial.setOn(isOn);
  },
  SET_SPATIAL_BUTTON_SHOWN (state, isShown) {
    state.spatial.setShown(isShown);
  },
  SHOW_KNOWLEDGE_GRAPH_AREA (state) {
    state.mapAreaTabs.setActive(KEY_KNOWLEDGE_GRAPH, true);
  },
  HIDE_KNOWLEDGE_GRAPH_AREA (state) {
    state.mapAreaTabs.setActive(KEY_KNOWLEDGE_GRAPH, false);
  },
  SET_KNOWLEDGE_GRAPH_AREA_SHOWN (state, isActive) {
    state.mapAreaTabs.setActive(KEY_KNOWLEDGE_GRAPH, isActive);
  },
  SET_CURRENT_MAP_AREA_TAB_INDEX (state, index) {
    state.mapAreaTabs.setCurrentIndex(index);
  },
  SET_CURRENT_MAP_AREA_TAB_TO_DEFAULT (state) {
    state.mapAreaTabs.setCurrentIndexToDefault();
  },
  SET_MAP_GEOMETRIES_STATE (state, isActive) {
    state.mapGeometries.setActive(isActive);
  },
  TRIGGER_SPATIAL_FILTER (state) {
    state.spatial.trigger();
  },
  TOGGLE_EXPAND_MAP_AREA (state) {
    state.mapArea.expand = !state.mapArea.expand;
  },
};

export const actions = {
  showMapArea ({ commit }) {
    commit('SHOW_MAP_AREA');
  },
  hideMapArea ({ commit }) {
    commit('HIDE_MAP_AREA');
  },
  setMapAreaShown ({ commit }, isShown) {
    commit('SET_MAP_AREA_SHOWN', isShown);
  },
  showExportButton ({ commit }) {
    commit('SHOW_EXPORT_BUTTON');
  },
  hideExportButton ({ commit }) {
    commit('HIDE_EXPORT_BUTTON');
  },
  showSpatialButton ({ commit }) {
    commit('SHOW_SPATIAL_BUTTON');
  },
  hideSpatialButton ({ commit }) {
    commit('HIDE_SPATIAL_BUTTON');
  },
  setSpatialButtonShown ({ commit }, isShown) {
    commit('SET_SPATIAL_BUTTON_SHOWN', isShown);
  },
  toggleSpatialButtonOn ({ commit }) {
    commit('TOGGLE_SPATIAL_BUTTON_ON');
  },
  setSpatialButtonOn ({ commit }, isOn = true) {
    commit('SET_SPATIAL_BUTTON_ON', isOn);
  },
  showKnowledgeGraphArea ({ commit }) {
    commit('SHOW_KNOWLEDGE_GRAPH_AREA');
  },
  hideKnowledgeGraphArea ({ commit }) {
    commit('HIDE_KNOWLEDGE_GRAPH_AREA');
    commit('SET_CURRENT_MAP_AREA_TAB_TO_DEFAULT');
  },
  setKnowledgeGraphAreaShown ({ commit }, isActive) {
    commit('SET_KNOWLEDGE_GRAPH_AREA_SHOWN', isActive);
  },
  setCurrentMapAreaTabIndex ({ commit }, index) {
    commit('SET_CURRENT_MAP_AREA_TAB_INDEX', index);
  },
  setMapGeometriesState ({ commit }, isActive) {
    commit('SET_MAP_GEOMETRIES_STATE', isActive);
  },
  triggerSpatialFilter ({ commit }) {
    commit('TRIGGER_SPATIAL_FILTER');
  },
  toggleExpandMapArea ({ commit }) {
    commit('TOGGLE_EXPAND_MAP_AREA');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
