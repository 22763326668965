import errors from '@core/router/exclusions/errors';

const page404 = errors.find(page => page.name === '404');
const defaultPage404 = () => import('@core/pages/404.vue');

const page401 = errors.find(page => page.name === '401');
const defaultPage401 = () => import('@core/pages/401.vue');

const page500 = errors.find(page => page.name === '500');
const defaultPage500 = () => import('@core/pages/500.vue');

export default [
  {
    path: '*',
    name: 'error:404',
    component: page404?.component || defaultPage404,
    meta: { layout: 'error' },
  },
  {
    path: '*',
    name: 'error:401',
    component: page401?.component || defaultPage401,
    meta: { layout: 'error' },
  },
  {
    path: '*',
    name: 'error:500',
    component: page500?.component || defaultPage500,
    meta: { layout: 'error' },
  },
];
