<template>
  <v-card height="144">
    <v-layout align-center justify-center class="pa-6 fill-height">
      <div v-html="$trans('No product selected.')"></div>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: 'CartItemsEmptyState',
};
</script>
