import { clone, merge } from 'lodash';
import store from '@core/store';

export const initials = {
  // Toggle
  show: false,
  loading: false,

  // Settings
  persistent: false,
  width: 420,
  maxWidth: 800,
  color: 'accent',
  light: null,
  dark: null,
  closeable: false,

  // Illustration
  illustration: null,
  illustrationWidth: 300,
  illustrationHeight: 300,

  // Text
  title: false,
  text: false,

  // Alignment
  alignment: false, // e.g. 'center'

  // Input Prompt
  prompt: {
    name: 'field',
    mode: 'aggressive',
    model: null,
    rules: null,
    show: false,
    attrs: null,
  },

  // Buttons
  buttons: {
    action: {
      show: true,
      color: 'primary',
      text: 'Okay',
      closeOnClick: true,
      callback: () => {
        store.dispatch('dialog/close');
      },
    },

    cancel: {
      show: true,
      color: 'dark',
      text: 'Cancel',
      closeOnClick: true,
      callback: () => {
        store.dispatch('dialog/close');
      },
    },
  },
};

export const state = () => ({
  dialog: clone(initials),
});

export const getters = {
  dialog: state => state.dialog,
  prompt: state => state.dialog.prompt,
};

export const mutations = {
  PROMPT_DIALOG (state, payload) {
    state.dialog = merge({}, state.dialog, payload, { loading: false });
  },

  CLOSE_DIALOG (state) {
    state.dialog.show = false;
    state.dialog.loading = false;
  },

  RESET_INITIAL_STATE (state) {
    state.dialog = initials;
  },

  SET_LOADING (state, loading) {
    state.dialog.loading = loading;
  },

  PROMPT_ERROR (state, payload) {
    state.dialog = merge({}, state.dialog, {
      color: 'error',
      illustration: () => import('@core/components/Icons/IconError.vue'),
      loading: false,
      show: true,
      buttons: {
        cancel: { show: false },
        action: {
          text: 'Okay',
          color: null,
          callback: () => {
            store.dispatch('dialog/error', { show: false, ...payload });
          },
        },
      },
    }, payload);
  },

  UPDATE_PROMPT_MODEL (state, model) {
    state.dialog.prompt.model = model;
  },
};

export const actions = {
  prompt: (context, payload) => {
    context.commit('RESET_INITIAL_STATE');
    context.commit('PROMPT_DIALOG', payload);
  },

  error: (context, payload) => {
    context.commit('RESET_INITIAL_STATE');
    context.commit('PROMPT_ERROR', payload);
  },

  open: (context, payload) => {
    context.commit('RESET_INITIAL_STATE');
    context.commit('PROMPT_DIALOG', Object.assign(payload, { show: true }));
  },

  show: (context, payload) => {
    context.commit('RESET_INITIAL_STATE');
    context.commit('PROMPT_DIALOG', Object.assign(payload, { show: true }));
  },

  hide: context => {
    context.commit('CLOSE_DIALOG');
  },

  close: context => {
    context.commit('CLOSE_DIALOG');
    context.commit('RESET_INITIAL_STATE');
  },

  loading: (context, loading) => {
    context.commit('SET_LOADING', loading);
  },

  reset: context => {
    context.commit('RESET_INITIAL_STATE');
  },

  updatePromptModel ({ commit }, model) {
    commit('UPDATE_PROMPT_MODEL', model);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
