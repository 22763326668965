export const state = {
  layers: {
    base: JSON.parse(localStorage.getItem('webgis:layers.base') || '[]'),
    others: JSON.parse(localStorage.getItem('webgis:layers.others') || '[]'),
    overlay: JSON.parse(localStorage.getItem('webgis:layers.overlay') || '[]'),
  },
  layerControlItems: [],
};

export const getters = {
  layers: state => state.layers,
  layerControlItems: state => state.layerControlItems,
  basemapLayers: state => state.layers.base,
  overlayLayers: state => state.layers.overlays,
  otherLayers: state => state.layers.others,
};

export const mutations = {
  SET_LAYERS (state, { type, items }) {
    state.layers[type] = items;
    localStorage.setItem(`webgis:layers.${type}`, JSON.stringify(items));
  },

  SET_LAYER_CONTROL_ITEMS (state, layerControlItems) {
    state.layerControlItems = layerControlItems;
  },

  UNSET_LAYERS (state) {
    state.layers.base = [];
    state.layers.others = [];
    state.layers.overlays = [];
    localStorage.removeItem('webgis:layers.base');
    localStorage.removeItem('webgis:layers.others');
    localStorage.removeItem('webgis:layers.overlay');
  },
};

export const actions = {
  layers ({ commit }, payload) {
    commit('SET_LAYERS', payload);
  },

  setLayerControlItems ({ commit }, layerControlItems) {
    commit('SET_LAYER_CONTROL_ITEMS', layerControlItems);
  },

  unsetLayers ({ commit }) {
    commit('UNSET_LAYERS');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
