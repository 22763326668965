export const state = () => ({
  avatar: {
    image: '',
    alt: '',
    upload: '',
  },
});

export const getters = {
  avatar: state => state.avatar,
  avatarImage: state => state.avatar.image,
  avatarAlt: state => state.avatar.alt,
  avatarUpload: state => state.avatar.upload,
};

export const mutations = {
  emptyState () {
    this.replaceState({ avatar: null });
  },

  UPDATE (state, payload) {
    state.avatar = { ...state.avatar, ...payload };
  },
};

export const actions = {
  update: ({ commit }, payload) => {
    commit('UPDATE', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
