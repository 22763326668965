<template>
  <div class="app-tab-content__container">
    <v-toolbar
      :color="color"
      dark flat
    ></v-toolbar>
    <app-content class="py-0">
      <v-tabs
        v-model="current"
        background-color="transparent"
        class="mb-5"
        style="margin-top: -48px;"
      >
        <template v-for="(item, i) in menus">
          <app-mode v-if="item.isViewable()" :key="i" :name="item.mode">
            <app-can-view :code="item.permission">
              <v-tab
                :id="`app-tab-${item.key}`"
                :key="item.key"
                :to="item.to"
                exact
                @click="e => onTabClick(item, e)"
              >
                <v-icon v-if="item.icon" left :color="item.iconColor">{{ item.icon }}</v-icon>
                {{ $trans(item.text) }}
              </v-tab>
            </app-can-view>
          </app-mode>
        </template>
      </v-tabs>
      <slot></slot>
      <portal-target name="app.tab:content" slim></portal-target>
      <v-tabs-items v-model="current">
        <v-tab-item
          v-for="item in menus"
          :id="item.id"
          :key="item.key"
          :value="item.id"
          transition="false"
        >
          <slot name="item" v-bind="{ item }">
            <app-mode :name="item.mode">
              <component
                :is="item.component"
                :item="item"
              ></component>
            </app-mode>
          </slot>
        </v-tab-item>
      </v-tabs-items>
      <slot name="footer"></slot>
    </app-content>
  </div>
</template>

<script>
import AppTabsMenuRegistrar from '@core/registrars/AppTabsMenuRegistrar';
import queryString from 'querystring';
import { has, kebabCase } from 'lodash';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'grey lighten-3',
    },
  },

  computed: {
    current: {
      get () {
        return kebabCase(this.$route.query.tab);
      },
      set (current) {
        if (this.$route.query.tab) {
          const currentTab = (current ?? '').toString();
          const query = queryString.parse(currentTab.replace(/(\/(\w+)\?)/g, ''));
          this.$router.replace({ query }).catch(() => {});
        }
      },
    },

    menus () {
      return AppTabsMenuRegistrar.from(this.name).all();
    },
  },

  methods: {
    onTabClick (item, e) {
      if (has(item, 'onClick')) {
        e.preventDefault();
        item.onClick(this.$store);
      }
    },
  },
};
</script>
