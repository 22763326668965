var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"image-preview ov-hidden",attrs:{"flat":"","tile":"","color":"transparent"}},[_vm._t("top"),_c('v-card',{staticClass:"image-preview__window ov-hidden",attrs:{"height":_vm.height,"color":_vm.color,"flat":"","tile":"","width":"100%"},on:{"scroll":_vm.onScroll}},[(_vm.loading)?_c('panzoom-image-thumbnail-loading-state',{attrs:{"height":_vm.height}},[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-center":""}},[_vm._t("loading",[_c('div',[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("mdi-loading mdi-spin")]),_c('span',{staticClass:"white--text",domProps:{"textContent":_vm._s(_vm.$trans('Loading preview'))}})],1)])],2)],1):_vm._e(),_c('div',{ref:"image-preview",staticClass:"image-preview__container pa-1",attrs:{"title":"image preview container"},on:{"wheel":_vm.onMouseWheel,"dblclick":function($event){$event.preventDefault();return _vm.onDblclick($event)}}},[_c('div',{staticClass:"image-preview__inner-container"},[_vm._t("default")],2)])],1),(_vm.showZoomBar)?[_c('v-fade-transition',[_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"image-preview__zoombar ma-2",attrs:{"column":""}},[_vm._t("zoom-bar",[_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"disabled":_vm.zoomInBtnDisabled,"min-width":"32","width":"32","min-height":"32","height":"32","title":"button zoom in"},on:{"click":_vm.zoomIn}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Zoom in'))}})]),_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"disabled":_vm.zoomOutBtnDisabled,"min-width":"32","width":"32","min-height":"32","height":"32","title":"button zoom out"},on:{"click":_vm.zoomOut}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Zoom out'))}})]),_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"disabled":_vm.zoomResetBtnDisabled,"min-width":"32","width":"32","min-height":"32","height":"32","title":"button zoom reset"},on:{"click":_vm.zoomReset}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-magnify-scan")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Reset zoom'))}})])]),_vm._t("zoom-bar.append")],2)],1)]:_vm._e(),(_vm.showSlider)?[_c('v-fade-transition',[_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"image-preview__slider ma-4 px-4 text-center",attrs:{"align-center":"","justify-center":""}},[_c('v-card',{staticClass:"image-preview__slider-card mx-auto rounded",attrs:{"elevation":"1","width":"80%"},on:{"wheel":function($event){$event.preventDefault();return _vm.onMouseWheel($event)}}},[_c('v-card-text',{staticClass:"py-1 px-2"},[_c('v-slider',{attrs:{"title":"zoom slider","max":"200","min":"1","hide-details":""},model:{value:(_vm.zoom),callback:function ($$v) {_vm.zoom=$$v},expression:"zoom"}})],1)],1)],1)],1)]:_vm._e()],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }