<template>
  <div class="mt-10">
    <v-alert v-if="billing.hasErrors()" outlined text type="error" dismissible>
      <span
        class="login-error-message"
        v-text="billing.getErrors('message', 'Something went wrong')"
      ></span>
    </v-alert>
    <template v-if="billing.hasSubscription()">
      <portal-target name="profile:stacked"></portal-target>
      <v-simple-table class="mt-3">
        <tbody>
          <tr>
            <th><span v-html="$trans('Subscription start date')"></span></th>
            <th><span v-html="$trans('Subscription end date')"></span></th>
          </tr>
          <tr v-for="(item, i) in billing.items" :key="i">
            <td><p class="mb-0" v-html="item.started"></p></td>
            <td><p class="mb-0" v-html="item.expired"></p></td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
    <template v-else>
      <billing-overview-tab-item-empty-state></billing-overview-tab-item-empty-state>
    </template>
  </div>
</template>

<script>
import Billing from '@/modules/Billing/resources/Billing';

export default {
  name: 'BillingOverviewTabItem',

  data: () => ({
    billing: new Billing,
  }),

  computed: {
    billingInfo () {
      return this.billing.meta.billingInfo;
    },
  },

  created () {
    this.billing.listBillingInfo();
  },
};
</script>
