import DataResource from '@core/resources/DataResource';
import { API_PER_PAGE_ALL } from '@config/api';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';
import HeatmapOverlay from 'leaflet-heatmap';
import { GET_WEBGIS_LAYERS_URL } from '@/modules/Dashboard/api/webgis';
import { toGeoJson, getCoordinateFromCardinalCoordinate } from '@/modules/Dashboard/utils/map';
import {
  MAP_CENTER,
  MAP_LAYER_URL,
  Z_INDEX,
  MAX_ZOOM,
  ZOOM,
  BACKGROUND_WELL_OPTIONS,
  SELECTED_WELL_OPTIONS,
  RESULTS_WELL_OPTIONS,
  POLYGON_HIGHLIGHT_OPTIONS,
  POLYGON_DASHED_HIGHLIGHT_OPTIONS,
  HEATMAP_CONFIG,
  BOUNDS,
  DEFAULT_FIT_BOUNDS_PADDING,
} from '@/modules/Dashboard/config/maps';
import { GET_WELLS_INFO_URL } from '@/modules/Dashboard/api/wells';
import { GET_GEOMETRY } from '@/modules/Dashboard/api/search';
import { isEmpty, chunk } from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
import domtoimage from 'dom-to-image';

export default class Map extends DataResource {
  constructor (options = {}) {
    super(options);
    this.open = false;
    this.map = undefined;
    this.id = options.id || 'map';
    this.item = options.item || {};
    this.zoom = options.zoom || 4;
    this.maxZoom = options.maxZoom || 21;
    this.minZoom = options.minZoom || 0;
    this.coordinates = { longitude: options.longitude, latitude: options.latitude };
    this.axios.defaults.timeout = 120000;
    this.setupAdditionalLayerMethods();
    this.L = L;
    this.events = {
      onMapLoad: () => {},
      onMapLoaded: e => this.onMapLoaded(e),
      onDrawCreated: () => {},
      ...options.events,
    };
  }

  onMapLoaded () {
    console.log('[map]', 'Loaded');
  }

  initialize () {
    this.startLoading();

    if (!document.querySelector(`#${this.id}`)) {
      return;
    }

    if (this.route.name !== 'dashboard') {
      this.remove();
    }

    this.map = L.map(this.id, {
      zoomControl: false,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
    });

    this.drawnItems = L.featureGroup();
    this.events.onMapLoad(this.map);

    L.control.scale().addTo(this.map);

    this.getWebGISLayers();

    this.setLayers();

    this.setBackgroundMarkers();

    this.backgroundLayer = L.featureGroup();
    this.map.addLayer(this.backgroundLayer);
    this.selectedLayer = L.featureGroup();
    this.map.addLayer(this.selectedLayer);
    this.resultsLayer = L.featureGroup();
    this.map.addLayer(this.resultsLayer);
    this.heatLayer = new HeatmapOverlay(HEATMAP_CONFIG);
    this.map.addLayer(this.heatLayer);

    this.map.addLayer(this.drawnItems);
    this.drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        circlemarker: false,
        circle: false,
        marker: false,
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          clickable: true,
          drawError: {
            color: '#e1e100', // Color the shape will turn when intersects
            message: '<strong>Oh snap!<strong> you can\'t draw that!', // Message that will show when intersect
          },
          shapeOptions: {
            weight: 2,
            dashArray: '1 4 3 1',
            color: 'steelblue',
            fill: true,
            fillColor: '#fcfdff',
            fillOpacity: 0.3,
          },
        },
        rectangle: {
          shapeOptions: {
            clickable: true,
            weight: 2,
            dashArray: '1 4 3 1',
            color: 'steelblue',
            fill: true,
            fillColor: '#fcfdff',
            fillOpacity: 0.3,
          },
        },
      },
      edit: {
        featureGroup: this.drawnItems,
        edit: false,
      },
    });

    this.createPolygonDrawHandler();
    this.createRectangleDrawHandler();

    this.map.on('draw:created', e => {
      const drawnLayer = e.layer;

      if (this.drawnItems !== undefined) {
        this.drawnItems.clearLayers();
        this.drawnItems.addLayer(drawnLayer);
        const geoPolygon = this.makeGeoFilterFlat(drawnLayer) ?? '';
        const geoFilterType = this.getGeoFilterType() ?? '';
        this.store.dispatch('search/setHasDrawnItems', true, { root: true });
        this.store.dispatch('search/setGeoFilter', geoPolygon, { root: true });
        this.store.dispatch('search/setGeoFilterType', geoFilterType, { root: true });
        this.store.dispatch('search/setShowSpatialFilter', true, { root: true });
        this.store.dispatch('search/setShowSpatialFilterButton', true, { root: true });

        const bounds = drawnLayer.getBounds();
        if (!isEmpty(bounds)) {
          this.map.setView(bounds.getCenter(), ZOOM);
        }

        this.events.onDrawCreated(e, geoPolygon);
      }

      this.rectangleDrawHandler.disable();
      this.polygonDrawHandler.disable();
    });

    this.setupMeasureControl();

    this.map.setView(MAP_CENTER, ZOOM);

    this.events.onMapLoaded(this.map);

    this.updateMap(this.items, this.search);

    this.stopLoading();
  }

  reInitialize () {
    this.remove();
    this.initialize();
  }

  async screenshot () {
    this.startLoading();
    const container = document.getElementById(this.id);
    const blob = await domtoimage.toBlob(container, {
      height: container.offsetHeight,
      width: container.offsetWidth,
    });
    const hash = moment().format('YYYY-MM-DD-HHmmss');
    const query = this.route.query.q ?? 'map';
    const name = `map-area-snapshot-${query}-${hash}.png`;
    FileSaver.saveAs(blob, name);
    this.stopLoading();
  }

  setMap (map) {
    this.map = map;

    return this;
  }

  getMap () {
    return this.map;
  }

  getGeoFilter () {
    return this.route.query.geo_polygon;
  }

  getGeoFilterType () {
    return this.store.getters['search/geoFilterType'];
  }

  updateMap (items, search) {
    if (this.resultsLayer) {
      this.resultsLayer.clearLayers();
    }

    if (!isEmpty(search)) {
      this.setResultMarkers(items, true);
    }
  }

  setLayers () {
    L.tileLayer(
      MAP_LAYER_URL, {
        maxZoom: MAX_ZOOM,
        zIndex: Z_INDEX,
        center: MAP_CENTER,
      },
    ).addTo(this.map);
  }

  async setBackgroundMarkers () {
    const params = {
      projects_list: this.getProjectIds(),
      order_by: 'well_name',
      page_size: API_PER_PAGE_ALL,
      page: 1,
      q: '',
    };

    const { data } = await this.axios.get(GET_WELLS_INFO_URL, { params });
    const features = toGeoJson(data.data);

    features.map(feature => ({ ...feature, ...feature.properties })).forEach(feature => {
      this.setBackgroundMarkerWithPopup(feature);
    });

    if (this.backgroundLayer) {
      this.backgroundLayer.bringToBack();

      if (!isEmpty(this.backgroundLayer.getBounds())) {
        this.map.fitBounds(this.backgroundLayer.getBounds(), {
          padding: DEFAULT_FIT_BOUNDS_PADDING,
        });
      }
    }
  }

  async setMarkers (items) {
    const params = {
      page_size: items.length, page: 1, order_by: 'well_name', q: '',
    };
    const { data } = await this.axios.get(GET_WELLS_INFO_URL, { params });
    const features = toGeoJson(data.data);

    features.map(feature => ({ ...feature, ...feature.properties })).forEach(feature => {
      this.setBackgroundMarkerWithPopup(feature);
    });

    this.backgroundLayer.bringToBack();
  }

  setResultMarkers (items, fitToBounds = false) {
    if (this.resultsLayer) {
      this.resultsLayer.clearLayers();
    }

    const features = toGeoJson(items);

    features.map(feature => ({ ...feature, ...feature.properties })).forEach(feature => {
      this.setResultMarkerWithPopup(feature);
    });

    const bounds = this.resultsLayer?.getBounds() ?? false;
    const ne = bounds?._northEast ?? false;

    if (fitToBounds && ne) {
      setTimeout(() => {
        try {
          this.map.fitBounds(bounds);
          this.resultsLayer.bringToFront();
        } catch (error) {
          console.log(error);
        }
      }, 600);
    } else if (ne) {
      setTimeout(() => {
        try {
          const center = bounds?.getCenter();
          if (center !== undefined) {
            this.map.setView(center, 6);
          }
        } catch (error) {
          console.log(error);
        }
      }, 600);
    }
  }

  async setWhiteCircleCoordinates ({ lat, lng, meta }) {
    if (this.selectedLayer) {
      this.selectedLayer.clearLayers();
    }

    this.coordinates = {
      lat: getCoordinateFromCardinalCoordinate(lat),
      lng: getCoordinateFromCardinalCoordinate(lng),
    };

    if (this.map) {
      this.map.setView([
        this.coordinates.lat,
        this.coordinates.lng,
      ]);
    }

    if (this.store.getters['search/geoFilterTypeIsWell']) {
      const layer = L.circleMarker(this.coordinates, {
        stroke: true,
        radius: 13,
        weight: 2,
        fill: true,
        fillColor: '#09d034',
        color: 'white',
        fillOpacity: 0,
        riseOnHover: true,
      });

      if (this.selectedLayer) {
        this.selectedLayer.addLayer(layer);
        this.selectedLayer.bringToFront();
      }
    }

    if (this.store.getters['search/geoFilterTypeIsBasin']) {
      this.setPolygonCoordinates({ meta });
    }

    if (this.store.getters['search/geoFilterTypeIsCountry']) {
      this.setPolygonCoordinates({ meta });
    }
  }

  async setPolygonCoordinates ({ meta }) {
    if (this.selectedLayer) {
      this.selectedLayer.clearLayers();
    }

    if (this.store.getters['search/geoFilterTypeIsBasin']) {
      const basinId = meta.item.basin_id?.[0];
      const style = {
        color: 'white',
        fillColor: 'rgba(255,255,255,0)',
        stroke: true,
        weight: 2,
        fillOpacity: 0,
        opacity: 1,
      };
      const polygonGeojson = this.createGeojsonLayer(style);
      await this.createFeature(basinId, 'basin', polygonGeojson, false);
      this.selectedLayer.addLayer(polygonGeojson);
    }

    if (this.store.getters['search/geoFilterTypeIsCountry']) {
      const countryId = meta.item.country_id?.[0];
      const style = {
        color: 'white',
        fillColor: 'rgba(255,255,255,0)',
        stroke: true,
        weight: 2,
        fillOpacity: 1,
        opacity: 1,
      };
      const polygonGeojson = this.createGeojsonLayer(style);
      await this.createFeature(countryId, 'country', polygonGeojson, false);
      this.selectedLayer.addLayer(polygonGeojson);
    }

    const bounds = this.selectedLayer && this.selectedLayer.getBounds();
    if (!isEmpty(bounds)) {
      this.map.fitBounds(bounds);
    }
  }

  setCoordinatesFromResultLayer ({ lat, lng, properties }, color = 'red', bringToFront = true) {
    this.coordinates = {
      lat: getCoordinateFromCardinalCoordinate(lat),
      lng: getCoordinateFromCardinalCoordinate(lng),
    };

    this.map.setView([
      this.coordinates.lat,
      this.coordinates.lng,
    ]);

    const layer = this.resultsLayer.getLayerMetadataId(properties.id)
      ? this.resultsLayer.getLayerMetadataId(properties.id)
      : this.backgroundLayer.getLayerMetadataId(properties.id);

    if (layer) {
      layer.setStyle({ color, fillColor: color });

      if (bringToFront) {
        layer.bringToFront();

        setTimeout(() => {
          this.resultsLayer.bringToFront();
        }, 10);
      }
    }
  }

  setCoordinates ({ lat, lng, properties }, clearLayer = true) {
    if (this.selectedLayer && clearLayer) {
      this.selectedLayer.clearLayers();
    }

    this.coordinates = {
      lat: getCoordinateFromCardinalCoordinate(lat),
      lng: getCoordinateFromCardinalCoordinate(lng),
    };

    this.map.setView([
      this.coordinates.lat,
      this.coordinates.lng,
    ]);

    const feature = {
      id: properties.id,
      latitude: this.coordinates.lat,
      longitude: this.coordinates.lng,
      well_name: properties.well_name,
      field_name: properties.field_name,
      basin: properties.basin,
    };

    this.setSelectedMarkerWithPopup(feature);

    this.selectedLayer.bringToFront();
  }

  remove () {
    if (this.map !== undefined) {
      this.map.off();
      this.map.remove();
    }

    const container = L.DomUtil.get(this.id);
    if (container != null) {
      container._leaflet_id = null;
    }
  }

  checkContainer (id) {
    const container = L.DomUtil.get(id);
    return container._leaflet_id === undefined;
  }

  setToOpen () {
    this.open = true;
  }

  setToClose () {
    this.open = false;
  }

  async getWebGISLayers () {
    const params = { page_size: 200 };
    const { data } = await this.axios.get(GET_WEBGIS_LAYERS_URL, { params });

    this.baseLayers = data.data.filter(layer => layer.attributes.layer_type === 'base');
    this.store.dispatch('webgis/layers', { type: 'base', items: this.baseLayers });
  }

  async createFeature (geometryId, geometryType, polygonGeojson, fitBounds = true) {
    const geometry = await this.getGeometry(geometryId, geometryType);
    const feature = this.setRedPolygonWithPopup(geometry);

    polygonGeojson.addData(feature);

    if (fitBounds) {
      const bounds = polygonGeojson?.getBounds() ?? false;
      const ne = bounds?._northEast ?? false;

      if (ne) {
        setTimeout(() => {
          try {
            this.map.fitBounds(bounds);
          } catch (error) {
            console.log('Bounds not valid.');
          }
        }, 1000);
      }
    }

    return { feature, polygonGeojson, bounds: L.geoJSON(feature).getBounds() };
  }

  setHighlightFeatures (geometries) {
    this.toggleLoadingState(true);
    this.backgroundLayer.removeFrom(this.map);
    this.resultsLayer.removeFrom(this.map);
    this.selectedLayer.removeFrom(this.map);

    const polygonGeojson = this.createGeojsonLayer();
    this.map.addLayer(polygonGeojson);

    if (geometries.basins.length !== 0) {
      const { basins } = geometries;

      basins.forEach(basin => {
        this.createFeature(basin, 'basin', polygonGeojson);
      });
    } else {
      const { countries } = geometries;

      countries.forEach(country => {
        this.createFeature(country, 'country', polygonGeojson);
      });
    }
    this.toggleLoadingState(false);
  }

  createGeojsonLayer (styleOptions = POLYGON_HIGHLIGHT_OPTIONS) {
    function style () {
      return styleOptions;
    }

    function highlightFeature (e) {
      const layer = e.target;

      layer.setStyle({
        weight: 5,
        color: '#666',
        dashArray: '',
        fillOpacity: 0.7,
      });

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
    }

    function resetHighlight (e) {
      e.target.setStyle({
        color: 'rgb(244,41,65)',
        stroke: true,
        weight: 1,
        fillOpacity: 0.7,
      });
    }

    function zoomToFeature (e) {
      this._map.fitBounds(e.target.getBounds());
    }

    function onEachFeature (feature, layer) {
      layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: zoomToFeature,
      });

      layer
        .bindTooltip(layer.feature.properties.name, {
          direction: 'center',
        })
        .openTooltip();
    }

    const basinLayer = L.geoJSON(null, {
      style,
      onEachFeature,
    });

    return basinLayer;
  }

  setRedPolygonWithPopup (geometry) {
    const feature = {
      type: 'Feature',
      properties: {
        id: geometry.id,
        name: geometry.name,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: geometry?.location?.coordinates,
      },
    };

    return feature;
  }

  getSelectedLayer () {
    return this.selectedLayer;
  }

  clearSelectedLayer () {
    if (this.selectedLayer) {
      this.selectedLayer.clearLayers();
    }

    return this;
  }

  removeLayer (layer) {
    if (this.map) {
      this.map.removeLayer(layer);
    }

    return this;
  }

  setRedMarkerWithPopup (data) {
    const {
      layer, popup, latLng, map,
    } = this.setMarkerWithPopup(data, 'red');
    const text = `<h3>${data.well_name}</h3><div class="muted--text caption">${data.field_name}</div><div class="mb-0 muted--text caption">${data.basin}</div>`;

    popup.setContent(text);
    popup.openOn(map);

    this.setStyles(layer, map);

    this.selectedLayer.addLayer(layer);
    this.selectedLayer.bringToFront();
    this.map.addLayer(this.selectedLayer);
    this.map.panTo(latLng);
    this.map.setView(latLng, 7);

    return this;
  }

  setGreenMarkersWithPopup (items) {
    if (!isEmpty(items)) {
      toGeoJson(items)
        .map(feature => ({ ...feature, ...feature.properties }))
        .map(feature => this.setResultMarkerWithPopup(feature));

      this.map.addLayer(this.resultsLayer);
    }

    return this;
  }

  setResultMarkerWithPopup (data) {
    const {
      layer, popup, latLng, map,
    } = this.setMarkerWithPopup(data, '#09d034');

    layer.setStyle(RESULTS_WELL_OPTIONS);

    this.setStyles(layer, map);

    if (this.resultsLayer) {
      this.resultsLayer.addLayer(layer);
    }

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  setSelectedMarkerWithPopup (data, color = 'red') {
    if (this.backgroundLayer) {
      this.backgroundLayer.eachLayer(l => {
        l.setStyle(BACKGROUND_WELL_OPTIONS);
      });
    }

    if (this.resultsLayer) {
      this.resultsLayer.eachLayer(l => {
        l.setStyle(RESULTS_WELL_OPTIONS);
      });
    }

    const {
      layer, popup, latLng, map,
    } = this.setMarkerWithPopup(data, color);

    popup.openOn(map);
    map.panTo(latLng);

    this.selectedLayer.addLayer(layer);

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  setBackgroundMarkerWithPopup (data) {
    const {
      layer, popup, latLng, map,
    } = this.setMarkerWithPopup(data);

    this.setStyles(layer, map);

    if (this.backgroundLayer) {
      this.backgroundLayer.addLayer(layer);
    }

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  setMarkerWithPopup (data, color = 'rgba(88, 88, 88, 0.4)') {
    const latLng = L.latLng(
      getCoordinateFromCardinalCoordinate(data.latitude),
      getCoordinateFromCardinalCoordinate(data.longitude),
    );

    const layer = L.circleMarker(latLng, {
      color,
      fill: true,
      fillColor: color,
      fillOpacity: 1,
      radius: 5,
      riseOnHover: true,
      weight: 0.5,
      metadata: data,
    });

    const link = `<button data-id="${data.id}" data-group="popup-btn" type="button" class="v-btn v-btn--contained theme--light v-size--small mt-4"><span class="v-btn__content">View Document</span></button>`;
    const text = `<h3>${data.well_name}</h3><div class="muted--text caption">${data.field_name}</div><div class="mb-0 muted--text caption">${data.basin}</div>${link}`;
    const popup = this.setPopup(data, text);

    layer.bindPopup(popup, { autoClose: true });
    layer.on('popupopen', popup => {
      const button = popup.popup._container.querySelector('[data-group=popup-btn]');

      if (button) {
        button.addEventListener('click', () => {
          const wellId = button.getAttribute('data-id');
          this.router.push({ name: 'reports.well', query: { wellId, noPageNum: true } });
        }, false);
      }
    });

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  setStyles (layer, map) {
    layer.on('click', () => {
      this.backgroundLayer.eachLayer(l => {
        l.setStyle(BACKGROUND_WELL_OPTIONS);
      });

      this.resultsLayer.eachLayer(l => {
        l.setStyle(RESULTS_WELL_OPTIONS);
      });

      layer.setStyle(SELECTED_WELL_OPTIONS);
      map.panTo(layer.getLatLng());

      this.selectedLayer.addLayer(layer);
    });
  }

  setPopup (data, text) {
    const popup = L.popup().setLatLng(L.latLng(
      getCoordinateFromCardinalCoordinate(data.latitude),
      getCoordinateFromCardinalCoordinate(data.longitude),
    ));

    popup.setContent(text);

    return popup;
  }

  createLink (text) {
    const button = L.DomUtil.create('button', '');
    button.setAttribute('type', 'button');
    button.innerHTML = text;

    return button;
  }

  getCurrentZoomLevel () {
    return this.zoom;
  }

  getMaxZoom () {
    return this.map && this.map.getMaxZoom();
  }

  getMinZoom () {
    return this.map && this.map.getMinZoom();
  }

  getWorldBounds () {
    this.map.worldBounds = [ BOUNDS.ll, BOUNDS.ur ];
    return this.map.worldBounds;
  }

  zoomIn () {
    this.zoom = this.map.getZoom() + 1;
    this.map.setZoom(this.zoom);
  }

  zoomOut () {
    this.zoom = this.map.getZoom() - 1;
    this.map.setZoom(this.zoom);
  }

  isZoomedInMax () {
    return this.getCurrentZoomLevel() >= this.getMaxZoom();
  }

  isZoomedOutMax () {
    return this.getCurrentZoomLevel() <= this.getMinZoom();
  }

  async getGeometry (geometryId, geometryType) {
    const params = {
      geometry_id: geometryId,
      geometry_type: geometryType,
    };

    const { data } = await this.axios.get(GET_GEOMETRY, {
      params,
      timeout: this.getAxiosTimeout(),
      ...this.getCancelToken(),
    });
    const geometry = data.data;

    return geometry;
  }

  createPolygonDrawHandler () {
    this.polygonDrawHandler = new L.Draw.Polygon(this.map, this.drawControl.options.draw.polygon);
  }

  getPolygonDrawHandler () {
    return this.polygonDrawHandler;
  }

  createRectangleDrawHandler () {
    // eslint-disable-next-line max-len
    this.rectangleDrawHandler = new L.Draw.Rectangle(this.map, this.drawControl.options.draw.rectangle);
  }

  getRectangleDrawHandler () {
    return this.rectangleDrawHandler;
  }

  generateGeoFilterFromFlatPolygon (flatPolygon) {
    return chunk(flatPolygon.split(','), 2);
  }

  setGeoFilter (layer) {
    const geoFilter = layer.getLatLngs()
      .flatMap(latlngObj => latlngObj.map(latlng => [ latlng.lng, latlng.lat ])).flat();

    return this.store.dispatch('search/setGeoFilter', geoFilter);
  }

  makeGeoFilterFlat (layer) {
    return layer.getLatLngs()
      .flatMap(latlngObj => latlngObj.map(latlng => [ latlng.lng, latlng.lat ]))
      .flat().toString();
  }

  clearOverlayLayers () {
    if (this.resultsLayer !== undefined) {
      this.resultsLayer.clearLayers();
    }

    if (this.selectedLayer !== undefined) {
      this.selectedLayer.clearLayers();
    }

    if (this.heatLayer !== undefined) {
      this.heatLayer.setData({ data: [], max: 0 });
    }
  }

  fitToBackgroundLayer () {
    const bounds = this.backgroundLayer.getBounds();
    this.map.fitBounds(bounds);
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }

  async invalidateSize () {
    this.startLoading();
    setTimeout(() => this.map.invalidateSize(), 400);
    await this.wait(400);
    this.stopLoading();
  }

  setupAdditionalLayerMethods () {
    L.FeatureGroup.include({
      getFirstLayer () {
        return Object.values(this._layers)?.[0];
      },
      getLayerMetadataId (id) {
        return Object.values(this._layers).find(i => i.options?.metadata?.id === id);
      },
    });
  }

  getPolygonDashedHighlightOptions () {
    return POLYGON_DASHED_HIGHLIGHT_OPTIONS;
  }

  setupMeasureControl () {
    this.measureControl = new L.Control.Measure({
      primaryLengthUnit: 'meters',
      secondaryLengthUnit: 'kilometers',
      primaryAreaUnit: 'sqmeters',
      secondaryAreaUnit: 'sqfeet',
      activeColor: 'var(--v-secondary-base)',
      completedColor: 'var(--v-secondary-base)',
    });
    this.measureControl.addTo(this.map);
  }

  startMapMeasureControl () {
    this.measureControl._startMeasure();
  }

  cancelMapMeasureControl () {
    this.measureControl._finishMeasure();
  }

  finishMapMeasureControl () {
    this.measureControl._handleMeasureDoubleClick();
  }

  getMeasurementResults () {
    return this.measureControl.$results?.innerHTML;
  }
}
