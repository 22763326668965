<template>
  <v-layout class="align-center">
    <v-icon
      v-if="icon" small
      left
      v-text="icon"
    ></v-icon>
    <v-icon
      v-else-if="item.icon"
      small left
      v-text="item.icon"
    ></v-icon>
    <a
      target="_blank"
      class="link--text td-underline"
      @click="onClick"
      v-html="item.text"
    ></a>
  </v-layout>
</template>

<script>
export default {
  name: 'ReportDownloadLinkPrompt',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    icon: {
      type: String,
      default: null,
    },
  },

  methods: {
    async onClick () {
      this.$emit('click', this.item);
    },
  },
};
</script>
