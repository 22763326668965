<template>
  <v-layout :class="{ 'layer-control--dense': dense }" column class="layer-control">
    <v-card
      :width="width"
      max-width="400"
      class="layer-control__container"
    >
      <v-sheet class="pa-4 primary lighten-2">
        <v-text-field
          v-model="search"
          :label="$trans('Search layers')"
          :dense="dense"
          clearable dark
          flat hide-details
          solo-inverted
          clear-icon="mdi-close-circle-outline"
          @keyup="searchTreeview"
        ></v-text-field>
      </v-sheet>
      <v-card
        v-bind="defaultAttrs"
        class="ov-y-auto"
      >
        <v-card-text class="pa-0">
          <h4 class="pl-4 pt-4" v-text="$trans('Basemaps')"></h4>
          <v-radio-group
            v-model="basemaps.current"
            dense
            class="pr-4 pl-4"
            @change="onBasemapsChange"
          >
            <v-radio
              v-for="(map, i) in basemaps.items"
              :key="i" :value="map.value"
              hide-details
            >
              <template v-slot:label>
                <span v-text="map.text"></span>
              </template>
            </v-radio>
          </v-radio-group>

          <v-divider></v-divider>

          <h4 class="pl-4 pt-4" v-text="$trans('Overlays')"></h4>
          <v-treeview
            ref="overlays-treeview-list"
            v-model="layers.current"
            :items="layers.items"
            :search="search"
            :dense="dense"
            selectable
            hoverable multiple-active
            class="mb-4"
            @input="onLayersChange"
          >
            <template v-slot:label="{ item }">
              <span :title="item.name" v-text="item.name"></span>
            </template>
          </v-treeview>

          <slot></slot>
        </v-card-text>
      </v-card>
    </v-card>
  </v-layout>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  name: 'MapGisLayerControlCard',

  props: {
    basemaps: {
      type: Object,
      required: true,
    },

    layers: {
      type: Object,
      required: true,
    },

    width: {
      type: [ String, Number ],
      default: null,
    },

    dense: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    search: null,
  }),

  computed: {
    defaultAttrs () {
      return {
        'max-height': '500px',
        ...this.$attrs,
      };
    },
  },

  methods: {
    searchTreeview () {
      if (isEmpty(this.search)) {
        this.collapseTreeview();
      } else {
        this.expandTreeview();
      }
    },

    expandTreeview () {
      if (this.$refs['overlays-treeview-list']) {
        this.$refs['overlays-treeview-list'].updateAll(true);
      }
    },

    collapseTreeview () {
      if (this.$refs['overlays-treeview-list']) {
        this.$refs['overlays-treeview-list'].updateAll(false);
      }
    },

    onBasemapsChange (basemap) {
      this.$emit('change', { basemap });
      this.$emit('change:basemaps', basemap);
    },

    onLayersChange (layers) {
      this.$emit('change', { layers });
      this.$emit('change:layers', layers);
    },
  },
};
</script>

<style lang="scss">
.layer-control {
  &--dense {
    &,
    p,
    span {
      font-size: 0.9rem;
    }

    h4 {
      font-size: 0.95rem;
    }
  }
}
</style>
