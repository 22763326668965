<template>
  <v-container>
    <v-row>
      <v-col cols="auto" class="ml-auto">
        <v-switch
          v-model="$vuetify.theme.dark"
          :label="$trans('Dark mode')"
          @change="setDarkMode"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(theme, name) in themes" :key="name">
        <v-card
          :color="current.name === name ? 'primary' : 'card'"
          :dark="current.name === name"
          @click="setTheme({ theme, name })"
        >
          <v-card-title>
            {{ name | titleCase }}&nbsp;
            {{ current.name === name ? 'is active' : null }}
          </v-card-title>
          <v-card-text>
            <v-card light class="mb-4">
              <v-card-text>
                <p class="black--text" v-text="$trans('Light')"></p>
                <v-layout>
                  <app-theme-color-badge
                    :color="theme.light.primary"
                    :title="$trans('primary')"
                    class="ma-1"
                  ></app-theme-color-badge>
                  <app-theme-color-badge
                    :color="theme.light.secondary"
                    :title="$trans('secondary')"
                    class="ma-1"
                  ></app-theme-color-badge>
                  <app-theme-color-badge
                    :color="theme.light.accent"
                    :title="$trans('accent')"
                    class="ma-1"
                  ></app-theme-color-badge>
                </v-layout>
              </v-card-text>
            </v-card>

            <v-card dark class="mb-4">
              <v-card-text>
                <p v-text="$trans('Dark')"></p>
                <v-layout>
                  <app-theme-color-badge
                    :color="theme.dark.primary"
                    :title="$trans('primary')"
                    class="ma-1"
                  ></app-theme-color-badge>
                  <app-theme-color-badge
                    :color="theme.dark.secondary"
                    :title="$trans('secondary')"
                    class="ma-1"
                  ></app-theme-color-badge>
                  <app-theme-color-badge
                    :color="theme.dark.accent"
                    :title="$trans('accent')"
                    class="ma-1"
                  ></app-theme-color-badge>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { startCase } from 'lodash';

export default {
  name: 'ThemePickerCardGroup',

  filters: {
    titleCase (text) {
      return startCase(text);
    },
  },

  data: () => ({
    dark: false,
  }),

  computed: {
    ...mapGetters({
      themes: 'theme/themes',
      current: 'theme/current',
    }),
  },

  methods: {
    setTheme ({ theme, name }) {
      this.$store.dispatch('theme/set', { name, ...theme });
    },

    setDarkMode (isDark) {
      this.$store.dispatch('theme/dark', isDark);
    },
  },
};
</script>
