// export const DEFAULT_INTUITION_PROJECT_ID = 'df79cd4f-88ec-4777-bf4d-e023c687736a'; // AU
export const DEFAULT_INTUITION_PROJECT_ID = '24086d54-d66d-493d-8c34-7018b840b1a3'; // PM
export const PARTICLE_POSITION_MULTIPLIER = 1 / 1000;
export const PARTICLE_SIZE = 5;
export const DEFAULT_IMAGE_CLASSES = [ 'Thin Section', 'Core', 'Map', 'Seismic', 'Well Plot' ];
export const IMAGE_CLASSES_TABS = [
  {
    text: 'Thin Section',
    chip: 0,
    active: true,
    eager: true,
  },
  {
    text: 'Core',
    chip: 0,
    active: false,
    eager: true,
  },
  {
    text: 'Map',
    chip: 0,
    active: false,
    eager: true,
  },
  {
    text: 'Seismic',
    chip: 0,
    active: false,
    eager: true,
  },
  {
    text: 'Stratiggraphic Structural Elements',
    chip: 0,
    active: false,
    eager: true,
  },
];

export const TWO_D_AXES = {
  XY: {
    rotationX: 0,
    rotationOrbit: 0
  },
  ZY: {
    rotationX: 0,
    rotationOrbit: -90
  },
  ZX: {
    rotationX: -90,
    rotationOrbit: 90
  },
}

export default Object.freeze({
  DEFAULT_INTUITION_PROJECT_ID,
  DEFAULT_IMAGE_CLASSES,
  IMAGE_CLASSES_TABS,
  PARTICLE_POSITION_MULTIPLIER,
  PARTICLE_SIZE,
  TWO_D_AXES
});
