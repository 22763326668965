<template>
  <app-content>
    <meta-tag :title="$trans('Change Subscription Plan')"></meta-tag>
    <app-back-btn></app-back-btn>

    <v-row>
      <v-col cols="12" lg="8" class="mx-auto">
        <validation-observer
          ref="observer"
          v-slot="{ handleSubmit, invalid }"
          slim
        >
          <v-form
            ref="form"
            enctype="multipart/form-data"
            @submit.prevent="handleSubmit(submit)"
          >
            <!-- Radio validator -->
            <validation-provider
              name="plan"
              rules="required"
            >
              <input
                :value="current"
                name="plan"
                type="hidden"
                class="d-none"
              >
            </validation-provider>
            <!-- Radio validator -->

            <h1 v-text="$trans('Change Subscription Plan')"></h1>
            <v-alert v-if="plan.hasErrors()" outlined text type="error" dismissible>
              <span
                class="login-error-message"
                v-text="plan.getErrors('message', 'Something went wrong')"
              ></span>
            </v-alert>

            <div class="my-10">
              <v-item-group v-model="current">
                <v-item
                  v-for="(plan, i) in filteredPlans" :key="i"
                  v-slot="{ active, toggle }"
                >
                  <div
                    :class="{
                      'plan__item--active': active,
                    }"
                    class="plan__item mb-3"
                  >
                    <v-card
                      :disabled="plan.disabled"
                      class="pa-4"
                      @click="toggle"
                    >
                      <v-layout>
                        <div>
                          <v-card-title>
                            <h2
                              v-text="$trans_choice('billing.plan.title', plan.isCurrent, {
                                title: plan.title,
                              })"
                            ></h2>
                          </v-card-title>
                          <v-card-text>
                            <h3 v-html="plan.price"></h3>
                            <div v-html="plan.priceDetail"></div>
                          </v-card-text>
                        </div>
                        <v-spacer></v-spacer>
                        <div v-if="plan.logo" class="ma-4">
                          <img
                            :src="plan.logo"
                            :alt="plan.title"
                            width="235" height="auto"
                          >
                        </div>
                      </v-layout>
                      <v-card-text v-if="plan.text" class="pt-0">
                        <div v-html="plan.text"></div>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-item>
              </v-item-group>

              <v-layout align-center>
                <template v-if="billingInfo.isPaidSubscription">
                  <template v-if="billingInfo.isSubscriptionActive">
                    <a
                      class="ml-3 s-link"
                      href="#"
                      @click.prevent="cancelSubscription"
                    ><span v-text="$trans('Cancel subscription')"></span></a>
                  </template>
                  <template v-else-if="billingInfo.isSubscriptionForCancellation">
                    <a
                      class="ml-3 s-link"
                      href="#"
                      @click.prevent="resumeSubscription"
                    ><span v-text="$trans('Resume subscription')"></span></a>
                  </template>
                </template>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="billing.isLoadingOr(invalid)"
                  :loading="billing.loading"
                  x-large
                  color="secondary"
                  class="ml-3"
                  @click="submit"
                ><span v-text="$trans('Continue')"></span></v-btn>
              </v-layout>
            </div>

          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import Plan from '@/modules/Billing/resources/Plan';
import Cart from '@/modules/Billing/resources/Cart';
import Billing from '@/modules/Billing/resources/Billing';
import { mapGetters } from 'vuex';

export default {
  layout: 'admin',

  meta: {
    permissions: [ 'payment' ],
  },

  data: () => ({
    current: null,
    cart: new Cart,
    plan: new Plan,
    billing: new Billing,
    hasCurrentPlan: false,
  }),

  computed: {
    ...mapGetters({
      billingInfo: 'billing/billingInfo',
    }),
    filteredPlans() {
      const filtered = this.plan.items.map((obj) => {
        if(!obj.isCurrent){
          return obj;
        }
        if (!this.hasCurrentPlan){
          this.hasCurrentPlan = true;
          return obj
        }
        obj.isCurrent = false;
        return obj;
      })
      const head = filtered.shift();

      return [...filtered, head];
    },
  },

  mounted () {
    this.plan.list();
  },

  methods: {
    isCurrentPlan (plan) {
      return this.billingInfo.subscriptionType.toLowerCase() === plan.code.toLowerCase();
    },

    isDisabled (plan) {
      return !plan.selectable || this.isCurrentPlan(plan);
    },

    getPlan () {
      return this.filteredPlans[this.current];
    },

    async submit () {
      try {
        await this.cart.add(this.getPlan());
        this.$router.push({ name: 'billing.details' });
      } catch (e) {
       this.cart.setErrors(e?.response?.data ?? e);
      }
    },

    cancelSubscription () {
      this.plan.cancelSubscription();
    },

    resumeSubscription () {
      this.plan.resumeSubscription();
    },
  },
};
</script>

<style lang="scss">
.plan__item {
  border: 4px solid transparent;
  border-radius: 5px;

  &--current {
    border: 4px solid var(--v-primary-base);
  }

  &--active {
    border: 4px solid var(--v-secondary-base);
  }
}
</style>
