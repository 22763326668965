export default [
  {
    name: 'workflows.post-mortem',
    title: 'Post Mortem',
    description: 'View Post Mortem page',
    parent: 'workflows',
    permission: 'pod_post_mortem',
    sort: 1,
  },
];
