import { $app } from '@/config/app';
import { ACCOUNT_TYPE_BOLT } from '@/modules/Billing/enums/AccountTypes';
import Profile from '@/modules/User/resources/Profile';
import AppTabsMenuRegistrar from '@core/registrars/AppTabsMenuRegistrar';

AppTabsMenuRegistrar.register([
  {
    id: 'api',
    to: { name: 'about', query: { tab: 'api' } },
    text: "Using the API's",
    location: 'about',
    permission: null,
    key: 'api',
    sort: 3,
    component: () => import('@/modules/Datawarehouse/components/About/AboutApiTabItem'),
    isViewable () {
      const user = (new Profile).getUserFromStore();
      return user.isAccountType(ACCOUNT_TYPE_BOLT) && $app.isAppMode('DATA_ATELIER');
    },
  },
]);
