import StoreState from '@core/resources/StoreState';

export const state = new StoreState({
  isOn: false,
  label: 'Spatial Filter',
  isTriggered: 0,
  trigger () {
    this.isTriggered++;
  },
  toggleOn () {
    this.isOn = !this.isOn;
  },
  setOn (isOn) {
    this.isOn = isOn;
  },
});

export const getters = {
  ...StoreState.mapDefaultGetters(),
  isOn: state => state.isOn,
  isTriggered: state => state.isTriggered,
};

export const mutations = {
  ...StoreState.mapDefaultMutations(),

  TOGGLE_SPATIAL_FILTER (state) {
    state.setShown(!state.shown);
  },

  SHOW_SPATIAL_BUTTON (state) {
    state.setOn(true);
  },

  HIDE_SPATIAL_BUTTON (state) {
    state.setOn(false);
  },

  TOGGLE_SPATIAL_BUTTON_ON (state) {
    state.toggleOn();
  },

  SET_SPATIAL_BUTTON_ON (state, isOn = true) {
    state.setOn(isOn);
  },

  SET_SPATIAL_BUTTON_SHOWN (state, isShown) {
    state.setShown(isShown);
  },

  TRIGGER_SPATIAL_FILTER (state) {
    state.trigger();
  },
};

export const actions = {
  ...StoreState.mapDefaultActions(),

  toggleSpatialFilter ({ commit }) {
    commit('TOGGLE_SPATIAL_FILTER');
  },

  showSpatialButton ({ commit }) {
    commit('SHOW_SPATIAL_BUTTON');
  },

  hideSpatialButton ({ commit }) {
    commit('HIDE_SPATIAL_BUTTON');
  },

  setSpatialButtonShown ({ commit }, isShown) {
    commit('SET_SPATIAL_BUTTON_SHOWN', isShown);
  },

  toggleSpatialButtonOn ({ commit }) {
    commit('TOGGLE_SPATIAL_BUTTON_ON');
  },

  setSpatialButtonOn ({ commit }, isOn = true) {
    commit('SET_SPATIAL_BUTTON_ON', isOn);
  },

  triggerSpatialFilter ({ commit }) {
    commit('TRIGGER_SPATIAL_FILTER');
  },
};

export default {
  namespaced: true,
  name: 'webgis/spatial',
  state,
  getters,
  mutations,
  actions,
};
