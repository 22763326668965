import AppTabsMenuRegistrar from '@core/registrars/AppTabsMenuRegistrar';

AppTabsMenuRegistrar.register([
  {
    to: { name: 'profile.index' },
    permission: null,
    location: 'profile',
    text: 'Profile',
    key: 'profile',
    sort: 0,
  },
]);
