import { BASE_URL } from '@/config/api';

export const SEARCH_IMAGES_URL = `${BASE_URL}/search/`;
export const FIND_IMAGE_URL = (id, projectId) => `${BASE_URL}/search/?q=(_id:${id}) AND (project_id:${projectId})`;
export const FIND_IMAGES_BY_TAG = `${BASE_URL}/datalake/document-image-thumbnail-tag/`;
export const FIND_IMAGES_BY_DOCREF = docRef => `${BASE_URL}/search/?q=doc_ref:${docRef} AND content_type:nontext`;

export default {
  FIND_IMAGE_URL,
  SEARCH_IMAGES_URL,
  FIND_IMAGES_BY_TAG,
  FIND_IMAGES_BY_DOCREF,
};
