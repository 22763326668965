import DataResource from '@core/resources/DataResource';
import PLANS from '@/modules/Billing/config/plans';
import { GET_PRODUCTS_LIST } from '@/modules/Billing/api/products';
import { POST_BILLING_CANCEL_SUBSCRIPTION_URL, POST_BILLING_RESUME_SUBSCRIPTION_URL } from '@/modules/Billing/api/billing';
import {
  isNumber, has, orderBy, isEmpty,
} from 'lodash';
import { BILLING_STATUS_ACTIVE, BILLING_STATUS_FOR_CANCELLATION } from '@/modules/Billing/enums/BillingStatus';
import { HTTP_OK } from '@core/enums/Response';
import { isURL } from '@/utilities/url';

const PLAN_TYPE_ONE_TIME = 'one_time';

export default class Plan extends DataResource {
  async list () {
    this.flushErrors();
    this.startLoading();

    try {
      const { data } = await this.axios.get(GET_PRODUCTS_LIST);
  
      this.setItems(data.data.prices);
      this.setData(data.data);
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    } finally {
      this.stopLoading();
      this.setupCurrent();
    }
  }

  setupCurrent () {
    this.current = null;
  }

  setItems (items) {
    this.items = orderBy(items.map(item => this.makeItem(item)), 'created');
  }

  makeItem (item) {
    return {
      ...item,
      title: this.getPlanTitle(item),
      text: this.getDescription(item),
      logo: this.getLogo(item),
      price: this.getPlanPrice(item),
      priceDetail: this.getPriceDetail(item),
      disabled: this.getPlanIsDisabled(item),
      isCurrent: this.getPlanIsCurrent(item),
    };
  }

  getPlanTitle (plan) {
    return plan?.nickname;
  }

  getDescription (item) {
    return item?.metadata?.description ?? false;
  }

  getLogo (item) {
    const image = item?.metadata?.image ? `.${item?.metadata?.image}` : '';

    if (isEmpty(image)) {
      return false;
    }

    if (isURL(image)) {
      return image;
    }

    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`@/modules/Billing/assets/logos/logo${image}.png`);
  }

  getPlanPrice (item) {
    if (has(item.metadata, 'display_price')) {
      return item.metadata.display_price;
    }

    if (has(item, 'unit_amount')) {
      if (item.unit_amount === 0) {
        return 'Free';
      }

      const unitAmount = item.unit_amount.toString();
      const amount = `${unitAmount.substring(0, unitAmount.length - 2) }.${ unitAmount.substring(unitAmount.length - 2)}`;
      const amountText = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      return `${item.currency.toUpperCase()} ${amountText}`;
    }

    return 'Fee';
  }

  getPriceDetail (item) {
    if (has(item.metadata, 'price_detail')) {
      return item.metadata.price_detail;
    }

    if (item.type === PLAN_TYPE_ONE_TIME && item.unit_amount === 0) {
      return this.trans('');
    }

    const intervalCount = item?.recurring?.interval_count === 1 ? 'per' : `per ${item?.recurring?.interval_count}`;
    return this.trans(`${intervalCount} ${item?.recurring?.interval}`);
  }

  getPlanIsDisabled (item) {
    const user = this.store.getters['auth/user'];
    const { nickname } = item;
    const accountType = user.data?.account_type?.name?.toString();
    const plans = PLANS.filter(i => !i.selectable).map(i => i.code);
    const tier = (item.metadata?.tier_name ?? item.metadata?.tier_code)
      ?? (nickname?.toLowerCase()?.includes(accountType)
        ? accountType
        : nickname?.toLowerCase()
      );

    // TODO: Remove hardcode
    const isCurrent = this.getPlanIsCurrent(item);
    const isApprentice = tier?.includes('apprentice');

    return !item.active
      || isApprentice
      || isCurrent
      || accountType === tier
      || plans.includes(tier);
  }

  getPlanIsCurrent (item) {
    const billingInfo = this.store.getters['billing/billingInfo'];
    const code = billingInfo?.subscriptionType?.toLowerCase();
    const name = item?.metadata?.tier_code ?? item?.metadata?.tier_name;

    if (name) {
      return name.toLowerCase() === code;
    }

    return item?.nickname?.toLowerCase()?.includes(code);
  }

  isCurrentPlan (plan) {
    const billingInfo = this.store.getters['billing/billingInfo'];

    return billingInfo.subscriptionType.toLowerCase() === plan.code?.toLowerCase();
  }

  getCurrentlySelectedPlan () {
    return this.items[isNumber(this.selected) || 0];
  }

  async cancelSubscription () {
    this.startLoading();
    const { email } = this.store.getters['auth/user'];

    this.$prompt({
      name: 'email',
      rules: `required|email|exact_match:${email}`,
      attrs: {
        outlined: true,
        label: this.trans('Email'),
      },
    }, {
      persistent: true,
      color: 'warning',
      title: this.trans('Cancel your current plan?'),
      text: [
        '<p>Click "Finish Cancellation" below to request to cancel your current subscription plan.</p>',
        '<ul>',
        '<li>A confirmation email will be sent to you once we have processed your Cancellation Request.</li>',
        '<li>You may opt to resume your subscription again at any given time.</li>',
        '</ul>',
        '<br>',
        '<p>Enter your email below to proceed.</p>',
      ],
      buttons: {
        action: {
          color: 'error',
          text: 'Finish Cancellation',
          callback: async store => {
            await store.dispatch('dialog/close');
            await this.requestCancelSubscription(store.getters['dialog/prompt']);
          },
        },
        cancel: {
          text: 'Go Back',
        },
      },
    });

    this.stopLoading();
  }

  async resumeSubscription () {
    this.startLoading();

    this.$dialog({
      persistent: true,
      title: 'Resume Subscription',
      text: [
        '<p>You are about to resume your subscription.</p>',
        '<p>Please click continue to proceed reactivating your subscription.</p>',
      ],
      buttons: {
        action: {
          color: 'secondary',
          text: 'Resume Subscription',
          callback: async store => {
            await this.requestResumeSubscription(store.getters['dialog/prompt']);
            await store.dispatch('dialog/close');
          },
        },
      },
    });

    this.stopLoading();
  }

  async requestCancelSubscription (prompt) {
    this.flushErrors();
    this.startLoading();

    const email = prompt.model;

    try{
      const { data } = await this.axios.post(POST_BILLING_CANCEL_SUBSCRIPTION_URL, { email });

      if (data.data.message) {
        this.$dialog({
          persistent: true,
          color: 'error',
          illustration: () => import('@/modules/Billing/components/Icons/IconManCancelSubscription'),
          title: this.trans('Subscription Cancellation being processed'),
          text: [
            '<p>Please check your email for the cancellation details.</p>',
            '<p>You will be signed out for changes to take effect.</p>',
          ],
          buttons: {
            cancel: {
              show: false,
            },
            action: {
              text: 'Sign me out',
              callback: () => {
                this.router.push({ name: 'auth.logout' });
              },
            },
          },
        });

        this.store.dispatch('billing/updateStatus', BILLING_STATUS_FOR_CANCELLATION);
      }
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
    } finally {
      this.stopLoading();
    }
  }

  async requestResumeSubscription (prompt) {
    this.flushErrors();
    this.startLoading();

    const email = prompt.model;
    try {
      const response = await this.axios.post(POST_BILLING_RESUME_SUBSCRIPTION_URL, { email });

      if (response.data.data.message && response.status === HTTP_OK) {
        this.$dialog({
          persistent: true,
          color: 'secondary',
          illustration: () => import('@/modules/Billing/components/Icons/IconSuccessBalloon'),
          title: this.trans('Subscription resumed'),
          text: [
            '<p>Your subscription will be resumed.</p>',
            '<p>You will be signed out for changes to take effect.</p>',
          ],
          buttons: {
            cancel: { show: false },
            action: {
              text: 'Sign me out',
              callback: () => {
                this.router.push({ name: 'auth.logout' });
              },
            },
          },
        });

        this.store.dispatch('billing/updateStatus', BILLING_STATUS_ACTIVE);
      }
    } catch (e) {
    this.setErrors(e?.response?.data ?? e);
    } finally {
      this.stopLoading();
    }
  }
}
