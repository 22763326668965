<template>
  <div>
    <meta-tag :title="$trans('Datalake')"></meta-tag>

    <file-upload-dialog
      ref="file-upload-dialog"
      @form:close="onFormClose"
      @click:cancel="onCancel"
      @tray:close="onTrayClose"
      @tray:cancelled="onTrayCancelled"
    ></file-upload-dialog>
  </div>
</template>

<script>
export default {
  layout: 'admin',

  beforeRouteLeave (to, from, next) {
    this.$refs['file-upload-dialog'].confirmNavigateAwayOnUploading(to, from, next);
  },

  methods: {
    onFormClose () {
      console.log('form:close');
      this.$router.push({ name: 'datalake' });
    },

    onCancel () {
      console.log('click:cancel');
    },

    onTrayCancelled () {
      console.log('tray:cancelled');
    },

    onTrayClose () {
      console.log('tray:close');
      // this.$router.push({ name: 'datalake' });
    },
  },
};
</script>
