import ActionMenuRegistrar from '@core/registrars/ActionMenuRegistrar';

ActionMenuRegistrar.register([
  {
    type: 'component',
    component: () => import('@/modules/User/components/ActionMenu/ActionMenuAvatar'),
    sort: 1,
  },
  {
    icon: 'mdi-account-outline',
    text: 'Profile',
    to: { name: 'profile' },
    sort: 2,
  },
]);
