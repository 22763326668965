import Search from '@/modules/Search/resources/Search';
import { SOURCETYPE_PUBLICATIONS } from '@/modules/Sourcetray/config/sources';
import { includes } from 'lodash';

export default class Publication extends Search {
  constructor (options = {}) {
    super(options);
  }

  runAggregations () {
    return false;
  }

  setAggregations (aggregations) {
    this.meta.aggregations.setAggregations(aggregations);
  }

  getProjectIDs () {
    const sourceTypes = this.store
      .getters['sourcetray/sources']
      .filter(i => includes([
        SOURCETYPE_PUBLICATIONS,
      ], i.sourceType));

    return `project_id:${sourceTypes?.map(i => i.id).join(',')}`;
  }
}
