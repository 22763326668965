<template>
  <app-content>
    <meta-tag :title="$trans('Search Reports')"></meta-tag>

    <!-- DataSource Tray -->
    <portal-target name="search:sourcetray" slim>
      <data-source-tray-filter
        width="400px"
        @update:selected="changedDataSource"
      ></data-source-tray-filter>
    </portal-target>
    <!-- DataSource Tray -->

    <v-row>
      <v-col cols="12" lg="12">
        <v-row v-if="search.isNotProjectEmpty()">
          <v-col
            :lg="isRightSidebarAreaShown ? 12 : 5"
            cols="12"
          >
            <app-search-field
              v-model="searchModel"
              :loading="search.searching"
              :title="'search well reports'"
              :label="$trans('Search well reports')"
              :placeholder="$trans('Search well reports')"
              autofocus solo
              @click:clear="clearSearch"
              @enter:search="setSearch"
            ></app-search-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            :lg="isRightSidebarAreaShown ? 12 : 5"
            cols="12"
          >
            <v-layout class="mb-2 ov-y-hidden ov-x-auto">
              <!-- Advanced Search Filter -->
              <app-can-view code="search_filter">
                <app-search-advanced-filter-dialog
                  v-model="search.meta.filters.selected"
                  :items="search.meta.filters.items"
                  persistent width="580px"
                  @click:filter="setFilters"
                ></app-search-advanced-filter-dialog>
              </app-can-view>
              <!-- Advanced Search Filter -->

              <!-- Search Export Button -->
              <v-tooltip v-if="isExportButtonShown" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-3"
                    depressed
                    @click="exportItems"
                    v-on="on"
                    title="search export results"
                  >
                    <v-icon left small>mdi-export</v-icon>
                    <span v-text="$trans('Export results')"></span>
                  </v-btn>
                </template>
                <span v-text="$trans('Export the 1st 500 results.')"></span>
              </v-tooltip>
              <!-- Search Export Button -->

              <!-- See Favorites List Button -->
              <v-tooltip v-if="favorites.length > 0" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-3"
                    depressed
                    @click="toggleFavorites"
                    v-on="on"
                    title="See favorites"
                  >
                    <v-icon left small color="#F5814C">mdi-heart</v-icon>
                    <span v-text="$trans('See Favorites')"></span>
                  </v-btn>
                </template>
                <span v-text="$trans('See Documents added to favorite list.')"></span>
              </v-tooltip>
              <!-- See Favorites List Button -->

              <v-spacer></v-spacer>

              <!-- Search Spatial Filter -->
              <v-btn v-if="isSpatialButtonShown" depressed class="ml-3">
                <v-switch
                  v-model="isSpatialButtonOn"
                  :label="$trans(search.meta.spatial.label)"
                  flat
                  @change="toggleSpatialFilter"
                ></v-switch>
              </v-btn>
              <!-- Search Spatial Filter -->
            </v-layout>
          </v-col>
        </v-row>

        <template v-if="search.state.isInitial()">
          <!-- Project Initially Empty -->
          <template v-if="search.loading">
            <v-layout>
              <v-icon left>mdi-loading mdi-spin</v-icon>
              <p class="ma-0 muted--text" v-html="$trans('Checking project...')"></p>
            </v-layout>
          </template>
          <template v-else-if="search.hasErrors()">
            <v-alert outlined text type="error" dismissible class="mt-4">
              <span
                class="login-error-message"
                v-text="search.getErrors('message', 'Something went wrong')"
              ></span>
            </v-alert>
          </template>
          <v-row v-else-if="search.isProjectEmpty()">
            <v-col cols="12" lg="12">
              <search-page-empty-state></search-page-empty-state>
            </v-col>
          </v-row>
          <!-- Project Initially Empty -->

          <!-- Alert box -->
          <v-row v-else>
            <v-col cols="12" :lg="isRightSidebarAreaShown ? 12 : 5">
              <alert-search-query-hint></alert-search-query-hint>
            </v-col>
          </v-row>
          <!-- Alert box -->
        </template>

        <!-- v-if="search.isReady()" -->
        <template v-if="search.state.isReady()">
          <!-- Category Tabs -->
          <app-stickybar :key="search.meta.categories.tabKey" divider class="mt-6 mb-3">
            <v-tabs
              v-model="search.meta.categories.currentIndex"
              show-arrows
              @change="onTabChanged"
            >
              <v-tab
                v-for="(tab, index) in search.meta.categories.items"
                :key="tab.code"
                :href="`#${tab.code}`"
                :title="`tab-${tab.code}-${index}`"
              >
                <span v-text="$trans(tab.text)"></span>
                <app-text-loader
                  :loading="search.meta.categories.loading"
                  width="40px"
                  height="20px"
                  rounded
                  :title="`tab-count-${tab.code}-${index}`"
                  class="rounded-pill mb-0 mx-2"
                >
                  <v-badge v-if="tab.chip" :content="tab.chip" inline></v-badge>
                </app-text-loader>
              </v-tab>
            </v-tabs>
          </app-stickybar>
          <!-- Category Tabs -->

          <!-- Category Result Items -->
          <v-tabs-items v-model="search.meta.categories.currentIndex">
            <v-tab-item
              v-for="(tab, i) in search.meta.categories.items"
              :key="tab.code"
              :eager="tab.eager"
              :value="tab.code"
            >
              <component
                :is="tab.component"
                v-if="search.meta.categories.isCurrentIndex(tab.code)"
                :key="`${tab.code}-${i}`"
                :tab.sync="tab" :item="search"
                :favorites="favorites"
                @click:item="itemSelected"
                @click:favorite="onFavoriteClick"
              ></component>
            </v-tab-item>
          </v-tabs-items>
          <!-- Category Result Items -->
        </template>
      </v-col>
    </v-row>

    <router-view></router-view>

    <app-can-view code="search_info_panel">
      <app-right-sidebar-area
        v-model="isRightSidebarAreaShown"
        :width="isMapAreaExpanded ? '70%' : '600px'"
      >
        <div class="d-block w-100 px-6">
          <div class="map-area__container w-100">
            <v-tabs v-model="currentMapAreaTabIndex" class="mb-1">
              <template v-for="tab in search.meta.mapAreaTabs.items">
                <app-can-view :key="tab.key" :code="tab.permission">
                  <v-tab
                    v-if="tab.active"
                    v-text="$trans(tab.text)"
                  ></v-tab>
                </app-can-view>
              </template>
            </v-tabs>
            <v-tabs-items v-model="search.meta.mapAreaTabs.currentIndex">
              <template v-for="tab in search.meta.mapAreaTabs.items">
                <app-can-view :key="tab.key" :code="tab.permission">
                  <v-tab-item
                    :eager="tab.eager"
                  >
                    <component
                      :is="tab.component"
                      v-if="tab.active"
                      v-model="search"
                    ></component>
                  </v-tab-item>
                </app-can-view>
              </template>
            </v-tabs-items>
          </div>

          <app-can-view code="search_info_panel">
            <div v-if="search.data.hasWellInfo" class="well-information__container w-100">
              <well-information-detail-card
                :loading="search.loading"
                :item.sync="search.data"
                class="my-6 w-100"
              ></well-information-detail-card>
            </div>
          </app-can-view>
        </div>
      </app-right-sidebar-area>
    </app-can-view>
    <favorites-dialog
      :favorites="favorites"
      :favDialog="favDialog" 
      @click:close="toggleFavorites"
      @click:clear="clearFavorites"
      @click:favorite="onFavoriteClick"
    ></favorites-dialog>
  </app-content>
</template>

<script>
import Search from '@/modules/Search/resources/Search';
import { mapGetters } from 'vuex';

export default {
  layout: 'admin',

  beforeRouteLeave (to, from, next) {
    this.search.map.cancelAllRequests();
    this.search.resetToInitialState();

    next();
  },

  data: () => ({
    search: new Search,
    searchModel: '',
    favorites: [],
    favDialog: false,
  }),

  computed: {
    ...mapGetters({
      isExportButtonShown: 'search/isExportButtonShown',
      isSpatialButtonShown: 'search/isSpatialButtonShown',
      isMapAreaExpanded: 'search/isMapAreaExpanded',
      kgSearchQuery: 'knowledgeGraph/kgSearchQuery',
    }),

    currentMapAreaTabIndex: {
      get () {
        return this.$store.getters['search/currentMapAreaTabIndex'];
      },
      set (index) {
        this.$store.dispatch('search/setCurrentMapAreaTabIndex', index);
      },
    },

    isSpatialButtonOn: {
      get () {
        return this.$store.getters['search/isSpatialButtonOn'];
      },
      set (isOn) {
        this.$store.dispatch('search/setSpatialButtonOn', isOn);
      },
    },

    isRightSidebarAreaShown: {
      get () {
        return this.$store.getters['search/isMapAreaShown'];
      },
      set (isShown) {
        this.$store.dispatch('search/setMapAreaShown', isShown);
      },
    },
  },

  watch: {
    '$route.query.q': function () {
      this.searchModel = this.$route.query.q
      this.search.listCategoryTabsResultsCount();
    },

    kgSearchQuery (query) {
      this.setSearchFromKGQuery(query);
    },

    currentMapAreaTabIndex () {
      this.search.map.invalidateSize();
    },
    favorites: {
      handler(newVal) {
        if (newVal.length === 0) {
          this.favDialog = false
        }
      },
      deep: true
    }
  },

  async mounted () {
    await this.search.checkIfProjectIsEmpty();
    await this.search.listOrReset();
    this.searchModel = this.search.search || ''
    const storedFavorites = localStorage.getItem('favorites');
    if (storedFavorites) {
      this.favorites = JSON.parse(storedFavorites);
    }
  },

  methods: {
    setSearch (e) {
      this.search.setSearch(e.target.value);
    },

    setSearchFromKGQuery (query) {
      this.search.setSearch(query);
    },

    clearSearch () {
      this.search.clearSearch()
      this.search.clearDrawFilters();
      this.search.clearQueryString();
      this.search.resetToInitialState();
      this.searchModel = ''
    },

    changedDataSource ({ query }) {
      this.search.setQueryString(query);
    },

    setFilters (filters) {
      this.search.setFilters(filters);
    },

    onTabChanged (index) {
      this.search.onTabChanged(index);
    },

    itemSelected (item) {
      this.search.toggleRowSelect(item);
    },

    exportItems () {
      this.search.exportItems();
    },

    toggleSpatialFilter (isOn) {
      this.search.setSpatialFilter(isOn);
    },
    
    onFavoriteClick (item){
      if(this.favorites.some(obj => obj.id === item.id)) {
        const index = this.favorites.map(e => e.id).indexOf(item.id);
        if (index !== -1) {
          this.favorites.splice(index, 1);
          localStorage.setItem('favorites', JSON.stringify(this.favorites));
        }
      } else {
        if (!this.favorites.includes(item)) {
          this.favorites.push(item);
          localStorage.setItem('favorites', JSON.stringify(this.favorites));
        }
      }
    },

    toggleFavorites(){
      this.favDialog = !this.favDialog
    },
    clearFavorites(){
      this.favorites = []
      localStorage.removeItem('favorites')
      this.favDialog = false
    },
    
    onResultClick (item) {
      this.$emit('click:item', item);
      this.$router.push({
        name: 'reports.single',
        params: { slug: item.docRef },
        query: {
          page_num: item.page,
          project_type: item.type,
          data_index: item.data_index,
          back: this.$route.fullPath,
        },
      });
    }
  },
};
</script>
