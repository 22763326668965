export const state = {
  loading: false,
  shown: false,
  data: {
    id: null,
    well: {},
    total: 0,
    labels: [],
    data: [],
  },
  charts: {
    pie: [],
  },
};

export const getters = {
  loading: state => state.loading,
  data: state => state.data,
  shown: state => state.shown,
  pieChartItems: state => state.charts.pie,
};

export const mutations = {
  SHOW (state) {
    state.shown = true;
  },

  HIDE (state) {
    state.shown = false;
  },

  SET_SHOWN (state, isShown) {
    state.shown = isShown;
  },

  UNSET_DATA (state) {
    state.data = {};
  },

  SET_DATA (state, data) {
    state.data = data;
  },

  SET_PIECHART_ITEMS (state, items) {
    state.charts.pie = items;
  },

  START_LOADING (state) {
    state.loading = true;
  },

  STOP_LOADING (state) {
    state.loading = false;
  },
};

export const actions = {
  show ({ commit }) {
    commit('SHOW');
  },

  hide ({ commit }) {
    commit('HIDE');
  },

  setShown ({ commit }, isShown) {
    commit('SET_SHOWN', isShown);
  },

  setData ({ commit }, data) {
    commit('UNSET_DATA');
    commit('SET_DATA', data);
  },

  setPieChartItems ({ commit }, items) {
    commit('SET_PIECHART_ITEMS', items);
  },

  startLoading ({ commit }) {
    commit('START_LOADING');
  },

  stopLoading ({ commit }) {
    commit('STOP_LOADING');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
