<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-width="200"
    :open-on-hover="openOnHover"
    left
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="{ on }">
        <v-btn
          small
          icon
          class="ma-1"
          v-bind="$attrs"
          v-on="on"
        >
          <v-icon small>{{ icon }}</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card :width="width" dark color="dark">
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'AppPopover',

  props: {
    icon: {
      type: String,
      default: 'mdi-information',
    },

    width: {
      type: [ String, Number ],
      default: '240',
    },

    openOnHover: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
