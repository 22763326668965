var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viz__pie-chart_container"},[_c('v-card',{attrs:{"width":"960px","min-height":"400px"}},[_c('div',{staticClass:"layer-control__popover--fixed"},[_c('app-popover',{attrs:{"title":_vm.$trans('Click to learn more on how this data and visualization was put together'),"width":"800","icon":"mdi-information-outline"}},[_c('div',{staticClass:"caption mb-0"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$trans(_vm.currentSettings.curation_info))}})])])],1),_c('v-card-title',[_c('h3',{domProps:{"textContent":_vm._s(_vm.currentSettings.title)}})]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.piechart.hasOthers() ? 7 : null}},[_c('v-sheet',{attrs:{"height":"400"}},[_c('canvas',{ref:"viz",attrs:{"width":"400","height":"420"}})])],1),(_vm.piechart.hasOthers())?_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{attrs:{"flat":"","width":"386px"}},[_c('h3',{staticClass:"ml-4 mb-2"},[_vm._v(_vm._s(_vm.$trans('Others: {percent}', { percent: _vm.piechart.getOthersPercent(), })))]),_c('v-data-table',{staticClass:"dense-footer",attrs:{"headers":_vm.otherChartHeaders,"items":_vm.piechart.others,"footer-props":_vm.otherChartFooterProps,"hide-default-header":"","dense":""},scopedSlots:_vm._u([{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.$trans(item.text))}})]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDecimal")(item.percent))+"% ")]}}],null,false,1951232851)})],1)],1):_vm._e()],1)],1),_c('v-card-text',[(_vm.piechart.isSummary())?[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Total'))}}),_c('h3',{domProps:{"textContent":_vm._s(_vm.$trans_choice(
            '{total} wells', _vm.piechart.meta.total, {
              total: _vm.piechart.meta.total
            }
          ))}})]:[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Well Name'))}}),_c('h3',{domProps:{"textContent":_vm._s(_vm.piechart.well.well_name)}})]],2),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('viz-pie-chart-map-control-references-dialog'),_c('map-gis-lithologies-export-btn')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }