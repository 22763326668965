<template>
  <v-alert
    outlined type="info"
    max-height="326px"
    class="file-upload-alert ov-y-auto"
  >
    <div
      v-for="(message, i) in messages" :key="i"
      class="file-upload-alert__message mb-0"
      v-html="$trans(message)"
    ></div>
  </v-alert>
</template>

<script>
import Uploader from '@/modules/File/resources/Uploader';

export default {
  name: 'FileUploadAlert',

  data: () => ({
    uploader: new Uploader,
    messages: [],
  }),

  mounted () {
    this.messages = this.uploader.getUploadFormTextMessage();
  },
};
</script>

<style lang="scss">
.file-upload-alert {
  &__message,
  &__message a,
  &__message span, {
    font-size: 0.99rem;
    line-height: 1.4;
  }
}

.v-application .file-upload-alert .file-upload-alert__message > p,
.v-application .file-upload-alert .file-upload-alert__message > a {
  font-size: 0.99rem;
  line-height: 1.4;
}
</style>
