<template>
  <component :is="dashboardMode"></component>
</template>

<script>
import { DASHBOARD_MODE } from '@config/app';
import DefaultDashboard from '@/modules/Dashboard/components/Dashboard/DefaultDashboard';
import DynamicDashboard from '@/modules/Dashboard/components/Dashboard/DynamicDashboard';

export default {
  components: {
    DefaultDashboard,
    DynamicDashboard,
  },

  computed: {
    dashboardMode () {
      switch (DASHBOARD_MODE) {
      case 'general':
      case 'dynamic':
        return 'DynamicDashboard';

      case 'static':
      case 'default':
      default:
        return 'DefaultDashboard';
      }
    },
  },
};
</script>
