<template>
  <section>
    <!-- eslint-disable max-len -->
    <h2 class="mb-4" v-text="$trans('API Requests Tracker')"></h2>
    <p v-html="$trans('Depending on your subscription, you can make a number of requests per day with the following endpoint.')"></p>
    <p v-html="$trans('Requests are renewed daily.')"></p>

    <template v-if="isRequestsEmpty">
      <api-requests-tracker-empty-state></api-requests-tracker-empty-state>
    </template>
    <template v-else>
      <v-tabs
        v-model="current"
        slider-color="primary"
      >
        <v-tab
          v-for="(tab, i) in headers"
          :key="i"
          :href="`#tab-${i}`"
        >
          {{ tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="current">
        <v-tab-item
          v-for="(endpoints, i) in tabs"
          :key="i"
          :value="`tab-${i}`"
        >
          <div class="mt-5 d-grid endpoints">
            <div v-for="(value, key) in endpoints" :key="key" class="endpoints__item">
              <h3 v-text="key"></h3>
              <v-progress-linear :value="getValueInPercentage(value)"></v-progress-linear>
              <v-layout>
                <v-spacer></v-spacer>
                <span
                  class="font-italic caption muted--text"
                  v-text="$trans('{value} of {total} requests used', {
                    value: value,
                    total: totalRestrictions,
                  })"
                ></span>
              </v-layout>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </section>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  name: 'ApiRequestsTracker',

  data: vm => ({
    current: 0,
    user: vm.$user.getUserFromLocalStorage(),
  }),

  computed: {
    headers () {
      return Object.keys(this.requests);
    },
    tabs () {
      return Object.keys(this.requests).map(key => this.requests[key]);
    },
    totalRestrictions () {
      return this.user?.account_type?.restrictions?.['api-requests-DW'];
    },
    requests () {
      return this.user?.download_tracker?.['api-requests-DW'];
    },
    isRequestsEmpty () {
      return isEmpty(this.requests?.GET);
    },
  },

  mounted () {
    this.getUser();
  },

  methods: {
    async getUser () {
      const user = await this.$user.getUserFromApi();
      this.user = user.data;
    },
    getValueInPercentage (value) {
      return (value / this.totalRestrictions) * 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.endpoints {
  column-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(2, auto);
  row-gap: 1rem;
}
</style>
