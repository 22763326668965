<template>
  <div class="viz__pie-chart_container">
    <!-- :width="piechart.hasOthers() ? '1000px' : '420px'" -->
    <v-card width="960px" min-height="400px">
      <div class="layer-control__popover--fixed">
        <app-popover
          :title="$trans('Click to learn more on how this data and visualization was put together')"
          width="800"
          icon="mdi-information-outline"
        >
          <div class="caption mb-0">
            <div v-html="$trans(currentSettings.curation_info)"></div>
          </div>
        </app-popover>
      </div>
      <v-card-title>
        <!-- <viz-pie-chart-descriptor-select> -->
        <h3 v-text="currentSettings.title"></h3>
        <!-- </viz-pie-chart-descriptor-select> -->
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row>
          <v-col :cols="piechart.hasOthers() ? 7 : null">
            <v-sheet height="400">
              <canvas
                ref="viz"
                width="400"
                height="420"
              ></canvas>
            </v-sheet>
          </v-col>
          <v-col v-if="piechart.hasOthers()" cols="5">
            <v-card flat width="386px">
              <h3 class="ml-4 mb-2">{{ $trans('Others: {percent}', {
                percent: piechart.getOthersPercent(),
              }) }}</h3>
              <v-data-table
                :headers="otherChartHeaders"
                :items="piechart.others"
                :footer-props="otherChartFooterProps"
                hide-default-header
                dense
                class="dense-footer"
              >
                <template v-slot:item.text="{ item }">
                  <span v-text="$trans(item.text)"></span>
                </template>
                <template v-slot:item.percent="{ item }">
                  {{ item.percent | toDecimal }}%
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <template v-if="piechart.isSummary()">
          <span v-text="$trans('Total')"></span>
          <h3
            v-text="$trans_choice(
              '{total} wells', piechart.meta.total, {
                total: piechart.meta.total
              }
            )"
          ></h3>
        </template>
        <template v-else>
          <span v-text="$trans('Well Name')"></span>
          <h3 v-text="piechart.well.well_name"></h3>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <viz-pie-chart-map-control-references-dialog></viz-pie-chart-map-control-references-dialog>
        <map-gis-lithologies-export-btn></map-gis-lithologies-export-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapColorToLabel } from '@/modules/Webgis/config/colors';
import { mapGetters } from 'vuex';
import toDecimal from '@core/filters/toDecimal';
import '@/modules/Webgis/sass/viz.scss';

export default {
  name: 'VizPieChartMapControlCard',

  filters: { toDecimal },

  data: () => ({
    type: 'pie',
    chart: null,
    otherChartHeaders: [
      { text: 'Text', value: 'text' },
      { text: 'Percent', value: 'percent', align: 'right' },
    ],
    otherChartFooterProps: { itemsPerPageOptions: [ 5, 10, 15 ] },
  }),

  computed: {
    ...mapGetters({
      piechart: 'viz/piechart/piechart',
      currentSettings: 'viz/piechart/currentSettings',
    }),
  },

  watch: {
    'piechart.data': {
      deep: true,
      handler () {
        this.initChart();
      },
    },
  },

  mounted () {
    this.initChart();
  },

  methods: {
    initChart () {
      setTimeout(async () => {
        if (this.chart) {
          await this.chart.destroy();
        }

        const canvas = this.$refs.viz.getContext('2d');
        this.chart = new window.Chart(canvas, {
          type: this.type,
          data: {
            labels: this.getFormattedLabels(),
            datasets: this.getFormattedDatasets(),
          },
          options: {
            animation: {
              duration: 0,
            },
            responsive: true,
            maintainAspectRatio: false,
            zoomOutPercentage: 85,
            layout: {
              padding: 80,
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
              text: this.title,
              position: 'bottom',
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                title (tooltipItem, data) {
                  return data.labels[tooltipItem[0].index];
                },
                label (tooltipItem, data) {
                  const dataset = data.datasets[0];
                  const value = dataset.data[tooltipItem.index];
                  const meta = Object.values(dataset._meta).pop();
                  const percent = Math.round((value / meta?.total) * 100);
                  return `${percent}%`;
                },
              },
            },
            plugins: {
              outlabels: {
                text: '%l: %p',
                color: 'white',
                stretch: 14,
                font: {
                  resizable: true,
                  minSize: 10,
                  maxSize: 12,
                },
              },
            },
          },
        });
      }, 200);
    },

    getFormattedLabels () {
      return this.piechart.data.labels.map(label => label.replace('_Percentage', ''));
    },

    getFormattedDatasets () {
      return [{
        label: this.currentSettings.title,
        data: this.piechart.data.values,
        backgroundColor: this.getBackgroundColors(),
        hoverOffset: 4,
      }];
    },

    getBackgroundColors () {
      return mapColorToLabel(this.piechart.data.labels);
    },
  },
};
</script>
