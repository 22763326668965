<template>
  <v-card
    ref="panzoom-pdf-previewer"
    :class="{
      'pdf-previewer--fullscreen': fullview,
      'pdf-previewer--twopage': twopageview,
    }"
    outlined
    height="100%"
    min-height="calc(100vh - 330px)"
    class="pdf-previewer"
  >
    <app-stickybar>
      <v-toolbar elevation="4">
        <v-app-bar-nav-icon class="mr-2" @click="toggleDrawer"></v-app-bar-nav-icon>
        <slot name="toolbar.title">
          <v-layout align-center justify-start class="ov-x-auto">
            <!-- Toggle Jump to Page -->
            <panzoom-pdf-previewer-page-selector
              v-model="currentPage" :total="total"
              class="mr-2"
              @update:page="jumpToPage"
            ></panzoom-pdf-previewer-page-selector>
            <!-- Toggle Jump to Page -->

            <v-spacer></v-spacer>
            <div class="panzoom-pdf-previewer__search-field-container">
              <panzoom-pdf-previewer-search-field
                v-if="searchBar"
                v-model="search"
                :loading="searching"
                :page="currentPage"
                :results="searchResults"
                class="mx-2"
                @click:item="selectItem"
                @click:clear="clearSearch"
                @keyup:search="setSearch"
              >
                <template
                  v-slot:search.item="{
                    item,
                    select,
                    highlight,
                    search,
                    showOriginalText,
                    itemTextKey,
                  }"
                >
                  <slot
                    name="search.item"
                    v-bind="{
                      item,
                      select,
                      highlight,
                      showOriginalText,
                      itemTextKey,
                      search,
                      updatePage,
                    }"
                  ></slot>
                </template>
              </panzoom-pdf-previewer-search-field>
            </div>
            <v-spacer></v-spacer>

            <v-layout align-center justify-lg-end justify-sm-start>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small icon
                    class="mr-2"
                    v-on="on"
                    @click="downloadItem"
                  >
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span v-text="$trans('Download')"></span>
              </v-tooltip>

              <panzoom-pdf-previewer-toolbar-menu></panzoom-pdf-previewer-toolbar-menu>
            </v-layout>
          </v-layout>
        </slot>
      </v-toolbar>
    </app-stickybar>

    <v-card flat tile class="ov-hidden">
      <panzoom-image-previewer
        id="pdf-previewer"
        ref="image-previewer"
        :color="color"
        :loading="loading"
        :options="computedOptions"
        :zoom-bar="zoomBar"
        :height="height"
        class="panzoom-pdf-previewer__image-previewer ov-hidden"
        @scroll="onScroll"
        @scrolled="onScrolled"
        @wheel:up="onWheelUp"
        @wheel:down="onWheelDown"
      >
        <template v-slot:top>
          <div class="top-intersector__bar" style="height: 10px; position: absolute; width: 100%;"></div>
        </template>

        <template v-slot:loading>
          <slot name="loading"></slot>
        </template>

        <!-- Items -->
        <v-row>
          <template v-for="(item, i) in items">
            <v-col :key="i" :cols="twoPageViewCol" :lg="twoPageViewCol">
              <div
                v-intersect.quiet="{
                  handler: e => onTopIntersect(e, item),
                  options: {
                    root: rootElement,
                  },
                }" :data-page="i+1"
                class="top-intersector__sensor"
                style="height: 10px; margin-top: 10px; width: 100%;"
              ></div>
              <v-sheet
                :id="`page-${i+1}`"
                v-intersect.quiet="{
                  handler: e => onIntersect(e, item),
                  options: {
                    root: rootElement,
                    threshold: [ 0.3, 0.45, 0.5 ],
                  },
                }" :data-page="i+1"
                color="transparent"
                class="pdf-previewer__item mb-3"
              >
                <div
                  v-if="showPage"
                  class="muted--text text-right caption pa-2 white"
                  v-text="$trans('Page {page}', {
                    page: i+1,
                  })"
                ></div>
                <component :is="itemWrapper" :key="i" class="pdf-previewer__item-wrapper">
                  <slot
                    name="item"
                    v-bind="{
                      item,
                      twopageview,
                      page: i+1,
                      isLoaded: item.loaded,
                      isLoading: item.loading,
                      onImageLoad: e => onImageLoad(item, e),
                    }"
                  ></slot>
                </component>
              </v-sheet>
            </v-col>
          </template>
        </v-row>
        <!-- Items -->

        <template v-slot:zoom-bar.append>
          <slot name="zoom-bar.append"></slot>
        </template>
      </panzoom-image-previewer>

      <panzoom-pdf-previewer-thumbnail-navigator
        :color="color"
        :value="drawer"
        :items="computedItems"
        @click:thumbnail="selectThumbnail"
      >
        <template v-slot:thumbnail="{ item, selectThumbnail: clickThumbnail, page: p }">
          <slot name="thumbnail" v-bind="{ item, selectThumbnail: clickThumbnail, page: p }">
            <img
              :data-src="item.url"
              :src="item.url"
              width="100%" height="auto"
              class="d-block pa-2 mb-2 bordered"
            >
          </slot>
        </template>
      </panzoom-pdf-previewer-thumbnail-navigator>
    </v-card>
  </v-card>
</template>

<script>
import PanzoomImagePreviewer from '@/components/Preview/PanzoomImagePreviewer';
import { VLazy } from 'vuetify/lib';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PanzoomPdfPreviewer',

  components: {
    VLazy,
  },

  extends: PanzoomImagePreviewer,

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    color: {
      type: String,
      default: 'light darken-4',
    },

    page: {
      type: [ String, Number ],
      default: 1,
    },

    total: {
      type: [ String, Number ],
      default: 0,
    },

    options: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      default: false,
    },

    searching: {
      type: Boolean,
      default: false,
    },

    showPage: {
      type: Boolean,
      default: false,
    },

    searchBar: {
      type: Boolean,
      default: false,
    },

    searchResults: {
      type: Array,
      default: () => [],
    },
  },

  data: vm => ({
    search: null,
    fullscreen: false,
    isScrolling: false,
    loadAllPages: false,
    currentPage: vm.page || 1,
  }),

  computed: {
    ...mapGetters({
      drawer: 'panzoom/drawer',
      fullview: 'panzoom/fullview',
      twopageview: 'panzoom/twopageview',
    }),

    twoPageViewCol () {
      return this.twopageview ? 6 : 12;
    },

    computedItems () {
      return this.items.map(i => ({
        ...i,
        width: null,
        height: null,
        loaded: false,
        loading: true,
        wrapper: this.itemWrapper,
      }));
    },

    computedOptions () {
      return {
        step: 0.2,
        maxScale: 8,
        minScale: 0.20,
        overflow: 'auto',
        touchAction: 'pan',
        panOnlyWhenZoomed: true,
        initialZoom: this.fullview ? 0.75 : 1,
        setTransform: (elem, { x, y, scale }) => this.setZoomTransform(elem, { x, y, scale }),
        ...this.options,
      };
    },

    rootElement () {
      return this.$refs['image-previewer']?.$el;
    },

    pageNum () {
      return this.$route.query.page_num;
    },

    itemWrapper () {
      if (this.loadAllPages) {
        return 'div';
      }

      return 'v-lazy';
    },
  },

  watch: {
    pageNum (pageNum) {
      this.jumpToPage(pageNum);
    },
  },

  mounted () {
    this.jumpToDefaultPage();
  },

  methods: {
    ...mapActions({
      toggleDrawer: 'panzoom/drawer',
      setCurrentPage: 'panzoom/currentPage',
    }),

    setSearch (e) {
      this.$emit('enter:search', e);
    },

    clearSearch (e) {
      this.$emit('click:clear', e);
    },

    downloadItem () {
      this.$emit('click:download');
    },

    selectItem ({ item }) {
      this.jumpToPage(item.page_num);
      this.$emit('click:search-item', item);
    },

    async scrollToPage (page) {
      const imagePreviewer = this.$refs['image-previewer'];
      const imageWindow = imagePreviewer.$el.querySelector('.image-preview__window');
      const hasDiv = document.querySelector(`[data-page="${page}"]`);

      if (hasDiv) {
        await this.$vuetify.goTo(`[data-page="${page}"]`, {
          offset: 0,
          easing: 'easeInOutCubic',
          container: imageWindow,
        });
      }
    },

    async updatePage (page, behavior = 'smooth') {
      this.isScrolling = behavior === 'smooth';

      const hasDiv = document.querySelector(`[data-page="${page}"]`);

      if (hasDiv) {
        await hasDiv.scrollIntoView({ behavior });
      }

      this.setCurrentPage(page);

      this.isScrolling = false;
    },

    jumpToDefaultPage (pageNum = null) {
      this.jumpToPage(pageNum || this.pageNum || this.currentPage);
    },

    async jumpToPage (page) {
      await this.updatePage(page, 'instant');
      this.currentPage = page;
      this.$emit('jump:item', page);
    },

    selectThumbnail (i) {
      this.jumpToPage(i);
      this.toggleDrawer();
    },

    onTopIntersect (e, item) {
      if (this.isScrolling) {
        this.currentPage = item.page;
        this.setCurrentPage(item.page);
      }
      this.$emit('intersected:item', item);
    },

    onIntersect (e, item) {
      console.log('intersected via own container', item);
    },

    onScroll () {
      setTimeout(() => {
        this.isScrolling = true;
      }, 200);
    },

    onScrolled () {
      this.isScrolling = false;
    },

    onWheelUp ({ event, panzoom }) {
      if (event.ctrlKey) {
        event.preventDefault();
        panzoom.zoomIn();
      }
    },

    onWheelDown ({ event, panzoom }) {
      if (event.ctrlKey) {
        event.preventDefault();
        panzoom.zoomOut();
      }
    },

    setZoomTransform (el, { scale, x, y }) {
      // Parent element center points
      const parentEl = el.parentElement;
      const parentCenterY = parentEl.scrollTop + parentEl.clientHeight / 2;
      const parentCenterX = parentEl.scrollLeft + parentEl.clientWidth / 2;

      // Position ratios relative to inner element height
      const parentCenterYRatio = parentCenterY / el.clientHeight;
      const parentCenterXRatio = parentCenterX / el.clientWidth;

      // Set transform origin to center points
      el.style.transformOrigin = `${parentCenterX}px ${parentCenterY}px`;

      // Set zoom level
      el.style.transform = `scale(${scale}) translate(${x}px, ${y}px)`;

      // Re-sync scroll top and scroll left positions
      parentEl.scrollTop = el.clientHeight * parentCenterYRatio - parentEl.clientHeight / 2;
      parentEl.scrollLeft = el.clientWidth * parentCenterXRatio - parentEl.clientWidth / 2;

      // Additional styling
      el.style.userSelect = 'text';
    },

    onImageLoad (item) {
      item.loaded = true;
      item.loading = false;
    },
  },
};
</script>

<style lang="scss">
.pdf-previewer {
  &__item {
    .active {
      border: 2px solid var(--v-primary-base);
      border-radius: 4px;
      overflow: hidden;
    }

    &-wrapper {
      min-height: calc(100vh - 270px);
    }
  }

  &--fullscreen {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 6;

    .image-preview__window,
    > .image-preview > .image-preview__window {
      min-height: calc(100vh - 64px);
    }
  }

  .image-preview__container {
    position: relative;
  }

  .panzoom-pdf-previewer__image-previewer {
    > .image-preview__window,
    > .image-preview__window > .image-preview__container {
      user-select: text;
    }
  }
}
</style>
