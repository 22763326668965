import StoreState from '@core/resources/StoreState';
import Vue from 'vue';

export const KEY_CART_ITEMS = 'cart.items';

export const state = new StoreState({
  items: Vue.prototype.$session.get(KEY_CART_ITEMS) || [],
});

export const getters = {
  ...StoreState.mapDefaultGetters(),
  cart: state => state,
  items: state => state.items,
};

export const mutations = {
  ...StoreState.mapDefaultMutations(),
  FLUSH_CART (state) {
    state.items = [];
    Vue.prototype.$session.remove(KEY_CART_ITEMS);
  },
  ADD_TO_CART (state, item) {
    state.items.push(item);

    if (Vue.prototype.$session.has(KEY_CART_ITEMS)) {
      Vue.prototype.$session.remove(KEY_CART_ITEMS);
    }

    Vue.prototype.$session.set(KEY_CART_ITEMS, state.items);
  },
};

export const actions = {
  ...StoreState.mapDefaultActions(),
  flushCart ({ commit }) {
    commit('FLUSH_CART');
  },
  addToCart ({ commit }, item) {
    commit('ADD_TO_CART', item);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
