<template>
    <v-card>
      <v-card-title>Browser Incompatible</v-card-title>
      <v-card-text>
        Uh oh. Looks like you're using an older browser. Please use <a href="https://alternativeto.net/category/browsers/chromium-based/" target="_blank">Chromium based browsers</a> for the best experience.
      </v-card-text>
      
      <v-layout justify-center class="mt-10 mb-4 mx-4">
        <v-spacer></v-spacer>
          <v-btn
            x-large
            rounded
            color="secondary"
            class="text-capitalize px-8"
            @click="onClose"
          ><span v-text="$trans('i Understand')"></span></v-btn>
      </v-layout>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DialogBrowserCompatibility',
  computed: {
    ...mapGetters({
      dialog: 'dialog/dialog',
    }),
  },

  methods: {
    onClose() {
      this.$emit('close')
    }
  }
};
</script>
