export const state = {
  showNoNodeSnackbar: false,
  mapAreaDimensions: {},
  kgSearchQuery: '',
};

export const getters = {
  showNoNodeSnackbar: state => state.showNoNodeSnackbar,
  mapAreaDimensions: state => state.mapAreaDimensions,
  kgSearchQuery: state => state.kgSearchQuery,
};

export const mutations = {
  SET_SHOW_NO_NODE_SNACKBAR (state, showNoNodeSnackbar) {
    state.showNoNodeSnackbar = showNoNodeSnackbar;
  },

  SHOW_NO_NODE_SNACKBAR (state) {
    state.showNoNodeSnackbar = true;
  },

  HIDE_NO_NODE_SNACKBAR (state) {
    state.showNoNodeSnackbar = false;
  },

  SET_MAP_AREA_DIMENSIONS (state, mapAreaDimensions) {
    state.mapAreaDimensions = mapAreaDimensions;
  },

  SET_KG_SEARCH_QUERY (state, kgSearchQuery) {
    state.kgSearchQuery = kgSearchQuery;
  },
};

export const actions = {
  setShowNoNodeSnackbar ({ commit }, showNoNodeSnackbar) {
    commit('SET_SHOW_NO_NODE_SNACKBAR', showNoNodeSnackbar);
  },

  showNoNodeSnackbar ({ commit }) {
    commit('SHOW_NO_NODE_SNACKBAR');
  },

  hideNoNodeSnackbar ({ commit }) {
    commit('HIDE_NO_NODE_SNACKBAR');
  },

  setMapAreaDimensions ({ commit }, mapAreaDimensions) {
    commit('SET_MAP_AREA_DIMENSIONS', mapAreaDimensions);
  },

  setKgSearchQuery ({ commit }, kgSearchQuery) {
    commit('SET_KG_SEARCH_QUERY', kgSearchQuery);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
