<template>
  <portal to="sidebar:right">
    <v-navigation-drawer
      v-model="model"
      v-shortkey="[ 'ctrl', 'shift', '>' ]"
      :width="width"
      app clipped
      bottom right
      v-bind="$attrs"
      @shortkey.native="toggleMenu"
    >
      <slot></slot>
      <portal to="sidebar:right.activator">
        <slot name="activator" v-bind="{ toggleMenu }">
          <v-btn
            v-if="isMobile"
            depressed block
            @click="toggleMenu"
            v-text="$trans('View details')"
          ></v-btn>
        </slot>
      </portal>

      <template v-slot:append>
        <slot name="append">
          <portal-target name="sidebar:right.append" multiple></portal-target>
        </slot>
      </template>
    </v-navigation-drawer>
  </portal>
</template>

<script>
import Breakpoints from '@core/mixins/Breakpoints';

export default {
  name: 'AppRightSidebarArea',

  mixins: [ Breakpoints ],

  props: {
    value: {
      type: Boolean,
      default: true,
    },

    width: {
      type: [ String, Number ],
      default: '320px',
    },
  },

  data: vm => ({
    menu: vm.value,
  }),

  computed: {
    model: {
      get () {
        return this.value;
      },

      set (value) {
        this.$emit('input', value);
      },
    },
  },

  mounted () {
    this.setMenuState();
  },

  methods: {
    setMenuState () {
      if (this.isMobile) {
        this.hideMenu();
      }
    },

    toggleMenu () {
      this.menu = !this.menu;
      this.$emit('input', this.menu);
    },

    hideMenu () {
      this.menu = false;
      this.$emit('input', this.menu);
    },

    showMenu () {
      this.menu = true;
      this.$emit('input', this.menu);
    },
  },
};
</script>
