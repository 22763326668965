import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      show: 'sidebar/model',
      clipped: 'sidebar/clipped',
      mini: 'sidebar/mini',
    }),

    /**
     * The sidebar object.
     *
     * @return {Object}
     */
    sidebar () {
      return {
        model: this.show,
        clipped: this.clipped,
        mini: this.mini,
      };
    },

    /**
     * The sidebar model to determine visibility.
     *
     * @type {Object|boolean}
     */
    model: {
      get () {
        return this.show;
      },
      set (model) {
        this.$_toggleSidebar(model);
      },
    },
  },

  methods: {
    ...mapActions({
      $_toggleSidebar: 'sidebar/toggle',
      $_clipSidebar: 'sidebar/clip',
      $_minifySidebar: 'sidebar/minify',
    }),

    /**
     * Toggle the sidebar visibility.
     *
     * @return {void}
     */
    toggleSidebar () {
      this.$_toggleSidebar(!this.model);
    },

    /**
     * Toggle the clipped variant of the sidebar.
     *
     * @return {void}
     */
    clipSidebar () {
      this.$_clipSidebar(!this.clipped);
    },

    /**
     * Toggle the mini variant of the sidebar.
     *
     * @return {void}
     */
    minifySidebar () {
      this.$_minifySidebar(!this.mini);
    },
  },
};
