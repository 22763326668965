<template>
  <div>
    <v-skeleton-loader type="image" height="56"></v-skeleton-loader>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppSearchFieldLoadingState',
};
</script>
