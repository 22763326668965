export default {
  isShown: false,
  isOn: true,
  isSpatialFilterTriggered: false,
  label: 'Spatial Filter',
  setOn (isOn) {
    this.isOn = isOn;
  },
  setShown (isShown) {
    this.isShown = isShown;
  },
  show () {
    this.isShown = true;
  },
  hide () {
    this.isShown = false;
  },
  toggle () {
    this.isOn = !this.isOn;
  },
  trigger () {
    this.isSpatialFilterTriggered = !this.isSpatialFilterTriggered;
  },
};
