<template>
  <keep-alive></keep-alive>
</template>

<script>
export default {
  layout: 'web',

  created () {
    this.$router.replace({ name: 'error:404', params: [ this.$route.path ] });
  },
};
</script>
