<template>
  <images-data-iterator-thumbnail
    class="intuition-data-iterator__thumbnail"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:default="{ hover }">
      <v-layout
        fill-height align-center
        align-content-center
        justify-center
        column
      >
        <v-overlay :value="hover" absolute>
          <v-layout fill-height column justify-center>
            <v-btn
              dark
              small outlined
              class="mb-2"
              @click="flyToTarget(image)"
            >
              <v-icon left small>mdi-image-search-outline</v-icon>
              <span v-text="$trans('Fly to image')"></span>
            </v-btn>
            <v-btn
              :to="{
                name: 'intuition.single',
                params: { slug: image.id },
                query: { project_id: image.attributes.project_id },
              }" exact
              dark
              small outlined
              class="mb-2"
            >
              <v-icon left small>mdi-magnify-plus-outline</v-icon>
              <span v-text="$trans('View image')"></span>
            </v-btn>
            <v-btn
              dark
              small outlined
              class="mb-2"
              @click="download(image)"
            >
              <v-icon left small>mdi-download</v-icon>
              <span v-text="$trans('Download')"></span>
            </v-btn>
            <v-btn
              dark
              small outlined
              class="mb-2"
              @click="goToPage(image)"
            >
              <v-icon left small>mdi-file-eye-outline</v-icon>
              <span
                v-html="$trans(`Go to page {page}`, {
                  page: image.attributes.page_num,
                })"
              ></span>
            </v-btn>
          </v-layout>
        </v-overlay>
      </v-layout>
    </template>
    <template v-slot:append>
      <v-layout class="caption text-truncate text--disabled">
        <router-link
          :title="image.attributes.doc_title"
          :to="{
            name: 'reports.single',
            params: { slug: image.attributes.doc_ref },
            query: {
              page_num: image.attributes.page_num,
              project_id: item.attributes.project_id,
              project_type: image.attributes.project_type,
              back: $route.path,
            },
          }"
          class="caption text-truncate text--disabled"
          v-text="image.attributes.doc_title"
        ></router-link>
        <v-spacer></v-spacer>
        <span
          v-text="`${$trans('Page')}: ${image.attributes.page_num}`"
        ></span>
      </v-layout>
      <slot name="image.append" v-bind="{ image }"></slot>
    </template>
  </images-data-iterator-thumbnail>
</template>

<script>
import Intuition3D from '@/modules/Intuition/resources/Intuition3D';

export default {
  name: 'IntuitionDataIteratorThumbnail',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    intuition3D: new Intuition3D,
    meta: {},
  }),

  computed: {
    image () {
      return {
        ...this.item,
        attributes: {
          ...this.item?.attributes,
          ...this.meta,
        },
      };
    },
  },

  watch: {
    item: {
      deep: true,
      handler () {
        this.fetchImage();
      },
    },
  },

  mounted () {
    this.fetchImage();
  },

  methods: {
    download (item) {
      this.$emit('click:download', item);
    },

    flyToTarget (item) {
      this.$emit('click:flyToTarget', item);
    },

    goToPage (item) {
      this.$emit('click:goToPage', item);
    },

    async fetchImage () {
      this.meta = await this.intuition3D.getImageAttributes(this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.intuition-data-iterator {
  &__thumbnail {
    min-height: 200px;
    min-width: 200px;
  }
}
</style>
