<template>
  <v-container v-bind="$attrs" fluid class="app-workspace pa-0 h-100">
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: 'AppWorkspace',
};
</script>
