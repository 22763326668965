import { basename, stripExtension } from '@core/utils';

const modules = {};

const requireModules = require.context(
  // The relative path of the components folder
  '@core',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base module filenames
  /(modules|components|store)\/(\w+\/store\/)?\w+\.js/,
);

requireModules.keys().forEach(filename => {
  const namespace = stripExtension(basename(filename, '/'));
  modules[namespace] = requireModules(filename).default || requireModules(filename);
});

export default modules;
