import { GET_BILLING_PAYMENT_METHODS_URL, PUT_BILLING_PAYMENT_METHODS_URL } from '@/modules/Billing/api/billing';
import DataResource from '@core/resources/DataResource';
import Country from 'country-list-js';
import { isEmpty, isEqual, isNil } from 'lodash';
import Vue from 'vue';

export default class Customer extends DataResource {
  constructor (options) {
    super(options);

    this.setMeta({
      countries: [],
      provinces: [],
      countryAttribute: 'name',
      sameAsAccountEmail: false,
      hasProvinces: () => !isEmpty(this.meta.provinces),
    });

    this.setupCountriesList();
    this.setAndObserveFields();
  }

  setFieldsFromObject (items) {
    console.log('setFieldsFromObject', items);
  }

  setAndObserveFields () {
    const fields = this.observe(this.getDefaultFields(), true, () => {
      if (isEqual(this.fields, this.oldFields)) {
        this.makePristine();
      } else {
        this.makeFieldsDirty();
      }
    });
    this.setFields(fields);
    this.oldFields = { ...this.fields };
  }

  setupDefaultFields () {
    this.startLoading();
    this.setAndObserveFields();
    this.stopLoading();
  }

  getDefaultFields () {
    const currentPaymentMethod = this.store.getters['billing/currentPaymentMethod'];
    const billingDetails = currentPaymentMethod?.billing_details;
    const country = Country.findByIso2(billingDetails?.address?.country ?? null);
    const accountFullName = this.store.getters['auth/user'].data.full_name ?? null;

    if (country) {
      this.setContactFromCountry(country);
    } else {
      console.warn('Customer@getDefaultFields', 'No country found', country);
    }

    return {
      account_full_name: accountFullName,
      full_name: billingDetails?.name ?? null,
      account_email: this.getAccountEmail(),
      email: billingDetails?.email ?? null,
      country: billingDetails?.address?.country ?? null,
      street1: billingDetails?.address?.line1 ?? null,
      street2: billingDetails?.address?.line2 ?? null,
      city: billingDetails?.address?.city ?? null,
      postal_code: billingDetails?.address?.postal_code ?? null,
      state: billingDetails?.address?.state ?? null,
      phone: billingDetails?.phone ?? null,
      payment_method_id: currentPaymentMethod?.id ?? null,
    };
  }

  getAccountEmail () {
    const user = this.store.getters['auth/user'];
    return user.email;
  }

  setupCountriesList () {
    this.meta.countries = Object.values(Country.all).map(country => ({
      ...country,
      text: country.name,
      value: country.iso2,
    }));
  }

  toggleBillingEmailSameAsAccount () {
    if (this.meta.sameAsAccountEmail) {
      this.fields.email = this.fields.account_email;
      this.fields.full_name = this.fields.account_full_name;
    }
  }

  async updatePaymentDetails () {
    this.startLoading();

    this.fields.country = this.fields.country?.value ?? this.fields?.country ?? '';
    this.fields.state = this.fields.state?.value ?? this.fields?.state ?? '';

    console.log('[@updatePaymentDetails]', this.fields);
    if (isEmpty(this.fields.payment_method_id) || isNil(this.fields.payment_method_id)) {
      const billingDetails = {
        address: {
          city: this.fields.city,
          country: this.fields.country,
          line1: this.fields.street1,
          line2: this.fields.street2,
          postal_code: this.fields.postal_code,
          state: this.fields.state,
        },
        email: this.fields.email,
        name: this.fields.full_name,
        phone: this.fields.phone,
      };
      Vue.prototype.$session.set('billing.billingDetailsData', billingDetails);
      console.log('Billing details without pm_id:', billingDetails);
      return Promise.resolve(billingDetails);
    }

    // this.router.push({ name: 'checkout' });
    // return Promise.resolve(this);

    try {
      delete this.fields.needsSetupIntent;
      const { data } = await this.axios.put(PUT_BILLING_PAYMENT_METHODS_URL, this.fields);

      if (!isEmpty(data?.data?.message)) {
        this.stopLoading();
        this.setErrors({ message: data.data.message });
        return Promise.reject(this.getErrors());
      }

      await this.fetchListPayments();

      this.notify('Payment Details successfully updated');
      this.stopLoading();

      return Promise.resolve(data.data);
    } catch (e) {
      console.debug(e);
      return Promise.reject(e);
    }
  }

  async fetchUserBillingDetails () {
    await this.fetchListPayments();
    this.setAndObserveFields();
    this.makeFieldsDirty();
  }

  makeFieldsDirty () {
    this.dirty = true;
    this.pristine = false;
  }

  async fetchListPayments () {
    try {
      this.disableAxiosResponseHandlers();
      const { data } = await this.axios.get(GET_BILLING_PAYMENT_METHODS_URL);
      await this.store.dispatch('billing/setPaymentMethods', data.data.payment_methods.data);
      await this.store.dispatch('billing/setUpcomingInvoices', data.data.upcoming_invoice.data);
    } catch (err) {
      console.log(err);
    } finally {
      this.enableAxiosResponseHandlers();
    }
  }

  setContactFromCountry (country) {
    this.setProvincesByCountry(country);
    this.setPhonePrefix(country.dialing_code);
  }

  setPhonePrefix (dialogCode) {
    if (isEmpty(this.fields.phone)) {
      this.fields.phone = `+${dialogCode}`;
    }
  }

  setProvincesByCountry (country) {
    this.fields.state = null;
    this.meta.provinces = (country?.provinces ?? []).map(province => ({
      ...province,
      text: province.name,
      value: province.name,
    }));
  }
}
