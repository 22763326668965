<template>
  <v-dialog v-model="dialog" min-width="420" max-width="600">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="{ on }">
        <v-btn color="primary" class="px-6" v-on="on">
          <span v-text="$trans('References')"></span>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-card-title>
        <h2 class="h2 mb-4" v-html="$trans('References')"></h2>
      </v-card-title>
      <v-data-iterator
        :items-per-page="itemsPerPage"
        :items="items"
        :hide-default-footer="hideFooter"
        class="px-6"
      >
        <template v-slot:default="{ items: references }">
          <div v-for="(item, i) in references" :key="i">
            <router-link
              :to="{
                name: 'reports.single',
                params: { slug: item.iraya_data_id },
                query: { page_num: item.page_num },
              }"
            ><span v-text="item.iraya_data__doc_name"></span></router-link>
            <span>&nbsp;<em class="muted--text" v-text="`(p.${item.page_num})`"></em></span>
          </div>
        </template>
      </v-data-iterator>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          large
          color="primary"
          class="px-6"
          @click="dialog = false"
        ><span v-text="$trans('Close')"></span></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'VizMapControlReferencesDialog',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    dialog: false,
    itemsPerPage: 10,
  }),

  computed: {
    hideFooter () {
      return this.items.length <= this.itemsPerPage;
    },
  },
};
</script>
