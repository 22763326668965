import { basename, stripExtension } from '@core/utils';

const modules = {};

const requireModules = require.context(
  // The relative path of the components folder
  '@',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base module filenames
  /(modules|components|store)\/(\w+\/store\/)?(\w+\/)?\w+\.js/,
);

requireModules.keys().forEach(filename => {
  const store = requireModules(filename);
  const namespace = store?.default?.name ?? stripExtension(basename(filename, '/'));
  modules[namespace] = store.default || store;
});

export default modules;
