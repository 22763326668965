<template>
  <v-dialog
    v-model="modal"
    persistent scrollable
    max-width="560px"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :title="'Update payment button'"
        :loading="cart.loading"
        :disabled="cart.isLoadingOr(disabled)"
        large
        color="secondary"
        v-on="on"
      ><span v-text="$trans('Update payment method')"></span></v-btn>
    </template>
    <v-card>
      <v-alert v-if="cart.hasErrors()" outlined text type="error" dismissible class="ma-3">
        <span
          class="login-error-message"
          v-text="cart.getErrors('message', 'Something went wrong')"
        ></span>
      </v-alert>
      <v-alert v-if="customer.hasErrors()" outlined text type="error" dismissible class="ma-3">
        <span
          class="login-error-message"
          v-text="customer.getErrors('message', 'Something went wrong')"
        ></span>
      </v-alert>
      <v-card-text class="pa-6">
        <subscription-update-payment-form
          with-card-field
          :customer="customer"
          :disabled="disabled"
          @success="onSuccess"
          @cancel="closeDialog"
        ></subscription-update-payment-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Cart from '@/modules/Billing/resources/Cart';
import Customer from '@/modules/Billing/resources/Customer';

export default {
  name: 'SubscriptionUpdatePaymentMethodDialog',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    customer: new Customer,
    cart: new Cart,
    modal: false,
  }),

  watch: {
    modal (modal) {
      if (modal) {
        this.customer.setupDefaultFields();
      }
    },
  },

  methods: {
    async onSuccess ({ stripe }) {
      await this.cart
        .setStripe(stripe.stripe)
        .confirmCardSetupIfNotExists(stripe.card)
        .then(() => {
          this.customer.fetchListPayments();
          this.closeDialog();
        });
    },

    closeDialog () {
      this.modal = false;
    },
  },
};
</script>
