export default {
  filters: {
    nl2br (text) {
      return this.nl2br(text);
    },
  },

  methods: {
    nl2br (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
  },
};
