<template>
  <div class="text-center">
    <template v-if="skeleton">
      <v-skeleton-loader width="100%" :height="height" class="ov-hidden">
        <v-card flat :height="height" class="ov-hidden">
          <div class="v-skeleton-loader__map-area rounded v-skeleton-loader__bone"></div>
        </v-card>
      </v-skeleton-loader>
    </template>
    <template v-else>
      <div class="map-icon__container">
        <icon-map-with-map-marker></icon-map-with-map-marker>
      </div>
      <h3 class="pt-0 pb-10 px-10 text--disabled">{{ $trans('Loading map area...') }}</h3>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MapAreaLoadingState',

  props: {
    skeleton: {
      type: Boolean,
      default: false,
    },

    height: {
      type: [ String, Number ],
      default: '100%',
    },
  },
};
</script>

<style lang="scss">
.v-skeleton-loader__map-area {
  background: rgba(0, 0, 0, 0.12);
  height: 100vh;
}

.map-icon__container {
  filter: grayscale(0.1);
  opacity: 0.8;
}
</style>
