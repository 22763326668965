<template>
  <component :is="transitionComponent" mode="out-in">
    <v-snackbar
      v-model="model"
      :bottom="snackbar.y === 'bottom'"
      :left="snackbar.x === 'left'"
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      <v-icon v-if="snackbar.icon" small>{{ snackbar.icon }}</v-icon>
      <span v-text="snackbar.text"></span>

      <template v-slot:action>
        <v-btn
          v-if="snackbar.button.show"
          small
          text
          @click="snackbarCallback()"
        >
          <v-icon v-if="snackbar.button.icon" small>{{ snackbar.button.icon }}</v-icon>
          <template v-else>{{ snackbar.button.text }}</template>
        </v-btn>
      </template>
    </v-snackbar>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';
import { VSlideYTransition, VSlideYReverseTransition } from 'vuetify/lib';

export default {
  name: 'AppSnackbar',

  components: {
    slideY: VSlideYTransition,
    slideYReverse: VSlideYReverseTransition,
  },

  computed: {
    ...mapGetters({
      snackbar: 'snackbar/snackbar',
    }),

    transitionComponent () {
      return this.snackbar.y === 'bottom' ? 'slide-y-reverse' : 'slide-y';
    },

    model: {
      get () {
        return this.snackbar.show;
      },
      set (show) {
        this.$store.dispatch('snackbar/toggle', { show });
        return show;
      },
    },
  },

  methods: {
    snackbarCallback () {
      this.snackbar.button.callback();
    },
  },
};
</script>
