import { generateRandomHash } from '@utils/text';
import { has, values, isArray } from 'lodash';

export default class DataSource {
  constructor (options = {}) {
    this.id = this.generateRandomID();
    this.name = options.name || options.attributes?.project_type;
    this.sourceId = options.id;
    this.icon = options.icon;
    this.type = options.type;
    this.color = options.color;
    this.attributes = options.attributes;
    this.url = options.url;
    this.children = this.mapAttributesToChildren(options.children);
  }

  static generateRandomHash () {
    return generateRandomHash();
  }

  generateRandomID () {
    return generateRandomHash();
  }

  mapAttributesToChildren (children, parent = null) {
    return children.map(child => {
      const text = `${parent || child.text || '/'}/${child.name}`.replace('//', '');

      return {
        ...child,
        caption: text,
        icon: this.icon,
        type: this.type,
        color: this.color,
        parentId: this.id,
        sourceType: this.attributes?.project_type,
        children: this.mapAttributesToChildren((child?.children || []), text),
        storageUrl: child.url ?? this.attributes?.project_settings?.PROJECT_STORAGE_URL,
        storageKey: this.attributes?.project_settings?.PROJECT_STORAGE_KEY,
      };
    });
  }

  makeSourceTrayChildrenFromUnstructuredConfig (options) {
    const items = has(options, 'project_settings')
      ? options.project_settings.SOURCE_TRAY_CONFIGURATION
      : [];

    const parsedItems = values(items);
    parsedItems.shift();

    const lastLevel = (parsedItems.pop() || []).map(name => ({
      name,
      type: this.type,
      parentId: this.id,
      id: `${this.attributes.name}:${name}`, // generateRandomHash(),
      sourceType: this.attributes.project_type,
    }));

    const p = parsedItems.reverse().reduce((res, key) => ({
      name: key,
      id: `${this.attributes.name}:${key}`,
      type: this.type,
      parentId: this.id,
      children: isArray(res) ? res : [ res ],
      sourceType: this.attributes.project_type,
    }), lastLevel);

    return [ p ];
  }
}
