// export const PIE_CHART_COLORS = [
//   'rgb(249, 65, 68)',
//   'rgb(231, 123, 16)',
//   'rgb(248, 153, 97)',
//   'rgb(249, 199, 79)',
//   'rgb(144, 190, 109)',
//   'rgb(67, 170, 139)',
//   'rgb(77, 144, 142)',
//   'rgb(87, 117, 144)',
//   'rgb(39, 125, 161)',
//   'rgb(59, 91, 104)',
// ];

export const PIE_CHART_COLORS = [
  [ 0, 249, 65, 68 ],
  [ 40, 231, 123, 16 ],
  [ 80, 248, 153, 97 ],
  [ 120, 249, 199, 79 ],
  [ 160, 144, 190, 109 ],
  [ 200, 67, 170, 139 ],
  [ 240, 77, 144, 142 ],
  [ 280, 87, 117, 144 ],
  [ 320, 39, 125, 161 ],
  [ 360, 59, 91, 104 ],
];

export const MOST_COMMON_LABELS = {
  'Limestone(clay/silt-size)': PIE_CHART_COLORS[0],
  Limestone: PIE_CHART_COLORS[1],
  Sandstone: PIE_CHART_COLORS[2],
  Evaporites: PIE_CHART_COLORS[3],
  Volcanic: PIE_CHART_COLORS[4],
  Coquina: PIE_CHART_COLORS[5],
  Mudstone: PIE_CHART_COLORS[6],
  Dolomite: PIE_CHART_COLORS[7],
  Packstone: PIE_CHART_COLORS[8],
  Others: PIE_CHART_COLORS[9],
  default: 'rgb(19, 81, 104)',
};

function getInterval (xValue) {
  const limits = PIE_CHART_COLORS.map(i => i[0]);
  let upperLimitIndex = 1;
  for (let i = 0; i < limits.length; i++) {
    if (limits[i] > xValue) {
      upperLimitIndex = i;
      return [ upperLimitIndex - 1, upperLimitIndex ];
    }
  }

  return limits;
}

function computeColor (interval, colorIndex, xValue) {
  const ll = interval[0];
  const ul = interval[1];
  const colorEnd = PIE_CHART_COLORS[ul][colorIndex];
  const colorStart = PIE_CHART_COLORS[ll][colorIndex];
  const xEnd = PIE_CHART_COLORS[ul][0];
  const xStart = PIE_CHART_COLORS[ll][0];
  const slope = (colorEnd - colorStart) / (xEnd - xStart);
  const computedColor = Math.abs((slope * xValue) - (slope * xStart) + colorEnd);
  return computedColor;
}

function generatePieSegmentColor (stringInput) {
  /* eslint-disable no-bitwise */
  const stringUniqueHash = [ ...stringInput ]
    .reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);

  const xValue = Math.abs(stringUniqueHash % 360);
  const interval = getInterval(xValue);
  const computedR = computeColor(interval, 1, xValue);
  const computedG = computeColor(interval, 2, xValue);
  const computedB = computeColor(interval, 3, xValue);

  return `rgb(${computedR}, ${computedG}, ${computedB})`;
}

export const mapColorToLabel = labels => {
  const _labels = [ ...labels ];

  const colors = _labels.sort()
    .map(label => ({
      color: generatePieSegmentColor(label),
      label,
    }));

  return labels.map(l => colors.find(i => i.label === l).color);
};

export default {
  PIE_CHART_COLORS,
  mapColorToLabel,
};
