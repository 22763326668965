<template>
  <app-content>
    <validation-observer ref="form:signin" v-slot="{ handleSubmit }">
      <v-form
        v-model="form.valid"
        :disabled="form.loading"
        method="post"
        @submit.prevent="handleSubmit(submit)"
      >
        <h2 class="mb-4">{{ $trans('Themes') }}</h2>
        <theme-picker-card-group></theme-picker-card-group>
      </v-form>
    </validation-observer>
  </app-content>
</template>

<script>
import Settings from '@/modules/Settings/resources/Settings';

export default {
  layout: 'admin',

  data: () => ({
    form: new Settings,
  }),
};
</script>
