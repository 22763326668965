<template>
  <v-layout
    column align-center
    class="fill-height"
  >
    <icon-messy-folder class="accent--text"></icon-messy-folder>
    <h1 class="my-3" v-text="$trans('Report not ready')"></h1>
    <p class="muted--text">{{ $trans('The file is still being processed on our servers.') }}</p>
    <p class="muted--text">{{ $trans('Check back again soon.') }}</p>
    <slot></slot>
  </v-layout>
</template>

<script>
export default {
  name: 'ReportViewerEmptyState',
};
</script>
