import Billing from '@/modules/Billing/resources/Billing';

export default new class CartHook {
  async trigger (cart) {
    await this.upgradeUserSubscription();
    await this.refreshUser(cart);
    cart.store.dispatch('cart/flushCart');
    cart.store.dispatch('billing/flushClientSecret');
    cart.store.dispatch('order/generateAvailabilityLimit', { limit: 1 });
    cart.router.push({ name: 'orders.completed' });
  }

  async upgradeUserSubscription () {
    return (new Billing).upgradeSubscription();
  }

  async refreshUser (cart) {
    cart.router.push({ name: 'auth.logout' });
  }
};
