<template>
  <portal to="profile:account.bottom">
    <v-layout class="align-start mb-5">
      <v-icon
        small left
        class="mt-1"
      >mdi-folder-account-outline</v-icon>
      <div>
        <div class="font-weight-bold" v-text="$trans('Account Type')"></div>
        <div
          class="text-capitalize"
          v-html="billingInfo.subscriptionType"
        ></div>
      </div>
    </v-layout>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileAccountSystemTray',

  computed: mapGetters({
    billingInfo: 'billing/billingInfo',
  }),
};
</script>
