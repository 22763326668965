<template>
  <v-card
    tile
    width="100%"
    height="420px"
  >
    <svg
      id="publicationsKnowledgeGraphSVG"
      :width="kgCardSize.width"
      :height="kgCardSize.height"
    />

    <v-overlay
      :absolute="overlay.absolute"
      :value="overlay.overlay && knowledgeGraph.loaded"
    >
      <v-btn>
        Selected publication is not in knowledge graph.
      </v-btn>
    </v-overlay>

    <v-overlay
      :absolute="true"
      :value="!knowledgeGraph.loaded"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import KnowledgeGraph from '@/modules/Search/resources/KnowledgeGraph';
import { mapGetters, mapActions } from 'vuex';
import {
  KG_OPTIONS,
  PUBLICATIONS_KNOWLEDGE_GRAPH_ID,
} from '@/modules/Search/config/knowledgeGraph';

export default {
  name: 'PublicationsKnowledgeGraph',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 699,
    },
    height: {
      type: Number,
      default: 420,
    },
  },

  data () {
    return {
      kgCardSize: {
        width: 0,
        height: 0,
      },
      overlay: {
        absolute: true,
        overlay: false,
      },
      knowledgeGraph: new KnowledgeGraph({
        id: PUBLICATIONS_KNOWLEDGE_GRAPH_ID,
        kgType: 'publications',
        kgMode: 'ego',
        svgId: 'publicationsKnowledgeGraphSVG',
        width: '699',
        height: '420',
        degreeRange: [ 8, 25 ],
        weightRange: [ 1, 10 ],
        currentSelection: null,
        previousSelection: null,
        nodeLabelAttribute: 'article_name',
        flyToScale: 0.7,
        meta: {
          style: KG_OPTIONS.styles,
          simulation: {
            forces: {
              manyBody: -10,
              link: {
                distance: 1,
                iterations: 1,
              },
              collide: {
                force: 100,
                strength: 0.5,
                iterations: 2,
              },
            },
            alpha: 0.3,
            tick: 1,
          },
        },
      }),
    };
  },

  computed: {
    ...mapGetters({
      mapAreaDimensions: 'knowledgeGraph/mapAreaDimensions',
      showNoNodeSnackbar: 'knowledgeGraph/showNoNodeSnackbar',
    }),
  },

  watch: {
    async item (newItem, oldItem) {
      if (newItem.article_title !== 'N/A' && newItem.doc_ref !== oldItem.doc_ref) {
        const docRef = newItem.doc_ref;
        this.knowledgeGraph.resetKG();
        await this.knowledgeGraph.initializeEgo(docRef);
        this.highlightNode();
        this.knowledgeGraph.previousSelection = this.knowledgeGraph.currentSelection;
        this.knowledgeGraph.currentSelection = docRef;
      }
    },
    showNoNodeSnackbar () {
      this.overlay.overlay = this.showNoNodeSnackbar;
    },
  },

  beforeMount () {
    this.knowledgeGraph.resetKG();
  },

  async mounted () {
    this.knowledgeGraph.resetKG();
    const containerRef = document.getElementById('search-map');
    if (containerRef) {
      const dimensions = { width: containerRef.clientWidth, height: containerRef.clientHeight };
      if (dimensions.width > 0) {
        this.kgCardSize = dimensions;
      }
    }
    this.knowledgeGraph.resetKG();
    this.overlay.overlay = this.showNoNodeSnackbar;
    this.knowledgeGraph.width = this.kgCardSize.width ? this.kgCardSize.width : this.width;
    this.knowledgeGraph.height = this.kgCardSize.height ? this.kgCardSize.height : this.height;

    setTimeout(async () => {
      const docRef = this.item.doc_ref;
      this.knowledgeGraph.resetKG();
      await this.knowledgeGraph.initializeEgo(docRef);
      this.highlightNode();
      this.knowledgeGraph.previousSelection = this.knowledgeGraph.currentSelection;
    }, 1000);
  },

  beforeDestroy () {
    this.knowledgeGraph.resetKG();
  },

  methods: {
    ...mapActions({
      setShowNoNodeSnackbar: 'knowledgeGraph/setShowNoNodeSnackbar',
      setMapAreaDimensions: 'knowledgeGraph/setMapAreaDimensions',
    }),

    isSameNode (n1, n2) {
      return n1 === n2;
    },

    async onResize () {
      await this.$nextTick();
      this.kgCardSize = { width: this.$el.clientWidth, height: this.$el.clientHeight };
      this.setMapAreaDimensions(this.kgCardSize);
    },

    highlightNode () {
      this.knowledgeGraph.previousSelection = this.knowledgeGraph.currentSelection !== undefined
        ? this.knowledgeGraph.currentSelection
        : this.item.doc_ref;
      this.knowledgeGraph.currentSelection = this.item.doc_ref;
    },
  },
};
</script>
