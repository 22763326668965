import { ACCOUNT_TYPE_BOLT } from '@/modules/Billing/enums/AccountTypes';
import Profile from '@/modules/User/resources/Profile';

export default async function isBoltAccount (to, from, next) {
  const user = await (new Profile).getUserFromApi();

  if (user.isAccountType(ACCOUNT_TYPE_BOLT)) {
    return next();
  }

  const redirect = from.name ? from : user.getRedirectRouteFromEnv();
  return next(redirect);
}
