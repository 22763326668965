<template>
  <v-card>
    <slot v-bind="{ item }">
      <v-card-title>
        <h3 v-text="item.title"></h3>
      </v-card-title>
      <v-card-text class="flex-grow-1">
        <div class="mb-5">
          <div
            v-if="item.price"
            class="title font-weight-bold"
            v-text="item.price"
          ></div>
          <div v-else class="py-6 mt-1"></div>
          <div v-if="item.overline" class="title" v-html="item.overline"></div>
          <div v-else class="py-4"></div>
        </div>
        <div v-html="item.text"></div>
      </v-card-text>
    </slot>
  </v-card>
</template>

<script>
export default {
  name: 'SubscriptionCurrentPlan',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
