import store from '@core/store';

export default new class LogoutHook {
  trigger () {
    const remembersMe = store.getters['auth/remember'];

    if (!remembersMe) {
      store.dispatch('webgis/unsetLayers');
    }
  }
};
