<template>
  <portal to="webbar:append">
    <template v-if="isUserLoggedIn">
      <app-can-view :code="permissions">
        <v-btn
          :to="redirectURL"
          large
          color="primary"
        >
          {{ $trans(redirectURL.meta.title) }}
        </v-btn>
      </app-can-view>
    </template>
    <template v-else>
      <v-btn
        :to="{ name: 'login' }"
        large
        color="primary"
      >{{ $trans('Login') }}</v-btn>
    </template>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GoToDashboardSystemTray',

  computed: {
    ...mapGetters({
      isUserLoggedIn: 'auth/isLoggedIn',
    }),

    redirectURL () {
      return this.$user.getRedirectRouteFromEnv();
    },

    permissions () {
      const permissions = this.redirectURL.meta?.permissions ?? [];

      return `data_dashboard|general_dashboard|${permissions.join('|')}`;
    },
  },
};
</script>
