var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-iterator',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('images-data-iterator-loading-state',{attrs:{"height":_vm.height,"items-count":24,"sizes":_vm.sizes}})]},proxy:true},{key:"no-results",fn:function(){return [_vm._t("no-results",[_c('images-data-iterator-empty-state')])]},proxy:true},{key:"no-data",fn:function(){return [_vm._t("no-data",[_c('images-data-iterator-empty-state')])]},proxy:true},{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item,image_id){return _c('v-col',{key:item.name,attrs:{"sm":_vm.sizes.sm,"md":_vm.sizes.md,"lg":_vm.sizes.lg},on:{"click":function($event){return _vm.selectItem({
          item: item,
          select: props.select,
          isSelected: props.isSelected,
        })}}},[_c('v-lazy',{attrs:{"transition":"slide-y-reverse-transition"}},[_vm._t("item",[_c('images-data-iterator-thumbnail',{attrs:{"id":("image-thumbnail-" + image_id),"height":_vm.height,"src":item.attributes.thumbnail_url},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-layout',{attrs:{"fill-height":"","align-center":"","align-content-center":"","justify-center":"","column":""}},[_c('v-overlay',{attrs:{"value":hover,"absolute":"","title":"images thumb overlay"}},[(_vm.hasOverlay)?[_vm._t("overlay",[_c('v-layout',{attrs:{"fill-height":"","column":"","justify-center":""}},[_vm._t("button.link",[_c('v-btn',{staticClass:"mb-4",attrs:{"to":{
                                name: 'images.single',
                                params: { slug: item.id },
                              },"exact":"","dark":"","small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-magnify-plus-outline")]),_vm._v(" "+_vm._s(_vm.$trans('Zoom in'))+" ")],1)],null,{ item: item }),_vm._t("button.download",[_c('v-btn',{staticClass:"mb-4",attrs:{"dark":"","small":"","outlined":"","title":"images overlay button download"},on:{"click":function($event){return _vm.download(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$trans('Download'))+" ")],1)],null,{ item: item, download: _vm.download }),_vm._t("button.go-to-page",[_c('v-btn',{staticClass:"mb-4",attrs:{"to":{
                                name: 'reports.single',
                                params: { slug: item.attributes.doc_ref },
                                query: {
                                  page_num: item.attributes.page_num,
                                  data_index: item.data_index,
                                  back: _vm.$route.fullPath,
                                },
                              },"dark":"","small":"","outlined":"","title":"images overlay button goto"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-file-eye-outline")]),_vm._v(" "+_vm._s(_vm.$trans("Go to page {page}", { page: item.attributes.page_num, }))+" ")],1)],null,{ item: item })],2)],null,{ item: item, hover: hover, isSelected: _vm.isSelected(item) })]:_vm._e()],2)],1)]}},{key:"append",fn:function(){return [_c('v-layout',{staticClass:"caption text-truncate text--disabled"},[_c('router-link',{staticClass:"caption text-truncate text--disabled",attrs:{"title":item.attributes.doc_title,"to":{
                      name: 'reports.single',
                      params: { slug: item.attributes.doc_ref },
                      query: {
                        page_num: item.attributes.page_num,
                        project_type: item.attributes.project_type,
                        data_index: item.data_index,
                        back: _vm.$route.path,
                      },
                    }},domProps:{"textContent":_vm._s(item.attributes.doc_title)}}),_c('v-spacer'),_c('span',{domProps:{"textContent":_vm._s(((_vm.$trans('Page')) + ": " + (item.attributes.page_num)))}})],1),_vm._t("item.append",null,null,{ item: item })]},proxy:true}],null,true)})],null,Object.assign({}, props,
              {item: item,
              height: _vm.height,
              selected: props.isSelected(item)}))],2)],1)}),1)]}},{key:"footer",fn:function(props){return [_vm._t("footer",null,null,props)]}}],null,true)},'v-data-iterator',Object.assign({}, _vm.computedProps, _vm.$attrs),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }