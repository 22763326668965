<template>
  <div>
    <!-- Related Wells Selection -->
    <v-autocomplete
      v-if="hasRelatedWells"
      v-model="relatedWells.selected"
      :items="relatedWells.items"
      hide-details return-object
      outlined dense
      item-value="id"
      item-text="attributes.well_name"
      @change="changeWell"
    >
      <template v-slot:prepend-inner>
        <v-icon x-small color="red" class="mt-2">mdi-circle</v-icon>
      </template>
    </v-autocomplete>
    <!-- Related Wells Selection -->

    <h2 class="my-4">
      <span v-text="relatedWell.well_name"></span>
      <v-chip class="ml-3" v-text="relatedWell.well_type"></v-chip>
    </h2>
    <v-simple-table class="v-data-table--denser">
      <tbody>
        <!-- Location -->
        <tr>
          <td
            rowspan="3"
            class="caption font-weight-bold text-uppercase text-no-wrap"
            v-text="$trans('Location')"
          ></td>
          <td class="caption font-weight-bold text-uppercase" v-text="$trans('Field')"></td>
          <td class="caption" v-text="relatedWell.field_name"></td>
          <td class="caption font-weight-bold text-uppercase" v-text="$trans('Basin')"></td>
          <td class="caption" v-text="relatedWell.basin"></td>
        </tr>
        <tr>
          <td class="caption font-weight-bold text-uppercase" v-text="$trans('Latitude')"></td>
          <td class="caption">{{ relatedWell.latitude | toFixed(4) }}</td>
          <td class="caption font-weight-bold text-uppercase" v-text="$trans('Longitude')"></td>
          <td class="caption">{{ relatedWell.longitude | toFixed(4) }}</td>
        </tr>
        <tr>
          <td class="caption font-weight-bold text-uppercase" v-text="$trans('CRS')"></td>
          <td :colspan="hasBlock ? null : '100%'" class="caption" v-text="relatedWell.crs"></td>
          <td v-if="hasBlock" class="caption font-weight-bold text-uppercase" v-text="$trans('Block')"></td>
          <td v-if="hasBlock" class="caption" v-text="relatedWell.block"></td>
        </tr>

        <tr>
          <td colspan="100%" class="pa-0 h-0">
            <v-divider class="ma-0"></v-divider>
          </td>
        </tr>

        <!-- Depth info -->
        <tr>
          <td
            rowspan="4"
            class="caption font-weight-bold text-uppercase text-no-wrap"
            v-text="$trans('Depth Info')"
          ></td>
          <td class="caption font-weight-bold text-uppercase" v-text="$trans('Water Depth')"></td>
          <td class="caption" v-text="relatedWell.water_depth + 'm'"></td>
          <td
            class="caption font-weight-bold text-uppercase"
            v-text="$trans('Derrick Floor Elevation')"
          ></td>
          <td class="caption" v-text="relatedWell.derrick_floor_elev + 'm'"></td>
        </tr>
        <tr>
          <td class="caption font-weight-bold text-uppercase" v-text="$trans('Rotary Table')"></td>
          <td class="caption" v-text="relatedWell.rotary_table + 'm'"></td>
          <td class="caption font-weight-bold text-uppercase" v-text="$trans('Kelly Bushing')"></td>
          <td class="caption" v-text="relatedWell.kelly_bushing + 'm'"></td>
        </tr>
        <tr>
          <td
            class="caption font-weight-bold text-uppercase"
            v-text="$trans('TVDSS (Subsea)')"
          ></td>
          <td class="caption" v-text="relatedWell.tvdss + 'm'"></td>
          <td
            class="caption font-weight-bold text-uppercase"
            v-text="$trans('True Vertical Depth (TVD)')"
          ></td>
          <td class="caption" v-text="relatedWell.tvd + 'm'"></td>
        </tr>
        <tr>
          <td
            class="caption font-weight-bold text-uppercase"
            v-text="$trans('Total/Measured Depth (TD/MD)')"
          ></td>
          <td colspan="100%" class="caption" v-text="relatedWell.total_depth + 'm'"></td>
        </tr>

        <tr>
          <td colspan="100%" class="pa-0 h-0">
            <v-divider class="ma-0"></v-divider>
          </td>
        </tr>

        <!-- Operator -->
        <tr>
          <td
            class="caption font-weight-bold text-uppercase text-no-wrap"
            v-text="$trans('Operator')"
          ></td>
          <td colspan="100%" class="caption" v-text="relatedWell.operator"></td>
        </tr>

        <tr>
          <td colspan="100%" class="pa-0 h-0">
            <v-divider class="ma-0"></v-divider>
          </td>
        </tr>

        <!-- Spud Date -->
        <tr>
          <td
            class="caption font-weight-bold text-uppercase text-no-wrap"
            v-text="$trans('Spud Date')"
          ></td>
          <td colspan="100%" class="caption" v-text="relatedWell.spud"></td>
        </tr>

        <tr>
          <td colspan="100%" class="pa-0 h-0">
            <v-divider class="ma-0"></v-divider>
          </td>
        </tr>

        <!-- End of Drilling -->
        <tr>
          <td
            class="caption font-weight-bold text-uppercase text-no-wrap"
            v-text="$trans('End of Drilling')"
          ></td>
          <td colspan="100%" class="caption" v-text="relatedWell.end_of_drill"></td>
        </tr>

        <tr>
          <td colspan="100%" class="pa-0 h-0">
            <v-divider class="ma-0"></v-divider>
          </td>
        </tr>

        <!-- Post Drill Conclusion -->
        <tr>
          <td
            class="caption font-weight-bold text-uppercase"
            v-text="$trans('Post Drill Conclusion')"
          ></td>
          <td colspan="100%" class="caption" v-text="relatedWell.post_drill_conclusion"></td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import IntegerFilter from '@core/filters/IntegerFilter';
import { isEmpty, orderBy } from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'WellInformationTable',

  mixins: [ IntegerFilter ],

  props: {
    item: {
      type: [ Object, Array ],
      default: () => {},
    },
  },

  data: vm => ({
    well: vm.item.data?.selected,
  }),

  computed: {
    relatedWell () {
      return {
        ...this.well,
        ...this.well?.attributes,
      };
    },

    relatedWells () {
      return {
        selected: this.item.data?.selected,
        items: orderBy(Object.values(this.item.data.items), 'well_name'),
      };
    },

    hasRelatedWells () {
      const wells = this.relatedWells.items.filter(well => well.id !== this.relatedWell.id);
      return !isEmpty(wells);
    },

    hasBlock () {
      return this.relatedWell.block
    },
  },

  methods: {
    ...mapActions({
      setWell: 'report/setWell',
    }),

    changeWell (item) {
      this.well = item;
      this.setWell(item);
    },
  },
};
</script>
