<template>
  <portal to="appbar:subtitle">
    <div class="data-source-tray-filter__container">
      <v-layout justify-start align-center class="mx-3">
        <v-divider vertical class="mx-4 w-100"></v-divider>
        <!-- Main data source tray -->
        <v-layout
          data-tour-overlay
          data-tour-step="welcome/onboarding.6"
          justify-start class="w-100"
        >
          <shortkey-tooltip content="\">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :min-width="width"
              v-bind="$attrs"
            >
              <template v-slot:activator="{ on }">
                <slot
                  name="activator"
                  v-bind="{ on, toggleMenu, clear, size: project.selectedSize() }"
                >
                  <v-tooltip bottom transition="slide-y-transition">
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        v-shortkey="[ 'ctrl', '\\' ]"
                        :icon="isMobile"
                        :depressed="isDesktop"
                        large
                        v-on="{ ...on, ...tooltip }"
                        @shortkey="toggleMenu"
                        @click="clear"
                      >
                        <v-icon :left="isDesktop">mdi-tray-full</v-icon>
                        <span v-if="isDesktop" v-text="$trans('Select Data Sources')"></span>
                        <v-badge
                          v-if="project.selectedSize()"
                          :content="project.selectedSize()"
                          :inline="isDesktop"
                          :overlap="isDesktop"
                        ></v-badge>
                      </v-btn>
                    </template>
                    <span>{{ $trans('Source tray') }} ctrl+\</span>
                  </v-tooltip>
                </slot>
              </template>
              <v-card class="mt-2">
                <v-layout>
                  <v-spacer></v-spacer>
                  <app-popover icon="mdi-help-circle">
                    <p class="caption mb-0">
                      <strong class="font-weight-bold">{{ $trans('Data source tray') }}</strong>
                      {{ $trans('lets you pick what dataset to display in the application.') }}
                    </p>
                  </app-popover>
                </v-layout>
                <v-card-text class="pt-1">
                  <v-text-field
                    ref="eage-search"
                    v-model="searchQuery"
                    :label="$trans('Search Data Sources')"
                    :prepend-inner-icon="project.loading ? 'mdi-loading mdi-spin' : 'mdi-magnify'"
                    :append-icon="searchQuery ? 'mdi-close' : null"
                    autofocus
                    hide-details
                    single-line
                    outlined filled
                    rounded dense
                    autocomplete="off"
                    background-color="bar"
                    @click:append="clear"
                    @focus.native="focus"
                    @keyup="search"
                    @shortkey.native="focus"
                  ></v-text-field>
                </v-card-text>

                <!-- Selected Nodes -->
                <template v-if="project.selectedSize()">
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-group>
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $trans_choice('Selected dataset', project.selectedSize()) }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip dark small>{{ project.selectedSize() }}</v-chip>
                        </v-list-item-action>
                      </template>
                      <v-list-item
                        v-for="selected in project.selected" :key="selected.caption"
                      >
                        <v-list-item-avatar class="mr-0">
                          <v-avatar :color="selected.color" size="10"></v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <span class="caption" v-text="selected.caption"></span>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn x-small icon @click="removeChip(selected)">
                            <v-icon x-small>mdi-close</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                  <v-divider></v-divider>
                </template>
                <!-- Selected Nodes -->

                <!-- Source Tree -->
                <v-treeview
                  ref="datasource-tray-list"
                  v-model="project.selected"
                  :items="project.items"
                  :open-all="project.meta.openAll"
                  :search="searchQuery"
                  :selection-type="project.meta.selectionType"
                  dense
                  selectable
                  open-on-click
                  return-object
                  class="my-4"
                >
                  <template v-slot:label="{ item, selected }">
                    <v-chip
                      :color="selected ? item.color : null"
                      :dark="selected" label
                      v-text="item.name"
                    ></v-chip>
                  </template>
                </v-treeview>
                <!-- Source Tree -->

                <v-card-actions class="caption text--disabled">
                  <span class="px-1">
                    {{ $trans_choice(
                      'Selected {count} dataset',
                      project.selectedSize(), {
                        count: project.selectedSize(),
                      },
                    ) }}
                  </span>
                  <v-spacer></v-spacer>
                  <!-- Expand/collapse treeview button -->
                  <span
                    v-if="project.meta.openAll"
                    role="button"
                    class="s-link px-1"
                    @click="collapseTreeview"
                  >
                    {{ $trans('Collapse') }}
                  </span>
                  <span
                    v-else
                    role="button"
                    class="s-link px-1"
                    @click="expandTreeview"
                  >
                    {{ $trans('Expand') }}
                  </span>
                  <!-- Expand/collapse treeview button -->

                  <!-- Selec/unselect all -->
                  <span
                    v-if="project.isAllSelected()"
                    role="button"
                    class="s-link px-1"
                    @click="unselectAll"
                  >
                    {{ $trans('Clear all') }}
                  </span>
                  <span
                    v-else
                    role="button"
                    class="s-link px-1"
                    @click="selectAll"
                  >
                    {{ $trans('Select all') }}
                  </span>
                  <!-- Selec/unselect all -->

                  <!-- Reset to defaults -->
                  <span
                    role="button"
                    class="s-link px-1"
                    @click="resetToDefaults"
                  >
                    {{ $trans('Reset to defaults') }}
                  </span>
                  <!-- Reset to defaults -->
                </v-card-actions>
              </v-card>
            </v-menu>
          </shortkey-tooltip>
        </v-layout>
        <!-- Main data source tray -->
      </v-layout>
    </div>
  </portal>
</template>

<script>
import Project from '@/modules/Sourcetray/resources/Project';
import sources from '@/modules/Sourcetray/config/sources';
import Breakpoints from '@core/mixins/Breakpoints';
import { isEmpty } from 'lodash';

export default {
  name: 'DataSourceTrayFilter',

  mixins: [ Breakpoints ],

  props: {
    width: {
      type: [ String, Number ],
      default: 'auto',
    },
  },

  data: () => ({
    searchQuery: '',
    project: new Project({
      loading: false,
      meta: {
        sources,
        defaults: [],
        openAll: false,
        opened: [],
        selectionType: 'leaf',
      },
    }),
    idString: '',
    nodeResult: [],
  }),

  computed: {
    menu: {
      get () {
        return this.$store.getters['sourcetray/shown'];
      },
      set (isShown) {
        this.$store.dispatch('sourcetray/setShown', isShown);
      },
    },
    originalSelected () {
      const array = []
      if (this.project.selected) {
        this.project.selected.map(item => {
          const {id, ...filtered} = item;
          array.push({
            ...filtered,
            id: id.slice(-36),
          });
        });
      }
      return array;
    },
    emitObject () {
      return {
        items: this.originalSelected,
        query: {
          ...this.$route.query,
          projects_list: this.project.getSelectedItemsAsString(),
        },
      };
    },
  },

  watch: {
    'project.selected': function () {
      this.project.saveSourceTrayList(this.originalSelected);
      this.$emit('update:selected', this.emitObject);
    },
  },

  mounted () {
    this.fetch();
  },

  methods: {
    async fetch () {
      await this.project.list();
      this.project.items = this.getNodes(this.project.items);
      this.project.selected = this.findInitialSelected(this.project.selected);
    },

    search () {
      if (isEmpty(this.searchQuery)) {
        this.collapseTreeview();
      } else {
        this.expandTreeview();
      }
    },

    toggleMenu () {
      this.menu = !this.menu;
    },

    selectAll () {
      this.project.selectAll();
    },

    unselectAll () {
      this.project.unselectAll();
    },

    resetToDefaults () {
      this.project.unselectAll();
    },

    clear () {
      this.project.clearSearch();
    },

    focus () {
      this.$refs['eage-search'].focus();
    },

    expandTreeview () {
      if (this.$refs['datasource-tray-list']) {
        this.$refs['datasource-tray-list'].updateAll(true);
      }

      this.project.meta.openAll = true;
    },

    collapseTreeview () {
      if (this.$refs['datasource-tray-list']) {
        this.$refs['datasource-tray-list'].updateAll(false);
      }

      this.project.meta.openAll = false;
    },

    removeChip (selected) {
      this.project.unselect(selected);
    },
    
    getNodes(items){
      const array = [];
      items.map(item =>  {
        this.idString = this.idString.concat(item.id)
        const {id, children, ...filteredItem} = item;
        if (!children) {
          this.idString = '';
        }
        array.push({
          ...filteredItem,
          id: this.idString,
          children: children ? this.getNodes(children) : [],
        });
      });
      return array;
    },

    findInitialSelected(items){
      const array = []
      items.map(item => {
        this.searchNodes(item.caption, this.project.items);
        array.push(this.nodeResult);
      });
      return array;
    },

    searchNodes(caption, items){
      items.map(item => {
        if (item.caption === caption) {
          this.nodeResult = item;
        }
        this.searchNodes(caption, item.children);
      });
    },
  },
};
</script>
