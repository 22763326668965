<template>
  <div>
    <v-row>
      <v-col cols="12" class="pa-0">
        <img
          :src="$asset.getAppAsset('logos/combomark', {
            modifier: 'default.',
          })"
          :height="height"
          :width="width"
          v-bind="$attrs"
        >
      </v-col>
      <v-col cols="6" xl="12" lg="auto" md="auto" class="pt-0 mx-auto">
        <img
          :src="logoFixed"
          height="auto"
          width="60%"
          class="mt-4"
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AppBrandBanner',

  props: {
    width: {
      type: [ String, Number ],
      default: '100%',
    },
    height: {
      type: [ String, Number ],
      default: 'auto',
    },
  },
  computed: {
    logoFixed () {
      return require('@assets/logos/simple.ELASTICDOCS.png')
    },
  }
};
</script>
