import { sumBy } from 'lodash';

export const heat = {
  items: [],
  setItems (items) {
    this.items = items;
  },
  getItems () {
    return this.items;
  },
};

export const feature = {
  items: [],
  setItems (items) {
    this.items = items.map(item => ({ ...item, ...item.properties }));
  },
  getItems () {
    return this.items;
  },
};

export const polygon = {
  items: [],
  mapBy (key) {
    return this.items.map(i => i[key]);
  },
  getTotalCount () {
    return sumBy(this.items, 'doc_count');
  },
  setItems (items) {
    this.items = items;
  },
  getItems () {
    return this.items;
  },
};

export const mapGeometries = {
  isActive: true,
  isActiveLocal: (localStorage.getItem('search:mapGeometries/active') ?? 'true') !== 'false',
  setActive (isActive) {
    this.active = isActive;
    localStorage.setItem('search:mapGeometries/active', isActive);
  },
  activate () {
    this.setActive(true);
  },
  deactivate () {
    localStorage.removeItem('search:mapGeometries/active');
  },
};

export default {
  heat,
  feature,
  polygon,
  mapGeometries,
};
