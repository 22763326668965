<template>
  <app-content>
    <meta-tag :title="$trans('Datalake')"></meta-tag>
    <!-- Search -->
    <v-row justify="space-between" align="start">
      <v-col
        cols="12" lg="6"
        order="2"
        order-lg="1"
      >
        <portal-target name="header:left" slim></portal-target>
        <portal-target name="header:action" multiple></portal-target>
      </v-col>

      <v-col
        cols="12" md="4"
        order="1"
        order-lg="2"
      >
        <portal-target name="header:right" slim>
          <datalake-storage-info></datalake-storage-info>
        </portal-target>
      </v-col>
    </v-row>
    <!-- Search -->

    <!-- Category tabs -->
    <app-stickybar divider class="mt-8 mb-0">
      <template v-slot:default="{ unstuck }">
        <v-tabs
          v-model="datalake.meta.tabs.current"
          :class="{ 'mb-3': unstuck }"
          background-color="workspace"
        >
          <template v-for="(tab, i) in datalake.meta.tabs.items">
            <has-module :key="`module-key-${i}`" :name="tab.moduleKey">
              <app-can-view :code="tab.permission">
                <v-tab
                  :id="`tab-${i}-${(tab.name).toLowerCase()}`"
                  :key="i"
                  @click="selectTab(tab, i)"
                  v-text="tab.name"
                ></v-tab>
              </app-can-view>
            </has-module>
          </template>
        </v-tabs>
      </template>
    </app-stickybar>
    <v-tabs-items v-model="datalake.meta.tabs.current">
      <v-tab-item v-for="(tab, t) in datalake.meta.tabs.items" :key="t" :eager="tab.eager">
        <!-- Main Datatable -->
        <has-module :name="tab.moduleKey">
          <app-can-view :code="tab.permission">
            <v-card class="ov-hidden">
              <component
                :is="tab.component"
                :ref="`datalake-table-${tab.name}`"
                :key="t"
                :active="isTabActive(t)"
                :resource="tab.resource"
                :item="datalake.meta"
                :tab.sync="tab"
                @resource:update="r => onResourceUpdate(r, tab)"
              ></component>
            </v-card>
          </app-can-view>
        </has-module>
        <!-- Main Datatable -->
      </v-tab-item>
    </v-tabs-items>
    <!-- Category tabs -->

    <router-view></router-view>
  </app-content>
</template>

<script>
import Datalake from '@/modules/Datalake/resources/Datalake';
import { isString } from 'lodash';
import { mapGetters } from 'vuex';
import { tabs } from '@/modules/Datalake/config/tabs';

export default {
  layout: 'admin',

  data: () => ({
    datalake: new Datalake({
      options: {
        itemsPerPage: 100,
      },
      meta: {
        tabs,
        dataSource: '',
        selectedTab: 0,
      },
    }),
  }),

  computed: {
    ...mapGetters({
      itemsPerPage: 'datalake/itemsPerPage',
    }),

    currentRouteTab () {
      if (isString(this.$route.query.tab)) {
        const tabIndex = this.datalake.meta.tabs.findIndexByTabKey(this.$route.query.tab);
        return tabIndex;
      }

      return parseInt(this.datalake.meta.selectedTab, 10);
    },
  },

  watch: {
    itemsPerPage (value) {
      this.datalake.store.dispatch('datalake/setItemsPerPage', value);
    },

    currentRouteTab (currentRouteTab) {
      this.setCurrentTabFromRoute(currentRouteTab);
    },
  },

  mounted () {
    this.setCurrentTabFromRoute(this.currentRouteTab);

    if (this.$app.isAppMode('DATA_ATELIER')) {
      this.$store.dispatch('appbar/show');
      this.$store.dispatch('appbar/hideOnScroll', false);
    }
  },

  methods: {
    setCurrentTabFromRoute (currentRouteTab) {
      if (this.$route.query.tab) {
        this.datalake.meta.tabs.setCurrent(currentRouteTab);
      }
    },

    onResourceUpdate (resource, tab) {
      this.datalake.meta.tabs.setResource(resource, tab);
    },

    selectTab (item, i) {
      this.datalake.meta.tabs.setTab(item, i);
      const datalake = this.datalake.meta.tabs.getDataResource();

      if (datalake) {
        datalake.setQueryString({
          tab: item.tabKey.toString(),
          itemsPerPage: this.itemsPerPage,
        }).list();
      }
    },

    isTabActive (index) {
      return this.datalake.meta.tabs.current === index;
    },
  },
};
</script>
