<template>
  <v-list-item
    v-bind="$attrs" :to="filteredTo"
    :exact="exact"
    :link="link" color="primary"
  >
    <slot></slot>
  </v-list-item>
</template>

<script>
import { ACCOUNT_TYPE_APPRENTICE } from '@/modules/Billing/enums/AccountTypes';
export default {
  props: {
    /**
     * The url object of the navigation link.
     *
     * @type {Object}
     */
    to: {
      type: Object,
      required: true,
    },

    /**
     * Determine if the route should match exactly.
     *
     * @type {boolean}
     */
    exact: {
      type: Boolean,
    },

    /**
     * Determine if the route is a link.
     *
     * @type {boolean}
     */
    link: {
      type: Boolean,
    },
  },
  computed: {
    filteredTo () {
      if (this.to.name !== 'profile.api' || this.$store.getters['auth/user']?.data?.account_type?.name !== ACCOUNT_TYPE_APPRENTICE) {
        return this.to;
      }
      return { name: 'profile', query: { from: 'side' } };
    }
  },
};
</script>
