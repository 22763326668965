<template>
  <div>
    <v-alert v-if="datalake.hasErrors()" outlined text type="error" dismissible>
      <span
        class="login-error-message"
        v-text="datalake.getErrors('message', 'Something went wrong')"
      ></span>
    </v-alert>
    <!-- DataSource Tray -->
    <data-source-tray-filter
      width="400px"
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <portal to="header:left">
      <app-search-field
        v-model.trim.lazy="datalake.search"
        :title="'search datalake'"
        :loading="datalake.searching"
        :label="$trans('Search datalake')"
        :placeholder="$trans('Search datalake')"
        :hint="$trans('Datalake search is only limited to file names and file types. Go to the Search module for the whole corpus search.')"
        autofocus solo
        @click:clear="clearSearch"
        @enter:search="setSearch"
      ></app-search-field>
    </portal>

    <div data-tour-step="welcome/onboarding.5" data-tour-overlay>
      <v-data-table
        v-if="!imagesMode"
        :footer-props="{ 'items-per-page-options': datalake.options.rowsPerPage }"
        :dense="datalake.meta.dense"
        :headers="datalake.headers"
        :items="datalake.items"
        :loading="datalake.loading"
        :mobile-breakpoint="datalake.meta.mobileBreakpoint"
        :server-items-length="datalake.pagination.total"
        :show-select="datalake.meta.showSelect"
        :options.sync="datalake.options"
        @update:options="updateList"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <app-stickybar class="bg-white">
            <app-data-table-toolbar
              :size="datalake.selectedSize()"
              @click:clear="unselectAll"
            >
              <template>
                <v-switch
                  v-model="imagesMode"
                  :label="currentImagesMode"
                >
                </v-switch>
              </template>
            </app-data-table-toolbar>
            <v-spacer></v-spacer>
            <v-data-footer
              :title="'search datalake pagination'"
              class="no-border"
              :pagination="pagination"
              :options="options"
              :items-per-page-options="datalake.options.rowsPerPage"
              @update:options="updateOptions"
            ></v-data-footer>
          </app-stickybar>
          <v-divider></v-divider>
        </template>

        <template v-slot:item.doc_name="{ item }">
          <div class="text-truncate text-no-wrap" style="max-width: 420px;">
            <app-can-view code="report_viewer">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <router-link
                    :to="{
                      name: 'reports.single',
                      params: {
                        slug: item.id,
                      },
                      query: {
                        data_index: item.data_index,
                      },
                    }"
                    class="text-no-wrap"
                  >
                    <span v-on="on" v-text="item.doc_name"></span>
                  </router-link>
                </template>
                <span
                  v-text="$trans('View {name}', {
                    name: item.doc_name,
                  })"
                ></span>
              </v-tooltip>
              <template v-slot:alt>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" v-text="item.doc_name"></span>
                  </template>
                  <span
                    v-text="$trans('View {name}', {
                      name: item.doc_name,
                    })"
                  ></span>
                </v-tooltip>
              </template>
            </app-can-view>
          </div>
        </template>

        <template v-slot:item.owner="{ item }">
          <span class="text-no-wrap text-truncate" v-text="item.owner"></span>
        </template>

        <template v-slot:item.update_date="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.update_date | toDateFromNow }}</span>
            </template>
            <span v-text="item.update_date"></span>
          </v-tooltip>
        </template>

        <template v-slot:item.file_type="{ item }">
          <div class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon
                    :color="getFileTypeColor(item.file_type)"
                    left small
                  >
                    {{ getFileTypeIcon(item.file_type) }}
                  </v-icon>
                  <span class="text-uppercase" v-text="item.file_type"></span>
                </div>
              </template>
              <span class="text-uppercase" v-text="item.file_type"></span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.file_size="{ item }">
          <span>{{ item.file_size | toMb }}</span>
        </template>
      </v-data-table>
      
      <div v-else>
        <app-stickybar class="bg-white">
          <app-data-table-toolbar
            :size="datalake.selectedSize()"
            @click:clear="unselectAll"
          >
            <template>
              <v-switch
                v-model="imagesMode"
                :label="currentImagesMode"
              >
              </v-switch>
            </template>
          </app-data-table-toolbar>
          <v-spacer></v-spacer>
          <v-data-footer
            :title="'search datalake pagination'"
            class="no-border"
            :pagination="datalake.pagination"
            :options="datalake.options"
            :items-per-page-options="datalake.options.rowsPerPage"
            @update:options="updateList"
          ></v-data-footer>
        </app-stickybar>
        <v-divider></v-divider>
        <div class="item-list">
          <images-data-iterator
            :items="datalake.items"
            :loading="datalake.loading"
            :hide-default-footer="true"
            :items-per-row="6"
            :mobile-breakpoint="datalake.meta.mobileBreakpoint"
            :options.sync="datalake.options"
            :server-items-length="datalake.pagination.total"
            :show-select="datalake.meta.showSelect"
            @update:options="updateList"
          >
            <template v-slot:no-data>
              <template v-if="datalake.hasSearch()">
                <images-data-iterator-empty-state>
                  <template v-slot:icon>
                    <div class="secondary--text text-center mx-auto">
                      <icon-landscape-image></icon-landscape-image>
                    </div>
                  </template>
                </images-data-iterator-empty-state>
              </template>
              <template v-else-if="datalake.hasErrors()">
                <v-alert outlined text type="error" dismissible>
                  <span
                    class="login-error-message"
                    v-text="image.getErrors('message', 'Something went wrong')"
                  ></span>
                </v-alert>
              </template>
              <template v-else>
                <images-datalake-data-iterator-empty-state></images-datalake-data-iterator-empty-state>
              </template>
            </template>
            <template v-slot:item="{ item }">
              <images-data-iterator-thumbnail
                :height="height"
                :id="`image-thumbnail-${item.id}`"
                :src="getProcessedImageUrl(item.id)"
                >
                <template v-slot:default="{ hover }">
                  <v-layout
                    fill-height align-center
                    align-content-center
                    justify-center
                    column
                  >
                    <v-overlay :value="hover" absolute title="images thumb overlay">
                      <slot name="button.link" v-bind="{ item }">
                        <v-btn
                          :to="{
                            name: 'datalake.single',
                            params: { slug: item.id },
                            query: $route.query
                          }" 
                          exact
                          dark
                          small outlined
                          class="mb-4"
                          width="100%"
                        >
                          <v-icon left small>mdi-magnify-plus-outline</v-icon>
                          {{ $trans('Zoom in') }}
                        </v-btn>
                      </slot>
                      <slot name="overlay" v-bind="{ item, hover }">
                        <v-layout fill-height column justify-center>                            
                          <slot name="button.go-to-report" v-bind="{ item }">
                            <v-btn
                              :to="{
                                name: 'reports.single',
                                params: {
                                  slug: item.id,
                                },
                                query: {
                                  data_index: item.data_index,
                                },
                              }"
                              dark
                              small outlined
                              class="mb-4"
                              title="images overlay button goto"
                            >
                              <v-icon left small>mdi-file-eye-outline</v-icon>
                              {{ $trans(`Open Report`) }}
                            </v-btn>
                          </slot>
                        </v-layout>
                      </slot>
                    </v-overlay>
                  </v-layout>
                </template>
                <template v-slot:append>
                  <v-layout class="caption text-truncate text--disabled">

                    <router-link
                      :to="{
                        name: 'reports.single',
                        params: {
                          slug: item.id,
                        },
                        query: {
                          data_index: item.data_index,
                          back: $route.fullPath,
                        },
                      }"
                      class="caption text-truncate text--disabled"
                    >
                      <span v-text="item.doc_name"></span>
                    </router-link>
                  </v-layout>
                  <slot name="item.append" v-bind="{ item }"></slot>
                </template>
              </images-data-iterator-thumbnail>
            </template>
          </images-data-iterator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datalake from '@/modules/Datalake/resources/Datalake';
import GetFileTypeIcon from '@core/mixins/GetFileTypeIcon';
import PrettyBytes from '@core/filters/PrettyBytes';
import PrettyDates from '@core/filters/PrettyDates';
import { mapGetters } from 'vuex';

export default {
  name: 'Documents',

  mixins: [ GetFileTypeIcon, PrettyBytes, PrettyDates ],

  props: {
    resource: {
      type: Object,
      default: () => {},
    },

    tab: {
      type: Object,
      default: () => {},
    },
  },

  data: vm => ({
    datalake: new Datalake(vm.resource),
    imagesMode: null,
    height: 350,
    processedImageUrls: {}
  }),

  computed: {
    ...mapGetters({
      itemsPerPage: 'datalake/itemsPerPage',
    }),
    currentImagesMode(){
      return this.imagesMode ? 'Preview Mode' : 'Datalake Mode'
    },
    currentModeQuery() {
      return this.imagesMode ? 'preview' : 'datalake'
    }
  },

  watch: {
    itemsPerPage (value) {
      this.datalake.store.dispatch('datalake/setItemsPerPage', value);
    },
    async "datalake.items"() {
      await this.processImageUrls(this.datalake.items);
    }
  },

  created() {
    this.imagesMode = this.$route.query.mode === 'preview'
  },

  mounted () {
    this.$emit('resource:update', this.datalake);
  },

  methods: {
    changedDataSource ({ query }) {
      this.datalake.setQueryString(query).list();
    },

    updateList (options) {
      options = {
        ...options,
        mode: this.imagesMode ? 'preview' : 'datalake'
      } 
      this.datalake.store.dispatch('datalake/setItemsPerPage', options.itemsPerPage);
      this.datalake.setQueryString(options).list();
    },

    setSearch (e) {
      this.datalake.setSearch(e.target.value).list();
    },

    clearSearch () {
      this.datalake.clearSearch();
    },

    unselectAll () {
      this.datalake.unselectAll();
    },
    parsePDFImagesUrl (item) {
      const split = item.pdf_images_url.split('?');
      const pageNumber = 1;
      const pageSize = parseInt(pageNumber, 10);
      const urlPages = [];
      
      for (let i = 1; i <= pageSize; i++) {
        urlPages.push({
          page: i,
          pageNum: pageNumber,
          url: `${split[0]}/${i}.png?${split[1]}`,
          ocrData: [],
          totalOCRData: 0,
        });
      }
      return urlPages[0].url;
    },
    async checkImageHeight(url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          if (img.height/img.width > 2.5 || img.width/img.height > 2.5) {
            resolve(require('@assets/broken-image.png'));
          } else {
            resolve(url);
          }
        };
        img.src = url;
      });
    },
    async processImageUrls(items) {
      const promises = items.map(async (item) => {
        const url = await this.checkImageHeight(this.parsePDFImagesUrl(item));
        this.$set(this.processedImageUrls, item.id, url);
      });
      await Promise.all(promises);
    },
    getProcessedImageUrl(url) {
      return this.processedImageUrls[url] || url;
    },
  },
};
</script>
