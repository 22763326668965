<template>
  <v-layout column justify-center>
    <slot name="icon">
      <div class="accent--text text-center">
        <icon-landscape-image></icon-landscape-image>
      </div>
    </slot>
    <div
      class="my-6 text-center text--disabled"
      v-text="$trans('No images found')"
    ></div>
  </v-layout>
</template>

<script>
export default {
  name: 'ImagesDataIteratorEmptyState',
};
</script>
