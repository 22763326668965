import Vue from 'vue';
import App from '@core/App.vue';
import './registerServiceWorker';
import './plugins/load-script';
import './plugins/axios';
import './plugins/vue-session';
import router from '@core/router';
import store from '@core/store';
import '@core/plugins';
import vuetify from './plugins/vuetify';
import './plugins/vue-word-cloud';
import './plugins/vee-validate';
import './plugins/portal-vue';
import './plugins/vue-tour';
import './plugins/meta';
import './plugins/chart';
import '@/sass/app.scss';
import '@/sass/fonts.scss';
import i18n from './i18n';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
