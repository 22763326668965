var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-content',[_c('meta-tag',{attrs:{"title":_vm.$trans('File Sets')}}),_c('data-source-tray-filter',{attrs:{"width":"400px"},on:{"update:selected":_vm.changedDataSource}}),_c('v-row',{attrs:{"justify":"space-between","align":"start"}},[_c('v-col',{attrs:{"cols":"12","lg":"6","order":"2","order-lg":"1"}},[_c('app-search-field',{attrs:{"loading":_vm.set.searching,"label":_vm.$trans('Search file sets'),"placeholder":_vm.$trans('Search file sets'),"autofocus":"","solo":""},on:{"click:clear":_vm.clearSearch,"enter:search":_vm.setSearch},model:{value:(_vm.set.search),callback:function ($$v) {_vm.$set(_vm.set, "search", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"set.search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","order":"1","order-lg":"2"}},[_c('portal-target',{attrs:{"name":"header:right","slim":""}},[_c('file-data-storage-info')],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-8 ov-hidden"},[_c('v-data-table',{staticClass:"hover-checkbox--disabled",attrs:{"footer-props":{ 'items-per-page-options': _vm.set.options.rowsPerPage },"dense":_vm.set.meta.dense,"headers":_vm.set.headers,"items":_vm.set.items,"loading":_vm.set.loading,"mobile-breakpoint":_vm.set.meta.mobileBreakpoint,"server-items-length":_vm.set.pagination.total,"show-select":_vm.set.meta.showSelect,"options":_vm.set.options},on:{"update:options":[function($event){return _vm.$set(_vm.set, "options", $event)},_vm.updateList]},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('app-stickybar',{staticClass:"bg-white align-center"},[_c('app-data-table-toolbar',{attrs:{"size":_vm.set.selectedSize()},on:{"click:clear":_vm.unselectAll}},[_c('file-data-table-manager-filter-bar',{staticClass:"ml-2",on:{"click:filter":_vm.onFilter}})],1),_c('v-spacer'),_c('v-data-footer',{staticClass:"no-border",attrs:{"pagination":pagination,"options":options,"items-per-page-options":_vm.set.options.rowsPerPage},on:{"update:options":updateOptions}})],1),_c('v-divider')]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{class:{ 'muted--text': item.isNotSelectable },attrs:{"to":{
                name: 'sets.approval',
                params: { id: item.id },
                query: { back: _vm.$route.fullPath },
              }},domProps:{"textContent":_vm._s(item.title)}})]}},{key:"item.storage_used",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:{ 'muted--text': item.isNotSelectable }},[_vm._v(_vm._s(_vm._f("toMb")(item.storage_used)))])]}},{key:"item.date_added",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-no-wrap",class:{ 'muted--text': item.isNotSelectable }},on),[_vm._v(_vm._s(_vm._f("toDateFromNow")(item.date_added)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateTime")(item.date_added)))])])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-alert',{staticClass:"text-center pa-1 ma-2",staticStyle:{"line-height":"1"},attrs:{"color":item.status.color,"dense":"","outlined":"","text":"","width":"180px"}},[_c('span',{staticClass:"caption",domProps:{"textContent":_vm._s(item.status.text)}})])]}},{key:"item.total_files",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:{ 'muted--text': item.isNotSelectable },domProps:{"textContent":_vm._s(item.total_files)}})]}},{key:"item.added_by_full_name",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap",class:{ 'muted--text': item.isNotSelectable },domProps:{"textContent":_vm._s(item.added_by_full_name)}})]}},{key:"item.project_name",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:{ 'muted--text': item.isNotSelectable },domProps:{"textContent":_vm._s(item.project_name)}})]}}]),model:{value:(_vm.set.selected),callback:function ($$v) {_vm.$set(_vm.set, "selected", $$v)},expression:"set.selected"}})],1)],1)],1),_c('app-right-sidebar-area',{attrs:{"value":_vm.isRightSidebarAreaShown,"width":"420px","color":"grey lighten-4"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('p',{staticClass:"body-1 mb-10 muted--text",domProps:{"textContent":_vm._s(_vm.$trans_choice('Selected {count} Sets', _vm.set.selectedSize(), {
              count: _vm.set.selectedSize(),
            }))}}),_c('file-audit-approval-batch-form',{attrs:{"items":_vm.set.selected},on:{"click:submitted":_vm.onSubmitted}})],1)],1)]},proxy:true}])},[_c('div',{staticClass:"d-block w-100 mt-5 px-6 right-sidebar__content"},[_c('v-layout',{staticClass:"align-center mb-4"},[_c('h1',{domProps:{"textContent":_vm._s(_vm.$trans('Batch Approval'))}}),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":_vm.closeSidebar}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"body-2 muted--text",domProps:{"textContent":_vm._s(_vm.$trans('The following sets will be approved or rejected. Add or remove by selecting or unselecting the checkbox from the table.'))}}),_c('div',{staticClass:"body-2 muted--text",domProps:{"textContent":_vm._s(_vm.$trans('You may also approve / reject individual sets by clicking the set below. You will be redirected to a new page.'))}})]),_vm._l((_vm.set.selected),function(set,i){return _c('div',{key:i},[_c('v-card',{staticClass:"mb-2",attrs:{"dense":""}},[_c('v-card-text',[_c('v-layout',{staticClass:"align-center"},[_c('div',[_c('router-link',{staticClass:"link--text font-weight-bold",attrs:{"to":{
                    name: 'sets.approval',
                    params: { id: set.id },
                  },"target":"_blank"},domProps:{"textContent":_vm._s(set.title)}})],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":"","transition":"slide-x-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return set.unselect(set)}}},on),[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Remove from batch'))}})])],1)],1)],1)],1)})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }