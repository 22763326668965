const STATE_KEY_INITIAL = 'initial';
const STATE_KEY_READY = 'ready';
const STATE_KEY_UNREADY = 'unready';

export default {
  name: 'search',
  state: STATE_KEY_INITIAL,
  isInitial () {
    return this.state === STATE_KEY_INITIAL;
  },
  isReady () {
    return this.state === STATE_KEY_READY;
  },
  isState (state) {
    return this.state === state;
  },
  getState () {
    return this.state;
  },
  setState (state) {
    this.state = state;
  },
  unsetState () {
    this.state = null;
  },
  makeInitial () {
    this.state = STATE_KEY_INITIAL;
  },
  makeReady () {
    this.setState(STATE_KEY_READY);
  },
  makeUnready () {
    this.setState(STATE_KEY_UNREADY);
  },
};
