import { coordsToGeoJson } from '@/modules/Dashboard/utils/map';
import Map from '@/modules/Search/resources/Map';
import {
  GET_BARGRAPH_SUMMARY_URL,
  GET_BARGRAPH_LIST_URL,
  EXPORT_BARGRAPH_URL,
  POST_VIZ_SPATIAL_WELL_FITLER,
} from '@/modules/Webgis/api/viz';
import L from 'leaflet';
import 'leaflet.minichart/dist/leaflet.minichart.min.js';
import {
  isNil,
  isEmpty,
  isArray,
  max,
  min,
  sum,
  size,
} from 'lodash';
import { DEFAULT_FIT_BOUNDS_PADDING } from '@/modules/Dashboard/config/maps';
import { API_PER_PAGE_ALL } from '@config/api';
import { mapColorToLabel } from '@/modules/Webgis/config/colors';
import fileDownload from 'js-file-download';
import moment from 'moment';
import {
  GLOBAL_BARGRAPH_SCALE,
  DEFAULT_BARGRAPH_STYLE,
  DEACTIVE_BARGRAPH_STYLE,
  ACTIVE_BARGRAPH_STYLE,
  GREEN_ACTIVE_BARGRAPH_STYLE,
} from '@/modules/Webgis/config/viz';

export default class BarGraphDataViz extends Map {
  constructor (map) {
    super();

    this.map = map;
    this.barGraphLayer = L.featureGroup();
    this.oldBarGraphLayer = L.featureGroup();
    this.meta.isFetchingSummary = false;
  }

  async listSummary () {
    this.disableAxiosResponseHandlers();

    try {
      if (this.isEnabled()) {
        this.meta.isFetchingSummary = true;

        const params = { projects_list: this.getProjectIds() };
        const { data } = await this.axios.get(GET_BARGRAPH_SUMMARY_URL, { params });

        this.store.dispatch('viz/bargraph/setData', {
          type: 'summary',
          data: data.data,
          total: data.data.total_wells,
          projects: this.getCurrentProjects(),
        });

        this.meta.isFetchingSummary = false;
        if (this.barGraphLayer) {
          this.barGraphLayer.bringToFront();
        }
      }
    } catch (e) {
      console.warn(e);
    } finally {
      this.enableAxiosResponseHandlers();
    }
  }

  async listMapMarkers () {
    const user = this.store.getters['auth/user'];

    if (user.isPermittedTo('webgis_bar_graph')) {
      const params = {
        projects_list: this.getProjectIds(),
        order_by: 'well_name',
        page_size: API_PER_PAGE_ALL,
        page: 1,
      };

      const { data } = await this.axios.get(GET_BARGRAPH_LIST_URL, { params });

      const items = data.data.map(item => ({
        ...item,
        id: item.well_id,
        attributes: item,
        sum: sum(item.values),
      }));

      if (this.barGraphLayer) {
        this.barGraphLayer.clearLayers();
      }

      await this.store.dispatch('viz/bargraph/setData', {
        type: 'list',
        data: items,
        total: size(items),
        projects: this.getCurrentProjects(),
      });

      this.setItems(items);

      this.setItemsAsBackgroundMarkers();

      if (this.barGraphLayer) {
        this.barGraphLayer.bringToFront();
        this.map.addLayer(this.barGraphLayer);

        if (!isEmpty(this.barGraphLayer.getBounds())) {
          this.map.fitBounds(this.barGraphLayer.getBounds(), {
            padding: DEFAULT_FIT_BOUNDS_PADDING,
          });
        }
      }

      this.map.on('click', () => {
        if (this.isEnabled()) {
          this.resetList();
        }
      });
    }
  }

  async listGeoFilteredWells (geoPolygon) {
    this.startLoading();
    this.barGraphLayer.eachLayer(l => l.setStyle(ACTIVE_BARGRAPH_STYLE));

    const user = this.store.getters['auth/user'];

    if (user.isPermittedTo('webgis_bar_graph') && !isEmpty(geoPolygon)) {
      this.store.dispatch('viz/bargraph/setAsFilteredResults');
      this.meta.isSpatialFiltered = true;

      const params = {
        geo_polygon: geoPolygon,
      };

      const { data } = await this.axios.post(POST_VIZ_SPATIAL_WELL_FITLER, params);

      if (isArray(data.data)) {
        const spatialWells = data.data.map(i => i.id);

        const { data: wells } = await this.axios.get(GET_BARGRAPH_SUMMARY_URL, {
          params: {
            well_id_list: spatialWells.join(','),
          },
        });

        this.store.dispatch('viz/bargraph/setData', {
          type: 'summary',
          wells: spatialWells,
          data: wells.data,
          total: wells.data.total_wells,
          projects: this.getCurrentProjects(),
        });

        this.barGraphLayer.eachLayer(l => {
          if (spatialWells.includes(l.options.metadata.id)) {
            l.setStyle(GREEN_ACTIVE_BARGRAPH_STYLE);
          } else {
            l.setStyle(DEACTIVE_BARGRAPH_STYLE);
          }
        });
      }
    }

    this.stopLoading();
  }

  setItems (items) {
    this.items = items.map(item => ({
      ...item,
      sum: sum(item.values),
      id: item.well_id,
      attributes: item,
    }));
  }

  setItemsAsBackgroundMarkers () {
    coordsToGeoJson(this.getItems()).forEach(feature => {
      this.setBackgroundMarkerWithPopup({ ...feature, ...feature.properties });
    });
  }

  setBackgroundMarkerWithPopup (data) {
    if (isEmpty(data.values)) {
      return {};
    }

    const {
      popup, latLng,
    } = this.setMarkerWithPopup(data);

    const scale = this.getMapMarkerScale({
      values: data.values,
    });

    const layer = L.circleMarker(latLng, {
      stroke: true,
      color: 'rgba(00, 00, 00, 0.8)',
      weight: 0.5,
      fill: true,
      fillColor: 'rgba(00, 00, 00, 0.8)',
      fillOpacity: 1,
      radius: scale,
      riseOnHover: true,
      metadata: data,
      opacity: 0.5,
      ...DEACTIVE_BARGRAPH_STYLE,
    });

    layer.setRadius(scale);

    if (this.barGraphLayer) {
      this.barGraphLayer.addLayer(layer);
    }

    const settings = this.store.getters['viz/bargraph/currentSettings'];
    layer.bindTooltip(`<div>
      <div><strong>Latitude</strong>: <span>${data.latitude}</span</div>
      <div><strong>Longitude</strong>: <span>${data.longitude}</span</div>
      <div><strong>${settings.title}</strong>: <span>${data.sum.toFixed(2)}</span</div>
    </div>`);

    layer.on('click', e => {
      L.DomEvent.stopPropagation(e);
      this.barGraphLayer.eachLayer(l => l.setStyle(DEACTIVE_BARGRAPH_STYLE));
      layer.setStyle(ACTIVE_BARGRAPH_STYLE);
      this.onBarGraphClick({ layer: e, data });
    });

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  async onBarGraphClick (item) {
    this.disableAxiosResponseHandlers();

    try {
      if (item?.data?.id) {
        this.store.dispatch('viz/bargraph/startLoading');
        this.store.dispatch('viz/bargraph/setAsFilteredResults');
        const params = { well_id_list: item.data.id };
        const { data } = await this.axios.get(GET_BARGRAPH_LIST_URL, { params });
        this.setData(data.data[0]);
        this.store.dispatch('viz/bargraph/setData', {
          total: 0,
          type: 'single',
          data: data.data[0],
          well: data.data[0],
          projects: this.getCurrentProjects(),
        });

        setTimeout(() => {
          this.store.dispatch('viz/bargraph/show');
        }, 300);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.enableAxiosResponseHandlers();
      this.store.dispatch('viz/bargraph/stopLoading');
    }
  }

  getBarGraphColors (labels) {
    return mapColorToLabel(labels);
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }

  async onClearDrawFilters () {
    this.listSummary();

    if (this.barGraphLayer) {
      this.barGraphLayer.eachLayer(l => l.setStyle(ACTIVE_BARGRAPH_STYLE));
    }
  }

  showMapMarkers () {
    this.barGraphLayer = this.oldBarGraphLayer ?? this.barGraphLayer;
    if (this.barGraphLayer) {
      this.setMapMarkerColors(this.meta.selectedWells);
      this.barGraphLayer.eachLayer(l => l.setStyle(ACTIVE_BARGRAPH_STYLE));
      this.map.addLayer(this.barGraphLayer);
    }
  }

  hideMapMarkers () {
    this.oldBarGraphLayer = this.barGraphLayer;
    if (this.barGraphLayer) {
      this.barGraphLayer.eachLayer(l => l.setStyle({ opacity: 0 }));
      this.map.removeLayer(this.barGraphLayer);
    }
  }

  getCurrentProjects () {
    const ids = this.store.getters['sourcetray/sources']?.map(i => i.id);

    return this.store.getters['sourcetray/projects'].filter(i => ids.includes(i.id));
  }

  exportItemOrAll (id) {
    if (isNil(id)) {
      this.export(null);
    } else {
      this.export(id);
    }
  }

  async export (wells, key = 'well_id_list') {
    const formData = new FormData;

    if (isNil(wells)) {
      formData.append('projects_list', this.getProjectIds());
    } else if (isArray(wells)) {
      formData.append(key, isArray(wells) ? wells.map(i => i?.well_id ?? i).join(',') : wells.well_id);
    } else {
      formData.append(key, wells.well_id);
    }

    const { data } = await this.axios.post(EXPORT_BARGRAPH_URL, formData, {
      headers: {
        'Content-Type': 'arraybuffer',
      },
    });

    const date = moment().format('YYYY-MM-DD-hhmmss');
    return fileDownload(data, `bargraph-data-exported-${date}.csv`);
  }

  isEnabled () {
    const viz = this.store.getters['viz/viz/viz'];

    return viz.isSelected('barGraph');
  }

  getMapMarkerScale ({ values }, itemWells = null) {
    const items = itemWells ?? this.getItems()
      .filter(i => !isEmpty(i.values))
      .map(i => ({ ...i, sum: sum(i.values) }));

    const wells = {
      max: max(items.map(i => i.sum)),
      min: min(items.map(i => i.sum)),
    };

    const currentSum = sum(values);
    const globalMax = GLOBAL_BARGRAPH_SCALE.max;
    const globalMin = GLOBAL_BARGRAPH_SCALE.min;
    const currentMin = wells.min;
    const currentMax = wells.max;
    const slope = (globalMax - globalMin) / (currentMin - currentMax);
    const xValue = currentSum;
    const scale = Math.abs((slope * xValue) - (slope * currentMax) + globalMax);

    return scale.toFixed(2) / 2;
  }

  setMapMarkerColors (wells) {
    this.meta.selectedWells = wells;
    const wellIds = isArray(wells)
      ? (wells ?? []).map(i => i?.well_id ?? i)
      : wells?.well_id;

    if (wellIds) {
      this.barGraphLayer.eachLayer(l => l.setStyle(DEFAULT_BARGRAPH_STYLE));
      this.barGraphLayer.eachLayer(l => {
        if ((wellIds ?? []).includes(l.options.metadata.id)) {
          l.setStyle(GREEN_ACTIVE_BARGRAPH_STYLE);
        } else {
          l.setStyle(DEFAULT_BARGRAPH_STYLE);
        }
      });
    }
  }

  async onSegmentClick (item) {
    this.store.dispatch('viz/bargraph/setAsFilteredResults');
    console.log(this.store.getters['viz/bargraph/bargraph'].isFiltered());
    const filteredItems = this.items.filter(i => {
      const index = i.labels.findIndex(label => label === item.label);
      return index > 0;
    }).map(i => {
      const index = i.labels.findIndex(label => label === item.label);
      const value = i.values[index];
      return { ...i, sum: value };
    });

    this.barGraphLayer.eachLayer(l => {
      const index = l.options.metadata.labels.findIndex(label => label === item.label);

      if (index > 0) {
        // Exists
        const well = filteredItems.find(i => i.id === l.options.metadata.id);
        const radius = this.getMapMarkerScale({ values: [ well.sum ] }, filteredItems);
        l.setRadius(radius);
      } else {
        l.setRadius(0);
      }
    });
  }

  resetList () {
    const isSpatialOn = this.store.getters['webgis/spatial/isOn'];

    if (!this.meta.isFetchingSummary && this.isEnabled() && !isSpatialOn) {
      if (this.barGraphLayer) {
        this.barGraphLayer.eachLayer(l => l.setStyle({ opacity: 0.5 }));
      }
      this.listSummary();
      this.resetItemsBackgroundMarkersRadius();
    } else {
      console.log('Not fetching. Bar graph wells are already being fetched or spatial filter is on');
    }
  }

  resetItemsBackgroundMarkersRadius () {
    this.store.dispatch('viz/bargraph/unsetAsFilteredResults');

    coordsToGeoJson(this.getItems()).forEach(feature => {
      const data = { ...feature, ...feature.properties };
      const scale = this.getMapMarkerScale({
        values: data.values,
      });

      this.barGraphLayer.eachLayer(l => {
        if (l.options.metadata.id === data.id) {
          l.setRadius(scale);
        }
      });
    });
  }
}
