import MapDataResource from '@/modules/Dashboard/resources/Map';
import HeatmapOverlay from 'leaflet-heatmap';
import L from 'leaflet';
import {
  ZOOM,
  BOUNDS,
  Z_INDEX,
  MAX_ZOOM,
  MIN_ZOOM,
  MAP_CENTER,
  MAP_LAYER_URL,
  HEATMAP_CONFIG,
  RESULTS_WELL_OPTIONS,
  POLYGON_HIGHLIGHT_OPTIONS,
  DEFAULT_FIT_BOUNDS_PADDING,
  POLYGON_DASHED_HIGHLIGHT_OPTIONS,
} from '@/modules/Dashboard/config/maps';
import { draw } from '@/modules/Search/config/map';
import { toGeoJson, getCoordinateFromCardinalCoordinate } from '@/modules/Dashboard/utils/map';
import { API_PER_PAGE_ALL } from '@config/api';
import { GET_WELLS_INFO_URL } from '@/modules/Dashboard/api/wells';
import { isEmpty, isNil, chunk } from 'lodash';
import { scaleLog } from 'd3';

export default class Map extends MapDataResource {
  constructor (options = {}) {
    super(options);

    this.L = L;

    this.setEvents({
      ...this.events,
      onMapLoad: () => {},
      onMapLoaded: e => this.onMapLoaded(e),
      onBeforeDrawCreated: () => {},
      onDrawCreated: () => {},
      onMapMarkerClicked: () => {},
      ...options.events,
    });
  }

  onMapLoaded (e) {
    super.onMapLoaded(e);
  }

  initialize () {
    if (!document.querySelector(`#${this.id}`)) {
      return;
    }

    this.startLoading();

    this.map = L.map(this.id, {
      zoomControl: this.getOption('zoomControl', false),
      maxZoom: this.getOption('maxZoom', MAX_ZOOM),
      minZoom: this.getOption('minZoom', MIN_ZOOM),
      timeout: 30000,
    });

    this.setupFeatureGroups();
    this.events.onMapLoad(this.map);

    this.setControlsToDefault();
    this.setTileLayerToDefault();
    this.setMapMarkersToDefault();
    this.setMapViewToDefault();

    this.setupDrawHandlers();
    this.addDefaultMapEventListeners();

    this.setupMeasureControl();

    this.events.onMapLoaded(this.map);

    this.invalidateSize();
    this.stopLoading();
  }

  setMapViewToDefault () {
    if (this.map) {
      if (this.map.hasLayer(this.drawLayer)) {
        this.fitToBoundsOrCenterView(this.drawLayer);
      } else {
        this.map.setView(MAP_CENTER, this.getOption('zoom', ZOOM));
      }
    }
  }

  setControlsToDefault () {
    L.control.scale().addTo(this.map);
  }

  setTileLayerToDefault () {
    L.tileLayer(
      MAP_LAYER_URL, {
        zIndex: this.getOption('zIndex', Z_INDEX),
        center: this.getOption('center', MAP_CENTER),
        maxZoom: this.getOption('maxZoom', MAX_ZOOM),
      },
    ).addTo(this.map);
  }

  setupFeatureGroups () {
    this.backgroundLayer = L.featureGroup();
    this.drawLayer = L.featureGroup();
    this.resultsLayer = L.featureGroup();
    this.selectedLayer = L.featureGroup();
    this.heatLayer = new HeatmapOverlay(HEATMAP_CONFIG);

    this.map.addLayer(this.backgroundLayer);
    this.map.addLayer(this.drawLayer);
    this.map.addLayer(this.resultsLayer);
    this.map.addLayer(this.selectedLayer);
    this.map.addLayer(this.heatLayer);
  }

  setupDrawHandlers () {
    this.createDrawControlHandler();
    this.createPolygonDrawHandler();
    this.createRectangleDrawHandler();

    this.drawHandlers = [
      this.polygonDrawHandler,
      this.rectangleDrawHandler,
    ];
  }

  createDrawControlHandler () {
    this.drawControlHandler = new L.Control.Draw(draw.options(this.drawLayer));
  }

  createPolygonDrawHandler () {
    this.polygonDrawHandler = new L.Draw.Polygon(
      this.map,
      this.drawControlHandler.options.draw.polygon,
    );
  }

  createRectangleDrawHandler () {
    this.rectangleDrawHandler = new L.Draw.Rectangle(
      this.map,
      this.drawControlHandler.options.draw.rectangle,
    );
  }

  clearAllDrawHandlers () {
    this.store.dispatch('map/setAsUndrawn');
    this.disableAllDrawHandlers();
    this.clearDrawLayer();
  }

  enableAllDrawHandlers () {
    if (!isEmpty(this.drawHandlers)) {
      this.drawHandlers.forEach(handler => handler.enable());
    }
  }

  disableAllDrawHandlers () {
    if (!isEmpty(this.drawHandlers)) {
      this.drawHandlers.forEach(handler => handler.disable());
    }
  }

  enablePolygonDrawHandler () {
    this.store.dispatch('webgis/spatial/showSpatialButton');
    this.polygonDrawHandler.enable();
  }

  disablePolygonDrawHandler () {
    this.store.dispatch('webgis/spatial/hideSpatialButton');
    this.polygonDrawHandler.disable();
  }

  isPolygonDrawHandlerEnabled () {
    return this.polygonDrawHandler && this.polygonDrawHandler?._enabled;
  }

  togglePolygonDrawHandler () {
    if (this.isPolygonDrawHandlerEnabled()) {
      this.disablePolygonDrawHandler();
    } else {
      this.enablePolygonDrawHandler();
    }
  }

  enableRectangleDrawHandler () {
    this.rectangleDrawHandler.enable();
  }

  disableRectangleDrawHandler () {
    this.rectangleDrawHandler.disable();
  }

  isRectangleDrawHandlerEnabled () {
    return this.rectangleDrawHandler?._enabled;
  }

  toggleRectangleDrawHandler () {
    if (this.isRectangleDrawHandlerEnabled()) {
      this.disableRectangleDrawHandler();
    } else {
      this.enableRectangleDrawHandler();
    }
  }

  setMapMarkersToDefault () {
    this.listBackgroundMarkers();
  }

  addDefaultMapEventListeners () {
    this.addMapOnDrawCreatedListener();
  }

  addMapOnDrawCreatedListener () {
    this.map.on('draw:created', e => {
      const { layer: drawLayer } = e;
      const geoPolygon = this.makeGeoFilterFlat(drawLayer);

      this.store.dispatch('map/setAsUndrawn');
      this.events.onBeforeDrawCreated(e, geoPolygon);

      this.clearDrawLayer();
      this.drawLayer.addLayer(drawLayer);
      this.fitToBoundsOf(this.drawLayer);

      this.store.dispatch('map/setAsDrawn');

      this.events.onDrawCreated(e, geoPolygon);

      this.updateMap(this.items, this.search);

      this.disableAllDrawHandlers();
    });
  }

  async listBackgroundMarkers () {
    const params = {
      projects_list: this.getProjectIds(),
      order_by: 'well_name',
      page_size: API_PER_PAGE_ALL,
      page: 1,
    };

    const { data } = await this.axios.get(GET_WELLS_INFO_URL, { params });

    toGeoJson(data.data).forEach(feature => {
      this.setBackgroundMarkerWithPopup({ ...feature, ...feature.properties });
    });

    if (this.backgroundLayer) {
      this.backgroundLayer.bringToBack();

      if (!isEmpty(this.backgroundLayer.getBounds())) {
        try {
          // Wait for the map to render before zooming to the bounds.
          // This is to avoid errors like undefined '_leaflet_pos'.
          setTimeout(() => {
            this.map.fitBounds(this.backgroundLayer.getBounds(), {
              padding: DEFAULT_FIT_BOUNDS_PADDING,
            });
          }, 800);
        } catch (e) {
          console.debug(e);
        }
      }
    }
  }

  setBackgroundMarkerWithPopup (data) {
    const {
      layer, popup, latLng, map,
    } = this.setMarkerWithPopup(data);

    layer.on('click', e => {
      this.onMapMarkerClicked(e);
    });

    this.setStyles(layer, map);

    if (this.backgroundLayer) {
      this.backgroundLayer.addLayer(layer);
    }

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  onMapMarkerClicked (e) {
    this.events.onMapMarkerClicked(e);
  }

  clearBackgroundMarkers () {
    if (this.backgroundLayer) {
      this.backgroundLayer.clearLayers();
    }
  }

  clearResultMarkers () {
    if (this.resultsLayer) {
      this.resultsLayer.clearLayers();
    }
  }

  clearDrawLayer () {
    if (this.drawLayer) {
      this.drawLayer.clearLayers();
    }
  }

  clearSelectedLayer () {
    if (this.selectedLayer) {
      this.selectedLayer.clearLayers();
    }
  }

  clearHeatLayer () {
    if (this.heatLayer) {
      this.heatLayer.setData({ data: [], max: 0 });
    }
  }

  setResultMarkers (items, fitToBounds = false) {
    this.clearResultMarkers();

    toGeoJson(items)
      .map(feature => ({ ...feature, ...feature.properties }))
      .forEach(feature => this.setResultMarkerWithPopup(feature));

    this.resultsLayer.bringToFront();

    if (fitToBounds) {
      this.fitToBoundsOrCenterView(this.resultsLayer);
    }
  }

  setSelectedLayerWithWhiteMarker ({ lat, lng }) {
    this.clearSelectedLayer();

    const coordinates = {
      lat: getCoordinateFromCardinalCoordinate(lat),
      lng: getCoordinateFromCardinalCoordinate(lng),
    };

    this.selectedLayer.bringToFront();
    this.selectedLayer.addLayer(L.circleMarker(coordinates, {
      stroke: true,
      radius: 13,
      weight: 2,
      fill: true,
      fillColor: '#09d034',
      color: 'white',
      fillOpacity: 0,
      riseOnHover: true,
    }));

    this.map.setView([
      coordinates.lat,
      coordinates.lng,
    ], this.getOption('zoom', ZOOM));
  }

  hasHeatLayer () {
    return !isNil(this.heatLayer);
  }

  setHeatLayer (heatData) {
    const user = this.store.getters['auth/user'];

    if (this.heatLayer && user.isPermittedTo('search_heatmap')) {
      const heatMax = Math.max(...heatData.map(heat => heat.count));
      this.heatLayer.setData({ data: heatData, max: heatMax });
    }
  }

  addLayerToResultMarkers (layer) {
    this.resultsLayer.addLayer(layer);
  }

  addLayerToDrawLayer (layer) {
    this.drawLayer.addLayer(layer);
  }

  fitToBoundsOf (layer, padding = [ 60, 60 ]) {
    const bounds = layer?.getBounds() ?? false;

    if (!isEmpty(bounds)) {
      setTimeout(() => {
        this.map.fitBounds(bounds, { padding });
      }, 500);
    }
  }

  fitToBoundsOrCenterView (layer = null, padding = [ 60, 60 ]) {
    if (layer) {
      const bounds = layer?.getBounds() ?? false;
      const hasNEBounds = bounds?._northEast ?? false;

      if (!isEmpty(bounds)) {
        setTimeout(() => {
          if (hasNEBounds) {
            this.map.setView(bounds?.getCenter(), this.getOption('zoom', ZOOM));
          } else {
            this.map.fitBounds(bounds, { padding });
          }
        }, 500);
      }
    }
  }

  setResultMarkerWithPopup (data) {
    const {
      layer, popup, latLng, map,
    } = this.setMarkerWithPopup(data, '#09d034');

    layer.setStyle(RESULTS_WELL_OPTIONS);

    this.setStyles(layer, map);

    if (this.resultsLayer) {
      this.resultsLayer.addLayer(layer);
    }

    return {
      layer,
      latLng,
      popup,
      map: this.map,
    };
  }

  fitBoundsToWorld () {
    if (this.map) {
      this.map.fitBounds(this.getWorldBounds());
    }
  }

  fitBoundsToResultsOrWorld () {
    if (this.map) {
      if (this.hasBounds(this.drawLayer)) {
        this.fitToBoundsOf(this.drawLayer);
      } else if (this.hasBounds(this.resultsLayer)) {
        this.fitToBoundsOf(this.resultsLayer, [ 20, 20 ]);
      } else {
        this.map.fitBounds(this.getWorldBounds());
      }
    }
  }

  getWorldBounds () {
    this.map.worldBounds = [ BOUNDS.ll, BOUNDS.ur ];
    return this.map.worldBounds;
  }

  hasBounds (layer) {
    return !isEmpty(layer?.getBounds() ?? false);
  }

  setHeatColorScale (count) {
    this.heatColorScale = scaleLog()
      .domain([ Math.min(...count), Math.max(...count) ])
      .range([ 200, 0 ]);
  }

  setFillOpacityScale (count) {
    this.fillOpacityScale = scaleLog()
      .domain([ Math.min(...count), Math.max(...count) ])
      .range([ 0.2, 0.9 ]);
  }

  getPolygonGeometryStyle (count) {
    return {
      color: 'grey',
      fillColor: `rgb(255, ${
        this.heatColorScale(count)
      }, ${
        this.heatColorScale(count)
      })`,
      stroke: true,
      weight: 1,
      fillOpacity: this.fillOpacityScale(count),
      opacity: this.fillOpacityScale(count),
    };
  }

  createGeoJSONLayer (styleOptions = POLYGON_HIGHLIGHT_OPTIONS) {
    function style () {
      return styleOptions;
    }

    function highlightFeature (e) {
      const layer = e.target;

      layer.setStyle({
        weight: 5,
        color: '#666',
        dashArray: '',
        fillOpacity: 0.7,
      });

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
    }

    function resetHighlight (e) {
      e.target.setStyle({
        color: 'rgb(244,41,65)',
        stroke: true,
        weight: 1,
        fillOpacity: 0.7,
      });
    }

    function zoomToFeature (e) {
      this._map.fitBounds(e.target.getBounds());
    }

    function onEachFeature (feature, layer) {
      layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: zoomToFeature,
      });

      layer
        .bindTooltip(layer.feature.properties.name, {
          direction: 'center',
        })
        .openTooltip();
    }

    const basinLayer = L.geoJSON(null, {
      style,
      onEachFeature,
    });

    return basinLayer;
  }

  generateGeoFilterFromFlatPolygon (flatPolygon, reverse = false) {
    return chunk(flatPolygon.split(',').map(i => parseFloat(i)), 2)
      .map(i => (reverse ? [ i[1], i[0] ] : i));
  }

  makeGeoFilterFlat (layer) {
    return layer.getLatLngs()
      .flatMap(latLngObj => latLngObj.map(latLng => [ latLng.lng, latLng.lat ]))
      .flat().toString();
  }

  getPolygonDashedHighlightOptions () {
    return POLYGON_DASHED_HIGHLIGHT_OPTIONS;
  }

  async invalidateSize () {
    this.startLoading();
    setTimeout(() => this.map.invalidateSize(), 400);
    await this.wait(400);
    this.stopLoading();
  }
}
