export default {
  data: () => ({
    isAutoBackToTop: true,
  }),

  methods: {
    backToTop () {
      this.$vuetify.goTo(0);
    },
  },

  updated () {
    if (this.isAutoBackToTop) {
      this.backToTop();
    }
  },
};
