export const supportedQuery = [
  'q',
  'page',
  'geo_tab',
  'order_by',
  'page_from',
  'descriptor',
  'geo_polygon',
  'projects_list',
  'has_geo_polygon',
  { search: 'q' },
  { sortBy: 'order_by' },
  { itemsPerPage: 'page_size' },
];

export const unsupportedQuery = [];

export default {
  supportedQuery,
  unsupportedQuery,
};
