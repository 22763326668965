import ObservableSlim from 'observable-slim';
import router from '@core/router';
import store from '@core/store';
import { _axios, config as axiosConfig, requestInterceptors } from '@/plugins/axios';
import {
  isObject, isEqual, values,
  isEmpty, keys, sortBy, size,
  uniq, has, reject,
  fromPairs, get,
} from 'lodash';
import config, { $app } from '@config/app';
import axios from 'axios';
import i18n from '@/i18n';

export default class DataResource {
  constructor (resource = {}) {
    this.key = resource.key || 'id';
    this.items = resource.items || [];
    this.headers = resource.headers || [];

    this.setMeta(resource.meta || {});
    this.makeData(resource.data || {});
    this.makeFields(resource.fields || {});
    this.setEvents(resource.events || {});

    this.axios = _axios;
    this.makeCancelToken();

    this.router = router;
    this.route = router.currentRoute;
    this.store = store;

    this.loading = resource.loading !== undefined ? resource.loading : false;
    this.loaded = !this.loading;
    this.disabled = resource.disabled !== undefined ? resource.disabled : this.loading;
    this.pristine = resource.pristine ?? true;
    this.dirty = resource.dirty ?? !this.pristine;
    this.valid = resource.valid ?? true;
    this.ready = false;
    this.unready = true;

    this.searching = resource.searching !== undefined ? resource.searching : false;
    this.search = resource.search || this.getSearch() || undefined;

    this.selected = resource.selected || [];
    this.showSelected = resource.showSelected || false;
    this.actions = resource.actions || [];
    this.errors = {};

    this.query = {};
    this.pagination = resource.pagination || {};

    this.mergeOptions(resource.options);

    this.setConfig({ ...config, ...$app });
  }

  list () { console.warn('The method @list is unhandled. You should write it yourself.'); }

  find (id) { console.warn('The method @find is unhandled. You should write it yourself.', id); }

  save () { console.warn('The method @save is unhandled. You should write it yourself.'); }

  update (id) { console.warn('The method @update is unhandled. You should write it yourself.', id); }

  remove (id) { console.warn('The method @remove is unhandled. You should write it yourself.', id); }

  restore (id) { console.warn('The method @restore is unhandled. You should write it yourself.', id); }

  delete (id) { console.warn('The method @delete is unhandled. You should write it yourself.', id); }

  mergeOptions (options = {}, withRowAll = true) {
    const routeQuery = this.getRouteQueryAsOptions();
    const itemsPerPage = parseInt(routeQuery.itemsPerPage || options.itemsPerPage || 10, 10);
    const total = this.pagination.total || '-1';
    const rowAll = withRowAll ? { text: 'All', value: total } : 5;

    this.options = {
      ...this.options,
      ...options,
      itemsPerPage,
      q: routeQuery.q,
      mustSort: options.mustSort,
      multiSort: options.multiSort,
      sortBy: options.sortBy || [],
      groupBy: options.groupBy || [],
      sortDesc: options.sortDesc || [],
      groupDesc: options.groupDesc || [],
      page: options.page || this.pagination.page || 1,
      rowsPerPage: uniq(sortBy([
        itemsPerPage, 5, 10, 15, 20, 50, 100,
      ])),
    };

    return this;
  }

  setOptions (options) {
    this.options = { ...this.options, ...options };

    return this;
  }

  getOption (key, fallback = null) {
    return this.options?.[key] ?? fallback;
  }

  getOptions () {
    return this.options;
  }

  /**
   * Available options:
   * {
   *   page: number,
   *   itemsPerPage: number,
   *   pageStart: number,
   *   pageStop: number,
   *   pageCount: number,
   *   itemsLength: number,
   * }
   * @param {Object} pagination
   */
  setPagination (pagination) {
    const pageStop = pagination.page * this.options.itemsPerPage
      > (pagination.total || this.pagination.itemsLength)
      ? pagination.total || this.pagination.itemsLength
      : pagination.page * this.options.itemsPerPage;

    this.pagination = {
      ...this.pagination,
      itemsPerPage: this.options.itemsPerPage,
      pageStart: (pagination.page * this.options.itemsPerPage) - this.options.itemsPerPage,
      pageStop,
      itemsLength: pagination.total || this.pagination.itemsLength,
      ...pagination,
    };

    return this;
  }

  getPagination () {
    return this.pagination;
  }

  getTotalPagination () {
    return this.pagination?.total;
  }

  setHeaders (headers) {
    this.headers = headers;
  }

  setMeta (meta) {
    this.meta = {
      isAllSelected: false,
      ...meta,
    };

    return this;
  }

  setQueryString (options) {
    const supportedQuery = this.parseOptionsAsSupportedQuery(options);

    this.query = {
      ...this.getQueryString(),
      ...supportedQuery,
    };

    this.pushRouteQuery();
    this.cleanQuery();

    return this.mergeOptions(options);
  }

  getQueryString () {
    return isEmpty(this.query) ? this.getRouteQuery() : this.query;
  }

  parseOptionsAsSupportedQuery (options) {
    const routeQuery = this.getQueryString();

    return fromPairs((this.meta.supportedQuery || []).map(val => {
      const key = isObject(val) ? values(val)[0] : val;
      const optionsKey = isObject(val) ? keys(val)[0] : val;
      const optionValue = options[optionsKey] || options[key];
      const value = optionValue || routeQuery[key];

      if (key === 'q' && optionValue === '') {
        return [ key, optionValue ];
      }

      if (optionValue !== undefined && optionValue === '') {
        return [ key, optionValue ];
      }

      return [ key, value ];
    }));
  }

  getRouteQueryAsOptions () {
    const options = this.getQueryString();

    return fromPairs((this.meta.supportedQuery || []).map(val => {
      const key = isObject(val) ? values(val)[0] : val;
      const optionsKey = isObject(val) ? keys(val)[0] : val;
      const value = options[key] || null;

      return [ optionsKey, value ];
    }));
  }

  getSupportedQueryKeyOf (queryKey) {
    for (let i = 0; i < this.meta.supportedQuery.length; i++) {
      const current = this.meta.supportedQuery[i];
      const key = isObject(current) ? keys(current)[0] : current;
      const value = isObject(current) ? values(current)[0] : current;

      if (key === queryKey) {
        return value;
      }
    }

    return null;
  }

  cleanQuery () {
    const query = this.clone(this.getQueryString());

    Object.keys(query).forEach(k => {
      if (isEmpty(query[k])) {
        delete query[k];
      }

      if (query[`has_${k}`] === 'false') {
        delete query[k];
      }
    });

    this.cleanedQuery = query;

    return this;
  }

  getCleanedQueryString () {
    return this.cleanedQuery;
  }

  getRouteQuery () {
    return this.route.query;
  }

  pushRouteQuery () {
    this.router.replace({ query: this.query }).catch(() => {});

    return this;
  }

  setItems (items) {
    this.items = items;
  }

  getItems () {
    return this.items;
  }

  getFirstItem () {
    return this.items?.[0];
  }

  unsetItems () {
    this.items = [];
  }

  findItem (item, key = 'id') {
    const query = item instanceof Object ? item : { [key]: item };

    return this.items.find(i => i[key] === query[key]);
  }

  isFirstItem (item, key = 'id') {
    const first = this.getFirstItem();

    return first?.[key] === item?.[key];
  }

  getPreviousOrFirstItem (key = '_id') {
    return this.findItem(this.route.query[key]) ?? this.getFirstItem();
  }

  goToAndSlide (query, options = { offset: 130, behavior: 'smooth' }) {
    try {
      const element = document.querySelector(query);
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - options.offset;

      if (element) {
        window.scrollTo({ top: offsetPosition, behavior: options.behavior });
      }
    } catch (error) {
      console.warn('Error sliding to element.', error);
    }

    return this;
  }

  isEmpty () {
    return isEmpty(this.items);
  }

  isNotEmpty () {
    return !this.isEmpty();
  }

  hasSearch () {
    return !isEmpty(this.search);
  }

  setSearch (search = undefined) {
    this.search = search;
    this.searching = true;
    this.setQueryString({ ...this.options, q: search });

    return this.pushRouteQuery();
  }

  getSearch () {
    return this.search || this.route.query.q;
  }

  clearSearch () {
    return this.setSearch('');
  }

  isSearching () {
    return this.searching;
  }

  toggleLoadingState () {
    this.loading = !this.loading;

    return this;
  }

  startSearching () {
    this.searching = true;

    return this;
  }

  stopSearching () {
    this.searching = false;

    return this;
  }

  startLoading () {
    this.loaded = false;
    this.loading = true;

    return this;
  }

  stopLoading () {
    this.searching = false;
    this.loading = false;
    this.loaded = true;

    return this;
  }

  isLoadingOr (additionalFlag) {
    return this.loading || additionalFlag;
  }

  toggleDisabledState () {
    this.disabled = !this.disabled;

    return this;
  }

  disable () {
    this.disabled = true;

    return this;
  }

  enable () {
    this.disabled = false;

    return this;
  }

  setErrors (errors) {
    this.errors = errors;
    this.stopLoading();
    this.store.dispatch('errors/set', errors);

    return this;
  }

  getErrors (key = null, fallback = null) {
    return key ? this.errors?.[key] ?? fallback : this.errors;
  }

  hasErrors () {
    return !isEmpty(this.errors);
  }

  flushErrors () {
    this.errors = {};

    return this;
  }

  size () {
    return size(this.items);
  }

  getSelectedItemsAsString (key = null) {
    return this.selected.map(i => (key ? i[key] : i)).toString();
  }

  hasSelected () {
    return this.selectedSize() > 0;
  }

  selectedSize () {
    return (this.selected || []).length;
  }

  unselectAll () {
    this.selected = [];
    this.meta.isAllSelected = false;

    return this;
  }

  selectAll () {
    const returnAsKey = has(this.meta, 'returnObject') && !this.meta.returnObject;
    this.selected = returnAsKey ? this.items.map(i => i[this.key]) : this.items;
    this.meta.isAllSelected = true;
  }

  isAllSelected () {
    return this.meta.isAllSelected;
  }

  isPristine () {
    return this.pristine;
  }

  isNotPristine () {
    return !this.pristine;
  }

  isDirty () {
    return this.dirty;
  }

  isNotDirty () {
    return !this.dirty;
  }

  makePristine () {
    this.pristine = true;
    this.dirty = false;
  }

  makeDirty () {
    if (isEqual(this.data, this.oldData)) {
      this.makePristine();
    } else {
      this.dirty = true;
      this.pristine = false;
    }
  }

  makeReady () {
    this.ready = true;
    this.unready = false;

    return this;
  }

  makeUnready () {
    this.unready = true;
    this.ready = false;

    return this;
  }

  isReady () {
    return this.ready;
  }

  isNotReady () {
    return this.unready;
  }

  setFields (fields) {
    this.fields = fields;

    return this;
  }

  getFields () {
    return this.fields;
  }

  updateFields (fields) {
    Object.keys(fields).forEach(key => {
      this.fields[key] = fields[key];
    });

    return this;
  }

  observeFields (fields = {}) {
    this.oldFields = { ...fields };

    return ObservableSlim.create(fields, true, c => this._onPropertyChanged(c));
  }

  restoreOldFields () {
    this.setFields({ ...this.oldFields });
    this.makePristine();
  }

  field (key) {
    return this.fields?.[key];
  }

  oldField (key) {
    return this.oldFields?.[key];
  }

  setEvents (events) {
    this.events = events;
  }

  getEvents () {
    return this.events;
  }

  getEvent (key, fallback = null) {
    return this.events?.[key] ?? fallback;
  }

  makeData (data) {
    this.data = this.observeData(data);

    return this;
  }

  makeFields (fields) {
    this.fields = fields;

    return this;
  }

  getData () {
    return this.data;
  }

  setData (data) {
    this.data = this.observeData(data);

    return this;
  }

  updateData (data) {
    Object.keys(data).forEach(key => {
      this.data[key] = data[key];
    });

    return this;
  }

  observeData (data = {}) {
    this.oldData = { ...data };

    return ObservableSlim.create(data, true, c => this._onPropertyChanged(c));
  }

  restoreOldData () {
    this.setData({ ...this.oldData });
    this.makePristine();
  }

  old (key) {
    return this.oldData?.[key];
  }

  observe (data, domDelay = true, callback = null) {
    return ObservableSlim.create(data, domDelay, callback ?? (c => this._onPropertyChanged(c)));
  }

  alert (message) {
    this.store.dispatch('alert/showAndSetMessage', message);

    return this;
  }

  $prompt (prompt, payload) {
    this.store.dispatch('dialog/prompt', {
      ...payload,
      prompt: {
        ...prompt,
        show: true,
      },
      show: true,
    });
  }

  $dialog (payload) {
    this.store.dispatch('dialog/show', payload);
  }

  dialog (type, payload) {
    this.store.dispatch(`dialog/${type}`, payload);
  }

  getSelectedItems () {
    return this.selected;
  }

  isSelected (item, key = 'id') {
    return this.selected.find(i => i[key] === item[key]);
  }

  toggle (item) {
    if (this.isSelected(item)) {
      return this.unselect(item);
    }

    return this.select(item);
  }

  select (item) {
    this.selected.push(this.items.find(i => i === item));

    return this;
  }

  selectFirstItem () {
    this.selected = [ this.getFirstItem() ];

    return this;
  }

  unselect (item) {
    this.selected = reject(this.selected, i => i === item);

    return this;
  }

  selectPreviousOrFirstItem (key = '_id') {
    this.selected = [ this.getPreviousOrFirstItem(key) ];

    return this;
  }

  parseStringify (obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  ps (obj) {
    return this.parseStringify(obj);
  }

  copyToClipboard (text) {
    navigator.clipboard.writeText(text);

    return this;
  }

  notify (text, options = {}) {
    this.store.dispatch('snackbar/show', { text, ...options });

    return this;
  }

  hideNotifications (options = {}) {
    this.store.dispatch('snackbar/hide', options);

    return this;
  }

  goTo (route) {
    this.router.push(route).catch(() => {});

    return this;
  }

  setConfig (config) {
    this.config = config;
  }

  getConfig (key = null, fallback = null) {
    return key ? get(this.config, key) ?? fallback : this.config;
  }

  disableAxiosResponseHandlers () {
    const instance = axios.create(axiosConfig);
    instance.interceptors.request.use(requestInterceptors.success, requestInterceptors.error);
    this.axios = instance;
  }

  enableAxiosResponseHandlers () {
    this.axios = _axios;
  }

  setAxiosTimeoutToDynamic (items) {
    // Temporary fix for the axios timeout
    // for 300-800+ api calls:
    // In average, through tests:
    // 300 api calls are less than or equal to 60 seconds
    // To set timeout value,
    // polygons.length / (300/60) * 1000
    let guessedTimeoutValue = (items.length / 5) * 1000;
    guessedTimeoutValue = guessedTimeoutValue <= this.axios.defaults.timeout
      ? this.axios.defaults.timeout
      : guessedTimeoutValue;

    this.setAxiosTimeout(guessedTimeoutValue);
  }

  setAxiosTimeout (timeout = 60000) {
    this.axios.timeout = timeout;
  }

  getAxiosTimeout () {
    return this.axios.timeout;
  }

  getCancelToken () {
    return {
      cancelToken: this.axiosSource?.token,
    };
  }

  makeCancelToken () {
    this.axiosSource = this.generateCancelToken();

    return this;
  }

  generateCancelToken () {
    return axios.CancelToken.source();
  }

  cancelAllRequests () {
    if (this.axiosSource) {
      this.axiosSource.cancel('Operation cancelled by user');
    }

    return this;
  }

  clone (obj) {
    return { ...obj };
  }

  trans (text, fields) {
    return i18n.t(text, fields);
  }

  transChoice (text, count = 1, fields = {}) {
    return i18n.tc(text, count, fields);
  }

  async wait (ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  clearConsoleLogs () {
    console.clear();
  }

  _onPropertyChanged () {
    this.makeDirty();
  }
}
