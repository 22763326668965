import Manifest from '@core/manifests/Manifest';

export const MODULE_TYPE_CORE = 'core';
export const MODULE_TYPE_SRC = 'src';

const requireModule = require.context(
  // The relative path of the routes folder
  '@/modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base names
  /[a-zA-Z0-9-_]\/manifest.json$/,
);

const requireCoreModule = require.context(
  // The relative path of the routes folder
  '@core/modules',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base names
  /[a-zA-Z0-9-_]\/manifest.json$/,
);

export const modules = [
  ...requireCoreModule
    .keys().sort()
    .map(file => ({ type: MODULE_TYPE_CORE, path: file, ...requireCoreModule(file) })),
  ...requireModule
    .keys().sort()
    .map(file => ({ type: MODULE_TYPE_SRC, path: file, ...requireModule(file) })),
];

class ModuleManifest extends Manifest {
  constructor (attributes = {}) {
    super(attributes);
  }

  getAllModules () {
    return this.all();
  }

  getCoreModules () {
    return this.getItems().filter(i => i.type === MODULE_TYPE_CORE);
  }

  getModules () {
    return this.getItems().filter(i => i.type === MODULE_TYPE_SRC);
  }

  getItems () {
    return this.getAllItems().filter(i => i.enabled);
  }

  install (Vue) {
    Vue.prototype.$modules = this;
  }
}

export default new ModuleManifest({ items: modules });
