<template>
  <div class="bbox-ocr-indicator__container">
    <slot></slot>
    <v-card
      v-if="isVisible"
      :color="color"
      :style="cssVars"
      :class="{ pulsate: pulsate }"
      flat rounded
      class="bbox-ocr-indicator__dot rounded-circle"
    ></v-card>
  </div>
</template>

<script>
export default {
  name: 'BboxOcrIndicator',

  props: {
    alwaysShow: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Boolean,
      default: false,
    },

    total: {
      type: [ String, Number ],
      default: 0,
    },

    color: {
      type: String,
      default: 'yellow darken-1',
    },

    pulsate: {
      type: Boolean,
      default: false,
    },

    left: {
      type: [ String, Number ],
      default: '-0.5rem',
    },

    top: {
      type: [ String, Number ],
      default: '-0.5rem',
    },
  },

  computed: {
    isVisible () {
      return this.alwaysShow || (!this.value && this.total !== 0);
    },

    cssVars () {
      return {
        '--bbox-indicator-color': this.color,
        '--bbox-indicator-left': this.left,
        '--bbox-indicator-top': this.top,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bbox-ocr-indicator {
  &__container {
    position: relative;
  }

  &__dot {
    background: var(--bbox-indicator-color);
    border-color: var(--bbox-indicator-color);
    color: var(--bbox-indicator-color);
    height: 8px;
    left: var(--bbox-indicator-left);
    opacity: 0.7;
    position: absolute;
    top: var(--bbox-indicator-top);
    transition: opacity 0.3s ease-in-out;
    width: 8px;

    &.pulsate::after {
      border: 3px solid;
      border-color: inherit;
      border-radius: 50%;
      content: '';
    }
  }
}
</style>
