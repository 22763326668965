import DataResource from '@core/resources/DataResource';
import Settings from '@/modules/Settings/resources/Settings';
import {
  SEND_RESET_PASSWORD_URL,
  CHANGE_PASSWORD_URL,
  REFRESH_TOKEN_URL,
  POST_REGISTER_URL,
  POST_VERIFY_RECAPTCHA_URL,
  POST_VERIFY_ACCOUNT_URL,
} from '@/modules/Auth/api/authentication';
import { DEFAULT_PRODUCT_ID } from '@config/app';
import { AUTH_TYPE, MILLISECONDS_BEFORE_TOKEN_EXPIRED } from '@config/token';
import { USER_PROFILE_URL } from '@/modules/Auth/api/profile';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import moment from 'moment';
import recaptcha from '@/modules/Auth/config/recaptcha';
import LoginHooksManifest from '@/modules/Auth/manifests/LoginHooksManifest';
import LogoutHooksManifest from '@/modules/Auth/manifests/LogoutHooksManifest';
import RegisterHooksManifest from '@/modules/Auth/manifests/RegisterHooksManifest';
import Vue from 'vue';
import { isArray } from 'lodash';

export default class Auth extends DataResource {
  constructor (options = {}) {
    super(options);
    this.email = options.email || this.route.query.email || null;
    this.password = options.password || null;
    this.passwordConfirmation = options.passwordConfirmation || null;
    this.username = options.username || this.route.query.email || null;
    this.token = options.token || null;
    this.valid = options.valid || false;
    this.loading = options.loading || false;
    this.rememberMe = options.rememberMe || this.store.getters['auth/remember'];
    this.errors = options.errors || null;

    this.verification = {
      verify_code: options.verifyCode || this.route.query.verify_code || this.route.query['verify-code'] || null,
    };

    this.makeFields({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirmation_password: '',
      organization: '',
      job: '',
      address: '',
      country: '',
      contact_number: '',
      account_type: 'apprentice',
      notARobot: '',
      recaptcha: '',
      recaptcha_check: false,
      recaptcha_hint: '',

      /**
       * Hardcoded product ID for now.
       * TODO: refactor
       */
      priceId: DEFAULT_PRODUCT_ID,
    });

    this.setMeta({
      recaptcha,
    });

    this.meta.recaptcha.resetHint();
  }

  check () {
    return this.store.getters['auth/isAuthenticated'];
  }

  async attempt ({ username, password, rememberMe }) {
    await this.store.dispatch('auth/login', {
      username,
      password,
      rememberMe,
    });
    this.fetchInstanceSettings();
  }

  async login () {
    this.flushErrors();
    this.startLoading();

    try {
      await this.attempt({
        username: this.username,
        password: this.password,
        rememberMe: this.rememberMe,
      });
      this.triggerLoginHook();
    } catch (e) {
      this.setErrors(e?.response?.data ?? e);
      this.meta.validator.setErrors(this.getErrors());
    } finally {
      this.stopLoading();
    }

    return this.check() ? this.redirect() : false;
  }

  async logout () {
    await this.store.dispatch('auth/logout');

    return this;
  }

  async register () {
    try {
      this.startLoading();
      this.disableAxiosResponseHandlers();

      const formData = new FormData;
      Object.keys(this.fields).forEach(key => formData.append(key, this.fields[key]));
      const { data } = await this.axios.post(POST_REGISTER_URL, formData);

      if (data.data?.message) {
        this.triggerRegisterHook();
      }
    } catch (e) {
      this.alert({
        title: 'An error occured.',
        content: e?.response?.data?.errors?.message
          ?? 'An unhandled error occured while trying to register. Please try again.',
        type: 'error',
      });
      return Promise.reject(this.getErrors());
    } finally {
      this.stopLoading();
      this.enableAxiosResponseHandlers();
    }

    return Promise.resolve(this);
  }

  async verifyRecaptcha (token) {
    this.meta.recaptcha.startHint();
    try {
      this.startLoading();
      this.disableAxiosResponseHandlers();

      this.fields.recaptcha = token;
      const fields = { recaptcha: token };
      const formData = new FormData;
      Object.keys(fields).forEach(key => formData.append(key, fields[key]));
      const { data } = await this.axios.post(POST_VERIFY_RECAPTCHA_URL, formData);

      if (data?.data?.success) {
        this.meta.recaptcha.successHint();
      } else {
        this.meta.recaptcha.errorHint();
      }
    } catch (e) {
      this.meta.recaptcha.errorHint();
    } finally {
      this.stopLoading();
      this.enableAxiosResponseHandlers();
    }
  }

  async redirect () {
    const intended = this.route.query.redirect ?? this.getRedirectRouteFromEnv();

    await this.router.push(intended);

    return this.check();
  }

  getRedirectRouteFromEnv () {
    const user = this.getUserProfile();

    return user.getRedirectRouteFromEnv();
  }

  async sendEmailResetRecoveryEmail () {
    try {
      this.generateAvailabilityLimit();

      const params = {
        email: this.email,
        ui_url: window.location.origin,
      };

      const { data } = await this.axios.post(SEND_RESET_PASSWORD_URL, params);

      if (data.data.message) {
        this.setErrors({ email: data.data.message });
        this.meta.validator.setErrors(this.getErrors());
        this.router.push({ name: 'password.sent', query: { email: this.email } });
      }
    } catch (e) {
      this.setErrors(e);
      this.meta.validator.setErrors(this.getErrors());
    }
  }

  async changePassword () {
    const params = {
      reset_password_code: this.route.query['reset-code'],
      new_password: this.password,
      password_confirmation: this.password_confirmation,
    };

    const { data } = await this.axios.post(CHANGE_PASSWORD_URL, params);

    if (data.data.message) {
      this.notify(data.data.message);
    }

    this.router.push({ name: 'login' });
  }

  async fetchUserProfile () {
    const { data } = await this.axios.get(USER_PROFILE_URL);
    let user = data.data;
    if (isArray(user)) {
      user = data.data.reduce((a, b) => ({ ...a, ...b }), {});
    }
    this.store.dispatch('auth/setUser', user);

    return this;
  }

  getUserProfile () {
    return this.store.getters['auth/user'];
  }

  async fetchInstanceSettings () {
    const settings = new Settings;
    settings.fetchInstanceSettings();
  }

  setValidator (validator) {
    this.meta.validator = validator;

    return this;
  }

  setErrors (data) {
    this.errors = data;

    if (data.errors) {
      Object.keys(data.errors).forEach(key => {
        const errorKey = key === 'non_field_errors' ? 'username' : key;
        this.errors[errorKey] = data.errors[key];
      });
    }
  }

  getToken () {
    return this.store.getters['auth/token'];
  }

  isTokenAboutToExpire () {
    const { exp } = jwtDecode(this.getToken());
    const now = Date.now() / 1000;
    const timeUntilRefresh = exp - now;

    return (timeUntilRefresh - MILLISECONDS_BEFORE_TOKEN_EXPIRED) <= 0;
  }

  async refreshToken () {
    // Create a new axios instance to prevent conflict
    // with existing instances with interceptors
    // that might produce an infinite loop.
    const refreshTokenAxios = axios.create();
    let token = this.getToken();

    try {
      const { data } = await refreshTokenAxios.post(REFRESH_TOKEN_URL, { token });
      token = data?.token || data?.data?.token;
      this.store.dispatch('auth/setSessionToken', token);
      this.setAxiosAuthorizationHeader(token);
    } catch (err) {
      this.logout();
      this.router.push({ name: 'home' });
    }

    return token;
  }

  setAxiosAuthorizationHeader (token) {
    this.axios.defaults.headers.Authorization = `${AUTH_TYPE} ${token}`;
  }

  getAuthorizationHeaderToken () {
    return `${AUTH_TYPE} ${this.getToken()}`;
  }

  generateAvailabilityLimit (type = 'password', limit = 5, period = 'minutes') {
    const time = moment().add(limit, period).valueOf();
    this.store.dispatch(`${type}/setAvailability`, time);
  }

  isStillAvailable () {
    const availability = this.store.getters['password/availability'];
    return availability >= moment().valueOf();
  }

  isPageStillAvailable (page) {
    const session = Vue.prototype.$session.get(`${page}.availability`);
    const availability = this.store.getters[`${page}/availability`];

    if (session) {
      return session >= moment().valueOf();
    }

    return availability >= moment().valueOf();
  }

  triggerLoginHook () {
    LoginHooksManifest.getHooks().forEach(hook => hook.trigger(this));
  }

  triggerLogoutHook () {
    LogoutHooksManifest.getHooks().forEach(hook => hook.trigger(this));
  }

  triggerRegisterHook () {
    RegisterHooksManifest.getHooks().forEach(hook => hook.trigger(this));
  }

  async verifyAccount () {
    this.startLoading();

    try {
      const { data } = await this.axios.post(POST_VERIFY_ACCOUNT_URL, this.verification);
      if (data.data.message) {
        this.generateAvailabilityLimit('verify', 2);
        this.store.dispatch('verify/setMessage', {
          title: 'Account Verification',
          content: data.data.message,
          type: 'success',
        });
        this.router.push({ name: 'verified' });
      }
    } catch (e) {
      this.stopLoading();
    }
  }

  remembersMe () {
    return this.store.getters['auth/remember'];
  }

  remembersMeNot () {
    return !this.remembersMe();
  }
}
