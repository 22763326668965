import Config from '@core/resources/Config';

export const items = [
  { text: 'Low', value: 'low' },
  { text: 'Medium', value: 'medium' },
  { text: 'High', value: 'high' },
  { text: 'Urgent', value: 'urgent' },
];

export default new Config({
  items,
});
