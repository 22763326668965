<template>
  <v-simple-table>
    <thead>
      <tr>
        <th
          colspan="100%"
          class="text-uppercase"
          v-text="$trans('End of Drilling')"
        ></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="100%" v-text="item.end_of_drill"></td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'TableEndOfDrilling',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
