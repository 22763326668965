<template>
  <v-simple-table dense>
    <tbody>
      <tr>
        <td class="caption font-weight-bold text-uppercase" v-text="$trans('Field')"></td>
        <td v-text="item.field_name"></td>
        <td class="caption font-weight-bold text-uppercase" v-text="$trans('Basin')"></td>
        <td v-text="item.basin"></td>
      </tr>
      <tr>
        <td class="caption font-weight-bold text-uppercase" v-text="$trans('Latitude')"></td>
        <td v-text="getCoordinateFromCardinalCoordinate(item.latitude)"></td>
        <td class="caption font-weight-bold text-uppercase" v-text="$trans('Longitude')"></td>
        <td v-text="getCoordinateFromCardinalCoordinate(item.longitude)"></td>
      </tr>
      <tr>
        <td class="caption font-weight-bold text-uppercase" v-text="$trans('CRS')"></td>
        <td colspan="100%" v-text="item.crs"></td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>

import { getCoordinateFromCardinalCoordinate } from '@/modules/Dashboard/utils/map';

export default {
  name: 'DetailLocation',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    getCoordinateFromCardinalCoordinate,
  },
};
</script>
