<template>
  <workflow-content>
    <meta-tag :title="$trans('Rock Physics')"></meta-tag>

    <portal to="appbar:left">
      <qeye-logo-brand></qeye-logo-brand>
    </portal>

    <portal to="workflow:sidebar">
      <app-search-field
        v-model="rockphysics.search"
        :loading="rockphysics.searching"
        :label="$trans('Search a well')"
        :placeholder="$trans('Search a well')"
        solo class="mb-6"
        @click:clear="clearSearch"
        @enter:search="setSearch"
      ></app-search-field>

      <v-list
        dense nav
        two-line
        class="pa-0"
      >
        <v-list-item
          v-for="(item, i) in wells" :key="i"
          :class="{ 'grey': isActive(item) }"
          @click="setPage(item)"
        >
          <v-list-item-content>
            <strong v-text="item.text"></strong>
            <span v-text="item.subtext"></span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <menu-pagination
        :filtered="rockphysics.search"
        :page="rockphysics.pagination.page"
        :length="rockphysics.pagination.pageCount"
        :total="rockphysics.pagination.total"
        :pagination="rockphysics.pagination"
        @update:current="onUpdatePage"
        @click:unfilter="onUnfilter"
      ></menu-pagination>
    </portal>

    <v-row>
      <v-col cols="12">
        <v-layout align-center>
          <v-btn icon class="mr-3" @click="toggleMenu">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <h2 v-text="rockphysics.data.well_name"></h2>
        </v-layout>
      </v-col>
    </v-row>
    <v-alert v-if="rockphysics.hasErrors()" outlined text type="error" dismissible class="my-4">
      <span
        class="login-error-message"
        v-text="rockphysics.getErrors('message', 'Something went wrong')"
      ></span>
    </v-alert>

    <v-row v-if="rockphysics.data.bokeh">
      <v-col cols="12">
        <rockphysics-preview-card-loading-state
          v-if="rockphysics.loading"
        ></rockphysics-preview-card-loading-state>
        <rockphysics-interactive-card
          v-else
          :item.sync="rockphysics.data.bokeh"
        ></rockphysics-interactive-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <rockphysics-interactive-card-empty-state></rockphysics-interactive-card-empty-state>
      </v-col>
    </v-row>
  </workflow-content>
</template>

<script>
import RockPhysics from '@/modules/RockPhysics/resources/RockPhysics';
import { mapActions } from 'vuex';

export default {
  layout: 'admin',

  meta: {
    permissions: [ 'pod_rock_physics' ],
    isWorkflow: true,
  },

  data: () => ({
    rockphysics: new RockPhysics({
      meta: {
        currentTab: 0,
        supportedQuery: [
          'q',
          'page',
          'order_by',
          'projects_list',
          { search: 'q' },
          { sortBy: 'order_by' },
          { itemsPerPage: 'page_size' },
        ],
      },
    }),
  }),

  computed: {
    searchQuery () {
      return this.rockphysics.search || '';
    },

    wells () {
      return (this.rockphysics.items || []);
    },
  },

  mounted () {
    this.rockphysics.list();
  },

  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggleMenu',
    }),

    setSearch (e) {
      this.rockphysics.setSearch(e.target.value).setQueryString({ page: 1 }).list();
    },

    clearSearch () {
      this.rockphysics.clearSearch().list();
    },

    setPage (item) {
      this.rockphysics.unselectAll();
      this.rockphysics.select(item);
      this.rockphysics.findAvoDetail(item.well_name);
    },

    isActive (item) {
      return this.rockphysics.selected.includes(item);
    },

    onUpdatePage (page) {
      this.rockphysics.setQueryString({ page }).list();
    },

    onUnfilter () {
      this.rockphysics.clearSearch().list();
    },
  },
};
</script>
