import { isEmpty } from 'lodash';
import { uploadForm, uploadTray, uploadProgress } from '@/modules/File/config/upload';
import { set } from '@/modules/File/config/set';
import tours from '@/modules/File/config/tours';

export const state = {
  isUploadRetrying: false,
  isUploadDone: false,
  uploadProgress,
  uploadForm,
  uploadTray,
  files: [],
  sets: [],
  tours,
  set,
};

export const getters = {
  sets: state => state.sets,
  set: state => state.set,
  files: state => state.files,
  hasFiles: state => !isEmpty(state.files),
  uploadTray: state => state.uploadTray,
  uploadForm: state => state.uploadForm,
  uploadProgress: state => state.uploadProgress,
  uploadTrayIsShown: state => state.uploadTray.isShown,
  uploadFormIsShown: state => state.uploadForm.isShown,
  uploadTrayIsHidden: state => !state.uploadTray.isShown,
  uploadFormIsHidden: state => !state.uploadForm.isShown,
  isSetUploadCancelled: state => state.set.isCancelled,
  isSetUploadRetrying: state => state.set.isRetrying,
  isUploadRetrying: state => state.isUploadRetrying,
  isUploadDone: state => state.isUploadDone,
  isUploaderTourShown: state => state.tours.find('uploader').isShown,
};

export const mutations = {
  SET_FILES (state, files) {
    state.files = files;
  },

  SET_UPLOAD_TRAY_IS_SHOWN (state, isShown) {
    state.uploadTray.setIsShown(isShown);
  },

  SHOW_UPLOAD_TRAY (state) {
    state.uploadTray.show();
  },

  HIDE_UPLOAD_TRAY (state) {
    state.uploadTray.hide();
  },

  TOGGLE_UPLOAD_TRAY (state) {
    state.uploadTray.toggle();
  },

  SET_UPLOAD_PROGRESS (state, progress) {
    state.uploadProgress = progress;
  },

  CLEAR_UPLOAD_PROGRESS (state) {
    state.uploadProgress = uploadProgress;
  },

  SET_FILE_SET (state, fileSet) {
    state.isUploadDone = false;
    state.set = { ...state.set, ...fileSet, isCancelled: false };
    state.sets.push(state.set);
  },

  TOGGLE_UPLOAD_TRAY_MINIMIZE (state) {
    state.uploadTray.toggleMinimize();
  },

  UPLOAD_FILES (state, upload) {
    state.upload = upload;
  },

  SET_UPLOAD_TRAY_ITEMS (state, files) {
    state.uploadTray.setItems(files);
  },

  CANCEL_UPLOAD (state) {
    state.sets.forEach(i => i.cancelUpload());
    state.set.cancelUpload();
  },

  RETRY_UPLOAD (state) {
    state.sets.forEach(i => i.retryUpload());
    state.isUploadRetrying = true;
    state.set.retryUpload();
  },

  MARK_UPLOAD_AS_DONE (state) {
    state.isUploadDone = true;
  },

  SHOW_UPLOAD_FORM (state) {
    state.uploadForm.show();
  },

  HIDE_UPLOAD_FORM (state) {
    state.uploadForm.hide();
  },

  TOGGLE_UPLOAD_FORM (state) {
    state.uploadForm.toggle();
  },

  SHOW_UPLOADER_TOUR (state) {
    state.tours.setLocal('uploader', 'show', state.tours.show('uploader'));
  },

  HIDE_UPLOADER_TOUR (state) {
    state.tours.setLocal('uploader', 'show', state.tours.hide('uploader'));
  },

  TOGGLE_UPLOADER_TOUR (state) {
    state.tours.setLocal('uploader', 'show', state.tours.toggleShown('uploader'));
  },
};

export const actions = {
  setFiles ({ commit }, files) {
    commit('SET_FILES', files);
  },

  setUploadTrayIsShown ({ commit }, isShown) {
    commit('SET_UPLOAD_TRAY_IS_SHOWN', isShown);
  },

  showUploadTray ({ commit }) {
    commit('SHOW_UPLOAD_TRAY');
  },

  hideUploadTray ({ commit }) {
    commit('HIDE_UPLOAD_TRAY');
  },

  toggleUploadTray ({ commit }) {
    commit('TOGGLE_UPLOAD_TRAY');
  },

  setUploadProgress ({ commit }, progress) {
    commit('SET_UPLOAD_PROGRESS', progress);
  },

  clearUploadProgress ({ commit }) {
    commit('CLEAR_UPLOAD_PROGRESS');
  },

  setFileSet ({ commit }, set) {
    commit('SET_FILE_SET', set);
  },

  toggleUploadTrayMinimize ({ commit }) {
    commit('TOGGLE_UPLOAD_TRAY_MINIMIZE');
  },

  upload ({ commit }, upload) {
    commit('UPLOAD_FILES', upload);
  },

  setUploadTrayItems ({ commit }, files) {
    commit('SET_UPLOAD_TRAY_ITEMS', files);
  },

  cancelUpload ({ commit }) {
    commit('CANCEL_UPLOAD');
  },

  retryUpload ({ commit }, uploader) {
    commit('RETRY_UPLOAD', uploader);
  },

  markUploadAsDone ({ commit }) {
    commit('MARK_UPLOAD_AS_DONE');
  },

  showUploadForm ({ commit }) {
    commit('SHOW_UPLOAD_FORM');
  },

  hideUploadForm ({ commit }) {
    commit('HIDE_UPLOAD_FORM');
  },

  toggleUploadForm ({ commit }) {
    commit('TOGGLE_UPLOAD_FORM');
  },

  showUploaderTour ({ commit }) {
    commit('SHOW_UPLOADER_TOUR');
  },

  hideUploaderTour ({ commit }) {
    commit('HIDE_UPLOADER_TOUR');
  },

  toggleUploaderTour ({ commit }) {
    commit('TOGGLE_UPLOADER_TOUR');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
