<template>
  <v-dialog v-model="dialog" min-width="420" max-width="600">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="{ on }">
        <v-btn color="primary" class="px-6" v-on="on">
          <span v-text="$trans('References')"></span>
        </v-btn>
      </slot>
    </template>
    <v-card class="pa-6">
      <v-card-title>
        <h2 class="h2 mb-4" v-html="$trans('References')"></h2>
      </v-card-title>
      <v-card-text v-if="well">
        <div v-for="(reference, i) in well.references" :key="i" class="mb-3">
          <router-link
            :to="{
              name: 'reports.single',
              params: { slug: reference.iraya_data_id },
              query: { page_num: reference.page_num },
            }"
          >
            <span v-text="reference.iraya_data__doc_name"></span>
          </router-link>
          &nbsp;
          <em class="muted--text" v-text="`(p.${reference.page_num})`"></em>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          large
          color="primary"
          class="px-6"
          @click="dialog = false"
        ><span v-text="$trans('Close')"></span></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'VizPieChartMapControlReferencesDialog',

  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapGetters({
      well: 'viz/piechart/well',
    }),
  },
};
</script>
