import store from '@core/store';
import { SUPPORTS_AUTHENTICATION } from '@core/config/auth';
import { generateToken } from '@/utilities/session';

export default async function isAuthenticated (to, from, next) {
  const isAuthenticated = SUPPORTS_AUTHENTICATION && store.getters['auth/isAuthenticated'];

  if (!SUPPORTS_AUTHENTICATION) {
    return next();
  }

  if (!to.meta.isAuthenticatable) {
    return next();
  }

  const user = store.getters['auth/user'];
  if (user?.data?.token && !sessionStorage.session_id) {
    sessionStorage.setItem('token', user.data.token);
    sessionStorage.setItem('session_id', generateToken());
    return window.location.reload();
  }

  if (!isAuthenticated) {
    return next({ name: 'login', query: { redirect: to.fullPath }, replace: true });
  }

  return next(to.query.redirect);
}
