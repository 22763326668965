var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"py-5 align-center"},[_c('v-autocomplete',{attrs:{"label":_vm.$trans('Projects'),"items":_vm.projects,"dense":"","filled":"","outlined":"","hide-details":""},on:{"change":_vm.onProjectChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.color,"left":"","size":"10"}}),_c('span',{staticClass:"ml-4 caption",domProps:{"textContent":_vm._s(item.text)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.color,"size":"10"}}),_c('span',{staticClass:"ml-4 caption",domProps:{"textContent":_vm._s(item.text)}})]}}]),model:{value:(_vm.project.currentFilter),callback:function ($$v) {_vm.$set(_vm.project, "currentFilter", $$v)},expression:"project.currentFilter"}}),_c('div',{staticClass:"mx-2"}),_c('v-autocomplete',{attrs:{"label":_vm.$trans('Status'),"items":_vm.statusItems,"dense":"","filled":"","outlined":"","hide-details":""},on:{"change":_vm.onStatusChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-avatar',{attrs:{"color":item.color,"left":"","size":"10"}}),_c('span',{staticClass:"mx-4 caption",domProps:{"textContent":_vm._s(item.text)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.color,"size":"10"}}),_c('span',{staticClass:"ml-4 caption",domProps:{"textContent":_vm._s(item.text)}})]}}]),model:{value:(_vm.status.currentFilter),callback:function ($$v) {_vm.$set(_vm.status, "currentFilter", $$v)},expression:"status.currentFilter"}}),_c('div',{staticClass:"mx-2"}),(_vm.updateOnClick)?_c('v-btn',{attrs:{"depressed":""},domProps:{"textContent":_vm._s(_vm.$trans('Filter'))},on:{"click":_vm.filter}}):_vm._e(),(_vm.hasFilters)?_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":""},domProps:{"textContent":_vm._s(_vm.$trans('Clear Filter'))},on:{"click":_vm.clearFilter}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }