<template>
  <keep-alive></keep-alive>
</template>

<script>
export default {
  layout: 'auth',

  created () {
    this.$router.push({ name: 'password.reset', replace: true });
  },
};
</script>
