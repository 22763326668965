import DataResource from '@core/resources/DataResource';
import PrettyBytes from '@core/filters/PrettyBytes';
import sources from '@/modules/Sourcetray/config/sources.js';
import { SPACE_PROJECT_URL } from '@/modules/File/api/spaces';
import { PROJECT_TYPE_ALL } from '@/modules/File/config/files';
import { sumBy } from 'lodash';

export default class Space extends DataResource {
  constructor (options = {}) {
    super(options);

    this.progressStatus = options.progressStatus ?? {};
  }

  async find (id) {
    this.startLoading();

    const { data } = await this.axios.get(SPACE_PROJECT_URL(id));

    this.setData(data.data);
    this.setProgressStatus(this.isIdKeyAll(id) ? data.data : [ data.data ]);

    this.stopLoading();
  }

  isIdKeyAll (id) {
    return id === PROJECT_TYPE_ALL;
  }

  setProgressStatus (items) {
    const storages = items.map(item => {
      const { name, project_settings: projectSettings } = item.attributes;
      const storageUsed = parseFloat(item.attributes.storage_used ?? 0);
      const ingestedFiles = parseFloat(item.attributes.ingested_files ?? 0);
      const storageLimit = parseFloat(projectSettings?.UPLOADER_SETTINGS?.STORAGE_LIMIT ?? 0);
      const ingested = PrettyBytes.filters.toMb(ingestedFiles);
      const used = PrettyBytes.filters.toMb(storageUsed);
      const limit = PrettyBytes.filters.toMb(storageLimit);
      const percent = (storageUsed / storageLimit) * 100;
      const color = sources.find(source => source.name === item.attributes.project_type)?.color;
      const text = `${name} ${used} of ${limit} used`;
      const ingestedText = `${name} ${ingested} ingested`;

      const storageUsedPercent = (storageUsed / storageLimit) * 100;
      const ingestedFilesPercent = (ingestedFiles / storageLimit) * 100;
      const storageUsedBar = {
        text,
        color,
        key: 'storage',
        opacity: 0.5,
        percent: storageUsedPercent,
        value: storageUsed,
      };
      const ingestedFilesBar = {
        color,
        key: 'ingested',
        text: ingestedText,
        opacity: 0.25,
        percent: ingestedFilesPercent,
        value: ingestedFiles,
      };
      const bars = [ storageUsedBar, ingestedFilesBar ];

      return {
        ingestedFiles,
        storageLimit,
        ingestedText,
        storageUsed,
        ingested,
        percent,
        color,
        limit,
        name,
        used,
        text,
        bars,
      };
    });

    const totalIngestedFiles = sumBy(storages, 'ingestedFiles');
    const totalStorageUsed = sumBy(storages, 'storageUsed');
    const totalStorageLimit = sumBy(storages, 'storageLimit');
    const totalColor = storages.map(i => i.color);
    const totalUsed = PrettyBytes.filters.toMb(totalStorageUsed);
    const totalLimit = PrettyBytes.filters.toMb(totalStorageLimit);
    const totalIngested = PrettyBytes.filters.toMb(totalIngestedFiles);
    const totalPercent = (totalStorageUsed / totalStorageLimit) * 100;

    const text = `${totalUsed ?? 0} used, ${totalIngested} ingested`;

    this.progressStatus = {
      totalIngestedFiles,
      totalStorageLimit,
      totalStorageUsed,
      totalPercent,
      totalColor,
      totalLimit,
      totalUsed,
      storages,
      text,
    };

    return this;
  }

  getProgressStatus () {
    return this.progressStatus;
  }
}
