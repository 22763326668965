<template>
  <v-card height="calc(100vh - 216px)">
    <v-layout fill-height align-center justify-center>
      <div class="text-center mb-10">
        <icon-task-list-paper></icon-task-list-paper>
        <div v-text="$trans('Select a well from the list')"></div>
      </div>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: 'PetrophysicsInteractiveCardEmptyState',
};
</script>
