<template>
  <div></div>
</template>

<script>
export default {
  layout: 'home',

  created () {
    this.$router.replace({ name: 'login' });
  },
};
</script>
